import React, { useState } from 'react';
import WalletBalanceDisplayPrototype from '../../shared/molecules/WalletBalanceDisplayPrototype';
import WalletTabs from '@shared/molecules/WalletTabs';
import { Title } from '@shared/atoms/Typography';
import BalanceModal from '../modals/BalanceModal';

import "./index.less"
import { useTranslation } from 'react-i18next';

const WalletPrototype = () => {
  const [isBalanceModalVisible, setIsBalanceModalVisible] = useState(false)
  
  const handleCloseModal = () => {
    setIsBalanceModalVisible(false);
  };

  const { t } = useTranslation();

  return (
    <div className='wallet-container-prototype'>
      <BalanceModal isModalVisible={isBalanceModalVisible} handleClose={handleCloseModal} />

      <Title level={1} className="title">{t("wallet")}</Title>
      <WalletBalanceDisplayPrototype balance={"0.00"} onClick={() => setIsBalanceModalVisible(true)} />
      <WalletTabs />
    </div>
  )

};

export default WalletPrototype;
