import React, { useEffect } from "react";
import { useContext } from "react";
import { Context } from "@context/context";

import DraggableList from "@components/organisms/lists/DraggableList";
import { DroppableTypes } from "@context/dndContextPrototype";
import PlaylistVideoCard from "../PlaylistVideoCard";

import "./index.less";

function playlistVideoCardPlaceholder(video, serie) {
  return (
    <PlaylistVideoCard
      coverImg={video.image}
      title={video.title}
      infoBoxSubtitle={serie.name ? serie.name : ""}
      description={video.description}
      moreButtons={{
        moreDescription: video.more_description,
        moreInfoTitle: video.more_info_title,
        morePhoto: video.more_photo,
        moreTitle: video.more_title,
        goToLink: video.go_to,
        goToTitle: video.go_to_title,
      }}
    />
  );
}

const PlaylistSeries = ({ children, content }) => {
  const {
    isPlaylistArrangeMode,
    setIsPlaylistArrangeMode,
    isItemsArrangeMode,
  } = useContext(Context);

  useEffect(
    () => () => setIsPlaylistArrangeMode(false),
    [setIsPlaylistArrangeMode]
  );

  if (!isPlaylistArrangeMode && !isItemsArrangeMode) return children;

  // Arranging playlist items
  if (isItemsArrangeMode) {
    return (
      <div className="playlist-series-arrange">
        <DraggableList
          listItems={content}
          droppableId={DroppableTypes.MoveItems}
          isCombineEnabled={true}
        >
          {(serie, provided) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className="playlist-series-arrange__card"
              >
                {serie.videos.slice(0, 1).map((video, index) => {
                  return (
                    <div className="video-container" key={video.id} id={index}>
                      {playlistVideoCardPlaceholder(video, serie)}
                    </div>
                  );
                })}
              </div>
            );
          }}
        </DraggableList>
      </div>
    );
  }

  return (
    <div className="playlist-series-arrange">
      <DraggableList
        listItems={content}
        droppableId={DroppableTypes.SorterMode}
      >
        {(serie, provided) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className="playlist-series-arrange__card"
            >
              {serie.videos.slice(0, 1).map((video, index) => {
                return (
                  <div className="video-container" key={video.id} id={index}>
                    {playlistVideoCardPlaceholder(video, serie)}
                  </div>
                );
              })}
            </div>
          );
        }}
      </DraggableList>
    </div>
  );
};

export default PlaylistSeries;
