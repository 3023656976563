import React, { useEffect, useState } from "react";
import { formLayout } from "@modules/config";
import Form from "@shared/atoms/Form/index";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@shared/atoms/Button/index";
import Input from "@shared/atoms/Input/index";

import Alert from "@shared/atoms/Alert/index";
import "./index.less";
import { confirmResetPassword } from "../../../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import comparePasswords from "@utils/comparePasswords";

const MobileResetPassword = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [disableBtn, setDisableBtn] = useState(false);

    const { email, token } = useParams();
    const history = useHistory();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [warning, setWarning] = useState("");

    const userConfirmResetPassword = useSelector(
        (state) => state.userConfirmResetPassword
    );
    const {
        error: resetConfirmError,
        success: resetConfirmSuccess
    } = userConfirmResetPassword;
    const [error, setError] = useState("");

    useEffect(() => {
        if (!(email && token)) {
            history.replace("/login");
        }
    }, [email, token, history]);


    useEffect(() => {
        let isSamePasswords = comparePasswords(password, confirmPassword);
        if (isSamePasswords) {
            setWarning("");
            setDisableBtn(false);
        } else {
            isSamePasswords !== undefined && setWarning("Passwords don't match");
            setDisableBtn(true);
        }
    }, [password, confirmPassword]);

    const submitPassword = () => {
        dispatch(confirmResetPassword(email, token, password, confirmPassword));
    };


    useEffect(() => {
        if (resetConfirmError && resetConfirmError?.data) {
            setError(Object.values(resetConfirmError?.data?.errors)[0]);
        }
    }, [resetConfirmError]);

    useEffect(() => {
        if (resetConfirmSuccess) {
            history.replace('/login');
        }
    }, [history, resetConfirmSuccess])

    return <div className="reset-password-container">
        {warning && <Alert
                    message={warning}
                    closable={false}
        />}
        {resetConfirmError && <Alert
            message={error || t("password_not_changed_error")}
            type="error"
        />}
        {resetConfirmSuccess && (
            <Alert
                message={t("password_has_changed")}
                type="success"
            />
        )}
        <Form layout={formLayout}>
            <div>
                <Form.Item>
                    <Input.Password
                        placeholder={t("password")}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Item>
                <Form.Item>
                    <Input.Password
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder={t("confirm_password")}
                    />
                </Form.Item>
            </div>
            <div className="login-btn-container login-btn-container-mobile reset-button">
                <Button
                    onClick={submitPassword}
                    disabled={disableBtn}
                    className="login-btn login-btn-mobile"
                    type="primary"
                    htmlType="submit"
                >
                    {t("reset")}
                </Button>
            </div>
        </Form>
    </div>;
};

export default MobileResetPassword;