import { Context } from "@context/context";
import { localStorageService } from "@modules/services/localStorage.service";
import Layout from "antd/lib/layout/layout";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import "./index.less";

const PrivacyAndPolicyPrototype = ({ shouldCloseModal }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { setShowPrivacyModalPrototype } = useContext(Context);

  const goBackHandler = () => {
    if (shouldCloseModal) {
      setShowPrivacyModalPrototype(false);
    } else {
      history.push(localStorageService().get("redirected_from") || "/settings");
      localStorageService().remove("redirected_from");
    }
  };
  return (
    <Layout className={"mobile-layout-prototype bg-black"}>
      <div class="privacy-container-prototype">
        <span class="privacy-policy-secondary-title">
          <button id="back-button" onClick={goBackHandler}>
            <span>{t("just_back")}</span>
          </button>
        </span>
        <div class="privacy-policy-secondary-title flex justify-between align-center">
          <span>Privacy Policy</span>
          <span class="span-small text-underline">Version: June 2021</span>
        </div>
        <div class="privacy-policy-text-block">
          The Choice Platform is operated and managed by Choice NV, a limited
          liability company incorporated under the laws of Belgium, registered
          at the Crossroads Bank for Enterprises under the number 0748.659.757
          and with its registered office at Jachthoorn 5, 3210 Lubbeek, Belgium
          or by any of its affiliates (jointly <strong>"Choice"</strong>,{" "}
          <strong>"we"</strong> or <strong>"us"</strong>). Choice collects data
          when you (<strong>"User"</strong> or <strong>"you"</strong>) use the
          Platform, register as a user and contact us. Amongst these data, we
          also collect personal data, namely any information relating to an
          identified or identifiable natural person (
          <strong>"Personal Data"</strong>); an identifiable natural person is
          one who can be identified, directly or indirectly, in particular by
          reference to an identifier such as a name, an identification number,
          location data, an online identifier or to one or more factors specific
          to the physical, physiological, genetic, mental, economic, cultural or
          social identity of that natural person.
        </div>
        <div class="privacy-policy-text-block">
          Choice understands the importance of privacy and strives to protect
          it. All your Personal Data collected by Choice shall therefore be
          processed in accordance with the applicable privacy legislations,
          including the European Regulation 2016/679 on the protection of
          natural persons with regard to the processing of personal data and on
          the free movement of such data (the
          <strong>"General Data protection Regulation"</strong> or{" "}
          <strong>"GDPR"</strong>), together with codes of practice, regulatory
          guidance and standard clauses and other related legislation resulting
          from the GDPR, as amended from time to time.
        </div>
        <div class="privacy-policy-text-block">
          This privacy policy (the <strong>"Privacy Policy"</strong>) describes
          the Personal Data that we collect from you and process, the purposes
          of such processing, the legal basis for processing your Personal Data,
          the retention period of these Personal Data, which third parties may
          access your Personal Data and your rights under the GDPR with regards
          to the Personal Data we process. This Privacy Policy applies to the
          relationship between you and Choice, together with the Terms of Use of
          the Platform you can consult on{" "}
          <a
            href="https://my.choice.be/ToU"
            target="_blank"
            rel="noreferrer"
            download="ToU.pdf"
          >
            https://my.choice.be/ToU.
          </a>
          <br />
          <br />
          Please read this Privacy Policy carefully. You can also consult this
          Privacy Policy on our website{" "}
          <a
            href="https://my.choice.be/privacy-policy"
            target="_blank"
            rel="noreferrer"
            download="privacy-policy.pdf"
          >
            https://my.choice.be/privacy-policy
          </a>
        </div>
        <ol className="privacy-policy-ol">
          <li>
            <span className="privacy-policy-tertiary-title">
              DATA CONTROLLER
            </span>
            <ol className="data-controller-ol">
              <li className="privacy-list-items">Choice NV</li>
              <li className="privacy-list-items">
                Jachthoorn 5, 3210 Lubbeek, Belgium
              </li>
              <li className="privacy-list-items">Erwin Maes</li>
              <li className="privacy-list-items">erwin.maes@choice.be</li>
              <li className="privacy-list-items">watch.choice.be</li>
            </ol>
          </li>
          <li>
            <span className="privacy-policy-tertiary-title">
              THE PURPOSES OF THE PROCESSING
            </span>
            <p className="privacy-p-small">
              We collect your Personal Data for the following purposes:
            </p>
            <ol type="a">
              <li>to allow you to access and use the Platform; </li>
              <li>
                to set up your User account and your access to your account;
              </li>
              <li>to display content from external platforms.</li>
              <li>
                to communicate with you and provide you with the appropriate
                support;
              </li>
              <li>
                to improve, effectively present and personalise our services by
                using the analytical services of third parties to evaluate your
                use of the website, create reports on the activity, collect
                demographic data, analyse performance data, and collect other
                data on our website. These third parties use cookies and other
                technology to help analyse the data and to provide the data to
                us.{" "}
              </li>
            </ol>
            <p className="privacy-p-small">
              We want to guarantee a safe and lawful processing of your Personal
              Data, and we assure you that your Personal Data will be processed
              in a straightforward and lawful way. This infers that your
              Personal Data will only be processed for the aforementioned
              explicitly purposes.
            </p>
          </li>
          <li>
            <span className="privacy-policy-tertiary-title">
              TYPES OF DATA COLLECTED
            </span>
            <p className="privacy-p-small">
              3.1.1 When you use the Platform, we collect the following
              categories of data:{" "}
            </p>
            <ol type="a">
              <li>
                identification data such as your user account data (if any);
              </li>
              <li>
                technical data such as your IP address, your operating system,
                the devices on which you use the Platform;
              </li>
              <li>
                behavioural data such as the manner in which you use the
                Platform or the type of content you watch.{" "}
              </li>
            </ol>
            <p className="privacy-p-small">
              3.1.2 When you register for a User account, we collect the
              following categories of data:{" "}
            </p>
            <ol type="a">
              <li>
                identification data such as your email address, your name and
                surname, your address, your telephone number, your preferred
                language;
              </li>
              <li>
                technical data such as your IP address, your operating system,
                your devices you use the Platform on.
              </li>
            </ol>
            <p className="privacy-p-small">
              3.1.3 When you contact us, we collect the following categories of
              data:
            </p>
            <ol type="a">
              <li>
                identification data such as you user account (if any), your
                email address or phone number;
              </li>
              <li>the timings of your correspondence with us;</li>
              <li>the content of your correspondence with us</li>
            </ol>
            <p className="privacy-p-small">
              3.1.4 When you register for our newsletter, we collect the
              following categories of data:
            </p>
            <ol type="a">
              <li>
                identification data such as you user account (if any), your
                email address or phone number.
              </li>
            </ol>
          </li>
          <li>
            <span className="privacy-policy-tertiary-title">
              LAWFUL BASIS OF PROCESSING
            </span>
            <p className="privacy-p-small">
              We process your personal data in strict accordance with the
              applicable privacy legislation and on the basis of the following
              legal grounds:
            </p>
            <ol type="a">
              <li>
                <u>Performance of the agreement:</u> we need to process most of
                your Personal Data to deliver our services, to comply with our
                obligations, to communicate with you prior to entering into a
                Commercial User Agreement with you. For example, we need your
                contact details to be able to create a user account.
              </li>
              <li>
                <u>Legitimate interest:</u> we sometimes need to process your
                Personal Data to achieve a legitimate interest. For instance, we
                collect your behaviour data to improve our services and the
                Platform.{" "}
              </li>
              <li>
                <u>Consent:</u> we may also process your data if we are given
                express consent, e.g. when you register for a newsletter or when
                you agree to the use of specific cookies.]
              </li>
            </ol>
          </li>
          <li>
            <span className="privacy-policy-tertiary-title">
              RETENTION AND DELETION
            </span>
            <p className="privacy-p-small">
              We will only process and store your Personal Data for as long as
              required by the lawful basis on which they have been collected.
              This means that:
            </p>
            <ol type="a">
              <li>
                Personal Data collected in order to perform a contract between
                you and Choice will be retained until such contract has been
                fully performed;
              </li>
              <li>
                Personal Data collected for the purposes of Choice's legitimate
                interest will be retained as long as needed to fulfil such
                legitimate interest;
              </li>
              <li>
                Personal Data collected based on the User's consent can be
                retained for as long as such consent is not withdrawn.
              </li>
            </ol>
            <p className="privacy-p-small">
              We may be obliged to retain your Personal Data for a longer period
              to comply with a legal obligation or with an order of an
              authority. In any event, Choice will not retain your data longer
              than 10 year.{" "}
            </p>
          </li>
          <li>
            <span className="privacy-policy-tertiary-title">
              YOUR RIGHTS AS DATA SUBJECT{" "}
            </span>
            <p className="privacy-p-small">
              Under the GDPR, you are entitled to exercise your rights mentioned
              below. To exercise rights, please contact us by using the contact
              details provided in Article 0 of this Privacy Policy.
            </p>
            <p className="privacy-p-small">
              <u>6.1.1 Right for information and access</u>
            </p>
            <ol className="list-style-none">
              <li className="margin-10">
                You are always entitled to know what Personal Data we have on
                you, how, where and for how long they are processed. You
                therefore have the right to request information regarding the
                Personal Data that we hold and process in your respect and to
                access these Personal Data. We will then provide you with
                information and/or access to the Personal Data that is being
                processed and on the source of such Personal Data.
              </li>
              <li className="margin-10">
                Please note that we may request you to provide us with
                additional information necessary to confirm your identity before
                accessing your request for information or access.
              </li>
            </ol>
            <p className="privacy-p-small">
              <u>6.1.2 Right of rectification and right of erasure</u>
            </p>
            <ol className="list-style-none">
              <li className="margin-10">
                You have the right to request us, free of charge, to rectify any
                inaccuracies in your Personal Data if such Personal Data would
                be incomplete or inaccurate.
              </li>
              <li className="margin-10">
                You have the right to request us, free of charge, to erase all
                your Personal Data collected, stored and processed by us,
                without unreasonable delay
              </li>
            </ol>
            <p className="privacy-p-small">
              <u>6.1.3 Right to object to data processing</u>
            </p>
            <ol className="list-style-none">
              <li className="margin-10">
                You may, at any time, withdraw the consent that you have given
                to Choice to the processing of your Personal Data.
              </li>
              <li className="margin-10">
                We may solely use your Personal Data to contact you for direct
                marketing purposes if you have explicitly consented hereto. If
                you have consented hereto in the past, and you no longer want to
                receive such direct marketing through mail, you may at any time
                unsubscribe.
              </li>
            </ol>
            <p className="privacy-p-small">
              <u>6.1.4 Right to restriction</u>
            </p>
            <ol className="list-style-none">
              <li className="margin-10">
                You have the right to obtain from us the restriction of
                processing where: (i) you contest the accuracy of the Personal
                Data, (ii) the processing is unlawful and you request the
                restriction instead of deletion of the Personal Data, (iii) we
                no longer need the Personal Data but they are required for a
                legal claim, and (iv) you have objected to the deletion and
                there is a verification of whether legitimate grounds can
                override this request.{" "}
              </li>
            </ol>
            <p className="privacy-p-small">
              <u>6.1.5 Right to Data Portability</u>
            </p>
            <ol className="list-style-none">
              <li className="margin-10">
                Within the limits set forth in the GDPR, you may request the
                portability of your Personal Data, i.e. by obtaining that the
                Personal Data you have provided to Choice will be returned to
                you or transferred to someone else of your choice, in a
                structured, commonly used and machine-readable format
              </li>
            </ol>
            <p className="privacy-p-small">
              <u>
                6.1.6 Right to lodge a complaint with the competent authority{" "}
              </u>
            </p>
            <ol className="list-style-none">
              <li className="margin-10">
                If you are not satisfied with the way we process your Personal
                Data, please let us know and we will investigate your concern
                using the above communication means. You also have the right to
                lodge a complaint with a supervisory authority. In Belgium, the
                supervisory authority is the Gegevensbeschermingsautoriteit /
                autorité de protection des données: <br />{" "}
                <a
                  href="https://www.dataprotectionauthority.be/citizen"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.dataprotectionauthority.be/citizen
                </a>{" "}
              </li>
            </ol>
          </li>
          <li>
            <span className="privacy-policy-tertiary-title">
              DISCLOSER TO THIRD PARTIES AND TRANSFER TO THIRD COUNTRIES
            </span>
            <p className="privacy-p-small">7.1 Discloser to third parties</p>
            <ol className="list-style-none">
              <li className="margin-10">
                Other than as set out in this Privacy Policy, Choice will not
                sell or otherwise disclose your Personal Data to third parties
                without obtaining your prior explicit consent, unless this is
                necessary for the purpose set out in this Privacy Policy or
                unless we are required to do so by law
              </li>
              <li>
                In the event of full or partial merger with, or acquisition of
                all or part of Choice, Choice may transfer your Personal Data to
                a Third Party. In such event, Choice shall impose this Third
                Party to use any Personal Data strictly consistent with this
                Privacy Policy.{" "}
              </li>
              <li>
                We may also share Personal Data with third-party cloud providers
                and service providers who help us to provide, commercialise and
                improve the Platform and our services. We do not authorise these
                third-party service providers to use or disclose your Personal
                Data except as strictly necessary to perform any services under
                our supervision or to comply with applicable legislation. We
                seek to provide any such third-party service provider with only
                the Personal Data they need to perform their specific function.
              </li>
              <li>
                In any event, such third-party service providers shall be
                obliged to treat your Personal Data in accordance with this
                Privacy Policy. However, we cannot be held liable for any
                damages, whether direct or indirect, that may result from the
                misuse of your Personal Data by such third-party service
                providers
              </li>
            </ol>
            <p className="privacy-p-small">7.2 Transfer to third countries</p>
            <ol className="list-style-none">
              <li>
                Depending on the circumstances, some recipients of your Personal
                Data may be located in non-EU countries. In such case, if this
                country is not recognised by the European Commission as offering
                an adequate level of data protection, we will put in place
                appropriate safeguards to protect your Personal Data in
                accordance with the GDPR, such as the Standard Contractual
                Clauses of the European Commission, if necessary with additional
                measures.{" "}
              </li>
            </ol>
          </li>
          <li>
            <span className="privacy-policy-tertiary-title">
              CHANGES TO THIS PRIVACY POLICY{" "}
            </span>
            <ol className="list-style-none">
              <li>
                Choice reserves the right to make changes to this Privacy Policy
                at any time at its sole discretion. You will be notified of any
                changes to this Privacy Policy when you visit our Platform
                following implementation of those changes and/or – as far as
                technically and legally feasible – through a notice sent via any
                contact information available to Choice. It is strongly
                recommended to check this page often, referring to the date of
                the last modification.{" "}
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </Layout>
  );
};

export default PrivacyAndPolicyPrototype;
