// import { Context } from "@context/context";

import { Context } from "@context/context";
import { deleteTeam, getTeamsAction } from "@redux/actions/TeamActions";
import Modal from "@shared/atoms/Modal/index";
import { Paragraph } from "@shared/atoms/Typography/index";
import { useContext } from "react";
import { useDispatch } from "react-redux";

import "./index.less";

const DeleteTeamModalPrototype = ({ text, id }) => {
  const { setShowDeleteTeamModal, showDeleteTeamModal } = useContext(Context);

  const dispatch = useDispatch();

  const deleteTeamItemHandler = () => {
    Promise.resolve(dispatch(deleteTeam(id))).then(() =>
      dispatch(getTeamsAction())
    );
    setShowDeleteTeamModal(false);
  };

  return (
    <Modal
      visible={showDeleteTeamModal}
      className="modal-basic-prototype simple-popup"
      mask={true}
      closable={true}
      onCancel={() => setShowDeleteTeamModal(false)}
      footer={
        <>
          <>
            <button
              className="accept modal-button"
              onClick={deleteTeamItemHandler}
            >
              Accept
            </button>

            <button
              className="modal-button"
              onClick={() => setShowDeleteTeamModal(false)}
            >
              Reject
            </button>
          </>
        </>
      }
    >
      <div>
        <Paragraph>{text}</Paragraph>
      </div>
    </Modal>
  );
};

export default DeleteTeamModalPrototype;
