import React, { useEffect, useState } from "react";
import Form from "@shared/atoms/Form/index";
import Input from "@shared/atoms/Input/index";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "@redux/actions/countryActions";
import moment from "moment";
import { Select } from "antd";
import Checkbox from "@shared/atoms/Checkbox/index";
import choiceLogo from "@assets/icons/choice-icon-white.png";
import Image from "@components/shared/atoms/Image";
import EditableImage from "@components/organisms/EditableImage";
// import Badge from "@components/shared/atoms/Badge";
import userDefaultIcon from "@assets/icons/user.png";
import "./index.less";

const UpdateProfileFormPrototype = ({
  setBlobImg,
  setFirstName,
  setLastName,
  setNickName,
  setEmail,
  setAddress,
  setPhoneNumber,
  setHouseholdCount,
  setCountryId,
  setBirthDate,
  setGender,
  setMailCheckbox,
  // setUserProfileImg,
  findPublicProfileCheckbox,
  firstName,
  lastName,
  nickName,
  email,
  address,
  phoneNumber,
  householdCount,
  countryId,
  birthDate,
  mailCheckbox,
  setFindPublicProfileCheckbox,
  userProfileImg,
}) => {
  const { t } = useTranslation();
  const { Option } = Select;

  const genderOptions = [`${t("male_prototype")}`, `${t("female_prototype")}`];
  const [genderName, setGenderName] = useState("");

  const dispatch = useDispatch();

  const countries = useSelector((state) => state.countries.countries);

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  // const deleteImageHandler = () => {
  //   setUserProfileImg(userDefaultIcon);
  //   setBlobImg(null);
  // };

  const getDate = (e) => {
    if (e) {
      setBirthDate(moment(e.target.value).format("YYYY-MM-DD"));
    }
  };

  return (
    <Form className="form-prototype update-profile-form-prototype">
      <Form.Item>
        <div className="update-profile-logo-container ex-sm sm md">
          <Image
            className="update-profile-logo-img"
            src={choiceLogo}
            alt="choice"
          />
        </div>
      </Form.Item>
      {userProfileImg && (
        <Form.Item>
          <div className="profile-image-container">
            {/* {
              <Badge
                onClick={deleteImageHandler}
                className="delete"
                count={"X"}
              ></Badge>
            } */}

            <div className="img-container">
              <Image
                className="profile-image"
                src={userProfileImg || userDefaultIcon}
                alt="profile"
              />
            </div>
          </div>
        </Form.Item>
      )}

      <Form.Item className="upload-image-from-item">
        <EditableImage
          setBlobImg={setBlobImg}
          btnText={"Upload Profile Image"}
          hasOr={true}
        />
      </Form.Item>
      <Form.Item>
        <Input
          disabled="true"
          type="text"
          placeholder={`${t("nickname")}`}
          value={nickName}
          onChange={(e) => {
            setNickName(e.target.value);
          }}
        />
      </Form.Item>
      <Form.Item>
        <Input
          type="text"
          placeholder={t("first_name")}
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        />
      </Form.Item>
      <Form.Item>
        <Input
          type="text"
          placeholder={t("last_name")}
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />
      </Form.Item>
      <Form.Item>
        <Input
          disabled="true"
          type="email"
          placeholder={t("email")}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </Form.Item>
      <Form.Item>
        <Input
          type="text"
          placeholder={t("address")}
          value={address}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
        />
      </Form.Item>
      <Form.Item>
        <Input
          type="text"
          placeholder={t("phone_number")}
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
        />
      </Form.Item>
      <Form.Item>
        <Input
          type="text"
          placeholder={t("household")}
          value={householdCount}
          onChange={(e) => {
            setHouseholdCount(e.target.value);
          }}
        />
      </Form.Item>

      <Form.Item>
        {countries?.length && (
          <Select
            showSearch
            optionFilterProp="children"
            placeholder={t("country_of_domicile")}
            onSelect={(e) => setCountryId(e)}
            // defaultValue={
            //   countries?.filter((country) => country.id === countryId)[0]?.name
            // }
            value={countryId ? countryId : `${t("country")}`}
          >
            {countries &&
              countries.map((country) => (
                <Option value={country.id} key={country.id}>
                  {country.name}
                </Option>
              ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item>
        <label className="date-label">{t("year_of_birth")}</label>
        <Input
          type="date"
          value={moment(birthDate).format("yyyy-MM-DD")}
          onChange={(date) => getDate(date)}
        />
      </Form.Item>
      <Form.Item>
        <Select
          // options={genderOptions}
          onSelect={(e) => {
            setGender(e);
            setGenderName(e);
          }}
          value={genderName ? genderName : `${t("gender")}`}
          // className="ant-select"
        >
          {genderOptions.map((gender) => (
            <Option value={gender} key={gender}>
              {gender}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item className="email-checkbox">
        <Checkbox
          onChange={() => {
            setMailCheckbox(!mailCheckbox);
          }}
          checked={mailCheckbox}
        />
        <label>{t("email_notifications")}</label>
      </Form.Item>
      <Form.Item className="profile-find-checkbox">
        <Checkbox
          onChange={() => {
            setFindPublicProfileCheckbox(!findPublicProfileCheckbox);
          }}
          checked={findPublicProfileCheckbox}
        />
        <label>{t("allow_users_to_find_my_public_profile")}</label>
      </Form.Item>
    </Form>
  );
};

export default UpdateProfileFormPrototype;
