import { createSelector } from "reselect";
import {
  ADULT_CONTENT_REQUEST,
  ADULT_CONTENT_SUCCESS,
  ADULT_CONTENT_FAIL,
  ADULT_CONTENT_CONFIRM_REQUEST,
  ADULT_CONTENT_CONFIRM_SUCCESS,
  ADULT_CONTENT_CONFIRM_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_RESET,
  USER_PROFILE_UPDATE_FAIL,
  USER_PROFILE_BY_ID_REQUEST,
  USER_PROFILE_BY_ID_SUCCESS,
  USER_PROFILE_BY_ID_FAIL,
  USER_CHANGE_PASSWORD_REQUEST,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_FAIL,
  USER_CHANGE_PASSWORD_RESET,
} from "../constants/profileConstants";

export const adultContentToggleReducer = (state = {}, action) => {
  switch (action.type) {
    case ADULT_CONTENT_REQUEST:
      return { loading: true };
    case ADULT_CONTENT_SUCCESS:
      return { loading: false, confirmationEmail: action.payload };
    case ADULT_CONTENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adultContentConfirmReducer = (state = {}, action) => {
  switch (action.type) {
    case ADULT_CONTENT_CONFIRM_REQUEST:
      return { loading: true };
    case ADULT_CONTENT_CONFIRM_SUCCESS:
      return { loading: false, success: true };
    case ADULT_CONTENT_CONFIRM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return { loading: true };
    case USER_PROFILE_SUCCESS:
      return { loading: false, profile: action.payload };
    case USER_PROFILE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateUserProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_UPDATE_REQUEST:
      return { loading: true };
    case USER_PROFILE_UPDATE_SUCCESS:
      return { loading: false, profile: action.payload };
    case USER_PROFILE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_PROFILE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const userProfileByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_BY_ID_REQUEST:
      return { loading: true };
    case USER_PROFILE_BY_ID_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_PROFILE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userChangePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CHANGE_PASSWORD_REQUEST:
      return { loading: true };
    case USER_CHANGE_PASSWORD_SUCCESS:
      return { loading: false, message: action.payload };
    case USER_CHANGE_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case USER_CHANGE_PASSWORD_RESET:
      return {};
    default:
      return state;
  }
};

// Selectors
export const selectCurrentUser = createSelector(
  (state) => state?.userProfile,
  (userProfile) => {
    if (!userProfile.profile) return null;

    return userProfile.profile.data;
  }
);
