import localforage from "localforage";

export async function storePlaylistPositionToStorage(playlistObj) {
  try {
    const playlists = await localforage.getItem("playlist-positions");
    if (playlists) {
      const playlistAt = playlists.findIndex(
        (playlist) => playlist.playlistId === playlistObj.playlistId
      );
      const userIdAt = playlists.findIndex(
        (playlist) => playlist.userId === playlistObj.userId
      );
      if (playlistAt === -1 || userIdAt === -1) {
        playlists.push(playlistObj);
      } else {
        playlists[playlistAt] = playlistObj;
      }

      await localforage.setItem("playlist-positions", playlists);
    } else {
      await localforage.setItem("playlist-positions", [playlistObj]);
    }
  } catch (error) {
    throw new Error(error);
  }
}
