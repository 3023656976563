export const SEARCH_UNAUTHORIZED_REQUEST = "SEARCH_UNAUTHORIZED_REQUEST";
export const SEARCH_UNAUTHORIZED_SUCCESS = "SEARCH_UNAUTHORIZED_SUCCESS";
export const SEARCH_UNAUTHORIZED_FAIL = "SEARCH_UNAUTHORIZED_FAIL";
export const SEARCH_UNAUTHORIZED_RESET = "SEARCH_UNAUTHORIZED_RESET";

export const SEARCH_AUTHORIZED_REQUEST = "SEARCH_AUTHORIZED_REQUEST";
export const SEARCH_AUTHORIZED_SUCCESS = "SEARCH_AUTHORIZED_SUCCESS";
export const SEARCH_AUTHORIZED_FAIL = "SEARCH_AUTHORIZED_FAIL";
export const SEARCH_AUTHORIZED_RESET = "SEARCH_AUTHORIZED_RESET";

//search history

export const SEARCH_HISTORY_REQUEST = "SEARCH_HISTORY_REQUEST";
export const SEARCH_HISTORY_SUCCESS = "SEARCH_HISTORY_SUCCESS";
export const SEARCH_HISTORY_FAIL = "SEARCH_HISTORY_FAIL";

export const CLEAR_HISTORY_REQUEST = "CLEAR_HISTORY_REQUEST";
export const CLEAR_HISTORY_SUCCESS = "CLEAR_HISTORY_SUCCESS";
export const CLEAR_HISTORY_FAIL = "CLEAR_HISTORY_FAIL";

export const CREATE_SEARCH_HISTORY_REQUEST = "CREATE_SEARCH_HISTORY_REQUEST";
export const CREATE_SEARCH_HISTORY_SUCCESS = "CREATE_SEARCH_HISTORY_SUCCESS";
export const CREATE_SEARCH_HISTORY_FAIL = "CREATE_SEARCH_HISTORY_FAIL";

export const TOGGLE_FOLLOW_SEARCH_HISTORY_REQUEST =
  "TOGGLE_FOLLOW_SEARCH_HISTORY_REQUEST";
export const TOGGLE_FOLLOW_SEARCH_HISTORY_SUCCESS =
  "TOGGLE_FOLLOW_SEARCH_HISTORY_SUCCESS";
export const TOGGLE_FOLLOW_SEARCH_HISTORY_FAIL =
  "TOGGLE_FOLLOW_SEARCH_HISTORY_FAIL";


export const SEARCH_DELETE_REQUEST = 'SEARCH_DELETE_REQUEST'
export const SEARCH_DELETE_SUCCESS = 'SEARCH_DELETE_SUCCESS'
export const SEARCH_DELETE_FAIL = 'SEARCH_DELETE_FAIL'
