import React from "react";
import PrivacyAndPolicyPrototype from "@shared/molecules/PrivacyAndPolicyPrototype"
import DesktopPrototype from "@components/organisms/DesktopPrototype";
import { isMobile } from "react-device-detect";

import "./index.less";

export const Privacy = () => {



    return (
        <>
            {isMobile ? <PrivacyAndPolicyPrototype /> :
                <DesktopPrototype>
                    <PrivacyAndPolicyPrototype />
                </DesktopPrototype>}
        </>
    );
};
