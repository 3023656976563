import React from "react";
import { useTranslation } from "react-i18next";

import "./index.less";

const TempDown = () => {
  const { t } = useTranslation();
  return (
    <div className="temp-down-page">
      <section class="content">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="355.135"
          height="85"
          viewBox="0 0 355.135 85"
        >
          <defs></defs>
          <g transform="translate(-289.108 -330.21)">
            <path
              class="a"
              d="M545.154,364.683c4.77-6.513,11.282-8.66,18.909-8.173a21.617,21.617,0,0,1,11.959,4.289,22.408,22.408,0,0,1,8.1,12.9,34,34,0,0,1,1.014,8.331q.018,13.17,0,26.341a8.459,8.459,0,0,1-.571,3.307,4.145,4.145,0,0,1-2.357,2.4,7.545,7.545,0,0,1-5.7-.006,4.633,4.633,0,0,1-2.773-3.842,12.328,12.328,0,0,1-.155-2.221q0-12.7-.01-25.405a18.336,18.336,0,0,0-1.411-7.291,12.34,12.34,0,0,0-10.218-7.638,16.327,16.327,0,0,0-7.9.62c-4.535,1.6-7,5-8.172,9.494a25.388,25.388,0,0,0-.721,6.486q.01,11.947.006,23.894a10.871,10.871,0,0,1-.19,2.29,4.825,4.825,0,0,1-4.318,4.02,8.769,8.769,0,0,1-2.728.028,4.713,4.713,0,0,1-4.3-4.41,15.9,15.9,0,0,1-.129-2.008q-.01-35.589,0-71.178a17.42,17.42,0,0,1,.127-2.08,4.75,4.75,0,0,1,4.36-4.519,8.079,8.079,0,0,1,3.221.145c2.464.614,3.778,2.378,3.939,5.265.023.407.016.815.016,1.223q0,13.423,0,26.845Z"
              transform="translate(-182.779)"
            />
            <path
              class="a"
              d="M1387.555,465.771c-5.966,0-11.932.008-17.9-.012-.622,0-.86.053-.72.794a24.5,24.5,0,0,0,2.172,6.76,14.957,14.957,0,0,0,10.211,8.11,20.333,20.333,0,0,0,15.458-2.077,19.513,19.513,0,0,0,3-2.235,6.313,6.313,0,0,1,6.08-1.575,5.446,5.446,0,0,1,3.568,7.346,12.178,12.178,0,0,1-4.807,5.423,29.183,29.183,0,0,1-12.025,4.549,31.023,31.023,0,0,1-13.939-.5c-11.553-3.422-18.793-11.062-21.04-22.878-2.056-10.812,1.051-20.246,9.255-27.743a27.533,27.533,0,0,1,16.874-7.146,28.419,28.419,0,0,1,21.594,6.825,23.56,23.56,0,0,1,8.014,13.187,13.388,13.388,0,0,1,.183,5.231,6.848,6.848,0,0,1-5.865,5.669,16.971,16.971,0,0,1-3.157.272Q1396.036,465.768,1387.555,465.771Zm-18.107-8.927h.75q15.588,0,31.176.019c.747,0,.746-.244.6-.809a13.114,13.114,0,0,0-7.606-8.879,19.261,19.261,0,0,0-10.86-1.425,17.6,17.6,0,0,0-10.641,5.272A14.4,14.4,0,0,0,1369.448,456.844Z"
              transform="translate(-798.741 -78.196)"
            />
            <path
              class="a"
              d="M827.481,463.88a29.548,29.548,0,0,1-29.623,29.454,29.447,29.447,0,1,1,29.623-29.454Zm-29.6,17.778a17.772,17.772,0,1,0-.019-35.542,17.771,17.771,0,0,0,.019,35.542Z"
              transform="translate(-358.639 -78.166)"
            />
            <path
              class="a"
              d="M289.108,463.982c.011-14.4,9.278-26.088,23.121-29.015a30.166,30.166,0,0,1,24.547,5,7.851,7.851,0,0,1,3.365,4.368,5.3,5.3,0,0,1-1.364,5.523,5.8,5.8,0,0,1-5.823,1.368,10.834,10.834,0,0,1-3.472-1.864,18.16,18.16,0,0,0-24.243,2.645,17.185,17.185,0,0,0-3.22,17.836c2.256,6.355,6.958,10.158,13.591,11.331a18.534,18.534,0,0,0,16.3-4.657,6.285,6.285,0,0,1,5.567-1.757,5.553,5.553,0,0,1,3.929,7.575,11.406,11.406,0,0,1-3.433,4.386,27.389,27.389,0,0,1-12.709,5.787,30.2,30.2,0,0,1-19.755-2.317c-9.013-4.494-14.3-11.822-16.018-21.739A32.151,32.151,0,0,1,289.108,463.982Z"
              transform="translate(0 -78)"
            />
            <path
              class="a"
              d="M1118.38,463.95c.033-14.142,9-25.721,22.443-28.838a30.26,30.26,0,0,1,25.245,4.865,7.8,7.8,0,0,1,3.353,4.376,5.3,5.3,0,0,1-1.388,5.516,5.8,5.8,0,0,1-5.829,1.344,10.7,10.7,0,0,1-3.41-1.829,18.163,18.163,0,0,0-24.25,2.581,17.179,17.179,0,0,0-3.269,17.828c2.249,6.386,6.966,10.209,13.632,11.379a18.548,18.548,0,0,0,16.3-4.676,6.24,6.24,0,0,1,5.571-1.735,5.553,5.553,0,0,1,3.9,7.591,11.462,11.462,0,0,1-3.441,4.38,27.393,27.393,0,0,1-12.715,5.776,30.192,30.192,0,0,1-19.947-2.427c-8.874-4.51-14.09-11.787-15.8-21.587A31.922,31.922,0,0,1,1118.38,463.95Z"
              transform="translate(-620.245 -77.993)"
            />
            <path
              class="a"
              d="M1038.733,468.36q0-11,0-22a10.545,10.545,0,0,1,.43-3.273,4.335,4.335,0,0,1,3.362-3.119,8.379,8.379,0,0,1,4.206.105c2.218.642,3.221,2.293,3.535,4.45a15.072,15.072,0,0,1,.132,2.157q.011,21.752.009,43.5a13.547,13.547,0,0,1-.152,2.156,4.745,4.745,0,0,1-4.366,4.287,8.843,8.843,0,0,1-3.3-.088c-2.372-.523-3.649-2.231-3.837-5.088-.027-.407-.021-.817-.021-1.226Q1038.732,479.29,1038.733,468.36Z"
              transform="translate(-560.657 -82.133)"
            />
            <path
              class="b"
              d="M1636.5,530.248a7.733,7.733,0,1,1,8,7.389A7.648,7.648,0,0,1,1636.5,530.248Z"
              transform="translate(-1007.728 -143.702)"
            />
            <path
              class="b"
              d="M1644.191,453.387a7.726,7.726,0,1,1,7.775-7.722A7.71,7.71,0,0,1,1644.191,453.387Z"
              transform="translate(-1007.73 -80.641)"
            />
            <path
              class="b"
              d="M1644.238,606.612a7.726,7.726,0,1,1-7.754,7.663A7.724,7.724,0,0,1,1644.238,606.612Z"
              transform="translate(-1007.73 -206.911)"
            />
            <path
              class="a"
              d="M1033.525,357.289c-.023-4.208,2.854-7.062,7.129-7.07a7.218,7.218,0,0,1,6.913,6.944,7.1,7.1,0,0,1-6.827,7.369C1036.253,364.555,1033.55,361.841,1033.525,357.289Z"
              transform="translate(-556.764 -14.962)"
            />
          </g>
        </svg>
        <h1 class="title">{t("we_will_be_right_back")}</h1>
        <p>{t("temp_down_text")}</p>
      </section>
    </div>
  );
};
export default TempDown;
