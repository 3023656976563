import React, { useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  deletePlaylist,
  updatePlaylist,
} from "@redux/actions/playlistsActions";
import { Context } from "@context/context";
import { Title, Paragraph } from "@shared/atoms/Typography/index";
import useDetectMobile from "@src/hooks/useDetectMobile";
import List from "@shared/atoms/List/index";
import Image from "@shared/atoms/Image/index";
import playButtonsIcon from "@assets/icons/play-buttons.svg";
import KebabMenuPopup from "../../../../organisms/KebabMenuPopup";
import KebabMenu from "@shared/atoms/icons/KebabMenu";
import PopupItem from "../PopupItem";
import CheckMark from "@shared/atoms/icons/CheckMark";
import Plus from "@shared/atoms/icons/Plus";
import Share from "@shared/atoms/icons/Share";
import Settings from "@shared/atoms/icons/Settings";
import Trash from "@shared/atoms/icons/Trash";
import Dot from "@shared/atoms/Dot";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./index.less";
import { METHODS } from "const/apiMethods";

const FavoritesItem = (
  {
    active,
    isFinished,
    bgClass,
    additionalClasses,
    playlistName,
    deleteItem = true,
    videosCount = 0,
    kebabMenu = true,
    playlist,
    currentUserId,
    ...rest
  },
  ref
) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isUserPlaylist, setIsUserPlaylist] = useState(true);
  const dispatch = useDispatch();

  const {
    setShowFavoritesVideos,
    setShowAddNewVideoModal,
    setShowPlaylistSettingsModal,
    setShowShareModal,
    setSelectedPlaylist,
    setClearSelectedPlaylist,
  } = useContext(Context);

  const isMobile = useDetectMobile();
  const history = useHistory();

  const { t } = useTranslation();

  const deletePlaylistHandler = (selectedPlaylist) => {
    Promise.resolve(dispatch(deletePlaylist(selectedPlaylist.id))).then(() => {
      setShowFavoritesVideos(false);
      history.push("/playlists");
    });
  };

  const kebabMenuHandler = (e) => {
    setShowDropdown(!showDropdown);
    if (isMobile) {
      e.stopPropagation();
    }
  };

  const playlistSettingsHandler = () => {
    setShowPlaylistSettingsModal(true);
  };

  const addVideoModalHandler = (e) => {
    setShowAddNewVideoModal(true);
    if (isMobile) {
      e.stopPropagation();
      setSelectedPlaylist(playlist);
      setClearSelectedPlaylist(true);
    }
  };

  const finishPlaylistHandler = (selectedPlaylist) => {
    const data = {
      _method: METHODS.PATCH,
    };

    if (selectedPlaylist.finished === 0) {
      data["finished"] = 1;
      Promise.resolve(dispatch(updatePlaylist(data, selectedPlaylist.id))).then(
        () => {
          history.push("/playlists");
        }
      );
    } else {
      data["finished"] = 0;

      Promise.resolve(dispatch(updatePlaylist(data, selectedPlaylist.id))).then(
        () => {
          history.push("/playlists");
        }
      );
    }
  };

  useEffect(() => {
    if (currentUserId) {
      setIsUserPlaylist(currentUserId === playlist?.user_id);
    }
  }, [currentUserId, playlist]);

  // const defaultIcon = playlist?.icon?.includes("defaultIcon");

  return (
    <div
      className={`favorites-list-item-container ${additionalClasses || ""} ${
        bgClass ? bgClass : ""
      }`}
      {...rest}
      ref={ref}
    >
      <List.Item>
        <div className={`icon-container ${isMobile ? "light-bg" : ""}`}>
          <Image
            className="play-buttons-menu"
            src={
              playlist?.icon?.includes("defaultIcon") || !playlist?.icon
                ? playButtonsIcon
                : playlist?.icon
            }
            alt="playButtonsIcon"
          />
        </div>
        <div>
          <Title level={2} className="title">
            {playlistName}
          </Title>
          <div className="playlist-statistic-container">
            <Paragraph className="para">{videosCount} videos</Paragraph>
            <Dot />
            <Paragraph className="para">{playlist?.views} views</Paragraph>
          </div>
        </div>
        <div>
          {kebabMenu && (
            <KebabMenu className="kebab-menu" onClick={kebabMenuHandler} />
          )}

          {showDropdown && (
            <KebabMenuPopup setShowDropdown={setShowDropdown}>
              <PopupItem
                icon={<Plus />}
                allowed={isUserPlaylist}
                onClick={(e) => addVideoModalHandler(e)}
              >
                {t("add_video")}
              </PopupItem>
              <PopupItem
                onClick={() => {
                  setShowShareModal(true);
                }}
                icon={<Share />}
              >
                {t("share_playlist")}
              </PopupItem>
              <PopupItem
                icon={<Settings />}
                // allowed={isUserPlaylist}
                onClick={() => {
                  playlistSettingsHandler();
                }}
              >
                {t("playlist_settings")}{" "}
              </PopupItem>
              {deleteItem && (
                <PopupItem
                  icon={<Trash />}
                  allowed={isUserPlaylist}
                  onClick={() => deletePlaylistHandler(playlist)}
                >
                  {t("delete_playlist")}
                </PopupItem>
              )}
              <PopupItem
                allowed={isUserPlaylist}
                onClick={() => finishPlaylistHandler(playlist)}
                icon={<CheckMark />}
              >
                {playlist?.finished === 1
                  ? `${t("mark_playlist_as_unfinished")}`
                  : `${t("mark_playlist_as_finished")}`}
              </PopupItem>
            </KebabMenuPopup>
          )}
        </div>
      </List.Item>
    </div>
  );
};

const forwardedFavoritesItem = React.forwardRef(FavoritesItem);

export default forwardedFavoritesItem;
