import React, { forwardRef } from "react";
import ClosePlayer from "../../atoms/icons/ClosePlayer";
import CastButton from "@components/shared/molecules/CastButton";
import AirPlay from "@shared/molecules/AirPlay";
import "./index.less";

const VideoPlayerControls = ({ onClosePlayer, title, videoUrl }, ref) => {
  return (
    <div className="video-player-controls">
      <div ref={ref} className="top-controls">
        <div className="video-title">{title}</div>
        <div className="right-controls">
          <AirPlay />
          <CastButton videoUrl={videoUrl} />
          <ClosePlayer onClick={onClosePlayer} />
        </div>
      </div>
      {/* <div className="bottom-controls">bottom controls</div> */}
    </div>
  );
};

const forwarderVideoPlayerControls = forwardRef(VideoPlayerControls);

export default forwarderVideoPlayerControls;
