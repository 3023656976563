import nextIcon from "@assets/icons/arrow-right-black-bg.svg";
import prevIcon from "@assets/icons/arrow-left-black-bg.svg";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block'}}
        onClick={onClick}
      >
        <img src={nextIcon}  alt="arrow_right"/>
      </div>
    );
  }

  function SamplePrevtArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block'}}
        onClick={onClick}
      >
        <img src={prevIcon}  alt="arrow_right"/>
      </div>
    );
  }


export {
    SampleNextArrow,
    SamplePrevtArrow
};