import React from "react";
import { Card } from "@shared/atoms/Card";
import "./index.less";

const TierCardPrototype = ({ name, price, packageName }) => {
  return (
    <Card className="tier-card-prototype">
      <div class="tier-item">
        <div class="tier-name-price">
          <p>{name}</p>
          <p>€{price}</p>
        </div>
        <p class="tier-desc">{packageName}</p>
      </div>
    </Card>
  );
};

export default TierCardPrototype;
