import { changeStatusText } from "@utils/changeStatusText";
import React from "react";
import { useSelector } from "react-redux";
import "./index.less";

const GroupStatusesSelect = ({ changeStatusHandler, defaultValue, activeStatus }) => {
  const { statuses } = useSelector((state) => state.playlistStatuses);

  return (
    <select
      className="group-status-select"
      defaultValue={`${defaultValue}`}
      onChange={changeStatusHandler}
    >
      {statuses?.map((el) => (
        <option selected={el.name===activeStatus} key={el.id} value={el.name}>
          {changeStatusText(el.name)}
        </option>
      ))}
    </select>
  );
};

export default GroupStatusesSelect;
