import React, { useContext } from 'react'
import Modal from "@shared/atoms/Modal/index";
import PrivaceAndPolicyPrototype from "@shared/molecules/PrivacyAndPolicyPrototype"
import { Context } from '@context/context';

import "./index.less"

const PrivacyModalPrototype = () => {
  const { showPrivacyModalPrototype, setShowPrivacyModalPrototype } = useContext(Context)
  return (
    <Modal
      visible={showPrivacyModalPrototype}
      className='privacy-modal-prototype'
      onCancel={() => setShowPrivacyModalPrototype(false)}
      footer={<></>}
    >
      <PrivaceAndPolicyPrototype shouldCloseModal={true} />
    </Modal>
  )
}

export default PrivacyModalPrototype