import React, { useContext } from "react";
import Modal from "@shared/atoms/Modal";
import Button from "@shared/atoms/Button";
import { Paragraph } from "@shared/atoms/Typography/index";
import { useTranslation } from "react-i18next";

import "./index.less";
import { Context } from "../../../../../context/context";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePlaylist,
  getPlaylistById,
  removeSerieInGroup,
} from "../../../../../redux/actions/playlistsActions";
import { useHistory } from "react-router-dom";

const DeleteItemModalPrototype = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { playlist: playlistById } = useSelector(
    (state) => state.playlistById
  );

  const {
    setShowDeleteItemModalPrototype,
    showDeleteItemModalPrototype,
    setShowHomeContent,
    deletableItemPrototype,
    selectedPlaylist,
    deleteSerieInGroupItem,
    setShowSubBarPrototype,
  } = useContext(Context);

  const deletePlaylistVideoHandler = () => {
    Promise.resolve(
      dispatch(
        removeSerieInGroup(
          deleteSerieInGroupItem?.pivot.serie_id,
          deleteSerieInGroupItem?.id
        )
      )
    ).then(() => {
      dispatch(getPlaylistById(selectedPlaylist.id));
      setShowDeleteItemModalPrototype(false);
    });
    // Promise.resolve(dispatch(deleteSeriesGroupInPlaylist(deleteSeriesId))).then(
    //   () => {
    //     dispatch(getPlaylistById(selectedPlaylist.id));
    //     setShowDeleteItemModalPrototype(false);
    //   }
    // );
  };

  const deletePlaylistHandler = () => {
    Promise.resolve(dispatch(deletePlaylist(playlistById.data.id))).then(() => {
      setShowDeleteItemModalPrototype(false);
      history.push("/");
      setShowHomeContent(true);
      setShowSubBarPrototype(false);
    });
  };

  const callDeleteHandler = () => {
    if (deletableItemPrototype === "video") {
      deletePlaylistVideoHandler();
    }
    if (deletableItemPrototype === "playlist") {
      deletePlaylistHandler();
    }
  };

  return (
    <Modal
      className="delete-item-modal-prototype modal-basic-prototype"
      visible={showDeleteItemModalPrototype}
      onCancel={() => {
        setShowDeleteItemModalPrototype(false);
      }}
      closable={true}
      footer={[
        <footer className="footer">
          <Button
            key="delete"
            onClick={callDeleteHandler}
            className="response-buttons-prototype blue"
          >
            {t("delete")}
          </Button>
          <Button
            key="cancel"
            onClick={() => setShowDeleteItemModalPrototype(false)}
            className="response-buttons-prototype gray"
          >
            {t("cancel")}
          </Button>
        </footer>,
      ]}
    >
      <Paragraph> {t("are_you_sure_you_want_to_delete_this_item")}?</Paragraph>
    </Modal>
  );
};

export default DeleteItemModalPrototype;
