import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  movePlaylistVideo,
  getPlaylists
} from "@redux/actions/playlistsActions";
import useDetectMobile from "@src/hooks/useDetectMobile";
import { Context } from "@context/context";
import Modal from "@shared/atoms/Modal";
import Input from "@shared/atoms/Input";
import Form from "@shared/atoms/Form";
import Alert from "@shared/atoms/Alert/index";
import CloseModalIcon from "@shared/atoms/icons/CloseModal";
import { formLayout } from "@modules/config";
import FavoritesList from "../../lists/FavoritesList";
import FavoritesItem from "@shared/molecules/listItems/FavoritesItem";
import { useTranslation } from "react-i18next";

import "./index.less";

const PlaylistMoveToModal = ({ moveToData }) => {
  const isMobile = useDetectMobile();

  const [playlists, setPlaylists] = useState([]);
  const [search, setSearch] = useState("");

  const { t } = useTranslation();

  const {
    showMoveToPlaylistModal,
    setShowMoveToPlaylistModal,
    selectedVideo
  } = useContext(Context);

  const dispatch = useDispatch();

  const {
    playlists: reducerPlaylists
    // loading,
    // error,
  } = useSelector((state) => state.playlists);

  const {
    // loading: movePlaylistLoading,
    // message: movePlaylistMessage,
    error: movePlaylistError
  } = useSelector((state) => state.movePlaylistVideo);

  useEffect(() => {
    if (movePlaylistError) {
      setShowMoveToPlaylistModal(true);
    }
  }, [movePlaylistError, setShowMoveToPlaylistModal]);

  useEffect(() => {
    if (!reducerPlaylists && showMoveToPlaylistModal) {
      dispatch(getPlaylists());
    }
    setPlaylists(reducerPlaylists);
  }, [reducerPlaylists, dispatch, showMoveToPlaylistModal]);

  const moveVideoHandler = (playlist) => {
    let newPlaylistId = playlist.id;
    if (!isMobile) {
      let videoId = selectedVideo.id,
        oldPlaylistId = selectedVideo.pivot.playlist_id;

      dispatch(movePlaylistVideo(videoId, oldPlaylistId, newPlaylistId));
    } else {
      const { videoId, oldPlaylistId } = moveToData;

      dispatch(movePlaylistVideo(videoId, oldPlaylistId, playlist.id));
    }

    handleClose();
  };

  const handleClose = () => {
    setShowMoveToPlaylistModal(false);
  };

  useEffect(() => {
    if (showMoveToPlaylistModal) {
      setPlaylists((state) => {
        let newPlaylist = { ...state };

        newPlaylist.favorites = reducerPlaylists.favorites.filter(
          (item) =>
            item.name.toLowerCase().includes(search.toLowerCase())
        );
        newPlaylist.finished = reducerPlaylists.finished.filter(
          (item) =>
            item.name.toLowerCase().includes(search.toLowerCase())
        );
        newPlaylist.unfinished = reducerPlaylists.unfinished.filter(
          (item) =>
            item.name.toLowerCase().includes(search.toLowerCase())
        );
        newPlaylist.watchLater = reducerPlaylists.watchLater.filter(
          (item) =>
            item.name.toLowerCase().includes(search.toLowerCase())
        );

        return newPlaylist;
      });
    }
  }, [search, reducerPlaylists, showMoveToPlaylistModal]);

  return (
    <Modal
      title={t("choose_playlist")}
      closable={true}
      visible={showMoveToPlaylistModal}
      onCancel={handleClose}
      className="move-to-video-modal"
      closeIcon={<CloseModalIcon />}
      footer={[<></>]}
    >
      {movePlaylistError && (
        <Alert
          message={movePlaylistError?.data?.message}
          type="error"
        />
      )}
      {showMoveToPlaylistModal && (
        <Form layout={formLayout} className="playlist-move-modal">
          <div className="playlists-list">
            <Form.Item label={t("search")}>
              <Input
                onChange={(e) => setSearch(e.target.value)}
                placeholder={t("search_for_playlist")}
              />
            </Form.Item>

            <FavoritesList>
              <>
                {playlists?.unfinished?.map(playlist => (
                  <FavoritesItem
                    kebabMenu={false}
                    key={playlist.id}
                    videosCount={playlist.videos.length}
                    playlistName={playlist.name}
                    playlist={playlist}
                    onClick={() =>
                      moveVideoHandler(playlist)
                    }
                  ></FavoritesItem>
                ))}
                {playlists?.finished?.map((publicPlaylist) => (
                  <FavoritesItem
                    kebabMenu={false}
                    key={publicPlaylist.id}
                    videosCount={publicPlaylist.videos.length}
                    playlistName={publicPlaylist.name}
                    playlist={publicPlaylist}
                    onClick={() =>
                      moveVideoHandler(publicPlaylist)
                    }
                  ></FavoritesItem>
                ))}
              </>
            </FavoritesList>
            {playlists.length && (
              <>
                <FavoritesItem
                  kebabMenu={false}
                  playlistName="Favorites"
                  deleteItem={false}
                  playlist={playlists.favorites[0]}
                  onClick={() =>
                    moveVideoHandler(playlists.favorites[0])
                  }
                  videosCount={
                    playlists?.favorites[0]?.videos?.length
                  }
                ></FavoritesItem>
                <FavoritesList>
                  <FavoritesItem
                    kebabMenu={false}
                    deleteItem={false}
                    playlist={playlists.watchLater[0]}
                    playlistName={`${t("watch_later")}`}
                    onClick={() =>
                      moveVideoHandler(
                        playlists.watchLater[0]
                      )
                    }
                    videosCount={
                      playlists?.watchLater[0]?.videos
                        ?.length
                    }
                  ></FavoritesItem>

                  {playlists?.unfinished.map(
                    (privatePlaylist) => (
                      <FavoritesItem
                        key={privatePlaylist.id}
                        kebabMenu={false}
                        playlistName={
                          privatePlaylist.name
                        }
                        playlist={privatePlaylist}
                        videosCount={
                          privatePlaylist.videos
                            .length
                        }
                        onClick={() =>
                          moveVideoHandler(
                            privatePlaylist
                          )
                        }
                      ></FavoritesItem>
                    )
                  )}
                </FavoritesList>
              </>
            )}
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default PlaylistMoveToModal;
