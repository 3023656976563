import PopupWithCustomFooter from "../PopupWIthCustomFooter";

const PopupWithButtonAndSubText = ({
  btnText,
  subText,
  onBtnClick,
  onSubTextClick,
  ...rest
}) => {
  return (
    <PopupWithCustomFooter
      {...rest}
      footer={
        <div className="custom-footer-container">
          <button className="modal-button" onClick={onBtnClick}>
            {btnText}
          </button>
          <p onClick={onSubTextClick}>{subText}</p>
        </div>
      }
    />
  );
};

export default PopupWithButtonAndSubText;
