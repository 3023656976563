import React from "react";
import Modal from "@shared/atoms/Modal/index";
import { Paragraph } from "@shared/atoms/Typography/index";
import Button from "@shared/atoms/Button";
import CloseModalIcon from "@shared/atoms/icons/CloseModal";
import { useTranslation } from "react-i18next";

import "./index.less";

const EnterProfileInfoModal = ({visible, onOk, onCancel}) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={"Enter Profile info"}
            className="profile-info-modal"
            visible={visible}
            closable={true}
            closeIcon={<CloseModalIcon />}
            onCancel={onCancel}
            footer={[
                <Button onClick={onOk} className="enter-profile-btn" type="primary" key="ok">
                    {t("ok")}
                </Button>,
                <Button onClick={onCancel} className="enter-profile-btn" key="cancel">
                    {t("cancel")}
                </Button>,
            ]}
        >
            <Paragraph className="para">
                Enter profile info to get access for subscriptions
            </Paragraph>
        </Modal>
    );
};

export default EnterProfileInfoModal;
