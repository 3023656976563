import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fillRequiredFields } from "@redux/actions/userActions";
import { Context } from "@context/context";
import Modal from "@shared/atoms/Modal";
import Button from "@shared/atoms/Button";
import Form from "@shared/atoms/Form/index";
import Input from "@shared/atoms/Input/index";
import { formLayout } from "@modules/config";
import Alert from "@shared/atoms/Alert/index";
import { useTranslation } from "react-i18next";

import "./index.less";

const RequiredFieldsModal = () => {
    const [nickname, setNickName] = useState("");

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { message, loading, error } = useSelector(
        (state) => state.userRequiredFields
    );

    const { showRequiredFieldsModal, setShowRequiredFieldsModal } =
        useContext(Context);

    const submitRequiredFields = () => {
        dispatch(fillRequiredFields(nickname));
    };

    return (
        <Modal
            className="required-fields-modal"
            title={t("fill_required_fields_to_continue")}
            visible={showRequiredFieldsModal}
            closable={false}
            footer={[
                <Button
                    onClick={submitRequiredFields}
                    disabled={loading}
                    type="primary"
                    key="Report"
                    htmlType="submit"
                >
                    {t("submit")}
                </Button>,
            ]}
        >
            <>
                {error && (
                    <Alert
                        type="error"
                        message={error.data.errors.nickname[0]}
                    />
                )}
                {message && setShowRequiredFieldsModal(false)}
                <Form layout={formLayout}>
                    <Form.Item label={t("nickname")}>
                        <Input
                            onChange={(e) => setNickName(e.target.value)}
                            placeholder={t("enter_nickname")}
                        />
                    </Form.Item>
                </Form>
            </>
        </Modal>
    );
};

export default RequiredFieldsModal;
