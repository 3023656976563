import React from "react";
import "./index.less";

// type: success - white text
//       danger - red small text
const ErrorFeedbackPrototype = ({ children, type="" , className=""}) => {

  return <div className={`error-feedback-prototype ${type} ${className}`}>{children}</div>;
};

export default ErrorFeedbackPrototype;
