import React from "react";
import "./index.less";

const Cast = ({ onClick, color, className }) => {
    /**
     *
     * @prop {string} color  - "white" | "dark"
     * @prop {function} onClick - function that executes on svg click
     * @example
     *
     * <Cast color="white" />
     *
     */
    return (
        <svg
            className={`cast-svg ${className ? className : ""}`}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            onClick={onClick}
        >
            <g className="a" transform="translate(-5360 686)">
                <rect
                    className="b"
                    width="32"
                    height="32"
                    transform="translate(5360 -686)"
                />
                <path
                    className={`${color ? color : "c"}`}
                    d="M22.818,3H3.182A2.209,2.209,0,0,0,1,5.222V8.556H3.182V5.222H22.818V20.778H15.182V23h7.636A2.209,2.209,0,0,0,25,20.778V5.222A2.209,2.209,0,0,0,22.818,3ZM1,19.667V23H4.273A3.3,3.3,0,0,0,1,19.667Zm0-4.444v2.222A5.508,5.508,0,0,1,6.455,23H8.636A7.7,7.7,0,0,0,1,15.222Zm0-4.444V13a9.911,9.911,0,0,1,9.818,10H13A12.113,12.113,0,0,0,1,10.778Z"
                    transform="translate(5363 -683)"
                />
            </g>
        </svg>
    );
};

export default Cast;
