export const COPIED_TIRES_REQUEST = "COPIED_TIRES_REQUEST";
export const COPIED_TIRES_SUCCESS = "COPIED_TIRES_SUCCESS";
export const COPIED_TIRES_FAIL = "COPIED_TIRES_FAIL";

export const ARRAGNE_SHORTCUTS_REQUEST = "ARRAGNE_SHORTCUTS_REQUEST"
export const ARRAGNE_SHORTCUTS_SUCCESS = "ARRAGNE_SHORTCUTS_SUCCESS"
export const ARRAGNE_SHORTCUTS_FAIL = "ARRAGNE_SHORTCUTS_FAIL"

export const DELETE_SHORTCUTS_REQUEST = "DELETE_SHORTCUTS_REQUEST"
export const DELETE_SHORTCUTS_SUCCESS = "DELETE_SHORTCUTS_SUCCESS"
export const DELETE_SHORTCUTS_FAIL = "DELETE_SHORTCUTS_FAIL"

export const ARRAGNE_SHORTCUTS = "ARRAGNE_SHORTCUTS";