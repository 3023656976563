import React from "react";
import Button from "@shared/atoms/Button/index";
import { Link } from "react-router-dom";
import LogoWhite from "@assets/images/choice-logo-white.svg";
import "./index.less";
import Image from "@shared/atoms/Image/index";
import LoginGreeting from "@shared/molecules/LoginGreeting";
import RegisterGreeting from "@shared/molecules/RegisterGreeting";
import { useTranslation } from "react-i18next";

const AuthShowcase = ({ isRegistrationPage }) => {
    const { t } = useTranslation();
    return (
        <div className="auth-showcase">
            <Image
                className="choice-logo white-logo"
                src={LogoWhite}
                alt="white-logo"
            />
            <div>
                {isRegistrationPage ? <LoginGreeting /> : <RegisterGreeting />}
                {isRegistrationPage ? (
                    <Button className="register-button btn-dark">
                        <Link to="/login">{t("login")}</Link>
                    </Button>
                ) : (
                    <Button className="register-button btn-dark">
                        <Link to="/register">{t("register")}</Link>
                    </Button>
                )}
            </div>
        </div>
    );
};

export default AuthShowcase;
