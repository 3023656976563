import { localStorageService } from "@modules/services/localStorage.service";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./index.less";

const AcceptCookiesModal = ({ close }) => {
  const { t } = useTranslation();

  function acceptCookies() {
    localStorageService().set("cookies", "ok");
    close();
  }

  useEffect(() => {
    localStorageService().set("redirected_from", "/");
  }, []);

  return (
    <aside className="accept-cookies">
      <div className="accept-cookies__close" onClick={acceptCookies}></div>
      <div className="accept-cookies__content">
        {t("i-agree-to-the")} <Link to="terms">Terms of Use</Link>{" "}
        {t("and-the")}
        <Link to="privacy">Privacy Policy</Link> {t("of-choice")}
      </div>
      <button className="accept-cookies__button" onClick={acceptCookies}>
        {t("agree")}
      </button>
    </aside>
  );
};

export default AcceptCookiesModal;
