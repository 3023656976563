import React from "react";
import MainLayout from "../../layout/MainLayout";
import TeamsPrototype from "../../components/organisms/TeamsPrototype";
import DesktopPrototype from "@components/organisms/DesktopPrototype";
import { localStorageService } from "../../../modules/services/localStorage.service";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";


export const Teams = () => {
  const history = useHistory();
  if (!localStorageService().get("access_token")) history.push("/");

  return (
    <>
      {!isMobile ? (
        <DesktopPrototype>
          <TeamsPrototype />
        </DesktopPrototype>
      ) : (
        <MainLayout>
          <TeamsPrototype />
        </MainLayout>
      )}
    </>
  );
};
