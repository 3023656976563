import { CHOICE_URL } from "@modules/config";
import { axiosInstance } from "@modules/request/api";

export const requestVanityPlaylist = (slug) => async (dispatch) => {
  try {
    const { data } = await axiosInstance().get(`
      ${CHOICE_URL}/api/playlists/slug/${slug}
    `);
    return data.data;
  } catch (error) {
    return Promise.reject(error?.response?.data?.error);
  }
};
