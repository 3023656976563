export const GET_HOME_CONTENT_REQUEST = "GET_HOME_CONTENT_REQUEST";
export const GET_HOME_CONTENT_SUCCESS = "GET_HOME_CONTENT_SUCCESS";
export const GET_HOME_CONTENT_FAIL = "GET_HOME_CONTENT_FAIL";

export const GET_HOME_GLOBE_REQUEST = "GET_HOME_GLOBE_REQUEST";
export const GET_HOME_GLOBE_SUCCESS = "GET_HOME_GLOBE_SUCCESS";
export const GET_HOME_GLOBE_FAIL = "GET_HOME_GLOBE_FAIL";

export const GET_HOME_HEART_REQUEST = "GET_HOME_HEART_REQUEST";
export const GET_HOME_HEART_SUCCESS = "GET_HOME_HEART_SUCCESS";
export const GET_HOME_HEART_FAIL = "GET_HOME_HEART_FAIL";

export const GET_HOME_TIERS_REQUEST = "GET_HOME_TIERS_REQUEST";
export const GET_HOME_TIERS_SUCCESS = "GET_HOME_TIERS_SUCCESS";
export const GET_HOME_TIERS_FAIL = "GET_HOME_TIERS_FAIL";

export const GET_HOME_USER_REQUEST = "GET_HOME_USER_REQUEST";
export const GET_HOME_USER_SUCCESS = "GET_HOME_USER_SUCCESS";
export const GET_HOME_USER_FAIL = "GET_HOME_USER_FAIL";

export const GET_HOME_DIRECT_REQUEST = "GET_HOME_DIRECT_REQUEST";
export const GET_HOME_DIRECT_SUCCESS = "GET_HOME_DIRECT_SUCCESS";
export const GET_HOME_DIRECT_FAIL = "GET_HOME_DIRECT_FAIL";

export const GET_HOME_WATCH_LATER_REQUEST = "GET_HOME_WATCH_LATER_REQUEST";
export const GET_HOME_WATCH_LATER_SUCCESS = "GET_HOME_WATCH_LATER_SUCCESS";
export const GET_HOME_WATCH_LATER_FAIL = "GET_HOME_WATCHLATER_FAIL";

export const TOGGLE_WELCOME_MESSAGE_REQUEST = "TOGGLE_WELCOME_MESSAGE_REQUEST";
export const TOGGLE_WELCOME_MESSAGE_SUCCESS = "TOGGLE_WELCOME_MESSAGE_SUCCESS";
export const TOGGLE_WELCOME_MESSAGE_FAIL = "TOGGLE_WELCOME_MESSAGE_FAIL";
