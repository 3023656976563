import React from "react";
import AntdImage from "antd/lib/image/index";

import "./index.less";

const Image = ({ ...rest }) => {
  return <AntdImage {...rest} loading="lazy" />;
};
Image.defaultProps = {
  preview: false,
};
export default Image;
