import "./index.less";

const CloseModal = ({ className,onClick }) => {
    return (
        <svg
            onClick={onClick}
            className={`close-modal-icon ${className ? className : ""}`}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <defs>
                <clipPath id="a">
                    <path
                        className="a"
                        d="M10,20A10,10,0,1,1,20,10,10.011,10.011,0,0,1,10,20ZM10,2a8,8,0,1,0,8,8A8.009,8.009,0,0,0,10,2Zm2,11a.99.99,0,0,1-.707-.292L10,11.414,8.707,12.707a1,1,0,0,1-1.414,0,1,1,0,0,1,0-1.414L8.586,10,7.293,8.707A1,1,0,1,1,8.707,7.293L10,8.586l1.293-1.293a1,1,0,1,1,1.414,1.414L11.414,10l1.293,1.293A1,1,0,0,1,12,13Z"
                        transform="translate(2 2)"
                    />
                </clipPath>
            </defs>
            <g transform="translate(-11389 6401)">
                <rect
                    className="b"
                    width="24"
                    height="24"
                    transform="translate(11389 -6401)"
                />
                <g transform="translate(11389 -6401)">
                    <path
                        className="a"
                        d="M10,20A10,10,0,1,1,20,10,10.011,10.011,0,0,1,10,20ZM10,2a8,8,0,1,0,8,8A8.009,8.009,0,0,0,10,2Zm2,11a.99.99,0,0,1-.707-.292L10,11.414,8.707,12.707a1,1,0,0,1-1.414,0,1,1,0,0,1,0-1.414L8.586,10,7.293,8.707A1,1,0,1,1,8.707,7.293L10,8.586l1.293-1.293a1,1,0,1,1,1.414,1.414L11.414,10l1.293,1.293A1,1,0,0,1,12,13Z"
                        transform="translate(2 2)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default CloseModal;
