import React from "react";
import MainLayout from "../../layout/MainLayout";
import StatisticsPrototype from "../../components/organisms/StatisticsPrototype";
import DesktopPrototype from "@components/organisms/DesktopPrototype";
import { localStorageService } from "../../../modules/services/localStorage.service";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";



export const Statistics = () => {
  const history = useHistory();
  if (!localStorageService().get("access_token")) history.push("/my-playlists");

  return (
    <>
      {!isMobile ? (
        <DesktopPrototype>
          <StatisticsPrototype />
        </DesktopPrototype>
      ) : (
        <MainLayout>
          <StatisticsPrototype />
        </MainLayout>
      )}
    </>
  );
};
