import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  arrangeArchive,
  arrangePlaylistsInsideGroup,
  arrangeNavbarPlaylist,
  arrangePlaylistSeriesLocally,
  arrangeSeriesInGroupLocally,
  arrangeSeriesInGroupLocallyNoSortMode,
  addPlaylistInGroup,
  takeOutPlaylistFromGroup,
  movePlaylistfromGroupToPublic,
  arrangeFollowedPlaylists,
  arrangeFollowedPlaylistsLocal,
  arrangePlaylists,
} from "@redux/actions/playlistsActions";

import { useContext } from "react";
import { Context } from "./context";
import { selectPlaylists } from "@redux/reducers/playlistsReducer";
import { arrangeShortCuts } from "@redux/actions/shortcutActions";
import { moveArray } from "@utils/moveArray";
import { PLAYLISTS_SUCCESS } from "@redux/constants/playlistsConstants";
import {
  reArangeGroupPlaylists,
  reArrangeGroup,
} from "@redux/actions/groupActions";

export const DroppableTypes = {
  ArrangeMode: "ArrangeMode",
  MyPlaylistLeft: "MyPlaylistLeft",
  ArrangeTierMode: "ArrangeTierMode",
  SorterMode: "SorterMode",
  SorterModeInnerVideos: "SorterModeInnerVideos",
  MoveItems: "MoveItems",
  Follow: "Follow",
  Header: "Header",
  Public: "Public",
  ArrangeGroupMode: "ArrangeGroupMode",
  Group: "Group",
  GroupItem: "GroupItem",
  Archive: "Archive",
  ShortCut: "ShortCut",
};

const getFromTo = (result) => ({
  from: +result.source.index,
  to: +result.destination.index,
});

const parseDroppableType = (str) => str.split(":")[0];
const parseId = (str) => +str.split(":")[1];
const getStatusId = (stArr, stName) =>
  stArr.find(({ name }) => name === stName)?.id;

const DragDropContextPrototype = ({ children }) => {
  const {
    setIsPlaylistArrangeMode,
    setIsItemsArrangeMode,
    setHeaderPosition,
    setHeaderDragStarted,
    enableCombine,
  } = useContext(Context);

  const dispatch = useDispatch();
  const { playlists } = useSelector(selectPlaylists);
  const { statuses } = useSelector((state) => state.playlistStatuses);
  const { playlists: followingPlaylists } = useSelector(
    (state) => state.following
  );

  const leftPlaylistLength = playlists?.other?.filter(
    ({ is_left }) => is_left
  ).length;

  const mapDroppableToFunction = {
    [DroppableTypes.Group]: (result) => {
      const { destination, source } = result;

      if (source.droppableId === DroppableTypes.Group) {
        if (destination && source) {
          const sourceIndex = source.index;
          const targetIndex = destination.index;

          const groupIds = playlists?.groups?.map((group) => group.id);

          const groupsArr = [...playlists?.groups];

          const movedArray = moveArray(groupsArr, sourceIndex, targetIndex);

          dispatch({
            type: PLAYLISTS_SUCCESS,
            payload: { ...playlists, groups: movedArray },
          });

          const moveGroupIds = moveArray(groupIds, sourceIndex, targetIndex);

          dispatch(reArrangeGroup(moveGroupIds));
        }
      }
    },
    [DroppableTypes.Public]: (result) => {
      const { destination, source, draggableId } = result;

      if (parseDroppableType(source.droppableId) === DroppableTypes.Public) {
        if (destination && source) {
          const sourceIndex = source.index;
          const targetIndex = destination.index;

          const playlistIds = playlists?.public?.map((playlist) => playlist.id);

          const publicPlaylistArr = [...playlists.public];

          const movedArray = moveArray(
            publicPlaylistArr,
            sourceIndex,
            targetIndex
          );

          dispatch({
            type: PLAYLISTS_SUCCESS,
            payload: { ...playlists, public: movedArray },
          });

          const movePlaylistIds = moveArray(
            playlistIds,
            sourceIndex,
            targetIndex
          );

          dispatch(arrangePlaylists(movePlaylistIds));
        }
      }
      if (parseDroppableType(source.droppableId) === DroppableTypes.GroupItem) {
        const privatePaylistStatusId = getStatusId(statuses, "Public");

        /// groupp itemm

        if (destination) {
          dispatch(
            movePlaylistfromGroupToPublic({
              from: source.index,
              to: destination.index,
              groupId: parseId(source.droppableId),
              playlistId: draggableId,
              statusId: privatePaylistStatusId,
              archiveLength: playlists.archive.length,
              otherPlaylistLength: playlists.other.length,
            })
          );
        }
      }
      if (!destination) {
        enableCombine(result);
      }
    },
    [DroppableTypes.GroupItem]: (result) => {
      const { destination, source, draggableId } = result;

      if (
        [
          DroppableTypes.ArrangeMode,
          DroppableTypes.MyPlaylistLeft,
          DroppableTypes.Public,
        ].includes(source.droppableId)
      ) {
        dispatch(
          addPlaylistInGroup({
            from: source.index,
            to: destination.index,
            groupId: parseId(destination.droppableId),
            playlistId: draggableId,
            isFromLeftPl: DroppableTypes.MyPlaylistLeft === source.droppableId,
          })
        );
      }

      if (parseDroppableType(source.droppableId) === DroppableTypes.GroupItem) {
        const currentGroup = playlists.groups.find(
          (item) => item.id === parseId(destination.droppableId)
        );
        const currentGroupPlaylists = currentGroup.playlists;

        const groupIndex = playlists.groups.findIndex(
          (item) => item.id === parseId(destination.droppableId)
        );

        const movedPlaylists = moveArray(
          [...currentGroupPlaylists],
          source.index,
          destination.index
        );

        const currentChangedGroup = {
          ...currentGroup,
          playlists: movedPlaylists,
        };

        const movedPlaylistsInGroup = Object.assign([], playlists.groups, {
          [groupIndex]: { ...currentChangedGroup },
        });

        dispatch({
          type: PLAYLISTS_SUCCESS,
          payload: { ...playlists, groups: movedPlaylistsInGroup },
        });

        const playlistIdsArr = movedPlaylists.map((playlist) => playlist.id);
        dispatch(reArangeGroupPlaylists(currentGroup.id,playlistIdsArr))
      }
    },
    [DroppableTypes.Archive]: (result) => {
      const { destination, source, draggableId } = result;
      if (destination.droppableId === source.droppableId) {
        dispatch(
          arrangeArchive({
            from: source.index,
            to: destination.index,
            playlistId: draggableId,
          })
        );
      } else if (
        [DroppableTypes.ArrangeMode, DroppableTypes.MyPlaylistLeft].includes(
          source.droppableId
        )
      ) {
        dispatch(
          arrangeArchive({
            from: source.index,
            to: destination.index,
            playlistId: draggableId,
            movedIn: true,
          })
        );
      }
    },
    [DroppableTypes.MyPlaylistLeft]: (result) => {
      const { destination, source, draggableId } = result;

      if (source.droppableId === DroppableTypes.Archive) {
        dispatch(
          arrangeNavbarPlaylist({
            leftPlaylistLength,
            archiveLength: playlists.archive.length,
            from: source.index,
            to: destination.index,
            playlistId: draggableId,
            is_left: true,
            fromArchive: true,
          })
        );
        return;
      }

      if (parseDroppableType(source.droppableId) === DroppableTypes.GroupItem) {
        const privatePaylistStatusId = getStatusId(statuses, "Private");

        dispatch(
          takeOutPlaylistFromGroup({
            from: source.index,
            to: destination.index,
            playlistId: draggableId,
            groupId: parseId(source.droppableId),
            statusId: privatePaylistStatusId,
            archiveLength: playlists.archive.length,
          })
        );
        return;
      }

      if (destination && source) {
        const sourceIndex = source.index;
        const targetIndex = destination.index;

        const playlistIds = playlists?.other?.map((playlist) => playlist.id);

        const otherPlaylistArr = [...playlists.other];

        const movedArray = moveArray(
          otherPlaylistArr,
          sourceIndex,
          targetIndex
        );

        dispatch({
          type: PLAYLISTS_SUCCESS,
          payload: { ...playlists, other: movedArray },
        });

        const movePlaylistIds = moveArray(
          playlistIds,
          sourceIndex,
          targetIndex
        );

        dispatch(arrangePlaylists(movePlaylistIds));
      }

      // if (destination) {
      //   dispatch(
      //     arrangeNavbarPlaylist({
      //       leftPlaylistLength,
      //       archiveLength: playlists.archive.length,
      //       from: source.index,
      //       to: destination.index,
      //       playlistId: +draggableId,
      //       is_left: true,
      //     })
      //   );
      // }
      else {
        enableCombine(result);
      }
    },
    [DroppableTypes.ArrangeMode]: (result) => {
      const { destination, source, draggableId } = result;

      const leftPlaylistLength = playlists.other.filter(
        ({ is_left }) => is_left
      ).length;
      const hasMovedToRightPl =
        destination && destination.droppableId !== source.droppableId;

      if (source.droppableId === DroppableTypes.Archive) {
        dispatch(
          arrangeNavbarPlaylist({
            leftPlaylistLength,
            archiveLength: playlists.archive.length,
            publicPlaylistLength: playlists.public.length,
            from: source.index,
            to: destination.index,
            playlistId: draggableId,
            is_left: false,
            fromArchive: true,
          })
        );
        return;
      }

      if (parseDroppableType(source.droppableId) === DroppableTypes.GroupItem) {
        const privatePaylistStatusId = getStatusId(statuses, "Private");

        dispatch(
          takeOutPlaylistFromGroup({
            from: source.index,
            to: destination.index,
            groupId: parseId(source.droppableId),
            publicPlaylistLength: playlists.public.length,
            playlistId: draggableId,
            movedInLeftPl: false,
            leftPlaylistLength,
            archiveLength: playlists.archive.length,
            statusId: privatePaylistStatusId,
          })
        );
        return;
      }

      if (destination) {
        const from = hasMovedToRightPl
          ? source.index
          : source.index + leftPlaylistLength;
        const to = destination.index;
        dispatch(
          arrangeNavbarPlaylist({
            leftPlaylistLength,
            publicPlaylistLength: playlists.public.length,
            archiveLength: playlists.archive.length,
            from,
            to: to,
            playlistId: draggableId,
            is_left: false,
          })
        );
      } else {
        // Is combine mode
        // console.log("isCombineMode: ", combine);
        // console.log({ source });
        // console.log(result);
        // setShowCombinePlaylistModal(true);
        enableCombine(result);
      }
    },
    [DroppableTypes.SorterMode]: (result) => {
      dispatch(
        arrangePlaylistSeriesLocally({
          ...getFromTo(result),
          seriesId: +result.draggableId,
        })
      );
      setIsPlaylistArrangeMode(false);
    },
    [DroppableTypes.SorterModeInnerVideos]: (result) => {
      dispatch(
        arrangeSeriesInGroupLocally({
          videoId: +result.draggableId,
          currentSeriesId: parseId(result.source.droppableId), // currentSeriesId + from (series id and index inside it)
          destinationId: parseId(result.destination.droppableId), // destinationId + to
          ...getFromTo(result),
        })
      );
    },
    [DroppableTypes.MoveItems]: (result) => {
      dispatch(arrangeSeriesInGroupLocallyNoSortMode(result));
      setIsItemsArrangeMode(false);
    },
    [DroppableTypes.Header]: (result) => {
      setHeaderPosition(result.destination.droppableId.split(":")[1]);
      setHeaderDragStarted(false);
    },
    [DroppableTypes.ArrangeTierMode]: (result) => {
      dispatch(
        arrangeNavbarPlaylist({
          archiveLength: playlists.archive.length,
          from: result.source.index + 1,
          to: result.destination.index + 1,
          playlistId: +result.draggableId,
        })
      );
    },
    [DroppableTypes.Follow]: (result) => {
      if (result.destination && result.source) {
        const {
          destination: { index: targetIndex },
          source: { index: sourceIndex },
        } = result;
        if (sourceIndex || targetIndex) {
          const playlistIds = followingPlaylists.playlists.map(
            (playlist) => playlist.id
          );
          const movedArray = moveArray(
            followingPlaylists.playlists,
            sourceIndex,
            targetIndex
          );
          const movePlaylistIds = moveArray(
            playlistIds,
            sourceIndex,
            targetIndex
          );
          dispatch(arrangeFollowedPlaylistsLocal(movedArray));
          dispatch(arrangeFollowedPlaylists(movePlaylistIds));
        }
      }
    },
    [DroppableTypes.ShortCut]: (result) => {
      const { destination, source, draggableId } = result;

      const payload = {
        from: source.index,
        to: destination.index,
        shortcutId: draggableId,
      };

      dispatch(arrangeShortCuts(payload));
    },
  };

  const onDragEnd = (result) => {
    const { destination, source, combine } = result;

    if (source.droppableId === DroppableTypes.MoveItems && combine === null) {
      return;
    }

    // user drops outside the list
    if (!destination && !combine) return;

    // user drop the item back into the starting position
    if (
      destination?.droppableId === source.droppableId &&
      destination?.index === source.index
    )
      return;

    if (
      parseDroppableType(destination?.droppableId || combine?.droppableId) ===
      DroppableTypes.Header
    ) {
      setHeaderDragStarted(false);
    }

    mapDroppableToFunction[
      parseDroppableType(destination?.droppableId || combine?.droppableId)
    ](result);
  };

  const onBeforeCapture = (result) => {
    if (result.draggableId === "header") {
      setHeaderDragStarted(true);
    }
  };

  return (
    <DragDropContext onBeforeCapture={onBeforeCapture} onDragEnd={onDragEnd}>
      {children}
    </DragDropContext>
  );
};

export default DragDropContextPrototype;
