import React from "react";

import "./index.less";


const MoveTo = () => {
    return (
        <svg
            className="move-to-icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g
                id="Group_5641"
                data-name="Group 5641"
                transform="translate(-2337 982)"
            >
                <rect
                    id="Rectangle_3842"
                    data-name="Rectangle 3842"
                    width="24"
                    height="24"
                    transform="translate(2337 -982)"
                    fill="none"
                    opacity="0"
                />
                <g id="ic_subscriptions_24px" transform="translate(2337 -982)">
                    <path
                        id="Path_745"
                        data-name="Path 745"
                        d="M20,8H4V6H20ZM18,2H6V4H18Zm4,10v8a2.006,2.006,0,0,1-2,2H4a2.006,2.006,0,0,1-2-2V12a2.006,2.006,0,0,1,2-2H20A2.006,2.006,0,0,1,22,12Zm-6,4-6-3.27v6.53Z"
                    />
                </g>
            </g>
        </svg>
    );
};
export default MoveTo;
