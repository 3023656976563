import React, {
  useState,
  createContext,
  useEffect,
  useRef,
  createRef,
} from "react";
import { useTranslation } from "react-i18next";
import { localStorageService } from "../modules/services/localStorage.service";
import { VIEWS_PROTOTYPE } from "@src/const/viewsPrototype";

const Context = createContext();

// needs to be modified for support translation
export const formTypeAuthPrototype = {
  empty: "",
  login: "Log in",
  register: "Register",
  forgotPassword: "Forgot Password",
};
function useCombinePlaylist() {
  const [showCombinePlaylistModal, setShowCombinePlaylistModal] =
    useState(false);
  const [combineData, setCombineData] = useState(null);

  function enableCombine(data) {
    setShowCombinePlaylistModal(true);
    setCombineData(data);
  }
  function disableCombine() {
    setShowCombinePlaylistModal(false);
    setCombineData(null);
  }
  return {
    enableCombine,
    disableCombine,
    combineData,
    showCombinePlaylistModal,
  };
}
const ContextProvider = ({ children }) => {
  const { t } = useTranslation();

  const [headerPosition, setHeaderPosition] = useState(0);
  const [headerDragStarted, setHeaderDragStarted] = useState(false);
  const [showCombinePlaylistModal, setShowCombinePlaylistModal] =
    useState(false);
  // const [combineData, setCombineData] = useState(null);

  const [currentGroupPrototype, setCurrentGroupPrototype] = useState(null)

  // function combinePlaylists() {}
  const combine = useCombinePlaylist();

  const [uploadedImageSize, setUploadedImageSize] = useState(0);

  const [showSubBarPrototype, setShowSubBarPrototype] = useState(false);
  const [currentViewPrototype, setCurrentViewPrototype] = useState("");
  const [subBarAnimate, setSubBarAnimate] = useState(false);
  const [isCopyingItem, setIsCopyingItem] = useState(false);
  const [copySource, setCopySource] = useState(null);
  const [copiedPlaylist, setCopiedPlaylist] = useState(null);
  const [copiedGroup, setCopiedGroup] = useState(null);

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [termsCheckboxState, setTermsCheckboxState] = useState(false);
  const [localeLang, setLocaleLang] = useState(
    localStorageService().get("lang") || "en"
  );
  const [language, setLanguage] = useState("");
  const [resetPasswordOtpCode, setResetPasswordOtpCode] = useState("");
  const [showChannelPackagePreview, setShowChannelPackagePreview] =
    useState(false);
  const [showPreviousSeries, setShowPreviousSeries] = useState(false);
  const [showPrograms, setShowPrograms] = useState(false);
  const [showDutchPrograms, setShowDutchPrograms] = useState(false);
  const [showActionBar, setShowActionBar] = useState(false);
  const [searchingChannel, setSearchingChannel] = useState(false);

  const [searchedChannlesLength, setSearchedChannlesLength] = useState(3);
  const [searchedPackagesLength, setSearchedPackagesLength] = useState(3);

  const [authMobileHeading, setAuthMobileHeading] = useState(
    `${t("welcome_back")}`
  );
  const [authMobileSubHeading, setAuthMobileSubHeading] = useState(
    `${t("log_into_your_account")}`
  );

  const [currentChannelGroup, setCurrentChannelGroup] = useState("");
  const [channelGroupChannelsCount, setChannelGroupChannelsCount] =
    useState(null);
  const [currentChannel, setCurrentChannel] = useState();
  const [selectedProgram, setSelectedProgram] = useState({
    title: "",
    startTimeInMs: null,
  });

  const [playingChannelLive, setPlayingChannelLive] = useState(false);
  const [tier, setTier] = useState({});
  const [tierListOpened, setTierListOpened] = useState([]);
  const [mobileHeaderHasSubscribe, setMobileHeaderHasSubscribe] =
    useState(false);
  const [showWarningText, setShowWarningText] = useState('');

  //channel prototype
  const [showTermsImagesPrototype, setShowTermsImagesPrototype] =
    useState(true);
  const [showChannelsPrototype, setShowChannelsPrototype] = useState(false);
  const [showVideoPlayerPrototype, setShowVideoPlayerPrototype] =
    useState(false);

  // series sorter mode
  const [showUpdateSerieTitleModal, setShowUpdateSerieTitleModal] =
    useState(false);
  const [currentSerieItem, setCurrentSerieItem] = useState({});

  //tiers
  const [tierPricePrototype, setTierPricePrototype] = useState("");
  const [tierPrototype, setTierPrototype] = useState(null);

  //favorites
  const [showFavoritesVideos, setShowFavoritesVideos] = useState(false);
  const [showFavoritesAddRelated, setShowFavoritesAddRelated] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState({});
  const [clearSelectedPlaylist, setClearSelectedPlaylist] = useState(false);
  const [selectedVideoLink, setSelectedVideoLink] = useState({});
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showCreatePlaylistModal, setShowCreatePlaylistModal] = useState(false);
  const [showPlaylistSettingsModal, setShowPlaylistSettingsModal] =
    useState(false);
  const [showCreatePlaylistModalOnMobile, setShowCreatePlaylistModalOnMobile] =
    useState(false);
  const [showAddNewVideoModal, setShowAddNewVideoModal] = useState(false);
  const [confirmAdultContentOtpCode, setConfirmAdultContentOtpCode] =
    useState("");
  const [showMoveToPlaylistModal, setShowMoveToPlaylistModal] = useState(false);
  const [showRelatedVideosModal, setShowRelatedVideosModal] = useState(false);
  const [showSaveToPlaylistModal, setShowSaveToPlaylistModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [relatedVideo, setRelatedVideo] = useState({});
  const [disableRelatedAddBtn, setDisableRelatedAddBtn] = useState(false);
  const [searchingPlaylists, setSearchingPlaylists] = useState(false);
  const [currentPlaylistIdPrototype, setCurrentPlaylistIdPrototype] =
    useState("");
  const [currentVideoPrototype, setCurrentVideoPrototype] = useState(null);

  //following
  const [showFollowingVideos, setShowFollowingVideos] = useState(false);
  const [showFollowingRelatedVideos, setShowFollowingRelatedVideos] =
    useState(false);

  //search
  const [searchedWordPrototype, setSearchedWordPrototype] = useState("");

  // settings
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showSettingsPlaylist, setShowSettingsPlaylist] = useState(false);
  const [twoDimention, setTwoDimention] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [mobileHeaderText, setMobileHeaderText] = useState(null);
  const [mobileHeaderSubText, setMobileHeaderSubText] = useState("");
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showChangePasswordDesktop, setShowChangePasswordDesktop] =
    useState(false);

  const [modalTypeAuthPrototype, setModalTypeAuthPrototype] = useState(
    formTypeAuthPrototype.empty
  ); // if not logged in show two option [login | register] buttons

  const [showAlertModalPrototype, setShowAlertModalPrototype] = useState(false);
  const [showCookiesModalPrototype, setShowCookiesModalPrototype] =
    useState(false);
  const [showPrivacyModalPrototype, setShowPrivacyModalPrototype] =
    useState(false);

  //

  // modals
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(true);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showRequiredFieldsModal, setShowRequiredFieldsModal] = useState(false);
  const [showUnsubscribedTierModal, setShowUnsubscribedTierModal] =
    useState(false);
  const [showtierSubscribedModal, setShowTierSubscribedModal] = useState(false);
  const [showCheckEmailModalPrototype, setShowCheckEmailModalPrototype] =
    useState(false);

  const [showTierBuyModalPrototype, setShowTierBuyModalPrototype] =
    useState(false);
  const [showShareModalPrototype, setShowShareModalPrototype] = useState(false);
  const [showReportContentModalPrototype, setShowReportContentModalPrototype] =
    useState(false);
  const [showSearchModalPrototype, setShowSearchModalPrototype] =
    useState(false);
  const [showDeleteItemModalPrototype, setShowDeleteItemModalPrototype] =
    useState(false);
  const [deletableItemPrototype, setDeletableItemPrototype] = useState("");
  const [showConfirmPasteModalPrototype, setShowConfirmPasteModalPrototype] = useState(false)
  const [showDeleteGroupModalPrototype, setShowDeleteGroupModalPrototype] =
    useState(false);

  const [
    showEditPlaylistVideoModalPrototype,
    setShowEditPlaylistVideoModalPrototype,
  ] = useState(false);
  const [playlistVideoPrototype, setPlaylistVideoPrototype] = useState(null);
  const [deleteSerieInGroupItem, setDeleteSerieInGroupItem] = useState();
  const [showCreateGroupModalPrototype, setShowCreateGroupModalPrototype] = useState(false)

  const [
    showAddPlaylistItemModalPrototype,
    setShowAddPlaylistItemModalPrototype,
  ] = useState(false);
  const [showEditPlaylistModalPrototype, setShowEditPlaylistModalPrototype] =
    useState(false);

  const [showAddPlaylistModalPrototype, setShowAddPlaylistModalPrototype] =
    useState(false);

  const [indexOfNewPlaylist, setIndexOfNewPlaylist] = useState(1);

  const [playlistScrollTop, setPlaylistScrollTop] = useState(0);

  const [
    openUnsubscribeTierModalPrototype,
    setOpenUnsubscribeTierModalPrototype,
  ] = useState(false);
  const [showAddToPlaylistConfirmModal, setShowAddToPlaylistConfirmModal] =
    useState(false);
  const [showSuccessModalPrototype, setShowSuccessModalPrototype] =
    useState(false);
  const [showEditGroupNameModalPrototype, setShowEditGroupNameModalPrototype] = useState(false)
  const [showCopyInHeartDimensionModal, setShowCopyInHeartDimensionModal] = useState(false);
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [showAddMemberToTeamModal, setShowAddMemberToTeamModal] = useState(false);
  const [showSimpleWarningModal, setShowSimpleWarningModal] = useState(false);
  const [showSimplePopupModal, setShowSimplePopupModal] = useState(false);
  const [showEditTeamsModal, setShowEditTeamsModal] = useState(false);
  const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);
  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false);
  

  //refs
  const searchContainerRef = useRef(null);
  const favoritePlaylistRef = useRef(null);
  const subbarRefPrototype = useRef(null);
  const tabletFrameRef = useRef(null);
  const tabletPrototypeRef = useRef(null);
  const videoPlayerItemRef = createRef();
  const scrollSubbarRef = useRef(null);

  // Anauthenticated background state
  const [showHomeContent, setShowHomeContent] = useState(true);

  //copy alert
  const [showCopyAlert, setShowCopyAlert] = useState(false);

  // Arrange items (submenu)
  const [isArrangeMode, setIsArrangeMode] = useState(false);
  const [isGroupArrangeMode, setIsGroupArrangeMode] = useState(false);
  const [isOpenableArrangeMode, setIsOpenableArrangeMode] = useState(false);
  const [isRestrictedOpenableArrangeMode, setIsRestrictedOpenableArrangeMode] =
    useState(false);
  const [isShortcutArrangeMode, setIsShortcutArrangeMode] = useState(false);
  // Arrange playlist series
  const [isPlaylistArrangeMode, setIsPlaylistArrangeMode] = useState(false);
  const [isItemsArrangeMode, setIsItemsArrangeMode] = useState(false);

  // Dnd
  const [dragVideoItem, setDragVideoItem] = useState(null);

  const [shareableLink, setSharebaleLink] = useState(false);

  //header

  const [headerScrollMargin, setHeaderScrollMargin] = useState(0);

  //indiana scroll
  const [isSubbarHorizontalScrollable, setIsSubbarHorizontalScrollable] =
    useState(true);

  //groups
  const [deletableGroupInfo, setDeletableGroupInfo] = useState(null);

  const [isEditMode, setIsEditMode] = useState(false);

  //statistics
  const [showPlaylistStatistics, setShowPlaylistStatistics] = useState(false)

  useEffect(() => {
    if (localeLang === "nl") {
      setLanguage("Dutch");
    } else {
      setLanguage("English");
    }
  }, [localeLang]);

  const userEmailHandler = (email) => {
    setUserEmail(email);
  };

  const localeHandler = () => {
    if (!localStorageService().get("lang")) {
      localStorageService().set("lang", localeLang);
    }
    setLocaleLang(localStorageService().get("lang"));
  };

  const copyingItem = (copySourceElement = null) => {
    if (copySourceElement) {
      setCurrentViewPrototype(VIEWS_PROTOTYPE.playlists);
      setShowSubBarPrototype(true);
      setIsCopyingItem(true);
      setCopySource(copySourceElement);
    } else {
      setShowSubBarPrototype(false);
      setIsCopyingItem(false);
      setCopySource(null);
    }
  };

  const closeEditModeIfOpened = () => isEditMode && setIsEditMode(false);

  const contextValues = {
    uploadedImageSize,
    setUploadedImageSize,
    showSubBarPrototype,
    setShowSubBarPrototype,
    isCopyingItem,
    setIsCopyingItem,
    copiedPlaylist,
    setCopiedPlaylist,
    copiedGroup,
    setCopiedGroup,
    copyingItem,
    copySource,
    currentViewPrototype,
    setCurrentViewPrototype,
    currentGroupPrototype,
    setCurrentGroupPrototype,
    subBarAnimate,
    setSubBarAnimate,
    mobileHeaderText,
    setMobileHeaderText,
    mobileHeaderSubText,
    setMobileHeaderSubText,
    localeLang,
    language,
    localeHandler,
    setLocaleLang,
    userEmail,
    userEmailHandler,
    userPassword,
    setUserPassword,
    resetPasswordOtpCode,
    setResetPasswordOtpCode,
    termsCheckboxState,
    setTermsCheckboxState,
    showChannelPackagePreview,
    setShowChannelPackagePreview,
    playingChannelLive,
    setPlayingChannelLive,
    currentChannelGroup,
    setCurrentChannelGroup,
    channelGroupChannelsCount,
    setChannelGroupChannelsCount,
    showPreviousSeries,
    setShowPreviousSeries,
    showActionBar,
    tier,
    setTier,
    tierPricePrototype,
    setTierPricePrototype,
    tierPrototype,
    setTierPrototype,
    mobileHeaderHasSubscribe,
    setMobileHeaderHasSubscribe,
    setShowActionBar,
    showPrograms,
    setShowPrograms,
    showDutchPrograms,
    setShowDutchPrograms,
    showTermsImagesPrototype,
    setShowTermsImagesPrototype,
    showChannelsPrototype,
    setShowChannelsPrototype,
    showVideoPlayerPrototype,
    setShowVideoPlayerPrototype,
    showFavoritesAddRelated,
    setShowFavoritesAddRelated,
    showFavoritesVideos,
    selectedPlaylist,
    clearSelectedPlaylist,
    showRelatedVideosModal,
    setShowRelatedVideosModal,
    selectedVideoLink,
    setSelectedVideoLink,
    setSelectedPlaylist,
    setClearSelectedPlaylist,
    setShowFavoritesVideos,
    selectedVideo,
    setSelectedVideo,
    showCreatePlaylistModal,
    setShowCreatePlaylistModal,
    showCreatePlaylistModalOnMobile,
    showShareModal,
    setShowShareModal,
    openUnsubscribeTierModalPrototype,
    setOpenUnsubscribeTierModalPrototype,
    showAddToPlaylistConfirmModal,
    setShowAddToPlaylistConfirmModal,
    showSearchModalPrototype,
    setShowSearchModalPrototype,
    showShareModalPrototype,
    setShowShareModalPrototype,
    showReportContentModalPrototype,
    setShowReportContentModalPrototype,
    showTierBuyModalPrototype,
    setShowTierBuyModalPrototype,
    showSuccessModalPrototype,
    setShowSuccessModalPrototype,
    showtierSubscribedModal,
    setShowTierSubscribedModal,
    showReportModal,
    setShowReportModal,
    showRequiredFieldsModal,
    setShowRequiredFieldsModal,
    setShowCreatePlaylistModalOnMobile,
    showSaveToPlaylistModal,
    setShowSaveToPlaylistModal,
    showAddPlaylistItemModalPrototype,
    setShowAddPlaylistItemModalPrototype,
    showEditPlaylistModalPrototype,
    setShowEditPlaylistModalPrototype,
    showEditPlaylistVideoModalPrototype,
    setShowEditPlaylistVideoModalPrototype,
    showCookiesModalPrototype,
    setShowCookiesModalPrototype,
    showPrivacyModalPrototype,
    setShowPrivacyModalPrototype,
    showConfirmPasteModalPrototype,
    setShowConfirmPasteModalPrototype,
    showDeleteGroupModalPrototype,
    setShowDeleteGroupModalPrototype,
    showCreateGroupModalPrototype,
    setShowCreateGroupModalPrototype,
    showEditGroupNameModalPrototype,
    setShowEditGroupNameModalPrototype,
    showCopyInHeartDimensionModal,
    setShowCopyInHeartDimensionModal,
    playlistVideoPrototype,
    setPlaylistVideoPrototype,
    deleteSerieInGroupItem,
    setDeleteSerieInGroupItem,
    showUpdateSerieTitleModal,
    setShowUpdateSerieTitleModal,
    currentSerieItem,
    setCurrentSerieItem,
    relatedVideo,
    setRelatedVideo,
    disableRelatedAddBtn,
    setDisableRelatedAddBtn,
    searchingPlaylists,
    setSearchingPlaylists,
    currentPlaylistIdPrototype,
    setCurrentPlaylistIdPrototype,
    currentVideoPrototype,
    setCurrentVideoPrototype,
    searchingChannel,
    setSearchingChannel,
    searchedChannlesLength,
    setSearchedChannlesLength,
    searchedPackagesLength,
    setSearchedPackagesLength,
    authMobileHeading,
    setAuthMobileHeading,
    authMobileSubHeading,
    setAuthMobileSubHeading,
    currentChannel,
    setCurrentChannel,
    selectedProgram,
    setSelectedProgram,
    showEditProfile,
    setShowEditProfile,
    twoDimention,
    setTwoDimention,
    showSettingsPlaylist,
    setShowSettingsPlaylist,
    showSubscribeModal,
    setShowSubscribeModal,
    showAddNewVideoModal,
    setShowAddNewVideoModal,
    showMoveToPlaylistModal,
    setShowMoveToPlaylistModal,
    showPlaylistSettingsModal,
    setShowPlaylistSettingsModal,
    showUnsubscribedTierModal,
    setShowUnsubscribedTierModal,
    confirmAdultContentOtpCode,
    setConfirmAdultContentOtpCode,
    showFollowingVideos,
    setShowFollowingVideos,
    showFollowingRelatedVideos,
    setShowFollowingRelatedVideos,
    setShowForgetPassword,
    showForgetPassword,
    showChangePassword,
    setShowChangePassword,
    showChangePasswordDesktop,
    setShowChangePasswordDesktop,
    showPayment,
    setShowPayment,
    searchContainerRef,
    favoritePlaylistRef,
    subbarRefPrototype,
    tabletFrameRef,
    tabletPrototypeRef,
    showResetPasswordModal,
    setShowResetPasswordModal,
    dragVideoItem,
    setDragVideoItem,
    showCopyAlert,
    setShowCopyAlert,
    showCheckEmailModalPrototype,
    setShowCheckEmailModalPrototype,
    modalTypeAuthPrototype,
    setModalTypeAuthPrototype,
    showAlertModalPrototype,
    setShowAlertModalPrototype,
    showDeleteItemModalPrototype,
    setShowDeleteItemModalPrototype,
    deletableItemPrototype,
    setDeletableItemPrototype,
    showAddPlaylistModalPrototype,
    setShowAddPlaylistModalPrototype,
    indexOfNewPlaylist,
    setIndexOfNewPlaylist,
    playlistScrollTop,
    setPlaylistScrollTop,
    formTypeAuthPrototype,
    shareableLink,
    setSharebaleLink,
    isArrangeMode,
    setIsArrangeMode,
    isGroupArrangeMode,
    setIsGroupArrangeMode,
    isOpenableArrangeMode,
    setIsOpenableArrangeMode,
    isRestrictedOpenableArrangeMode,
    setIsRestrictedOpenableArrangeMode,
    isShortcutArrangeMode,
    setIsShortcutArrangeMode,
    videoPlayerItemRef,
    scrollSubbarRef,
    showHomeContent,
    setShowHomeContent: () => {},
    tierListOpened,
    setTierListOpened,
    isPlaylistArrangeMode,
    setIsPlaylistArrangeMode,
    isItemsArrangeMode,
    setIsItemsArrangeMode,
    headerPosition,
    setHeaderPosition,
    headerDragStarted,
    setHeaderDragStarted,
    showCombinePlaylistModal,
    setShowCombinePlaylistModal,
    ...combine,
    searchedWordPrototype,
    setSearchedWordPrototype,
    headerScrollMargin,
    setHeaderScrollMargin,
    isSubbarHorizontalScrollable,
    setIsSubbarHorizontalScrollable,
    deletableGroupInfo,
    setDeletableGroupInfo,
    isEditMode,
    setIsEditMode,
    togglePlaylistEditMode: () => setIsEditMode((mode) => !mode),
    closeEditModeIfOpened,
    showPlaylistStatistics,
    setShowPlaylistStatistics,
    showCreateTeamModal,
    setShowCreateTeamModal,
    showSimpleWarningModal,
    setShowSimpleWarningModal,
    showWarningText,
    setShowWarningText,
    showSimplePopupModal,
    setShowSimplePopupModal,
    showEditTeamsModal,
    setShowEditTeamsModal,
    isEditUserModalVisible,
    setIsEditUserModalVisible,
    showDeleteTeamModal,
    setShowDeleteTeamModal,
    showAddMemberToTeamModal,
    setShowAddMemberToTeamModal
  };

  return <Context.Provider value={contextValues}>{children}</Context.Provider>;
};

export { Context, ContextProvider };
