export const FAVORITES = "Favorites";
export const WATCH_LATER = "Watch Later";

export const COPY_ITEM = {
  MY_PLAYLIST: "MY_PLAYLIST",
  DEFAULT_PLAYLIST: "DEFAULT_PLAYLIST",
  TV_CHANNEL: "TV_CHANNEL",
  PLAYLIST_VIDEO: "PLAYLIST_VIDEO",
  PLAYLIST_SERIE: "PLAYLIST_SERIE",
  EPG_ITEM: "EPG_ITEM",
  EPG: "EPG", 
};