import React, { useState, useContext, useEffect } from "react";
import { Context } from "@context/context";
import Form from "@shared/atoms/Form/index";
import Input from "@shared/atoms/Input/index";
import Button from "@shared/atoms/Button/index";
import { formLayout } from "@modules/config";

import "./index.less";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

const RegisterPasswordForm = ({ registerHandler }) => {
    const [confirmPassword, setConfirmPassword] = useState("");
    const [disableBtn, setDisableBtn] = useState(true);
    const [isVerified, setIsVerified] = useState(false);

    const { t } = useTranslation();

    const { userPassword, setUserPassword } = useContext(Context);

    useEffect(() => {
        if (userPassword && confirmPassword && isVerified) {
            if (userPassword !== confirmPassword) {
                setDisableBtn(true);
            } else {
                setDisableBtn(false);
            }
        }
    }, [userPassword, confirmPassword, isVerified]);

    const passwordHandler = (e) => {
        setUserPassword(e.target.value);
    };

    const confirmPasswordHandler = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleVerifyCaptcha = (value) => {
        setIsVerified(true);
    };

    return (
        <Form layout={formLayout} className="register-form-mobile">
            <label className="registration-label-mobile">
                {t("enter_your_password")}
            </label>
            <Form.Item>
                <Input.Password
                    onChange={passwordHandler}
                    placeholder={t("password")}
                />
            </Form.Item>
            <Form.Item>
                <Input.Password
                    onChange={confirmPasswordHandler}
                    placeholder={t("confirm_password")}
                />
            </Form.Item>
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={handleVerifyCaptcha}
                className="re-captcha"
            />
            <div className="next-btn-container">
                <Button
                    className=""
                    disabled={disableBtn}
                    // onClick={setOtpScreen}
                    onClick={registerHandler}
                    type="primary"
                    htmlType="submit"
                >
                    {t("finish")}
                </Button>
            </div>
        </Form>
    );
};

export default RegisterPasswordForm;
