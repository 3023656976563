import "./index.less";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import Modal from "@shared/atoms/Modal";
import querystring from "query-string";
import { getTeam, updateTeam } from "@redux/actions/TeamActions";
import useSelectSearch from "hooks/useSelectSearch";
import Select from "@components/shared/atoms/SearchSelect";
import { Context } from "@context/context";

const AddMemberToTeamModal = ({ ...rest }) => {
  const dispatch = useDispatch();
  const queryString = querystring.parse(window.location.search);

  const { setShowAddMemberToTeamModal } = useContext(Context);

  const {
    selectedItems: selectedMembers,
    isAllSelected: isAllMembersSelected,
    onSelectChange: onMembersChange,
  } = useSelectSearch();

  const addMemberToTeamHandler = () => {
    let fd = new FormData();

    selectedMembers.forEach((pl, index) => {
      fd.append(`users[${index}]`, pl);
    });

    dispatch(updateTeam(queryString.teamId, fd)).then(() => {
      dispatch(getTeam(queryString.teamId));
      setShowAddMemberToTeamModal(false);
    });
  };

  return (
    <Modal
      title="Add Member to Team"
      className="add-member-modal"
      modalContainer=".tablet-prototype"
      footer={[
        <div className="btn-container">
          <button
            key="back"
            type="primary"
            className="confirm-btn"
            onClick={addMemberToTeamHandler}
          >
            Add Member
          </button>
        </div>,
      ]}
      {...rest}
    >
      <Select
        onChange={onMembersChange}
        placeholder="Add user e-mail"
        isAllSelected={isAllMembersSelected}
      />
    </Modal>
  );
};

export default function ModalWrapper(props) {
  return props.visible ? <AddMemberToTeamModal {...props} /> : null;
}
