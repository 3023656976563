import React from "react";
import List from "@components/shared/atoms/List";
import Spacer from "@components/shared/atoms/Spacer";
import { useHistory } from "react-router-dom";
import Image from "@components/shared/atoms/Image";
import { Paragraph } from "@components/shared/atoms/Typography";
import directPublishImage from "@assets/icons/direct_publish.svg";

import "./index.less";
import { PAGES } from "const/pages";

const SearchResultListPrototype = ({ data, searchType }) => {
  const history = useHistory();
  const redirectToContent = (item) => {
    switch (searchType) {
      case "channels":
        history.push(`/tiers?channel=${item.id}`);
        break;
      case "playlists":
        history.push(`/playlists?playlist=${item.id}`);
        break;
      case "series":
        history.push(
          `/playlists?playlist=${item.playlist_id}&serie=${item.id}`
        );
        break;
      case "videos":
        handleVideoRedirect(item);
        break;
      case "users":
        history.push(`/playlists?playlist=${item.directPublishId}`);
        break;
      default:
        break;
    }
  };

  const handleVideoRedirect = ({ id, series }) => {
    const isPlaylistOwner = series[0].owner;
    const playlistId = series[0].playlist_id;
    const seriesId = series[0].id;
    const videoId = id;

    const redirectPage = isPlaylistOwner ? PAGES.myPlaylists : PAGES.playlists;

    const videoUrl = `/${redirectPage}?playlist=${playlistId}&serie=${seriesId}&video=${videoId}`;

    history.push(videoUrl);
  };

  return (
    <List className="search-result-list">
      <Spacer height={4} />
      {data?.[searchType]?.map((item) => {
        return (
          <List.Item onClick={() => redirectToContent(item)}>
            {/* <Paragraph> {item.name ?? item.title ?? item.nickname}</Paragraph> */}
            <Paragraph>
              {
                item.name && item.name.length > 80 ? item.name.slice(0, 80) + "..." : item.name
              }

              {
                item.title && item.title.length > 80 ? item.title.slice(0, 80) + "..." : item.title
              }

              {
                item.nickname && item.nickname.length > 80 ? item.nickname.slice(0, 80) + "..." : item.nickname
              }
            </Paragraph>

            {item.logoId && <Image className="item-img" src={item.logoId} />}
            {item.icon && <Image className="item-img" src={item.icon} />}
            {item.image && <Image className="item-img" src={item.image} />}

            {item.profile_picture && (
              <Image className="item-img" src={item.profile_picture} />
            )}
            {!item.image && searchType === "series" && (
              <Image className="thunder-img" src={directPublishImage} />
            )}
          </List.Item>
        );
      })}
      <Spacer height={2} />
    </List>
  );
};

export default SearchResultListPrototype;
