export const COPY_VIDEO_REQUEST = "COPY_VIDEO_REQUEST";
export const COPY_VIDEO_SUCCESS = "COPY_VIDEO_SUCCESS";
export const COPY_VIDEO_FAIL = "COPY_VIDEO_FAIL";

export const COPY_SERIES_REQUEST = "COPY_SERIES_REQUEST";
export const COPY_SERIES_SUCCESS = "COPY_SERIES_SUCCESS";
export const COPY_SERIES_FAIL = "COPY_SERIES_FAIL";

export const COPY_EPG_ITEM_REQUEST = "COPY_EPG_ITEM_REQUEST";
export const COPY_EPG_ITEM_SUCCESS = "COPY_EPG_ITEM_SUCCESS";
export const COPY_EPG_ITEM_FAIL = "COPY_EPG_ITEM_FAIL";

export const COPY_EPG_REQUEST = "COPY_EPG_REQUEST";
export const COPY_EPG_SUCCESS = "COPY_EPG_SUCCESS";
export const COPY_EPG_FAIL = "COPY_EPG_FAIL";

export const COPY_TIER_CHANNEL_REQUEST = "COPY_TIER_CHANNEL_REQUEST";
export const COPY_TIER_CHANNEL_SUCCESS = "COPY_TIER_CHANNEL_SUCCESS";
export const COPY_TIER_CHANNEL_FAIL = "COPY_TIER_CHANNEL_FAIL";