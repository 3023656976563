import { axiosInstance } from "../../modules/request/api";
import {
    ADULT_CONTENT_REQUEST,
    ADULT_CONTENT_SUCCESS,
    ADULT_CONTENT_FAIL,
    ADULT_CONTENT_CONFIRM_REQUEST,
    ADULT_CONTENT_CONFIRM_SUCCESS,
    ADULT_CONTENT_CONFIRM_FAIL,
    USER_PROFILE_REQUEST,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAIL,
    USER_PROFILE_UPDATE_REQUEST,
    USER_PROFILE_UPDATE_SUCCESS,
    USER_PROFILE_UPDATE_FAIL,
    USER_PROFILE_BY_ID_REQUEST,
    USER_PROFILE_BY_ID_SUCCESS,
    USER_PROFILE_BY_ID_FAIL,
    USER_CHANGE_PASSWORD_REQUEST,
    USER_CHANGE_PASSWORD_SUCCESS,
    USER_CHANGE_PASSWORD_FAIL,
} from "../constants/profileConstants";

import { CHOICE_URL } from "@modules/config";
import { METHODS } from "const/apiMethods";

export const toggleAdultContent = () => async (dispatch) => {
    try {
        dispatch({ type: ADULT_CONTENT_REQUEST });

        const { data } = await axiosInstance().post(
            `${CHOICE_URL}/api/user/adult_content`
        );

        dispatch({ type: ADULT_CONTENT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: ADULT_CONTENT_FAIL, payload: error.response });
    }
};

export const confirmAdultContent = (code) => async (dispatch) => {
    try {
        dispatch({ type: ADULT_CONTENT_CONFIRM_REQUEST });

        const { data } = await axiosInstance().post(
            `${CHOICE_URL}/api/user/pass_age_verification?code=${code}`
        );

        dispatch({ type: ADULT_CONTENT_CONFIRM_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: ADULT_CONTENT_CONFIRM_FAIL,
            payload: error?.response,
        });
    }
};

export const getUserProfile = () => async (dispatch) => {
    try {
        dispatch({ type: USER_PROFILE_REQUEST });
        const { data } = await axiosInstance().get(
            `${CHOICE_URL}/api/user/profile`
        );
        dispatch({ type: USER_PROFILE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: USER_PROFILE_FAIL, payload: error });
    }
};

export const updateUserProfile = (updatedObj,method=METHODS.POST) => async (dispatch) => {
    try {
        dispatch({ type: USER_PROFILE_UPDATE_REQUEST });
        if(method === METHODS.POST){
            const { data } = await axiosInstance().post(
                `${CHOICE_URL}/api/user`,
                updatedObj
            );
            dispatch({ type: USER_PROFILE_UPDATE_SUCCESS, payload: data });

        } else if(method===METHODS.PATCH){
            const { data } = await axiosInstance().patch(
                `${CHOICE_URL}/api/user`,
                updatedObj
            );
            dispatch({ type: USER_PROFILE_UPDATE_SUCCESS, payload: data });
            dispatch(getUserProfile())

        }

    } catch (error) {
        dispatch({ type: USER_PROFILE_UPDATE_FAIL, payload: error.response });
    }
};

export const getUserProfileById = (userId) => async (dispatch) => {
    try {
        dispatch({ type: USER_PROFILE_BY_ID_REQUEST });

        const { data } = await axiosInstance().get(
            `${CHOICE_URL}/api/user/profile/${userId}`
        );
        dispatch({ type: USER_PROFILE_BY_ID_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: USER_PROFILE_BY_ID_FAIL, payload: error });
    }
};

export const changePassword =
    (oldPassword, newPassword, confirmPassword) => async (dispatch) => {
        try {
            dispatch({ type: USER_CHANGE_PASSWORD_REQUEST });
            const { data } = await axiosInstance().put(
                `${CHOICE_URL}/api/user/password`,
                {
                    old_password: oldPassword,
                    password: newPassword,
                    password_confirmation: confirmPassword,
                }
            );
            dispatch({ type: USER_CHANGE_PASSWORD_SUCCESS, payload: data });
        } catch (error) {
            dispatch({
                type: USER_CHANGE_PASSWORD_FAIL,
                payload: error.response,
            });
        }
    };
