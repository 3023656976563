import {
  COPY_GROUP_FAIL,
  COPY_GROUP_REQUEST,
  COPY_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  EDIT_GROUP_FAIL,
  EDIT_GROUP_REQUEST,
  EDIT_GROUP_SUCCESS,
  GROUP_ARRANGE_FAIL,
  GROUP_ARRANGE_REQUEST,
  GROUP_ARRANGE_SUCCESS,
  GROUP_PLAYLISTS_ARRANGE_FAIL,
  GROUP_PLAYLISTS_ARRANGE_REQUEST,
  GROUP_PLAYLISTS_ARRANGE_SUCCESS,
} from "@redux/constants/groupConstants";

export const groupDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_GROUP_REQUEST:
      return { loading: true };
    case DELETE_GROUP_SUCCESS:
      return { loading: false, data: action.payload };
    case DELETE_GROUP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const editGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_GROUP_REQUEST:
      return { loading: true };
    case EDIT_GROUP_SUCCESS:
      return { loading: false, data: action.payload };
    case EDIT_GROUP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const copyGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case COPY_GROUP_REQUEST:
      return { loading: true };
    case COPY_GROUP_SUCCESS:
      return { loading: false, data: action.payload };
    case COPY_GROUP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const groupArrangeReducer = (state = {}, action) => {
  switch (action.type) {
    case GROUP_ARRANGE_REQUEST:
      return { loading: true }
    case GROUP_ARRANGE_SUCCESS:
      return { loading: false, data: action.payload }
    case GROUP_ARRANGE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state;
  }
}

export const groupPlaylistsArangeReducer = (state = {}, action) => {
  switch (action.type) {
    case GROUP_PLAYLISTS_ARRANGE_REQUEST:
      return { loading: true }
    case GROUP_PLAYLISTS_ARRANGE_SUCCESS:
      return { loading: false, data: action.payload }
    case GROUP_PLAYLISTS_ARRANGE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state;
  }
}