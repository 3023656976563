import { useDispatch } from "react-redux";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "@context/context";
import { useHistory } from "react-router-dom";

import {
  copyNavbarPlaylist,
  getPlaylistById,
  updatePlaylist,
} from "@redux/actions/playlistsActions";

// @hooks
import { useContextMenu } from "hooks/useContextMenu";
import { useStatus } from "hooks/useStatus";

// @components
import ContextMenuPrototype, {
  ContextMenuOption,
} from "@components/shared/molecules/ContextMenuPrototype";
import DeletePlaylistModalPrototype from "@components/organisms/modals/DeletePlaylistModalPrototype";

// @assets
import arrangeImg from "@assets/icons/arrange.svg";
import deleteImg from "@assets/icons/delete.svg";
import copyImg from "@assets/icons/copy.svg";
import archiveSvg from "@assets/icons/archive.svg";
import settingsIcon from "@assets/icons/settings.svg";
import shareImg from "@assets/icons/share-prototype.svg";

// @utils

// @constants
import { COPY_ITEM } from "const/playlists";
import SubbarItem from "../SubbarItem";
import { METHODS } from "const/apiMethods";
import usePathName from "hooks/usePathName";

const SCROLL_BEHAVIOUR = {
  block: "nearest",
  inline: "center",
};

const MyPlaylistSubbarItem = (props) => {
  const { item, index, isActive } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const currentPage = usePathName();

  const { findStatusId } = useStatus();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const activeItemRef = useRef(null);

  const {
    isEditMode,
    copiedPlaylist,
    setCopiedPlaylist,
    setIsArrangeMode,
    setSharebaleLink,
    togglePlaylistEditMode,
    setShowShareModalPrototype,
    setShowEditPlaylistModalPrototype,
  } = useContext(Context);

  const {
    xPos,
    yPos,
    isVisible,
    openContextMenu,
    closeContextMenu,
    withCloseContextMenu,
    contextMenuClickWithDebounce,
    onTouchEnd,
  } = useContextMenu(".subbar-item-container");

  const handelShareClick = () => {
    setShowShareModalPrototype(true);
    setSharebaleLink(window.location.origin + `/playlists?playlist=${item.id}`);
  };

  const handleEditClick = () => {
    dispatch(getPlaylistById(item?.id));
    togglePlaylistEditMode();
  };

  const handleSettingsClick = () => {
    dispatch(getPlaylistById(item?.id));
    // old icon/image does not change if we do not wait for some time
    setTimeout(() => {
      setShowEditPlaylistModalPrototype(true);
    }, 1000);
  };

  const handleArchiveClick = () => {
    const fd = new FormData();
    fd.append("is_archived", true);
    fd.append("order", 1);
    fd.append("_method", METHODS.PATCH);

    dispatch(updatePlaylist(fd, item.id));
  };

  const handleCopyClick = () => {
    setCopiedPlaylist({ copiedItem: item, type: COPY_ITEM.MY_PLAYLIST });
    navigator.clipboard.writeText(
      window.location.origin + `/playlists?playlist=${item.id}`
    );
  };

  const handlePasteClick = (direction) => () => {
    const current = index + 1;
    let calcIndex = direction === "Left" ? current : current + 1;

    const { copiedItem } = copiedPlaylist;

    const payloadData = {
      to: calcIndex,
      playlistId: copiedItem.id,
    };

    if (item?.status === "Public") {
      payloadData.status_id = findStatusId("Public");
    } else {
      payloadData.status_id = findStatusId("Private");
    }

    dispatch(copyNavbarPlaylist(payloadData));

    setCopiedPlaylist(null);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleMoveClick = () => {
    setIsArrangeMode(true);
  };

  const setPlaylistIdInUrl = () => {
    history.replace({ search: `?playlist=${item.id}` });
  };

  const handleContextMenu = (e) => {
    openContextMenu(e);
    setPlaylistIdInUrl();
  };

  const handleTouchStart = (e) => {
    contextMenuClickWithDebounce(e, setPlaylistIdInUrl);
  };

  const scrollToActiveItem = () => {
    let item = activeItemRef.current;
    item?.scrollIntoView(SCROLL_BEHAVIOUR);
  };

  useEffect(() => {
    if (activeItemRef.current && isActive) {
      scrollToActiveItem();
    }
  }, [activeItemRef, currentPage]);

  const isPasteShown =
    COPY_ITEM.MY_PLAYLIST === copiedPlaylist?.type ||
    COPY_ITEM.DEFAULT_PLAYLIST === copiedPlaylist?.type;

  return (
    <>
      <ContextMenuPrototype
        isVisible={isVisible}
        handleClose={closeContextMenu}
        xPos={xPos}
        yPos={yPos}
      >
        <ContextMenuOption
          onClick={withCloseContextMenu(handelShareClick)}
          icon={shareImg}
          label={"Share"}
        />

        <ContextMenuOption
          onClick={withCloseContextMenu(handleEditClick)}
          icon={settingsIcon}
          label={isEditMode ? "Stop Edit" : "Edit"}
        />
        <ContextMenuOption
          onClick={withCloseContextMenu(handleSettingsClick)}
          icon={settingsIcon}
          label="Settings"
        />
        <ContextMenuOption
          onClick={withCloseContextMenu(handleArchiveClick)}
          icon={archiveSvg}
          label="Archive"
        />
        <ContextMenuOption
          onClick={withCloseContextMenu(handleCopyClick)}
          icon={copyImg}
          label="Copy"
        />
        {isPasteShown && (
          <>
            <ContextMenuOption
              onClick={withCloseContextMenu(handlePasteClick("Left"))}
              icon={copyImg}
              label="<- Paste"
            />
            <ContextMenuOption
              onClick={withCloseContextMenu(handlePasteClick("Right"))}
              icon={copyImg}
              label="Paste ->"
            />
          </>
        )}
        <ContextMenuOption
          label="Delete"
          icon={deleteImg}
          onClick={withCloseContextMenu(handleDeleteClick)}
        />
        <ContextMenuOption
          onClick={withCloseContextMenu(handleMoveClick)}
          icon={arrangeImg}
          label="Move"
        />
      </ContextMenuPrototype>
      <SubbarItem
        {...props}
        contextHandler={{
          onContextMenu: handleContextMenu,
          onTouchStart: handleTouchStart,
          onTouchEnd: onTouchEnd,
        }}
        activeItemRef={activeItemRef}
        closeContextMenu={closeContextMenu}
      />
      <DeletePlaylistModalPrototype
        isVisible={showDeleteModal}
        setIsVisible={setShowDeleteModal}
        playlist={item}
      />
    </>
  );
};

export default MyPlaylistSubbarItem;
