import produce from "immer";
import {
  CLEAR_INVALID_COUNTRY_ERROR,
  COUNTRY_REQUEST,
  COUNTRY_REQUEST_FAIL,
  COUNTRY_REQUEST_SUCCESS,
  SET_INVALID_COUNTRY_ERROR,
} from "../constants/countryConstants";

export const countryReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_REQUEST:
      return { loading: true };
    case COUNTRY_REQUEST_SUCCESS:
      return { loading: false, countries: action.payload };
    case COUNTRY_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const invalidCountryReducer = produce(
  (state = { isError: false }, { type, payload }) => {
    switch (type) {
      case SET_INVALID_COUNTRY_ERROR:
        state.isError = true;
        break;
      case CLEAR_INVALID_COUNTRY_ERROR:
        state.isError = false;
        break;
      default:
        return state;
    }
  }
);

// Selector functions
export const selectInvalidCountryFlag = (state) => state.invalidCountry.isError;
