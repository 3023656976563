import { localStorageService } from "@modules/services/localStorage.service"

const lang = localStorageService().get("lang")

const ONLY_ME = lang === "en" ? "Only Me" : "Alleen ik"
const DIRECT_LINK = lang === "en" ? "Only people who receive the direct link(URL)" : "Alleen mensen die de directe link (URL) ontvangen"
const PUBLIC = lang === "en" ? "Everybody" : "Iedereen"

export const changeStatusText = (status) => {
  const statusLowerCased = status.toLowerCase()
  switch (statusLowerCased) {
    case "private": return ONLY_ME
    case "private link": return DIRECT_LINK
    case "public": return PUBLIC
    default: return ONLY_ME
  }
}