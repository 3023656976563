import React from "react";
import AntdAutoComplete from "antd/lib/auto-complete/index";
import "./index.less";

const AutoComplete = ({ children, ...rest }) => {
    return (
        <AntdAutoComplete
            className="auto-complete"
            filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
            }
            {...rest}
        >
            {children}
        </AntdAutoComplete>
    );
};

export default AutoComplete;
