import React, { useContext, useEffect, useState } from "react";
import { Context } from "@context/context";
import { useTranslation } from "react-i18next";
import Form from "../../../shared/atoms/Form";
import InputPrototype from "../../../shared/atoms/InputPrototype";
import { Paragraph } from "../../../shared/atoms/Typography";
import Button from "../../../shared/atoms/Button";
import {
  clearPasswordResetData,
  passwordReset,
} from "../../../../../redux/actions/userActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ErrorFeedbackPrototype from "../../../shared/atoms/ErrorFeedbackPrototype";
import { formTypeAuthPrototype } from "../../../../../context/context";

const ForgotPasswordFormPrototype = ({ returnToLogin }) => {
  const { setModalTypeAuthPrototype } = useContext(Context);
  const resetError = useSelector((state) => state.userPasswordReset.error);
  const resetSuccess = useSelector((state) => state.userPasswordReset.success);

  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setModalTypeAuthPrototype(formTypeAuthPrototype.forgotPassword);
    return () => {
      setModalTypeAuthPrototype(formTypeAuthPrototype.login);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      setEmail("");
      dispatch(clearPasswordResetData());
    };
  }, [dispatch]);

  const handleSubmit = () => {
    dispatch(passwordReset(email));
  };

  return (
    <>
      <Form className="ant-form-prototype">
        <Form.Item>
          <InputPrototype
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {resetError && (
            <ErrorFeedbackPrototype>
              {t("password_reset_link_send_error")}
            </ErrorFeedbackPrototype>
          )}
          {resetSuccess && (
            <ErrorFeedbackPrototype type="success">
              {`${t("the_password_reset_link_is_sent_to")} "${email}".`}
            </ErrorFeedbackPrototype>
          )}
        </Form.Item>
        <Form.Item>
          <Button className="ant-btn-prototype" onClick={handleSubmit}>
            {t("send")}
          </Button>
        </Form.Item>
      </Form>
      <Paragraph className="paragraph-prototype" onClick={returnToLogin}>
        {t("go_back")}
      </Paragraph>
      <Paragraph className="paragraph-prototype">
        <a
          href="mailto:support@watchchoice.tv"
          style={{ color: "rgb(93, 163, 239)" }}
        >
          Forgot the Email address you used for Choice? No problem, pls contact
          us
        </a>
      </Paragraph>
    </>
  );
};

export default ForgotPasswordFormPrototype;
