import ReactDOM from "react-dom";
import React, { useState, useEffect, useRef } from "react";
import useOutsideClick from "hooks/useOutsideClick";
import Image from "@components/shared/atoms/Image";
import useOutsideTap from "hooks/useOutsideTap";

import "./index.less";

export const ContextMenuOption = ({ onClick, icon, label }) => {
  return (
    <li onClick={onClick}>
      <Image className="icon-img" src={icon} alt="context menu item" />{" "}
      <span>{label}</span>
    </li>
  );
};

function ContextMenuPrototype({ isVisible, isInPlaylistHeader,arrowDir, ...rest }) {
  return isVisible
    ? ReactDOM.createPortal(
        <ContextMenuPrototypeInternal
          isInPlaylistHeader={isInPlaylistHeader}
          arrowDir={arrowDir}
          {...rest}
        />,
        document.getElementById("context-menu-root")
      )
    : null;
}

const arrowDirection = {
  left: "left",
  down: "down",
  right: "right",
  up:"up"
};

function ContextMenuPrototypeInternal({
  isInPlaylistHeader,
  handleClose,
  xPos,
  yPos,
  children,
  arrowDir = arrowDirection.down,
}) {
  const [hasItem, setHasItem] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const contextMenuListLength = Boolean(
        containerRef?.current?.childNodes[0].childNodes.length
      );
      setHasItem(contextMenuListLength);

      const position = { x: 0, y: 0 };
      const contWidth = containerRect.width;
      const contHeight = containerRect.height;

      if (arrowDir === arrowDirection.down) {
        position.x = xPos - contWidth / 2;
        position.y = yPos - contHeight;
      } else if (arrowDir === arrowDirection.left) {
        position.x = xPos - contWidth;
        position.y = yPos - contHeight;
      } else if (arrowDir === arrowDirection.right) {
        position.x = xPos;
        position.y = yPos;
      } else if(arrowDir === arrowDirection.up){
        position.x = xPos - contWidth / 2;
        position.y = yPos - contHeight / 1000;
      }

      if (isInPlaylistHeader) {
        position.x = xPos - contWidth - contWidth / 6;
        position.y = yPos - contHeight + contHeight;
      }

      const overflowBottom = position.y + contHeight - document.body.offsetHeight 
      const overflowRight = position.x + contWidth - document.body.offsetWidth;

      if(overflowBottom > 0) {
        position.y -= overflowBottom;
      }

      if(overflowRight > 0) {
        position.x -= overflowRight;
      }

      containerRef.current.style.left = `${position.x}px`;
      containerRef.current.style.top = `${position.y}px`;
    }
  }, [xPos, yPos, arrowDir, isInPlaylistHeader]);

  useOutsideClick(containerRef, function () {
    handleClose();
  });

  useOutsideTap(containerRef, function () {
    handleClose();
  });
  
  return (
    <>
      {hasItem && (
        <div ref={containerRef} className="context-menu-prototype">
          <ul className="context-menu-list">{children}</ul>
          <div className={`arrow-${arrowDir}`}></div>
        </div>
      )}
    </>
  );
}

export default ContextMenuPrototype;
export { arrowDirection };
