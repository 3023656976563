import React from "react";
import Image from "@shared/atoms/Image";
import { Title, Paragraph } from "@shared/atoms/Typography";
import FranceFlag from "@assets/images/flags/france-flag.png";
import List from "@shared/atoms/List/index";
import { useTranslation } from "react-i18next";

import "./index.less";

const SubscribedChannelsItem = ({
  tierName,
  activeFrom,
  activeTo,
  isPrototype = false,
}) => {
  const { t } = useTranslation();
  return (
    <List.Item
      className={`${isPrototype ? "subscribed-channel-item-prototype" : ""}`}
    >
      {!isPrototype && (
        <div className="image-container">
          <Image
            className="channel-flag"
            preview={false}
            src={FranceFlag}
            alt={"flag"}
          />
        </div>
      )}

      <div className="subscribtion-text-container">
        <Title className="title" level={4}>
          {tierName}
        </Title>
        <Paragraph className="sub-text">
          {t("wallet_active_date")}: {activeFrom} - {activeTo}
        </Paragraph>
      </div>
    </List.Item>
  );
};

export default SubscribedChannelsItem;
