import Image from "@shared/atoms/Image";
import NoPlaylistSvg from "@assets/images/noContent.svg";

import "./index.less";

const EmptyPlaylistSvg = () => {
    return (
        <div className="empty-playlist">
            <Image src={NoPlaylistSvg} />
        </div>
    );
};

export default EmptyPlaylistSvg;
