import React from 'react'
import ArrowCircle from '@components/shared/atoms/icons/ArrowCircle'

import "./index.less"

/**
 * Custom arrow icon component
 *
 * @prop {function} slideHandler - scroll function
 *
 */

const SlideArrows = ({ slideLeftHandler, slideRightHandler }) => {
  return (
    <div className='slide-arrows-container'>
      <div>
        <ArrowCircle onClick={slideLeftHandler} color='#ccc' rotateDeg={180} width={24} height={24} />
      </div>
      <div>
        <ArrowCircle onClick={slideRightHandler} color='#ccc' width={24} height={24} />
      </div>
    </div>
  )
}

export default SlideArrows