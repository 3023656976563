export const SET_BALANCE_REQUST = "SET_BALANCE_REQUST";
export const SET_BALANCE_SUCCESS = "SET_BALANCE_SUCCESS";
export const SET_BALANCE_FAIL = "SET_BALANCE_FAIL";

export const TRANSACTIONS_REQUEST = "TRANSACTIONS_REQUEST";
export const TRANSACTIONS_SUCCESS = "TRANSACTIONS_SUCCESS";
export const TRANSACTIONS_FAIL = "TRANSACTIONS_FAIL";

export const SUBSCRIBTIONS_REQUEST = "SUBSCRIBTIONS_REQUEST";
export const SUBSCRIBTIONS_SUCCESS = "SUBSCRIBTIONS_SUCCESS";
export const SUBSCRIBTIONS_FAIL = "SUBSCRIBTIONS_FAIL";

export const TIERS_REQUEST = "TIERS_REQUEST";
export const TIERS_SUCCESS = "TIERS_SUCCESS";
export const TIERS_FAIL = "TIERS_FAIL";

export const SUBSCRIBE_TIER_REQUEST = "SUBSCRIBE_TIER_REQUEST";
export const SUBSCRIBE_TIER_SUCCESS = "SUBSCRIBE_TIER_SUCCESS";
export const SUBSCRIBE_TIER_FAIL = "SUBSCRIBE_TIER_FAIL";
export const SUBSCRIBE_TIER_CLEAR = "SUBSCRIBE_TIER_CLEAR";

export const UNSUBSCRIBE_TIER_REQUEST = "UNSUBSCRIBE_TIER_REQUEST";
export const UNSUBSCRIBE_TIER_SUCCESS = "UNSUBSCRIBE_TIER_SUCCESS";
export const UNSUBSCRIBE_TIER_FAIL = "UNSUBSCRIBE_TIER_FAIL";

export const TIER_DATE_PERIOD_REQUEST = "TIER_DATE_PERIOD_REQUEST";
export const TIER_DATE_PERIOD_SUCCESS = "TIER_DATE_PERIOD_SUCCESS";
export const TIER_DATE_PERIOD_FAIL = "TIER_DATE_PERIOD_FAIL";


export const COUPON_CODE_REQUEST = "COUPON_CODE_REQUEST"
export const COUPON_CODE_SUCCESS = "COUPON_CODE_SUCCESS"
export const COUPON_CODE_FAIL = "COUPON_CODE_FAIL"
export const COUPON_CODE_RESET = "COUPON_CODE_RESET"