import React, { createRef, useContext, useEffect, useState } from "react";
import { Title } from "@components/shared/atoms/Typography";
import LogoWhite from "@assets/images/choice-logo-white.svg";
import Image from "@shared/atoms/Image/index";
import Slider from "react-slick";
import PlaylistVideoCard from "../PlaylistVideoCard";
import debounce from "lodash/debounce";
import { Context } from "@context/context";

import "./index.less";
import { SampleNextArrow, SamplePrevtArrow } from "../CustomSlickArrow";

const openedPadd = {
  paddingBottom: "12rem"
}

const HomeWelcome = ({ data, playlistData, show }) => {
  const [isMoreInfoOpened, setIsMoreInfoOpened] = useState(false);
  const [sliderRefs, setSliderRefs] = useState([]);
  const [playlistCardRefs, setPlaylistCardRefs] = useState([]);

  const { copyingItem, showSubBarPrototype } = useContext(Context);

  useEffect(() => {
    if (playlistData && playlistData.series) {
      setSliderRefs((sliderRef) =>
        Array(playlistData.series?.length)
          .fill()
          .map((_, i) => {
            return sliderRef[i] || createRef(sliderRef[i]);
          })
      );
    }
  }, [playlistData]);

  useEffect(() => {
    if (playlistData) {
      const allVideos = playlistData?.series?.map((serie) => serie.videos);

      setPlaylistCardRefs((playlistCardRef) =>
        Array(allVideos.length)
          .fill()
          .map((_, i) => {
            return playlistCardRef[i] || createRef(playlistCardRef[i]);
          })
      );
    }
  }, [playlistData]);

  function moreInfoClickHandler() {
    setIsMoreInfoOpened((prevState) => !prevState);
  }

  const onWheelSlider = debounce((e, ref) => {
    if (!ref.current) return;

    if (e.deltaX > 0) {
      ref?.current?.slickNext();
    } else if (e.deltaX < 0) {
      ref?.current?.slickPrev();
    }
  }, 20);

  const playlistVideoHandler = (video) => {
    if (video.is_external) {
      window.open(video.src);
    }
  };

  const copyPlaylistVideoHandler = (video) => {
    let url =
      window.origin +
      `/playlists?playlist=${playlistData.id}&video=${video.id}`;
    navigator.clipboard.writeText(url);
  };

  const addToPlaylist = (video) => {
    // Show subbar to choose target playlist
    copyingItem(video);
  };

  return (
    <div className="home-welcome-container" style={showSubBarPrototype ? openedPadd : {}}>
      <>
        {show && (
          <div>
            <Image
              preview={false}
              className="choice-logo white-logo"
              src={LogoWhite}
              alt="white-logo"
            />
            <Title level={3}>{data?.title}</Title>

            <div className="description-container">
              <div className="description-content" dangerouslySetInnerHTML={{ __html: data?.description }}>
              </div>
            </div>
            {data?.images[0]?.path && (
              <a
                key={data?.images[0]?.path}
                href={data?.images[0]?.redirect_url}
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  preview={false}
                  src={data?.images[0]?.path}
                  alt="terms of use"
                  className="home-content-img"
                />
              </a>
            )}

            {data?.images[1]?.path && (
              <a
                key={data?.images[1]?.path}
                href={data?.images[1]?.redirect_url}
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  preview={false}
                  src={data?.images[1]?.path}
                  alt="privacy policy"
                  className="home-content-img"
                />
              </a>
            )}

            {data?.images[2]?.path && (
              <a
                key={data?.images[2]?.path}
                href={data?.images[2]?.redirect_url}
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  preview={false}
                  src={data?.images[2]?.path}
                  alt="privacy policy"
                  className="home-content-img"
                />
              </a>
            )}
          </div>
        )}

        <div className="data-container">
          {playlistData &&
            playlistData?.series?.map((serie, idx) => {
              return (
                <Slider
                  infinite={false}
                  swipe={!isMoreInfoOpened}
                  arrows={!isMoreInfoOpened}
                  nextArrow={ <SampleNextArrow /> }
                  prevArrow={ <SamplePrevtArrow /> }
                  ref={sliderRefs[idx]}
                  key={idx}
                >
                  {serie.videos.map((video, index) => {
                    return (
                      <div
                        onWheel={(e) => onWheelSlider(e, sliderRefs[idx])}
                        className="video-container"
                        key={video.id}
                        ref={playlistCardRefs[idx]}
                        id={index}
                      >
                        <PlaylistVideoCard
                          coverImg={video.image}
                          title={video.title}
                          onClick={() => playlistVideoHandler(video)}
                          // sharePlaylistHandler={() =>
                          //   sharePlaylistItemHandler(video, serie)
                          // }
                          // reportPlaylistItemHandler={() =>
                          //   reportPlaylistItem(video)
                          // }
                          // deleteHandler={() =>
                          //   deletePlaylistVideoHandler(video)
                          // }
                          // editHandler={() =>
                          //   editPlaylistVideoHandler(video, serie.name)
                          // }
                          copyHandler={() => copyPlaylistVideoHandler(video)}
                          onAddToPlaylist={() => addToPlaylist(video)}
                          infoBoxSubtitle={
                            serie.name ? serie.name : "No subtitle"
                          }
                          description={video.description}
                          moreButtons={{
                            moreDescription: video.more_description,
                            moreInfoTitle: video.more_info_title,
                            morePhoto: video.more_photo,
                            moreTitle: video.more_title,
                            goToLink: video.go_to,
                            goToTitle: video.go_to_title,
                            moreInfoClickHandler,
                            isMoreInfoOpened,
                          }}
                          isHomePlaylist={true}
                        />
                      </div>
                    );
                  })}
                </Slider>
              );
            })}
        </div>
      </>
    </div>
  );
};

export default HomeWelcome;
