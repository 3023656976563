import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  createRef,
  useRef,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Context } from "@context/context";
import querystring from "query-string";
import SearchModalPrototype from "@components/organisms/modals/SearchModalPrototype";
import { useSelector, useDispatch } from "react-redux";
import HeaderPrototype from "../HeaderPrototype";
import ChannelCard from "@shared/molecules/ChannelCard";
import defaultImg from "@assets/images/default_img.png";
import {
  getEpgChannels,
  getChannelById,
  resetChannelsById,
  directShareChannelEpg,
} from "../../../../redux/actions/channelActions";
import SpinnerPrototype from "../../shared/atoms/SpinnerPrototype";
import moment from "moment";
import VideoPlayerWrapper from "../VideoPlayerWrapper";
import VideoPlayer from "../../shared/atoms/VideoPlayer";
import ShareModalPrototype from "../modals/ShareModalPrototype";
import ReportContentModalPrototype from "../modals/ReportContentModalPrototype";
import Slider from "react-slick";
import ScrollTop from "@components/shared/atoms/ScrollTop";
import { Home } from "../../../pages/Home/index";
import TierBuyModalPrototype from "../modals/TierBuyModalPrototype";
import InvalidCountryModalPrototype from "../modals/InvalidCountryPrototype";
// import SimpleWarningModal from "../modals/SimpleWarningModal";
import { selectInvalidCountryFlag } from "@redux/reducers/countryReducer";
import { clearInvalidCountryError } from "@redux/actions/countryActions";
import { view, viewStart } from "@redux/actions/viewActions";
import tvImage from "@assets/icons/tv-icon-new.svg";
import useToggleWelcomeMsg from "hooks/useToggleWelcomeMsg";
import debounce from "lodash/debounce";
import { CHANNEL_BY_ID_RESET } from "@redux/constants/channelConstants";
import UnsubscribeTierModalPrototype from "../modals/UnsubscribeTierModalPrototype";
import {
  SampleNextArrow,
  SamplePrevtArrow,
} from "@components/shared/molecules/CustomSlickArrow";
import { useTranslation } from "react-i18next";
import SuccessModalPrototype from "../modals/SuccessModalPrototype";
import Spacer from "@components/shared/atoms/Spacer";

import { getPlaylistById } from "@redux/actions/playlistsActions";
import MobilePlaylistsPrototype from "../MobilePlaylistsPrototype";
import { COPY_ITEM } from "const/playlists";
import { isMobile } from "react-device-detect";
import SimpleWarningModal from "../modals/SimpleWarningModal";
import Alert from "@components/shared/atoms/Alert";

import { LOCALSTORAGE } from "const";
import { localStorageService } from "@modules/services/localStorage.service";
import useScrollPositionFromStorage from "hooks/useScrollPositionFromStorage";
import "./index.less";
import localforage from "localforage";

const MobileTiers = () => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const history = useHistory();
  const isInvalidCountryError = useSelector(selectInvalidCountryFlag);
  const parsedQueryString = querystring.parse(search);

  // const [uniqueTitlesArr, setUniqueTitlesArr] = useState([]);
  // const [uniqueSubTitlesArr, setUniqueSubTitlesArr] = useState([]);
  // const [groupedEpgs, setGroupedEpgs] = useState([]);
  const [channelCardRefs, setChannelCardRefs] = useState([]);
  const [liveEpg, setLiveEpg] = useState(null);
  // const [epgsWithoutLive, setEpgsWithoutLive] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [reportType, setReportType] = useState("App//Channel");
  const [reportableId, setReportableId] = useState(parsedQueryString.channel);
  const [sliderRefs, setSliderRefs] = useState([]);
  const [foundEpg, setFoundEpg] = useState(false);
  const [notFoundError, setNotFoundError] = useState(false);
  const epgId = parsedQueryString.epgId;

  const { epgs: seriesEpgs, loading: epgsLoading } = useSelector(
    (state) => state.epgChannels
  );

  const { channel } = useSelector((state) => state.channelById);
  const { toggleWelcome } = useToggleWelcomeMsg("hide_tv_welcome");

  const { message: viewMsg } = useSelector((state) => state.view);

  const dispatch = useDispatch();

  const videoWrapperRef = useRef(null);

  const {
    showTermsImagesPrototype,
    setShowTermsImagesPrototype,
    setShowChannelsPrototype,
    showChannelsPrototype,
    showVideoPlayerPrototype,
    setShowVideoPlayerPrototype,
    setShowShareModalPrototype,
    tabletFrameRef,
    setShowSuccessModalPrototype,
    setCopiedPlaylist,
  } = useContext(Context);

  useEffect(() => {
    return () => {
      dispatch(resetChannelsById());
    };
  }, [dispatch]);

  const onWheelSlider = debounce((e, ref) => {
    if (!ref.current) return;

    if (e.deltaX > 0) {
      ref?.current?.slickNext();
    } else if (e.deltaX < 0) {
      ref?.current?.slickPrev();
    }
  }, 20);



  // const uniqueTitlesHandler = useCallback(() => {
  //   if (epgsWithoutLive && parsedQueryString.channel) {
  //     let unique = [...new Set(epgsWithoutLive?.map((epg) => epg.title))];
  //     setUniqueTitlesArr(unique);

  //     let uniqueSubTitles = [
  //       ...new Set(epgsWithoutLive?.map((epg) => epg.subtitle)),
  //     ];
  //     setUniqueSubTitlesArr(uniqueSubTitles);
  //   }
  // }, [epgsWithoutLive, parsedQueryString.channel]);

  // const createGroupedEpgs = useCallback(() => {
  //   let groupedEpgsArr = [];
  //   uniqueTitlesArr?.forEach((unique) => {
  //     let filterdChannels = epgsWithoutLive
  //       ?.filter((epg) => {
  //         if (epg.startTime && epg.startTime < moment.now())
  //           return epg.title === unique;

  //         return null;
  //       })
  //       .reverse();

  //     groupedEpgsArr.push(filterdChannels);

  //     groupedEpgsArr.sort((a, b) => {
  //       if (a[0]?.startTime && b[0]?.startTime)
  //         return b[0]?.startTime - a[0]?.startTime;
  //       return (
  //         new Date(b[0]?.fullStartTimeString) -
  //         new Date(a[0]?.fullStartTimeString)
  //       );
  //     });
  //   });
  //   uniqueSubTitlesArr?.forEach((unique) => {
  //     let filterdChannels = epgsWithoutLive?.filter((epg) => {
  //       if (epg.startTime === null) return epg.subtitle === unique;
  //       return null;
  //     });
  //     groupedEpgsArr.push(filterdChannels);
  //   });

  //   setGroupedEpgs(groupedEpgsArr);
  // }, [epgsWithoutLive, uniqueTitlesArr, uniqueSubTitlesArr]);

  const findEpgItemPositionToScroll = (series) => {
    let i = 0;
    let found = false;
    while (i < series.length) {
      const currentEpgArr = series[i].epg;
      const foundEpgIndex = currentEpgArr.findIndex(({ id }) => id === +epgId);

      if (foundEpgIndex != -1) {
        setFoundEpg({
          slider: i,
          sliderItemIndex: foundEpgIndex,
        });
        found = true;
        break;
      }
      i++;
    }

    if (!found) {
      setNotFoundError(true);
    }
  };

  const scrollTop = (position) => {
    if (isMobile) {
      window.scroll({
        top: position,
      });
    } else {
      tabletFrameRef?.current?.scroll({
        top: position,
      });
    }
  };

  const scrollToEpgItem = () => {
    const { slider, sliderItemIndex } = foundEpg;
    const foundSlider = sliderRefs[slider]?.current;

    if (foundSlider) {
      const topPosition =
        foundSlider.innerSlider.list.getBoundingClientRect().top;

      scrollTop(topPosition);
      foundSlider.slickGoTo(sliderItemIndex);
    }
  };

  useEffect(() => {
    if (seriesEpgs?.series && seriesEpgs?.series?.length && channel?.id) {
      setChannelCardRefs((channelCardRef) =>
        Array(seriesEpgs?.series?.length)
          .fill()
          .map((_, i) => {
            return channelCardRef[i] || createRef(channelCardRef[i]);
          })
      );
      setSliderRefs((sliderRef) =>
        Array(seriesEpgs?.series?.length)
          .fill()
          .map((_, i) => {
            return sliderRef[i] || createRef(sliderRef[i]);
          })
      );
    }
  }, [seriesEpgs?.series, channel]);

  // useEffect(() => {
  //   uniqueTitlesHandler();
  // }, [uniqueTitlesHandler]);

  // useEffect(() => {
  //   createGroupedEpgs();
  // }, [createGroupedEpgs]);

  useEffect(() => {
    if (parsedQueryString.channel) {
      if (parsedQueryString.isPlaylistInTier) {
        dispatch(getPlaylistById(parsedQueryString?.channel));
        dispatch(view("App\\Playlist", parsedQueryString?.channel));
      } else {
        dispatch(getChannelById(parsedQueryString?.channel));
        dispatch(getEpgChannels(parsedQueryString?.channel));
        dispatch(view("App\\Channel", parsedQueryString?.channel));
      }

      setShowChannelsPrototype(true);
      setShowTermsImagesPrototype(false);
    }
  }, [
    dispatch,
    parsedQueryString.channel,
    parsedQueryString.isPlaylistInTier,
    setShowChannelsPrototype,
    setShowTermsImagesPrototype,
  ]);

  useEffect(() => {
    if (parsedQueryString.index && channel && channelCardRefs) {
      channelCardRefs[parsedQueryString.index]?.current?.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, [parsedQueryString?.index, channel, channelCardRefs]);

  // useEffect(() => {
  //   if (epgsLoading) {
  //     setGroupedEpgs([]);
  //   }
  // }, [epgsLoading]);

  useEffect(() => {
    return () => {
      dispatch({ type: CHANNEL_BY_ID_RESET });
    };
  }, [dispatch]);

  useEffect(() => {
    if (!seriesEpgs?.series.length || !epgId || foundEpg) return null;

    findEpgItemPositionToScroll(seriesEpgs.series);
  }, [seriesEpgs]);

  useEffect(() => {
    if (sliderRefs.length && epgId && foundEpg) {
      scrollToEpgItem();
    }
  }, [sliderRefs, epgId, foundEpg]);

  const playVideoHandler = (epg, epgUrl) => {
    if (channel.liveStreamUrl) {
      const url =
        channel.dvrStreamBaseUrl +
        "-" +
        epg.startTime / 1000 +
        "-" +
        (parseInt(epg.stopTime / 1000) - parseInt(epg.startTime / 1000)) +
        "." +
        channel.dvrStreamBaseUrlExtension;
      // setShowChannelsPrototype(false);
      setShowVideoPlayerPrototype(true);
      dispatch(viewStart(viewMsg?.viewActionId));
      setVideoUrl(url);
      setVideoTitle(epg?.title);
      if (videoWrapperRef?.current) {
        videoWrapperRef.current.style.display = "block";
      }
    } else if (epg.url) {
      const URL = epgUrl ? epgUrl : epg.url;
      window.open(URL);
    }
  };

  const playLiveStreamHandler = (epg) => {
    const url = channel.liveStreamUrl;
    const item = epg || channel

    setShowVideoPlayerPrototype(true);
    dispatch(viewStart(viewMsg?.viewActionId));
    setVideoUrl(url);
    setVideoTitle(item?.title ? item?.title : "Live Stream");
    if (videoWrapperRef?.current) {
      videoWrapperRef.current.style.display = "block";
    }
  };

  const shareChannelItemHandler = (channel, index) => {
    setShowShareModalPrototype(true);
    history.push(`tiers?channel=${channel.id}&index=${index}`);
  };

  const shareChannelHandler = (channel) => {
    setShowShareModalPrototype(true);
    history.push(`tiers?channel=${channel.id}`);
  };

  const reportChannelItemHandler = (itemId) => {
    setReportType("App//ChannelItem");
    setReportableId(itemId);
  };

  const epgDirectShareHandler = (epg) => {
    if (epg) {
      const url = `/tiers?channel=${epg.channelId}`;

      Promise.resolve(dispatch(directShareChannelEpg(epg.id, url))).then(() => {
        setShowSuccessModalPrototype(true);
      });
    }
  };

  const handleCopy = (epgId) => {
    setCopiedPlaylist({
      type: COPY_ITEM.EPG_ITEM,
      copiedItem: {
        epgId,
        url: `tiers?channel=${parsedQueryString.channel}&epgId=${epgId}`,
      },
    });
  };

  const handlCopyEpg = (epgs) => {
    const epgsIdUrlArr = epgs.map((epg) => ({
      id: epg.id,
      url: `tiers?channel=${parsedQueryString.channel}&epgId=${epg.id}`,
    }));

    setCopiedPlaylist({
      type: COPY_ITEM.EPG,
      copiedItem: {
        epgs: epgsIdUrlArr,
        name: epgs[0].title,
      },
    });
  };

  useScrollPositionFromStorage();

  const isOnlyPropertyInUrlQuery = (queryKey) => {
    return Object.keys(parsedQueryString).length === 1 && parsedQueryString[queryKey];
  }

  useEffect(() => {
    if(!isOnlyPropertyInUrlQuery("channel")) return;

    let scrollTop = localStorageService().get(LOCALSTORAGE.TIER_SCROLL_POSISION) || 0;

    if (isMobile) {
      window.scroll({ top: scrollTop });
    } else {
        tabletFrameRef?.current?.scroll({
          top: scrollTop,
        });
    }
  }, [seriesEpgs, tabletFrameRef])

  const isLastSeenChannelId = (channelId)  => {
    const lastSeenChannelId =  localStorageService().get(LOCALSTORAGE.LAST_SEEN_CHANNEL_ID)
    return channelId === lastSeenChannelId;
  }

  const saveLastSeenChannelscrolledChannelId = async (epgSliderState) => {
    const { name, epgId } = epgSliderState
    const lastSeenBroadcastChannelEpgSliderStates = await localforage.getItem(LOCALSTORAGE.LAST_SEEN_CHANNEL_STATE) || {};
    
    await localforage.setItem(LOCALSTORAGE.LAST_SEEN_CHANNEL_STATE, {
      ...lastSeenBroadcastChannelEpgSliderStates,
      [name]: epgId
    })
  }

  const restoreLastSeenChannelSlidersState = async () => {
    const lastSeenChannelSlidersStates = await localforage.getItem(LOCALSTORAGE.LAST_SEEN_CHANNEL_STATE) || {}
    const foundResultsToScrollAt = [];

    if(seriesEpgs?.series) {
      Object.keys(lastSeenChannelSlidersStates).forEach((epgSeriesName) => {
        const epgId = lastSeenChannelSlidersStates[epgSeriesName];
        let foundSerieIndex = null;

        const foundChannelSerie = seriesEpgs?.series.find((serie, serieIndex) => {
          if(serie.name === epgSeriesName) {
            foundSerieIndex = serieIndex;
            return true;
          }
        });

        const foundChannelSerieEpgIndex = foundChannelSerie.epg.findIndex((epg) => epg.id === epgId);

        if(foundChannelSerieEpgIndex) {
          foundResultsToScrollAt.push({
            serieIndex: foundSerieIndex,
            serieEpgIndex: foundChannelSerieEpgIndex,
          });
        }

      })
    }

    foundResultsToScrollAt.forEach(({serieIndex, serieEpgIndex}) => {
      const foundSlider = sliderRefs[serieIndex]?.current;

      if (foundSlider) {
        foundSlider.slickGoTo(serieEpgIndex);
      }
    })
  }

  const handleAfterSlide = (curSlideIndex, seriesEpg) => {
    saveLastSeenChannelscrolledChannelId({
      name: seriesEpg.name,
      epgId: seriesEpg.epg[curSlideIndex].id,
    });
  }

  const saveNewChannelIdAndRemoveOldState = async (channelId) => {
    localStorageService().set(LOCALSTORAGE.LAST_SEEN_CHANNEL_ID, channelId);
    await localforage.removeItem(LOCALSTORAGE.LAST_SEEN_CHANNEL_STATE);
  }

  const ifNewChannelSaveState = async () => {
    const isLastSeen = isLastSeenChannelId(parsedQueryString?.channel)
    if(!isLastSeen) {
      saveNewChannelIdAndRemoveOldState(parsedQueryString?.channel);
    }
  }

  const scrollSavedSlidesIfLastSeenChannel = async () => {
    if(isLastSeenChannelId(parsedQueryString?.channel)) {
      restoreLastSeenChannelSlidersState();
    }
  }

  useEffect(() => {
    if(isOnlyPropertyInUrlQuery("channel")) {
      scrollSavedSlidesIfLastSeenChannel();
    }
  }, [parsedQueryString?.channel, sliderRefs.length])

  useEffect(() => {
    ifNewChannelSaveState()
  }, [parsedQueryString?.channel])


  if (isInvalidCountryError) {
    return (
      <InvalidCountryModalPrototype
        isVisible={isInvalidCountryError}
        closeHandler={() => {
          dispatch(clearInvalidCountryError());
          history.replace("/");
        }}
      />
    );
  }

  if (notFoundError) {
    return (
      <SimpleWarningModal
        isVisible={notFoundError}
        text={t("the_episode_is_no_longer_available")}
        closeHandler={() => {
          history.replace("/");
        }}
      />
    );
  }

  return (
    <div className="mobile-tiers">
      {!showVideoPlayerPrototype && <ScrollTop />}
      <TierBuyModalPrototype />
      <UnsubscribeTierModalPrototype />
      <SuccessModalPrototype>
        {t("ok_successfully_added_to_your_direct_publish_channel")}
      </SuccessModalPrototype>
      <ShareModalPrototype />
      <ReportContentModalPrototype
        reportableType={reportType}
        reportableId={reportableId}
      />
      <SearchModalPrototype isChannelListSearch={true} />
      {epgsLoading && <SpinnerPrototype />}
      {showTermsImagesPrototype && (
        <HeaderPrototype
          name={"tiers"}
          image={tvImage}
          toggleWelcomeMessage={toggleWelcome}
          headerPositionStyle={{ position: "absolute" }}
        />
      )}
      {showChannelsPrototype && (
        <>
          {channel && seriesEpgs && (
            <HeaderPrototype
              name={channel?.name}
              image={channel?.logoId}
              shareHandler={() => shareChannelHandler(channel)}
              buttonText={t("subscribe")}
              // reportHandler={() => {
              //   setShowReportContentModalPrototype(true);
              // }}
              isChannelsHeader={true}
              headerPositionStyle={{ position: "absolute" }}
            />
          )}

          {!parsedQueryString.isPlaylistInTier && <Spacer />}

          {epgsLoading && <SpinnerPrototype />}
          {(liveEpg || channel?.liveStreamUrl) && (
            <ChannelCard
              onClick={() => playLiveStreamHandler(liveEpg)}
              coverImg={
                channel?.live_thumb?.startsWith("http")
                  ? channel?.live_thumb
                  : defaultImg
              }
              key={liveEpg?.id}
              title={
                liveEpg?.title
                  ? `${liveEpg?.title} - ( Live Stream )`
                  : ` ( Live Stream )`
              }
              infoBoxSubtitle={liveEpg?.episode}
              infoBoxTime={moment(liveEpg?.startTime).format(
                "DD-MM-YYYY HH:mm"
              )}
              infoBoxDescription={liveEpg?.description}
              shareChannelHandler={() => shareChannelHandler(channel)}
              reportChannelItemHandler={() =>
                reportChannelItemHandler(channel?.id)
              }
              directShareHandler={() => epgDirectShareHandler(liveEpg)}
            />
          )}
          {seriesEpgs?.series?.map((seriesEpg, index) => {
            return (
              <div ref={channelCardRefs[index]} id={index} key={index}>
                <Slider
                  infinite={false}
                  ref={sliderRefs[index]}
                  nextArrow={<SampleNextArrow />}
                  prevArrow={<SamplePrevtArrow />}
                  afterChange={(curSlideIndex) => handleAfterSlide(curSlideIndex, seriesEpg)}
                >
                  {seriesEpg?.epg?.map((epg) => {
                    return (
                      <div
                        onWheel={(e) => onWheelSlider(e, sliderRefs[index])}
                        key={epg.id}
                      >
                        <ChannelCard
                          onClick={() => playVideoHandler(epg, seriesEpg?.epg_url)}
                          coverImg={
                            epg?.poster?.startsWith("http")
                              ? epg?.poster
                              : defaultImg
                          }
                          title={epg.title}
                          infoBoxTime={
                            epg.fullStartTimeString
                              ? moment(epg?.fullStartTimeString).format(
                                  "DD-MM-YYYY HH:mm"
                                )
                              : epg.startTime
                              ? moment(epg.startTime).format("DD-MM-YYYY HH:mm")
                              : epg.subtitle
                          }
                          infoBoxSubtitle={epg.episode}
                          infoBoxDescription={epg.description}
                          shareChannelHandler={() =>
                            shareChannelItemHandler(channel, index)
                          }
                          reportChannelItemHandler={() =>
                            reportChannelItemHandler(channel.id)
                          }
                          directShareHandler={() => epgDirectShareHandler(epg)}
                          copyItem={() => handleCopy(epg.id)}
                          copyEpg={() => handlCopyEpg(seriesEpgs)}
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            );
          })}
        </>
      )}
      {parsedQueryString.isPlaylistInTier && (
        <MobilePlaylistsPrototype isPlaylistInTier={true} />
      )}
      {showTermsImagesPrototype && <Home />}
      {showVideoPlayerPrototype && (
        <VideoPlayerWrapper
          className="tier-video-player-wrapper"
          setVideoUrl={setVideoUrl}
          videoUrl={videoUrl}
          title={videoTitle}
          ref={videoWrapperRef}
        >
          <VideoPlayer
            url={videoUrl}
            controls={true}
            playing={true}
            isVideoLive={true}
            width={"100%"}
          />
        </VideoPlayerWrapper>
      )}
    </div>
  );
};

export default MobileTiers;
