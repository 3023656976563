import React, { createRef, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Context } from "@context/context";
import { DroppableTypes } from "@context/dndContextPrototype";
import SorterRow from "./SorterRow";
import NewVideo from "./NewVideo";
import DraggableList from "../lists/DraggableList";
import AddNewSeriePrototype from "../modals/AddNewSeriePrototype";
import Spacer from "@components/shared/atoms/Spacer";
import SlideArrows from "@components/shared/molecules/SlideArrows";
import addImg from "@assets/icons/add.svg";
import copyImg from "@assets/icons/copy.svg";
import editImg from "@assets/icons/edit.svg";
import deleteImg from "@assets/icons/deleteContext.svg";

import "./index.less";
import UpdateSerieDetailsModal from "../modals/UpdateSerieDetailsModal";
import SpinnerPrototype from "@components/shared/atoms/SpinnerPrototype";
import ContextMenuPrototype, {
  ContextMenuOption,
} from "@components/shared/molecules/ContextMenuPrototype";
import { useContextMenu } from "hooks/useContextMenu";

const SortPlaylistPrototype = () => {
  const {
    setShowAddPlaylistItemModalPrototype,
    setShowEditPlaylistVideoModalPrototype,
    setPlaylistVideoPrototype,
    showUpdateSerieTitleModal,
    setShowUpdateSerieTitleModal,
    setCurrentSerieItem,
    currentSerieItem,
    setShowDeleteItemModalPrototype,
    setDeleteSerieInGroupItem,
    setDeletableItemPrototype,
  } = useContext(Context);

  const {
    isVisible,
    xPos,
    yPos,
    closeContextMenu,
    openContextMenu,
    contextMenuHeight,
    contextMenuClickWithDebounce,
    onTouchEnd,
    onTouchMove,
  } = useContextMenu();

  const [showAddNewSerieModal, setShowAddNewSerieModal] = useState(false);
  const [series, setSeries] = useState(null);
  const [sorterRowRefs, setSorterRowRefs] = useState([]);
  const [rowChildOverflow, setRowChildOverflow] = useState([]);
  const [currentVideo, setCurrentVideo] = useState({});
  const [currentSerie, setCurrentSerie] = useState();
  const [videoOrderId, setVideOrderId] = useState(0);

  const { playlist, loading } = useSelector((state) => state.playlistById);

  const playlistById = useSelector((state) => state?.playlistById);
  const playlistItem = playlistById.playlist?.data;

  const userProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (playlist?.data?.series) {
      setSorterRowRefs((sorterRowRef) =>
        Array(playlist?.data?.series?.length)
          .fill()
          .map((_, i) => {
            return sorterRowRef[i] || createRef(sorterRowRef[i]);
          })
      );
    }
  }, [playlist]);

  function isOverflown(element) {
    return (
      element?.scrollHeight > element?.clientHeight ||
      element?.scrollWidth > element?.clientWidth
    );
  }

  useEffect(() => {
    if (sorterRowRefs.length) {
      const refs = [];
      sorterRowRefs.forEach((rowRef, i) => {
        refs.push({
          isOverflown: isOverflown(rowRef?.current?.firstChild),
        });
      });
      setRowChildOverflow(refs);
    }
  }, [sorterRowRefs]);

  const openAddNewSeriesGroup = () =>
    setShowAddPlaylistItemModalPrototype(true);

  const openEditVideo = (video, serieName) => {
    setShowEditPlaylistVideoModalPrototype(true);
    setPlaylistVideoPrototype({ ...video, series_name: serieName });
  };

  const addNewSerie = (series) => {
    setShowAddNewSerieModal(true);
    setSeries(series);
    closeContextMenu();
  };

  const addNewSerieLeft = (series) => {
    let currentVideoOrder = videoOrderId - 1;
    setVideOrderId(currentVideoOrder);

    setShowAddNewSerieModal(true);
    setSeries(series);
    closeContextMenu();
  };

  const addNewSerieRight = (series) => {
    let currentVideoOrder = videoOrderId;
    setVideOrderId(currentVideoOrder);

    setShowAddNewSerieModal(true);
    setSeries(series);
    closeContextMenu();
  };

  const slideHandler = (ref, direction) => {
    const sorterRowEl = ref?.current?.firstChild;

    if (direction === "left") {
      sorterRowEl.scrollLeft -= 200;
    } else {
      sorterRowEl.scrollLeft += 200;
    }
  };

  function withIsContextMenuVisible(cb) {
    return function withClose() {
      cb();
    };
  }

  const editVideoHandler = () => {
    //console.log(currentVideo)
    // if (video?.is_home) {
    //   // setShowUpdateSerieTitleModal(true);
    //   setCurrentSerieItem(serie)
    //   setCurrentVideo(video)
    //   console.log(currentSerieItem);
    //   // setCurrentVideo();
    // }
    openEditVideo(currentVideo, currentSerieItem.name);

    closeContextMenu();
  };

  const deleteVideoHandler = () => {
    setShowDeleteItemModalPrototype(true);
    setDeleteSerieInGroupItem(currentVideo);
    setPlaylistVideoPrototype(currentVideo);
    setDeletableItemPrototype("video");

    closeContextMenu();
  };

  const contextMenuOptions = (
    <>
      {
        // don't show context item if playlist is default or other user's public playlist
        Number(playlistItem?.user_id) ===
          Number(userProfile?.profile?.data?.id) && (
          <>
            <ContextMenuOption
              onClick={withIsContextMenuVisible(() =>
                addNewSerieLeft(currentSerieItem)
              )}
              icon={copyImg}
              label="Insert Left <-"
            />

            <ContextMenuOption
              onClick={withIsContextMenuVisible(() =>
                addNewSerieRight(currentSerieItem)
              )}
              icon={copyImg}
              label="Insert Right ->"
            />
          </>
        )
      }

      {
        // don't show context item if playlist is default or other user's public playlist
        Number(playlistItem?.user_id) ===
          Number(userProfile?.profile?.data?.id) && (
          <ContextMenuOption
            label={"Edit Episode"}
            icon={editImg}
            onClick={withIsContextMenuVisible(editVideoHandler)}
          />
        )
      }

      {Number(playlistItem?.user_id) ===
        Number(userProfile?.profile?.data?.id) && (
        <ContextMenuOption
          label={"Delete Episode"}
          icon={deleteImg}
          onClick={withIsContextMenuVisible(deleteVideoHandler)}
        />
      )}
    </>
  );

  return (
    <div className="sorter-prototype">
      <Spacer />
      <NewVideo isSeriesItem={true} onClick={openAddNewSeriesGroup} />
      {showAddNewSerieModal && (
        <AddNewSeriePrototype
          series={series}
          isVisible={showAddNewSerieModal}
          orderId={videoOrderId}
          closeModal={() => {
            setShowAddNewSerieModal(false);
            setSeries(null);
            setCurrentSerie();
          }}
        />
      )}
      {/* <NewVideo isSeriesItem={true} onClick={openAddNewSeriesGroup} /> */}
      {isVisible && (
        <ContextMenuPrototype
          isVisible={isVisible}
          xPos={xPos}
          yPos={yPos}
          handleClose={closeContextMenu}
          arrowDir={contextMenuHeight > yPos / 3 ? "up" : "down"}
        >
          {contextMenuOptions}
        </ContextMenuPrototype>
      )}

      {loading ? (
        <SpinnerPrototype />
      ) : (
        <DraggableList
          listItems={playlist?.data?.series || null}
          propertySelector={(serie) => serie.videos.length}
          droppableId={DroppableTypes.SorterMode}
          type="inner-videos"
        >
          {(serie, provided, snapshot, rubric) => {
            const currentIndex = rubric.source.index;
            return (
              <div ref={sorterRowRefs[currentIndex]}>
                <SorterRow
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  dragHandleProps={provided.dragHandleProps}
                  isDragging={snapshot.isDragging}
                  seriesName={serie.name}
                  serieId={serie.id}
                  serieItem={serie}
                >
                  {/* {serie?.image && (
                    <SorterRow.Item
                      thumbnail={serie?.image}
                      title={serie?.description}
                      key={serie?.id}
                      hasHomeIcon={serie?.video?.is_home}
                      onClick={() => {
                        setShowUpdateSerieTitleModal(true);
                        setCurrentSerieItem(serie);
                      }}
                    />
                  )} */}
                  <DraggableList
                    droppableId={`${DroppableTypes.SorterModeInnerVideos}:${serie.id}`}
                    listItems={serie.videos || null}
                    direction="horizontal"
                    style={{ display: "flex" }}
                  >
                    {(video, provided, snapshot, rubric) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <SorterRow.Item
                          thumbnail={video?.image}
                          title={video.title}
                          key={video.id}
                          hasHomeIcon={video?.is_home}
                          serieItem={serie}
                          // onClick={() => {
                          //   setCurrentVideo(video);
                          //   editVideoHandler(video, serie)
                          //   setVideOrderId(rubric?.source?.index + 1);
                          // }}
                          onContextMenu={(e) => {
                            openContextMenu(e);
                            setCurrentVideo(video);
                            setCurrentSerieItem(serie);
                            setVideOrderId(rubric?.source?.index + 1);
                          }}
                          onTouchStart={(e) => {
                            contextMenuClickWithDebounce(e, () => {}, 700)
                            setCurrentVideo(video);
                            setCurrentSerieItem(serie);
                            setVideOrderId(rubric?.source?.index + 1);
                          }}
                          onTouchMove={onTouchMove}
                          onTouchEnd={onTouchEnd}
                        />
                      </div>
                    )}
                  </DraggableList>
                    <NewVideo
                      isSeriesItem={false}
                      onClick={() => addNewSerie(serie)}
                    />
                </SorterRow>
                {rowChildOverflow[currentIndex]?.isOverflown && (
                  <SlideArrows
                    slideRightHandler={() =>
                      slideHandler(sorterRowRefs[currentIndex])
                    }
                    slideLeftHandler={() =>
                      slideHandler(sorterRowRefs[currentIndex], "left")
                    }
                  />
                )}
              </div>
            );
          }}
        </DraggableList>
      )}

      {showUpdateSerieTitleModal && (
        <UpdateSerieDetailsModal
          isVisible={showUpdateSerieTitleModal}
          serieItems={currentSerieItem}
          closeModal={() => {
            setShowUpdateSerieTitleModal(false);
            setSeries(null);
          }}
        />
      )}
      <Spacer height={10} />

    </div>
  );
};

export default SortPlaylistPrototype;
