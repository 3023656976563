import React from "react";
import "./index.less";
import RestrictedPlaylistItemIcon from "@assets/icons/restricted_playlist_item.svg";
import Image from "../../Image";

const RestrictedPlaylistItem = ({onClick}) => {
  return (
    <div className="recent-views-icon" onClick={onClick}>
        <Image src={RestrictedPlaylistItemIcon} alt="home" />
    </div>
  );
};

export default RestrictedPlaylistItem;
