import React from "react";
import Spin from "antd/lib/spin/index";
import Loading3QuartersOutlined from "@ant-design/icons/Loading3QuartersOutlined";

const Loader = ({ centered ,height="70vh" }) => {
    let centeredStyle;
    if (centered) {
        centeredStyle = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height:height

        };
    }

    const antIcon = (
        <Loading3QuartersOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    return <Spin style={centeredStyle} indicator={antIcon} />;
};

export default Loader;
