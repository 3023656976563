import React, { useState, useEffect, useContext } from "react";
import Modal from "@shared/atoms/Modal";
import { useTranslation } from "react-i18next";
import "./index.less";
import EditableImage from "@components/organisms/EditableImage";
import Select from "@components/shared/atoms/SearchSelect";
import { useDispatch, useSelector } from "react-redux";
import { findUsersEmail } from "@redux/actions/userActions";
import {
  createTeam,
  getTeam,
  getTeamsAction,
  updateTeam,
} from "@redux/actions/TeamActions";
import useSelectSearch from "hooks/useSelectSearch";
import { Context } from "@context/context";
import { debounce } from "lodash";

const EditTeamModalPrototype = ({ teamId, ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const suggestedEmails = useSelector(
    (state) => state.suggestedEmail.data?.users
  );

  const { setShowEditTeamsModal } = useContext(Context);
  const { data } = useSelector((state) => state.teamItem);
  const teamItem = data?.data;

  const [teamName, setTeamName] = useState("");
  const [description, setDescription] = useState("");
  const [playlistVal, setPlaylistVal] = useState(null);
  const [membersVal, setMembersVal] = useState(null);
  const [blobImg, setBlobImg] = useState(null);

  const { playlists, loading } = useSelector((state) => state.playlists);

  const publicPlaylists = playlists?.public?.map((playlist) => {
    return { name: playlist.name, id: playlist.id };
  });

  const otherPlaylists = playlists?.other?.map((playlist) => {
    return { name: playlist.name, id: playlist.id };
  });

  const allPlaylists = publicPlaylists &&
    otherPlaylists && [
      { name: `Select All Playlist`, id: `Select All Playlist` },
      ...publicPlaylists,
      ...otherPlaylists,
    ];

  const allEmails = suggestedEmails?.map((item) => {
    return { name: item.email, id: item.id };
  });

  const allMembers = allEmails && [
    { name: `Select All Member`, id: `Select All Member` },
    ...allEmails,
  ];

  useEffect(() => {
    // dispatch(findUsersEmail(membersVal));
  }, [membersVal]);

  const {
    selectedItems: selectedPlaylists,
    isAllSelected: isAllPlaylistSelected,
    onSelectChange: onPlaylistChange,
    selectValue: playlistsValue,
  } = useSelectSearch("Playlist", allPlaylists);

  const {
    selectedItems: selectedMembers,
    isAllSelected: isAllMembersSelected,
    onSelectChange: onMembersChange,
    selectValue: membersValue,
  } = useSelectSearch("Member", allMembers);

  const editTeamHandler = () => {
    let fd = new FormData();

    selectedPlaylists.forEach((pl, index) => {
      fd.append(`playlists[${index}]`, pl);
    });

    selectedMembers.forEach((pl, index) => {
      fd.append(`users[${index}]`, pl);
    });

    fd.append("name", teamName);
    fd.append("description", description);
    fd.append("logo", blobImg);

    dispatch(createTeam(fd));

    Promise.resolve(dispatch(updateTeam(fd))).then(() => {
      dispatch(getTeamsAction());
    });

    setShowEditTeamsModal(false);

    // const myPromise = new Promise((resolve, reject) => {
    //   dispatch(createTeam(fd));
    // });

    // myPromise.catch((err) => console.log(err));
  };

  useEffect(() => {
    if(!teamId) return;
    
    dispatch(getTeam(teamId));
  }, [dispatch, teamId]);

  useEffect(() => {
    setTeamName(teamItem?.name);
    setDescription(teamItem?.description);
    setPlaylistVal(teamItem?.playlists);
    setMembersVal(teamItem?.users);
    setBlobImg(null);
  }, [teamItem]);

  return (
    <Modal
      title={"Edit Team"}
      className="create-team-modal"
      modalContainer=".tablet-prototype"
      footer={[
        <div className="btn-container">
          <button
            key="back"
            type="primary"
            className="confirm-btn"
            onClick={editTeamHandler}
          >
            Edit Team
          </button>
        </div>,
      ]}
      {...rest}
    >
      <div className="input-container">
        <label>{t("playlist_name")}</label>
        <input
          maxLength={50}
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
        />
      </div>
      <div className="input-container">
        <label>Description</label>
        <input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      <Select
        value={teamItem?.playlists?.map((pl) => pl.name)}
        onChange={onPlaylistChange}
        placeholder="Choose a playlist"
        filteredOptions={allPlaylists}
        isAllSelected={isAllPlaylistSelected}
        setInputVal={(inputValue) => {
          setPlaylistVal(inputValue);
        }}
      />
      {/* <Select
        value={membersValue}
        onChange={onMembersChange}
        placeholder="Choose a member"
        filteredOptions={allMembers}
        isAllSelected={isAllMembersSelected}
        setInputVal={(inputValue) => {
          setMembersVal(inputValue);
        }}
      /> */}

      <div className="editable-img">
        <EditableImage setBlobImg={setBlobImg} btnText="Upload channel image" />
      </div>
    </Modal>
  );
};

export default EditTeamModalPrototype;
