import React from "react";
import { Select } from "antd";
import "./index.less";

const { Option } = Select;

const selectStyles = {
  width: "100%",
  borderRadius: "1rem",
  color: "#000",
  outline: "none",
  border: "1px solid #ef8508",
  marginTop: "2rem",
  padding: "3px",
};

const SearchSelect = ({
  mode = "tags",
  setInputVal,
  isAllSelected,
  filteredOptions = [],
  ...rest
}) => {
  return (
    <Select
      mode={mode}
      size="middle"
      style={selectStyles}
      notFoundContent="No items found"
      filterOption={(input, option) =>
        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...rest}
    >
      {isAllSelected
        ? []
        : filteredOptions?.map((option) => (
            <Option key={option?.id}>{option?.name}</Option>
          ))}
    </Select>
  );
};

export default SearchSelect;
