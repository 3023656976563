import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import Form from "../../shared/atoms/Form";
import InputPrototype from "../../shared/atoms/InputPrototype";
import ErrorFeedbackPrototype from "../../shared/atoms/ErrorFeedbackPrototype";
import Text from "antd/es/typography/Text";
import Button from "../../shared/atoms/Button";
import logo from "../../../../assets/icons/reset-password-choice.png";
import comparePasswords from "../../../../utils/comparePasswords";
import { confirmResetPassword } from "../../../../redux/actions/userActions";

import "./index.less";


const MobileResetPasswordPrototype = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { email, token } = useParams();

  const {
    error: resetConfirmError,
    success: resetConfirmSuccess,
    loading
  } = useSelector(state => state.userConfirmResetPassword);


  const [warning, setWarning] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (!(email && token)) {
      history.replace("/settings");
    }
  }, [email, token, history]);


  useEffect(() => {
    if (resetConfirmSuccess) {
      setTimeout(() => {
        history.replace("/settings");
      }, 2000);
    }
  }, [resetConfirmSuccess, history]);

  const handleSubmit = () => {
    let isSamePasswords = comparePasswords(password, confirmPassword);
    if (!isSamePasswords) {
      setWarning("Passwords don't match");
      return;
    }
    setWarning("");
    dispatch(confirmResetPassword(email, token, password, confirmPassword));
  };

  // If request was failed, create error message from the complex error response
  let err = "";
  if (resetConfirmError) {
    const errorsArr = resetConfirmError?.data?.errors;
    err = errorsArr[Object.keys(errorsArr)[0]][0];
  }

  return <div className="mobile-reset-password-prototype">
    {resetConfirmError && <ErrorFeedbackPrototype type="danger">{err}</ErrorFeedbackPrototype>}
    {resetConfirmSuccess && <ErrorFeedbackPrototype>{t("password_has_changed")}</ErrorFeedbackPrototype>}
    <h1 className="choice-header-prototype">
      <img src={logo} alt="logo" />
    </h1>
    <Form className="ant-form-prototype reset-password-prototype">
      <Form.Item>
        <Text>{t("new_password")}</Text>
        <InputPrototype
          type="password"
          transparent={true}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Item>
      <Form.Item>
        {warning && <ErrorFeedbackPrototype type="danger">{warning}</ErrorFeedbackPrototype>}
        <Text>{t("confirm_password")}</Text>
        <InputPrototype
          type="password"
          transparent={true}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Form.Item>
      <Form.Item>
        <Button onClick={handleSubmit} disabled={loading} loading={loading} className="ant-btn-prototype blue">Reset</Button>
      </Form.Item>
    </Form>
  </div>;
};

export default MobileResetPasswordPrototype;