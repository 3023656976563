import React, { useContext, useState, useEffect } from "react";
import { Context } from "@context/context";
import Modal from "@shared/atoms/Modal";
import Button from "@shared/atoms/Button";
import { useTranslation } from "react-i18next";
import PlaylistSelectableListPrototype from "@components/organisms/lists/PlaylistSelectableListPrototype";
import Input from "@components/shared/atoms/Input";
import { Title } from "@components/shared/atoms/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  createPlaylistGroup,
  getPlaylists,
} from "@redux/actions/playlistsActions";
import Alert from "@components/shared/atoms/Alert";
import { CREATE_GROUP_RESET } from "@redux/constants/playlistsConstants";
import { changeStatusText } from "@utils/changeStatusText";

import "./index.less";
import GroupStatusesSelect from "@components/shared/molecules/GroupStatusesSelect";

const CreateGroupModalPrototype = () => {
  const { t } = useTranslation();
  const [checkedPlaylists, setCheckedPlaylists] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [statusId, setStatusId] = useState(null);
  const [status, setStatus] = useState("");

  const { statuses } = useSelector((state) => state.playlistStatuses);

  const {
    data: createGroupData,
    loading: createGroupLoading,
    error: createGroupError,
  } = useSelector((state) => state.createPlaylistGroup);

  const dispatch = useDispatch();

  const { showCreateGroupModalPrototype, setShowCreateGroupModalPrototype } =
    useContext(Context);

  useEffect(() => {
    if (statuses) setStatusId(statuses[0]?.id);
  }, [statuses]);

  const closeModalHandler = () => {
    setShowCreateGroupModalPrototype(false);
    dispatch({ type: CREATE_GROUP_RESET });
  };

  const changeStatus = (e) => {
    const statusId = statuses?.find(
      (status) => status.name === e.target.value
    ).id;

    setStatus(e.target.value);
    setStatusId(statusId);
  };

  const createGroupHandler = () => {
    Promise.resolve(
      dispatch(
        createPlaylistGroup({
          name: groupName,
          playlists: checkedPlaylists,
          status_id: statusId,
        })
      )
    ).then(() => {
      dispatch(getPlaylists());
    });
  };

  return (
    <Modal
      className="create-group-modal-prototype modal-basic-prototype"
      visible={showCreateGroupModalPrototype}
      onCancel={closeModalHandler}
      closable={true}
      footer={[
        <footer className="footer">
          <Button
            key="del"
            onClick={createGroupHandler}
            className="response-buttons-prototype blue"
            disabled={createGroupLoading}
          >
            {t("create")}
          </Button>
          <Button
            key="can"
            onClick={closeModalHandler}
            className="response-buttons-prototype gray"
          >
            {t("cancel")}
          </Button>
        </footer>,
      ]}
    >
      {createGroupError?.data?.errors?.name?.length && (
        <Alert
          type="error"
          message={`${createGroupError?.data?.errors?.name[0]}`}
        />
      )}
      {createGroupError?.data?.errors?.playlists?.length && (
        <Alert
          type="error"
          message={`${createGroupError?.data?.errors?.playlists[0]}`}
        />
      )}
      {createGroupData?.status && (
        <Alert type="success" message={`Group created`} />
      )}

      <div className="group-desc">
        <Input
          onChange={(e) => setGroupName(e.target.value)}
          className="group-name-input"
          placeholder="Enter group name..."
        />
        <Title level={3} className="title">
          Choose channels
        </Title>
      </div>
      <GroupStatusesSelect changeStatusHandler={changeStatus} defaultValue={status} />

      <PlaylistSelectableListPrototype
        setCheckedPlaylists={setCheckedPlaylists}
      />
    </Modal>
  );
};

export default CreateGroupModalPrototype;
