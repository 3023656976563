import React, { useState, useContext, useEffect } from "react";
import { Context } from "@context/context";
import Modal from "@shared/atoms/Modal";
import Button from "@shared/atoms/Button";
import Radio from "@shared/atoms/Radio";
import Input from "@shared/atoms/Input/index";
import Space from "@shared/atoms/Space";
import { getReports, addReport } from "@redux/actions/playlistsActions";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@shared/atoms/Alert/index";
import Loader from "@shared/atoms/Loader";
import { PLAYLIST_REPORT_MODAL_CLOSE } from "@redux/constants/playlistsConstants";
import { useTranslation } from "react-i18next";

import "./index.less";

const ReportContentModalPrototype = ({ reportableType, reportableId }) => {
  const [reportReason, setReportReason] = useState("");
  const [reportTypeId, setReportTypeId] = useState(null);
  const {
    showReportContentModalPrototype,
    setShowReportContentModalPrototype,
  } = useContext(Context);

  const [showOtherInput, setShowOtherInput] = useState(true);

  const { t } = useTranslation();

  const handleClose = () => {
    setShowReportContentModalPrototype(false);
    dispatch({ type: PLAYLIST_REPORT_MODAL_CLOSE });
  };

  const {
    error: fetchReportsError,
    reports: reportsList,
    loading: reportsLoading,
  } = useSelector((state) => state.reports);

  const {
    error: submitReportError,
    loading: submitLoading,
    message: submitSuccess,
  } = useSelector((state) => state.submitReport);

  const dispatch = useDispatch();
  useEffect(() => {
    if (showReportContentModalPrototype && !reportsList) {
      dispatch(getReports());
    }
  }, [
    dispatch,
    submitSuccess,
    submitReportError,
    reportsList,
    showReportContentModalPrototype,
  ]);

  function addReportHandler() {
    const data = {
      url: window.location.href,
      report_type_id: reportTypeId,
      reportable_type: reportableType,
      reportable_id: reportableId,
    };
    if (reportReason) {
      data.message = reportReason;
    }

    dispatch(addReport(data));
  }

  function onModalClose() {
    dispatch({ type: PLAYLIST_REPORT_MODAL_CLOSE });
  }

  return (
    <Modal
      className="modal-basic-prototype report-content-modal-prototype"
      title={t("report_content")}
      visible={showReportContentModalPrototype}
      onCancel={handleClose}
      onChange={onModalClose}
      footer={[
        <Button
          disabled={submitLoading}
          className="report-btn"
          type="primary"
          key="Report"
          htmlType="submit"
          onClick={() => addReportHandler()}
        >
          {t("report")}
        </Button>,
      ]}
    >
      {submitReportError && (
        <Alert message={submitReportError?.data?.message} type="error" />
      )}

      {fetchReportsError && (
        <Alert message="Failed to fetch reports" type="error" />
      )}

      {submitSuccess && (
        <Alert message={submitSuccess?.message} type="success" />
      )}

      {reportsLoading ? (
        <Loader centered={true} height="15vh" />
      ) : (
        <>
          <Radio.Group
            onChange={(e) => {
              if (e.target.value === "Other") {
                setShowOtherInput(false);
              } else {
                setShowOtherInput(true);
                setReportReason("");
              }
            }}
          >
            <Space direction="vertical" size={19}>
              {reportsList?.map((reportItem) => {
                return (
                  <Radio
                    key={reportItem.key}
                    value={reportItem.name}
                    onClick={() => setReportTypeId(reportItem.id)}
                  >
                    {reportItem.name}
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
          {!showOtherInput && (
            <Input
              className="report-reason"
              placeholder={`${t("enter_reason")}...`}
              onChange={(e) => setReportReason(e.target.value)}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default ReportContentModalPrototype;
