import {
  META_DATA_REQUEST,
  META_DATA_SUCCESS,
  META_DATA_FAIL,
  META_DATA_RESET,
} from "../constants/metaDataConstants";

export const metadataReducer = (state = {}, action) => {
  switch (action.type) {
    case META_DATA_REQUEST:
      return { loading: true };
    case META_DATA_SUCCESS:
      return { loading: false, data: action.payload };
    case META_DATA_FAIL:
      return { loading: false, error: action.payload };
    case META_DATA_RESET:
      return {};
    default:
      return state;
  }
};
