import React from "react";
import { Route, Redirect } from "react-router-dom";

const UnauthenticatedRoute = (props) => {
    const { component: Component, ...rest } = props;
    const loggedIn = false;

    return (
        <Route
            {...rest}
            render={(props) => {
                return !loggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: `/login`,
                            state: { from: props.location },
                        }}
                    />
                );
            }}
        />
    );
};

export default UnauthenticatedRoute;
