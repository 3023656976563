import { getUrlHostname } from "@utils/functions";
import moment from "moment";

const useWatchLater = () => {
  const constructwatchLaterPlaylistName = (url) => {
    let hostName = "";
    try {
      hostName = getUrlHostname(url);
    } catch (error) {
      console.log(error);
    }

    return `${hostName} ${moment().format("YYYY-MM-DD - hh:mm:ss")}`;
  };

  return {
    constructwatchLaterPlaylistName,
  };
};


export default useWatchLater;