import React from 'react'
import List from '@components/shared/atoms/List'
import PlaylistSelectableItemPrototype from '@components/shared/molecules/listItems/PlaylistSelectableItemPrototype'
import { useSelector } from 'react-redux'

import "./index.less"

const PlaylistSelectableListPrototype = ({setCheckedPlaylists}) => {
  const { playlists } = useSelector(state => state.playlists)
  const allPlaylists = [...playlists.other, ...playlists.public]

  return (
    <List className="playlist-selectable-list">
      {allPlaylists?.map(playlist => (
        <PlaylistSelectableItemPrototype
          key={playlist.id}
          playlist={playlist}
          setCheckedPlaylists={setCheckedPlaylists}
        />
      ))}
    </List>)
}

export default PlaylistSelectableListPrototype