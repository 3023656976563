import React from "react";
import { localStorageService } from "@modules/services/localStorage.service";
import KebabMenu from "../../atoms/icons/KebabMenu";

import "./index.less";

const ChannelMenuPrototype = ({ onClick, className }) => {
  const isLoggedIn = localStorageService().get("access_token")
  return (
    <>
      {isLoggedIn && (
        <div onClick={onClick} className={`channel-menu-prototype ${className ? className : ""}`}>
          <KebabMenu />
        </div>
      )}
    </>
  );
};

export default ChannelMenuPrototype;
