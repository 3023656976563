import {
  useState,
  useContext,
  useLayoutEffect,
  useEffect,
  useCallback,
} from "react";
import "./App.less";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import * as pages from "./modules/pages/index";
import PrivateRoute from "./modules/i18n/components/PrivateRoute";
import UnauthenticatedRoute from "./modules/i18n/components/UnauthenticatedRoute";
import { AppRoutes } from "./const/app-routes";
import { localStorageService } from "./modules/services/localStorage.service";

import { Context } from "@context/context";
import { useDispatch, useSelector } from "react-redux";
import { getPlaylists, getPlaylistStatuses } from "@redux/actions/playlistsActions";

import { useTranslation } from "react-i18next";
import { supportedLanguages } from "./modules/config";

// import AOS from "aos";
// import "aos/dist/aos.css"; // You can also use <link> for styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getUserProfile } from "@redux/actions/profileActions";
import { checkAPIStatus } from "@redux/actions/apiActions";
import TempDown from "@components/organisms/TempDown";
import debounce from "lodash/debounce";
import { getHomeContents } from "@redux/actions/homeContentActions";
import { getCopiedTiers } from "@redux/actions/shortcutActions";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file



function App() {
  const [theme, setTheme] = useState("dark-theme");

  const { localeLang, setLocaleLang } = useContext(Context);
  const dispatch = useDispatch();

  const { apiStatus } = useSelector((state) => state.apiHealth);

  const { i18n } = useTranslation();

  const delayedGetPlaylistStatuses = debounce(() => {
    dispatch(getPlaylistStatuses());
  }, 700);

  useLayoutEffect(() => {
    getTheme();
  }, []);

  useEffect(() => {
    dispatch(checkAPIStatus());
    dispatch(getPlaylists())
    dispatch(getCopiedTiers());
  }, [dispatch]);

  useEffect(() => {
    delayedGetPlaylistStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeCastApi = function () {
    window?.cast?.framework?.CastContext?.getInstance()?.setOptions({
      receiverApplicationId:
        window.chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
      autoJoinPolicy: window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
    });
  };

  useEffect(() => {
    window["__onGCastApiAvailable"] = function (isAvailable) {
      if (isAvailable) {
        initializeCastApi();
      }
    };
  }, []);

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getHomeContents());
  }, [dispatch]);

  // useEffect(() => {
  //   AOS.init();
  // }, []);

  const changeLanguageFromUrl = useCallback(
    function changeLanguageFromUrl() {
      let urlArr = window.location.pathname.split("/");
      let languageInUrl = urlArr[1];
      if (supportedLanguages.includes(languageInUrl)) {
        i18n.changeLanguage(languageInUrl);
        setLocaleLang(languageInUrl);
        localStorageService().set("lang", languageInUrl);
        setLocaleLang(localStorageService().get("lang"));

        if (localeLang !== localStorageService().get("lang")) {
          window.location.replace(urlArr.join("/"));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n]
  );

  useEffect(() => {
    changeLanguageFromUrl();
  }, [changeLanguageFromUrl]);

  const getTheme = () => {
    if (localStorageService().get("theme")) {
      setTheme(localStorageService().get("theme"));
    } else {
      setTheme("dark-theme");
      localStorageService().set("theme", "dark-theme");
    }
  };

  const generateRoutes = (routes) => {
    return Object.keys(routes).map((route) => {
      const { path, exact, isPrivate, page } = routes[route];

      return !isPrivate ? (
        <UnauthenticatedRoute
          exact={exact}
          path={`/${path}`}
          key={route}
          component={pages[page]}
        />
      ) : (
        <PrivateRoute
          exact={exact}
          path={`/${path}`}
          key={route}
          component={pages[page]}
        />
      );
    });
  };

  if (apiStatus && apiStatus !== 200) {
    return <TempDown />;
  }

  return (
    <Router basename={`/${localeLang}`}>
      <div className={`App ${theme}`}>
        <Switch>{generateRoutes(AppRoutes)}</Switch>
      </div>
    </Router>
  );
}

export default App;
