const validateEmail = (email) => {
  let isValid;
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email && emailRegex.test(String(email).toLowerCase())) {
    isValid = true
    // setDisableBtn(false);
  } else {
    // setDisableBtn(true);
    isValid = false
  }
  return isValid
}

export default validateEmail;