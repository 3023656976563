import React, { useContext, useEffect } from "react";
import Modal from "@shared/atoms/Modal/index";
import { Paragraph } from "@components/shared/atoms/Typography";
import Button from "@components/shared/atoms/Button";
import { Context } from "@context/context";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTiers, unsubscribeTier } from "@redux/actions/paymentActions";

import "./index.less";

const UnsubscribeTierModalPrototype = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading: tierUnsubscribeLoading } = useSelector(
    (state) => state.tierUnsubscribe
  );

  const { data: tierData } = useSelector((state) => state.tiers);

  const {
    openUnsubscribeTierModalPrototype,
    setOpenUnsubscribeTierModalPrototype,
    tierPrototype,
    setTierPrototype,
  } = useContext(Context);

  useEffect(() => {
    if (tierPrototype?.id && tierData?.tiers) {
      const currentTier = tierData?.tiers?.find(
        (tier) => tier.id === tierPrototype?.id
      );
      setTierPrototype(currentTier);
    }
  }, [tierData, tierPrototype, setTierPrototype]);

  const cancelSubscriptionHandler = () => {
    Promise.resolve(dispatch(unsubscribeTier(tierPrototype.id))).then(() => {
      closeModal();
      dispatch(getTiers());
    });
  };
  const closeModal = () => {
    setOpenUnsubscribeTierModalPrototype(false);
  };

  return (
    <Modal
      className="unsubscribe-tier-modal modal-basic-prototype"
      visible={openUnsubscribeTierModalPrototype}
      closable={true}
      footer={[
        <>
          <Button
            key="update"
            type="primary"
            onClick={cancelSubscriptionHandler}
            disabled={tierUnsubscribeLoading}
          >
            {t("yes")}
          </Button>
          <Button key="cancel" onClick={closeModal}>
            {t("no")}
          </Button>
        </>,
      ]}
    >
      <Paragraph className="text">
        {t("are_you_sure_you_want_to_cancel_your_subscription")}?
      </Paragraph>
    </Modal>
  );
};

export default UnsubscribeTierModalPrototype;
