import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addVideoInPlaylist } from "@redux/actions/playlistsActions";
import { Context } from "@context/context";
import Modal from "@shared/atoms/Modal";
import Input from "@shared/atoms/Input";
import Form from "@shared/atoms/Form";
import Alert from "@shared/atoms/Alert/index";
import CloseModalIcon from "@shared/atoms/icons/CloseModal";
import { formLayout } from "@modules/config";
import FavoritesList from "../../lists/FavoritesList";
import FavoritesItem from "@shared/molecules/listItems/FavoritesItem";
import { useTranslation } from "react-i18next";

import "./index.less";

const SaveToPlaylistModal = () => {
    const { t } = useTranslation();

    const {
        showSaveToPlaylistModal,
        setShowSaveToPlaylistModal,
        relatedVideo,
    } = useContext(Context);

    const dispatch = useDispatch();

    const {
        playlists,
        //  loading,
        //   error
    } = useSelector((state) => state.playlists);

    const {
        // message: relatedAddMessage,
        error: relatedAddError,
        // loading: relatedAddLoading,
    } = useSelector((state) => state.addRelatedVideo);


    const moveVideoHandler = (playlist) => {
        dispatch(addVideoInPlaylist([relatedVideo.src], playlist.id));

        handleClose();
    };

    const handleClose = () => {
        setShowSaveToPlaylistModal(false);
    };

    return (
        <Modal
            title={t("choose_playlist")}
            closable={true}
            visible={showSaveToPlaylistModal}
            onCancel={handleClose}
            className="move-to-video-modal"
            closeIcon={<CloseModalIcon />}
            footer={[<></>]}
        >
            {relatedAddError && (
                <Alert message={relatedAddError?.data?.message} type="error" />
            )}
            <Form layout={formLayout}>
                <div className="playlists-list">
                    <Form.Item label={t("search")}>
                        <Input placeholder={t("search_for_playlist")} />
                    </Form.Item>

                    <FavoritesList>
                        {playlists?.finished?.map((publicPlaylist) => (
                            <FavoritesItem
                                kebabMenu={false}
                                key={publicPlaylist.id}
                                videosCount={publicPlaylist.videos.length}
                                playlist={publicPlaylist}
                                playlistName={publicPlaylist.name}
                                onClick={() => moveVideoHandler(publicPlaylist)}
                            ></FavoritesItem>
                        ))}
                    </FavoritesList>
                    <FavoritesItem
                        kebabMenu={false}
                        playlistName={t("favorites")}
                        playlist={playlists?.favorites[0]}
                        deleteItem={false}
                    ></FavoritesItem>
                    <FavoritesList>
                        <FavoritesItem
                            kebabMenu={false}
                            deleteItem={false}
                            playlistName={`${t("watch_later")}`}
                            playlist={playlists?.watchLater[0]}
                        ></FavoritesItem>
                        {playlists?.unfinished.map((privatePlaylist) => (
                            <FavoritesItem
                                key={privatePlaylist.id}
                                kebabMenu={false}
                                playlistName={privatePlaylist.name}
                                playlist={privatePlaylist}
                                videosCount={privatePlaylist.videos.length}
                                onClick={() =>
                                    moveVideoHandler(privatePlaylist)
                                }
                            ></FavoritesItem>
                        ))}
                    </FavoritesList>
                </div>
            </Form>
        </Modal>
    );
};

export default SaveToPlaylistModal;
