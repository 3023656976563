import React from "react";
import { Route,Redirect } from "react-router";
import {localStorageService} from "@modules/services/localStorage.service"

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props;

  const loggedIn = localStorageService().get("access_token");
  return (
    <Route
      {...rest}
      render={(props) => {
        return loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `/login`,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
