import React from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import "./index.less";

const NavLink = ({ children, activeClassName = "active-nav-item", ...rest }) => {
    return (
        <RouterNavLink exact={true} activeClassName={activeClassName} {...rest}>
            {children}
        </RouterNavLink>
    );
};

export default NavLink;
