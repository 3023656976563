import React, { useContext } from "react";
import { Context } from "@context/context";
import { Paragraph } from "@shared/atoms/Typography/index";
import Checkbox from "@shared/atoms/Checkbox/index";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { localStorageService } from "@modules/services/localStorage.service";


import "./index.less";

const TermsField = ({ openModal }) => {
    const location = useLocation();
    const { termsCheckboxState, setTermsCheckboxState } = useContext(Context);
    const { t } = useTranslation();

    function onChange() {
        setTermsCheckboxState(!termsCheckboxState);
    }

    return (
        <Paragraph className="terms-field">
            <Checkbox onChange={onChange} />
            <div>
                {t("i_accept_and_agree_to_the")}
                <Link
                    className={`terms-modal-link`}
                    onClick={() => {
                        localStorageService().set(
                            "redirected_from",
                            location.pathname
                        );
                    }}
                    to="/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}
                    {t("terms_and_conditions")}
                </Link>
            </div>
        </Paragraph>
    );
};

export default TermsField;
