import { useContext } from "react";
import { Context } from "@context/context";
import { isMobile } from "react-device-detect";

/**
 * Hook that scrolls to top
 * @example
 * const scrollToTop = useScrollToTop()
 *
 */

const useScrollToTop = () => {
  const { tabletFrameRef } = useContext(Context)

  return function scrollToTop() {
    let element;
    if (!isMobile) {
      element = tabletFrameRef.current;
    } else {
      element = window;
    }
    element?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

};

export default useScrollToTop