import React from "react";
import { useTranslation } from "react-i18next";
import { Title } from "../../atoms/Typography/index";

import "./index.less";

const LoginGreeting = () => {
    const { t } = useTranslation();
    return (
        <div className="auth-headings-container">
            <Title level={2}>{t("welcome_back")}</Title>
            <Title className="login-sub-text" level={2}>
                {t("Log into your account")}
            </Title>
        </div>
    );
};

export default LoginGreeting;
