import React, { useState } from "react";
import Modal from "@shared/atoms/Modal/index";
import { Paragraph } from "@shared/atoms/Typography/index";
import Image from "@shared/atoms/Image/index";
import "./index.less";
import Checkbox from "@components/shared/atoms/Checkbox";
import { localStorageService } from "@modules/services/localStorage.service";

// import { useTranslation } from "react-i18next";

const AddWebsiteToHomeScreenModalPrototype = ({
  isVisible,
  setIsVisible,
  logo,
}) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const checkboxChangeHandler = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };
  const closeHandler = () => {
    setIsVisible(false);
    if (isCheckboxChecked) {
      if (localStorageService().get("add_to_home_screen_show_time"))
        localStorageService().delete("add_to_home_screen_show_time");
      localStorageService().set("hide_add_to_home_screen", true);
    } else {
      localStorageService().set("hide_add_to_home_screen", false);

      const today = new Date();
      const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
      localStorageService().set(
        "add_to_home_screen_show_time",
        tomorrow.toString()
      );
    }
  };
  return (
    <Modal
      visible={isVisible}
      className="modal-basic-prototype add-website-to-home-modal-prototype"
      mask={false}
      footer={<></>}
      closable={true}
      onCancel={() => {
        closeHandler();
      }}
    >
      <div>
        <Paragraph>
          Install this app on your home screen for quick & easy access!
        </Paragraph>
        <Paragraph>
          Tap <Image src={logo} alt="settings" /> then 'Add to Home Screen'
        </Paragraph>
        <div className="checkbox-text-container">
          <Checkbox onChange={checkboxChangeHandler}>
            Do not show again
          </Checkbox>
        </div>
      </div>
    </Modal>
  );
};

export default AddWebsiteToHomeScreenModalPrototype;
