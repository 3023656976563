import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Hook that detects pathname
 * @example
 * const currentPage = usePathName()
 *
 */

function usePathName() {
  const [currentPage, setCurrentPage] = useState("");
  const location = useLocation();
  const pathName = location.pathname;

  useEffect(() => {
    if (pathName) {

      setCurrentPage(pathName.slice(1));
    }
  }, [pathName]);

  return currentPage;
}

export default usePathName;
