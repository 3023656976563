import React, { useEffect } from "react";
import MainLayout from "../../layout/MainLayout";
import MobileTiers from "../../components/organisms/MobileTiers";
import DesktopPrototype from "@components/organisms/DesktopPrototype";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { getHomeTiersContents } from "@redux/actions/homeContentActions";

export const Tiers = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHomeTiersContents());
  }, [dispatch]);

  return (
    <>
      {!isMobile && (
        <DesktopPrototype>
          <MobileTiers />
        </DesktopPrototype>
      )}

      {isMobile && (
        <MainLayout fluid={true} className="mobile-layout">
          <MobileTiers />
        </MainLayout>
      )}
    </>
  );
};
