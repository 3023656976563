import React from "react";
import { useTranslation } from "react-i18next";
import "./index.less";

const CloseModal = ({ handleClose }) => {
    const { t } = useTranslation();
    return (
        <div onClick={handleClose} className="close-modal">
            <span>{t("back_to_sign_in")}</span>
        </div>
    );
};

export default CloseModal;
