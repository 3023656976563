import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  userLoginReducer,
  userConfirmEmailReducer,
  userPasswordResetReducer,
  userConfirmResetPasswordReducer,
  userRegisterReducer,
  userRegisterOnlyEmailReducer,
  userRegisterFinalizeReducer,
  userConfirmCodeReducer,
  userConfirmCodeResendReducer,
  userLogoutReducer,
  userRequiredFieldsReducer,
  userVerifyEmailReducer,
  FindUsersEmailReducer,
} from "./reducers/userReducers";

import {
  channelGroupReducer,
  channelItemsReducer,
  channelsReducer,
  currentChannelItemsReducer,
  createChannelGroupReducer,
  deleteChannelGroupReducer,
  createChannelItemReducer,
  updateChannelItemReducer,
  epgChannelsReducer,
  channelKeywordsReducer,
  channelSearchReducer,
  channelByIdReducer,
  dutchChannelGroupsReducer,
  dutchChannelsReducer,
  copyTierReducer,
  promotionChannelsReducer,
  directShareChannelsEpgReducer,
  subscribeToChanelReducer,
  unsubscribeToChanelReducer,
} from "./reducers/channelReducers";

import {
  adultContentToggleReducer,
  adultContentConfirmReducer,
  userProfileReducer,
  updateUserProfileReducer,
  userProfileByIdReducer,
  userChangePasswordReducer,
} from "./reducers/profileReducers";

import {
  playlistsReducer,
  playlistCreateReducer,
  playlistDeleteReducer,
  playlistVideoDeleteReducer,
  addVideosToPlaylistReducer,
  updatePlaylistReducer,
  movePlaylistVideoReducer,
  addRelatedVideoReducer,
  relatedVideosReducer,
  playlistByIdReducer,
  relatedVideoDeleteReducer,
  playlistWithRelatedVideosReducer,
  playlistKeywordsReducer,
  searchPlaylistReducer,
  fetchReportsReducer,
  addReportReducer,
  playlistVideoReorderReducer,
  getPlaylistStatusesReducer,
  playlistRelatedVideoReorderReducer,
  playlistVideoMetaDataReducer,
  playlistVideoUpdateReducer,
  defaultPlaylistsReducer,
  searchMyPlaylistsReducer,
  updateSerieItemDetailsReducer,
  updateGroupReducer,
  // playlistsNavbarReducer,
  addSerieToDirectShareReducer,
  addVideoToDirectShareReducer,
  createPlaylistGroupReducer,
  arrangeFollowedPlaylistsReducer,
  playlistsArrangeReducer,
  notFollowedPlaylistsHistoryReducer,
} from "./reducers/playlistsReducer";

import {
  socialFollowReducer,
  socialShareDeleteReducer,
  socialShareReducer,
  socialLikeReducer,
  socialUnlikeReducer,
  socialSuggestedPlaylistsReducer,
  socialUnfollowReducer,
  socialFollowedPlaylistsReducer,
  socialFollowingPlaylistsReducer,
} from "./reducers/socialReducers";

import {
  setBalanceReducer,
  transactionsReducer,
  subscribtionsReducer,
  tiersReducer,
  tierSubscribeReducer,
  tierDatePeriodReducer,
  couponCodeReducer,
  tierUnsubscribeReducer,
} from "./reducers/paymentReducers";

import {
  countryReducer,
  invalidCountryReducer,
} from "./reducers/countryReducer";

import {
  viewReducer,
  clickViewReducer,
  viewStartReducer,
  viewEndReducer,
  viewListReducer,
} from "./reducers/viewReducers";
import { metadataReducer } from "./reducers/metaDataReducers";

import {
  statisticsReducer,
  broadcastStatisticsReducer,
  playlistStatisticsReducer,
  exportPlaylistStatisticsReducer
} from "./reducers/statisticsReducers";
import {
  homeContentGlobeReducer,
  homeContentHeartReducer,
  homeContentReducer,
  homeContentTiersReducer,
  homeContentUserReducer,
  homeDirectPublishReducer,
  homeWatchLaterReducer,
  toggleWelcomeMsgReducer,
} from "./reducers/homeReducers";
import { apiHealthReducer } from "./reducers/apiHealthReducers";
import {
  createSearchHistoryReducer,
  searchAuthorizedReducer,
  searchHistoryReducer,
  searchUnauthorizedReducer,
  toggleFollowSearchHistoryReducer,
  deleteSearchHistoryItemReducer,
} from "./reducers/searchReducers";
import {
  copyGroupReducer,
  editGroupReducer,
  groupArrangeReducer,
  groupDeleteReducer,
  groupPlaylistsArangeReducer,
} from "./reducers/groupReducers";

import { copiedTierReducer } from "./reducers/shortcutReducers";
import { copyEpgItemReducer, copyEpgReducer, copySerieReducer, copyTierChannelReducer, copyVideoReducer } from "./reducers/copyReducer";
import { 
  createTeamReducer, 
  getTeamReducer, 
  getTeamsReducer, 
  deleteTeamReducer,
  deleteTeamUser,
  toggleTeamAdmin,
  leaveTeamReducer,
  deleteTeamPlaylistReducer
} from "./reducers/teamReducers";
import { ACC_MODES, LOCALSTORAGE } from "const";
import { setAccountModeReducer } from "./reducers/accountModeReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userLogout: userLogoutReducer,
  userConfirmEmail: userConfirmEmailReducer,
  userPasswordReset: userPasswordResetReducer,
  userConfirmResetPassword: userConfirmResetPasswordReducer,
  userRegister: userRegisterReducer,
  userRegisterOnlyEmail: userRegisterOnlyEmailReducer,
  userRegisterFinalize: userRegisterFinalizeReducer,
  userConfirmCode: userConfirmCodeReducer,
  userConfirmCodeResend: userConfirmCodeResendReducer,
  userRequiredFields: userRequiredFieldsReducer,
  userVerifyEmail: userVerifyEmailReducer,
  channelGroup: channelGroupReducer,
  channelItems: channelItemsReducer,
  updatedChannelItem: updateChannelItemReducer,
  channels: channelsReducer,
  channelKeywords: channelKeywordsReducer,
  channelSearch: channelSearchReducer,
  createChannelItem: createChannelItemReducer,
  currentChannelItems: currentChannelItemsReducer,
  createdChannelGroup: createChannelGroupReducer,
  deletedChannelGroup: deleteChannelGroupReducer,
  directShareChannelsEpg: directShareChannelsEpgReducer,
  epgChannels: epgChannelsReducer,
  channelById: channelByIdReducer,
  dutchChannelGroups: dutchChannelGroupsReducer,
  dutchChannels: dutchChannelsReducer,
  copiedTiers: copiedTierReducer,
  copyTier: copyTierReducer,
  promotedChannels: promotionChannelsReducer,
  playlists: playlistsReducer,
  // playlistsNavbar: playlistsNavbarReducer, // For Arrange Mode
  defaultPlaylists: defaultPlaylistsReducer,
  playlistById: playlistByIdReducer,
  playlistCreate: playlistCreateReducer,
  playlistDelete: playlistDeleteReducer,
  playlistVideoDelete: playlistVideoDeleteReducer,
  playlistVideoReorder: playlistVideoReorderReducer,
  playlistStatuses: getPlaylistStatusesReducer,
  playlistVideoMetaData: playlistVideoMetaDataReducer,
  playlistVideoUpdate: playlistVideoUpdateReducer,
  addVideosToPlaylist: addVideosToPlaylistReducer,
  updatePlaylist: updatePlaylistReducer,
  updateGroup: updateGroupReducer,
  editGroup: editGroupReducer,
  movePlaylistVideo: movePlaylistVideoReducer,
  playlistsArrange: playlistsArrangeReducer,
  arrangeFollowedPlaylists: arrangeFollowedPlaylistsReducer,
  addRelatedVideo: addRelatedVideoReducer,
  relatedVideos: relatedVideosReducer,
  relatedVideoDelete: relatedVideoDeleteReducer,
  playlistWithRelatedVideos: playlistWithRelatedVideosReducer,
  playlistRelatedVideoReorder: playlistRelatedVideoReorderReducer,
  playlistKeywords: playlistKeywordsReducer,
  notFollowedPlaylistsHistory: notFollowedPlaylistsHistoryReducer,
  addSerieToDirectShare: addSerieToDirectShareReducer,
  addVideoToDirectShare: addVideoToDirectShareReducer,
  searchPlaylist: searchPlaylistReducer,
  searchMyPlaylists: searchMyPlaylistsReducer,
  updateSerieItemDetails: updateSerieItemDetailsReducer,
  reports: fetchReportsReducer,
  submitReport: addReportReducer,
  socialLike: socialLikeReducer,
  socialUnlike: socialUnlikeReducer,
  socialFollow: socialFollowReducer,
  socialUnfollow: socialUnfollowReducer,
  socialShare: socialShareReducer,
  socialShareDelete: socialShareDeleteReducer,
  socialSuggestedPlaylists: socialSuggestedPlaylistsReducer,
  adultContentToggle: adultContentToggleReducer,
  adultContentConfirm: adultContentConfirmReducer,
  userProfile: userProfileReducer,
  updateUserProfile: updateUserProfileReducer,
  userProfileById: userProfileByIdReducer,
  userChangePassword: userChangePasswordReducer,
  setBalance: setBalanceReducer,
  transactions: transactionsReducer,
  subscribtions: subscribtionsReducer,
  tiers: tiersReducer,
  tierSubscribe: tierSubscribeReducer,
  tierUnsubscribe: tierUnsubscribeReducer,
  tierDatePeriod: tierDatePeriodReducer,
  couponCode: couponCodeReducer,
  countries: countryReducer,
  invalidCountry: invalidCountryReducer,
  followed: socialFollowedPlaylistsReducer,
  following: socialFollowingPlaylistsReducer,
  view: viewReducer,
  clickView: clickViewReducer,
  viewStart: viewStartReducer,
  viewEnd: viewEndReducer,
  viewList: viewListReducer,
  metadata: metadataReducer,
  statistics: statisticsReducer,
  broadcastStatistics: broadcastStatisticsReducer,
  playlistStatistics: playlistStatisticsReducer,
  exportPlaylistStatistics: exportPlaylistStatisticsReducer,
  homeContent: homeContentReducer,
  homeContentGlobe: homeContentGlobeReducer,
  homeContentHeart: homeContentHeartReducer,
  homeContentTiers: homeContentTiersReducer,
  homeContentUser: homeContentUserReducer,
  homeContentDirectPublish: homeDirectPublishReducer,
  homeContentWatchLater: homeWatchLaterReducer,
  toggleWelcomeMsg: toggleWelcomeMsgReducer,
  searchUnauthorized: searchUnauthorizedReducer,
  searchAuthorized: searchAuthorizedReducer,
  searchHistory: searchHistoryReducer,
  createSearchHistory: createSearchHistoryReducer,
  toggleFollowSearchHistory: toggleFollowSearchHistoryReducer,
  deleteSearchHistoryItem: deleteSearchHistoryItemReducer,
  apiHealth: apiHealthReducer,
  groupDelete: groupDeleteReducer,
  copyGroup: copyGroupReducer,
  createPlaylistGroup: createPlaylistGroupReducer,
  groupArrange: groupArrangeReducer,
  groupPlaylistsArange: groupPlaylistsArangeReducer,
  copyVideo: copyVideoReducer,
  copySerie: copySerieReducer,
  copyEpgItem: copyEpgItemReducer,
  copyEpg: copyEpgReducer,
  copyTierChannel: copyTierChannelReducer,
  createTeam: createTeamReducer,
  teamItem: getTeamReducer,
  deleteTeam: deleteTeamReducer,
  suggestedEmail: FindUsersEmailReducer,
  teams: getTeamsReducer,
  accountMode: setAccountModeReducer,
  deleteTeamUser: deleteTeamUser,
  toggleTeamAdmin: toggleTeamAdmin,
  leaveTeam: leaveTeamReducer,
  deleteTeamPlaylist: deleteTeamPlaylistReducer,
  subscribeToChanel: subscribeToChanelReducer,
  unsubscribeToChanel: unsubscribeToChanelReducer,
});

const initialState = {
  accountMode: localStorage.getItem(LOCALSTORAGE.ACCOUNT_MODE) || ACC_MODES.OWN
};

const middleware = [thunk];

// Trace actions
// const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

const store = createStore(
  reducer,
  initialState,
  // composeEnhancers(applyMiddleware(...middleware))
  composeWithDevTools(applyMiddleware(...middleware))
);

function getDispatch(store) {
  return function withDispatch() {
    return store.dispatch;
  };
}

export const getStoreDispatch = getDispatch(store);

export default store;
