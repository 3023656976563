import React from "react";
import AntdRadio from "antd/lib/radio/index";

const Radio = ({ ...rest }) => {
    return <AntdRadio {...rest}></AntdRadio>;
};


Radio.Group = AntdRadio.Group

export default Radio;
