import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions } from "../../../../../redux/actions/paymentActions";
import { Paragraph } from "../../atoms/Typography";
import "./index.less";

const WalletBalanceDisplayPrototype = ({ onClick }) => {
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.transactions);

  const getBalance = useCallback(() => {
    dispatch(getTransactions());
  }, [dispatch]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  return (
    <div className="balance-display-prototype" onClick={onClick}>
      <Paragraph className="balance">€{message?.balance}</Paragraph>
    </div>
  );
};

export default WalletBalanceDisplayPrototype;
