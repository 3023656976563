import React from "react";
import "./index.less";

const Trash = () => {
    return (
        <svg
            className="trash-icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <defs>
                <clipPath id="clip-path">
                    <rect
                        id="Rectangle_2"
                        data-name="Rectangle 2"
                        width="24"
                        height="24"
                        transform="translate(498 -3148)"
                    />
                </clipPath>
            </defs>
            <g
                id="Mask_Group_2"
                data-name="Mask Group 2"
                transform="translate(-498 3148)"
            >
                <g id="ic_delete_24px" transform="translate(496.667 -3149.333)">
                    <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M6.7,21.111a2.261,2.261,0,0,0,2.286,2.222h9.143a2.261,2.261,0,0,0,2.286-2.222V7.778H6.7ZM21.556,4.444h-4L16.413,3.333H10.7L9.556,4.444h-4V6.667h16Z"
                        transform="translate(-0.222)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Trash;
