import { usePlaylist } from "./usePlaylist";

export const useArchive = () => {
    const { updatePlaylist } = usePlaylist()

    const addPlaylistToArchive = (playlistId) => {
        updatePlaylist({
            playlistId,
            is_archived: true,
        })
    }

    const removePlaylistFromArchive = (playlistId) => {
        updatePlaylist({
            playlistId,
            is_archived: false,
        })
    }

    return {
        addPlaylistToArchive,
        removePlaylistFromArchive
    }
}