import {
  API_HEALTH_CHECK_REQUEST,
  API_HEALTH_CHECK_SUCCESS,
  API_HEALTH_CHECK_FAIL,
} from "../constants/apiHealthConstants";
import { CHOICE_URL } from "@modules/config";
import { axiosInstance } from "../../modules/request/api";

export const checkAPIStatus = () => async (dispatch) => {
  dispatch({ type: API_HEALTH_CHECK_REQUEST });

  try {
    const { status } = await axiosInstance().get(`${CHOICE_URL}/health`);

    if (status !== 200) {
      throw new Error("API FAILED");
    }

    dispatch({ type: API_HEALTH_CHECK_SUCCESS, payload: status });
  } catch (error) {
    dispatch({ type: API_HEALTH_CHECK_FAIL, payload: error.message });
  }
};
