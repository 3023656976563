import React, {
  useContext,
  useEffect,
  useState,
  createRef,
  useCallback,
  useRef,
} from "react";
import PlaylistVideoCard from "@shared/molecules/PlaylistVideoCard";
import { useSelector, useDispatch } from "react-redux";
import SpinnerPrototype from "../../shared/atoms/SpinnerPrototype";
import HeaderPrototype from "../HeaderPrototype";
import { useHistory, useLocation } from "react-router-dom";
import { Context } from "@context/context";
import ShareModalPrototype from "../modals/ShareModalPrototype";
import querystring from "query-string";
import ReportContentModalPrototype from "../modals/ReportContentModalPrototype";
import AddPlaylistItemModalPrototype from "../modals/AddPlaylistItemModalPrototype";
import EditPlaylistModalPrototype from "../modals/EditPlaylistModalPrototype";
import { Home } from "../../../pages/Home/index";
import VideoPlayerWrapper from "../VideoPlayerWrapper";
import VideoPlayer from "@components/shared/atoms/VideoPlayer";

import SortPlaylistPrototype from "../SortPlaylistPrototype";
import {
  addNewSeries,
  addSerieToDirectShare,
  addVideoToDirectShare,
  getPlaylistById,
  updatePlaylistVideo,
} from "@redux/actions/playlistsActions";
import DeleteItemModalPrototype from "../modals/DeleteItemModalPrototype";
import EditPlaylistVideoModalPrototype from "../modals/EditPlaylistVideoModalPrototype";
import ScrollTop from "@shared/atoms/ScrollTop";
import AddWatchLaterContent from "@components/shared/molecules/AddWatchLaterContent";
import {
  follow,
  socialFollowingPlaylists,
  unfollow,
} from "@redux/actions/socialActions";
import { toggleWelcomeMsg } from "@redux/actions/homeContentActions";
import Slider from "react-slick";
// import { useTranslation } from "react-i18next";
import { getViewList, view } from "@redux/actions/viewActions";

import globeImg from "@assets/icons/globe-icon-new.jpeg";
import watchLaterImg from "@assets/icons/watch_later.svg";
import myPlaylistIcon from "@assets/icons/my-playlists-new.jpeg";
import RecentViewsIcon from "@assets/icons/recent_views.svg";
import defaultIcon from "@assets/icons/default-playlist-icon.svg";

import {
  HIDE_HEART_WELCOME,
  HIDE_GLOBE_WELCOME,
} from "@src/const/toggleHomeWelcomeMsg";

import debounce from "lodash/debounce";
import { getUserProfile } from "@redux/actions/profileActions";
import PlaylistSeries from "@components/shared/molecules/PlaylistSeries";
import usePathName from "hooks/usePathName";
import moment from "moment";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { localStorageService } from "@modules/services/localStorage.service";
import Spacer from "@components/shared/atoms/Spacer";

import "./index.less";
import { PAGES } from "const/pages";
import AddToPlaylistConfirmModalPrototype from "../modals/AddToPlaylistConfirmModalPrototype";
import SuccessModalPrototype from "../modals/SuccessModalPrototype";
import { useTranslation } from "react-i18next";
import ConfirmPasteModalPrototype from "../modals/ConfirmPasteModalPrototype";
import { Helmet } from "react-helmet";
import {
  SampleNextArrow,
  SamplePrevtArrow,
} from "@components/shared/molecules/CustomSlickArrow";
import useScrollPositionFromStorage from "hooks/useScrollPositionFromStorage";
import { isIOS, isMacOs, isMobile } from "react-device-detect";
import { VIEWS_PROTOTYPE } from "const/viewsPrototype";
import { useArchive, usePaste } from "hooks";
import { usePlaylist } from "hooks/usePlaylist";
import { METHODS } from "const/apiMethods";
import PopupBlockedModalPrototype from "../modals/PopupBlockedModalPrototype";
import { storePlaylistPositionToStorage } from "@utils/storePlaylistPositionToStorage";
import localforage from "localforage";

import { COPY_ITEM } from "const/playlists";
import AlertModalPrototype from "../modals/AlertModalPrototype";
import PlaylistMessage from "../PlaylistMessage";
import { MESSAGE_TOGGLE } from "const";
import useToggleWelcomeMsg from "hooks/useToggleWelcomeMsg";
import { compareDates, getUrlHostname } from "@utils/functions";
import useWatchLater from "hooks/useWatchLater";
function DroppableComp({ i }) {
  return (
    <Droppable droppableId={`Header:${i}`} type="HeaderDraggable">
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            // className="droppable"
            style={{
              background: snapshot.isDraggingOver ? "lightblue" : "transparent",
            }}
          >
            <Draggable draggableId="header1" index={2}>
              {(provided) => {
                return (
                  <header
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    style={{ background: "transparent" }}
                  >
                    <div className="droppable-placeholder"></div>
                  </header>
                );
              }}
            </Draggable>

            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
}

const MobilePlaylistsPrototype = ({ isPlaylistInTier }) => {
  const isScrolled = useRef(false);
  const { search } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const { addPlaylistToArchive, removePlaylistFromArchive } = useArchive();
  const { deletePlaylist, sharePlaylist } = usePlaylist();
  const { toggleWelcome } = useToggleWelcomeMsg();
  const { constructwatchLaterPlaylistName } = useWatchLater();
  const {
    handlePasteVideo,
    handlePasteSerie,
    handlePasteEpgItem,
    handlePasteEpg,
    handlePasteTierChannel,
  } = usePaste();

  const {
    playlists,
    // error,
  } = useSelector((state) => state.playlists);

  const isLoggedIn = !!localStorageService().get("access_token");

  const parsedQueryString = querystring.parse(search);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [reportType, setReportType] = useState("App//Channel");
  const [reportableId, setReportableId] = useState(parsedQueryString.playlist);
  const [playlistCardRefs, setPlaylistCardRefs] = useState([]);
  const [sliderRefs, setSliderRefs] = useState([]);

  const [followText, setFollowText] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoTitle, setVideoTitle] = useState("");

  const [selectedVideo, setSelectedVideo] = useState({});

  const [isContextMenuVisible, setIsContextMenuVisible] = useState(true);

  const [showPopupBlockedModal, setShowPopupBlockedModal] = useState(false);

  const scrollPositionRef = useRef(null);

  const videoWrapperRef = useRef(null);

  const {
    setShowShareModalPrototype,
    setShowReportContentModalPrototype,
    setShowTermsImagesPrototype,
    showTermsImagesPrototype,
    setShowDeleteItemModalPrototype,
    setSelectedPlaylist,
    selectedPlaylist,
    setShowEditPlaylistVideoModalPrototype,
    copyingItem,
    setPlaylistVideoPrototype,
    setDeletableItemPrototype,
    setDeleteSerieInGroupItem,
    showVideoPlayerPrototype,
    setShowVideoPlayerPrototype,
    setCurrentViewPrototype,
    headerDragStarted,
    setShowSubBarPrototype,
    headerScrollMargin,
    setShowAddToPlaylistConfirmModal,
    setCurrentVideoPrototype,
    setShowSuccessModalPrototype,
    tabletFrameRef,
    isEditMode,
    setIsEditMode,
    togglePlaylistEditMode,
    copiedPlaylist,
    setCopiedPlaylist,
    playlistScrollTop,
  } = useContext(Context);

  const page = usePathName();
  const isPasteable = page === PAGES.myPlaylists;

  const location = useLocation();

  const { playlist, loading: loadingPlaylist } = useSelector(
    (state) => state.playlistById
  );

  const { profile } = useSelector((state) => state.userProfile);

  const isDirectPublish = playlist?.data?.direct_publish;
  const isWatchLater = playlist?.data?.watch_later;

  let seriesWithHeader = null;
  if (playlist?.data?.series.length > 0) {
    // const header = {
    //   id: 1,
    //   type: "header",
    // };
    // seriesWithHeader = [
    //   ...playlist?.data?.series.filter((el) => el?.videos.length > 0),
    // ];
    // seriesWithHeader.splice(headerPosition, 0, header);
    // seriesWithHeader is an old feature to drag end drop header
    seriesWithHeader = playlist?.data?.series;
  }
  const { loading: playlistVideoDeleteLoading } = useSelector(
    (state) => state.playlistVideoDelete
  );

  const { loading: deletePlaylistLoading } = useSelector(
    (state) => state.playlistDelete
  );

  // const { playlist } = useSelector((state) => state.playlistById);

  const { profile: userProfile } = useSelector((state) => state.userProfile);

  const { data: viewListData } = useSelector((state) => state.viewList);

  // const { homeData } = useSelector((state) => state.homeContent);

  const { statuses } = useSelector((state) => state.playlistStatuses);

  const [statuseseObj, setStatusesObj] = useState({});

  const currentPage = usePathName();

  const formatStatuses = useCallback(() => {
    let obj = {};
    statuses?.forEach((status) => {
      obj = { ...obj, [status.name]: status.id };
    });
    setStatusesObj(obj);
  }, [statuses]);

  const findPlaylistFromStorage = useCallback(async () => {
    const playlistPositions = await localforage.getItem("playlist-positions");

    const found = playlistPositions?.find(
      (playlist) =>
        playlist.playlistId === parsedQueryString.playlist &&
        playlist.userId === profile?.data?.id
    );
    return found;
  }, [parsedQueryString, profile]);

  useScrollPositionFromStorage();

  const scrollToCurrentPlaylistSlider = useCallback(async () => {
    if (parsedQueryString.video || parsedQueryString.serie) return;

    if (playlist) {
      const activePlaylistFromStorage = await findPlaylistFromStorage();
      //if not share mode
      scrollPositionRef.current = activePlaylistFromStorage?.scrollPosition;
    }
  }, [playlist, findPlaylistFromStorage, parsedQueryString]);

  const scrollToActivePlaylist = useCallback(async () => {
    if (parsedQueryString.video || parsedQueryString.serie) return;

    const activePlaylistFromStorage = await findPlaylistFromStorage();

    let scrollTop = activePlaylistFromStorage?.scrollPosition || 0;

    if (isMobile) {
      if (!isEditMode && playlist) window.scroll({ top: scrollTop });
    } else {
      if (!isEditMode && playlist)
        tabletFrameRef?.current?.scroll({
          top: scrollTop,
        });
    }
  }, [findPlaylistFromStorage, isEditMode, playlist, tabletFrameRef]);

  useEffect(() => {
    if (!parsedQueryString?.video) return;

    const targetVideo = document.querySelector(
      `[data-videoid="${parsedQueryString?.video}"]`
    );

    if (!targetVideo) return;

    if (isScrolled.current) return;

    targetVideo.scrollIntoView({
      block: "center",
    });

    isScrolled.current = true;
  }, [parsedQueryString]);

  useEffect(() => {
    // Automatically scroll on previous clicked content(series/playlist)
    // scrollToActivePlaylist();

    if (page === PAGES.playlists && playlist) {
      setCurrentViewPrototype(VIEWS_PROTOTYPE.channels);
    }
    if (page === PAGES.myPlaylists && playlist) {
      setCurrentViewPrototype(VIEWS_PROTOTYPE.playlists);
    }
    if (page === PAGES.myPlaylists || page === PAGES.playlists)
      setShowSubBarPrototype(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tabletFrameRef,
    isEditMode,
    page,
    playlist,
    setCurrentViewPrototype,
    setShowSubBarPrototype,
  ]);

  useEffect(() => {
    scrollToCurrentPlaylistSlider();
  }, [scrollToCurrentPlaylistSlider]);

  useEffect(() => {
    formatStatuses();
  }, [formatStatuses]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (location.search.slice(1).split("=")[0] === "recent-view") {
      dispatch(getViewList());
      setShowTermsImagesPrototype(false);
    }
  }, [dispatch, setShowTermsImagesPrototype, location]);

  useEffect(() => {
    if (parsedQueryString.playlist) {
      setShowTermsImagesPrototype(false);
    }
  }, [parsedQueryString, setShowTermsImagesPrototype]);

  useEffect(() => {
    if (
      parsedQueryString.playlist &&
      !Boolean(parsedQueryString.fromDirectPublish)
    ) {
      dispatch(getPlaylistById(parsedQueryString.playlist));
    }
    if (
      parsedQueryString.playlist &&
      Boolean(parsedQueryString.fromDirectPublish)
    ) {
      dispatch(getPlaylistById(parsedQueryString.playlist, true));
    }
  }, [
    dispatch,
    parsedQueryString.playlist,
    parsedQueryString.fromDirectPublish,
  ]);

  useEffect(() => {
    if (playlist) {
      setSelectedPlaylist(playlist.data);
      dispatch(view("App\\Playlist", playlist.data.id));
      if (
        !playlist.data.is_followed &&
        Boolean(parsedQueryString.fromDirectPublish)
      ) {
        localStorageService().set("not-followed-playlist", playlist.data);
      }
    }
  }, [
    playlist,
    dispatch,
    setSelectedPlaylist,
    parsedQueryString.fromDirectPublish,
  ]);

  useEffect(() => {
    return () => {
      if (currentPage !== PAGES.playlists)
        localStorageService().remove("not-followed-playlist");
      const activeSlidePlaylistFromStorage = localStorageService().get(
        "activeSlidePlaylist"
      );
      if (
        activeSlidePlaylistFromStorage &&
        activeSlidePlaylistFromStorage[0]?.playlistIndex
      ) {
        if (playlist?.data?.id && profile?.data?.id) {
          const playlistScrollData = {
            playlistId: playlist.data.id,
            scrollPosition: playlistScrollTop,
            userId: profile.data.id,
          };
          if (
            playlist?.data?.id ===
            activeSlidePlaylistFromStorage[0]?.playlistIndex
          ) {
            playlistScrollData["sliderData"] = activeSlidePlaylistFromStorage;
          }

          storePlaylistPositionToStorage(playlistScrollData);
        }
      }
    };
  }, [setSelectedPlaylist, playlist, dispatch, playlistScrollTop, profile]);

  useEffect(() => {
    if (playlist?.data?.id) {
      const allVideos = playlist?.data?.series.map((serie) => serie.videos);
      if (playlist.data.series) {
        setSliderRefs((serieRef) =>
          Array(playlist.data.series.length)
            .fill()
            .map((_, i) => {
              return serieRef[i] || createRef(serieRef[i]);
            })
        );
      }

      setPlaylistCardRefs((playlistCardRef) =>
        Array(allVideos.length)
          .fill()
          .map((_, i) => {
            return playlistCardRef[i] || createRef(playlistCardRef[i]);
          })
      );
    }
  }, [playlist?.data]);

  const scrollCurrentSlider = useCallback(async () => {
    const activePlaylistFromStorage = await findPlaylistFromStorage();

    const currentPlaylistSliders = activePlaylistFromStorage?.sliderData;

    if (currentPlaylistSliders) {
      const filteredSliderRefs = sliderRefs.filter((ref) =>
        Boolean(ref.current)
      );

      const seriesIds = [];
      const currentSlides = [];

      currentPlaylistSliders.forEach((slider) => {
        seriesIds.push(slider.serieId);
        currentSlides.push(slider.currentSlide);
      });

      const changedSliders = filteredSliderRefs.filter((sliderRef) => {
        return seriesIds.includes(
          Number(sliderRef.current.props["data-serieId"])
        );
      });
      if (changedSliders.length) {
        changedSliders.forEach((ref, index) => {
          //@SEBASTIAAN
          // ref?.current?.slickGoTo(currentSlides[index]);
        });
      }
    } else {
      sliderRefs.forEach((ref) => {
        //@SEBASTIAAN
        // ref?.current?.slickGoTo(0);
      });
    }
  }, [sliderRefs, findPlaylistFromStorage]);

  useEffect(() => {
    scrollCurrentSlider();
  }, [scrollCurrentSlider]);

  useEffect(() => {
    if (playlist?.data?.id) {
      setSliderRefs((sliderRef) =>
        Array(playlist?.data?.series?.length)
          .fill()
          .map((_, i) => {
            return sliderRef[i] || createRef(sliderRef[i]);
          })
      );
    } else if (viewListData) {
      setSliderRefs((sliderRef) =>
        Array(Object.keys(viewListData).length)
          .fill()
          .map((_, i) => {
            return sliderRef[i] || createRef(sliderRef[i]);
          })
      );
    }
  }, [playlist?.data, viewListData]);

  useEffect(() => {
    if (parsedQueryString.video && playlistCardRefs && sliderRefs) {
      let serieIndex;
      let currentVideoIndexInSerie;
      playlist?.data?.series?.forEach((serie, index) => {
        const foundVideo = serie.videos.find(
          (video) => video.id === Number(parsedQueryString.video)
        );
        const foundVideoIndexInSerie = serie.videos.findIndex(
          (video) => video.id === Number(parsedQueryString.video)
        );
        if (foundVideoIndexInSerie > -1) {
          currentVideoIndexInSerie = foundVideoIndexInSerie;
        }
        if (foundVideo) {
          serieIndex = index;
        }
      });

      if (serieIndex > -1) {
        const topScroll =
          playlistCardRefs[serieIndex]?.current?.getBoundingClientRect()?.y -
          playlistCardRefs[serieIndex]?.current?.getBoundingClientRect()
            ?.height;
        if (!parsedQueryString.video) {
          if (isMobile) {
            window.scroll({ top: topScroll });
          } else {
            tabletFrameRef?.current?.scroll({ top: topScroll });
          }
        }

        if (currentVideoIndexInSerie)
          sliderRefs[serieIndex]?.current?.slickGoTo(currentVideoIndexInSerie);

        playlistCardRefs[serieIndex]?.current?.classList.add("active");
        setTimeout(() => {
          playlistCardRefs[serieIndex]?.current?.classList.remove("active");
        }, 1500);
      }
    }
  }, [
    parsedQueryString,
    playlistCardRefs,
    playlist,
    tabletFrameRef,
    sliderRefs,
  ]);

  useEffect(() => {
    if (parsedQueryString.serie && sliderRefs) {
      const sliders = sliderRefs
        .map((serieRef) => serieRef.current)
        .filter(Boolean);

      const ids = [];

      sliders.forEach((slider) => {
        ids.push(slider.props["data-serieId"]);
      });

      let currentIndex = ids.findIndex((id) => id === parsedQueryString.serie);

      if (currentIndex) {
        let containerElement =
          sliderRefs[currentIndex]?.current?.innerSlider?.list;
        if (containerElement) {
          tabletFrameRef?.current?.scroll({
            top: containerElement?.getBoundingClientRect().top,
            block: "center",
            behavior: "smooth",
          });

          window.scroll({
            top: containerElement?.getBoundingClientRect().top,
            block: "center",
            behavior: "smooth",
          });

          containerElement.style.border = "5px solid #ef8508";
        }
      }
    }
  }, [parsedQueryString.serie, sliderRefs]);

  const playlistVideoHandler = (video, serie) => {
    Promise.resolve(dispatch(view("App\\Video", video.id))).then(() => {
      Promise.resolve(dispatch(view("App\\Serie", serie.id))).then(() => {
        if (video.is_external) {
          window.open(video.src, '_blank');

          // if (isIOS || isMacOs) {
          //   if (
          //     !newWin ||
          //     newWin.closed ||
          //     typeof newWin.closed == "undefined"
          //   ) {
          //     //POPUP BLOCKED
          //     setShowPopupBlockedModal(true);
          //   }
          // }
        } else {
          setShowVideoPlayerPrototype(true);
          setVideoTitle(video.title);
          setVideoUrl(video.src);
          if (videoWrapperRef?.current) {
            videoWrapperRef.current.style.display = "block";
          }
        }
      });
    });
  };

  const sharePlaylistItemHandler = (video, serie) => {
    if (!isLoggedIn) return setIsPopupOpen(true);
    setShowShareModalPrototype(true);
    history.push(`playlists?playlist=${serie.playlist_id}&video=${video.id}`);
  };

  const copyPlaylistVideoHandler = (video) => {
    let url =
      window.origin +
      `/playlists?playlist=${playlist?.data?.id}&video=${video.id}`;
    navigator.clipboard.writeText(url);
  };

  const infoboxClickHandler = (serie, ref) => {
    if (playlist?.data.direct_publish) {
      history.push(serie?.url + "&fromDirectPublish=true");
    } else {
      scrollToHomeVideo(serie, ref);
    }
  };

  const scrollToHomeVideo = (serie, ref) => {
    if (ref) {
      const slideAt = serie.videos.findIndex(({ is_home }) => is_home);
      ref?.current.slickGoTo(slideAt);
    }
  };

  const directShareHandler = (shareable) => {
    if (!isLoggedIn) return setIsPopupOpen(true);
    const { serieId, videoId, url } = shareable;

    switch (shareable.type) {
      case "serie":
        Promise.resolve(dispatch(addSerieToDirectShare(serieId, url))).then(
          () => {
            setShowSuccessModalPrototype(true);
          }
        );
        break;
      case "video":
        Promise.resolve(
          dispatch(addVideoToDirectShare(serieId, videoId, url))
        ).then(() => {
          setShowSuccessModalPrototype(true);
        });
        break;
      default:
        throw new Error("Shareable must be video or serie");
    }
  };

  const reportPlaylistHandler = (itemId) => {
    setReportType("App\\Playlist");
    setReportableId(itemId);
    setShowReportContentModalPrototype(true);
  };

  const reportPlaylistItem = (video) => {
    setReportType("App\\Video");
    setReportableId(video.id);
  };

  const deletePlaylistHandler = (id) => {
    deletePlaylist(id);
    setShowTermsImagesPrototype(true);
    history.push("/playlists");
  };

  const editPlaylistVideoHandler = (video, seriesName) => {
    setShowEditPlaylistVideoModalPrototype(true);
    setPlaylistVideoPrototype({ ...video, series_name: seriesName });
  };

  async function makeItemHomeHandler(video, serieId) {
    //
    const data = {
      is_home: true,
      series_id: serieId,
      '_method': METHODS.PUT
    };

    Promise.resolve(dispatch(updatePlaylistVideo(video?.id, data))).then(() => {
      setIsContextMenuVisible(false);
      setShowEditPlaylistVideoModalPrototype(false);
      dispatch(getPlaylistById(selectedPlaylist.id));
    });
  }

  const deletePlaylistVideoHandler = (video) => {
    setShowDeleteItemModalPrototype(true);
    setDeleteSerieInGroupItem(video);
    setPlaylistVideoPrototype(video);
    setDeletableItemPrototype("video");
  };

  const addToPlaylist = (video) => {
    // Show subbar to choose target playlist
    if (!isLoggedIn) return setIsPopupOpen(true);
    if (!localStorageService().get("hide_add_to_playlist_modal")) {
      setShowAddToPlaylistConfirmModal(true);
      setCurrentVideoPrototype(video);
    } else {
      copyingItem(video);
    }
    history.push(`/my-playlists?playlist=${parsedQueryString.playlist}`);
  };

  const onWheelSlider = debounce((e, ref) => {
    if (!ref) return;
    if (!ref.current) return;
    if (e.deltaX > 0) {
      ref?.current?.slickNext();
    } else if (e.deltaX < 0) {
      ref?.current?.slickPrev();
    }
  }, 20);

  const afterChangeSlide = async (sliderRef, sliderIndex, serieId) => {
    const currentSlide = sliderRef?.current.innerSlider.state.targetSlide;
    const currentPlaylistSliders =
      localStorageService().get(`activeSlidePlaylist`);
    if (currentPlaylistSliders) {
      const found = currentPlaylistSliders.find(
        (slide) =>
          slide.sliderIndex === sliderIndex &&
          Number(slide.playlistIndex) === Number(parsedQueryString.playlist) &&
          slide.serieId === serieId
      );
      if (found) {
        found.currentSlide = currentSlide;
      } else {
        if (
          currentPlaylistSliders[0].playlistIndex !== parsedQueryString.playlist
        ) {
          localStorageService().remove("activeSlidePlaylist");
          currentPlaylistSliders.splice(0, currentPlaylistSliders.length); //empty array
        }

        if (
          currentPlaylistSliders.some(
            (item) => item.sliderIndex === sliderIndex
          )
        ) {
          // if array already has item with this sliderIndex
          const currentSliderIndex = currentPlaylistSliders.findIndex(
            (item) => item.sliderIndex === sliderIndex
          );
          currentPlaylistSliders[currentSliderIndex] = {
            currentSlide: sliderRef?.current.innerSlider.state.targetSlide,
            sliderIndex,
            playlistIndex: parsedQueryString.playlist,
            serieId,
          };
        } else {
          currentPlaylistSliders.push({
            currentSlide: sliderRef?.current.innerSlider.state.targetSlide,
            sliderIndex,
            playlistIndex: parsedQueryString.playlist,
            serieId,
          });
        }
      }
      const sortedSlidersByIndex = currentPlaylistSliders.sort(
        (a, b) => a.sliderIndex - b.sliderIndex
      );

      const activePlaylistFromStorage = await findPlaylistFromStorage();
      if (activePlaylistFromStorage?.sliderData)
        activePlaylistFromStorage.sliderData = sortedSlidersByIndex;

      storePlaylistPositionToStorage({
        ...activePlaylistFromStorage,
        sliderData: sortedSlidersByIndex,
      });

      localStorageService().set(`activeSlidePlaylist`, sortedSlidersByIndex);
    } else {
      localStorageService().set(`activeSlidePlaylist`, [
        {
          currentSlide: sliderRef?.current.innerSlider.state.targetSlide,
          sliderIndex,
          playlistIndex: parsedQueryString.playlist,
          serieId,
        },
      ]);
    }
  };

  const followHandler = () => {
    Promise.resolve(
      dispatch(follow("App\\Playlist", playlist?.data?.id.toString()))
    ).then(() => {
      dispatch(getPlaylistById(parsedQueryString.playlist));
      dispatch(socialFollowingPlaylists());
    });
    if (localStorageService().get("not-followed-playlist"))
      localStorageService().remove("not-followed-playlist");
  };

  const unfollowHandler = () => {
    Promise.resolve(
      dispatch(unfollow("App\\Playlist", playlist?.data?.id.toString()))
    ).then(() => {
      dispatch(getPlaylistById(parsedQueryString.playlist));
      dispatch(socialFollowingPlaylists());
    });
  };

  const toggleGlobeWelcomeMsgHandler = () => {
    Promise.resolve(dispatch(toggleWelcomeMsg(HIDE_GLOBE_WELCOME))).then(() => {
      dispatch(getUserProfile());
    });
  };

  const toggleHeartWelcomeMsgHandler = () => {
    Promise.resolve(dispatch(toggleWelcomeMsg(HIDE_HEART_WELCOME))).then(() => {
      dispatch(getUserProfile());
    });
  };

  const handleMessageToggle = () => {
    let message;
    if (isWatchLater) {
      message = MESSAGE_TOGGLE.hide_watch_later_welcome;
    } else if (isDirectPublish) {
      message = MESSAGE_TOGGLE.hide_direct_publish_welcome;
    }
    toggleWelcome(message);
  };

  const recentViewClickHandler = (item) => {
    window.open(item.src);
  };

  const handleCopyVideo = (video) => {
    const videoId = video?.id;
    if (!isLoggedIn) return setIsPopupOpen(true);
    setCopiedPlaylist({
      type: COPY_ITEM.PLAYLIST_VIDEO,
      copiedItem: { videoId },
      copiedItemObj: { video },
    });
  };

  const handleCopySerie = (seriesId) => {
    if (!isLoggedIn) return setIsPopupOpen(true);
    setCopiedPlaylist({
      type: COPY_ITEM.PLAYLIST_SERIE,
      copiedItem: { seriesId },
    });
  };

  const watchLaterHandler = (videoItem) => {
    if (!isLoggedIn) return setIsPopupOpen(true);
    Promise.resolve(
      dispatch(
        addNewSeries({
          name: constructwatchLaterPlaylistName(videoItem?.src),
          playlist_id: playlists?.watchLater[0]?.id,
          videos: [
            {
              description: videoItem?.description,
              image: videoItem?.image,
              title: videoItem?.title,
              src: videoItem?.src,
            },
          ],
        })
      )
    ).then(() => {
      history.push({ search: `?playlist=${playlists?.watchLater[0]?.id}` });
      dispatch(getPlaylistById(playlists?.watchLater[0]?.id));
    });
  };

  useEffect(() => {
    const selectedVideoId = parsedQueryString?.video;
    if (!selectedVideoId) return;

    let foundVideo = null;

    for (let i = 0; i < selectedPlaylist?.series?.length; i++) {
      const serie = selectedPlaylist?.series[i];

      for (let j = 0; j < serie?.videos.length; j++) {
        const currentVideo = serie?.videos[j];
        if (currentVideo.id === +selectedVideoId) {
          foundVideo = currentVideo;
          break;
        }
        if (foundVideo) break;
      }
    }

    if (foundVideo) {
      setSelectedVideo(foundVideo);
    }
  }, [parsedQueryString]);

  useEffect(() => {
    if (
      !playlist?.data?.watch_later &&
      playlist?.data?.user_id !== userProfile?.data?.id &&
      playlist?.data?.status_id === statuseseObj.Public &&
      playlist?.data?.is_followed
    ) {
      setFollowText("Unsubscribe");
    } else if (
      !playlist?.data?.watch_later &&
      playlist?.data?.user_id !== userProfile?.data?.id &&
      playlist?.data?.status_id === statuseseObj.Public &&
      !playlist?.data?.is_followed
    ) {
      setFollowText("Subscribe");
    } else {
      setFollowText("");
    }
  }, [playlist, userProfile, statuseseObj]);

  // useEffect(() => {
  //   return () => {
  //     console.group(
  //       `%c group`,
  //       `font-size:10px; font-weight: 200; color:white; background: red`
  //     );
  //     console.log("gaeshva");
  //     console.groupEnd();
  //     setHeaderPosition(0);
  //     setHeaderDragStarted(false);
  //   };
  // }, [setHeaderDragStarted, setHeaderPosition]);

  const [isMoreInfoOpened, setIsMoreInfoOpened] = useState(false);

  function moreInfoClickHandler() {
    setIsMoreInfoOpened((prevState) => !prevState);
  }

  function getPlaylistIcon() {
    const isWatchLater = playlist?.data?.watch_later;
    const icon = playlist?.data?.icon;
    const doesIconExists = !!icon;

    if (isWatchLater) {
      return watchLaterImg;
    } else if (currentPage === PAGES.myPlaylists) {
      return doesIconExists ? icon : defaultIcon;
    } else if (currentPage === PAGES.playlists) {
      return doesIconExists ? icon : defaultIcon;
    }
  }

  function handelVideoCardSubTitle(serie, video) {
    let subtitle = "";
    if (playlist?.data?.watch_later) {
      const isOlderVideo = compareDates("08/10/2022", video.created_at);

      if (isOlderVideo) {
        const videoSrc = video.src;
        let videoSrcHostName = "";

        try {
          videoSrcHostName = getUrlHostname(videoSrc);
        } catch (e) {}

        subtitle = `${videoSrcHostName} ${moment(video.created_at).format(
          "YYYY-MM-DD"
        )}`;
      } else {
        subtitle = serie.name;
      }
    } else {
      subtitle = serie.name ? serie.name : "No subtitle";
    }

    return subtitle;
  }

  let headerMeet = false;

  let headerComp;

  let upper = [];
  let lower = [];
  if (seriesWithHeader) {
    // (currentPage === "my-playlists" && !location.search) return <HeaderPrototype
    //   name={"my-playlists"}
    //   image={myPlaylistIcon}
    //   isPlaylistsHeader={true}
    //   toggleHeartWelcomeMsgHandler={toggleHeartWelcomeMsgHandler}
    // />
    seriesWithHeader.map((serie, idx) => {
      // const headerComp = (
      //   <Droppable droppableId={`Header:${idx}`} type="HeaderDraggable">
      //     {(provided) => {
      //       return (
      //         <div {...provided.droppableProps} ref={provided.innerRef}>
      //           <Draggable draggableId="header" index={1}>
      //             {(provided) => {
      //               return (
      //                 <div
      //                   {...provided.dragHandleProps}
      //                   {...provided.draggableProps}
      //                   ref={provided.innerRef}
      //                 >
      //                   <HeaderPrototype
      //                     name={playlist?.data?.name || ""}
      //                     image={
      //                       playlist?.data?.watch_later
      //                         ? watchLaterImg
      //                         : playlist?.data?.icon || globeImg
      //                     }
      //                     buttonText={followText}
      //                     isPlaylistsHeader={true}
      //                     toggleEditMode={() => togglePlaylistEditMode()}
      //                     isEditMode={editMode}
      //                     reportHandler={() =>
      //                       reportPlaylistHandler(playlist?.data?.id)
      //                     }
      //                     followHandler={followHandler}
      //                     unfollowHandler={unfollowHandler}
      //                     shareHandler={() => sharePlaylist(playlist.data.id)}
      //                     removePlaylistHandler={() =>
      //                       deletePlaylistHandler(playlist.data.id)
      //                     }
      //                     isWatchLater={
      //                       playlist?.data?.watch_later ? true : false
      //                     }
      //                     scrollTop={true}
      //                     isDraggable={true}
      //                   />
      //                 </div>
      //               );
      //             }}
      //           </Draggable>
      //           {provided.placeholder}
      //         </div>
      //       );
      //     }}
      //   </Droppable>
      // );

      headerComp = (
        <>
          <Spacer height={headerScrollMargin} unit="vh" />

          <HeaderPrototype
            name={playlist?.data?.name || ""}
            image={
              playlist?.data?.watch_later
                ? watchLaterImg
                : playlist?.data?.icon || defaultIcon
            }
            isPlaylistsHeader={true}
            toggleEditMode={() => togglePlaylistEditMode()}
            isEditMode={isEditMode}
            reportHandler={() => reportPlaylistHandler(playlist?.data?.id)}
            buttonText={followText}
            followHandler={followHandler}
            unfollowHandler={unfollowHandler}
            isDefault={true}
            shareHandler={() => {
              setSelectedVideo({});
              sharePlaylist(playlist.data.id);
            }}
            removePlaylistHandler={() =>
              deletePlaylistHandler(playlist.data.id)
            }
            archivePlaylist={() => addPlaylistToArchive(playlist.data.id)}
            removePlaylistFromArchive={() =>
              removePlaylistFromArchive(playlist.data.id)
            }
            toggleWelcomeMessage={handleMessageToggle}
            isDirectPublish={isDirectPublish}
            isArchived={playlist?.data?.is_archived}
            isWatchLater={isWatchLater}
            isDraggable={true}
          />
          <Spacer />
          <PlaylistMessage playlist={playlist?.data} />
        </>
      );
      if (serie.type === "header") headerMeet = true;
      let item = (
        <React.Fragment key={serie.id}>
          {/* {serie.type === "header" ? null : seriesWithHeader[idx - 1] &&
            seriesWithHeader[idx - 1].type === "header" ? null : (
            <DroppableComp i={headerMeet ? idx - 1 : idx} />
          )} */}
          {serie.type !== "header" && (
            <Slider
              infinite={false}
              swipe={!isMoreInfoOpened}
              arrows={!isMoreInfoOpened}
              ref={sliderRefs[idx]}
              key={idx}
              data-serieId={`${serie.id}`}
              nextArrow={<SampleNextArrow />}
              prevArrow={<SamplePrevtArrow />}
              afterChange={() =>
                afterChangeSlide(sliderRefs[idx], idx, serie.id)
              }
            >
              {/* {!playlist?.data?.direct_publish && (
                <>
                  {serie?.image ? (
                    // <PlaylistVideoCard
                    //   coverImg={serie?.image}
                    //   onClick={() => playlistVideoHandler(serie.videos[0])}
                    //   title={serie?.name}
                    //   infoBoxSubtitle={serie.name ? serie.name : "No subtitle"}
                    //   description={serie?.description}
                    //   hasThreeDotMenu={true}
                    //   isSeriesCard={true}
                    //   videoItems={serie?.videos}
                    //   directShareHandler={() =>
                    //     directShareHandler({
                    //       type: "serie",
                    //       serieId: serie.id,
                    //       url: `/playlists${window.location.search}&serie=${serie.id}`,
                    //     })
                    //   }
                    //   hasHomePosition={true}
                    // />
                    <h1>hehe</h1>
                  ) : (
                    // <PlaylistVideoCard
                    //   coverImg={serie.videos[0]?.image}
                    //   onClick={() => playlistVideoHandler(serie.videos[0])}
                    //   title={serie?.title}
                    //   infoBoxSubtitle={serie.name ? serie.name : "No subtitle"}
                    //   description={serie?.description}
                    //   hasThreeDotMenu={true}
                    //   isSeriesCard={true}
                    //   videoItems={serie?.videos}
                    //   directShareHandler={() =>
                    //     directShareHandler({
                    //       type: "serie",
                    //       serieId: serie.id,
                    //       url: `/playlists?playlist=${playlist.data.id}&serie=${serie.id}`,
                    //     })
                    //   }
                    //   hasHomePosition={true}
                    // />
                    null
                  )}
                </>
              )} */}

              {/* <React.Fragment data-serieId={`${serie.id}`} ref={seriesRefs[idx]}> */}
              {location.search &&
                serie.videos.map((video, index) => {
                  return (
                    <div
                      onWheel={(e) => onWheelSlider(e, sliderRefs[idx])}
                      className="video-container"
                      data-videoid={video.id}
                      key={video.id}
                      ref={playlistCardRefs[idx]}
                      id={index}
                    >
                      <PlaylistVideoCard
                        coverImg={video.image}
                        title={video.title}
                        isHomePosition={video?.is_home}
                        onClick={() => {
                          if (video.is_external) {
                            window.open(video.src);
                            return;
                          }
                          playlistVideoHandler(video, serie);
                        }}
                        videoItem={video}
                        sharePlaylistHandler={() => {
                          sharePlaylistItemHandler(video, serie);
                          // setSelectedVideo(video);
                        }}
                        watchLaterHandler={() => watchLaterHandler(video)}
                        reportPlaylistItemHandler={() =>
                          reportPlaylistItem(video)
                        }
                        makeItemHomeHandler={() =>
                          makeItemHomeHandler(video, serie?.id)
                        }
                        deleteHandler={() => deletePlaylistVideoHandler(video)}
                        editHandler={() => {
                          editPlaylistVideoHandler(video, serie.name);
                        }}
                        copyVideo={() => handleCopyVideo(video)}
                        pasteVideoInSerie={(dir) =>
                          handlePasteVideo(serie.id, index, dir)
                        }
                        copySerie={() => handleCopySerie(serie.id)}
                        pasteSerie={(dir) =>
                          handlePasteSerie(parsedQueryString.playlist, idx, dir)
                        }
                        pasteEpgItem={(dir) =>
                          handlePasteEpgItem(serie.id, index, dir)
                        }
                        pasteEpg={(dir) =>
                          handlePasteEpg(parsedQueryString.playlist, idx, dir)
                        }
                        pasteTierChannelAsVideo={(dir) =>
                          handlePasteTierChannel(
                            parsedQueryString.playlist,
                            idx,
                            dir
                          )
                        }
                        copyHandler={() => copyPlaylistVideoHandler(video)}
                        onAddToPlaylist={() => addToPlaylist(video)}
                        infoBoxSubtitle={handelVideoCardSubTitle(serie, video)}
                        description={video.description}
                        hasThreeDotMenu={true}
                        moreButtons={{
                          moreDescription: video.more_description,
                          moreInfoTitle: video.more_info_title,
                          morePhoto: video.more_photo,
                          moreTitle: video.more_title,
                          goToLink: video.go_to,
                          goToTitle: video.go_to_title,
                          moreInfoClickHandler,
                          isMoreInfoOpened,
                        }}
                        directShareHandler={() =>
                          directShareHandler({
                            type: "video",
                            serieId: serie.id,
                            url: `/playlists?playlist=${playlist.data.id}&video=${video.id}`,
                            videoId: video.id,
                          })
                        }
                        infoboxClickHandler={() =>
                          infoboxClickHandler(serie, sliderRefs[idx])
                        }
                        isContextMenuVisibleMobile={isContextMenuVisible}
                        isPlaylistInTier={isPlaylistInTier}
                        isPasteable={isPasteable}
                      />
                    </div>
                  );
                })}
              {/* </React.Fragment> */}
            </Slider>
          )}
        </React.Fragment>
      );
      if (headerMeet) lower.push(item);
      else upper.push(item);
    });
  }

  return (
    <div className="mobile-playlists-prototype">
      {/* {selectedPlaylist && selectedPlaylist.name && !selectedVideo.title && (
        <Helmet>
          <title>{`Watch now on Choice TV: ${selectedPlaylist?.name}`}</title>
          <meta
            property="og:title"
            content={`Watch now on Choice TV: ${selectedPlaylist?.name}`}
          />
          <meta name="og:description" content={selectedPlaylist?.description} />
          <meta property="og:image" content={selectedPlaylist?.icon} />
        </Helmet>
      )} */}

      {selectedVideo && (
        <Helmet>
          <meta
            property="og:title"
            content={`Watch now on Choice TV: ${selectedVideo?.title}`}
          />
          <meta
            property="og:description"
            content={selectedVideo?.description}
          />
          <meta property="og:image" content={selectedVideo?.image} />
          <meta property="og:image:url" content={selectedVideo?.image} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
      )}
      {/*{showPopupBlockedModal && (*/}
      {/*  <PopupBlockedModalPrototype*/}
      {/*    isVisible={showPopupBlockedModal}*/}
      {/*    setIsVisible={setShowPopupBlockedModal}*/}
      {/*  />*/}
      {/*)}*/}
      <AlertModalPrototype
        isVisible={isPopupOpen}
        closeHandler={() => setIsPopupOpen(false)}
      />
      <AddToPlaylistConfirmModalPrototype />
      <ConfirmPasteModalPrototype playlistName={playlist?.data?.name} />
      <SuccessModalPrototype>
        {t("ok_successfully_added_to_your_direct_publish_channel")}
      </SuccessModalPrototype>
      {location.search && !isEditMode ? headerComp : null}
      {currentPage === PAGES.playlists && !location.search && (
        <>
          <HeaderPrototype
            name={"playlists"}
            image={globeImg}
            isPlaylistsHeader={true}
            toggleWelcomeMessage={toggleGlobeWelcomeMsgHandler}
            headerPositionStyle={{ position: "absolute" }}
            buttonText={followText}
            followHandler={followHandler}
            unfollowHandler={unfollowHandler}
          />
        </>
      )}
      {currentPage === PAGES.myPlaylists && !location.search && (
        <>
          <HeaderPrototype
            name={"my-playlists"}
            image={myPlaylistIcon}
            isPlaylistsHeader={true}
            toggleWelcomeMessage={toggleHeartWelcomeMsgHandler}
            headerPositionStyle={{ position: "absolute" }}
          />
        </>
      )}
      {showVideoPlayerPrototype && (
        <VideoPlayerWrapper
          setVideoUrl={setVideoUrl}
          videoUrl={videoUrl}
          title={videoTitle}
          ref={videoWrapperRef}
          className="playlists-video-wrapper"
        >
          <VideoPlayer
            url={videoUrl}
            controls={true}
            playing={true}
            width={"100%"}
          />
        </VideoPlayerWrapper>
      )}
      <>
        <ScrollTop />
        {(playlistVideoDeleteLoading ||
          loadingPlaylist ||
          deletePlaylistLoading) && (
          <SpinnerPrototype
            withOverlay={playlistVideoDeleteLoading || deletePlaylistLoading}
          />
        )}
        <ShareModalPrototype />
        <AddPlaylistItemModalPrototype />
        <EditPlaylistModalPrototype />
        <DeleteItemModalPrototype />
        <EditPlaylistVideoModalPrototype />
        <ReportContentModalPrototype
          reportableType={reportType}
          reportableId={reportableId}
        />
        {showTermsImagesPrototype && <Home />}
        {playlist &&
          !showTermsImagesPrototype &&
          playlist?.data?.series.length === 0 &&
          !isEditMode && (
            <HeaderPrototype
              className="playlist-header"
              name={playlist?.data?.name || ""}
              image={getPlaylistIcon()}
              buttonText={followText}
              isPlaylistsHeader={true}
              toggleEditMode={() => togglePlaylistEditMode()}
              isEditMode={isEditMode}
              reportHandler={() => reportPlaylistHandler(playlist?.data?.id)}
              followHandler={followHandler}
              unfollowHandler={unfollowHandler}
              isDefault={true}
              shareHandler={() => {
                sharePlaylist(playlist.data.id);
              }}
              removePlaylistHandler={() => {
                deletePlaylistHandler(playlist.data.id);
                setIsEditMode(false);
              }}
              isDirectPublish={isDirectPublish}
              isWatchLater={playlist?.data?.watch_later ? true : false}
              headerPositionStyle={{ position: "absolute" }}
            />
          )}
        {isEditMode && (
          <HeaderPrototype
            name={playlist?.data?.name || ""}
            image={getPlaylistIcon()}
            buttonText={followText}
            isPlaylistsHeader={true}
            toggleEditMode={() => togglePlaylistEditMode()}
            isEditMode={isEditMode}
            reportHandler={() => reportPlaylistHandler(playlist?.data?.id)}
            followHandler={followHandler}
            unfollowHandler={unfollowHandler}
            shareHandler={() => sharePlaylist(playlist.data.id)}
            removePlaylistHandler={() => {
              deletePlaylistHandler(playlist.data.id);
              setIsEditMode(false);
            }}
            isWatchLater={playlist?.data?.watch_later ? true : false}
            headerPositionStyle={{ position: "absolute" }}
          />
        )}

        {location.search.slice(1).split("=")[0] === "recent-view" && (
          <HeaderPrototype
            image={RecentViewsIcon}
            name={"Recent view"}
            showChannelMenu={false}
            headerPositionStyle={{ position: "absolute" }}
          />
        )}

        {playlist?.data?.watch_later === true && !isEditMode ? (
          <AddWatchLaterContent />
        ) : null}
        {/* check if the playlist is watch_later type */}
        {isEditMode ? (
          <SortPlaylistPrototype />
        ) : (
          playlistCardRefs &&
          seriesWithHeader && (
            <PlaylistSeries content={playlist.data.series}>
              <div className="scrollable-container">
                {headerDragStarted && upper ? (
                  upper
                ) : (
                  <div
                    className={`mobileplaylists-scrollable ${
                      showVideoPlayerPrototype ? "hide-overflow" : ""
                    } ${
                      upper.length === seriesWithHeader.length
                        ? "full-height"
                        : ""
                    }`}
                  >
                    {upper}
                  </div>
                )}

                {headerDragStarted && lower ? (
                  lower
                ) : (
                  <div
                    className={`mobileplaylists-scrollable  ${
                      showVideoPlayerPrototype ? "hide-overflow" : ""
                    } $ ${
                      lower.length === seriesWithHeader.length
                        ? "full-height"
                        : ""
                    }`}
                  >
                    {lower}
                  </div>
                )}
              </div>
            </PlaylistSeries>
          )
        )}
        {location.search.slice(1).split("=")[0] === "recent-view" &&
          viewListData &&
          Array(Object.keys(viewListData).length)
            .fill()
            ?.map((_, idx) => {
              return (
                <Slider
                  infinite={false}
                  swipe={!isMoreInfoOpened}
                  arrows={!isMoreInfoOpened}
                  nextArrow={<SampleNextArrow />}
                  prevArrow={<SamplePrevtArrow />}
                  key={moment().subtract(idx, "days").format("YYYY-MM-DD")}
                  ref={sliderRefs[idx]}
                >
                  {viewListData[Object.keys(viewListData)[idx]]?.map((item) => {
                    return (
                      <div onWheel={(e) => onWheelSlider(e, sliderRefs[idx])}>
                        <PlaylistVideoCard
                          coverImg={item?.image || item?.icon}
                          title={item?.title}
                          infoBoxSubtitle={Object.keys(viewListData)[idx]}
                          description={item?.description}
                          onClick={() => {
                            recentViewClickHandler(item);
                          }}
                          hasThreeDotMenu={false}
                          isPlaylistInTier={isPlaylistInTier}
                        />
                      </div>
                    );
                  })}
                </Slider>
              );
            })
            .reverse()}
      </>
    </div>
  );
};

export default MobilePlaylistsPrototype;
