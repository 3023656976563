import MainLayout from "../../layout/MainLayout";
import DesktopPrototype from "@components/organisms/DesktopPrototype";
import SpinnerPrototype from "@components/shared/atoms/SpinnerPrototype";
import { useVanity } from "hooks/useVanityUrl";
import { isMobile } from "react-device-detect";

export const VanityPage = () => {
  const [loading] = useVanity();

  return (
    <>
      {!isMobile && (
        <DesktopPrototype>{loading && <SpinnerPrototype />}</DesktopPrototype>
      )}

      {isMobile && (
        <MainLayout fluid={true} className="mobile-layout">
          {loading && <SpinnerPrototype />}
        </MainLayout>
      )}
    </>
  );
};
