import React, { useState, useEffect, useContext } from "react";
import { Context } from "@context/context";
import { useDispatch, useSelector } from "react-redux";
import Form from "@shared/atoms/Form/index";
import Input from "../../../shared/atoms/InputPrototype";
import Button from "../../../shared/atoms/Button";
import Checkbox from "../../../shared/atoms/Checkbox";
import { useTranslation } from "react-i18next";
import ErrorFeedbackPrototype from "../../../shared/atoms/ErrorFeedbackPrototype";

import "./index.less";
import { formTypeAuthPrototype } from "../../../../../context/context";
import {
  registerFinalize,
  registerOnlyEmail,
} from "../../../../../redux/actions/userActions";
import CookiesModalPrototype from "@components/organisms/modals/CookiesModalPrototype";
import PrivacyModalPrototype from "@components/organisms/modals/PrivacyModalPrototype";
import CheckEmailModalPrototype from "@components/organisms/modals/CheckEmailModalPrototype";

const RegisterFormPrototype = ({ closeModal }) => {
  const { t } = useTranslation();
  const [confirmed, setIsConfirmed] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const [email, setEmail] = useState("");
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disableEmailInput, setDisableEmailInput] = useState(false);
  const [onlyEmail, setOnlyEmail] = useState(false);

  const dispatch = useDispatch();

  const {
    loading: loadingRegister,
    message: userInfo,
    error,
  } = useSelector((state) => state.userRegisterFinalize);

  const {
    loading: emailRegisterLoading,
    info: emailRegisterSuccess,
    error: emailRegisterError,
  } = useSelector((state) => state.userRegisterOnlyEmail);

  const { setShowCheckEmailModalPrototype, setModalTypeAuthPrototype, setShowCookiesModalPrototype, setShowPrivacyModalPrototype } =
    useContext(Context);

  const handleCheckboxChange = () => {
    setIsConfirmed(!confirmed);
  };

  useEffect(() => {
    setModalTypeAuthPrototype(formTypeAuthPrototype.register);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.location.search) {
      let email = window.location.search.split("&")[0].split("=")[1];
      setEmail(email);
      setDisableEmailInput(true);
    } else {
      setOnlyEmail(true);
    }
  }, []);

  useEffect(() => {
    if (!window.location.search && email) {
      setDisableSubmitBtn(false);
      return;
    }
    if (loadingRegister || !confirmed) {
      setDisableSubmitBtn(true);
    } else {
      setDisableSubmitBtn(false);
    }
  }, [confirmed, loadingRegister, email]);

  const handleSubmit = () => {
    if (!window.location.search) {
      dispatch(registerOnlyEmail(email, window.location.href));
    } else {
      let token = window.location.search.split("&")[1].split("=")[1];
      dispatch(
        registerFinalize({
          email,
          password,
          nickname,
          token,
          password_confirmation: confirmPassword,
        })
      );
    }
  };


  const openTermsModal = () => {
    setIsConfirmed(false)
    setShowCookiesModalPrototype(true)

  }

  const openPrivacyModal = () => {
    setIsConfirmed(false)
    setShowPrivacyModalPrototype(true)
  }

  useEffect(() => { 
    if (!error && userInfo) {
      setShowCheckEmailModalPrototype(true)
    }
  }, [error, userInfo, setShowCheckEmailModalPrototype])

  return (
    <>
      <CookiesModalPrototype />
      <PrivacyModalPrototype />
      <CheckEmailModalPrototype />
      <Form className="ant-form-prototype register-form-prototype" autocomplete="off">
        <Form.Item>
          <Input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder={t("email")}
            type="email"
            className="input-prototype"
            value={email}
            disabled={disableEmailInput}
          />
          {emailRegisterError && emailRegisterError?.data.errors.email && (
            <ErrorFeedbackPrototype>
              {emailRegisterError.data.errors.email[0]}
            </ErrorFeedbackPrototype>
          )}
        </Form.Item>

        {!onlyEmail && (
          <>
            <Form.Item>
              <Input
                onChange={(e) => {
                  setNickname(e.target.value);
                }}
                placeholder={t("username")}
                className="input-prototype"
              />
            </Form.Item>

            <Form.Item>
              <Input
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder={t("password")}
                className="input-prototype"
                type="password"
              />
            </Form.Item>

            <Form.Item>
              <Input
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                placeholder={t("confirm_password")}
                className="input-prototype"
                type="password"
              />
            </Form.Item>

            <Form.Item>
              <Checkbox onChange={handleCheckboxChange} checked={confirmed} />{" "}
              <span onClick={handleCheckboxChange}>
                {t("i_agree_to_the")}
              </span>
              <span onClick={openTermsModal} className="highlight-text">
                {t("terms_of_service")}
              </span>
              <span>{" "}{t("and")}{" "}</span>
              <span onClick={openPrivacyModal} className="highlight-text">
                {t("the_privacy_statement")}
              </span>
            </Form.Item>
          </>
        )}
        {error && error?.data.errors.email && (
          <ErrorFeedbackPrototype>
            {error.data.errors.email[0]}
          </ErrorFeedbackPrototype>
        )}
        {error && error?.data.errors.password && (
          <ErrorFeedbackPrototype>
            {error.data.errors.password[0]}
          </ErrorFeedbackPrototype>
        )}
        {error && error?.data.errors.nickname && (
          <ErrorFeedbackPrototype>
            {error.data.errors.nickname[0]}
          </ErrorFeedbackPrototype>
        )}
        {emailRegisterSuccess && (
          <ErrorFeedbackPrototype>{t("confirmation_email_sent")}</ErrorFeedbackPrototype>
        )}
        {emailRegisterError && (
          <ErrorFeedbackPrototype>
            {emailRegisterError?.data?.email}
          </ErrorFeedbackPrototype>
        )}
        {userInfo && (
          <ErrorFeedbackPrototype>
            {t("registration_success")}
          </ErrorFeedbackPrototype>
        )}
        <Form.Item>
          <Button
            disabled={disableSubmitBtn || emailRegisterLoading}
            onClick={handleSubmit}
            className="ant-btn-prototype"
          >
            {t("register")}
          </Button>
          <Button onClick={closeModal} className="ant-btn-prototype white">
            {t("cancel")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default RegisterFormPrototype;
