import {
  STATISTICS_REQUEST,
  STATISTICS_SUCCESS,
  STATISTICS_FAIL,
  BROADCAST_STATISTICS_REQUEST,
  BROADCAST_STATISTICS_SUCCESS,
  BROADCAST_STATISTICS_FAIL,
  PLAYLIST_STATISTICS_REQUEST,
  PLAYLIST_STATISTICS_SUCCESS,
  PLAYLIST_STATISTICS_FAIL,
  EXPORT_PLAYLIST_STATISTICS_FAIL,
  EXPORT_PLAYLIST_STATISTICS_REQUEST,
  EXPORT_PLAYLIST_STATISTICS_SUCCESS,
} from "../constants/statisticsConstants";

export const statisticsReducer = (state = {}, action) => {
  switch (action.type) {
    case STATISTICS_REQUEST:
      return { loading: true };
    case STATISTICS_SUCCESS:
      return { loading: false, data: action.payload };
    case STATISTICS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const broadcastStatisticsReducer = (state = {}, action) => {
  switch (action.type) {
    case BROADCAST_STATISTICS_REQUEST:
      return { loading: true };
    case BROADCAST_STATISTICS_SUCCESS:
      return { loading: false, data: action.payload };
    case BROADCAST_STATISTICS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const playlistStatisticsReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYLIST_STATISTICS_REQUEST:
      return { loading: true }
    case PLAYLIST_STATISTICS_SUCCESS:
      return { loading: false, data: action.payload }
    case PLAYLIST_STATISTICS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state;
  }
}

export const exportPlaylistStatisticsReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPORT_PLAYLIST_STATISTICS_REQUEST:
      return { loading: true }
    case EXPORT_PLAYLIST_STATISTICS_SUCCESS:
      return { loading: false, data: action.payload }
    case EXPORT_PLAYLIST_STATISTICS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state;
  }
}