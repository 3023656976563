import { useContext, useEffect } from "react";
import { localStorageService } from "@modules/services/localStorage.service";
import debounce from "lodash/debounce";
import { Context } from "@context/context";
import usePathName from "./usePathName";
import { PAGES } from "const/pages";
import querystring from "query-string";
import { useLocation } from "react-router-dom";


function useScrollPositionFromStorage() {
  const { setPlaylistScrollTop } = useContext(Context);
  const { tabletFrameRef } = useContext(Context);

  const page = usePathName();
  const { search } = useLocation();
  const parsedQueryString = querystring.parse(search);

  useEffect(() => {
    function addScrollToStorage() {
      const tabletContainer = tabletFrameRef?.current;
      if (tabletContainer) {
        if (page === PAGES.playlists)
          localStorageService().set(
            "globe_scroll_position",
            tabletContainer.scrollTop
          );
        if (page === PAGES.myPlaylists)
          localStorageService().set(
            "heart_scroll_position",
            tabletContainer.scrollTop
          );
        if (page === PAGES.tiers && parsedQueryString?.channel) {
          localStorageService().set(
            "tier_scroll_position",
            tabletContainer.scrollTop
            );
          }
        setPlaylistScrollTop(tabletContainer.scrollTop);
      } else {
        if (page === PAGES.playlists) {
          localStorageService().set(
            "globe_scroll_position",
            window.pageYOffset
          );
        }
        if (page === PAGES.myPlaylists) {
          localStorageService().set(
            "heart_scroll_position",
            window.pageYOffset
          );
        }
        if (page === PAGES.tiers && parsedQueryString?.channel) {
          console.log("ty", parsedQueryString?.channel);
          localStorageService().set(
            "tier_scroll_position",
            window.pageYOffset
          );
        }
        setPlaylistScrollTop(window.pageYOffset);
      }
    }
    const delayedAddScrollToStorage = debounce(() => {
      addScrollToStorage();
    }, 200);

    const tabletContainer = tabletFrameRef.current;
    if (tabletFrameRef && tabletContainer) {
      tabletContainer?.addEventListener("scroll", delayedAddScrollToStorage);
    } else {
      setTimeout(() => {
        window.addEventListener("scroll", delayedAddScrollToStorage);
      }, 3000)
    }
    return () => {
      if (tabletFrameRef && tabletContainer) {
        tabletContainer?.removeEventListener(
          "scroll",
          delayedAddScrollToStorage
        );
      } else {
        window.removeEventListener("scroll", delayedAddScrollToStorage);
      }
    };
  }, [tabletFrameRef, page, setPlaylistScrollTop]);
}

export default useScrollPositionFromStorage;
