import Modal from "@shared/atoms/Modal/index";
import { Paragraph } from "@shared/atoms/Typography/index";

import "./index.less";

const SimplePopup = ({
  text,
  onAccept,
  onCancel,
  isVisible,
  acceptText = "Accept",
  cancelText = "Reject",
}) => {
  return (
    <Modal
      visible={isVisible}
      className="modal-basic-prototype simple-popup"
      mask={true}
      closable={true}
      onCancel={onCancel}
      footer={
        <>
          <button className="accept modal-button" onClick={onAccept}>
            {acceptText}
          </button>

          <button className="modal-button" onClick={onCancel}>
            {cancelText}
          </button>
        </>
      }
    >
      <div>
        <Paragraph>{text}</Paragraph>
      </div>
    </Modal>
  );
};

export default SimplePopup;
