import { useSelector } from "react-redux";

export const useStatus = () => {
  const { statuses } = useSelector((state) => state.playlistStatuses);

  function findStatusId(status) {
    return statuses.find(({ name }) => name === status)?.id;
  }

  return {
    findStatusId,
  };
};
