import React from "react";
import { Title, Paragraph } from "@shared/atoms/Typography";
import List from "@shared/atoms/List/index";
import { useTranslation } from "react-i18next";

import "./index.less";

const TransactionsItem = ({ tierName, date, price, type, isPaid }) => {
    const { t } = useTranslation();
    return (
        <List.Item>
            <div className="content-container">
                <Title level={4} className="title">
                    {type === "balance"
                        ? `${t("add_balance")}`
                        : `${t("wallet_subscription_for")} (${tierName})`}
                </Title>
                <Paragraph className={isPaid ? "paid" : "not-paid"}>
                    {isPaid ? `${t("paid")}` : `${t("not_paid")}`}
                </Paragraph>
            </div>

            <div className="content-container">
                <Paragraph className="date">{date}</Paragraph>
                <Paragraph
                    className={`price ${type === "balance" ? "balance" : ""} `}
                >
                    € {price}
                </Paragraph>
            </div>
        </List.Item>
    );
};

export default TransactionsItem;
