import React, { useEffect, useRef } from "react";

const CookieInfo = () => {
    const cookieInfoContainerRef = useRef(null);
    const cookieInfoCurrent = cookieInfoContainerRef?.current;

    useEffect(() => {
        const script = document.createElement("script");

        script.src =
            "https://consent.cookiebot.com/2d8b6fef-0d07-4fb1-bf59-6763486b9647/cd.js";
        script.async = true;
        script.type = "text/javascript";

        cookieInfoCurrent.appendChild(script);

        return () => {
            if (cookieInfoCurrent) {
                cookieInfoCurrent.removeChild(script);
            }
        };
    }, [cookieInfoCurrent]);
    return <div ref={cookieInfoContainerRef}></div>;
};

export default CookieInfo;
