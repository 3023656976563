import { localStorageService } from "@modules/services/localStorage.service";
/**
 * function that saves latest playlist in local storage
 *
 * @param {String} playlistId
 * @param {String} page
 * @example
 *  saveLatestPlaylistId(playlist.id,"globe")
 *
 */
export const saveLatestPlaylistId = (playlistId, page) => {
  localStorageService().set(`${page}_playlist_id`, playlistId);
};
