import {
  GET_HOME_CONTENT_REQUEST,
  GET_HOME_CONTENT_SUCCESS,
  GET_HOME_CONTENT_FAIL,
  GET_HOME_GLOBE_REQUEST,
  GET_HOME_GLOBE_SUCCESS,
  GET_HOME_GLOBE_FAIL,
  GET_HOME_HEART_REQUEST,
  GET_HOME_HEART_SUCCESS,
  GET_HOME_HEART_FAIL,
  GET_HOME_TIERS_REQUEST,
  GET_HOME_TIERS_SUCCESS,
  GET_HOME_TIERS_FAIL,
  TOGGLE_WELCOME_MESSAGE_REQUEST,
  TOGGLE_WELCOME_MESSAGE_SUCCESS,
  TOGGLE_WELCOME_MESSAGE_FAIL,
  GET_HOME_USER_REQUEST,
  GET_HOME_USER_SUCCESS,
  GET_HOME_USER_FAIL,
  GET_HOME_DIRECT_REQUEST,
  GET_HOME_DIRECT_SUCCESS,
  GET_HOME_DIRECT_FAIL,
  GET_HOME_WATCH_LATER_REQUEST,
  GET_HOME_WATCH_LATER_SUCCESS,
  GET_HOME_WATCH_LATER_FAIL,
} from "../constants/homeConstants";

export const homeContentReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_HOME_CONTENT_REQUEST:
      return { loading: true };
    case GET_HOME_CONTENT_SUCCESS:
      return { loading: false, homeData: action.payload };
    case GET_HOME_CONTENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const homeContentGlobeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_HOME_GLOBE_REQUEST:
      return { loading: true };
    case GET_HOME_GLOBE_SUCCESS:
      return { loading: false, globeData: action.payload };
    case GET_HOME_GLOBE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const homeContentHeartReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_HOME_HEART_REQUEST:
      return { loading: true };
    case GET_HOME_HEART_SUCCESS:
      return { loading: false, heartData: action.payload };
    case GET_HOME_HEART_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const homeContentTiersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_HOME_TIERS_REQUEST:
      return { loading: true };
    case GET_HOME_TIERS_SUCCESS:
      return { loading: false, tierData: action.payload };
    case GET_HOME_TIERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const homeContentUserReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_HOME_USER_REQUEST:
      return { loading: true };
    case GET_HOME_USER_SUCCESS:
      return { loading: false, userData: action.payload };
    case GET_HOME_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const homeDirectPublishReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_HOME_DIRECT_REQUEST:
      return { loading: true };
    case GET_HOME_DIRECT_SUCCESS:
      return { loading: false, data: action.payload };
    case GET_HOME_DIRECT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const homeWatchLaterReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_HOME_WATCH_LATER_REQUEST:
      return { loading: true };
    case GET_HOME_WATCH_LATER_SUCCESS:
      return { loading: false, data: action.payload };
    case GET_HOME_WATCH_LATER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const toggleWelcomeMsgReducer = (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_WELCOME_MESSAGE_REQUEST:
      return { loading: true };
    case TOGGLE_WELCOME_MESSAGE_SUCCESS:
      return { loading: false, data: action.payload };
    case TOGGLE_WELCOME_MESSAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Selectors
export const selectHomeContent = (state) => state.homeContent;

export const selectHomeGlobeContent = (state) => state.homeContentGlobe;

export const selectHomeHeartContent = (state) => state.homeContentHeart;

export const selectHomeTiersContent = (state) => state.homeContentTiers;

export const selectHomeUserContent = (state) => state.homeContentUser;

export const selectHomeDirectPublishContent = (state) => state.homeContentDirectPublish;

export const selectHomeWatchLaterContent = (state) => state.homeContentWatchLater;

export const selectToggleWelcomeMsg = (state) => state.toggleWelcomeMsg;
