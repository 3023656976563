import { useState, useEffect, useContext, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Title } from "../../shared/atoms/Typography";
import { StarFilled } from "@ant-design/icons";
// import SpinnerPrototype from "../../shared/atoms/SpinnerPrototype";

import { useHistory } from "react-router-dom";
import { Context } from "@context/context";

import "./index.less";
import Image from "@components/shared/atoms/Image";
import ChoiceIcon from "@assets/icons/choice-icon-main.png";
import editImg from "@assets/icons/edit.svg";
import addImg from "@assets/icons/add.svg";
import { getTeam, leaveTeamAction } from "@redux/actions/TeamActions";
import Spacer from "@components/shared/atoms/Spacer";
import AddPlaylistsInTeam from "../AddPlaylistsInTeam";

import EditTeamMemberModal from "../modals/EditTeamMemberModal";
import AddMemberToTeamModal from "../modals/AddMemberToTeamModalPrototype";
import SimplePopupWithSingleButton from "../modals/SimplePopupWithButton";
import SimplePopup from "../modals/SimpePopup";
import PopupWithButtonAndSubText from "../modals/PopupWithButtonAndSubText";
import { useAccountMode } from "hooks";

const TeamsPrototype = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    isEditUserModalVisible,
    setIsEditUserModalVisible,
    showAddMemberToTeamModal,
    setShowAddMemberToTeamModal,
  } = useContext(Context);

  const { t } = useTranslation();
  const [userToEdit, setUsertoEdit] = useState(null);
  const [isOnlyAdminLeaveWarning, toggleIsOnlyAdminLeaveWarning] = useReducer(
    (state) => !state,
    false
  );
  const [isLastMemberLeaveWarning, toggleIsLastMemberLeaveWarning] = useReducer(
    (state) => !state,
    false
  );
  const [isMemberLeaveWarning, toggleIsMemberLeaveWarning] = useReducer(
    (state) => !state,
    false
  );
  const [isLastMemberLeaveLastWarning, setIsLastMemberLeaveLastWarning] =
    useState(false);

  const teamId = history.location.search.split("=")[1];
  const { data } = useSelector((state) => state.teamItem);
  const { profile } = useSelector((state) => state.userProfile);
  const { setOwnMode } = useAccountMode();
  const adminList = [];

  const team = data?.data;
  const loggedInUser = profile?.data?.id;

  const userEditHandler = (user) => {
    setIsEditUserModalVisible(true);
    setUsertoEdit(user);
  };

  const isUserAdmin = () => {
    // if (team?.users.length === 1) {
    //   return adminList
    // }
    team?.users.map((teamUser) => {
      if (teamUser?.is_admin) {
        adminList.push(teamUser?.id);
      }
      return adminList;
    });
  };

  isUserAdmin();

  const isLoggedInUserAdmin = adminList.includes(loggedInUser, 0);

  const handleLeaveTeamClick = () => {
    if (isLastuser()) {
      toggleIsLastMemberLeaveWarning();
    } else if (hasOneAdmin() && isAdmin(adminList[0])) {
      toggleIsOnlyAdminLeaveWarning();
    } else {
      toggleIsMemberLeaveWarning();
    }
  };

  const handleLeaveTeam = (force = false) => {
    Promise.resolve(dispatch(leaveTeamAction(teamId, force))).then(() => {
      history.push("/settings");
    });
  };

  const handleLeaveWarningAccept = () => {
    handleLeaveTeam();
    toggleIsMemberLeaveWarning();
  };

  const handleLastMemberLeaveWarningAccept = () => {
    toggleIsLastMemberLeaveWarning();
    setIsLastMemberLeaveLastWarning(true);
  };

  const hasOneAdmin = () => {
    return adminList.length === 1;
  };

  const isAdmin = (adminId) => {
    return profile.data.id === adminId;
  };

  const isLastuser = () => {
    return team.users.length === 1;
  };

  useEffect(() => {
    if (!teamId) return;

    dispatch(getTeam(teamId));
  }, [dispatch, teamId]);

  return (
    <>
      <div className="teams-container-prototype">
        <Title className="heading">
          {t("teams")} - {team?.name}
        </Title>
        {/* {(statisticsLoading || broadcastStatisticsLoading || playlistChartDataLoading) && (
          <SpinnerPrototype />
        )} */}
        <div className="teams-content">
          <div className="teams-heading">
            <p>{team?.description ? team?.description : "No description"}</p>

            <Image src={ChoiceIcon} />
          </div>

          <div className="teams-list">
            <div className="teams-list-row add-item">
              <p>Add new member</p>
              <Image
                src={addImg}
                onClick={() => {
                  setShowAddMemberToTeamModal(true);
                }}
              />
            </div>

            <div className="teams-list-groups">
              {team?.users.map((user) => {
                return (
                  <div key={user?.id} className="teams-list-row">
                    <div className="teams-list-item">
                      {adminList.length >= 1 ? (
                        <>
                          {/* <StarFilled className="admin-icon" /> */}
                          <p>{user?.is_admin ? "Admin" : "Member"}</p>
                          <Image src={ChoiceIcon} />
                        </>
                      ) : null}

                      <div className="teams-info">
                        <p>{user?.email && user?.email.slice(0, 10)}</p>
                        <p>
                          {user?.nickname ? user?.nickname : "User Nickname"}
                        </p>
                      </div>
                    </div>

                    {adminList.length >= 1 && isLoggedInUserAdmin ? (
                      <Image
                        src={editImg}
                        onClick={() => userEditHandler(user)}
                      />
                    ) : null}
                  </div>
                );
              })}
              {team?.pending_users.map((user) => {
                return (
                  <div key={user?.id} className="teams-list-row">
                    <div className="teams-list-item">
                      <>
                        <p>Pending</p>
                        <Image src={ChoiceIcon} />
                      </>

                      <div className="teams-info">
                        <p>{user?.email && user?.email.slice(0, 10)}</p>
                        <p>
                          {user?.nickname ? user?.nickname : "User Nickname"}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="leave-btn-cnt">
            <button className="leave-btn" onClick={handleLeaveTeamClick}>
              Leave
            </button>
          </div>
          <AddPlaylistsInTeam />
          <Spacer height={15} />
        </div>
      </div>
      <AddMemberToTeamModal
        visible={showAddMemberToTeamModal}
        onCancel={() => setShowAddMemberToTeamModal(false)}
      />
      <SimplePopupWithSingleButton
        isVissible={isOnlyAdminLeaveWarning}
        btnText="Okay"
        text={t("leave_team_only_admin_message")}
        onAccept={toggleIsOnlyAdminLeaveWarning}
        onCansel={toggleIsOnlyAdminLeaveWarning}
      />
      <PopupWithButtonAndSubText
        isVissible={isLastMemberLeaveWarning}
        btnText="Cancel"
        subText="I do not care, please leave anyway"
        text={t("leave_team_last_member")}
        onBtnClick={toggleIsLastMemberLeaveWarning}
        onSubTextClick={handleLastMemberLeaveWarningAccept}
      />
      <SimplePopup
        isVisible={isMemberLeaveWarning}
        text={t("are_you_sure")}
        onAccept={handleLeaveWarningAccept}
        onCancel={toggleIsMemberLeaveWarning}
      />
      <SimplePopup
        isVisible={isLastMemberLeaveLastWarning}
        text={"are you really sure?"}
        acceptText="Yes"
        cancelText="No"
        onAccept={() => {
          handleLeaveTeam(true);
          setIsLastMemberLeaveLastWarning(false);
          setOwnMode();
        }}
        onCancel={() => {
          setIsLastMemberLeaveLastWarning(false);
        }}
      />
      {isEditUserModalVisible && (
        <EditTeamMemberModal
          text={"User Actions"}
          isVisible={isEditUserModalVisible}
          onCancel={() => setIsEditUserModalVisible(false)}
          user={userToEdit}
          adminList={adminList}
          teamId={team?.id}
        />
      )}
    </>
  );
};

export default TeamsPrototype;
