import React, { useContext } from "react";
import { Context } from "@context/context";

import VideoItem from "./VideoItem";

const SorterRow = (
  { children, dragHandleProps, row, serieItem, isDragging, onClick, seriesName, ...rest },
  ref
) => {
  const {
    setShowUpdateSerieTitleModal,
    setCurrentSerieItem
  } = useContext(Context);

  return (
    <>
      <div
        className={`sorter-row ${isDragging ? "sorter-row--dragging" : ""}`}
        ref={ref}
        {...rest}
      >
        {seriesName && <p
          dangerouslySetInnerHTML={{__html: seriesName}}
          className="sorter-title"
          onClick={() => {
            setShowUpdateSerieTitleModal(true);
            setCurrentSerieItem(serieItem);
          }}/>
        }
        <div className="sorter-row__content">
          <div className="sorter-row__handle" {...dragHandleProps}></div>
          {children}
        </div>
      </div>
    </>
  );
};

const forwaredSorterRow = React.forwardRef(SorterRow);
forwaredSorterRow.Item = VideoItem;

export default forwaredSorterRow;
