export const DELETE_GROUP_REQUEST = "DELETE_GROUP_REQUEST";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAIL = "DELETE_GROUP_FAIL";

export const EDIT_GROUP_REQUEST = "EDIT_GROUP_REQUEST";
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS";
export const EDIT_GROUP_FAIL = "EDIT_GROUP_FAIL";

export const COPY_GROUP_REQUEST = "COPY_GROUP_REQUEST";
export const COPY_GROUP_SUCCESS = "COPY_GROUP_SUCCESS";
export const COPY_GROUP_FAIL = "COPY_GROUP_FAIL";

export const GROUP_ARRANGE_REQUEST = "GROUP_ARRANGE_REQUEST"
export const GROUP_ARRANGE_SUCCESS = "GROUP_ARRANGE_SUCCESS"
export const GROUP_ARRANGE_FAIL = "GROUP_ARRANGE_FAIL"

export const GROUP_PLAYLISTS_ARRANGE_REQUEST = "GROUP_PLAYLISTS_ARRANGE_REQUEST"
export const GROUP_PLAYLISTS_ARRANGE_SUCCESS = "GROUP_PLAYLISTS_ARRANGE_SUCCESS"
export const GROUP_PLAYLISTS_ARRANGE_FAIL = "GROUP_PLAYLISTS_ARRANGE_FAIL"