import React,{useContext} from "react";
import {Context} from '@context/context';
import LogoWhite from "@assets/images/choice-logo-white.svg";
import Image from "@shared/atoms/Image/index";
import { Title } from "@shared/atoms/Typography/index";

import "./index.less";

const AuthHeaderMobile = () => {
    const {authMobileHeading,authMobileSubHeading} = useContext(Context)
    return (
        <header className="auth-header-mobile">
            <Image
                preview={false}
                className="choice-logo white-logo"
                src={LogoWhite}
                alt="white-logo"
            />
            <Title level={3}>{authMobileHeading}</Title>
            <Title level={3}>{authMobileSubHeading}</Title>
        </header>
    );
};

export default AuthHeaderMobile;
