import React from "react";
import { Link } from "react-router-dom";
import { Title, Paragraph } from "@shared/atoms/Typography";
import { useTranslation } from "react-i18next";

import "./index.less";

export const NotFound = () => {
    const { t } = useTranslation();
    return (
        <main className="not-found-page">
            <section className="error-content">
                <Title className="title" level={1}>
                    404 {t("not_found")}
                </Title>
                <Paragraph>
                    {t("page_not_found")}{" "}
                    <Link to="/channels">{t("go_back")}</Link>
                </Paragraph>
            </section>
        </main>
    );
};
