import { useState, useEffect, useRef, useCallback, useContext } from "react";
import { Context } from "@context/context";

import "./index.less";

const AirPlay = () => {
  const [hasAirPlaySupport, setHasAirPlaySupport] = useState(false);
  const { videoPlayerItemRef } = useContext(Context);
  const airPlayRef = useRef();

  const initAirPlay = useCallback(() => {
    const airPlayButton = airPlayRef.current;

    const videoItem = videoPlayerItemRef.current;
    if (!airPlayButton && !videoItem) {
      return;
    } else {
      videoItem.addEventListener(
        "webkitplaybacktargetavailabilitychanged",
        function (event) {
          switch (event.availability) {
            case "available":
              airPlayButton.hidden = false;
              airPlayButton.disabled = false;
              break;
            case "not-available":
              airPlayButton.hidden = true;
              airPlayButton.disabled = true;
              break;
            default:
              return;
          }
        }
      );
    }
  }, [videoPlayerItemRef]);

  useEffect(() => {
    if (window.WebKitPlaybackTargetAvailabilityEvent) {
      initAirPlay();
      setHasAirPlaySupport(true);
    }
    let videoPlayerContainer = videoPlayerItemRef?.current;

    return () => {
      if (!videoPlayerContainer) return;
      videoPlayerContainer.removeEventListener(
        "webkitplaybacktargetavailabilitychanged",
        initAirPlay
      );
    };
  }, [initAirPlay, videoPlayerItemRef]);

  const handleAirPlay = () => {
    if (!window.WebKitPlaybackTargetAvailabilityEvent) return;
    if (videoPlayerItemRef.current) {
      const video = videoPlayerItemRef.current.firstChild.firstChild;
      video.webkitShowPlaybackTargetPicker();
    }
  };

  return (
    <>
      {hasAirPlaySupport && (
        <button id="airPlayButton" ref={airPlayRef} onClick={handleAirPlay}>
          AirPlay
        </button>
      )}
    </>
  );
};

export default AirPlay;
