// import { useTranslation } from "react-i18next";
import { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Input from "@shared/atoms/Input/index";
import Button from "@shared/atoms/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  getPlaylistById,
  addVideoInPlaylistNew,
  addNewSeries,
} from "../../../../../redux/actions/playlistsActions";
import querystring from "query-string";
import { getMetaData } from "../../../../../redux/actions/metaDataActions";
import { URL_REGEX } from "../../../../../const/regex";
// import { useTranslation } from 'react-i18next';
import ErrorFeedbackPrototype from "@components/shared/atoms/ErrorFeedbackPrototype";
import SpinnerPrototype from "@components/shared/atoms/SpinnerPrototype";
import debounce from "lodash/debounce";
import useWatchLater from "hooks/useWatchLater";

import "./index.less";

const AddWatchLaterContent = () => {
  const [link, setLink] = useState("");
  const [showLinkErr, setShowLinkErr] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const { search } = useLocation();
  const { constructwatchLaterPlaylistName } = useWatchLater()

  const inputRef = useRef(null);

  const parsedQueryString = querystring.parse(search);

  const dispatch = useDispatch();
  const { data: metaData, loading: metaDataLoading } = useSelector(
    (state) => state.metadata
  );

  const { loading: addVideosLoading } = useSelector(
    (state) => state.addVideosToPlaylist
  );

  const addItemToPlaylistHandler = () => {
    Promise.resolve(
      dispatch(
        addNewSeries({
          name: constructwatchLaterPlaylistName(link),
          playlist_id: parsedQueryString.playlist,
          videos: [{
            description: metaData?.description,
            image: metaData?.image,
            title: metaData?.title,
            src: link,
          }],
        })
      )
    ).then(() => {
      dispatch(getPlaylistById(parsedQueryString.playlist));
      setLink("");
    });
  };

  const handleOnChange = debounce((link) => {
    setLink(link);
    if (link && link.match(URL_REGEX)) {
      setShowLinkErr(false);
      setDisableBtn(false);
      dispatch(getMetaData(link));
    } else {
      setShowLinkErr(true);
      setDisableBtn(true);
    }

    if (link.length === 0) {
      setShowLinkErr(false);
      setDisableBtn(true);
    }
  }, 10);

  return (
    <div className="add-in-watch-later-container">
      {metaDataLoading && <SpinnerPrototype withOverlay={true} size={8} />}
      <h3 className="add-watch-later-title">Add content in Watch Later:</h3>
      <div className="add-watch-later-container">
        <Input
          placeholder="https://www.example.com"
          onChange={(e) => handleOnChange(e.target.value)}
          value={link}
          ref={inputRef}
        />
        <Button
          className="ant-btn-prototype watch-later-btn"
          onClick={addItemToPlaylistHandler}
          disabled={disableBtn || addVideosLoading}
        >
          Submit
        </Button>
        {showLinkErr && (
          <ErrorFeedbackPrototype>Link is not valid</ErrorFeedbackPrototype>
        )}
      </div>
    </div>
  );
};

export default AddWatchLaterContent;
