//login
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";

// user logout

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";

// user email confirm code
export const USER_CONFIRM_EMAIL_REQUEST = "USER_CONFIRM_EMAIL_REQUEST";
export const USER_CONFIRM_EMAIL_SUCCESS = "USER_CONFIRM_EMAIL_SUCCESS";
export const USER_CONFIRM_EMAIL_FAIL = "USER_CONFIRM_EMAIL_FAIL";

//confirm verification code on email

export const USER_CONFIRM_RESET_PASSWORD_REQUEST =
  "USER_CONFIRM_RESET_PASSWORD_REQUEST";
export const USER_CONFIRM_RESET_PASSWORD_SUCCESS =
  "USER_CONFIRM_RESET_PASSWORD_SUCCESS";
export const USER_CONFIRM_RESET_PASSWORD_FAIL =
  "USER_CONFIRM_RESET_PASSWORD_FAIL";
export const USER_CONFIRM_RESET_PASSWORD_RESET = "USER_CONFIRM_RESET_PASSWORD_RESET"

//reset password

export const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAIL = "USER_RESET_PASSWORD_FAIL";
export const USER_CONFIRM_RESET_PASSWORD_CLEAR =
  "USER_CONFIRM_RESET_PASSWORD_CLEAR";

//register

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

//register only email
export const USER_REGISTER_ONLY_EMAIL_REQUEST =
  "USER_REGISTER_ONLY_EMAIL_REQUEST";
export const USER_REGISTER_ONLY_EMAIL_SUCCESS =
  "USER_REGISTER_ONLY_EMAIL_SUCCESS";
export const USER_REGISTER_ONLY_EMAIL_FAIL = "USER_REGISTER_ONLY_EMAIL_FAIL";
export const USER_REGISTER_ONLY_EMAIL_RESET = "USER_REGISTER_ONLY_EMAIL_RESET"

//register finalize
export const USER_REGISTER_FINALIZE_REQUEST = "USER_REGISTER_FINALIZE_REQUEST";
export const USER_REGISTER_FINALIZE_SUCCESS = "USER_REGISTER_FINALIZE_SUCCESS";
export const USER_REGISTER_FINALIZE_FAIL = "USER_REGISTER_FINALIZE_FAIL";

// confirmation code

export const USER_CONFIRM_CODE_REQUEST = "USER_CONFIRM_CODE_REQUEST";
export const USER_CONFIRM_CODE_SUCCESS = "USER_CONFIRM_CODE_SUCCESS";
export const USER_CONFIRM_CODE_FAIL = "USER_CONFIRM_CODE_FAIL";

// confirmation code resend

export const USER_CONFIRM_CODE_RESEND_REQUEST =
  "USER_CONFIRM_CODE_RESEND_REQUEST";
export const USER_CONFIRM_CODE_RESEND_SUCCESS =
  "USER_CONFIRM_CODE_RESEND_SUCCESS";
export const USER_CONFIRM_CODE_RESEND_FAIL = "USER_CONFIRM_CODE_RESEND_FAIL";
export const USER_REGISTER_FINALIZE_RESET = "USER_REGISTER_FINALIZE_RESET";

export const USER_REQUIRED_FIELDS_REQUEST = "USER_REQUIRED_FIELDS_REQUEST";
export const USER_REQUIRED_FIELDS_SUCCESS = "USER_REQUIRED_FIELDS_SUCCESS";
export const USER_REQUIRED_FIELDS_FAIL = "USER_REQUIRED_FIELDS_FAIL";

//verify email

export const USER_VERIFY_EMAIL_REQUEST = "USER_VERIFY_EMAIL_REQUEST";
export const USER_VERIFY_EMAIL_SUCCESS = "USER_VERIFY_EMAIL_SUCCESS";
export const USER_VERIFY_EMAIL_FAIL = "USER_VERIFY_EMAIL_FAIL";

export const FIND_USERES_EMAIL_REQUEST = "FIND_USERES_EMAIL_REQUEST";
export const FIND_USERES_EMAIL_SUCCESS = "FIND_USERES_EMAIL_SUCCESS";
export const FIND_USERES_EMAIL_FAIL = "FIND_USERES_EMAIL_FAIL";

