import React, { useState, useContext, useEffect } from "react";
import { Context } from "@context/context";

import { useDispatch, useSelector } from "react-redux";
import { register } from "@redux/actions/userActions";
import Form from "@shared/atoms/Form/index";
import Input from "@shared/atoms/Input/index";
import Button from "@shared/atoms/Button/index";
import Alert from "@shared/atoms/Alert/index";
import { formLayout } from "@modules/config";
import TermsField from "@shared/molecules/TermsField";
// import SocialLogin from "@components/organisms/SocialLogin";
import validateEmail from "@utils/validateEmail";

import RegisterPasswordForm from "../RegisterPasswordForm/mobile";
import TermsModal from "../../modals/TermsModal";

import "./index.less";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RegisterFormMobile = () => {
    const { t } = useTranslation();

    const [disableBtn, setDisableBtn] = useState(true);
    const [termsModalVisible, setTermsModalVisible] = useState(false);
    const [emailScreen, setEmailScreen] = useState(true);
    const [confirmPasswordScreen, setConfirmPasswordScreen] = useState(false);
    const [nickName, setNickName] = useState("");

    const dispatch = useDispatch();

    const {
        termsCheckboxState,
        userEmailHandler,
        userEmail,
        userPassword,
        setTermsCheckboxState,
        setAuthMobileHeading,
        setAuthMobileSubHeading,
    } = useContext(Context);
    let isValidEmail = validateEmail(userEmail);

    const userRegister = useSelector((state) => state.userRegister);
    const { userInfo, error } = userRegister;

    useEffect(() => {
        if (!termsCheckboxState || !isValidEmail || !nickName) {
            setDisableBtn(true);
        } else {
            setDisableBtn(false);
        }
    }, [termsCheckboxState, userEmail, nickName, isValidEmail]);

    useEffect(() => {
        setAuthMobileHeading("Welcome on");
        setAuthMobileSubHeading("Choice TV platform");
        return () => {
            setTermsCheckboxState(false);
        };
    }, [setAuthMobileHeading, setAuthMobileSubHeading, setTermsCheckboxState]);

    const openTermsModalHandler = () => {
        setTermsModalVisible(true);
    };

    const closeTermsModalHandler = () => {
        setTermsModalVisible(false);
    };


    const registerHandler = () => {
        dispatch(
            register({
                email: userEmail,
                password: userPassword,
                termAgreed: termsCheckboxState,
                nickname: nickName,
            })
        );
    };

    return (
        <>
            {emailScreen && (
                <Form layout={formLayout} className="register-form-mobile">
                    <TermsModal
                        title={`${t("terms_&_conditions")}`}
                        visible={termsModalVisible}
                        closeModal={closeTermsModalHandler}
                        additionalClasses="terms-modal-mobile"
                    />
                    <label className="registration-label-mobile">
                        {t("enter_your_email_and_nickname")}
                    </label>
                    <Form.Item>
                        <Input
                            onChange={(e) => {
                                userEmailHandler(e.target.value);
                            }}
                            placeholder={t("email")}
                        />
                    </Form.Item>
                    <span className="form-label">
                        {t("confirmation_link_will_be_sent_to_your_email")}
                    </span>
                    <Form.Item>
                        <Input
                            onChange={(e) => setNickName(e.target.value)}
                            placeholder={t("nickname")}
                            value={nickName}
                        />
                    </Form.Item>

                    <TermsField openModal={openTermsModalHandler} />

                    <div className="next-btn-container">
                        <Button
                            disabled={disableBtn}
                            onClick={() => {
                                setEmailScreen(false);
                                setConfirmPasswordScreen(true);
                            }}
                            type="primary"
                            htmlType="submit"
                        >
                            {t("next")}
                        </Button>
                    </div>
                    {/* <SocialLogin
                        additionalClasses="social-login-wrapper-mobile"
                        responseGoogle={responseGoogle}
                        responseFacebook={responseFacebook}
                    /> */}
                </Form>
            )}
            {confirmPasswordScreen && (
                <>
                    {userInfo && (
                        <Alert
                            message={[
                                `Registration is succesful! The verification link is sent to the mail "${userEmail}".  Please, confirm the mail to `,
                                <Link to="/login">{t("login")}</Link>,
                                " in the platform.",
                            ]}
                        />
                    )}
                    {error && error.data.errors.email && (
                        <Alert
                            message={error.data.errors.email[0]}
                            type="error"
                        />
                    )}
                    {error && error.data.errors.password && (
                        <Alert
                            message={error.data.errors.password[0]}
                            type="error"
                        />
                    )}
                    {error && error.data.errors.nickname && (
                        <Alert
                            message={error.data.errors.nickname[0]}
                            type="error"
                        />
                    )}{" "}
                    <RegisterPasswordForm registerHandler={registerHandler} />
                </>
            )}

            <div className="link-login-content-mobile">
                {t("already_have_an_account")}?{" "}
                <Link to="/login">{t("login")}</Link>{" "}
            </div>
        </>
    );
};

export default RegisterFormMobile;
