
/**
 * function that shortens strings
 *
 * @param {function} shortenString -function that shortens strings
 * @param {String} str - string that is going to shorten
 * @param {Number} maxLength - max character count in string
 * @example
 *
 * shortenString("some big big string", 10)
 */

export const shortenString = (str, maxLength) => {
   if (str) {
      return str?.length > maxLength ? `${str?.substring(0, maxLength)}...` : str;
   }

}
