import {
    SET_BALANCE_REQUST,
    SET_BALANCE_SUCCESS,
    SET_BALANCE_FAIL,
    TRANSACTIONS_REQUEST,
    TRANSACTIONS_SUCCESS,
    TRANSACTIONS_FAIL,
    SUBSCRIBTIONS_REQUEST,
    SUBSCRIBTIONS_SUCCESS,
    SUBSCRIBTIONS_FAIL,
    TIERS_REQUEST,
    TIERS_SUCCESS,
    TIERS_FAIL,
    SUBSCRIBE_TIER_REQUEST,
    SUBSCRIBE_TIER_SUCCESS,
    SUBSCRIBE_TIER_FAIL,
    SUBSCRIBE_TIER_CLEAR,
    UNSUBSCRIBE_TIER_REQUEST,
    UNSUBSCRIBE_TIER_SUCCESS,
    UNSUBSCRIBE_TIER_FAIL,
    TIER_DATE_PERIOD_REQUEST,
    TIER_DATE_PERIOD_SUCCESS,
    TIER_DATE_PERIOD_FAIL,
    COUPON_CODE_REQUEST,
    COUPON_CODE_SUCCESS,
    COUPON_CODE_FAIL,
    COUPON_CODE_RESET
} from "../constants/paymentConstants";

export const setBalanceReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_BALANCE_REQUST:
            return { loading: true };
        case SET_BALANCE_SUCCESS:
            return { loading: false, message: action.payload };
        case SET_BALANCE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const transactionsReducer = (state = {}, action) => {
    switch (action.type) {
        case TRANSACTIONS_REQUEST:
            return { loading: true };
        case TRANSACTIONS_SUCCESS:
            return { loading: false, message: action.payload };
        case TRANSACTIONS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const subscribtionsReducer = (state = {}, action) => {
    switch (action.type) {
        case SUBSCRIBTIONS_REQUEST:
            return { loading: true };
        case SUBSCRIBTIONS_SUCCESS:
            return { loading: false, message: action.payload };
        case SUBSCRIBTIONS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const tiersReducer = (state = {}, action) => {
    switch (action.type) {
        case TIERS_REQUEST:
            return { loading: true };
        case TIERS_SUCCESS:
            return { loading: false, data: action.payload };
        case TIERS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const tierSubscribeReducer = (state = {}, action) => {
    switch (action.type) {
        case SUBSCRIBE_TIER_REQUEST:
            return { loading: true };
        case SUBSCRIBE_TIER_SUCCESS:
            return { loading: false, message: action.payload };
        case SUBSCRIBE_TIER_FAIL:
            return { loading: false, error: action.payload };
        case SUBSCRIBE_TIER_CLEAR:
            return {};
        default:
            return state;
    }
};

export const tierUnsubscribeReducer = (state = {}, action) => {
    switch (action.type) {
        case UNSUBSCRIBE_TIER_REQUEST:
            return { loading: true }
        case UNSUBSCRIBE_TIER_SUCCESS:
            return { loading: false, message: action.payload }
        case UNSUBSCRIBE_TIER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const tierDatePeriodReducer = (state = {}, action) => {
    switch (action.type) {
        case TIER_DATE_PERIOD_REQUEST:
            return { loading: true };
        case TIER_DATE_PERIOD_SUCCESS:
            return { loading: false, message: action.payload };
        case TIER_DATE_PERIOD_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const couponCodeReducer = (state = {}, action) => {
    switch (action.type) {
        case COUPON_CODE_REQUEST:
            return { loading: true }
        case COUPON_CODE_SUCCESS:
            return { loading: false, data: action.payload }
        case COUPON_CODE_FAIL:
            return { loading: false, error: action.payload }
        case COUPON_CODE_RESET:
            return {}
        default:
            return state
    }
}
