import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import "./index.less"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PlaylistStatisticChart = ({ chartName = "Chart name", dataSetName = "Dataset name", dataSetData, lineColor = "#f08507", dotColor = "#FF9933", labels }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: chartName,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      }
    }

  };
  const data = {
    labels,
    datasets: [
      {
        label: dataSetName,
        data: dataSetData,
        borderColor: lineColor,
        backgroundColor: dotColor
      },
    ],

  };

  return (
    <div className='playlist-statistic-chart'>
      <Line options={options} data={data} />
    </div>
  )
}

export default PlaylistStatisticChart