export const LOCALSTORAGE = {
    GLOBAL_PLAYLIST_ID: "globe_playlist_id",
    HEART_PLAYLIST_ID: "heart_playlist_id",
    TIER_CHANNEL_ID: "tier_channel_id",
    TIER_SCROLL_POSISION: "tier_scroll_position",
    HIDE_COPY_IN_HEART_DIMENSION_MODAL: "hide_copy_in_heart_dimension_modal",
    ACCOUNT_MODE: "account_mode",
    TEAM_ID: "team_id",
    LAST_SEEN_CHANNEL_ID: "last_seen_channel_id",
    LAST_SEEN_CHANNEL_STATE: "last_seen_channel_state",
    ACCESS_TOKEN: "access_token",
}