const comparePasswords = (password, confirmPassword) => {
  if (password.length && confirmPassword.length) {
    if (password !== confirmPassword) {
      return false
    } else {
      return true
    }
  }
};

export default comparePasswords