import { useState, useEffect, useContext } from "react";
import { Context, formTypeAuthPrototype } from "../../../../../context/context";
import { login } from "../../../../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Input from "../../../shared/atoms/InputPrototype";
import Form from "@shared/atoms/Form/index";
import ErrorFeedbackPrototype from "@shared/atoms/ErrorFeedbackPrototype/index";
import { localStorageService } from "../../../../services/localStorage.service";
import Button from "../../../shared/atoms/Button";
import { useTranslation } from "react-i18next";

import { USER_LOGIN_RESET } from "@redux/constants/userConstants";

import "./index.less";
import { Paragraph } from "../../../shared/atoms/Typography";
import ForgotPasswordFormPrototype from "../ForgotPasswordFormPrototype";
import { debounce } from "lodash";
import { getUserProfile } from "@redux/actions/profileActions";
import { getPlaylists } from "@redux/actions/playlistsActions";
import { getCopiedTiers } from "@redux/actions/shortcutActions";

const LoginFormPrototype = () => {
  const { setModalTypeAuthPrototype } = useContext(Context);
  // Forgot password
  const [isForgotFormOpened, setIsForgotFormOpened] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);

  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const { loading, error, loggedIn } = useSelector((state) => state.userLogin);

  useEffect(() => {
    setModalTypeAuthPrototype(formTypeAuthPrototype.login);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: USER_LOGIN_RESET });
    };
  }, [dispatch]);

  useEffect(() => {
    if (localStorageService().get("access_token")) {
      history.push("/playlists");
    }
  }, [loggedIn, history]);

  useEffect(() => {
    if (loading) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [loading]);

  // Login logic here

  const delayedGetUserProfile = debounce(() => {
    dispatch(getUserProfile());
    dispatch(getPlaylists())
    dispatch(getCopiedTiers())
  }, 800);

  const handleSubmit = () => {
    Promise.resolve(dispatch(login(email, password))).then(() => {
      delayedGetUserProfile();
    });
    // closeModal();
  };

  useEffect(() => {
    if (loggedIn) {
      history.push("/");
    }
  }, [history, loggedIn]);

  if (isForgotFormOpened) {
    return (
      <ForgotPasswordFormPrototype
        returnToLogin={() => setIsForgotFormOpened(false)}
      />
    );
  }

  return (
    <>
      <Form className="ant-form-prototype">
        <Form.Item>
          <Input
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail"
            className="input-prototype"
          />
        </Form.Item>
        <Form.Item>
          <Input
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            type="password"
            className="input-prototype"
          />
        </Form.Item>
        {error && (
          <ErrorFeedbackPrototype>
            {error.data.message ? error.data.message : error.data.email}
          </ErrorFeedbackPrototype>
        )}
        <Form.Item>
          <Button
            disabled={disableBtn}
            onClick={handleSubmit}
            className="ant-btn-prototype"
          >
            {t("login")}
          </Button>
        </Form.Item>
      </Form>

      <Paragraph
        onClick={() => setModalTypeAuthPrototype(formTypeAuthPrototype.register)}
        className="paragraph-prototype"
      >
        {t("no_user_account_yet_register_here")}
      </Paragraph>

      <Paragraph
        onClick={() => setIsForgotFormOpened(true)}
        className="paragraph-prototype"
      >
        {t("forgot_password")}
      </Paragraph>

      <Paragraph className="paragraph-prototype">
        <a style={{ color: "#5da3ef" }} href="mailto:support@watchchoice.tv">
          {t("forgot_email")}
        </a>
      </Paragraph>
    </>
  );
};

export default LoginFormPrototype;
