import React from 'react'
import "./index.less"

const Dot = () => {
    return (
        <div className="dot">

        </div>
    )
}

export default Dot
