import React from "react";
import CookiesContent from "@components/organisms/CookiesContent";
import DesktopPrototype from "@components/organisms/DesktopPrototype";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

import "./index.less";

export const CookiesPolicy = () => {
    const history = useHistory();


    const goBackHandler = () => {
        history.push('/settings');
    }

    return (
        <>
            {isMobile ? (

                <CookiesContent goBackHandler={goBackHandler} />
            ) :
                <DesktopPrototype>
                    <CookiesContent goBackHandler={goBackHandler} />
                </DesktopPrototype>
            }
        </>

    );
};
