import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "@context/context";
import { useDispatch, useSelector } from "react-redux";
import {
    register,
    // confirmCode,
} from "@redux/actions/userActions";
import Button from "@shared/atoms/Button/index";
import Form from "@shared/atoms/Form/index";
import Alert from "@shared/atoms/Alert/index";
import comparePasswords from "@utils/comparePasswords";
import TermsField from "@shared/molecules/TermsField";
import TermsModal from "../../modals/TermsModal";
import validateEmail from "@utils/validateEmail";
import { formLayout } from "@modules/config";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

import Input from "antd/lib/input/index";

import "./index.less";

const RegisterForm = () => {
    // const [otpCode, setOtpCode] = useState("");
    const [disableBtn, setDisableBtn] = useState(false);
    const [email, setEmail] = useState("");
    const [nickname, setNickname] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [termsModalVisible, setTermsModalVisible] = useState(false);
    const [warning, setWarning] = useState({});
    const [isVerified, setIsVerified] = useState(false);

    const { t } = useTranslation();

    const {
        setTermsCheckboxState,
        termsCheckboxState,
        userEmailHandler,
        userEmail,
    } = useContext(Context);

    const dispatch = useDispatch();

    const userRegister = useSelector((state) => state.userRegister);
    const userConfirm = useSelector((state) => state.userConfirmCode);

    const { loading: loadingRegister, userInfo, error } = userRegister;
    const {
        loading: loadingConfirm,
        error: errorConfirm,
        // success: successConfirm,
    } = userConfirm;

    let isSamePasswords = comparePasswords(password, confirmPassword);
    let isValidEmail = validateEmail(email);

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        password,
        confirmPassword,
        email,
        loadingConfirm,
        loadingRegister,
        // otpCode,
        termsCheckboxState,
        isVerified,
    ]);
    useEffect(() => {
        return () => {
            setTermsCheckboxState(false);
        };
    }, [setTermsCheckboxState]);

    const emailChangeHandler = (e) => {
        setEmail(e.target.value);
        userEmailHandler(e.target.value);
        // if (email.includes("@")) {
        //     setUserName(email.split("@")[0]);
        // }
    };

    const warningsHandler = () => {
        let warningMessages = {
            email: "",
            password: "",
            terms: "",
            userName: "",
        };

        if (!isValidEmail && email) {
            warningMessages.email = "Email should be valid";
        } else {
            warningMessages.email = "";
        }

        if (!isSamePasswords && password.length && confirmPassword.length) {
            warningMessages.password = "Passwords don't match";
        } else {
            warningMessages.password = "";
        }
        if (
            !termsCheckboxState &&
            email &&
            password &&
            confirmPassword &&
            isSamePasswords
        ) {
            warningMessages.terms = "You have to agree terms and condition";
        } else {
            warningMessages.terms = "";
        }
        setWarning(warningMessages);
    };

    const validateFields = () => {
        warningsHandler();

        if (
            isSamePasswords &&
            isValidEmail &&
            isVerified &&
            termsCheckboxState
        ) {
            setDisableBtn(false);
            loadingRegister ? setDisableBtn(true) : setDisableBtn(false);
        } else {
            setDisableBtn(true);
        }
    };

    // const LiftUpStyle = {
    //     animation: `liftUp ${defaultDuration} ease-in`,
    // };

    // const showSlowStyle = {
    //     animation: `showSlow ${defaultDuration} ease-in`,
    // };

    // const handleChange = (otpCode) => {
    //     setOtpCode(otpCode);
    // };

    const handleSubmit = () => {
        dispatch(
            register({
                email,
                password,
                nickname,
                termsCheckboxState,
            })
        );
    };

    // const conifirmCodeHandler = () => {
    //     let registeredUserId = userInfo && userInfo.userId;
    //     dispatch(confirmCode(registeredUserId, otpCode));
    // };

    // const resendCodeHandler = () => {
    //     setResendTime(10);
    //     let countDownTimer = setInterval(function () {
    //         setResendTime((currentTime) => currentTime - 1);
    //     }, 1000);

    //     setTimeout(() => {
    //         clearInterval(countDownTimer);
    //     }, 10000);
    //     clearTimeout();
    //      dispatch(confirmCodeResend(userName));
    // };

    const closeTermsModalHandler = () => {
        setTermsModalVisible(false);
    };

    const openTermsModalHandler = () => {
        setTermsModalVisible(true);
    };

    const handleVerifyCaptcha = (value) => {
        setIsVerified(true);
    };

    return (
        <div className="auth-form register-form">
            {(warning.email ||
                warning.password ||
                warning.terms ||
                warning.userName) && (
                    <Alert
                        message={`${warning.email} ${warning.password} ${warning.terms} ${warning.userName}`}
                        closable={false}
                    />
                )}
            {error && error.data.errors.email && (
                <Alert message={error.data.errors.email[0]} type="error" />
            )}
            {error && error.data.errors.password && (
                <Alert message={error.data.errors.password[0]} type="error" />
            )}
            {error && error.data.errors.nickname && (
                <Alert message={error.data.errors.nickname[0]} type="error" />
            )}
            {userInfo && (
                <Alert
                    message={[
                        `Registration is succesful! The verification link is sent to the mail "${userEmail}".  Please, confirm the mail to `,
                        <Link to="/login">{t("login")}</Link>,
                        " in the platform.",

                    ]}
                    type="success"
                />
            )}
            <TermsModal
                visible={termsModalVisible}
                closeModal={closeTermsModalHandler}
            />
            <>
                {errorConfirm && (
                    <Alert message="Confirmation failed" type="error" />
                )}
                <Form layout={formLayout}>
                    <Form.Item label={t("email")} className="test" required>
                        <Input
                            onChange={emailChangeHandler}
                            placeholder={t("enter_your_email")}
                        />
                    </Form.Item>
                    {/* <Form.Item label={t("username")}>
                        <Input
                            onChange={(e) => setUserName(e.target.value)}
                            placeholder={t("enter_your_username")}
                            value={userName}
                        />
                    </Form.Item> */}
                    <Form.Item label={t("nickname")} required>
                        <Input
                            onChange={(e) => {
                                setNickname(e.target.value);
                            }}
                            placeholder={t("enter_nickname")}
                        />
                    </Form.Item>
                    <Form.Item label={t("password")} required>
                        <Input.Password
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            placeholder={t("enter_password")}
                            autoComplete="new-password"
                        />
                    </Form.Item>
                    <Form.Item label={t("confirm_password")} required>
                        <Input.Password
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                            }}
                            placeholder={t("confirm_password")}
                            autoComplete="new-password"
                        />
                    </Form.Item>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        onChange={handleVerifyCaptcha}
                        className="re-captcha"
                    />
                    <TermsField openModal={openTermsModalHandler} />
                    <div className="register-btn-container">
                        <Button
                            disabled={disableBtn}
                            onClick={handleSubmit}
                            type="primary"
                            htmlType="submit"
                        >
                            {t("register")}
                        </Button>
                    </div>
                </Form>
            </>
            {/* {userInfo && (
                <>
                    {errorConfirm && (
                        <Alert
                            message="Confirmation code is incorrect"
                            type="error"
                        />
                    )}
                    {errorResendCode && (
                        <Alert
                            message="Confirmation code resend failed"
                            type="error"
                        />
                    )}
                    {successConfirm && (
                        <Alert
                            message={"Registration is successfull"}
                            type="success"
                        />
                    )}
                    <div className="otp-container" style={showSlowStyle}>
                        <OtpInput
                            value={otpCode}
                            onChange={handleChange}
                            numInputs={4}
                            separator={
                                <span style={{ visibility: "hidden" }}>-</span>
                            }
                            containerStyle="otp-container-style"
                            inputStyle="otp-style"
                            isInputSecure={true}
                            shouldAutoFocus={true}
                        />
                        <div style={LiftUpStyle} className="done-btn-container">
                            <Button
                                disabled={disableDoneBtn}
                                onClick={conifirmCodeHandler}
                                type="primary"
                            >
                                Done
                            </Button>
                        </div>
                        <Paragraph className="resend-text">
                            Didn’t receive a code?{" "}
                            <span
                                onClick={resendCodeHandler}
                                className={`resend-code  ${
                                    resendTime < 10 && resendTime !== 0
                                        ? "disable-resend-code"
                                        : ""
                                }`}
                            >
                                Resend
                            </span>{" "}
                            in {resendTime} s
                        </Paragraph>
                    </div>
                </>
            )} */}
        </div>
    );
};

export default RegisterForm;
