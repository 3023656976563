import { CHOICE_URL } from "@modules/config";
import { axiosInstance } from "@modules/request/api";

import {
  STATISTICS_REQUEST,
  STATISTICS_SUCCESS,
  STATISTICS_FAIL,
  BROADCAST_STATISTICS_REQUEST,
  BROADCAST_STATISTICS_SUCCESS,
  BROADCAST_STATISTICS_FAIL,
  PLAYLIST_STATISTICS_REQUEST,
  PLAYLIST_STATISTICS_SUCCESS,
  PLAYLIST_STATISTICS_FAIL,
  EXPORT_PLAYLIST_STATISTICS_REQUEST,
  EXPORT_PLAYLIST_STATISTICS_SUCCESS,
  EXPORT_PLAYLIST_STATISTICS_FAIL,
} from "../constants/statisticsConstants";

export const getStatistics = () => async (dispatch) => {
  try {
    dispatch({ type: STATISTICS_REQUEST });
    const { data } = await axiosInstance().get(
      `${CHOICE_URL}/api/statistics/playlists`
    );
    dispatch({ type: STATISTICS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: STATISTICS_FAIL, payload: error.response });
  }
};

export const getBroadcastStatistics = (dateTo) => async (dispatch) => {
  try {
    dispatch({ type: BROADCAST_STATISTICS_REQUEST });
    const { data } = await axiosInstance().get(
      `${CHOICE_URL}/api/broadcaster?date_to=${dateTo}`
    );
    dispatch({ type: BROADCAST_STATISTICS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BROADCAST_STATISTICS_FAIL, payload: error.response });
  }
};


export const getPlaylistStatistics = (playlistId, startDate, endDate, type) => async (dispatch) => {
  try {
    dispatch({ type: PLAYLIST_STATISTICS_REQUEST })
    const { data } = await axiosInstance().get(
      `${CHOICE_URL}/api/statistics/playlists/${playlistId}?start_date=${startDate}&end_date=${endDate}&type=${type}`)
    dispatch({ type: PLAYLIST_STATISTICS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: PLAYLIST_STATISTICS_FAIL, payload: error.response })
  }
}

export const exportPlaylistStatistics = (playlistId, startDate, endDate, type) => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_PLAYLIST_STATISTICS_REQUEST })
    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/statistics/playlists/${playlistId}/export?start_date=${startDate}&end_date=${endDate}&type=${type}`)
    dispatch({ type: EXPORT_PLAYLIST_STATISTICS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: EXPORT_PLAYLIST_STATISTICS_FAIL, payload: error.response })

  }
}