import { useContext, useEffect, useState } from "react";
import { scrollTopHeight } from "@modules/config";
import throttle from "lodash/throttle";
import scrollIcon from "@assets/icons/scroll-up.svg";
import useDetectMobile from "@src/hooks/useDetectMobile";
import { Context } from "@context/context";
import useScrollToTop from "hooks/useScrollToTop";

import "./index.less";

const ScrollTop = () => {
  const [visible, setVisible] = useState(false);
  const isMobile = useDetectMobile();
  const { tabletFrameRef } = useContext(Context);

  const scrollToTop = useScrollToTop();

  useEffect(() => {
    let element;
    if (!isMobile) {
      element = tabletFrameRef.current;
    } else {
      element = window;
    }

    const toggleVisible = throttle(() => {
      if ((element.scrollY || element.scrollTop) > scrollTopHeight) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }, 250);

    element?.addEventListener("scroll", toggleVisible);
    return () => element?.removeEventListener("scroll", toggleVisible);
  }, [isMobile, tabletFrameRef]);

  return visible ? (
    <div className="scroll-top" onClick={scrollToTop}>
      <img src={scrollIcon} alt="" />
    </div>
  ) : null;
};

export default ScrollTop;
