import React, { useState, useContext } from "react";
import Modal from "@shared/atoms/Modal/index";
import Button from "@shared/atoms/Button";
import { useTranslation } from "react-i18next";
import { Context } from "@context/context";
import { Paragraph } from "@components/shared/atoms/Typography";
import Checkbox from "@components/shared/atoms/Checkbox";
import { localStorageService } from "@modules/services/localStorage.service";

import "./index.less";

const AddToPlaylistConfirmModalPrototype = () => {
  const { t } = useTranslation();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const {
    setShowAddToPlaylistConfirmModal,
    showAddToPlaylistConfirmModal,
    copyingItem,
    currentVideoPrototype,
  } = useContext(Context);

  const closeModal = () => {
    setShowAddToPlaylistConfirmModal(false);
  };

  const checkboxChangeHandler = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const onOkHandler = () => {
    copyingItem(currentVideoPrototype);

    if (isCheckboxChecked) {
      localStorageService().set("hide_add_to_playlist_modal", true);
    }

    closeModal();
  };
  return (
    <Modal
      className="add-to-playlist-confirm-modal modal-basic-prototype"
      visible={showAddToPlaylistConfirmModal}
      closable={true}
      onCancel={closeModal}
      footer={[
        <>
          <Button
            key="update"
            type="primary"
            onClick={onOkHandler}
            disabled={false}
          >
            {t("ok")}
          </Button>
        </>,
      ]}
    >
      <Paragraph className="text">
        {t("please_select_one_of_your_existing_channels_bellow")}
      </Paragraph>
      <div className="checkbox-text-container">
        <Checkbox onChange={checkboxChangeHandler}>
          {" "}
          Don’t show me anymore
        </Checkbox>
      </div>
    </Modal>
  );
};

export default AddToPlaylistConfirmModalPrototype;
