import Modal from "@shared/atoms/Modal/index";
import { useTranslation } from "react-i18next";
import { Paragraph } from "@shared/atoms/Typography/index";
import Image from "@shared/atoms/Image/index";
import warningIcon from "@assets/icons/warning.svg";

import "./index.less";

const InvalidCountryModalPrototype = ({ isVisible, closeHandler }) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={isVisible}
      className="modal-basic-prototype alert-modal-prototype"
      mask={true}
      footer={<></>}
      closable={true}
      onCancel={() => {
        closeHandler();
      }}
    >
      <div>
        <Image className="warning-img" src={warningIcon} alt="warning" />
        <Paragraph>{t("channel_not_available_in_your_country")}</Paragraph>
      </div>
    </Modal>
  );
};

export default InvalidCountryModalPrototype;
