import { DatePicker, InputNumber } from "antd";
import AntdInput from "antd/lib/input/index";
import Password from "antd/lib/input/Password";

const Input = AntdInput;
Input.Password = Password;
Input.Number = InputNumber;
Input.DatePicker = DatePicker;
export const { TextArea } = Input;
export default Input;
