import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Alert from "@shared/atoms/Alert";
import { Context } from "@context/context";
import Button from "@shared/atoms/Button/index";
import Modal from "@shared/atoms/Modal/index";

import OtpInput from "react-otp-input";
// import { Paragraph } from "@shared/atoms/Typography";

import "./index.less";

const ConfirmAdultContentModal = ({
    visible,
    closeHandler,
    errorText,
    cancelConfirmModalHandler,
    showAdultContentErrorText,
}) => {
    const [disableBtn, setDisableBtn] = useState(true);

    const { t } = useTranslation();

    const { confirmAdultContentOtpCode, setConfirmAdultContentOtpCode } =
        useContext(Context);

    useEffect(() => {
        if (confirmAdultContentOtpCode.length !== 4) {
            setDisableBtn(true);
        } else {
            setDisableBtn(false);
        }
    }, [confirmAdultContentOtpCode]);

    const handleChangeOtpCode = (code) => {
        setConfirmAdultContentOtpCode(code);
    };

    return (
        <Modal
            className="confirm-adult-content-modal"
            title={t("adult_content_verification_code")}
            closable={true}
            onCancel={cancelConfirmModalHandler}
            visible={visible}
            footer={[
                <Button
                    disabled={disableBtn}
                    type="primary"
                    key="back"
                    onClick={closeHandler}
                    htmlType="submit"
                >
                    {t("submit")}
                </Button>,
            ]}
        >
            {errorText && showAdultContentErrorText && (
                <Alert message={errorText} type="error" />
            )}
            {/* <Paragraph>
                You can set parental controls by entering the authenticate PIN
                sent to your email address to restrict video and TV based on
                their rating
            </Paragraph> */}
            <div className="otp-container">
                <OtpInput
                    value={confirmAdultContentOtpCode}
                    onChange={handleChangeOtpCode}
                    numInputs={4}
                    // separator={<span style={{ visibility: "hidden" }}>-</span>}
                    containerStyle="otp-container-style"
                    inputStyle="otp-style"
                    isInputSecure={true}
                    shouldAutoFocus={true}
                />
            </div>
        </Modal>
    );
};

export default ConfirmAdultContentModal;
