import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import MainLayout from "../../layout/MainLayout";
import { verifyEmail } from "../../../redux/actions/userActions";
import { Context } from "@context/context";
import SettingsPrototype from "../../components/organisms/SettingsPrototype";
import querystring from "query-string";
import DesktopPrototype from "@components/organisms/DesktopPrototype";
import { isMobile } from "react-device-detect";
import { getHomeUserContents } from '@redux/actions/homeContentActions';

import "./index.less";
import { getTeamsAction } from "@redux/actions/TeamActions";

export const Settings = () => {
  const { setShowPayment, setShowSubBarPrototype } = useContext(Context);

  const { search } = useLocation();
  const parsedQueryString = querystring.parse(search);

  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomeUserContents());
    dispatch(getTeamsAction());
  }, [dispatch]);

  useEffect(() => {
    if (parsedQueryString.view === "wallet") {
      setShowPayment(true);
    }
  }, [setShowPayment, parsedQueryString]);

  useEffect(() => {
    if (isMobile && location.search) {
      const [type, url] = location.search.split("=");
      const protocol = "https";
      const urlWithoutProtocol = decodeURIComponent(url).split(":")[1];

      const urlWithProtocol = protocol + ":" + urlWithoutProtocol;
      if (type === "?verify_url") {
        dispatch(verifyEmail(urlWithProtocol));
      }
    }
  }, [location, dispatch]);


  return (
    <>
      {!isMobile ? (
        <DesktopPrototype>
          <SettingsPrototype />
        </DesktopPrototype>
      ) : (
        <MainLayout>
          <SettingsPrototype />
        </MainLayout>
      )}
    </>
  );
};
