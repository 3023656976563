import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";

/*
 * props:
 *   droppableId: string - used by react-beautiful-dnd to identify droppable area
 *    propertySelector: function predicate - if present and returns true => render item else render null
 *   listItems: Array<item{}> - array of items to be rendered by the render prop
 *       item: {id: number | string} - item must have an id property
 *   render: (item, ...draggable_props) => React Component - used to render listItems one by one,
 *   droppableChildren: {droppableId} - if specified you are telling: render items which is draggable as well as droppable
 *       onDraggingOverStyle: CSS style object
 *
 * return:
 *   Droppable component consists of Draggable(?:Droppable) items
 * */
function DraggableList({
  droppableId,
  listItems,
  render,
  children,
  propertySelector = null,
  droppableChildren = null,
  style = {},
  ...rest
}) {
  if (!listItems) return null;

  if (!droppableId) throw new Error("droppableId prop not found");

  if (
    droppableChildren &&
    (!(typeof droppableChildren === "object") ||
      !droppableChildren.hasOwnProperty("droppableId"))
  ) {
    throw new Error(
      "droppableId is required when using droppableChildren prop"
    );
  }

  const _render = getCorrectRender(render, children);

  const renderItem = (item, ...args) => {
    const { droppableId, onDraggingOverStyle, ...rest } = droppableChildren;
    return (
      <Droppable droppableId={`${droppableId}:${item.id}`} {...rest}>
        {(prvd, snapshot) => {
          return (
            <div
              ref={prvd.innerRef}
              {...prvd.droppableProps}
              style={snapshot.isDraggingOver ? onDraggingOverStyle : {}}
            >
              {_render(item, ...args)}
              {prvd.placeholder}
            </div>
          );
        }}
      </Droppable>
    );
  };

  return (
    <Droppable droppableId={droppableId} {...rest}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} style={style}>
          {listItems.map((item, index) => {
            const draggableItem = (
              <Draggable
                draggableId={String(item.id)}
                key={`${index}:${item.id}`}
                index={index}
              >
                {(...args) =>
                  droppableChildren
                    ? renderItem(item, ...args)
                    : _render(item, ...args)
                }
              </Draggable>
            );
            if (propertySelector) {
              return propertySelector(item) ? draggableItem : null;
            }
            return draggableItem;
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

function getCorrectRender(render, children) {
  let _render;
  if (!render && !children)
    throw new Error("provide render or children function");
  if (render && children)
    throw new Error("Provide render or children not both");

  if (render && typeof render === "function" && !children) _render = render;
  else if (children && typeof children === "function" && !render)
    _render = children;
  else throw new Error("Invalid render or children prop");

  return _render;
}

export default DraggableList;
