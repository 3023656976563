import React, { useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import arrowIcon from "@assets/icons/down-arrow.svg"
import Image from '@components/shared/atoms/Image'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import "./index.less"

const WEEK_IN_MILLISECONDS = 604800000

const PlaylistStatisticCalendar = ({ setCalendarStartDate, setCalendarEndDate }) => {
  const { t } = useTranslation()
  const [showCalendar, setShowCalendar] = useState(false)
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date() - WEEK_IN_MILLISECONDS,
      key: 'selection'
    }
  ]);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar)
  }
  const dateChangeHandler = item => {
    setDateRange([item.selection])
    setCalendarStartDate(moment(item.selection.startDate).format("YYYY-MM-DD"))
    setCalendarEndDate(moment(item.selection.endDate).format("YYYY-MM-DD"))
  }

  return (
    <>
      <div onClick={toggleCalendar} className='date-range-label-container'>
        <label className='playlist-stats-calendar-label'>{t("select_date_range_for_statistics")} </label>
        <Image className={`arrow-img ${showCalendar ? 'rotate' : ""}`} src={arrowIcon} alt="arrow" />
      </div>
      {showCalendar &&
        <div className='playlist-stats-calendar-container'>
          <DateRangePicker
            // staticRanges={[]}
            // inputRanges={[]}
            onChange={dateChangeHandler}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={dateRange}
            direction="vertical"
            maxDate={new Date()}
          />
        </div>}
    </>

  )
}

export default PlaylistStatisticCalendar