import {
    VIEW_REQUEST,
    VIEW_SUCCESS,
    VIEW_FAIL,
    CLICK_VIEW_REQUEST,
    CLICK_VIEW_SUCCESS,
    CLICK_VIEW_FAIL,
    VIEW_START_REQUEST,
    VIEW_START_SUCCESS,
    VIEW_START_FAIL,
    VIEW_END_REQUEST,
    VIEW_END_SUCCESS,
    VIEW_END_FAIL,
    VIEW_LIST_REQUEST,
    VIEW_LIST_SUCCESS,
    VIEW_LIST_FAIL,
} from "../constants/viewConstants";

export const viewReducer = (state = {}, action) => {
    switch (action.type) {
        case VIEW_REQUEST:
            return { loading: true };
        case VIEW_SUCCESS:
            return { loading: false, message: action.payload };
        case VIEW_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const clickViewReducer = (state = {}, action) => {
    switch (action.type) {
        case CLICK_VIEW_REQUEST:
            return { loading: true };
        case CLICK_VIEW_SUCCESS:
            return { loading: false, message: action.payload };
        case CLICK_VIEW_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};


export const viewStartReducer = (state = {}, action) => {
    switch (action.type) {
        case VIEW_START_REQUEST:
            return { loading: true };
        case VIEW_START_SUCCESS:
            return { loading: false, message: action.payload };
        case VIEW_START_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const viewEndReducer = (state = {}, action) => {
    switch (action.type) {
        case VIEW_END_REQUEST:
            return { loading: true };
        case VIEW_END_SUCCESS:
            return { loading: false, message: action.payload };
        case VIEW_END_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};


export const viewListReducer = (state = {}, action) => {
    switch (action.type) {
        case VIEW_LIST_REQUEST:
            return { loading: true }
        case VIEW_LIST_SUCCESS:
            return { loading: false, data: action.payload }
        case VIEW_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}