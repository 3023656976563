import React, { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import querystring from "query-string";
import { Context } from "../../../../context/context";
import { useAccountMode } from "hooks";
import NavLink from "@shared/atoms/NavLink/index";
import List from "@shared/atoms/List/index";
import NavItem from "@shared/molecules/listItems/NavItem/index";
import Menu from "@shared/atoms/Menu";
import Image from "@shared/atoms/Image";
import SubBarPrototype from "../../shared/atoms/SubBarPrototype";
import tvImage from "@assets/icons/tv-icon-new.svg";
import playlistImage from "@assets/icons/my_playlists_new.svg";
import globeImage from "@assets/icons/globe-icon-new.svg";

import ChannelListPrototype from "../lists/ChannelListPrototype";
import PlaylistListPrototype from "../lists/PlaylistListPrototype";
import { localStorageService } from "@modules/services/localStorage.service";
import { VIEWS_PROTOTYPE } from "@src/const/viewsPrototype";
import AlertModalPrototype from "@components/organisms/modals/AlertModalPrototype";
import TierListPrototype from "../lists/TierListPrototype";
import { PLAYLIST_BY_ID_CLEAR } from "@redux/constants/playlistsConstants";
import UserIconFilledPrototype from "@components/shared/atoms/icons/UserIconPrototype";
import UsersIconFillPrototype from "@components/shared/atoms/icons/UsersIconFillPrototype";

import SearchModalPrototype from "../modals/SearchModalPrototype";
import SearchIconPrototype from "@components/shared/atoms/icons/SearchIconPrototype";
// import StatisticsIconPrototype from "@components/shared/atoms/icons/StatisticsPrototype";
import SearchSubbarContainer from "../SearchSubbarContainer";
// import HomePositionPrototype from "@components/shared/molecules/HomePositionPrototype";
import ProfileSubbarPrototype from "../lists/ProfileSubbarPrototype";
import CombinePlaylistModal from "../modals/CombinePlaylistsModal";
import CreateTeamModal from "../modals/CreateTeamModalPrototype";
import { PAGES } from "const/pages";
import usePathName from "hooks/usePathName";
import {
  SEARCH_AUTHORIZED_RESET,
  SEARCH_UNAUTHORIZED_RESET,
} from "@redux/constants/searchConstants";

import DeleteGroupModalPrototype from "../modals/DeleteGroupModalPrototype";
import CreateGroupModalPrototype from "../modals/CreateGroupModalPrototype";
import EditGroupNameModalPrototype from "../modals/EditGroupNameModalPrototype";
import CopyInHeartModal from "../modals/CopyInHeartModal";

import "./index.less";
import SimplePopup from "../modals/SimpePopup";
import EditTeamModalPrototype from "../modals/EditTeamModalPrototype";
import {
  getTeamsAction,
  approveTeamInvitation,
  approveDeleteFromAdmin,
  deleteTeam,
} from "@redux/actions/TeamActions";
import { useHistory } from "react-router-dom";
import { getUserProfile } from "@redux/actions/profileActions";
import { LOCALSTORAGE } from "const";

const MobileNavPrototype = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const isLoggedIn = !!localStorageService().get("access_token");
  const heartPlaylistId = localStorageService().get("heart_playlist_id");
  const lastSeenChannelId = localStorageService().get(LOCALSTORAGE.LAST_SEEN_CHANNEL_ID);
  const globePlaylistId = localStorageService().get("globe_playlist_id");
  const teamId = localStorageService().get("team_id");
  const deleteTeamId = localStorageService().get("teamId");
  const userId = localStorageService().get("userId");
  const adminName = localStorageService().get("adminName");
  const teamName = localStorageService().get("teamName");

  const { profile } = useSelector((state) => state.userProfile);

  const inviterTeamInfo = profile?.data?.membership[0];

  const {
    showSubBarPrototype,
    setShowSubBarPrototype,
    setSubBarAnimate,
    setCurrentViewPrototype,
    currentViewPrototype,
    setIsArrangeMode,
    setShowHomeContent,
    setShowTermsImagesPrototype,
    setTierListOpened,
    setShowSearchModalPrototype,
    setHeaderPosition,
    setHeaderDragStarted,
    showCombinePlaylistModal,
    disableCombine,
    setShowChannelsPrototype,
    setSearchedWordPrototype,
    closeEditModeIfOpened,
    showCreateTeamModal,
    setShowCreateTeamModal,
    showEditTeamsModal,
    setShowEditTeamsModal,
  } = useContext(Context);

  const currentPage = usePathName();
  const dispatch = useDispatch();
  const { isOwnMode, isTeamMode } = useAccountMode();
  const history = useHistory();

  const subBarTypes = {
    myPlaylists: "My Playlists",
    playlists: "Playlists",
    tiers: "Tiers",
  };

  const subBarToogleHandler = (view, page) => {
    if (showSubBarPrototype === false && currentPage !== page) {
      setShowSubBarPrototype(true);
    }
    if (currentPage === page) {
      setShowSubBarPrototype(!showSubBarPrototype);
    }
    setSubBarAnimate(true);

    switch (view) {
      case VIEWS_PROTOTYPE.channels:
        setCurrentViewPrototype(VIEWS_PROTOTYPE.channels);
        setIsArrangeMode(false);
        if (currentPage !== PAGES.playlists)
          dispatch({ type: PLAYLIST_BY_ID_CLEAR });
        break;
      case VIEWS_PROTOTYPE.playlists:
        setCurrentViewPrototype(VIEWS_PROTOTYPE.playlists);
        if (currentPage !== PAGES.myPlaylists)
          dispatch({ type: PLAYLIST_BY_ID_CLEAR });
        break;
      case VIEWS_PROTOTYPE.tiers:
        setCurrentViewPrototype(VIEWS_PROTOTYPE.tiers);
        setIsArrangeMode(false);
        break;
      case VIEWS_PROTOTYPE.search:
        setCurrentViewPrototype(VIEWS_PROTOTYPE.search);
        break;
      case VIEWS_PROTOTYPE.settings:
        setCurrentViewPrototype(VIEWS_PROTOTYPE.settings);
        break;
      default:
        setCurrentViewPrototype("");
    }
  };
  const subBarHideHandler = () => {
    setShowSubBarPrototype(false);
    // setShowTermsImagesPrototype(true);
    // setCurrentViewPrototype("");
    //setShowPlaylists(false);
  };

  const requireAuth = (myPlaylists, fn) => (e) => {
    if (myPlaylists) {
      setShowTermsImagesPrototype(true);
      setCurrentViewPrototype("");
    }

    let currentUser = localStorageService().get("access_token");
    if (currentUser) return fn();
    e.preventDefault();
    // Show warning alert

    setShowHomeContent(true);
    setIsPopupOpen(true);

    // Hide subbar if opened
    showSubBarPrototype && setShowSubBarPrototype(false);
  };

  const openModalHandler = () => {
    setShowSearchModalPrototype(true);
  };

  const myPlaylistsClick = () => {
    subBarToogleHandler(VIEWS_PROTOTYPE.playlists, "my-playlists");
    setTierListOpened([]);
    setHeaderPosition(0);
    setHeaderDragStarted(false);
  };

  const searchIconClick = () => {
    cleanSearch();
    openModalHandler();
    setShowSubBarPrototype(true);
    // requireAuth(null);
    // subBarHideHandler();

    // setTierListOpened([]);
    setCurrentViewPrototype(VIEWS_PROTOTYPE.search);
    if (isLoggedIn) {
      // subBarToogleHandler(VIEWS_PROTOTYPE.playlists, "search");
    }
    closeEditModeIfOpened();
  };

  const settingsIconClick = () => {
    subBarHideHandler();
    setTierListOpened([]);
    // subBarToogleHandler(VIEWS_PROTOTYPE.settings, "settings");
    if (localStorageService().get("access_token")) {
      subBarToogleHandler(VIEWS_PROTOTYPE.playlists, "settings");
    }
    setCurrentViewPrototype(VIEWS_PROTOTYPE.settings);
    closeEditModeIfOpened();
  };

  const handleTirtLink = () => {
    let URL = ""
    if(!lastSeenChannelId) {
      URL = "/tiers";
    } else {
      URL = `/tiers?channel=${lastSeenChannelId}`;
    }
    
    return URL;
  }

  const cleanSearch = () => {
    dispatch({ type: SEARCH_UNAUTHORIZED_RESET });
    dispatch({ type: SEARCH_AUTHORIZED_RESET });
    setSearchedWordPrototype("");
  };

  const deleteAdminMessage = `User ${adminName} wants to take away your admin role in team ${teamName}`;
  const invitePopupMessage = `You are invited in a team ${inviterTeamInfo?.name}. Do you want to join?`;

  const acceptHandler = () => {
    dispatch(approveTeamInvitation(inviterTeamInfo?.id, false)).then(() => {
      dispatch(getUserProfile());
      dispatch(getTeamsAction());
    });
  };

  const cancelHandler = () => {
    dispatch(approveTeamInvitation(inviterTeamInfo?.id, true)).then(() => {
      dispatch(getUserProfile());
    });
  };

  const clearStorage = () => {
    localStorageService().remove("teamId");
    localStorageService().remove("userId");
    localStorageService().remove("teamName");
    localStorageService().remove("adminName");
    history.push("/");
  };

  const isUserDeletedFromTeam =
    localStorageService().get("teamId") &&
    localStorageService().get("userId") &&
    localStorageService().get("teamName") &&
    localStorageService().get("adminName") &&
    isLoggedIn;

  return (
    <>
      <AlertModalPrototype
        isVisible={isPopupOpen}
        closeHandler={() => setIsPopupOpen(false)}
      />
      <CombinePlaylistModal
        isVisible={showCombinePlaylistModal}
        closeHandler={disableCombine}
      />
      <EditTeamModalPrototype
        visible={showEditTeamsModal}
        onCancel={() => setShowEditTeamsModal(false)}
        teamId={teamId}
      />
      <CreateTeamModal
        visible={showCreateTeamModal}
        onCancel={() => setShowCreateTeamModal(false)}
      />
      <CopyInHeartModal />
      <DeleteGroupModalPrototype />
      <CreateGroupModalPrototype />
      <EditGroupNameModalPrototype />

      <SimplePopup
        onAccept={acceptHandler}
        onCancel={cancelHandler}
        text={invitePopupMessage}
        isVisible={inviterTeamInfo?.id}
      />

      <SimplePopup
        text={deleteAdminMessage}
        onAccept={() => {
          dispatch(approveDeleteFromAdmin(deleteTeamId, userId, true)).then(
            () => {
              clearStorage();
            }
          );
        }}
        onCancel={() => {
          dispatch(approveDeleteFromAdmin(deleteTeamId, userId, false)).then(
            () => {
              clearStorage();
            }
          );
        }}
        isVisible={isUserDeletedFromTeam}
      />

      <SubBarPrototype>
        {VIEWS_PROTOTYPE.channels === currentViewPrototype && (
          <ChannelListPrototype />
        )}
        {VIEWS_PROTOTYPE.playlists === currentViewPrototype && (
          <PlaylistListPrototype />
        )}
        {VIEWS_PROTOTYPE.tiers === currentViewPrototype && (
          <TierListPrototype />
        )}
        {VIEWS_PROTOTYPE.search === currentViewPrototype && (
          <SearchSubbarContainer />
        )}
        {VIEWS_PROTOTYPE.settings === currentViewPrototype && (
          <ProfileSubbarPrototype />
        )}
      </SubBarPrototype>
      <SearchModalPrototype isMyPlaylistSearch={true} />
      <Menu className="mobile-navigation-prototype">
        <div className={`mobile-navigation-wrapper ${isTeamMode && "team-wr"}`}>
          {isOwnMode && (
            <NavLink
              to={handleTirtLink()}
              activeClassName="active-nav-item-prototype"
              onClick={() => {
                subBarToogleHandler(VIEWS_PROTOTYPE.tiers, "tiers");
                if(lastSeenChannelId) {
                  setShowTermsImagesPrototype(false);
                  setShowChannelsPrototype(true);
                } else {
                  setShowTermsImagesPrototype(true);
                  setShowChannelsPrototype(false);
                }
                closeEditModeIfOpened();
              }}
            >
              <List.Item>
                <NavItem className="nav-item-prototype">
                  <Image src={tvImage} alt="Tv" className="my-playlist-icon" />
                </NavItem>
              </List.Item>
            </NavLink>
          )}
          {isOwnMode && (
            <NavLink
              to={
                globePlaylistId
                  ? `/playlists?playlist=${globePlaylistId}`
                  : "/playlists"
              }
              activeClassName="active-nav-item-prototype"
              onClick={() => {
                setShowHomeContent(true);
                setShowTermsImagesPrototype(true);
                setCurrentViewPrototype("");
                subBarToogleHandler(VIEWS_PROTOTYPE.channels, "playlists");
                setTierListOpened([]);
                closeEditModeIfOpened();
              }}
            >
              <List.Item>
                <NavItem className="nav-item-prototype">
                  {/* <MenuTv /> */}
                  <Image
                    src={globeImage}
                    alt="Playlists"
                    className="my-playlist-icon"
                  />
                </NavItem>
              </List.Item>
            </NavLink>
          )}

          <NavLink
            to={
              heartPlaylistId
                ? `/my-playlists?playlist=${heartPlaylistId}`
                : "/my-playlists"
            }
            activeClassName="active-nav-item-prototype"
            onClick={requireAuth(subBarTypes.myPlaylists, () => {
              myPlaylistsClick();
              subBarToogleHandler(VIEWS_PROTOTYPE.playlists, "my-playlists");
              closeEditModeIfOpened();
            })}
          >
            <List.Item>
              <NavItem className="nav-item-prototype">
                {/* <MenuTvBlocked /> */}
                <Image
                  src={playlistImage}
                  alt="my playlists"
                  className="my-playlist-icon"
                />
              </NavItem>
            </List.Item>
          </NavLink>
          {isOwnMode && (
            <NavLink
              to={"/search"}
              activeClassName="active-nav-item-prototype"
              onClick={searchIconClick}
            >
              <List.Item>
                <NavItem className="nav-item-prototype item-padd">
                  <SearchIconPrototype />
                </NavItem>
              </List.Item>
            </NavLink>
          )}

          <NavLink to={"/settings"} activeClassName="active-nav-item-prototype">
            <List.Item>
              <NavItem
                className="nav-item-prototype item-padd"
                onClick={settingsIconClick}
              >
                {/* <TwoUsers color="#a5b1c6" /> */}
                {localStorageService().get("access_token") ? (
                  <UsersIconFillPrototype />
                ) : (
                  <UserIconFilledPrototype />
                )}
              </NavItem>
            </List.Item>
          </NavLink>
        </div>
      </Menu>
    </>
  );
};

export default MobileNavPrototype;
