import "./index.less";
const Plus = ({ onClick, width = 24, height = 24, color = "white" }) => {
    return (
        <svg
            onClick={onClick}
            width={width}
            height={height}
            viewBox="0 0 32 32"
            className="plus-icon-svg"
        >
            <defs>
                <clipPath id="a">
                    <path
                        className={color}
                        d="M24.375,11.375h-9.75V1.625a1.625,1.625,0,0,0-3.25,0v9.75H1.625a1.625,1.625,0,0,0,0,3.25h9.75v9.75a1.625,1.625,0,0,0,3.25,0v-9.75h9.75a1.625,1.625,0,0,0,0-3.25"
                    />
                </clipPath>
            </defs>
            <rect className="b" width="32" height="32" />
            <g transform="translate(3 3)">
                <path
                    className={color}
                    d="M24.375,11.375h-9.75V1.625a1.625,1.625,0,0,0-3.25,0v9.75H1.625a1.625,1.625,0,0,0,0,3.25h9.75v9.75a1.625,1.625,0,0,0,3.25,0v-9.75h9.75a1.625,1.625,0,0,0,0-3.25"
                    transform="translate(0 0)"
                />
            </g>
        </svg>
    );
};

export default Plus;
