import React from "react";
import Radio from "@shared/atoms/Radio";
import Space from "@shared/atoms/Space";
import { localStorageService } from "@modules/services/localStorage.service";

import "./index.less";

const SearchRadioGroupPrototype = ({ onChange, value }) => {
  const isLoggedIn = !!localStorageService().get("access_token");

  return (
    <Radio.Group
      className="search-radio-group-prototype"
      onChange={onChange}
      value={value}
    >
      <Space>
        <Radio value={"all"}>All Choice</Radio>
        {isLoggedIn && <Radio value={"my"}>My Choice</Radio>}
      </Space>
    </Radio.Group>
  );
};

export default SearchRadioGroupPrototype;
