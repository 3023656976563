import React from "react";
import "./index.less";
import PublicPlaylistItemIcon from "@assets/icons/public_playlist_item.svg";
import Image from "../../Image";

const PublicPlaylistItem = ({onClick}) => {
  return (
    <div className="recent-views-icon" onClick={onClick}>
        <Image src={PublicPlaylistItemIcon} alt="home" />
    </div>
  );
};

export default PublicPlaylistItem;
