import React, {
  useState,
  useContext,
  useEffect,
  createRef,
  useCallback,
} from "react";
import {
  getPlaylists,
  addVideoInPlaylist,
} from "@redux/actions/playlistsActions";
import { useSelector, useDispatch } from "react-redux";
import { Context } from "@context/context";
import Button from "@shared/atoms/Button";
import Modal from "@shared/atoms/Modal";
import Input from "@shared/atoms/Input";
import Form from "@shared/atoms/Form";
import Alert from "@shared/atoms/Alert/index";
import Dropdown from "@shared/atoms/icons/Dropdown";
import Loader from "@shared/atoms/Loader";
import { Title, Paragraph } from "@shared/atoms/Typography";
import CloseModalIcon from "@shared/atoms/icons/CloseModal";
import { formLayout } from "@modules/config";
import PlusSmall from "@shared/atoms/icons/PlusSmall";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import useDetectMobile from "@src/hooks/useDetectMobile";
import { getPlaylistById } from "@redux/actions/playlistsActions";

import "./index.less";

const AddNewVideoModal = () => {
  const [showList, setShowList] = useState(true);
  const [videoUrl, setVideoUrl] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);

  const [inputArrKeys, setInputArrKeys] = useState([]);
  const [playlistLinkRefs, setPlaylistLinkRefs] = useState([]);
  const [allPlaylists, setAllPlaylists] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const {
    setShowCreatePlaylistModal,
    setShowCreatePlaylistModalOnMobile,
    setShowAddNewVideoModal,
    showAddNewVideoModal,
    setSelectedPlaylist,
    selectedPlaylist,
    clearSelectedPlaylist,
    setClearSelectedPlaylist,
  } = useContext(Context);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const isMobile = useDetectMobile();

  const {
    playlists,
    loading: loadingPlaylists,
    // error,
  } = useSelector((state) => state.playlists);

  const {
    message: addVideoMessage,
    loading: addVideoLoading,
    error: addVideoError,
  } = useSelector((state) => state.addVideosToPlaylist);

  const handleClose = () => {
    setShowAddNewVideoModal(false);
    setVideoUrl("");
    setInputArrKeys([]);
    setSelectedPlaylist({});
  };

  const errorHandler = useCallback(() => {
    if (addVideoError) {
      let errorObj = addVideoError.data.errors;
      let errorMessage = "";

      for (const key in errorObj) {
        errorMessage += `${errorObj[key].join(" ")} `;
      }
      setErrorMessage(errorMessage);
    }
  }, [addVideoError]);

  useEffect(() => {
    errorHandler();
  }, [errorHandler, addVideoError]);

  useEffect(() => {
    if (!playlists && showAddNewVideoModal) {
      dispatch(getPlaylists());
    }
    if (playlists) {
      const allPlaylistsArr = playlists;
      // playlists.finished.forEach((playlist) => {
      //   allPlaylistsArr.push(playlist);
      // });
      // allPlaylistsArr.push(playlists.favorites[0]);
      // allPlaylistsArr.push(playlists.watchLater[0]);
      // playlists.unfinished.forEach((playlist) => {
      //   allPlaylistsArr.push(playlist);
      // });

      setAllPlaylists(allPlaylistsArr);
    }
  }, [dispatch, playlists, showAddNewVideoModal]);

  useEffect(() => {
    if (addVideoMessage) {
      setShowAddNewVideoModal(false);
    }
  }, [addVideoMessage, setShowAddNewVideoModal]);

  useEffect(() => {
    if (addVideoLoading) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [addVideoLoading, setDisableBtn]);

  const toggleList = () => {
    setShowList(!showList);
  };

  const deleteInputElement = (key) => {
    const filteredAry = inputArrKeys.filter((val) => val !== key);
    setInputArrKeys(filteredAry);
  };

  const createInputElement = (key) => {
    setInputArrKeys((prevState) => [...prevState, key]);
  };

  const playlistLinkRefsHandler = () => {
    setPlaylistLinkRefs((playlistLinkRefs) =>
      Array(inputArrKeys.length)
        .fill()
        .map((_, i) => playlistLinkRefs[i] || createRef())
    );
  };

  const showCreatePlaylistModal = () => {
    setShowAddNewVideoModal(false);
    if (!isMobile) {
      setTimeout(() => {
        setShowCreatePlaylistModal(true);
      }, 500);
    } else {
      setTimeout(() => {
        setShowCreatePlaylistModalOnMobile(true);
      }, 500);
    }
  };

  // const getPlaylistIdHandler = (playlist) => {
  //   setSelectedPlaylist(playlist);
  // };

  const addVideoHandler = () => {
    const newPlaylistsArray = [];

    if (videoUrl) {
      newPlaylistsArray.unshift(videoUrl);
    }

    playlistLinkRefs.forEach((playlistLinkRef) => {
      if (playlistLinkRef) {
        newPlaylistsArray.push(playlistLinkRef.current?.state.value);
      }
    });

    Promise.resolve(
      dispatch(addVideoInPlaylist(newPlaylistsArray, selectedPlaylist.id))
    ).then(() => {
      if (clearSelectedPlaylist) {
        setSelectedPlaylist({});
        setClearSelectedPlaylist(false);
      } else {
        dispatch(getPlaylistById(selectedPlaylist.id));
      }
      setVideoUrl("");
      setInputArrKeys([]);
    });
  };

  const playlistSearchHandler = (newSearchQuery) => {
    setSearchQuery(newSearchQuery);
    const filteredPlaylistsArr = [];

    allPlaylists.forEach((playlist) => {
      if (playlist.name.toLowerCase().includes(searchQuery)) {
        filteredPlaylistsArr.push(playlist);
      }
    });
  };

  return (
    <Modal
      title={t("add_new_video")}
      closable={true}
      visible={showAddNewVideoModal}
      mask={true}
      onCancel={handleClose}
      className="add-video-modal"
      closeIcon={<CloseModalIcon />}
      footer={
        showList
          ? [
              <Button
                disabled={disableBtn}
                className="add-video-btn"
                type="primary"
                key="back"
                htmlType="submit"
                onClick={addVideoHandler}
              >
                {t("add_video")}
              </Button>,
            ]
          : [
              <div key="create-new-playlist" className="create-new-playlist">
                <div className="or">or</div>
                <Paragraph onClick={showCreatePlaylistModal} className="text">
                  {t("create_new_playlist")}
                </Paragraph>
              </div>,
            ]
      }
    >
      {addVideoError && (
        <Alert
          message={addVideoError?.data?.message + " " + errorMessage}
          type="error"
        />
      )}

      <Form layout={formLayout}>
        <div className="new-playlist-input-container">
          <Form.Item label={t("paste_video_url")}>
            <Input
              onChange={(e) => {
                setVideoUrl(e.target.value);
              }}
              value={videoUrl}
              placeholder={t("paste_url_here")}
            />
          </Form.Item>
          {inputArrKeys.map((key, i) => (
            <Form.Item key={key} label={t("paste_video_url")}>
              <Input
                className="new-playlist-link-input"
                ref={playlistLinkRefs[i]}
                placeholder={t("paste_url_here")}
                onChange={playlistLinkRefsHandler}
              />
              <CloseModalIcon onClick={() => deleteInputElement(key)} />
            </Form.Item>
          ))}
        </div>

        <div className="add-new-link-container">
          <span
            onClick={() => createInputElement(uuidv4())}
            className="plus-icon-container"
          >
            <PlusSmall className="plus-icon" />
          </span>
        </div>
        <div className={`choose-playlist`}>
          <div onClick={toggleList}>
            <Title className="choose-playlist-text" level={4}>
              {t("choose_playlist_for_new_video")}
            </Title>
            <Dropdown dark={true} state={showList} />
          </div>
        </div>
        {loadingPlaylists && <Loader centered={true} height="10vh" />}
        {showList && (
          <div className="playlists-list">
            <Form.Item label={t("search")}>
              <Input
                placeholder={t("search_for_playlist")}
                onChange={(e) => playlistSearchHandler(e.target.value)}
              />
            </Form.Item>

            {/* <FavoritesList>
              {filteredPlaylists?.map((playlist) => (
                <FavoritesItem
                  kebabMenu={false}
                  key={playlist?.id}
                  videosCount={playlist?.videos?.length}
                  playlistName={playlist?.name}
                  playlist={playlist}
                  deleteItem={false}
                  onClick={() => getPlaylistIdHandler(playlist)}
                  additionalClasses={` ${
                    selectedPlaylist?.id &&
                    selectedPlaylist?.id === playlist?.id
                      ? "active"
                      : ""
                  }`}
                ></FavoritesItem>
              ))}
            </FavoritesList> */}
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default AddNewVideoModal;
