import { CHOICE_URL } from "@modules/config";
import { axiosInstance } from "../../modules/request/api";
import {
  META_DATA_FAIL,
  META_DATA_REQUEST,
  META_DATA_SUCCESS,
} from "../constants/metaDataConstants";

export const getMetaData = (url) => async (dispatch) => {
  dispatch({ type: META_DATA_REQUEST });
  try {
    const { data } = await axiosInstance().post(`${CHOICE_URL}/api/getMeta`, {
      url,
    });
    dispatch({ type: META_DATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: META_DATA_FAIL, payload: error.message });
  }
};
