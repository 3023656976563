import { getTeam } from "@redux/actions/TeamActions";

const { localStorageService } = require("@modules/services/localStorage.service");
const { SET_ACCOUNT_MODE } = require("@redux/constants/accountModeConstants");
const { ACC_MODES, LOCALSTORAGE } = require("const");
const { useDispatch, useSelector } = require("react-redux")

export const useAccountMode = () => {
    const dispatch = useDispatch();
    const accountMode = useSelector(({ accountMode }) => accountMode);

    function changeAccountMode(accMode, teamId) {
        const accountMode = ACC_MODES[accMode];

        if(!accountMode) return

        dispatch({
            type: SET_ACCOUNT_MODE,
            payload: accountMode,
        })

        localStorageService().set(LOCALSTORAGE.ACCOUNT_MODE, accountMode)
        if(teamId) {
            localStorageService().set(LOCALSTORAGE.TEAM_ID, teamId)
        } else {
            localStorageService().remove(LOCALSTORAGE.TEAM_ID)
        }
    }

    function fetchTeam() {
        if(isMode(ACC_MODES.TEAM)) {
            dispatch(getTeam(localStorageService().get(LOCALSTORAGE.TEAM_ID)))
        }
    }

    function isMode(accMode) {
        return accountMode === accMode;
    }

    return {
        changeAccountMode,
        isMode,
        isOwnMode: isMode(ACC_MODES.OWN),
        isTeamMode: isMode(ACC_MODES.TEAM),
        setOwnMode: () => changeAccountMode(ACC_MODES.OWN),
        setTeamMode: (teamId) => changeAccountMode(ACC_MODES.TEAM, teamId),
        accountMode,
        fetchTeam,
        teamId: localStorageService().get(LOCALSTORAGE.TEAM_ID),
    }
}