import {
    VIEW_REQUEST,
    VIEW_SUCCESS,
    VIEW_FAIL,
    CLICK_VIEW_REQUEST,
    CLICK_VIEW_SUCCESS,
    CLICK_VIEW_FAIL,
    VIEW_START_REQUEST,
    VIEW_START_SUCCESS,
    VIEW_START_FAIL,
    VIEW_END_REQUEST,
    VIEW_END_SUCCESS,
    VIEW_END_FAIL,
    VIEW_LIST_REQUEST,
    VIEW_LIST_SUCCESS,
    VIEW_LIST_FAIL,
} from "../constants/viewConstants";
import { CHOICE_URL } from "@modules/config";
import { axiosInstance } from "@modules/request/api";

export const view = (viewableType, viewableId) => async (dispatch) => {
    try {
        dispatch({ type: VIEW_REQUEST });

        const { data } = await axiosInstance().post(`${CHOICE_URL}/api/view`, {
            viewable_type: viewableType,
            viewable_id: viewableId,
        });
        dispatch({ type: VIEW_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: VIEW_FAIL, payload: error?.response });
    }
};

export const clickView = (videoId, clickType) => async(dispatch) => {
    try {
        dispatch({ type: CLICK_VIEW_REQUEST });

        const { data } = await axiosInstance().post(`${CHOICE_URL}/api/videos/${videoId}/clicks`, {
            type: clickType,
        });
        dispatch({ type: CLICK_VIEW_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CLICK_VIEW_FAIL, payload: error?.response });
    }
}

export const viewStart = (viewActionId) => async (dispatch) => {
    try {
        dispatch({ type: VIEW_START_REQUEST });
        const { data } = await axiosInstance().post(
            `${CHOICE_URL}/api/view/start`,
            { viewActionId }
        );
        dispatch({ type: VIEW_START_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: VIEW_START_FAIL, payload: error?.response });
    }
};

export const viewEnd = (viewProcessId) => async (dispatch) => {
    try {
        dispatch({ type: VIEW_END_REQUEST });
        const { data } = await axiosInstance().post(
            `${CHOICE_URL}/api/view/end`,
            { viewProcessId }
        );
        dispatch({ type: VIEW_END_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: VIEW_END_FAIL, payload: error?.response });
    }
};

export const getViewList = () => async (dispatch) => {
    try {
        dispatch({ type: VIEW_LIST_REQUEST });
        const { data } = await axiosInstance().get(
            `${CHOICE_URL}/api/view/list`
        );
        dispatch({ type: VIEW_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: VIEW_LIST_FAIL, payload: error?.response });
    }
};
