import React, { useEffect } from "react";
import Tab, { TabPane } from "../../atoms/Tab";
import { getTiers } from "@redux/actions/paymentActions";
import { useDispatch, useSelector } from "react-redux";
import TierCardPrototype from "../TierCardPrototype";
import TransactionsItem from "../listItems/TransactionsItem";
import TransactionsList from "../../../organisms/lists/TransactionsList";
import SubscribedChannelsList from "../../../organisms/lists/SubscribedChannelsList";
import SubscribedChannelsItem from "@shared/molecules/listItems/SubscribedChannelsItem";
import { getSubscribtions } from "@redux/actions/paymentActions";

import "./index.less";
import { useTranslation } from "react-i18next";

const WalletTabs = () => {
  const dispatch = useDispatch();

  const { data: tierData } = useSelector((state) => state.tiers);

  const { t } = useTranslation();

  const {
    // loading: transactionsLoading,
    message: transactionData,
  } = useSelector((state) => state.transactions);

  const { message: subscribtionsMessage } = useSelector(
    (state) => state.subscribtions
  );

  useEffect(() => {
    dispatch(getTiers());
    dispatch(getSubscribtions());
  }, [dispatch]);

  return (
    <div className="wallet-tabs-container">
      <Tab defaultActiveKey="1">
        <TabPane tab={t("wallet_transactions")} key="1">
          <TransactionsList hasTitle={false}>
            {transactionData?.transactions?.map((transaction) => (
              <TransactionsItem
                tierName={transaction.tier_name}
                date={transaction.date_at}
                price={transaction.amount}
                type={transaction.type}
                isPaid={transaction.is_paid}
              ></TransactionsItem>
            ))}
          </TransactionsList>{" "}
        </TabPane>
        <TabPane tab={t("wallet_tiers")} key="2">
          {tierData?.tiers.map((tier) => (
            <TierCardPrototype
              key={tier.id}
              name={tier.name}
              price={tier.price}
              packageName={tier.package_name}
            />
          ))}
        </TabPane>
        <TabPane tab={t("wallet_subscriptions")} key="3">
          <SubscribedChannelsList isPrototype={true}>
            {subscribtionsMessage?.subscriptions?.map((subscribtion) => (
              <SubscribedChannelsItem
                tierName={subscribtion.tier_name}
                activeFrom={subscribtion.active_from}
                activeTo={subscribtion.active_to}
                isPrototype={true}
              ></SubscribedChannelsItem>
            ))}
          </SubscribedChannelsList>
        </TabPane>
      </Tab>
    </div>
  );
};

export default WalletTabs;
