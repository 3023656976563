import {
  USER_CONFIRM_CODE_FAIL,
  USER_CONFIRM_CODE_REQUEST,
  USER_CONFIRM_CODE_RESEND_FAIL,
  USER_CONFIRM_CODE_RESEND_REQUEST,
  USER_CONFIRM_CODE_RESEND_SUCCESS,
  USER_CONFIRM_CODE_SUCCESS,
  USER_CONFIRM_RESET_PASSWORD_REQUEST,
  USER_CONFIRM_RESET_PASSWORD_SUCCESS,
  USER_CONFIRM_RESET_PASSWORD_FAIL,
  USER_CONFIRM_EMAIL_REQUEST,
  USER_CONFIRM_EMAIL_SUCCESS,
  USER_CONFIRM_EMAIL_FAIL,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_RESET,
  USER_LOGOUT,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_RESET,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_REQUIRED_FIELDS_REQUEST,
  USER_REQUIRED_FIELDS_SUCCESS,
  USER_REQUIRED_FIELDS_FAIL,
  USER_VERIFY_EMAIL_REQUEST,
  USER_VERIFY_EMAIL_SUCCESS,
  USER_VERIFY_EMAIL_FAIL,
  USER_CONFIRM_RESET_PASSWORD_CLEAR,
  USER_REGISTER_ONLY_EMAIL_REQUEST,
  USER_REGISTER_ONLY_EMAIL_SUCCESS,
  USER_REGISTER_ONLY_EMAIL_FAIL,
  USER_REGISTER_FINALIZE_REQUEST,
  USER_REGISTER_FINALIZE_SUCCESS,
  USER_REGISTER_FINALIZE_FAIL,
  USER_REGISTER_FINALIZE_RESET,
  USER_REGISTER_ONLY_EMAIL_RESET,
  USER_CONFIRM_RESET_PASSWORD_RESET,
  FIND_USERES_EMAIL_REQUEST,
  FIND_USERES_EMAIL_SUCCESS,
  FIND_USERES_EMAIL_FAIL,
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload, loggedIn: true };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload, loggedIn: false };
    case USER_LOGOUT:
      return {};
    case USER_LOGIN_RESET:
      return { ...state, error: null };
    default:
      return state;
  }
};

export const userLogoutReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGOUT_REQUEST:
      return { loading: true };
    case USER_LOGOUT_SUCCESS:
      return { loading: false, message: action.payload };
    case USER_LOGOUT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userConfirmEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CONFIRM_EMAIL_REQUEST:
      return { loading: true };
    case USER_CONFIRM_EMAIL_SUCCESS:
      return { loading: false, success: true };
    case USER_CONFIRM_EMAIL_FAIL:
      return { loading: false, error: true, errorMsg: action.payload };
    default:
      return state;
  }
};

export const userConfirmResetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CONFIRM_RESET_PASSWORD_REQUEST:
      return { loading: true };
    case USER_CONFIRM_RESET_PASSWORD_SUCCESS:
      return { loading: false, success: true };
    case USER_CONFIRM_RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case USER_CONFIRM_RESET_PASSWORD_RESET:
      return {};
    default:
      return state;
  }
};

export const userPasswordResetReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RESET_PASSWORD_REQUEST:
      return { loading: true };

    case USER_RESET_PASSWORD_SUCCESS:
      return { loading: false, success: true };

    case USER_RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };

    case USER_CONFIRM_RESET_PASSWORD_CLEAR:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, error: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const userRegisterOnlyEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_ONLY_EMAIL_REQUEST:
      return { loading: true };
    case USER_REGISTER_ONLY_EMAIL_SUCCESS:
      return { loading: false, info: action.payload };
    case USER_REGISTER_ONLY_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    case USER_REGISTER_ONLY_EMAIL_RESET:
      return {};

    default:
      return state;
  }
};

export const userRegisterFinalizeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_FINALIZE_REQUEST:
      return { loading: true };
    case USER_REGISTER_FINALIZE_SUCCESS:
      return { loading: false, message: action.payload };
    case USER_REGISTER_FINALIZE_FAIL:
      return { loading: false, error: action.payload };
    case USER_REGISTER_FINALIZE_RESET:
      return {};
    default:
      return state;
  }
};

export const userConfirmCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CONFIRM_CODE_REQUEST:
      return { loading: true };
    case USER_CONFIRM_CODE_SUCCESS:
      return { loading: false, message: action.payload, success: true };
    case USER_CONFIRM_CODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userConfirmCodeResendReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CONFIRM_CODE_RESEND_REQUEST:
      return { loading: true };
    case USER_CONFIRM_CODE_RESEND_SUCCESS:
      return { loading: false, message: action.payload };
    case USER_CONFIRM_CODE_RESEND_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userRequiredFieldsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REQUIRED_FIELDS_REQUEST:
      return { loading: true };
    case USER_REQUIRED_FIELDS_SUCCESS:
      return { loading: false, message: action.payload };
    case USER_REQUIRED_FIELDS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userVerifyEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_VERIFY_EMAIL_REQUEST:
      return { loading: true };
    case USER_VERIFY_EMAIL_SUCCESS:
      return { loading: false, message: action.payload };
    case USER_VERIFY_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const FindUsersEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case FIND_USERES_EMAIL_REQUEST:
      return { loading: true };
    case FIND_USERES_EMAIL_SUCCESS:
      return { loading: false, data: action.payload };
    case FIND_USERES_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
