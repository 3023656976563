import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setBalance as setBalanceHandler } from "@redux/actions/paymentActions";
import Button from "@shared/atoms/Button/index";
import Form from "@shared/atoms/Form/index";
import Alert from "@shared/atoms/Alert/index";
import Input from "@shared/atoms/Input/index";
import Modal from "@shared/atoms/Modal/index";
import Box from "@shared/atoms/Box/index";
import CloseModalIcon from "@shared/atoms/icons/CloseModal";
import { useTranslation } from "react-i18next";
import { formLayout } from "@modules/config";

import "./index.less";

const BalanceModal = ({ handleClose, isModalVisible }) => {
    const [balance, setBalance] = useState("");

    const { t } = useTranslation();

    const { loading, error } = useSelector((state) => state.setBalance);

    const dispatch = useDispatch();

    const balanceHandler = (e) => {
        setBalance(e.target.value);
    };

    const sendBalanceHandler = () => {
        dispatch(setBalanceHandler(balance));
    };

    return (
        <Modal
            title={t("add_balance")}
            closable={true}
            visible={isModalVisible}
            onCancel={handleClose}
            className="enter-balance-modal"
            closeIcon={<CloseModalIcon />}
            footer={[
                <Button
                    disabled={loading}
                    type="primary"
                    key="back"
                    onClick={sendBalanceHandler}
                    htmlType="submit"
                >
                    {t("add_balance")}
                </Button>,
            ]}
        >
            {error && (
                <Alert
                    message={
                        error?.data
                            ? error.data
                            : error?.amount
                                ? error?.amount[0]
                                : "Error! Invalid input"
                    }
                    type="error"
                />
            )}
            <Form layout={formLayout}>
                <Form.Item>
                    <div className="balance-box-container">
                        <Box onClick={() => setBalance(20)}>{"€20.00"}</Box>
                        <Box onClick={() => setBalance(50)}>{"€50.00"}</Box>
                        <Box onClick={() => setBalance(100)}>{"€100.00"}</Box>
                    </div>
                    <Input
                        className="balance-input"
                        value={balance}
                        required={true}
                        onChange={balanceHandler}
                        placeholder="€0.00"
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default BalanceModal;
