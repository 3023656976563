import React, { useState, useContext } from "react";
import Modal from "@shared/atoms/Modal";
import { useTranslation } from "react-i18next";
import "./index.less";
import EditableImage from "@components/organisms/EditableImage";
import Select from "@components/shared/atoms/SearchSelect";
import { useDispatch, useSelector } from "react-redux";
import { createTeam, getTeamsAction } from "@redux/actions/TeamActions";
import useSelectSearch from "hooks/useSelectSearch";
import { Context } from "@context/context";

const CreateTeamModal = ({ ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.createTeam);

  const errorMessage =
    error && Object.values(error?.data).map((el) => el[0])[0];

  const { setShowCreateTeamModal } = useContext(Context);

  const [teamName, setTeamName] = useState("");
  const [description, setDescription] = useState("");
  const [blobImg, setBlobImg] = useState(null);

  const { playlists } = useSelector((state) => state.playlists);

  const publicPlaylists = playlists?.public?.map((playlist) => ({
    name: playlist.name,
    id: playlist.id,
  }));

  const otherPlaylists = playlists?.other?.map((playlist) => ({
    name: playlist.name,
    id: playlist.id,
  }));

  const allPlaylists = publicPlaylists &&
    otherPlaylists && [
      { name: `Select All Playlist`, id: `Select All Playlist` },
      ...publicPlaylists,
      ...otherPlaylists,
    ];

  const {
    selectedItems: selectedPlaylists,
    isAllSelected: isAllPlaylistSelected,
    onSelectChange: onPlaylistChange,
    selectValue: playlistsValue,
  } = useSelectSearch("Playlist", allPlaylists);

  const {
    selectedItems: selectedMembers,
    isAllSelected: isAllMembersSelected,
    onSelectChange: onMembersChange,
  } = useSelectSearch();

  const createTeamHandler = () => {
    let fd = new FormData();

    selectedPlaylists
      .slice(isAllPlaylistSelected ? 1 : 0)
      .forEach((pl, index) => {
        fd.append(`playlists[${index}]`, pl);
      });

    selectedMembers.forEach((pl, index) => {
      fd.append(`users[${index}]`, pl);
    });

    fd.append("name", teamName);
    fd.append("description", description);
    if(blobImg) {
      fd.append("logo", blobImg);
    }

    dispatch(createTeam(fd)).then(() => {
      dispatch(getTeamsAction());
      setShowCreateTeamModal(false);
    });
  };

  return (
    <Modal
      title={"Create New Team"}
      className="create-team-modal"
      modalContainer=".tablet-prototype"
      footer={[
        <div className="btn-container">
          <button
            key="back"
            type="primary"
            className="confirm-btn"
            onClick={createTeamHandler}
          >
            {loading ? "Loading" : "Create Team"}
          </button>
        </div>,
      ]}
      {...rest}
    >
      <div className="input-container">
        <label>{t("playlist_name")}</label>
        <input
          maxLength={50}
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
        />
      </div>
      <div className="input-container">
        <label>Description</label>
        <input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      <Select
        mode="multiple"
        value={playlistsValue}
        onChange={onPlaylistChange}
        placeholder="Choose a playlist"
        filteredOptions={allPlaylists}
        isAllSelected={isAllPlaylistSelected}
      />
      <Select
        onChange={onMembersChange}
        placeholder="Add user e-mail"
        isAllSelected={isAllMembersSelected}
      />

      <div className="editable-img">
        <EditableImage setBlobImg={setBlobImg} btnText="Upload channel image" />
      </div>

      <div className="err-msg">{errorMessage}</div>
    </Modal>
  );
};

export default function ModalWrapper(props) {
  return props.visible ? <CreateTeamModal {...props} /> : null;
}
