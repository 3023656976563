import React from "react";
import AntTag from "antd/lib/tag/index";
import TagClose from "../icons/TagClose";

import "./index.less";

const Tag = ({ className, children, ...rest }) => {
    return (
        <AntTag
            className={className ? className : ""}
            closeIcon={<TagClose />}
            {...rest}
        >
            {children}
        </AntTag>
    );
};

export default Tag;
