const { toggleWelcomeMsg } = require("@redux/actions/homeContentActions");
const { getUserProfile } = require("@redux/actions/profileActions");
const { useDispatch } = require("react-redux");

const useToggleWelcomeMsg = (defaultField) => {
    const dispatch = useDispatch();

    function toggleWelcome(msg = defaultField) {
        Promise.resolve(dispatch(toggleWelcomeMsg(msg))).then(() => {
            dispatch(getUserProfile());
        });
    }

    return { toggleWelcome }
};

export default useToggleWelcomeMsg;