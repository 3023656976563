import useSelectSearch from "hooks/useSelectSearch";
import Select from "@components/shared/atoms/SearchSelect";
import Checkbox from "@components/shared/atoms/Checkbox";
import { useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import querystring from "query-string";

import "./index.less";
import { updateTeam } from "@redux/actions/TeamActions";
import { message } from "antd";

const AddPlaylistsInTeam = () => {
  const dispatch = useDispatch();
  const queryString = querystring.parse(window.location.search);

  const { playlists } = useSelector((state) => state.playlists);
  const teamPlaylist = useSelector(
    (state) => state.teamItem?.data?.data?.playlists
  );

  const [playlistVal, setPlaylistVal] = useState("");
  const [isAddPlaylistShown, toggleAddPlaylist] = useReducer(
    (state) => !state,
    false
  );

  const publicPlaylists = playlists?.public?.map((playlist) => {
    return { name: playlist.name, id: playlist.id };
  });

  const otherPlaylists = playlists?.other?.map((playlist) => {
    return { name: playlist.name, id: playlist.id };
  });

  const allPlaylists = publicPlaylists &&
    otherPlaylists && [
      { name: `Select All Playlist`, id: `Select All Playlist` },
      ...publicPlaylists,
      ...otherPlaylists,
    ];
  const {
    selectedItems: selectedPlaylists,
    isAllSelected: isAllPlaylistSelected,
    onSelectChange: onPlaylistChange,
    selectValue: playlistsValue,
  } = useSelectSearch("Playlist", allPlaylists);

  const handleSubmit = () => {
    Promise.resolve(      
      dispatch(
        updateTeam(queryString.teamId, {
          playlists: [...selectedPlaylists, ...teamPlaylist.map(({ id }) => id)],
        }))).then(() => {
          message.success('Playlist Added');
        })
  };

  return (
    <div className="team-add-playlist-container">
      <div style={{ alignSelf: "flex-start" }}>
        <Checkbox onChange={toggleAddPlaylist}>
          {isAddPlaylistShown ? "Hide Add Playlist" : "Show Add Playlist"}
        </Checkbox>
      </div>
      {isAddPlaylistShown && (
        <>
          <Select
            value={playlistsValue}
            onChange={onPlaylistChange}
            placeholder="Choose a playlist"
            filteredOptions={allPlaylists}
            isAllSelected={isAllPlaylistSelected}
            setInputVal={(inputValue) => {
              setPlaylistVal(inputValue);
            }}
          />
          <button onClick={handleSubmit}>Save</button>
        </>
      )}
    </div>
  );
};

export default AddPlaylistsInTeam;
