import React from "react";
import { useHistory } from "react-router";
import { Paragraph } from "@shared/atoms/Typography";
import Button from "@shared/atoms/Button";
import Image from "@shared/atoms/Image";

import LogOutImage from "@assets/icons/logOut-icon.svg";

import "./index.less";
import { useTranslation } from "react-i18next";

const BackToLogin = ({ text }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const loginHandler = () => {
        history.push("/login");
    };

    const registerHandler = () => {
        history.push("/register");
    };

    return (
        <div className="back-to-login-container">
            <Paragraph>
                {text ? text : "Please, login and view the content"}
            </Paragraph>
            <Button className="login-btn" onClick={loginHandler}>
                {t("login")}
                <Image src={LogOutImage} alt="log out" />
            </Button>
            <Button className="login-btn" onClick={registerHandler}>
                {t("register")}
                <Image src={LogOutImage} alt="log out" />
            </Button>
        </div>
    );
};

export default BackToLogin;
