import React, { useContext, useEffect, useState } from "react";
import Modal from "@shared/atoms/Modal/index";
import { Input } from "antd";
import Form from "@components/shared/atoms/Form";

import "./index.less";
import Button from "@components/shared/atoms/Button";
import { Context } from "@context/context";
import { useDispatch, useSelector } from "react-redux";
import {
  createPlaylistGroup,
  getPlaylists,
} from "@redux/actions/playlistsActions";
import GroupStatusesSelect from "@components/shared/molecules/GroupStatusesSelect";

const CombinePlaylistModal = ({ isVisible, closeHandler }) => {
  const [groupName, setGroupName] = useState("");
  const [statusId, setStatusId] = useState(null);
  const [status, setStatus] = useState("");

  const { combineData } = useContext(Context);
  const { statuses } = useSelector((state) => state.playlistStatuses);

  const dispatch = useDispatch();

  useEffect(() => {
    if (statuses) setStatusId(statuses[0]?.id);
  }, [statuses]);

  const changeStatus = (e) => {
    const statusId = statuses?.find(
      (status) => status.name === e.target.value
    ).id;

    setStatus(e.target.value);
    setStatusId(statusId);
  };

  function onSubmit() {
    const { draggableId, combine } = combineData;
    Promise.resolve(
      dispatch(
        createPlaylistGroup({
          name: groupName,
          playlists: [draggableId, combine.draggableId],
          status_id: statusId,
        })
      )
    )
      .then((res) => dispatch(getPlaylists()))
      .then(() => {
        closeHandler()
        resetState()
      })
      .catch((error) => console.log(error));
  }

  function resetState() {
    setGroupName("");
    setStatusId(null);
  }

  return (
    <Modal
      visible={isVisible}
      className="modal-basic-prototype alert-modal-prototype group-playlists"
      mask={true}
      footer={<></>}
      closable={true}
      onCancel={() => {
        closeHandler();
      }}
    >
      <Form>
        <Form.Item>
          <Input
            onChange={(e) => setGroupName(e.target.value)}
            value={groupName}
            placeholder="Group name"
            className="group-input"
          />
        </Form.Item>
        <Form.Item>
          <GroupStatusesSelect
            changeStatusHandler={changeStatus}
            defaultValue={status}
          />
        </Form.Item>
        <Button type="submit" onClick={onSubmit} className="ant-btn-prototype">
          Create
        </Button>
      </Form>
    </Modal>
  );
};

export default CombinePlaylistModal;
