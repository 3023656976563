import { CHOICE_URL } from "@modules/config";
import { axiosInstance } from "@modules/request/api";
import { 
    COPY_EPG_FAIL,
    COPY_EPG_ITEM_FAIL,
    COPY_EPG_ITEM_REQUEST,
    COPY_EPG_ITEM_SUCCESS,
    COPY_EPG_REQUEST,
    COPY_EPG_SUCCESS,
    COPY_SERIES_FAIL, 
    COPY_SERIES_REQUEST, 
    COPY_SERIES_SUCCESS, 
    COPY_TIER_CHANNEL_FAIL, 
    COPY_TIER_CHANNEL_REQUEST, 
    COPY_TIER_CHANNEL_SUCCESS, 
    COPY_VIDEO_FAIL, 
    COPY_VIDEO_REQUEST, 
    COPY_VIDEO_SUCCESS
} from "@redux/constants/copy";

export const copyVideoAction = (props) => async (dispatch) => {
    const URL = `${CHOICE_URL}/api/copy/video`;
    try {
        dispatch({ type: COPY_VIDEO_REQUEST })

        const { data } = await axiosInstance().post(URL, props)

        dispatch({
            type: COPY_VIDEO_SUCCESS,
            payload: data
        })

        return data;
    } catch (error) {
        dispatch({
            type: COPY_VIDEO_FAIL,
            payload: error?.response
        })
    }
}

export const copySerieAction = (props) => async (dispatch) => {
    const URL = `${CHOICE_URL}/api/copy/series`;
    try {
        dispatch({ type: COPY_SERIES_REQUEST })

        const { data } = await axiosInstance().post(URL, props)

        dispatch({
            type: COPY_SERIES_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: COPY_SERIES_FAIL,
            payload: error?.response
        })
    }
}

export const copyEpgItemAction = (props) => async (dispatch) => {
    const URL = `${CHOICE_URL}/api/copy/epg`;
    try {
        dispatch({ type: COPY_EPG_ITEM_REQUEST })

        const { data } = await axiosInstance().post(URL, props)

        dispatch({
            type: COPY_EPG_ITEM_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: COPY_EPG_ITEM_FAIL,
            payload: error?.response
        })
    }
}

export const copyEpgAction = (props) => async (dispatch) => {
    const URL = `${CHOICE_URL}/api/copy/epg-series`;
    try {
        dispatch({ type: COPY_EPG_REQUEST })

        const { data } = await axiosInstance().post(URL, props)

        dispatch({
            type: COPY_EPG_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: COPY_EPG_FAIL,
            payload: error?.response
        })
    }
}

export const copyTierChannelAsVideoAction = (props) => async (dispatch) => {
    const URL = `${CHOICE_URL}/api/copy/channel-as-video`;
    try {
        dispatch({ type: COPY_TIER_CHANNEL_REQUEST })

        const { data } = await axiosInstance().post(URL, props)

        dispatch({
            type: COPY_TIER_CHANNEL_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: COPY_TIER_CHANNEL_FAIL,
            payload: error?.response
        })
    }
}