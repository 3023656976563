export const formLayout = "vertical";
export const defaultDuration = "350ms";

export const API_URL = process.env.REACT_APP_API_URL;
export const CHOICE_URL = process.env.REACT_APP_CHOICE_URL;

export const mobileScreenWidth = 1000;
export const mobileScreenHeight = 1024;

export const supportedLanguages = ["en", "nl"];

export const scrollTopHeight = 300;
