import React, { useContext, useState } from "react";
import Sort from "@shared/molecules/Sort";
import Plus from "@shared/atoms/icons/Plus";
import Share from "@shared/atoms/icons/Share";
import Pen from "@shared/atoms/icons/Pen";
import KebabMenu from "@shared/atoms/icons/KebabMenu";
import { Title, Paragraph } from "@shared/atoms/Typography";
import { Context } from "@context/context";

import { follow, unfollow } from "@redux/actions/socialActions";
import { getPlaylistById } from "@redux/actions/playlistsActions";

import "./index.less";
import KebabMenuPopup from "../../../organisms/KebabMenuPopup";
import PopupItem from "../listItems/PopupItem";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const VideosHeaderMobile = ({
  title,
  videoCount,
  hasPlus = true,
  hasEdit = true,
  hasKebabMenu = false,
  sortByNewest,
  sortByOldest,
}) => {
  const dispatch = useDispatch();
  const [dropdownState, setDropdownState] = useState(false);
  const {
    selectedPlaylist,
    setShowAddNewVideoModal,
    setShowShareModal,
    setShowPlaylistSettingsModal,
  } = useContext(Context);

  const { t } = useTranslation();

  const {
    playlist: playlistById,
    // loading: loadingPlaylist,
    // error: errorPlaylist,
  } = useSelector((state) => state.playlistById);

  const followPlaylistHandler = () => {
    if (selectedPlaylist.is_followed) {
      Promise.resolve(
        dispatch(unfollow("App\\Playlist", selectedPlaylist.id.toString()))
      ).then(() => {
        dispatch(getPlaylistById(selectedPlaylist.id));
      });
    } else {
      Promise.resolve(
        dispatch(follow("App\\Playlist", selectedPlaylist.id.toString()))
      ).then(() => {
        dispatch(getPlaylistById(selectedPlaylist.id));
      });
    }
  };

  return (
    <div className="videos-header-mobile">
      <div>
        <Title className="title" level={4}>
          {title}
        </Title>
        <Paragraph className="sub-text">
          {videoCount} {t("videos")}
        </Paragraph>
      </div>
      <div className="icons-container">
        {hasPlus && <Plus onClick={() => setShowAddNewVideoModal(true)} />}
        <Share onClick={() => setShowShareModal(true)} />
        {hasEdit && <Pen onClick={() => setShowPlaylistSettingsModal(true)} />}
        <Sort
          sortByNewest={sortByNewest}
          sortByOldest={sortByOldest}
          showText={false}
        />
        {hasKebabMenu && (
          <div
            style={{
              position: "relative",
              display: "inline-block",
            }}
            onClick={() => {
              setDropdownState(!dropdownState);
            }}
          >
            <KebabMenu className="kebab-menu" />
            {dropdownState && (
              <KebabMenuPopup
                setShowDropdown={setDropdownState}
                className="t-100 favorites-playlist-type"
              >
                <PopupItem>Save playlist</PopupItem>
                {playlistById.data.is_followed ? (
                  <PopupItem onClick={followPlaylistHandler}>
                    {t("unfollow")}
                  </PopupItem>
                ) : (
                  <PopupItem onClick={followPlaylistHandler}>
                    {t("follow")}
                  </PopupItem>
                )}
              </KebabMenuPopup>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VideosHeaderMobile;
