/**
 * @param {string} url - website url
 * @returns {string} url hostname
 * @example
 * 
 * getUrlHostname("http://youtube.com/") => "youtube.com"
 * 
 */
export const getUrlHostname = (url) => {
  const { hostname } = new URL(url);

  return hostname.replace("www.", "");
};


/**
 * @param {Date} date1 - date string
 * @param {Date} date1 - date string
 * @returns {boolean} boolean
 * @example
 * 
 */
export const compareDates = (date1, date2) => {
  let d1 = new Date(date1).getTime();
  let d2 = new Date(date2).getTime();
  
  return d1 > d2;
}