import React, { useEffect } from "react";
import MainLayout from "../../layout/MainLayout";
// import FavoritesFirst from "@components/organisms/diemensions/first/Favorites";
// import FavoritesSecond from "@components/organisms/diemensions/second/Favorites";
// import FavoritesThird from "@components/organisms/diemensions/third/Favorites";
// import ReportContentModal from "@components/organisms/modals/ReportContentModal";
import MobilePlaylistsPrototype from "../../components/organisms/MobilePlaylistsPrototype";
import DesktopPrototype from "@components/organisms/DesktopPrototype";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { getDirectPublishContent, getHomeHeartContents, getWatchLaterContent } from "@redux/actions/homeContentActions";
import { useAccountMode } from "hooks";

export const MyPlaylists = () => {
  const dispatch = useDispatch();
  const { fetchTeam } = useAccountMode()

  useEffect(() => {
    dispatch(getHomeHeartContents());
    dispatch(getDirectPublishContent());
    dispatch(getWatchLaterContent());
    fetchTeam();
  }, [dispatch]);

  return (
    <>
      {!isMobile && (
        <>
          <DesktopPrototype >
            <MobilePlaylistsPrototype />
          </DesktopPrototype>
        </>
      )}

      {isMobile && (
        <MainLayout fluid={true} className="mobile-layout">
          {/* <MobileFavorites /> */}
          <MobilePlaylistsPrototype />
        </MainLayout>
      )}
    </>
  );
};
