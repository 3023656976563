import React, { useCallback, useEffect, useState } from "react";
import Modal from "@shared/atoms/Modal/index";
import { Paragraph } from "@shared/atoms/Typography/index";

import "./index.less";

const TEXT_DISPLAY_TIME = 2000;
const AUTO_CLOSE_MODAL_TIME = 6000;

const PopupBlockedModalPrototype = ({ isVisible, setIsVisible }) => {
  const [displayPopupText, setDisplayPopupText] = useState(false);

  const closeHandler = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  useEffect(() => {
    let popupTextTimer = setTimeout(() => {
      setDisplayPopupText(true);
    }, TEXT_DISPLAY_TIME);
    let popupTimer = setTimeout(() => {
      closeHandler();
    }, AUTO_CLOSE_MODAL_TIME);
    return () => {
      clearTimeout(popupTimer);
      clearTimeout(popupTextTimer);
    };
  }, [closeHandler]);

  return (
    <Modal
      visible={isVisible}
      className="modal-basic-prototype popup-blocked-modal-prototype"
      mask={true}
      footer={<></>}
      closable={true}
      onCancel={() => {
        closeHandler();
      }}
      destroyOnClose={true}
    >
      <div>
        <Paragraph>
          Choice redirects you now to an external content item…{" "}
        </Paragraph>
        {displayPopupText && (
          <Paragraph>
            Please check that your device does not block pop-ups{" "}
          </Paragraph>
        )}
      </div>
    </Modal>
  );
};

export default PopupBlockedModalPrototype;
