import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../../../context/context";
import { useLocation } from "react-router-dom";
import Modal from "@shared/atoms/Modal";
import Alert from "@shared/atoms/Alert/index";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SpinnerPrototype from "@shared/atoms/SpinnerPrototype";
import {
  getPlaylistById,
  updateSerieDetails,
} from "@redux/actions/playlistsActions";
import debounce from "lodash.debounce";

import "./index.less";

export default function UpdateSerieDetailsModal({ isVisible, closeModal, serieItems }) {
  const { t } = useTranslation();

  const [serieName, setSerieName] = useState("");
  const [serieDescription, setSerieDescription] = useState("");
  const [serieId, setSerieId] = useState("");
  const [serieItem, setSerieItem] = useState(null);

  const { currentSerieItem } = useContext(Context);

  const dispatch = useDispatch();
  const location = useLocation();

  const playlistId = location.search.split("=")[1];

  const { error: addVideoError } = useSelector(
    (state) => state.addVideosToPlaylist
  );

  const { loading: updateSerieItemDetailsLoading } = useSelector(
    (state) => state.updateSerieItemDetails
  );

  const delayedGetPlaylistById = debounce((playlistId) => {
    dispatch(getPlaylistById(playlistId));
  }, 400);

  async function handleUpdateSerieDetails() {
    await Promise.all([
      dispatch(
        updateSerieDetails(serieId, {
          name: serieName,
          // description: serieDescription,
          serie_id: serieId,
          home_video_id: serieItem || null
        })
      ),
      delayedGetPlaylistById(playlistId),
    ]).then(() => {
      closeModal();
    });
  }

  useEffect(() => {
    setSerieName(currentSerieItem?.name);
    setSerieDescription(currentSerieItem?.description);
    setSerieId(currentSerieItem?.id);
    return () => {
      setSerieName("");
      setSerieDescription("");
    };
  }, [currentSerieItem]);

  return (
    <div>
      {updateSerieItemDetailsLoading && (
        <SpinnerPrototype withOverlay={true} size={4} />
      )}

      <Modal
        title={t("update_series")}
        className="update-serie-modal-prototype"
        visible={isVisible}
        onCancel={closeModal}
        destroyOnClose={true}
        mask={true}
        footer={
          <button onClick={handleUpdateSerieDetails} disabled={false}>
            {t("update")}
          </button>
        }
      >
        {addVideoError && (
          <Alert
            message={
              addVideoError?.data.message +
              " " +
              addVideoError?.data.errors["0.src"]
            }
            type="error"
          />
        )}

        <div className="form-control">
          <label>{t("series_name")}</label>
          <input
            value={serieName}
            onChange={(e) => setSerieName(e.target.value)}
          />
        </div>

        {/* <div className="form-control">
          <label>{t("series_description")}</label>
          <input
            value={serieDescription}
            onChange={(e) => setSerieDescription(e.target.value)}
          />
        </div> */}

        <div className="form-control">
          <label>Choose Home</label>
          {/* <select
            type="dropdown"
            value={serieDescription}
            onChange={(e) => setSerieDescription(e.target.value)}
          /> */}
          <select name="series" id="" onChange={(e) => setSerieItem(+e.target.value)}>
          <option value="" disabled selected hidden>Choose Serie</option>
            {
              serieItems?.videos.map(item => {
                return (
                  <option key={item.id} selected={item.is_home} value={item?.id}>
                    {serieItems?.videos.length === 0 ? "Please add epsiode / item first" : `${item?.title}`}
                  </option>
                )
              })
            }
          </select>
        </div>
      </Modal>
    </div>
  );
}
