import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Paragraph } from "@shared/atoms/Typography/index";
import LanguagePickerPrototype from "../../shared/molecules/LanguagePickerPrototype";
import Image from "@shared/atoms/Image";
import Button from "@shared/atoms/Button/index";
import UpdateProfileModalPrototype from "../modals/UpdateProfileModalPrototype";
import UpdatePasswordModalPrototype from "../modals/UpdatePasswordModalPrototype";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getUserProfile,
  confirmAdultContent,
  toggleAdultContent,
} from "../../../../redux/actions/profileActions";
import { localStorageService } from "../../../services/localStorage.service";
import { logOut } from "../../../../redux/actions/userActions";
import { Context } from "@context/context";
// import { VIEWS_PROTOTYPE } from "@src/const/viewsPrototype";
import "./index.less";
// import prototypeCloseIcon from "../../../../assets/icons/closeIconPrototype.svg";
import ConfirmAdultContentModalPrototype from "@components/organisms/modals/ConfirmAdultContentModalPrototype";
import Switch from "@components/shared/atoms/Switch";
import debounce from "lodash.debounce";
import { PLAYLISTS_RESET } from "@redux/constants/playlistsConstants";

// @assets
import userIcon from "@assets/icons/users-icon.svg";
import choiceLogo from "@assets/icons/choice-icon-white.png";

const ProfileScreenPrototype = ({ redirectToWallet }) => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [showAdultContentErrorText, setShowAdultContentErrorText] =
    useState(true);

  const { 
    setConfirmAdultContentOtpCode, 
    confirmAdultContentOtpCode,
    setShowSubBarPrototype,
   } = useContext(Context);

  const { t } = useTranslation();

  const history = useHistory();
  const { profile } = useSelector((state) => state.userProfile);
  const adultConfirm = useSelector((state) => state.adultContentConfirm);

  const dispatch = useDispatch();

  const { error: adultConfirmError } = adultConfirm;

  const showUpdateProfileModal = () => {
    setShowProfileModal(true);
  };

  const showUpdatePasswordModal = () => {
    setShowPasswordModal(true);
  };

  useEffect(() => {
    //dont close modal if error
    if (!adultConfirmError) {
      setIsConfirmModalVisible(false);
    } else {
      setIsConfirmModalVisible(true);
    }
  }, [adultConfirmError]);

  const handleSwitchChange = (checked) => {
    const debouncedToggleAdultContent = debounce(() => {
      dispatch(toggleAdultContent());
    }, 0);

    if (checked) {
      debouncedToggleAdultContent();
      setIsConfirmModalVisible(true);
    } else {
      debouncedToggleAdultContent();
      dispatch(getUserProfile());
      setIsConfirmModalVisible(false);
    }
    dispatch(getUserProfile());
  };

  const handleCloseConfirmModal = () => {
    dispatch(confirmAdultContent(confirmAdultContentOtpCode));
    setIsConfirmModalVisible(true);
    setShowAdultContentErrorText(true);
    setConfirmAdultContentOtpCode("");
    if (!adultConfirmError) {
      dispatch(getUserProfile());
      setIsConfirmModalVisible(false);
    }
    dispatch(getUserProfile());
  };

  const cancelConfirmModalHandler = () => {
    setIsConfirmModalVisible(false);
    setConfirmAdultContentOtpCode("");
    setShowAdultContentErrorText(false);
  };

  const logOutHandler = () => {
    dispatch({ type: "USER_LOGOUT" });
    Promise.resolve(dispatch(logOut())).then(() => {
      dispatch({ type: PLAYLISTS_RESET });
      localStorageService().remove("access_token");
      localStorageService().remove("refresh_token");
      localStorageService().remove("heart_playlist_id");
      localStorageService().remove("globe_playlist_id");
      localStorageService().remove("heart_scroll_position");
      localStorageService().remove("globe_scroll_position");
      history.push("/");
      setShowSubBarPrototype(false);
    });
  };

  const isNotNull = (field) => {
    try {
      if (JSON.parse(field) === null) {
        return false;
      }
      return true;
    } catch (error) {
      return true;
    }
  };

  // const closeProfileHandler = () => {
  //   history.push("/");
  //   setShowSubBarPrototype(true);
  //   setCurrentViewPrototype(VIEWS_PROTOTYPE.playlists);
  // };

  return (
    <div className="profile-screen-prototype">
      <LanguagePickerPrototype />
      {/* <CloseModal className='close-btn'/> */}
      {/* <button className="close-btn" onClick={closeProfileHandler}>
        <img
          className="close-icon"
          preview={false}
          src={prototypeCloseIcon}
          alt="close-icon"
        />
      </button> */}

      <UpdateProfileModalPrototype
        show={showProfileModal}
        setShow={setShowProfileModal}
      />
      <UpdatePasswordModalPrototype
        show={showPasswordModal}
        setShow={setShowPasswordModal}
      />
      <div className="image-container">
        <Image className="logo-img" src={choiceLogo} alt="choice" />
      </div>
      <div className="image-container">
        <Image
          className="profile-img"
          src={profile?.data?.profile_picture || userIcon}
          alt="choice"
        />
      </div>
      {profile?.data && (
        <div className="profile-info-container">
          <Paragraph>
            {t("nickname")}:{" "}
            {isNotNull(profile?.data?.nickname) ? profile?.data?.nickname : ""}{" "}
          </Paragraph>
          <Paragraph>
            {t("first_name")}:{" "}
            {isNotNull(profile?.data?.firstname)
              ? profile?.data?.firstname
              : ""}{" "}
          </Paragraph>
          <Paragraph>
            {t("last_name")}:{" "}
            {isNotNull(profile?.data?.lastname) ? profile?.data?.lastname : ""}{" "}
          </Paragraph>
          <Paragraph>
            {t("email")}: {profile?.data?.email}{" "}
          </Paragraph>
          <Paragraph>
            {t("country_of_domicile")}:{" "}
            {isNotNull(profile?.data?.country) ? profile?.data?.country : ""}{" "}
          </Paragraph>
        </div>
      )}

      <div className="btns-container">
        <Button className="ant-btn-prototype" onClick={showUpdateProfileModal}>
          {t("edit_profile")}
        </Button>
        <Button className="ant-btn-prototype" onClick={showUpdatePasswordModal}>
          {t("change_password")}
        </Button>
        <Button className="ant-btn-prototype" onClick={redirectToWallet}>
          {t("wallet")}
        </Button>
        <Button className="ant-btn-prototype white" onClick={logOutHandler}>
          {t("log_out")}
        </Button>
      </div>

      <div className="adult-content-container">
        <span>{t("adult_content")}</span>
        <Switch
          onChange={handleSwitchChange}
          checked={Boolean(profile?.data.adult_content)}
        ></Switch>
      </div>

      <ConfirmAdultContentModalPrototype
        visible={isConfirmModalVisible}
        closeHandler={handleCloseConfirmModal}
        cancelConfirmModalHandler={cancelConfirmModalHandler}
        errorText={adultConfirmError?.data?.errors[0]}
        showAdultContentErrorText={showAdultContentErrorText}
      />

      <div className="terms-links-container">
        <Link to={"/cookies"}>Cookies</Link>
        <Link to={"/privacy"}>Privacy Policy</Link>
        <Link to={"/terms"}>Terms Of Use</Link>
      </div>
    </div>
  );
};

export default ProfileScreenPrototype;
