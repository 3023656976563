/**
 * @param {string} criteria - used for grouping criteria
 * @param {object[]} data
 * @param {function=} selector - select specific property from the current object
 * @returns {object} object
 * @example
 *  const data = [
 *    {foo: 1, bar: false},
 *    {foo: 2, bar: true},
 *    {foo: 3, bar: false},
 *    {foo: 1, bar: true}
 *  ];
 *  groupBy("foo", data, el => el.foo) => {
 *  1: [{foo: 1}, {foo: 1}],
 *  2: [{foo: 2}, {foo: 3}]
 * }
 */
function groupBy(criteria, data, selector) {
  if (!Array.isArray(data) || data.length === 0) return {};

  const grouped = {};

  for (let item of data) {
    if (!item[criteria])
      throw new Error(
        `key ${criteria} is not presented in ${JSON.stringify(item)}`
      );
    const key = item[criteria];
    grouped[key] = grouped[key] || [];
    if (selector) {
      grouped[key].push(selector(item));
    } else {
      grouped[key].push(item);
    }
  }

  return grouped;
}

export default groupBy;
