import {
  API_HEALTH_CHECK_REQUEST,
  API_HEALTH_CHECK_SUCCESS,
  API_HEALTH_CHECK_FAIL,
} from "../constants/apiHealthConstants";

export const apiHealthReducer = (state = {}, action) => {
  switch (action.type) {
    case API_HEALTH_CHECK_REQUEST:
      return { loading: true };
    case API_HEALTH_CHECK_SUCCESS:
      return { loading: false, apiStatus: action.payload };
    case API_HEALTH_CHECK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
