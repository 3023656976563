import React, { useState, useEffect, useContext } from "react";
import Button from "@shared/atoms/Button/index";
import Modal from "@shared/atoms/Modal/index";
import Form from "@shared/atoms/Form/index";
import Input from "@shared/atoms/Input/index";
import CloseModal from "../CloseModal/index";
import validateEmail from "@utils/validateEmail";

import { Context } from "@context/context";

import "./index.less";
import { useTranslation } from "react-i18next";

const EnterEmailModal = ({
    isModalVisible,
    handleClose,
    showConfirmEmailModal,
    formLayout,
}) => {
    const [disableBtn, setDisableBtn] = useState(true);

    const { userEmailHandler, userEmail } = useContext(Context);

    const { t } = useTranslation();

    useEffect(() => {
        setDisableBtn(true);
    }, []);

    useEffect(() => {
        let isValidEmail = validateEmail(userEmail);
        if (isValidEmail) {
            setDisableBtn(false);
        } else {
            setDisableBtn(true);
        }
    }, [userEmail]);

    return (
        <Modal
            title={t("reset_your_password")}
            closable={false}
            visible={isModalVisible}
            className="enter-email-modal"
            onCancel={handleClose}
            footer={[
                <Button
                    disabled={disableBtn}
                    type="primary"
                    key="back"
                    onClick={showConfirmEmailModal}
                    htmlType="submit"
                >
                    {t("send")}
                </Button>,
                <CloseModal handleClose={handleClose} />,
            ]}
        >
            <Form layout={formLayout}>
                <Form.Item label={t("email")}>
                    <Input
                        value={userEmail}
                        required={true}
                        onChange={(e) => userEmailHandler(e.target.value)}
                        placeholder={t("enter_your_email")}
                    />
                </Form.Item>
                <div className="email-label">
                    {t("reset_password_link_msg")}.
                </div>
            </Form>
        </Modal>
    );
};

export default EnterEmailModal;
