import { useContext } from "react";
import { Context } from "@context/context";
import Modal from "@shared/atoms/Modal/index";
import Image from "@shared/atoms/Image";
import CloseModal from "@shared/atoms/icons/CloseModal";
import Input from "@shared/atoms/Input";

import facebook from "../../../../../assets/icons/facebook-icon.svg";
import whatsApp from "../../../../../assets/icons/whatsApp-icon.svg";
import twitter from "../../../../../assets/icons/twitter-icon.svg";
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    EmailIcon,
} from "react-share";

import "./index.less";
import { useTranslation } from "react-i18next";

const { Search } = Input;

const ShareModal = () => {
    const { showShareModal, setShowShareModal, selectedPlaylist } =
        useContext(Context);
    const { t } = useTranslation();

    const onSearch = () => {
        const copyText = document.getElementById("copy");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    };

    const handleClose = () => {
        setShowShareModal(false);
    };
    return (
        <Modal
            className="share-modal"
            title={t("share")}
            closeIcon={<CloseModal />}
            onCancel={handleClose}
            visible={showShareModal}
            footer=""
        >
            <div className="social-icons-container">
                <FacebookShareButton
                    url={window.location.href}
                    quote={selectedPlaylist?.name}
                >
                    <Image src={facebook} alt="facebook" />
                </FacebookShareButton>
                <WhatsappShareButton
                    title={selectedPlaylist?.name}
                    url={window.location.href}
                >
                    <Image src={whatsApp} alt="whatsApp" />
                </WhatsappShareButton>
                <TwitterShareButton
                    title={selectedPlaylist?.name}
                    url={window.location.href}
                >
                    <Image src={twitter} alt="twitter" />
                </TwitterShareButton>
                <EmailShareButton
                    url={window.location.href}
                    subject={`Playlist: ${selectedPlaylist?.name}`}
                    body={"Choice TV: "}
                >
                    <EmailIcon size={48} round={true} />
                </EmailShareButton>
            </div>
            <p>{t("or_copy_link")}</p>
            <div className="copy-link-container">
                <Search
                    placeholder="https://docs.google.com/documewdljjwhkdjn…"
                    enterButton={t("copy")}
                    onSearch={onSearch}
                    value={window.location.href}
                    id="copy"
                />
            </div>
        </Modal>
    );
};

export default ShareModal;
