import React from "react";
import "./index.less";

const Box = ({ onClick, className = "", children }) => {
    return (
        <div onClick={onClick} className={`box ${className}`}>
            {children}
        </div>
    );
};

export default Box;
