import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  createRef,
} from "react";
import { useTranslation } from "react-i18next";
import { Context } from "@context/context";
import { useHistory, useLocation } from "react-router-dom";
import querystring from "query-string";
import { useSelector, useDispatch } from "react-redux";
import { getChannelById } from "../../../../redux/actions/channelActions";
import HeaderPrototype from "../HeaderPrototype";
import ChannelCard from "@shared/molecules/ChannelCard";
import VideoPlayerWrapper from "../VideoPlayerWrapper";
import VideoPlayer from "../../shared/atoms/VideoPlayer";
import ShareModalPrototype from "../modals/ShareModalPrototype";
import defaultImg from "@assets/images/default_img.png";
import ReportContentModalPrototype from "../modals/ReportContentModalPrototype";
import { Home } from "../../../pages/Home/index";
import SpinnerPrototype from "../../shared/atoms/SpinnerPrototype";
import SearchModalPrototype from "../modals/SearchModalPrototype";
import Slider from "react-slick";
import ScrollTop from "@components/shared/atoms/ScrollTop";
import { SampleNextArrow, SamplePrevtArrow } from "@components/shared/molecules/CustomSlickArrow";

import "./index.less";


const MobileChannelsPrototype = () => {
  const { search } = useLocation();

  const { t } = useTranslation();

  const parsedQueryString = querystring.parse(search);

  const [uniqueGroupsArr, setUniqueGroupsArr] = useState([]);
  const [groupedChannels, setGroupedChannels] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [channelCardRefs, setChannelCardRefs] = useState([]);
  const [reportType, setReportType] = useState("App//Channel");
  const [reportableId, setReportableId] = useState(parsedQueryString.channel);

  const dispatch = useDispatch();

  const videoWrapperRef = useRef(null);

  const history = useHistory();

  const {
    showTermsImagesPrototype,
    setShowTermsImagesPrototype,
    showChannelsPrototype,
    setShowChannelsPrototype,
    showVideoPlayerPrototype,
    setShowVideoPlayerPrototype,
    setShowShareModalPrototype,
    setShowReportContentModalPrototype,

  } = useContext(Context);

  const {
    currentChannelItems,
  } = useSelector((state) => state.currentChannelItems);

  const {
    channel,
    loading: channelByIdLoading,
  } = useSelector((state) => state.channelById);

  const uniqueGroupsHandler = useCallback(() => {
    if (currentChannelItems && parsedQueryString.channel) {
      let unique = [...new Set(currentChannelItems?.map((item) => item.group))];
      setUniqueGroupsArr(unique);
    }
  }, [currentChannelItems, parsedQueryString.channel]);

  const createGroupedChannels = useCallback(() => {
    let groupedChannelsArr = [];
    uniqueGroupsArr?.forEach((unique) => {
      let filterdChannels = currentChannelItems?.filter(
        (item) => item.group === unique
      );
      groupedChannelsArr.push(filterdChannels);
    });
    setGroupedChannels(groupedChannelsArr);
  }, [currentChannelItems, uniqueGroupsArr]);

  const videoItemRef = createRef();

  useEffect(() => {
    if (groupedChannels?.length && channel?.id) {
      setChannelCardRefs((channelCardRef) =>
        Array(groupedChannels?.length)
          .fill()
          .map((_, i) => {
            return channelCardRef[i] || createRef(channelCardRef[i]);
          })
      );
    }
  }, [groupedChannels.length, channel]);

  useEffect(() => {
    uniqueGroupsHandler();
  }, [uniqueGroupsHandler]);

  useEffect(() => {
    createGroupedChannels();
  }, [createGroupedChannels]);

  useEffect(() => {
    if (parsedQueryString.channel) {
      dispatch(getChannelById(parsedQueryString?.channel));
      setShowChannelsPrototype(true);
      setShowTermsImagesPrototype(false);
    }
  }, [
    dispatch,
    parsedQueryString.channel,
    setShowChannelsPrototype,
    setShowTermsImagesPrototype,
  ]);



  useEffect(() => {
    if (
      parsedQueryString.index &&
      groupedChannels &&
      channel &&
      channelCardRefs
    ) {

      channelCardRefs[parsedQueryString.index]?.current?.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, [groupedChannels, parsedQueryString, channel, channelCardRefs]);

  const playVideoHandler = (url) => {
    setShowChannelsPrototype(false);
    setShowVideoPlayerPrototype(true);
    setVideoUrl(url);
    videoWrapperRef.current.style.display = "block";
  };

  const shareChannelItemHandler = (channel, index) => {
    setShowShareModalPrototype(true);
    history.push(`channels?channel=${channel.channel_id}&index=${index}`);
  };

  const shareChannelHandler = (channel) => {
    setShowShareModalPrototype(true);
    history.push(`channels?channel=${channel.id}`);
  };

  const reportChannelItemHandler = (itemId) => {
    setReportType("App//ChannelItem");
    setReportableId(itemId);
  };

  return (
    <div className="channels-mobile-prototype">
      <ScrollTop />

      <ShareModalPrototype />
      <ReportContentModalPrototype
        reportableType={reportType}
        reportableId={reportableId}
      />
      <SearchModalPrototype />

      {showChannelsPrototype && (
        <>
          {channel && (
            <HeaderPrototype
              name={channel?.name}
              image={channel?.logoId}
              buttonText={channel?.is_followed ? t("subscribe") : t("unsubscribe")}
              shareHandler={() => shareChannelHandler(channel)}
              reportHandler={() => {
                setShowReportContentModalPrototype(true);
              }}
              isChannelsHeader={true}
              headerPositionStyle={{ position: "absolute" }}

            />
          )}

          {channelByIdLoading && <SpinnerPrototype />}
          {groupedChannels?.map((groupChannel, index) => {
            return (
              <div ref={channelCardRefs[index]} id={index}>
                <Slider
                  infinite={false}
                  nextArrow={ <SampleNextArrow /> }
                  prevArrow={ <SamplePrevtArrow /> }
                >
                  {groupChannel?.map((channel) => {
                    return (
                      <div>
                        <ChannelCard
                          onClick={() => playVideoHandler(channel.url)}
                          coverImg={
                            channel?.poster?.startsWith("http")
                              ? channel?.poster
                              : defaultImg
                          }
                          key={channel.id}
                          title={channel.title}
                          infoBoxSubtitle={channel.group}
                          shareChannelHandler={() =>
                            shareChannelItemHandler(channel, index)
                          }
                          reportChannelItemHandler={() =>
                            reportChannelItemHandler(channel.id)
                          }
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            );
          })}
        </>
      )}

      {showTermsImagesPrototype && <Home />}

      {showVideoPlayerPrototype && (
        <VideoPlayerWrapper
          setVideoUrl={setVideoUrl}
          videoUrl={videoUrl}
          ref={videoWrapperRef}
        >
          <VideoPlayer
            url={videoUrl}
            controls={true}
            playing={true}
            width={"100%"}
            ref={videoItemRef}
          />
        </VideoPlayerWrapper>
      )}
    </div>
  );
};
export default MobileChannelsPrototype;
