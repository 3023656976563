import { axiosInstance } from "../../modules/request/api";
import { CHOICE_URL } from "@modules/config";
import {
    SOCIAL_FOLLOW_REQUEST,
    SOCIAL_FOLLOW_SUCCESS,
    SOCIAL_FOLLOW_FAIL,
    SOCIAL_SHARE_REQUEST,
    SOCIAL_SHARE_SUCCESS,
    SOCIAL_SHARE_FAIL,
    SOCIAL_SHARE_DELETE_REQUEST,
    SOCIAL_SHARE_DELETE_SUCCESS,
    SOCIAL_SHARE_DELETE_FAIL,
    SOCIAL_LIKE_REQUEST,
    SOCIAL_LIKE_FAIL,
    SOCIAL_UNLIKE_REQUEST,
    SOCIAL_UNLIKE_SUCCESS,
    SOCIAL_UNLIKE_FAIL,
    SOCIAL_LIKE_SUCCESS,
    SOCIAL_SUGGESTED_PLAYLIST_REQUEST,
    SOCIAL_SUGGESTED_PLAYLIST_SUCCESS,
    SOCIAL_SUGGESTED_PLAYLIST_FAIL,
    SOCIAL_UNFOLLOW_FAIL,
    SOCIAL_UNFOLLOW_SUCCESS,
    SOCIAL_UNFOLLOW_REQUEST,
    SOCIAL_FOLLOWED_PLAYLISTS_REQUEST,
    SOCIAL_FOLLOWED_PLAYLISTS_SUCCESS,
    SOCIAL_FOLLOWED_PLAYLISTS_FAIL,
    SOCIAL_FOLLOWING_PLAYLISTS_REQUEST,
    SOCIAL_FOLLOWING_PLAYLISTS_SUCCESS,
    SOCIAL_FOLLOWING_PLAYLISTS_FAIL,
} from "../constants/socialConstants";

export const like = (likedType, likedId) => async (dispatch) => {
    try {
        dispatch({ type: SOCIAL_LIKE_REQUEST });
        const { data } = await axiosInstance().post(`${CHOICE_URL}/api/like`, {
            liked_type: likedType,
            liked_id: likedId,
        });
        dispatch({ type: SOCIAL_LIKE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SOCIAL_LIKE_FAIL, payload: error.response });
    }
};

export const unlike = (likedType, likedId) => async (dispatch) => {
    try {
        dispatch({ type: SOCIAL_UNLIKE_REQUEST });
        const { data } = await axiosInstance().delete(
            `${CHOICE_URL}/api/like`,
            {
                data: {
                    liked_type: likedType,
                    liked_id: likedId,
                },
            }
        );
        dispatch({ type: SOCIAL_UNLIKE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SOCIAL_UNLIKE_FAIL, payload: error.response });
    }
};

export const follow = (followingType, followingId) => async (dispatch) => {
    try {
        dispatch({ type: SOCIAL_FOLLOW_REQUEST });
        const { data } = await axiosInstance().post(
            `${CHOICE_URL}/api/follow`,
            { following_type: followingType, following_id: followingId }
        );
        dispatch({ type: SOCIAL_FOLLOW_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SOCIAL_FOLLOW_FAIL, payload: error.response });
    }
};

export const unfollow = (followingType, followingId) => async (dispatch) => {
    try {
        dispatch({ type: SOCIAL_UNFOLLOW_REQUEST });
        const { data } = await axiosInstance().delete(
            `${CHOICE_URL}/api/follow`,
            {
                data: {
                    following_type: followingType,
                    following_id: followingId,
                },
            }
        );
        dispatch({ type: SOCIAL_UNFOLLOW_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SOCIAL_UNFOLLOW_FAIL, payload: error.response });
    }
};

export const share = (sharedType, sharedId) => async (dispatch) => {
    try {
        dispatch({ type: SOCIAL_SHARE_REQUEST });
        const { data } = await axiosInstance().post(`${CHOICE_URL}/api/share`, {
            shared_type: sharedType,
            shared_id: sharedId,
        });
        dispatch({ type: SOCIAL_SHARE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SOCIAL_SHARE_FAIL, payload: error.response });
    }
};

export const shareDelete = (shareId) => async (dispatch) => {
    try {
        dispatch({ type: SOCIAL_SHARE_DELETE_REQUEST });
        const { data } = await axiosInstance().delete(
            `${CHOICE_URL}/api/share/${shareId}`
        );
        dispatch({ type: SOCIAL_SHARE_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SOCIAL_SHARE_DELETE_FAIL, payload: error.response });
    }
};

export const suggestedPlaylists = () => async (dispatch) => {
    try {
        dispatch({ type: SOCIAL_SUGGESTED_PLAYLIST_REQUEST });
        const { data } = await axiosInstance().get(
            `${CHOICE_URL}/api/playlists/suggestions`
        );
        dispatch({ type: SOCIAL_SUGGESTED_PLAYLIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: SOCIAL_SUGGESTED_PLAYLIST_FAIL,
            error: error.response,
        });
    }
};


export const socialFollowedPlaylists = () => async (dispatch) => {
    try {
        dispatch({ type: SOCIAL_FOLLOWED_PLAYLISTS_REQUEST });
        const { data } = await axiosInstance().get(
            `${CHOICE_URL}/api/user/followed`
        );
        dispatch({ type: SOCIAL_FOLLOWED_PLAYLISTS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: SOCIAL_FOLLOWED_PLAYLISTS_FAIL,
            error: error?.response,
        });
    }
};

export const socialFollowingPlaylists = () => async (dispatch) => {
    try {
        dispatch({ type: SOCIAL_FOLLOWING_PLAYLISTS_REQUEST });
        const { data } = await axiosInstance().get(
            `${CHOICE_URL}/api/user/following`
        );
        dispatch({ type: SOCIAL_FOLLOWING_PLAYLISTS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: SOCIAL_FOLLOWING_PLAYLISTS_FAIL,
            error: error.response,
        });
    }
};