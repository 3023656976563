import React from 'react'
import AntdCheckbox from 'antd/lib/checkbox/index'

const Checkbox = ({children, ...rest}) => {
  return (
    <AntdCheckbox {...rest} >
      {children}
    </AntdCheckbox>
  )
}

export default Checkbox
