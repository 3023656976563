// @components
import Image from "@components/shared/atoms/Image";
import List from "@components/shared/atoms/List";
import { Paragraph } from "@components/shared/atoms/Typography";

// @utils
import { shortenString } from "@utils/shortenString";

const StaticSubbarItem = ({
  title,
  image,
  itemClick,
  isVisible = true,
  svgIcon: SvgIcon,
  svgContainerStyles,
  contextHandler,
  closeContextMenu,
}) => {
  if (!isVisible) return <></>;

  return (
    <div className="subbar-item-container" onWheel={closeContextMenu}>
      <List.Item
        className="subbar-item"
        onClick={itemClick}
        {...contextHandler}
      >
        <div className="subbar-content-container">
          {SvgIcon ? (
            <SubbarSvgContainer svg={<SvgIcon />} styles={svgContainerStyles} />
          ) : (
            <Image className="subbar-item-img" src={image} alt="subbar item" />
          )}
          <Paragraph className="subbar-item-para">
            {shortenString(title, 15)}
          </Paragraph>
        </div>
      </List.Item>
    </div>
  );
};

export default StaticSubbarItem;

const SubbarSvgContainer = ({ svg, styles = {} }) => {
  return <div className="subbar-svg-container" style={styles}>{svg}</div>;
};
