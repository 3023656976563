import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { requestVanityPlaylist } from "@redux/actions/vanityActions";

export const useVanity = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const playlistUrl = location.pathname.replace("/", "").split("/")[0];
    setLoading(true);
    dispatch(requestVanityPlaylist(playlistUrl))
      .then(({ id }) => {
        history.push(`/my-playlists?playlist=${id}`);
      })
      .catch((error) => {
        history.push("/not-found");
      });
  }, [location.pathname, dispatch, history]);

  return [loading];
};
