const keyExists = (keys, object) => {
  if (typeof object !== "object" || Array.isArray(object) || object === null) {
    throw new Error("second argument must be an object");
  }
  if (Array.isArray(keys) && keys.length) {
    return keys.every((key) => object.hasOwnProperty(key));
  }

  return object.hasOwnProperty(keys);
};

export { keyExists };
