export const PLAYLISTS_REQUEST = "PLAYLISTS_REQUEST";
export const PLAYLISTS_SUCCESS = "PLAYLISTS_SUCCESS";
export const PLAYLISTS_FAIL = "PLAYLISTS_FAIL";
export const PLAYLISTS_RESET = "PLAYLISTS_RESET";

export const ARRANGE_NAVBAR_PLAYLISTS = "ARRANGE_NAVBAR_PLAYLISTS";
export const COPY_NAVBAR_PLAYLISTS = "COPY_NAVBAR_PLAYLISTS";
export const ARRANGE_ARCHIVE = "ARRANGE_ARCHIVE";
export const ARRANGE_OTHER_TO_ARCHIVE = "ARRANGE_OTHER_TO_ARCHIVE";
export const ARRANGE_ARCHIVE_TO_OTHER = "ARRANGE_ARCHIVE_TO_OTHER";
export const ARRANGE_GROUP = "ARRANGE_GROUP";
export const ARRANGE_GROUP_ITEMS = "ARRANGE_GROUP_ITEMS";
export const FOLLOWED_PLAYLISTS_ARRANGE_LOCAL = "FOLLOWED_PLAYLISTS_ARRANGE_LOCAL";
export const PLAYLISTS_ARRANGE_LOCAL = "PLAYLISTS_ARRANGE_LOCAL"
export const ADD_PLAYLIST_IN_GROUP = "ADD_PLAYLIST_IN_GROUP";
export const TAKE_OUT_PLAYLIST_FROM_GROUP = "TAKE_OUT_PLAYLIST_FROM_GROUP";
export const MOVE_PLAYLIST_FROM_GROUP_TO_PUBLIC =
  "MOVE_PLAYLIST_FROM_GROUP_TO_PUBLIC";

export const DEFAULT_PLAYLISTS_REQUEST = "DEFAULT_PLAYLISTS_REQUEST";
export const DEFAULT_PLAYLISTS_SUCCESS = "DEFAULT_PLAYLISTS_SUCCESS";
export const DEFAULT_PLAYLISTS_FAIL = "DEFAULT_PLAYLISTS_FAIL";

export const PLAYLIST_BY_ID_REQUEST = "PLAYLIST_BY_ID_REQUEST";
export const PLAYLIST_BY_ID_SUCCESS = "PLAYLIST_BY_ID_SUCCESS";
export const PLAYLIST_BY_ID_FAIL = "PLAYLIST_BY_ID_FAIL";
export const PLAYLIST_BY_ID_CLEAR = "PLAYLIST_BY_ID_CLEAR";
export const ARRANGE_PLAYLIST_BY_ID = "ARRANGE_PLAYLIST_BY_ID";
export const ARRANGE_SERIES_IN_GROUP = "ARRANGE_SERIES_IN_GROUP";

export const PLAYLIST_CREATE_REQUEST = "PLAYLIST_CREATE_REQUEST";
export const PLAYLIST_CREATE_SUCCESS = "PLAYLIST_CREATE_SUCCESS";
export const PLAYLIST_CREATE_FAIL = "PLAYLIST_CREATE_FAIL";
export const PLAYLIST_CREATE_RESET = "PLAYLIST_CREATE_RESET";

export const PLAYLIST_DELETE_REQUEST = "PLAYLIST_DELETE_REQUEST";
export const PLAYLIST_DELETE_SUCCESS = "PLAYLIST_DELETE_SUCCESS";
export const PLAYLIST_DELETE_FAIL = "PLAYLIST_DELETE_FAIL";

export const PLAYLIST_VIDEO_DELETE_REQUEST = "PLAYLIST_VIDEO_DELETE_REQUEST";
export const PLAYLIST_VIDEO_DELETE_SUCCESS = "PLAYLIST_VIDEO_DELETE_SUCCESS";
export const PLAYLIST_VIDEO_DELETE_FAIL = "PLAYLIST_VIDEO_DELETE_FAIL";

export const PLAYLIST_VIDEO_REORDER = "PLAYLIST_VIDEO_REORDER";
export const PLAYLIST_VIDEO_REORDER_REQUEST = "PLAYLIST_VIDEO_REORDER_REQUEST";
export const PLAYLIST_VIDEO_REORDER_SUCCESS = "PLAYLIST_VIDEO_REORDER_SUCCESS";
export const PLAYLIST_VIDEO_REORDER_FAIL = "PLAYLIST_VIDEO_REORDER_FAIL";

export const PLAYLIST_VIDEO_METADATA_REQUEST =
  "PLAYLIST_VIDEO_METADATA_REQUEST";
export const PLAYLIST_VIDEO_METADATA_SUCCESS =
  "PLAYLIST_VIDEO_METADATA_SUCCESS";
export const PLAYLIST_VIDEO_METADATA_FAIL = "PLAYLIST_VIDEO_METADATA_FAIL";

export const ADD_VIDEOS_TO_PLAYLIST_REQUEST = "ADD_VIDEOS_TO_PLAYLIST_REQUEST";
export const ADD_VIDEOS_TO_PLAYLIST_SUCCESS = "ADD_VIDEOS_TO_PLAYLIST_SUCCESS";
export const ADD_VIDEOS_TO_PLAYLIST_FAIL = "ADD_VIDEOS_TO_PLAYLIST_FAIL";
export const ADD_VIDEOS_TO_PLAYLIST_RESET = "ADD_VIDEOS_TO_PLAYLIST_RESET";

export const UPDATE_PLAYLIST_REQUEST = "UPDATE_PLAYLIST_REQUEST";
export const UPDATE_PLAYLIST_SUCCESS = "UPDATE_PLAYLIST_SUCCESS";
export const UPDATE_PLAYLIST_FAIL = "UPDATE_PLAYLIST_FAIL";
export const UPDATE_PLAYLIST_RESET = "UPDATE_PLAYLIST_RESET";

export const UPDATE_GROUP_REQUEST = "UPDATE_GROUP_REQUEST";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL";
export const UPDATE_GROUP_RESET = "UPDATE_GROUP_RESET";

export const UPDATE_SERIE_DETAILS_REQUEST = "UPDATE_SERIE_DETAILS_REQUEST";

export const DELETE_CURRENT_SERIE_IMAGE_REQUEST = 'DELETE_CURRENT_SERIE_IMAGE_REQUEST';
export const DELETE_CURRENT_SERIE_IMAGE_SUCCESS = 'DELETE_CURRENT_SERIE_IMAGE_SUCCESS';
export const DELETE_CURRENT_SERIE_IMAGE_FAIL = 'DELETE_CURRENT_SERIE_IMAGE_FAIL';

export const UPDATE_SERIE_DETAILS_SUCCESS = "UPDATE_SERIE_DETAILS_SUCCESS";
export const UPDATE_SERIE_DETAILS_FAIL = "UPDATE_SERIE_DETAILS_FAIL";

export const MOVE_PLAYLIST_REQUEST = "MOVE_PLAYLIST_REQUEST";
export const MOVE_PLAYLIST_SUCCESS = "MOVE_PLAYLIST_SUCCESS";
export const MOVE_PLAYLIST_FAIL = "MOVE_PLAYLIST_FAIL";

export const ADD_RELATED_VIDEO_REQUEST = "ADD_RELATED_VIDEO_REQUEST";
export const ADD_RELATED_VIDEO_SUCCESS = "ADD_RELATED_VIDEO_SUCCESS";
export const ADD_RELATED_VIDEO_FAIL = "ADD_RELATED_VIDEO_FAIL";

export const RELATED_VIDEOS_REQUEST = "RELATED_VIDEOS_REQUEST";
export const RELATED_VIDEOS_SUCCESS = "RELATED_VIDEOS_SUCCESS";
export const RELATED_VIDEOS_FAIL = "RELATED_VIDEOS_FAIL";
export const RELATED_VIDEOS_CLEAR = "RELATED_VIDEOS_CLEAR";

export const DELETE_RELATED_VIDEO_REQUEST = "DELETE_RELATED_VIDEO_REQUEST";
export const DELETE_RELATED_VIDEO_SUCCESS = "DELETE_RELATED_VIDEO_SUCCESS";
export const DELETE_RELATED_VIDEO_FAIL = "DELETE_RELATED_VIDEO_FAIL";

export const PLAYLIST_WITH_RELATED_VIDEOS_REQUEST =
  "PLAYLIST_WITH_RELATED_VIDEOS_REQUEST";

export const PLAYLIST_WITH_RELATED_VIDEOS_SUCCESS =
  "PLAYLIST_WITH_RELATED_VIDEOS_SUCCESS";

export const PLAYLIST_WITH_RELATED_VIDEOS_FAIL =
  "PLAYLIST_WITH_RELATED_VIDEOS_FAIL";

// update related video order
export const UPDATE_RELATED_VIDEO_ORDER = "UPDATE_RELATED_VIDEO_ORDER";
export const UPDATE_RELATED_VIDEO_ORDER_REQUEST =
  "UPDATE_RELATED_VIDEO_ORDER_REQUEST";
export const UPDATE_RELATED_VIDEO_ORDER_SUCCESS =
  "UPDATE_RELATED_VIDEO_ORDER_SUCCESS";
export const UPDATE_RELATED_VIDEO_ORDER_FAIL =
  "UPDATE_RELATED_VIDEO_ORDER_FAIL";

export const SEARCH_PLAYLIST_REQUEST = "SEARCH_PLAYLIST_REQUEST";
export const SEARCH_PLAYLIST_SUCCESS = "SEARCH_PLAYLIST_SUCCESS";
export const SEARCH_PLAYLIST_FAIL = "SEARCH_PLAYLIST_FAIL";

export const PLAYLIST_KEYWORDS_REQUEST = "PLAYLIST_KEYWORDS_REQUEST";
export const PLAYLIST_KEYWORDS_SUCCESS = "PLAYLIST_KEYWORDS_SUCCESS";
export const PLAYLIST_KEYWORDS_FAIL = "PLAYLIST_KEYWORDS_FAIL";

// reporting
export const PLAYLIST_REPORTS_REQUEST = "PLAYLIST_REPORTS_REQUEST";
export const PLAYLIST_REPORTS_SUCCESS = "PLAYLIST_REPORTS_SUCCESS";
export const PLAYLIST_REPORTS_FAIL = "PLAYLIST_REPORTS_FAIL";

export const PLAYLIST_REPORT_ADD_REQUEST = "PLAYLIST_REPORT_ADD_REQUEST";
export const PLAYLIST_REPORT_ADD_SUCCESS = "PLAYLIST_REPORT_ADD_SUCCESS";
export const PLAYLIST_REPORT_ADD_FAIL = "PLAYLIST_REPORT_ADD_FAIL";
export const PLAYLIST_REPORT_MODAL_CLOSE = "PLAYLIST_REPORT_MODAL_CLOSE";

export const PLAYLIST_STATUSES_REQUEST = "PLAYLIST_STATUSES_REQUEST";
export const PLAYLIST_STATUSES_SUCCESS = "PLAYLIST_STATUSES_SUCCESS";
export const PLAYLIST_STATUSES_FAIL = "PLAYLIST_STATUSES_FAIL";

export const PLAYLIST_VIDEO_UPDATE_REQUEST = "PLAYLIST_VIDEO_UPDATE_REQUEST";
export const PLAYLIST_VIDEO_UPDATE_SUCCESS = "PLAYLIST_VIDEO_UPDATE_SUCCESS";
export const PLAYLIST_VIDEO_UPDATE_FAIL = "PLAYLIST_VIDEO_UPDATE_FAIL";

export const SEARCH_MY_PLAYLISTS_REQUEST = "SEARCH_MY_PLAYLISTS_REQUEST";
export const SEARCH_MY_PLAYLISTS_SUCCESS = "SEARCH_MY_PLAYLISTS_SUCCESS";
export const SEARCH_MY_PLAYLISTS_FAIL = "SEARCH_MY_PLAYLISTS_FAIL";

export const DIRECT_SHARE_ADD_SERIE_REQUEST = "DIRECT_SHARE_ADD_SERIE_REQUEST";
export const DIRECT_SHARE_ADD_SERIE_SUCCESS = "DIRECT_SHARE_ADD_SERIE_SUCCESS";
export const DIRECT_SHARE_ADD_SERIE_FAIL = "DIRECT_SHARE_ADD_SERIE_FAIL";

export const DIRECT_SHARE_ADD_VIDEO_REQUEST = "DIRECT_SHARE_ADD_VIDEO_REQUEST";
export const DIRECT_SHARE_ADD_VIDEO_SUCCESS = "DIRECT_SHARE_ADD_VIDEO_SUCCESS";
export const DIRECT_SHARE_ADD_VIDEO_FAIL = "DIRECT_SHARE_ADD_VIDEO_FAIL";

export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAIL = "CREATE_GROUP_FAIL";
export const CREATE_GROUP_RESET = "CREATE_GROUP_RESET";

// new arrange

export const FOLLOWED_PLAYLISTS_ARRANGE_REQUEST =
  "FOLLOWED_PLAYLISTS_ARRANGE_REQUEST";
export const FOLLOWED_PLAYLISTS_ARRANGE_SUCCESS =
  "FOLLOWED_PLAYLISTS_ARRANGE_SUCCESS";
export const FOLLOWED_PLAYLISTS_ARRANGE_FAIL =
  "FOLLOWED_PLAYLISTS_ARRANGE_FAIL";

export const PLAYLISTS_ARRANGE_REQUEST = "PLAYLISTS_ARRANGE_REQUEST";
export const PLAYLISTS_ARRANGE_SUCCESS = "PLAYLISTS_ARRANGE_SUCCESS";
export const PLAYLISTS_ARRANGE_FAIL = "PLAYLISTS_ARRANGE_FAIL";


export const NOT_FOLLOWED_PLAYLIST_HISTORY_REQUEST = "NOT_FOLLOWED_PLAYLIST_HISTORY_REQUEST"
export const NOT_FOLLOWED_PLAYLIST_HISTORY_SUCCESS = "NOT_FOLLOWED_PLAYLIST_HISTORY_SUCCESS"
export const NOT_FOLLOWED_PLAYLIST_HISTORY_FAIL = "NOT_FOLLOWED_PLAYLIST_HISTORY_FAIL"