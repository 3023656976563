import React, { useCallback, useContext, useEffect, useState } from "react";
import Modal from "@shared/atoms/Modal/index";
import Input from "@shared/atoms/Input";
import Button from "@shared/atoms/Button";
import { Paragraph } from "@shared/atoms/Typography/index";
import { useTranslation } from "react-i18next";
import { Context } from "@context/context";
import {
  clearSubscribedTireMessage,
  getTiers,
  subscribeTier,
  validateCouponCode,
} from "@redux/actions/paymentActions";
import { useDispatch, useSelector } from "react-redux";
import { debounce, isObject } from "lodash";
import Loader from "@components/shared/atoms/Loader";
import { COUPON_CODE_RESET } from "@redux/constants/paymentConstants";

import "./index.less";

const TierBuyModalPrototype = () => {
  const { t } = useTranslation();

  const [couponCode, setCouponCode] = useState("");

  const {
    showTierBuyModalPrototype,
    setShowTierBuyModalPrototype,
    tierPricePrototype,
    tierPrototype,
    setTierPrototype,
  } = useContext(Context);

  const dispatch = useDispatch();

  const { data: tierData } = useSelector((state) => state.tiers);

  const {
    data: couponCodeData,
    error: couponCodeError,
    loading: couponCodeLoading,
  } = useSelector((state) => state.couponCode);

  const { message: tierSubscribeSuccess, error: tierSubscribeError } =
    useSelector((state) => state.tierSubscribe);

  const closeHandler = useCallback(() => {
    setShowTierBuyModalPrototype(false);
    setCouponCode("");
    dispatch(clearSubscribedTireMessage());
    dispatch({ type: COUPON_CODE_RESET });
  }, [setShowTierBuyModalPrototype, dispatch]);

  useEffect(() => {
    if (tierSubscribeSuccess) {
      closeHandler();
    }
  }, [closeHandler, tierSubscribeSuccess]);

  const validateCouponCodeHandler = debounce((e) => {
    dispatch(validateCouponCode(e.target.value, tierPrototype.id));
    setCouponCode(e.target.value);
  }, 400);

  const subscribeTierHandler = () => {
    Promise.resolve(dispatch(subscribeTier(tierPrototype.id, couponCode))).then(
      () => {
        dispatch(getTiers());
      }
    );
  };

  return (
    <Modal
      visible={showTierBuyModalPrototype}
      className="modal-basic-prototype tier-buy-modal-prototype"
      mask={true}
      footer={<></>}
      closable={true}
      destroyOnClose={true}
      onCancel={() => {
        closeHandler();
      }}
    >
      {couponCodeLoading && <Loader />}
      {couponCodeError && (
        <Paragraph className="msg error-msg">
          {couponCodeError?.data?.errors?.coupon_code[0]}
        </Paragraph>
      )}
      {tierSubscribeError && tierSubscribeError?.data?.message && (
        <Paragraph className="msg error-msg">
          {tierSubscribeError?.data?.message[0]}
        </Paragraph>
      )}
      {tierSubscribeError?.data && !isObject(tierSubscribeError.data) && (
        <Paragraph className="msg error-msg">
          {tierSubscribeError.data}
        </Paragraph>
      )}
      <div className="content-container">
        <Paragraph className="discount-text">
          {t("please_enter_your_discount_code")}
        </Paragraph>
        <Paragraph className="price">
          €{couponCodeData?.tier_price || tierPrototype?.price}
        </Paragraph>
      </div>
      <div>
        <Input
          onChange={validateCouponCodeHandler}
          className="coupon-code-input"
        />
      </div>
      <div className="btn-container">
        <Button onClick={subscribeTierHandler} className="confirm-btn">
          {t("confirm")}
        </Button>
      </div>
    </Modal>
  );
};

export default TierBuyModalPrototype;
