import React from "react";
import "./index.less";

const Report = () => {
    return (
        <svg
            className="report-icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <defs>
                <clipPath id="a">
                    <rect
                        className="a"
                        width="24"
                        height="24"
                        transform="translate(373 714)"
                    />
                </clipPath>
            </defs>
            <g className="b" transform="translate(-373 -714)">
                <path
                    className="a"
                    d="M.909,21.818h20l-10-20ZM11.818,18.66H10V16.555h1.818Zm0-4.211H10V10.239h1.818Z"
                    transform="translate(374.091 714.182)"
                />
            </g>
        </svg>
    );
};

export default Report;
