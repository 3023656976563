import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Layout from "antd/lib/layout/layout";
import { Context } from "@context/context";

import "./index.less";
import { useTranslation } from "react-i18next";

const CookiesContent = ({ shouldCloseModal }) => {
  const { setShowCookiesModalPrototype } = useContext(Context);
  const { t } = useTranslation(); 

  const history = useHistory();

  const goBackHandler = () => {
    if (shouldCloseModal) {
      setShowCookiesModalPrototype(false)
    } else {
      history.push("/settings");
    }
  };

  return (
    <Layout className={"mobile-layout-prototype bg-black"}>
      <div
        className="privacy-container-prototype"
        style={{ margin: "1.5rem", padding: "1rem" }}
      >
        <span className="privacy-policy-secondary-title">
          <button className="back-button" onClick={goBackHandler}>
            <span>{t("just_back")}</span>
          </button>
        </span>
        <div className="privacy-policy-secondary-title flex justify-between align-center">
          <span>Cookie policy </span>
          <span className="span-small text-underline">Version: August 2021</span>
        </div>

        <div className="privacy-policy-text-block">
          The Choice Platform is accessible from the webpage "watch.choice.be"
          and is operated and managed by Choice NV, a limited liability company
          incorporated under the laws of Belgium, registered at the Crossroads
          Bank for Enterprises under the number 0748.659.757 and with its
          registered office at Jachthoorn 5, 3210 Lubbeek, Belgium or by any of
          its affiliates (jointly <b> "Choice"</b>,<b>"we"</b> or <b>"us"</b>).
          We and the providers of Third Party Services, as defined in the Choice
          Platform's terms of use, use cookies and other technologies (such as
          web beacons) to distinguish you from other users, to make the Choice
          Platform easier to use and to help improving the Choice Platform and
          tailor content to you. The Policy is intended to inform you about what
          cookies are, what the cookies we use, are and how to option, manage or
          delete them. Prior to the use of Third-Party Services, you shall be
          deemed to have accepted the cookie policy of the provider of
          Third-Party Services as applicable to such Third-Party Services. This
          policy shall not apply to, and Choice shall not be liable for, any
          cookies or other technologies (such as web beacons) of providers of
          Third-Party Services.
          <div className="privacy-policy-text-block">
            Your acceptance to this policy is deemed to occur if you continue
            using the Choice Platform. If you do not agree with our cookies
            policy, you shall stop using the Choice Platform immediately. This
            policy only applies to our use of cookies. Please read our privacy
            policy accessible from http://my.choice.be/privacy-policy to
            understand how we process your personal data and the rights that you
            have concerning such processing.
          </div>
          <div className="section">
            <h2 className="section-header privacy-policy-secondary-title">
              <span className="count">1.</span>
              What are cookies?
            </h2>
            <div className="section-content">
              <p>
                A cookie is a small text file which is sent from our web server,
                and placed on your device (desktop, laptop, mobile device, etc.)
                when you browse the Choice Platform and use our services. They
                hold only a certain amount of data and allow the web server to
                recognise your device's browser and respond accordingly.
              </p>
              <p>
                We use other technologies such as browser storage and plugins
                (e.g., HTML5, IndexedDB, and WebSQL). Like cookies, these other
                technologies may store small amounts of data on your device. Web
                beacons (also known as clear gifs or pixel tags) often work in
                conjunction with cookies. In many cases, declining cookies will
                impair the effectiveness of web beacons associated with those
                cookies.
              </p>
              <p>
                If you use the Choice Platform [or the Choice app] on a mobile
                device, tablet, or streaming media device, we may collect a
                resettable device identifier from your device. Resettable device
                identifiers (also known as advertising identifiers) are similar
                to cookies and are found on many mobile devices and tablets (for
                example, the "Identifier for Advertisers" (or IDFA) on Apple iOS
                devices and the "Google Advertising ID" on Android devices), and
                certain streaming media devices. Like cookies, resettable device
                identifiers are used to make online advertising more relevant
                and for analytics and optimisation purposes.
              </p>
              <p className="mb-1">
                We use these types of technologies for various reasons, like
                making it easy to access our services by remembering you when
                you return; to provide, analyse, understand and enhance the use
                of our services; to enforce our terms, prevent fraud; to improve
                site performance, monitor visitor traffic and actions on our
                site; and to deliver and tailor our marketing or advertising,
                and to understand interactions with our emails, marketing, and
                online ads on third party sites.
              </p>
              <div className="list">
                <p className="list-head">
                  Below is a list of the types of cookies that we would like to
                  use on the Choice Platform:
                </p>
                <ul className="list-content">
                  <li>
                    <span>(a)</span>
                    <b>Strictly necessary cookies:</b> These cookies are
                    necessary for the Choice Platform to work and enable you to
                    move around the Choice Platform and use its services and
                    features. They include, for instance, cookies that enable
                    you to log into secure areas of the Choice Platform, to
                    authenticate and identify the users. They also help us to
                    enforce the Choice Platform's terms of use, prevent fraud
                    and maintain the security of our services.
                  </li>
                  <li>
                    <span>(b)</span>
                    <b>Analytical/performance cookies: </b>
                    analytical cookies are used to gather statistics about the
                    use of the Choice Platform in order to improve the
                    performance and design of our services. For these
                    purposes, analytical cookies collect information about
                    your device type, operating system type, browser type,
                    domain, other system settings, IP address, referring URLs,
                    information on actions taken on the Choice Platform and
                    the dates and times of your visits, as well as the country
                    and time zone in which your device is located. These
                    cookies are provided by our third-party analytics tool
                    provider and the information obtained through these
                    cookies will be disclosed to, or collected directly by,
                    this third-party service provider. For more information
                    about cookie and information practices (including the
                    types of cookies used and their expiration date), please
                    visit the link below:
                  </li>
                  <li>
                    <span>(c)</span>
                    <b> Functional cookies:</b> functional cookies allow the
                    Choice Platform to help maintain your session and remember
                    your preferences and prevent you from needing to re-enter
                    information you previously provided (for example, during
                    user sign up) in order to provide functionality for your
                    benefit. We also use these cookies to collect information
                    (such as popular pages, conversion rates, viewing patterns,
                    click-through and other information) about your use of the
                    Choice Platform and our services so that we can enhance and
                    personalise the Choice Platform and services and conduct
                    market research. Deletion of these types of cookies may
                    result in limited functionality of the Choice Platform and
                    our services.
                  </li>
                  <li>
                    <span>(d)</span>
                    <b>Targeting cookies:</b> targeting cookies[, advertising
                    cookies and resettable device identifiers] record your visit
                    to the Choice Platform, the pages you have visited, the
                    links you have followed [and your visit to other websites
                    and apps, your response to ads and emails, and to deliver
                    ads that are more relevant to you and for analytics and
                    optimisation purposes]. We will use this information to make
                    the Choice Platform and the advertising displayed on it more
                    relevant to your interests. We may also share this
                    information with third parties for this purpose.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="section">
            <h2 className="section-header  privacy-policy-secondary-title">
              <span className="count">2.</span>
              How can you manage cookies?
            </h2>
            <div className="section-content">
              <p>
                You can control which types of cookies you want to be stored on
                your device as well as other types of online tracking (including
                the collection of information by third parties about your online
                activities over time and across third-party web sites or online
                services for online interest based advertising) via our cookies
                preference centre or through the settings in your browser.
              </p>

              <div className="list">
                <p className="list-head">
                  Follow these links to learn how to set your browser:
                </p>
                <ul className="list-content">
                  <li>
                    <span>(a) {" "}</span>
                    <a
                      href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Internet Explorer and Edge
                    </a>
                  </li>
                  <li>
                    <span>(b) {" "}</span>
                    <a
                      href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Safari
                    </a>
                  </li>
                  <li>
                    <span>(c) {" "}</span>
                    <a
                      href="https://support.google.com/chrome/answer/95647?hl=en-GB&amp%3Bamp%3Bhlrm=en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Chrome
                    </a>
                  </li>
                  <li>
                    <span>(d) {" "}</span>
                    <a
                      href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Firefox
                    </a>
                  </li>
                  <li>
                    <span>(e) {" "}</span>
                    <a
                      href="https://help.opera.com/en/latest/web-preferences/#cookies"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Opera
                    </a>
                  </li>
                </ul>
              </div>
              <p>
                Please note that if you refuse, block or disable functional,
                analytical, and/or strictly necessary cookies, you may not be
                able to access all or parts of the Choice Platform.
              </p>
              <p>
                You may also opt out of interest-based ads from Choice in
                connection with a resettable device identifier on a mobile
                device, tablet, or streaming media device , please configure the
                appropriate setting on your device (usually found under
                "privacy" or "ads" in your device's settings). You may still see
                Choice ads on your device, but they will not be tailored to your
                likely interests.
              </p>
              <p>
                There are a number of ways to exercise choice regarding
                technologies that are similar to cookies, such as browser
                storage and plugins (e.g., HTML5, IndexedDB, and WebSQL). For
                example, many popular browsers provide the ability to clear
                browser storage, commonly in the settings or preferences area;
                see your browser's help function or support area to learn more.
                Other technologies, such as Silverlight storage, may be cleared
                from within the application.
              </p>
            </div>
          </div>
          <div className="section">
            <h2 className="section-header  privacy-policy-secondary-title">
              <span className="count">3.</span>
              Which specific cookies does the Choice platform use that (could)
              collect personal data?
            </h2>
            <div className="section-content border ml-0">
              <table>
                <tr>
                  <th align="left">Name</th>
                  <th align="left">Organization</th>
                  <th align="left">Function</th>
                  <th align="left">Expires</th>
                </tr>
                <tr>
                  <td>_pk_ses.6.46d9 1 </td>
                  <td>Matomo</td>
                  <td>
                    short lived cookies used to temporarily store data for the
                    visit
                  </td>
                  <td>30 minutes</td>
                </tr>
                <tr>
                  <td>_pk_id.6.46d9 71b6cedfc82a9b21.1627556919</td>
                  <td>Matomo</td>
                  <td>
                    used to store a few details about the user such as the
                    unique visitor ID
                  </td>
                  <td>13 months</td>
                </tr>
                <tr>
                  <td>_ga GA1.1.1346351084.1627590460</td>
                  <td>Google</td>
                  <td>used to distinguish users.</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>_ga_Q15PZ8CQRJ </td>
                  <td>Google</td>
                  <td>Used to persist session rate</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>_ga_WBR4JE5NYH</td>
                  <td>Google</td>
                  <td>Used to persist session state.</td>
                  <td>2 years</td>
                </tr>
              </table>
            </div>
          </div>
          <div className="section">
            <h2 className="section-header  privacy-policy-secondary-title">
              <span className="count">4.</span>
              Change to this cookies policy
            </h2>
            <div className="section-content">
              <p>
                We can change this cookie policy at any time. You will be
                informed of any changes upon your next visit to the Choice
                Platform. Such changes become binding upon your first visit to
                the Choice Platform after the changes have been implemented.
                Therefore, we recommend that you consult this page regularly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CookiesContent;
