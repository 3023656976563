import React, { useContext, useState } from "react";
import SubBarItemPrototype from "@components/shared/molecules/listItems/SubBarItemPrototype";
import List from "@shared/atoms/List/index";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuthorizedSearch,
  getUnauthorizedSearch,
  selectSearchHistory,
} from "@redux/actions/searchActions";
import searchIcon from "@assets/icons/search-icon.svg";
import { Context } from "@context/context";
import { localStorageService } from "@modules/services/localStorage.service";
// import { useHistory } from "react-router-dom";

const SearchListPrototype = ({ type = "followed" }) => {
  // const history = useHistory();
  const { data: searchHistory } = useSelector(selectSearchHistory);

  const dispatch = useDispatch();
  const isLoggedIn = !!localStorageService().get("access_token");

  const { setShowSearchModalPrototype, setSearchedWordPrototype } =
    useContext(Context);

  const openSearchModalWithSearchWord = (searchWord) => {
    setShowSearchModalPrototype(true);
    setSearchedWordPrototype(searchWord);

    if (searchWord) {
      isLoggedIn
        ? dispatch(getAuthorizedSearch(searchWord))
        : dispatch(getUnauthorizedSearch(searchWord));
    }
  };

  // const homePositionClickHandler = () => {
  //   history.push("/search");
  // };

  return (
    <List className="search-list-prototype">
      {type === "followed"
        ? searchHistory?.followed?.map((followedSearch) => (
            <SubBarItemPrototype
              key={followedSearch.id}
              searchItemId={followedSearch.id}
              itemClick={() => {
                openSearchModalWithSearchWord(followedSearch.search);
              }}
              content={followedSearch.search}
              image={searchIcon}
              item={followedSearch}
            />
          ))
        : searchHistory?.unfollowed?.map((unfollowedSearch) => (
            <SubBarItemPrototype
              key={unfollowedSearch.id}
              searchItemId={unfollowedSearch.id}
              itemClick={() => {
                openSearchModalWithSearchWord(unfollowedSearch.search);
              }}
              content={unfollowedSearch.search}
              image={searchIcon}
              item={unfollowedSearch}
            />
          ))}
    </List>
  );
};

export default SearchListPrototype;
