// import { Context } from "@context/context";
import Modal from "@shared/atoms/Modal/index";
import { Paragraph } from "@shared/atoms/Typography/index";

const PopupWithCustomFooter = ({
    text, onCansel, isVissible, footer,
}) => {
  
  return (
    <Modal
      visible={isVissible}
      className="modal-basic-prototype simple-popup"
      mask={true}
      closable={true}
      onCancel={onCansel}
      footer={footer}
    >
      <div>
        <Paragraph>
          {text}
        </Paragraph>
      </div>
    </Modal>
  );
};

export default PopupWithCustomFooter;
