import "./index.less";
const Dropdown = ({ state,dark }) => {
    const colorStyle = {
        fill:"#162D55"
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={`dropdown-icon ${state ? "reverse" : ""}`}
        >
            <defs></defs>
            <g transform="translate(-16317 9375)">
                <rect
                    className="a"
                    width="24"
                    height="24"
                    transform="translate(16317 -9375)"
                />
                <g transform="translate(16337 -9368) rotate(90)">
                    <path
                        className="b"
                        style={dark && colorStyle}
                        d="M7.948,15.674.243,8.543a.727.727,0,0,1,0-1.086L7.948.326a1.273,1.273,0,0,1,1.7,0,1.056,1.056,0,0,1,0,1.573L3.058,8l6.591,6.1a1.057,1.057,0,0,1,0,1.574,1.273,1.273,0,0,1-1.7,0"
                        transform="translate(10 16) rotate(180)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Dropdown;
