import { useState, useContext, useEffect } from "react";
import { Context } from "@context/context";
import { localStorageService } from "../../../services/localStorage.service";
import Modal from "../../shared/atoms/Modal";
import { selectCurrentUser } from "@redux/reducers/profileReducers";
import LoginFormPrototype from "../forms/LoginFormPrototype";
import RegisterFormPrototype from "../forms/RegisterFormPrototype";
import Button from "../../shared/atoms/Button";
import UsersIconFillPrototype from "@assets/icons/users-icon.svg";
import CheckEmailModalPrototype from "@components/organisms/modals/CheckEmailModalPrototype";
import { formTypeAuthPrototype } from "../../../../context/context";
import ProfileScreenPrototype from "../ProfileScreenPrototype";
import querystring from "querystring";
import { useHistory, useLocation, useParams } from "react-router-dom";
import WalletPrototype from "../WalletPrototype";
import { useDispatch, useSelector } from "react-redux";
import {
  USER_REGISTER_FINALIZE_RESET,
  USER_REGISTER_ONLY_EMAIL_RESET,
} from "@redux/constants/userConstants";
import { useTranslation } from "react-i18next";
import ForgetPasswordModalPrototype from "../modals/ForgetPasswordModalPrototype";
import { Home } from "@modules/pages";
import HeaderPrototype from "../HeaderPrototype";
import useToggleWelcomeMsg from "hooks/useToggleWelcomeMsg";

import "./index.less";

function SettingsPrototype() {
  const isLoggedIn = localStorageService().get("access_token");

  const params = useParams();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { search, pathname } = useLocation();
  const history = useHistory();

  const parsedQueryString = querystring.parse(search);

  const teamId = pathname.split("/")[2];
  const userId = pathname.split("/")[3];
  const teamName = pathname.split("/")[4];
  const adminName = pathname.split("/")[5];

  const [isModalOpened, setIsModalOpened] = useState(true);
  const [isWalletScreen, setIsWalletScreen] = useState(false);
  const [isHomeScreen, setIsHomeScreen] = useState(false);
  const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false);

  const {
    showCheckEmailModalPrototype,
    setModalTypeAuthPrototype,
    modalTypeAuthPrototype,
    setShowHomeContent,
    showTermsImagesPrototype
  } = useContext(Context);

  const currentUser = useSelector(selectCurrentUser);

  const redirectToWallet = () => {
    history.push({ search: `?view=wallet` });
  };

  const { toggleWelcome } = useToggleWelcomeMsg("hide_user_welcome");

  useEffect(() => {
    if (teamId && adminName && userId && teamName) {
      localStorageService().set("teamId", teamId);
      localStorageService().set("userId", userId);
      localStorageService().set("teamName", teamName);
      localStorageService().set("adminName", adminName);
    }
  }, [teamId, userId, teamName, adminName]);

  useEffect(() => {
    if (params.token && params.email) {
      setShowForgetPasswordModal(true);
      setIsModalOpened(false);
    }
  }, [params]);

  useEffect(() => {
    if (!localStorageService().get("access_token")) {
      setShowHomeContent(true);
    }

    return () => setShowHomeContent(false);
  }, [setShowHomeContent]);

  useEffect(() => {
    if (parsedQueryString["?view"] === "wallet") {
      setIsWalletScreen(true);
    } else {
      setIsWalletScreen(false);
    }

    if (parsedQueryString["?view"] === "home") {
      setIsHomeScreen(true);
    } else {
      setIsHomeScreen(false);
    }
  }, [parsedQueryString]);

  useEffect(() => {
    if (window.location.search) {
      if (window.location.search?.split("&")[1]?.split("=")[0] === "token") {
        setModalTypeAuthPrototype(formTypeAuthPrototype.register);
      }
    }
  }, [setModalTypeAuthPrototype]);

  if (isHomeScreen) {
    return (
      <>
        {showTermsImagesPrototype && (
          <HeaderPrototype
            name={"settings"}
            image={UsersIconFillPrototype}
            toggleWelcomeMessage={toggleWelcome}
            headerPositionStyle={{ position: "absolute" }}
            isSettingsHeader={true}
          />
        )}
        <Home />
      </>
    );
  }

  if (isWalletScreen) {
    return <WalletPrototype />;
  }

  if (isLoggedIn) {
    return <ProfileScreenPrototype redirectToWallet={redirectToWallet} />;
  }

  const closeModal = () => {
    setModalTypeAuthPrototype(formTypeAuthPrototype.empty);
    setIsModalOpened(false);
    dispatch({ type: USER_REGISTER_FINALIZE_RESET });
    if (currentUser === null) {
      history.push("/");
    } else {
      history.push("/my-playlists");
    }
  };

  const registerBtnClickHandler = () => {
    setModalTypeAuthPrototype(formTypeAuthPrototype.register);
    dispatch({ type: USER_REGISTER_ONLY_EMAIL_RESET });
  };

  // User is not logged in
  const optionButtons = (
    <div className="auth-buttons-prototype">
      <h1>{t("log_in")}</h1>
      <span className="auth-text">{t("login_title_small")}</span>
      <h3>{t("account")}:</h3>
      <Button
        onClick={() => setModalTypeAuthPrototype(formTypeAuthPrototype.login)}
      >
        {t("log_in")}
      </Button>
      <Button onClick={registerBtnClickHandler}>{t("register")}</Button>
    </div>
  );

  return (
    <>
      <ForgetPasswordModalPrototype
        show={showForgetPasswordModal}
        setShow={setShowForgetPasswordModal}
      />
      {/* {showHomeContent && <HomeContentPrototype />} */}
      <Modal
        className="ant-modal-prototype setting-modal"
        visible={isModalOpened}
        title={modalTypeAuthPrototype === "Log in" && t("log_in")}
        closable={false}
        onCancel={closeModal}
        footer={<></>}
      >
        <div className="settings-prototype">
          {modalTypeAuthPrototype === formTypeAuthPrototype.empty &&
            optionButtons}
          {(modalTypeAuthPrototype === formTypeAuthPrototype.login ||
            modalTypeAuthPrototype ===
              formTypeAuthPrototype.forgotPassword) && (
            <LoginFormPrototype closeModal={closeModal} />
          )}
          {modalTypeAuthPrototype === formTypeAuthPrototype.register && (
            <RegisterFormPrototype closeModal={closeModal} />
          )}
          <CheckEmailModalPrototype show={showCheckEmailModalPrototype} />
        </div>
      </Modal>
    </>
  );
}

export default SettingsPrototype;
