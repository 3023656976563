import React, { useContext } from "react";
import { Context } from "@context/context";
import { executeScrollToElement } from "@utils/executeScrollToElement";
import { useHistory } from "react-router-dom";

import "./index.less";
import { useTranslation } from "react-i18next";

const BackToHome = ({ goBack, searchRef, page }) => {
    const { favoritePlaylistRef, setMobileHeaderText } = useContext(Context);
    const history = useHistory();
    const { t } = useTranslation();

    const goBackHandler = () => {
        if (page === "playlists") {
            if (goBack) {
                history.push("/playlists");
                setMobileHeaderText(`Playlists`);
            } else {
                executeScrollToElement(favoritePlaylistRef);
            }
        }

        if (page === "following") {
            if (goBack) {
                history.push("/following");
            } else {
                executeScrollToElement(searchRef);
            }
        }
    };

    return (
        <div onClick={goBackHandler} className="back-to-home">
            <span>{t("back_to_home")}</span>
        </div>
    );
};

export default BackToHome;
