import React, { useState, useContext, useEffect, useCallback } from "react";
import { Context } from "@context/context";
import { useDispatch, useSelector } from "react-redux";
import {
    getChannelSuggestions,
    searchChannels,
} from "@redux/actions/channelActions";
import {getPlaylistSuggestions} from "../../../../../redux/actions/playlistsActions";
import { searchPlaylists } from "@redux/actions/playlistsActions";
import { executeScrollToElement } from "@utils/executeScrollToElement";
import useDetectMobile from "@src/hooks/useDetectMobile";
import useAdditionalClassesOnMobile from "@src/hooks/useAdditionalClassesOnMobile";
import AutoComplete from "@shared/atoms/AutoComplete";
import Input from "@shared/atoms/Input";

import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";
import { PLAYLIST_KEYWORDS_SUCCESS } from "../../../../../redux/constants/playlistsConstants";
import { CHANNEL_KEYWORDS_SUCCESS } from "../../../../../redux/constants/channelConstants";
// import clearInputIcon from "../../../../../assets/icons/clear_input.svg";
import "./index.less";

const ChannelSearch = ({
    className = "",
    tvChannelsSearch = false,
    playlistsSearch = false,
    // showClearIcon = false,
    placeholder,
    searchInputClass=""
}, ref) => {
    const { t } = useTranslation();

    const [searchOptions, setSearchOptions] = useState([]);
    const [showAutoComplete, setShowAutoComplete] = useState(false);
    // const [searchTerm, setSearchTerm] = useState("");
    const {
        searchingChannel,
        setSearchingChannel,
        setSearchedChannlesLength,
        setSearchedPackagesLength,
        searchContainerRef,
        setSearchingPlaylists,
    } = useContext(Context);
    const isMobile = useDetectMobile();

    const dispatch = useDispatch();

    const {
        // loading,
        suggestions,
    } = useSelector((state) => state.channelKeywords);

    const {suggestions: playlistSuggestions} = useSelector(state => state.playlistKeywords);
    let mobileClasses = useAdditionalClassesOnMobile(
        "search-channels-input-mobile"
    );

    useEffect(() => {
        executeScrollToElement(searchContainerRef);
    }, [searchContainerRef]);

    const suggestionsOptionsHandler = useCallback(() => {
        let suggs = [];
        if (suggestions) {
            suggs = suggestions.keywords;
        } else if (playlistSuggestions) {
            suggs = playlistSuggestions.keywords;
        }
        let formatedOptions = suggs.map((suggestion) => ({
            value: suggestion
        }));
        setSearchOptions(formatedOptions);
    }, [suggestions, playlistSuggestions]);

    useEffect(() => {
        suggestionsOptionsHandler();
    }, [suggestions, playlistSuggestions, suggestionsOptionsHandler]);

    //eslint-disable-next-line
    const delayedRequest = useCallback(
        debounce((value) => {
            if (tvChannelsSearch) {
                dispatch(getChannelSuggestions(value));
                dispatch(searchChannels(value));
            }
            if (playlistsSearch) {
                dispatch(getPlaylistSuggestions(value));
                dispatch(searchPlaylists(value));
            }
        }, 300),
        [tvChannelsSearch, playlistsSearch]
    );

    const searchChannelHandler = (e) => {
        // setSearchTerm(e.target.value);
        if (tvChannelsSearch) {
            setSearchingChannel(true);
            if (e.target.value.length === 0) {
                setSearchedChannlesLength(3);
                setSearchedPackagesLength(3);
                setSearchOptions([]);
                dispatch({type: CHANNEL_KEYWORDS_SUCCESS, payload: null});
            }
            if (e.target.value) {
                setShowAutoComplete(true);
                delayedRequest(e.target.value);
            } else {
                setSearchingChannel(false);
                setShowAutoComplete(false);
            }
        }
        if (playlistsSearch) {
            if (e.target.value.length === 0) {
                setSearchingPlaylists(false);
                setSearchOptions([]);
                dispatch({type: PLAYLIST_KEYWORDS_SUCCESS, payload: null});
            }
            if (e.target.value) {
                delayedRequest(e.target.value);
                setSearchingPlaylists(true);
                setShowAutoComplete(true);
            } else {
                setSearchingPlaylists(false);
                setShowAutoComplete(false);
            }
        }
    };

    const onSelectSuggestion = (value) => {
        if (tvChannelsSearch) {
            dispatch(searchChannels(value));
        }else if (playlistsSearch) {
            dispatch(searchPlaylists(value));
        }
        setShowAutoComplete(false);
    };


    return (
        <div
            ref={searchContainerRef}
            className={`search-channel-container ${className} ${
                searchingChannel || isMobile ? "no-borders" : ""
            }`}
        >
            <div className="auto-complete-container">
                <div className="auto-complete-inner">
                    <AutoComplete
                        onSelect={onSelectSuggestion}
                        options={searchOptions}
                        dropdownClassName={"auto-complete-dropdown-channels"}
                        open={showAutoComplete}
                        onBlur={() => {
                            setShowAutoComplete(false);
                        }}
                    >
                        <Input
                            onChange={searchChannelHandler}
                            className={`search-channels-input ${mobileClasses} ${searchInputClass}`}
                            ref={ref}
                            placeholder={
                                !placeholder
                                    ? `${t("search_tv_channels")}`
                                    : placeholder
                            }
                        />
                    </AutoComplete>
                    {/*{showClearIcon && <div className="clear-input-icon" onClick={() => setSearchTerm("")}><img src={clearInputIcon} alt="" /></div>}*/}
                </div>
            </div>
        </div>
    );
};

export default React.forwardRef(ChannelSearch);
