import React, { useState, useEffect, useContext } from "react";
import { Context } from "@context/context";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Button from "@shared/atoms/Button/index";
import Input from "@shared/atoms/Input/index";
import Form from "@shared/atoms/Form/index";
import Alert from "@shared/atoms/Alert/index";
// import SocialLogin from "@components/organisms/SocialLogin";
import ForgotPasswordForm from "../ForgotPasswordForm/mobile";
// import ConfirmPasswordForm from "../ConfirmPasswordForm/mobile";
// import ConfirmCodeForm from "../ConfirmCodeForm/mobile";
import { formLayout } from "@modules/config";
import { localStorageService } from "@modules/services/localStorage.service";
import ReCAPTCHA from "react-google-recaptcha";
import {
    login,
    // confirmEmail,
    // confirmResetPassword,
    passwordReset,
} from "@redux/actions/userActions";

import "./index.less";
import { useTranslation } from "react-i18next";
import { keyExists } from "../../../../../utils/keyExists";
import MobileResetPassword from "../../MobileResetPassword";

const AuthFormMobile = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const params = useParams();
    const [showMobileReset, setShowMobileReset] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [loginScreen, setLoginScreen] = useState(true);
    const [reset, setReset] = useState(false);
    // const [confirmPassword, setConfirmPassword] = useState(false);
    // const [confirmCode, setConfirmCode] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    const {
        userEmail,
        // resetPasswordOtpCode,
        setAuthMobileHeading,
        setAuthMobileSubHeading,
    } = useContext(Context);

    const userLogin = useSelector((state) => state.userLogin);
    const { loading, error, loggedIn } = userLogin;

    const userConfirmEmail = useSelector((state) => state.userConfirmEmail);
    const {
        errorMsg: errorMsgEmail,
        // loading: loadingEmailConfirm,
        success: successVerificationEmail,
    } = userConfirmEmail;

    const userPasswordReset = useSelector((state) => state.userPasswordReset);
    const { error: resetError, success: resetSuccess } = userPasswordReset;

    // const userConfirmResetPassword = useSelector(
    //     (state) => state.userConfirmResetPassword
    // );
    // const { error: resetConfrimCodeError, success: resetConfirmCodeSuccess } =
    //     userConfirmResetPassword;

    const dispatch = useDispatch();

    useEffect(() => {
        setAuthMobileHeading(`${t("welcome_back")}`);
        setAuthMobileSubHeading(`${t("log_into_your_account")}`);
    }, [setAuthMobileHeading, setAuthMobileSubHeading, t]);

    useEffect(() => {
        if (loggedIn) {
            history.push("/");
        } else {
            localStorageService().remove("access_token");
            localStorageService().remove("refresh_token");
            localStorageService().remove("expires_in");
        }
    }, [loggedIn, history]);

    useEffect(() => {
        if (loading) {
            setDisableBtn(true);
        } else {
            setDisableBtn(false);
        }
        if (username && password && isVerified) {
            setDisableBtn(false);
            if (loading) {
                setDisableBtn(true);
            } else {
                setDisableBtn(false);
            }
        } else {
            setDisableBtn(true);
        }
    }, [disableBtn, loading, username, password, isVerified]);

    useEffect(() => {
        if(keyExists(['email', 'token'], params)) {
            setShowMobileReset(true);
        }
    }, [params])

    const loginHandler = () => {
        dispatch(login(username, password));
    };

    const confirmEmailHandler = () => {
        dispatch(passwordReset(userEmail));

        // setConfirmCode(true);
        // setReset(false);
    };

    const resetScreenHandler = () => {
        setReset(true);
        setAuthMobileHeading("Reset Password");
        setAuthMobileSubHeading("");
        setLoginScreen(false);
    };

    const handleVerifyCaptcha = (value) => {
        setIsVerified(true);
    };

    if (showMobileReset) {
        return <MobileResetPassword />
    }

    return (
        <div className="auth-form auth-form-mobile">
            {error && <Alert message={error?.data?.message} type="error" />}
            {successVerificationEmail && (
                <Alert
                    message={`${t(
                        "the_password_reset_link_is_sent_to"
                    )} "${userEmail}".`}
                    type="success"
                />
            )}
            {loginScreen && (
                <Form layout={formLayout}>
                    <Form.Item>
                        <Input
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder={t("email_or_username")}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Input.Password
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder={t("password")}
                        />
                    </Form.Item>

                    <span
                        onClick={resetScreenHandler}
                        className="forgot-password forgot-password-mobile"
                    >
                        {t("forgot")}?
                    </span>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        onChange={handleVerifyCaptcha}
                        size="normal"
                        className="re-captcha-mobile"
                    />
                    <div className="login-btn-container login-btn-container-mobile">
                        <Button
                            disabled={disableBtn}
                            onClick={loginHandler}
                            className="login-btn login-btn-mobile"
                            type="primary"
                            htmlType="submit"
                        >
                            {t("login")}
                        </Button>
                    </div>
                    {/* <SocialLogin
                        additionalClasses="social-login-wrapper-mobile"
                        responseGoogle={responseGoogle}
                        responseFacebook={responseFacebook}
                    /> */}

                    <div className="link-register-content-mobile">
                        {t("need_an_account?")}{" "}
                        <Link className="link-register-mobile" to="/register">
                            {t("register_here")}
                        </Link>
                    </div>
                </Form>
            )}
            {errorMsgEmail && <Alert message={errorMsgEmail} type="error" />}
            {reset && (
                <>
                    {resetError && (
                        <Alert
                            message={`${t(
                                "error!_confirmation_code_was_not_sent"
                            )}`}
                            type="error"
                        />
                    )}
                    {resetSuccess && (
                        <>
                            <Alert
                                message={`Confirmation code was sent to ${userEmail}`}
                                type="success"
                            />
                            <span
                                className="back-to-login"
                                onClick={() => {
                                    window.location.reload();
                                }}
                            >
                                {t("go_back_to_login")}
                            </span>
                        </>
                    )}
                    <ForgotPasswordForm setConfirmEmail={confirmEmailHandler} />
                </>
            )}
            {/* {confirmCode && !errorMsgEmail && (
                <ConfirmCodeForm setConfirmPassword={confirmPasswordHandler} />
            )}
            {resetConfrimCodeError && (
                <Alert message={resetConfrimCodeError.data?.message} type="error" />
            )}
            {confirmPassword && !resetConfrimCodeError && (
                <ConfirmPasswordForm />
            )} */}
        </div>
    );
};

export default AuthFormMobile;
