import React, { useContext } from 'react'
import Modal from "@shared/atoms/Modal/index";
import CookiesContent from '@components/organisms/CookiesContent';
import { Context } from '@context/context';

import "./index.less"

const CookiesModalPrototype = () => {
  const { showCookiesModalPrototype, setShowCookiesModalPrototype } = useContext(Context)
  return (
    <Modal
      visible={showCookiesModalPrototype}
      className='cookies-modal-prototype'
      onCancel={() => setShowCookiesModalPrototype(false)}
      footer={<></>}
    >
      <CookiesContent shouldCloseModal={true} />
    </Modal>
  )
}

export default CookiesModalPrototype