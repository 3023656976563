import React from 'react'
import "./index.less"
/**
 * Custom arrow icon component
 * @prop {string} color - hex code of color
 * @prop {number} rotateDeg - rotates svg
 * @prop {string} width - width of svg
 * @prop {string} height - height of svg
 *
 * @prop {function} onClick - svg click handler
 *
 * @example
 * <ArrowCircle color='#ccc' rotateDeg={180} width={24} height={24} />
 *
 */

const ArrowCircle = ({ onClick, color = "#fff", rotateDeg = 0, width = "32", height = "32" }) => {
  const directionStyle = {
    transform: `rotate(${rotateDeg}deg)`
  }
  return (
    <svg onClick={onClick} className='arrow-circle-svg' style={directionStyle} fill={color} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
      <title>circle-arrow</title>
      <path
        d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13z">
      </path>
      <path d="M11.086 22.086l2.829 2.829 8.914-8.914-8.914-8.914-2.828 2.828 6.086 6.086z"></path>
    </svg>
  )
}

export default ArrowCircle