import React, { useContext } from "react";
import { Context } from "@context/context";
import { USER_REGISTER_RESET } from "@redux/constants/userConstants";
import Modal from "@shared/atoms/Modal/index";
import Button from "@shared/atoms/Button";
import { Paragraph } from "@shared/atoms/Typography/index";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import "./index.less";

const CheckEmailModalPrototype = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const {
    showCheckEmailModalPrototype,
    setShowCheckEmailModalPrototype,
    setModalTypeAuthPrototype,
    formTypeAuthPrototype,
  } = useContext(Context);

  const showLoginScreen = () => {
    setModalTypeAuthPrototype(formTypeAuthPrototype.login);
    setShowCheckEmailModalPrototype(false);
    dispatch({ type: USER_REGISTER_RESET });
  };

  return (
    <Modal
      className="modal-basic-prototype check-email-modal-prototype"
      closable={true}
      visible={showCheckEmailModalPrototype}
      onCancel={showLoginScreen}
      footer={[
        <Button
          type="primary"
          className="ant-btn-prototype modal-btn-prototype"
          onClick={showLoginScreen}
        >
          {t("log_in")}
        </Button>,
      ]}
    >
      <Paragraph className="para">
        Registration is successful. Please login
      </Paragraph>
    </Modal>
  );
};

export default CheckEmailModalPrototype;
