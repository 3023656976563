import React, { useContext, useEffect, useState } from "react";

import { isMobile } from "react-device-detect";
import MainLayout from "@modules/layout/MainLayout";
import SearchPrototype from "@components/organisms/SearchPrototype";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearchHistory,
  selectSearchHistory,
  toggleFollowSearchHistory,
} from "@redux/actions/searchActions";
import HeaderPrototype from "@components/organisms/HeaderPrototype";
import DesktopPrototype from "@components/organisms/DesktopPrototype";
import { useLocation } from "react-router-dom";
import { Context } from "@context/context";
import { VIEWS_PROTOTYPE } from "const/viewsPrototype";
import searchIcon from "@assets/icons/search-icon.svg";
import { localStorageService } from "@modules/services/localStorage.service";
import {
  SEARCH_AUTHORIZED_RESET,
  SEARCH_UNAUTHORIZED_RESET,
} from "@redux/constants/searchConstants";

export const Search = () => {
  const [termId, setTermId] = useState(null);
  const [btnText, setBtnText] = useState("");

  const dispatch = useDispatch();
  const isLoggedIn = !!localStorageService().get("access_token");

  const { data: searchHistory, loading: searchHistoryLoading } =
    useSelector(selectSearchHistory);

  const {
    setShowSubBarPrototype,
    setCurrentViewPrototype,
    setShowSearchModalPrototype,
  } = useContext(Context);

  const location = useLocation();
  const term = location?.search?.split("&")[1]?.split("=")[1] || "";

  // const btnText = 1;
  const searchInFollowed = searchHistory?.followed.find(
    (item) => item.search === term
  )?.id;

  const searchInunfollowed = searchHistory?.unfollowed.find(
    (item) => item.search === term
  )?.id;

  // debounce(() => {
  // }, 200);

  useEffect(() => {
    setShowSearchModalPrototype(true);
    if (isLoggedIn) {
      setShowSubBarPrototype(true);
      setCurrentViewPrototype(VIEWS_PROTOTYPE.search);
    }
    dispatch(getSearchHistory());
  }, [
    dispatch,
    setShowSearchModalPrototype,
    isLoggedIn,
    setShowSubBarPrototype,
    setCurrentViewPrototype,
  ]);

  useEffect(() => {
    return () => {
      setShowSearchModalPrototype(false);
      isLoggedIn
        ? dispatch({ type: SEARCH_AUTHORIZED_RESET })
        : dispatch({ type: SEARCH_UNAUTHORIZED_RESET });
    };
  }, [setShowSearchModalPrototype, dispatch, isLoggedIn]);

  useEffect(() => {
    if (searchInFollowed) {
      setTermId(searchInFollowed);
      setBtnText("Unsubscribe");
    } else {
      setTermId(searchInunfollowed);
      setBtnText("Subscribe");
    }
  }, [searchInFollowed, searchInunfollowed]);

  const searchSubscribeHandler = () => {
    setShowSubBarPrototype(true);
    setCurrentViewPrototype(VIEWS_PROTOTYPE.search);
    Promise.resolve(dispatch(toggleFollowSearchHistory(termId))).then(() => {
      dispatch(getSearchHistory());
    });
  };

  return (
    <>
      {!isMobile ? (
        <>
          <DesktopPrototype>
            {term && (
              <HeaderPrototype
                searchSubscribeHandler={searchSubscribeHandler}
                name={decodeURI(term)}
                disabledSearchFollowBtn={searchHistoryLoading}
                searchSubscribeBtnText={btnText}
                image={searchIcon}
                showChannelMenu={false}
                headerPositionStyle={{ position: "absolute" }}
              />
            )}
            <SearchPrototype />
          </DesktopPrototype>
        </>
      ) : (
        <MainLayout>
          {term && (
            <HeaderPrototype
              searchSubscribeHandler={searchSubscribeHandler}
              name={term}
              disabledSearchFollowBtn={searchHistoryLoading}
              searchSubscribeBtnText={btnText}
              image={searchIcon}
              showChannelMenu={false}
              headerPositionStyle={{ position: "absolute" }}
            />
          )}
          <SearchPrototype />
        </MainLayout>
      )}
    </>
  );
};
