import React, { useState } from "react";
import Image from "@shared/atoms/Image";
import sortIcon from "@assets/icons/sort-icon.svg";
import KebabMenuPopup from "../../../organisms/KebabMenuPopup";
import PopupItem from "../listItems/PopupItem";
import { useTranslation } from "react-i18next";

import "./index.less";

const Sort = ({ showText = true, sortByNewest, sortByOldest }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <div className="sort-container">
        <div
          className="sort popup-toggle"
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
        >
          <Image src={sortIcon} alt="sort-icon" className="popup-toggle" />
          {showText && <span className="popup-toggle">{t("sort")}</span>}
        </div>
        {showDropdown && (
          <KebabMenuPopup
            className="sort-popup"
            setShowDropdown={setShowDropdown}
          >
            <PopupItem onClick={sortByNewest}>
              {t("date_added_(newest)")}
            </PopupItem>
            <PopupItem onClick={sortByOldest}>
              {t("date_added_(oldest)")}
            </PopupItem>
          </KebabMenuPopup>
        )}
      </div>
    </>
  );
};

export default Sort;
