import React from "react";

import "./index.less";

const BorderedDivider = ({ className, hide = false, children }) => {
    return (
        <div className={`bordered-divider  ${className} ${hide ? "hide" : ""}`}>
            {children}
        </div>
    );
};

export default BorderedDivider;
