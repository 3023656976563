import React from "react";

import "./index.less";

const PlusSmall = ({ className, onClick }) => {
    return (
        <svg
            onClick={onClick}
            className={`plus-small-icon-svg ${className}`}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <defs>
                <clipPath id="a">
                    <path
                        className="a"
                        d="M10,20A10,10,0,1,1,20,10,10.011,10.011,0,0,1,10,20ZM10,2a8,8,0,1,0,8,8A8.009,8.009,0,0,0,10,2Zm0,12a1,1,0,0,1-1-1V11H7A1,1,0,0,1,7,9H9V7a1,1,0,0,1,2,0V9h2a1,1,0,1,1,0,2H11v2A1,1,0,0,1,10,14Z"
                        transform="translate(2 2)"
                    />
                </clipPath>
            </defs>
            <g transform="translate(-311 -714)">
                <rect
                    className="b"
                    width="24"
                    height="24"
                    transform="translate(311 714)"
                />
                <rect
                    className="b"
                    width="24"
                    height="24"
                    transform="translate(311 714)"
                />
                <g transform="translate(311 714)">
                    <path
                        className="a"
                        d="M10,20A10,10,0,1,1,20,10,10.011,10.011,0,0,1,10,20ZM10,2a8,8,0,1,0,8,8A8.009,8.009,0,0,0,10,2Zm0,12a1,1,0,0,1-1-1V11H7A1,1,0,0,1,7,9H9V7a1,1,0,0,1,2,0V9h2a1,1,0,1,1,0,2H11v2A1,1,0,0,1,10,14Z"
                        transform="translate(2 2)"
                    />
                </g>
                <g transform="translate(311 714)">
                    <path
                        className="a"
                        d="M10,20A10,10,0,1,1,20,10,10.011,10.011,0,0,1,10,20ZM10,2a8,8,0,1,0,8,8A8.009,8.009,0,0,0,10,2Zm0,12a1,1,0,0,1-1-1V11H7A1,1,0,0,1,7,9H9V7a1,1,0,0,1,2,0V9h2a1,1,0,1,1,0,2H11v2A1,1,0,0,1,10,14Z"
                        transform="translate(2 2)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default PlusSmall;
