import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "@redux/actions/profileActions";
import { USER_CHANGE_PASSWORD_RESET } from "@redux/constants/profileConstants";
import Modal from "../../../shared/atoms/Modal";
import Button from "@shared/atoms/Button";
import Image from "@shared/atoms/Image";
import choiceLogo from "@assets/icons/choice-icon-white.png";
import UpdatePasswordFormPrototype from "../../forms/UpdatePasswordFormPrototype";
import ErrorFeedbackPrototype from "../../../shared/atoms/ErrorFeedbackPrototype";


import "./index.less";
import { useTranslation } from "react-i18next";

const UpdatePasswordModalPrototype = ({ show, setShow }) => {
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [repeatNewPassword, setRepeatNewPassword] = useState("")

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { error: changePasswordError, loading: changePasswordLoading, message: changePasswordMessage } = useSelector(state => state.userChangePassword)


  const closeModal = useCallback(() => {
    setShow(false);
    dispatch({ type: USER_CHANGE_PASSWORD_RESET })
  }, [dispatch, setShow]);

  useEffect(() => {
    if (!changePasswordError && changePasswordMessage?.message) {
      closeModal()
    }
  }, [changePasswordError, closeModal, changePasswordMessage])


  const updatePasswordHandler = () => {
    dispatch(changePassword(oldPassword, newPassword, repeatNewPassword))
  }

  return (
    <Modal
      className="ant-modal-prototype update-password-modal-prototype"
      visible={show}
      closable={true}
      onCancel={closeModal}
      footer={[
        <>
          <Button key="update" disabled={changePasswordLoading} type="primary" onClick={updatePasswordHandler} >
            {t("update")}
          </Button>
          <Button key="cancel" onClick={closeModal}>
            {t("cancel")}
          </Button>
        </>,
      ]}
    >
      <Image className="logo" src={choiceLogo} alt="logo" />
      <UpdatePasswordFormPrototype
        oldPassword={oldPassword}
        newPassword={newPassword}
        repeatNewPassword={repeatNewPassword}
        setOldPassword={setOldPassword}
        setNewPassword={setNewPassword}
        setRepeatNewPassword={setRepeatNewPassword}
      />
      {changePasswordError && (
        <>
          {changePasswordError?.data?.errors?.old_password && (

            <ErrorFeedbackPrototype>
              {changePasswordError?.data?.errors?.old_password[0]}
            </ErrorFeedbackPrototype>
          )}
          {changePasswordError?.data?.errors?.password && (

            <ErrorFeedbackPrototype>
              {changePasswordError?.data?.errors?.password[0]}
            </ErrorFeedbackPrototype>
          )}
          {changePasswordError?.data?.errors?.password_confirmation && (

            <ErrorFeedbackPrototype>
              {changePasswordError?.data?.errors?.password_confirmation[0]}
            </ErrorFeedbackPrototype>
          )}
        </>
      )}

    </Modal>
  );
};

export default UpdatePasswordModalPrototype;
