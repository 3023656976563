import React, { useState, useContext } from "react";
import { Context } from "@context/context";
import { Title, Paragraph } from "@shared/atoms/Typography/index";
import List from "@shared/atoms/List/index";
import Image from "@shared/atoms/Image/index";
import playButtonsIcon from "@assets/icons/play-buttons.svg";
import KebabMenuPopup from "../../../../organisms/KebabMenuPopup";
import KebabMenu from "../../../atoms/icons/KebabMenu";
import PopupItem from "../PopupItem";
import Share from "../../../atoms/icons/Share";
import Dot from "@shared/atoms/Dot";

import "./index.less";
import { useTranslation } from "react-i18next";

const FollowingItem = ({
    title = "Music",
    playlist,
    subTitle = "0",
    additionalClasses="",
    ...rest
}, ref) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const { setShowShareModal } = useContext(Context);

    const sharePlaylistHandler = () => {
        setShowShareModal(true);
    };

    const { t } = useTranslation();

    const defaultIcon = playlist?.icon?.includes("defaultIcon");

    return (
        <div className={`following-list-item-container ${additionalClasses}`} {...rest} ref={ref}>
            <List.Item>
                <div className="icon-container">
                    <Image
                        className="play-buttons-menu"
                        style={{
                            objectFit: defaultIcon ? "none" : "cover",
                        }}
                        src={defaultIcon ? playButtonsIcon : playlist?.icon}
                        alt="playButtonsIcon"
                    />
                </div>
                <div>
                    <Title level={2} className="title">
                        {title}
                    </Title>
                    <div className="playlist-statistic-container">
                        <Paragraph className="para">
                            {subTitle} videos
                        </Paragraph>
                        <Dot />
                        <Paragraph className="para">
                            {playlist?.views} views
                        </Paragraph>
                    </div>
                </div>
                <div>
                    <KebabMenu
                        className="kebab-menu"
                        onClick={() => setShowDropdown(!showDropdown)}
                    />
                    {showDropdown && (
                        <KebabMenuPopup setShowDropdown={setShowDropdown}>
                            <PopupItem
                                onClick={() => sharePlaylistHandler()}
                                icon={<Share />}
                            >
                                {t("share_playlist")}{" "}
                            </PopupItem>
                        </KebabMenuPopup>
                    )}
                </div>
            </List.Item>
        </div>
    );
};

const forwardedFollowingItem = React.forwardRef(FollowingItem);


export default forwardedFollowingItem;
