import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { localStorageService } from "../services/localStorage.service";
import translationEn from "./localizations/en/translation.json";
import translationNl from "./localizations/nl/translation.json";

let lng = localStorageService().get("lang") || "en";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: translationEn,
    },
    nl: {
        translation: translationNl,
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: ["en", "nl"],
        resources,
        lng,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
