import React, { useEffect, useContext } from "react";
import ReactPlayer from "react-player";
import { Context } from "@context/context";
import { viewEnd } from "@redux/actions/viewActions";
import { useDispatch, useSelector } from "react-redux";

function iOS() {
  return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
let isSafari = (iOS() ? false : /^((?!chrome|android).)*safari/i.test(navigator.userAgent));

const VideoPlayer = ({isVideoLive = false, ...rest }) => {
  const { setPlayingChannelLive, videoPlayerItemRef } = useContext(Context);
  const dispatch = useDispatch();

  const { message: viewStartMsg } = useSelector((state) => state.viewStart);

  useEffect(() => {
    return () => {
      setPlayingChannelLive(false);
    };
  }, [setPlayingChannelLive]);

  useEffect(() => {
    return () => {
      dispatch(viewEnd(viewStartMsg?.viewProcessId));
    };
  }, [dispatch, viewStartMsg?.viewProcessId]);

  return (
    <div ref={videoPlayerItemRef}>
      <ReactPlayer
        {...rest}
        muted={isSafari}
        config={{
          file: {
            forceHLS: isSafari,
            forceVideo: true,
            hlsOptions: {
              liveDurationInfinity: isVideoLive
            }
          },
        }}
      />
    </div>
  );
};

export default VideoPlayer;
