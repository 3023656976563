import React, { useState, useContext, useEffect } from "react";
// import List from "@shared/atoms/List/index";
// import SearchSuggestionsItemPrototype from "../../../shared/molecules/listItems/SearchSuggestionsItemPrototype";
import { Link } from "react-router-dom";
import { Context } from "../../../../../context/context";
// import playButtonsIcon from "@assets/icons/play-buttons.svg";
import SearchRadioGroupPrototype from "@components/shared/molecules/radioGroups/SearchRadioGroupPrototype";
import { localStorageService } from "@modules/services/localStorage.service";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";

import {
  createSearchHistory,
  getAuthorizedSearch,
  getSearchHistory,
  getUnauthorizedSearch,
} from "@redux/actions/searchActions";
import { Paragraph } from "@components/shared/atoms/Typography";

import "./index.less";
import Loader from "@components/shared/atoms/Loader";
import SpinnerPrototype from "@components/shared/atoms/SpinnerPrototype";

const SearchSuggestionsListPrototype = ({
  data,
  // isPlaylistList,
  // isMyPlaylistSearch,
  // isChannelListSearch,
  searchWord,
  searchType,
  setSearchType,
}) => {
  const isLoggedIn = !!localStorageService().get("access_token");
  const dispatch = useDispatch();

  const {
    loading: authorizedSearchLoading,
    data: authorizedSearchData,
    error: authorizedSearchError
  } = useSelector((state) => state.searchAuthorized);

  const { setShowSearchModalPrototype, setShowSubBarPrototype } =
    useContext(Context);

  const handleSearch = (type) => {
    if (searchWord) {
      isLoggedIn
        ? dispatch(getAuthorizedSearch(decodeURI(searchWord), type))
        : dispatch(getUnauthorizedSearch(decodeURI(searchWord)));
    }
  };

  const changeSearchRadioValue = (e) => {
    setSearchType(e.target.value);
  };

  const delayedGetSearchHistory = debounce(() => {
    dispatch(getSearchHistory());
  }, 300);

  const handleSearchResultClick = () => {
    if (isLoggedIn) {
      dispatch(createSearchHistory(searchWord));
      delayedGetSearchHistory();
    }
    setShowSearchModalPrototype(false);
  };

  const resultItemClickHandler = () => {
    setShowSubBarPrototype(false);
  };

  useEffect(() => {
    handleSearch(searchType);
  }, [searchType]);

  console.log(authorizedSearchLoading);

  return (
    <>
      <SearchRadioGroupPrototype
        onChange={changeSearchRadioValue}
        value={searchType}
      />
      {
        authorizedSearchLoading && (
          <SpinnerPrototype className="spinner" size={3} />
        )
      }
      {data && (
        
        <div className="search-result-container">
          {/* COLLAPSE ALTERNETAVE */}
          {/* <SearchSuggestionsItemPrototype
            pannelHeader={data.playlists.length + " playlists"}
          >
            {data.playlists.map((playlist) => (
              <div className="result-container">
                <Image src={playlist.icon} />
                <Paragraph>{playlist.name}</Paragraph>
              </div>
            ))}
          </SearchSuggestionsItemPrototype>
          <SearchSuggestionsItemPrototype
            pannelHeader={data.series.length + " Series"}
          >
            {data.series.map((serie) => (
              <div className="result-container">
                <Paragraph>{serie.name}</Paragraph>
              </div>
            ))}
          </SearchSuggestionsItemPrototype>
          <SearchSuggestionsItemPrototype
            pannelHeader={data.videos.length + " Videos"}
          >
            {data.videos.map((video) => (
              <div className="result-container">
                <Paragraph>{video.title}</Paragraph>
              </div>
            ))}
          </SearchSuggestionsItemPrototype> */}
          {/* //////////////////////////// */}

          {isLoggedIn  && !authorizedSearchError && (
            
            <div className="result-item" onClick={handleSearchResultClick}>
              <Paragraph>
                <Link
                  onClick={resultItemClickHandler}
                  to={`/search?content=channels&term=${encodeURIComponent(
                    searchWord
                  )}&type=${searchType}`}
                >
                  {data?.channels?.length + " broadcast channels"}
                </Link>
              </Paragraph>
            </div>
          )}
          <div className="result-item" onClick={handleSearchResultClick}>
            <Paragraph>
              <Link
                onClick={resultItemClickHandler}
                to={`/search?content=playlists&term=${encodeURIComponent(
                  searchWord
                )}&type=${searchType}`}
              >
                {data?.playlists?.length + " virtual channels"}
              </Link>
            </Paragraph>
          </div>
          <div className="result-item" onClick={handleSearchResultClick}>
            <Paragraph>
              <Link
                onClick={resultItemClickHandler}
                to={`/search?content=series&term=${encodeURIComponent(
                  searchWord
                )}&type=${searchType}`}
              >
                {data?.series?.length + " series"}
              </Link>
            </Paragraph>
          </div>
          <div className="result-item" onClick={handleSearchResultClick}>
            <Paragraph>
              <Link
                onClick={resultItemClickHandler}
                to={`/search?content=videos&term=${encodeURIComponent(
                  searchWord
                )}&type=${searchType}`}
              >
                {data?.videos?.length + " content items"}
              </Link>
            </Paragraph>
          </div>
          <div className="result-item" onClick={handleSearchResultClick}>
            <Paragraph>
              <Link
                onClick={resultItemClickHandler}
                to={`/search?content=users&term=${encodeURIComponent(
                  searchWord
                )}&type=${searchType}`}
              >
                {data?.users?.length || "0" + " public profile"}
              </Link>
            </Paragraph>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchSuggestionsListPrototype;
