import { Context } from "@context/context";
import { copyEpgAction, copyEpgItemAction, copySerieAction, copyTierChannelAsVideoAction, copyVideoAction } from "@redux/actions/copyActions";
import { getPlaylistById, updatePlaylistVideo } from "@redux/actions/playlistsActions";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import querystring from "query-string";
import { useLocation } from "react-router-dom";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { debounce } from "lodash";
import { useEffect } from "react";

export const usePaste = () => {
    const { copiedPlaylist, playlistVideoPrototype } = useContext(Context);
    const { search } = useLocation();
    const parsedQueryString = querystring.parse(search);

    const dispatch = useDispatch();

    const handlePasteVideo = (seriesId, order, direction) => {
      
        const props = {
          video_id: copiedPlaylist.copiedItem.videoId,
          series_id: seriesId,
          order: direction === "Left" ? order : order + 1
        };
        
        let currPlaylistItem = JSON.parse(JSON.stringify(copiedPlaylist?.copiedItemObj?.video));
        currPlaylistItem.is_home = false;
        currPlaylistItem.series_id = currPlaylistItem.pivot.serie_id;

        Promise.resolve(dispatch(copyVideoAction(props))).then((data) => {
          currPlaylistItem.id = data?.copied_video_id;
          Promise.resolve(dispatch(updatePlaylistVideo(data?.copied_video_id, currPlaylistItem))).then(() => {
            dispatch(getPlaylistById(parsedQueryString.playlist))
          })
        })
      };

    const handlePasteSerie = (playlistId, position, direction) => {
        const props = {
          series_id: copiedPlaylist.copiedItem.seriesId,
          playlist_id: playlistId,
          position: direction === "Above" ? position : position + 1,
        };
        
        dispatchAndRefetch(copySerieAction, props)

      };
    
      const handlePasteEpgItem = (seriesId, order, direction) => {
        const props = {
          epg_id: copiedPlaylist.copiedItem.epgId,
          series_id: seriesId,
          url: copiedPlaylist.copiedItem.url,
          order: direction === "Left" ? order : order + 1,
        }
    
        dispatchAndRefetch(copyEpgItemAction, props)
      }
    
      const handlePasteEpg = (playlistId, order, direction) => {
        const props = {
          epgs: copiedPlaylist.copiedItem.epgs,
          name: copiedPlaylist.copiedItem.name,
          playlist_id: playlistId,
          position: direction === "Above" ? order : order + 1,
        }

        dispatchAndRefetch(copyEpgAction, props)
      }

    const handlePasteTierChannel = (playlistId, order, direction) => {
        const { id, tierName } = copiedPlaylist.copiedItem;
        const props = {
          tier_name: tierName,
          channel_id: id,
          playlist_id: playlistId,
          url: `tiers?channel=${id}`,
          position: direction === "Above" ? order : order + 1,
        }
    
        dispatchAndRefetch(copyTierChannelAsVideoAction, props)
      }

      const dispatchAndRefetch = (action, props) => {
        Promise.resolve(dispatch(action(props))).then(() => {
            dispatch(getPlaylistById(parsedQueryString.playlist))
        })
      }

      return {
        handlePasteVideo,
        handlePasteSerie,
        handlePasteEpgItem,
        handlePasteEpg,
        handlePasteTierChannel,
      }
}