import Modal from "@shared/atoms/Modal/index";
import { Paragraph } from "@shared/atoms/Typography/index";
import Image from "@shared/atoms/Image/index";
import warningIcon from "@assets/icons/warning.svg";
import { useContext } from "react";
import { Context } from "@context/context";

const SimpleWarningModal = ({ isVisible, text, onCancel }) => {
  const {
    showSimpleWarningModal,
    showWarningText
  } = useContext(Context);

  return (
    <Modal
      visible={showSimpleWarningModal ? showSimpleWarningModal : isVisible}
      className="modal-basic-prototype alert-modal-prototype"
      mask={true}
      footer={<></>}
      closable={true}
      onCancel={() => {
        onCancel();
      }}
    >
      <div>
        <Image className="warning-img" src={warningIcon} alt="warning" />
        <Paragraph>
          { text ? text : showWarningText }
        </Paragraph>
      </div>
    </Modal>
  );
};

export default SimpleWarningModal;
