import React, { useCallback, useContext, useEffect } from "react";
import { Paragraph } from "@shared/atoms/Typography/index";
import MobileNavPrototype from "@components/organisms/MobileNavPrototype";
// import ScrollContainer from "react-indiana-drag-scroll";

import { Context } from "@context/context";
import { useTranslation } from "react-i18next";

import "./index.less";
import usePathName from "hooks/usePathName";
import { PAGES } from "const/pages";

const DesktopPrototype = ({ children, hasNavigation = true }) => {
  const { tabletFrameRef, tabletPrototypeRef, setShowSubBarPrototype } =
    useContext(Context);
  const { t } = useTranslation();

  const page = usePathName();

  const hideScroll = useCallback(() => {
    const element = tabletFrameRef?.current;
    if (page === PAGES.tiers && tabletFrameRef) {
      element.style.overflow = "hidden";
    }
  }, [tabletFrameRef, page]);

  useEffect(() => {
    const hideSubbarMenu = () => {
      setShowSubBarPrototype(false);
    };
    if (tabletFrameRef && tabletFrameRef.current) {
      tabletFrameRef?.current?.addEventListener("wheel", hideSubbarMenu);
    }
    return () => {
      tabletFrameRef?.current?.removeEventListener("wheel", hideSubbarMenu);
    };
  }, [tabletFrameRef, setShowSubBarPrototype]);

  useEffect(() => {
    hideScroll();
  }, [hideScroll]);

  // const containerScrollHandler = () => {
  //   if (tabletFrameRef.current.scrollTop > 1)
  //     if (window.screen.availHeight > 650) {
  //       setHeaderScrollMargin(45)

  //     } else {
  //       setHeaderScrollMargin(35)
  //     }
  // }

  return (
    <div className="tablet-prototype-container">
      <div className="tablet-prototype" ref={tabletPrototypeRef}>
        <div
          className="tablet-prototype__content"
          ref={(ref) => (tabletFrameRef.current = ref)}
          // onScroll={containerScrollHandler}
        >
          {/* <ScrollContainer className="scroll-container"> */}
          {children}
          {/* </ScrollContainer> */}
        </div>
        {hasNavigation && <MobileNavPrototype />}
      </div>

      <div className="mobile-alert-prototype">
        <Paragraph>{t("desktop_text_1")}</Paragraph>
        <Paragraph>{t("desktop_text_2")}</Paragraph>
        <Paragraph>{t("desktop_text_3")}</Paragraph>
      </div>
    </div>
  );
};

export default DesktopPrototype;
