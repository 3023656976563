import React, { useContext, useState, useEffect } from "react";
import { Context } from "@context/context";
import { Title, Paragraph } from "@shared/atoms/Typography/index";
import Image from "@shared/atoms/Image";
import List from "@shared/atoms/List";
import userIcon from "@assets/icons/user-icon.png";
import KebabMenu from "../../../atoms/icons/KebabMenu";
import KebabMenuPopup from "../../../../organisms/KebabMenuPopup";
import PopupItem from "../PopupItem";
import Copy2 from "../../../atoms/icons/Copy2";
import Share from "../../../atoms/icons/Share";
import MoveTo from "../../../atoms/icons/MoveTo";
import Report from "../../../atoms/icons/Report";
import Like from "../../../atoms/icons/Like";

import "./index.less";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const FollowingVideoItem = ({
    onClick,
    title,
    image,
    active = false,
    likeHandler,
    hasLike = true,
    liked,
    playlist,
    video,
}) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const history = useHistory();

    const { t } = useTranslation();

    const {
        selectedVideo,
        setShowShareModal,
        setShowMoveToPlaylistModal,
        setShowReportModal
    } = useContext(Context);

    const copyLinkHandler = () => {
        navigator.clipboard.writeText(selectedVideo.src);
    };

    useEffect(() => {
        return () => {
            setShowMoveToPlaylistModal(false);
        };
    }, [setShowShareModal, setShowMoveToPlaylistModal]);

    const moveToPlaylistHandler = () => {
        setShowMoveToPlaylistModal(true);
    };

    const reportVideoHandler = () => {
        setShowReportModal(true);
    };

    const redirectToUser = () => {
        if (video) {
            history.push(`/settings?user=${video?.user_id}`);
        }
    };

    return (
        <div
            onClick={onClick}
            className={`following-video-list-item-container ${
                active ? "following-video-list-item-active" : ""
            }`}
        >
            <List.Item>
                <div className="video-image-container">
                    <Image
                        className="video-image"
                        src={image}
                        alt="thumbnail"
                    />
                </div>
                <div className="content-container">
                    <div className="video-heading-container">
                        <Title className="video-heading" level={2}>
                            {title}
                        </Title>
                        <div className="kebab-menu-container">
                            <KebabMenu
                                className="kebab-menu"
                                onClick={() => {
                                    setShowDropdown(!showDropdown);
                                }}
                            />
                        </div>
                    </div>
                    <div className="video-info">
                        {playlist?.data?.name}
                        <>
                            {" "}
                            <Paragraph className="sub-text creator-container">
                                <div></div>{" "}
                                <Image
                                    className="user-icon"
                                    src={userIcon}
                                    alt="icon"
                                />{" "}
                                <span
                                    onClick={redirectToUser}
                                    className="creator-name"
                                >
                                    {playlist?.data?.nickname}
                                </span>
                            </Paragraph>
                        </>
                        <div className="social-icons">
                            {hasLike && (
                                <div className="like-container">
                                    <Like
                                        onClick={likeHandler}
                                        className={`social-icon ${
                                            liked ? "liked" : ""
                                        }`}
                                    />
                                    <Paragraph className="count">{video?.likes}</Paragraph>
                                </div>
                            )}

                            {/* <div className="unlike-container">
                                <Image
                                    className="social-icon"
                                    src={unlikeIcon}
                                    alt="unlike"
                                />
                                <Paragraph className="count">2</Paragraph>
                            </div> */}
                        </div>
                    </div>
                </div>
                {showDropdown && (
                    <KebabMenuPopup
                        className="following-video-popup"
                        setShowDropdown={setShowDropdown}
                    >
                        <PopupItem onClick={copyLinkHandler} icon={<Copy2 />}>
                            Copy
                        </PopupItem>
                        <PopupItem
                            onClick={() => setShowShareModal(true)}
                            icon={<Share />}
                        >
                            {t("share")}
                        </PopupItem>
                        <PopupItem
                            onClick={moveToPlaylistHandler}
                            icon={<MoveTo />}
                        >
                            {t("add_to_playlist")}
                        </PopupItem>
                        <PopupItem
                            onClick={reportVideoHandler}
                            icon={<Report />}
                        >
                            {t("report")}
                        </PopupItem>
                    </KebabMenuPopup>
                )}
            </List.Item>
        </div>
    );
};

export default FollowingVideoItem;
