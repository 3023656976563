import React, { useContext, useEffect, useState } from "react";
import { useContextMenu } from "hooks/useContextMenu";
import { useHistory, useLocation } from "react-router-dom";
import { Card, CardMeta } from "@shared/atoms/Card";
import Image from "@shared/atoms/Image";
import InfoBoxPrototype from "../InfoBoxPrototype";
import ContextMenuPrototype, {
  arrowDirection,
  ContextMenuOption,
} from "../ContextMenuPrototype";
import { Context } from "@context/context";

import defaultImg from "@assets/images/default_img.png";
import editImg from "@assets/icons/edit.svg";
import deleteImg from "@assets/icons/deleteContext.svg";
import homePositionIcon from "@assets/icons/home-position-icon.svg";
import addImg from "@assets/icons/add.svg";
import moveImg from "@assets/icons/arrow-up-down.svg";
import shareImg from "@assets/icons/share-prototype.svg";
import copyImg from "@assets/icons/copy.svg";


import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addNewSeries,
  getPlaylistById,
} from "@redux/actions/playlistsActions";
import moment from "moment";
import HomePositionPrototype from "../HomePositionPrototype";
import { COPY_ITEM } from "const/playlists";
import { localStorageService } from "@modules/services/localStorage.service";
import { VIEWS_PROTOTYPE } from "const/viewsPrototype";

const PlaylistVideoCard = ({
  coverImg = defaultImg,
  title,
  metaInfo,
  infoBoxSubtitle,
  onClick,
  sharePlaylistHandler,
  reportPlaylistItemHandler,
  deleteHandler,
  makeItemHomeHandler,
  editHandler,
  copyHandler,
  onAddToPlaylist,
  directShareHandler,
  description,
  moreButtons,
  isHomePlaylist = false,
  hasThreeDotMenu,
  videoItem,
  isSeriesCard = false,
  infoboxClickHandler,
  videoItems,
  isHomePosition,
  hasHomePosition,
  isPlaylistInTier,
  copyVideo,
  pasteVideoInSerie,
  copySerie,
  pasteSerie,
  pasteEpgItem,
  pasteEpg,
  pasteTierChannelAsVideo,
  isPasteable,
  watchLaterHandler
}) => {
  const { 
    isVisible, 
    xPos,
    yPos, 
    closeContextMenu, 
    withCloseContextMenu, 
    openContextMenu, 
    contextMenuClickWithDebounce, 
    onTouchEnd,
    onTouchMove,
  } = useContextMenu();

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    setIsPlaylistArrangeMode,
    setIsItemsArrangeMode,
    tabletFrameRef,
    copiedPlaylist,
    setCopiedPlaylist,
    currentViewPrototype,
    setShowAddPlaylistItemModalPrototype
  } = useContext(Context);

  const playlistById = useSelector((state) => state?.playlistById);
  const playlistItem = playlistById.playlist?.data;

  const isLoggedIn = !!localStorageService().get("access_token");


  const userProfile = useSelector((state) => state.userProfile);

  // const [getPlaylists, setGetPlaylists] = useState(false);
  

  const {
    playlists,
    // error,
  } = useSelector((state) => state.playlists);

  const watchLaterSeriesHandler = () => {
    Promise.resolve(
      dispatch(
        addNewSeries({
          name: `Direct link - ${moment().format(
            "YYYY-MM-DD - hh:mm:ss"
          )}`,
          playlist_id: playlists?.watchLater[0]?.id,
          title: title,
          description: description,
          videos: videoItems,
        }) 
      )
    ).then(() => {
      // window.location = `playlists?playlist=${playlists?.watch_later}`
      history.push({ search: `?playlist=${playlists?.watchLater[0]?.id}` });
      dispatch(getPlaylistById(playlists?.watchLater[0]?.id));
    });
  };

  const handlePaste = (dir) => {
    switch (copiedPlaylist?.type) {
      case COPY_ITEM.PLAYLIST_VIDEO:
        pasteVideoInSerie(dir);
        break;
      case COPY_ITEM.PLAYLIST_SERIE:
        pasteSerie(dir);
        break;
      case COPY_ITEM.EPG_ITEM:
        pasteEpgItem(dir);
        break;
      case COPY_ITEM.EPG:
        pasteEpg(dir);
        break;
      case COPY_ITEM.TV_CHANNEL:
        pasteTierChannelAsVideo(dir);
        break; 
      default:
        break;
    }
    setCopiedPlaylist(null)
  }

  const openAddVideoHandler = (e) => {
    setShowAddPlaylistItemModalPrototype(true);
  };

  useEffect(() => {
    if (tabletFrameRef && tabletFrameRef.current) {
      if (isVisible) {
        tabletFrameRef.current.style.overflow = "hidden";
      } else {
        tabletFrameRef.current.style.overflow = "auto";
      }
    }
  }, [tabletFrameRef, isVisible]);



  const contextmenuOptions = (
    <>
      {!isHomePlaylist && !isSeriesCard && (
        <>
          {
            // don't show context item if playlist is default or other user's public playlist
            Number(playlistItem?.user_id) ===
            Number(userProfile?.profile?.data?.id) && !isPlaylistInTier && (
              <ContextMenuOption
                label={"Edit Episode"}
                icon={editImg}
                onClick={withCloseContextMenu(editHandler)}
              />
            )
          }

          {Number(playlistItem?.user_id) ===
            Number(userProfile?.profile?.data?.id) &&
            !isHomePosition && (
              <ContextMenuOption
                label={"Make Home"}
                icon={homePositionIcon}
                onClick={withCloseContextMenu(makeItemHomeHandler)}
              />
            )}

          {Number(playlistItem?.user_id) ===
            Number(userProfile?.profile?.data?.id) && !isPlaylistInTier && (
              <ContextMenuOption
                label={"Delete"}
                icon={deleteImg}
                onClick={withCloseContextMenu(deleteHandler)}
              />
            )}
          
          {
            currentViewPrototype === VIEWS_PROTOTYPE.channels && (
              <ContextMenuOption
                label={"Add to your channel"}
                icon={addImg}
                onClick={withCloseContextMenu(onAddToPlaylist)}
              />
            )
          }

          {
            currentViewPrototype === VIEWS_PROTOTYPE.playlists && (
              <ContextMenuOption
                label="Add Item"
                icon={addImg}
                onClick={withCloseContextMenu(openAddVideoHandler)}
              />
          )}

          <ContextMenuOption
            label={"Copy Item"}
            icon={copyImg}
            onClick={withCloseContextMenu(copyVideo)}
          />

          <ContextMenuOption
            label={"Copy Series"}
            icon={copyImg}
            onClick={withCloseContextMenu(copySerie)}
          />

          {(copiedPlaylist?.type === COPY_ITEM.PLAYLIST_VIDEO ||
           copiedPlaylist?.type === COPY_ITEM.EPG_ITEM) &&
           isPasteable && (
            <>
              <ContextMenuOption
                label={"Paste Left"}
                icon={addImg}
                onClick={withCloseContextMenu(() => handlePaste("Left"))}
              />
              <ContextMenuOption
                label={"Paste Right"}
                icon={addImg}
                onClick={withCloseContextMenu(() => handlePaste("Right"))}
              />
            </>
          )}

          {(copiedPlaylist?.type === COPY_ITEM.PLAYLIST_SERIE ||
            copiedPlaylist?.type === COPY_ITEM.EPG ||
            copiedPlaylist?.type === COPY_ITEM.TV_CHANNEL) &&
           isPasteable && (
            <>
              <ContextMenuOption
                label={"Paste Series Above"}
                icon={addImg}
                onClick={withCloseContextMenu(() => handlePaste("Above"))}
              />
              <ContextMenuOption
                label={"Paste Series Below"}
                icon={addImg}
                onClick={withCloseContextMenu(() => handlePaste("Below"))}
              />
            </>
          )}

          {location.pathname === "/playlists" &&
            location.search.split("=")[1] !==
            playlists?.watchLater[0]?.id?.toString() && (
              <ContextMenuOption
                label={t("add_this_item_to_watch_later")}
                icon={addImg}
                onClick={withCloseContextMenu(watchLaterHandler)}
              />
            )}

          {Number(playlistItem?.user_id) ===
            Number(userProfile?.profile?.data?.id) && !isPlaylistInTier && (
              <ContextMenuOption
                icon={moveImg}
                label="Move this series"
                onClick={withCloseContextMenu(() =>
                  setIsPlaylistArrangeMode((prev) => !prev)
                )}
              />
            )}

          {Number(playlistItem?.user_id) ===
            Number(userProfile?.profile?.data?.id) && !isPlaylistInTier && (
              <ContextMenuOption
                icon={moveImg}
                label="Move this item"
                onClick={withCloseContextMenu(() => {
                  setIsItemsArrangeMode((prev) => !prev);
                })}
              />
            )}
        </>
      )}
      {!isSeriesCard && (
        <ContextMenuOption
          icon={shareImg}
          label="Share"
          onClick={withCloseContextMenu(sharePlaylistHandler)}
        />
      )}

      {!playlistItem?.direct_publish && (
        <ContextMenuOption
          label="Direct share"
          icon={addImg}
          onClick={withCloseContextMenu(directShareHandler)}
        />
      )}

      {location.search.split("=")[1] !==
        playlists?.watchLater[0]?.id?.toString() &&
        location.pathname !== "/my-playlists" &&
        isSeriesCard && (
          <ContextMenuOption
            label={t("add_this_series_to_watch_later")}
            icon={addImg}
            onClick={withCloseContextMenu(watchLaterSeriesHandler)}
          />
        )}
    </>
  );

  return (
    <>
      {hasHomePosition && (
        <HomePositionPrototype className="playlist-card-home-position" />
      )}
      {isVisible && (
        <ContextMenuPrototype
          isVisible={isVisible}
          xPos={xPos}
          yPos={yPos}
          handleClose={closeContextMenu}
          arrowDir={arrowDirection.right}
        >
          {contextmenuOptions}
        </ContextMenuPrototype>
      )}

      <Card
        className="playlist-card"
        cover={
          <div data-aos="zoom-in">
            <Image
              onClick={onClick}
              src={coverImg}
              alt="channel cover"
              onContextMenu={openContextMenu}
              onTouchStart={contextMenuClickWithDebounce}
              onTouchEnd={onTouchEnd}
            />
          </div>
        }
      >
        <CardMeta className="channel-card-meta" title={metaInfo}>
          meta info
        </CardMeta>
        <InfoBoxPrototype
          title={infoBoxSubtitle}
          subTitle={title}
          description={description}
          isMoreInfoOpened={moreButtons?.isMoreInfoOpened}
          moreInfo={moreButtons}
          contextmenuOptions={contextmenuOptions}
          hasThreeDotMenu={hasThreeDotMenu}
          infoboxClickHandler={infoboxClickHandler}
          isContextMenuVisible={isVisible}
          moreButtons={moreButtons}
          goToTitle={moreButtons?.goToTitle}
          goTo={moreButtons?.goToLink}
          moreInfoTitle={moreButtons?.moreInfoTitle}
          moreInfoClickHandler={moreButtons?.moreInfoClickHandler}
          isSeriesHomeItem={isHomePosition}
          openContextMenu={openContextMenu}
          onTouchStart={contextMenuClickWithDebounce}
          onTouchEnd={onTouchEnd}
          onTouchMove={onTouchMove}
        />
      </Card>
    </>
  );
};

export default PlaylistVideoCard;
