import { CHOICE_URL } from "@modules/config";
import { axiosInstance } from "@modules/request/api";
import {
    COPIED_TIRES_REQUEST,
    ARRAGNE_SHORTCUTS, 
    COPIED_TIRES_SUCCESS,
    COPIED_TIRES_FAIL,
    ARRAGNE_SHORTCUTS_SUCCESS,
    ARRAGNE_SHORTCUTS_REQUEST,
    ARRAGNE_SHORTCUTS_FAIL,
    DELETE_SHORTCUTS_REQUEST,
    DELETE_SHORTCUTS_SUCCESS,
    DELETE_SHORTCUTS_FAIL
} from "@redux/constants/shortcutConstants"
import store from "@redux/store";

export const getCopiedTiers = () => async (dispatch) => {
    try {
      dispatch({ type: COPIED_TIRES_REQUEST });
      const { data } = await axiosInstance().get(
        `${CHOICE_URL}/api/shortcuts`
      );
      dispatch({ type: COPIED_TIRES_SUCCESS, payload: data.shortcuts });
    } catch (error) {
      dispatch({ type: COPIED_TIRES_FAIL, payload: error.response });
    }
  };

export const arrangeShortCuts = (payload) => (dispatch) => {
    dispatch({
      type: ARRAGNE_SHORTCUTS,
      payload,
    })
    const shortcutIds = store.getState().copiedTiers.copiedTiers.map(({ id }) => id);
    dispatch(arrangeShortCutsHttp({ shortcutIds }));
  }

export const arrangeShortCutsHttp = (payload) => async (dispatch) => {
    try {
      dispatch({ type: ARRAGNE_SHORTCUTS_REQUEST });
      const { data } = await axiosInstance().post(
        `${CHOICE_URL}/api/shortcuts/rearrange`,
        {
          shortcuts:  payload.shortcutIds
        }
      );
      dispatch({ type: ARRAGNE_SHORTCUTS_SUCCESS, payload: data.shortcuts });
      getCopiedTiers();
    } catch (error) {
      dispatch({ type: ARRAGNE_SHORTCUTS_FAIL, payload: error.response });
    }
};

export const deleteShortCut = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SHORTCUTS_REQUEST });

    const { data } = await axiosInstance().delete(
      `${CHOICE_URL}/api/shortcuts/${id}`
    );
    dispatch({ type: DELETE_SHORTCUTS_SUCCESS, payload: data });
    dispatch(getCopiedTiers());
  } catch (error) {
    dispatch({
      type: DELETE_SHORTCUTS_FAIL,
      payload: error.response,
    });
  }
};