import React, { useEffect, useContext } from "react";
import { socialFollowingPlaylists } from "@redux/actions/socialActions";
import { useSelector, useDispatch } from "react-redux";
import List from "@shared/atoms/List/index";
import SubBarItemPrototype from "@shared/molecules/listItems/SubBarItemPrototype";
import SpinnerPrototype from "@shared/atoms/SpinnerPrototype";
import {
  getPlaylists,
  getDefaultPlaylists,
  getNotFollowedPlaylistsHistory,
} from "../../../../../redux/actions/playlistsActions";
import { useHistory, useLocation } from "react-router-dom";
import { Context } from "@context/context";
import ArrangeMode from "@components/shared/molecules/ArrangeMode";
import { selectPlaylists } from "@redux/reducers/playlistsReducer";

import HomePositionPrototype from "@components/shared/molecules/HomePositionPrototype";
import WatchLater from "@components/shared/atoms/icons/watchLater";
import { localStorageService } from "@modules/services/localStorage.service";
import { saveLatestPlaylistId } from "@utils/saveLatestPlaylistId";
import querystring from "query-string";
import { DroppableTypes } from "@context/dndContextPrototype";

import "./index.less";

const ChannelListPrototype = () => {
  const dispatch = useDispatch();

  // const searchbarRef = useRef(null);

  const history = useHistory();
  const { search } = useLocation();

  const isLoggedIn = !!localStorageService().get("access_token");
  const notFollowedPlaylist = localStorageService().get(
    "not-followed-playlist"
  );


  const parsedQueryString = querystring.parse(search);
  const playlistIdFromUrl = parsedQueryString.playlist;

  const {
    setShowTermsImagesPrototype,
    // subbarRefPrototype,
  } = useContext(Context);

  const { defaultPlaylists } = useSelector((state) => state.defaultPlaylists);
  const { data: notFollowedPlaylistsHistory } = useSelector(
    (state) => state.notFollowedPlaylistsHistory
  );

  const { playlists, loading: loadingPlaylists } = useSelector(
    selectPlaylists
    // (state) => state.playlists
  );

  const { playlists: followedPlaylists, loading: loadingFollowedPlaylists } =
    useSelector((state) => state.following);

  // const [, rightPlaylist] = useReorderByDirection(followedPlaylists?.playlists);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(getDefaultPlaylists());
    } else {
      dispatch(getPlaylists());
      dispatch(socialFollowingPlaylists());
    }
  }, [dispatch, isLoggedIn]);

  const playlistClickHandler = (id, type) => {
    if (type == 'watchlater') {
      var element = document.getElementById("watchlaterbutton");
      if (element) {
        element.classList.add("active");
      }
    } else {
      var element = document.getElementById("watchlaterbutton");
      if (element) {
        element.classList.remove("active");
      }
    }
    history.push({ search: `?playlist=${id}` });
    saveLatestPlaylistId(id, "globe");
    setShowTermsImagesPrototype(false);
  };

  const watchListClickHandler = (id) => {
    history.push({ search: `?playlist=${id}` });
    saveLatestPlaylistId(id, "globe");
    setShowTermsImagesPrototype(false);
    // $('')
  };

  // const showSearchHandler = () => {
  //   searchbarRef.current.classList.remove("hidden");
  //   subbarRefPrototype.current.style.overflow = "hidden";
  // };

  const homePositionClickHandler = () => {
    history.push("/playlists");
    setShowTermsImagesPrototype(true);
  };

  // const recentViewClickHandler = () => {
  //   history.push({ search: `?recent-view=true` });
  //   setShowTermsImagesPrototype(false);
  //   // dispatch(getViewList())
  // };

  return (
    <List className="channel-preview-list">
      {isLoggedIn && (
        <>
          {/* <RecentViews onClick={recentViewClickHandler} /> */}
          {playlists?.watchLater.map((item) => (
            <WatchLater onClick={() => playlistClickHandler(item.id, 'watchlater')} />
          ))}
        </>
      )}
      {notFollowedPlaylist && (
        <div className="playlists-not-followed-history-list">
          <SubBarItemPrototype
            itemClick={() => {
              playlistClickHandler(notFollowedPlaylist.id, '');
            }}
            content={notFollowedPlaylist.name}
            image={notFollowedPlaylist.icon}
            active={
              String(playlistIdFromUrl) === String(notFollowedPlaylist.id)
            }
            item={notFollowedPlaylist}
          />
        </div>
      )}

      <HomePositionPrototype onClick={homePositionClickHandler} />

      {/* <SearchBarPrototype ref={searchbarRef} /> */}
      {/* <SubBarItemPrototype
        isSearch
        content={t("search_navbar")}
        image={searchImage}
        showSearch={showSearchHandler}
      /> */}

      {/* <SearchModalPrototype isPlaylistSearch={true} /> */}

      {(loadingPlaylists || loadingFollowedPlaylists) && (
        <SpinnerPrototype className="spinner" size={3} />
      )}

      {/* {followedPlaylists?.playlists?.map(
        (playlist) =>
          !playlist.is_default &&
          !playlist.watch_later && (
            <SubBarItemPrototype
              itemClick={() => {
                playlistClickHandler(playlist.id);
              }}
              content={playlist.name}
              image={playlist.icon}
            />
          )
      )} */}
      {!isLoggedIn && (
        <div className="default-playlists-list">
          {defaultPlaylists?.map((playlist) => (
            <SubBarItemPrototype
              itemClick={() => {
                playlistClickHandler(playlist.id, '');
              }}
              content={playlist.name}
              image={playlist.icon}
              active={String(playlistIdFromUrl) === String(playlist.id)}
              item={playlist}
              isDefaultPlaylist={true}
            />
          ))}
        </div>
      )}

      {isLoggedIn && followedPlaylists?.playlists && !loadingFollowedPlaylists && (
        <>
          <ArrangeMode
            content={followedPlaylists?.playlists}
            droppableId={DroppableTypes.Follow}
          >
            {followedPlaylists?.playlists?.map((playlist) => (
              <SubBarItemPrototype
                itemClick={() => playlistClickHandler(playlist.id, '')}
                content={playlist.name}
                image={playlist.icon}
                item={playlist}
                key={playlist.id}
                hasButtons={playlist.has_buttons}
                active={String(playlistIdFromUrl) === String(playlist.id)}
                isFollowed
              />
            ))}
          </ArrangeMode>
        </>
      )}
    </List>
  );
};

export default ChannelListPrototype;
