import React from "react";
import Modal from "@shared/atoms/Modal/index";
import { Paragraph } from "@shared/atoms/Typography/index";
import Image from "@shared/atoms/Image/index";
import warningIcon from "@assets/icons/warning.svg";
import "./index.less";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { Context } from "@context/context";

// import { useTranslation } from "react-i18next";

const AlertModalPrototype = ({ isVisible, closeHandler }) => {
  const { t } = useTranslation();

  const {
    showAlertModalPrototype,
    setShowAlertModalPrototype
  } = useContext(Context)

  return (
    <Modal
      visible={isVisible ? isVisible : showAlertModalPrototype}
      className="modal-basic-prototype alert-modal-prototype"
      mask={true}
      footer={<></>}
      closable={true}
      onCancel={() => {
        closeHandler();
        //setShowHomeContent(false);
        setShowAlertModalPrototype(false);
      }}
    >
      <div>
        <Image className="warning-img" src={warningIcon} alt="warning" />
        <Paragraph>{t("login_alert_modal_prototype")}</Paragraph>
      </div>
    </Modal>
  );
};

export default AlertModalPrototype;
