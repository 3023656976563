import { setInvalidCountryError } from "@redux/actions/countryActions";
import { getStoreDispatch } from "@redux/store";
import axios from "axios";
import { localStorageService } from "../services/localStorage.service";

let language = localStorageService().get("lang") ?? "en";

const isInvalidCountry = (errObj) => errObj.response.status === 427;

const dispatch = getStoreDispatch();

export const axiosInstance = (
  tokenConfig = { withToken: true, basicToken: false }
) => {
  const checkToken = () => {
    if (!localStorageService().get("access_token")) {
      return false;
    } else {
      return true;
    }
  };

  let hasToken = checkToken();

  let options;
  if (
    hasToken &&
    tokenConfig.withToken === true &&
    tokenConfig.basicToken === false
  ) {
    options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorageService().get("access_token"),
        "Accept-Language": language,
      },
    };
  } else {
    if (tokenConfig.basicToken) {
      options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic Y2hvaWNlOkNob2ljZTIwMjEh",
          "Accept-Language": language,
        },
      };
    } else {
      options = {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": language,
        },
      };
    }
  }

  let instance = axios.create(options);

  instance.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      // @TODO check error codes and redirect if not authorized user
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (isInvalidCountry(error)) {
        dispatch(setInvalidCountryError());
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

if (process.env.NODE_ENV === "development") {
  window.get = function axiosGet(url) {
    return axiosInstance().get(`https://api.dev.watchchoice.tv/api${url}`);
  };
}
