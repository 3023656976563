import React, { useState } from "react";
import { useSelector } from "react-redux";
import AuthLayout from "../../layout/AuthLayout/index";
import { Row, Col } from "antd";
import "./index.less";
import AuthHeading from "@components/organisms/AuthHeading";
import AuthShowcase from "@components/organisms/AuthShowcase";
import RegistrationForm from "@components/organisms/forms/RegisterForm/index";
// import SocialLogin from "@components/organisms/SocialLogin";
import AuthHeaderMobile from "../../components/shared/molecules/AuthHeaderMobile";
import RegisterFormMobile from "../../components/organisms/forms/RegisterForm/mobile";
import { isMobile } from "react-device-detect";

export const Registration = () => {
    const [isSendConfirmationCode, setIsSendConfirmationCode] = useState(false);


    const userRegister = useSelector((state) => state.userRegister);
    const { userInfo } = userRegister;

    const switchConfirmHandler = () => {
        setIsSendConfirmationCode(!isSendConfirmationCode);
    };

    return (
        <>
            {!isMobile ? (
                <AuthLayout>
                    <Row className="register-row">
                        <Col className="auth-dark-col" span={26}>
                            <AuthShowcase isRegistrationPage={true} />
                        </Col>
                        <Col
                            className="auth-light-col auth-gradient-bg-2"
                            span={14}
                        >
                            <AuthHeading
                                hideLogoOnSmallScreen={true}
                                isRegistrationPage={true}
                                isSendConfirmationCode={isSendConfirmationCode}
                            />
                            <RegistrationForm
                                switchConfirm={userInfo && switchConfirmHandler}
                                isSendConfirmationCode={isSendConfirmationCode}
                            />
                            {/* {!userInfo && (
                                <SocialLogin
                                    responseGoogle={responseGoogle}
                                    responseFacebook={responseFacebook}
                                />
                            )} */}
                        </Col>
                    </Row>
                </AuthLayout>
            ) : (
                <AuthLayout className="auth-mobile-layout">
                    <AuthHeaderMobile />
                    <RegisterFormMobile />
                </AuthLayout>
            )}
        </>
    );
};
