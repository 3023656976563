import React from "react";
import TermsOfUsePrototype from "@shared/molecules/TermsOfUsePrototype"

import "./index.less";
import Layout from "antd/lib/layout/layout";
// import TermsOfUse from "../../components/shared/molecules/TermsOfUse";
import DesktopPrototype from "@components/organisms/DesktopPrototype";
import { isMobile } from "react-device-detect";

export const Terms = () => {

    return (
        <>
            {isMobile ? <Layout className={'mobile-layout-prototype'}>
                <TermsOfUsePrototype />
            </Layout> : (
                <DesktopPrototype>
                <TermsOfUsePrototype />
                </DesktopPrototype>
            )}
        </>

    );
};
