import { Context } from "@context/context";
import { localStorageService } from "@modules/services/localStorage.service";
import {
  deleteTeamUser,
  getTeam,
  toggleTeamAdmin,
} from "@redux/actions/TeamActions";
import Modal from "@shared/atoms/Modal/index";
import { Paragraph } from "@shared/atoms/Typography/index";
import { LOCALSTORAGE } from "const";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleWarningModal from "../SimpleWarningModal";

import "./index.less";

const EditTeamMemberModal = ({
  text,
  isVisible,
  onCancel,
  user,
  adminList = [],
}) => {
  const {
    setIsEditUserModalVisible,
    setShowSimpleWarningModal,
    setShowWarningText,
    showSimpleWarningModal,
    showWarningText,
  } = useContext(Context);

  const [modalTitle, setModalTitle] = useState("");

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.teamItem);
  const { profile } = useSelector((state) => state.userProfile);

  const loggedInUser = profile?.data?.id;

  const teamItem = data?.data;

  // checking if the current editable user is admin or not
  const isAdmin = () => {
    return user?.is_admin;
  };

  let isUserAdmin = isAdmin();
  // console.log('Editable user admin status -> ', isUserAdmin);

  // checking if the current user is admin or not
  const isLoggedInUserAdmin = adminList.find((user) => user === loggedInUser);
  // console.log('Logged In user -> ', isLoggedInUserAdmin);

  const onAddAdmin = () => {
    if (isLoggedInUserAdmin && !isUserAdmin) {
      dispatch(toggleTeamAdmin(teamItem?.id, user?.id, true)).then(() => {
        dispatch(getTeam(teamItem?.id));
      });
    }

    if (isLoggedInUserAdmin && isUserAdmin) {
      dispatch(toggleTeamAdmin(teamItem?.id, user?.id, false)).then(() => {
        dispatch(getTeam(teamItem?.id));
      });
    }

    onCancel();
  };

  const onRemoveAdmin = () => {
    console.log(adminList.length >= 1 && isLoggedInUserAdmin);
    if (adminList.length === 1 && isLoggedInUserAdmin) {
      setShowSimpleWarningModal(true);
      setShowWarningText(
        "You can not remove yourself from admin unless there is more than 1 admin"
      );
    } else {
      dispatch(toggleTeamAdmin(teamItem?.id, user?.id, false)).then(() => {
        dispatch(getTeam(teamItem?.id));
        onCancel();
      });
    }

    console.log("deleting an admin user");
  };

  const onAdminRemoveAdmin = () => {
    // dispatch(toggleTeamAdmin());
  };

  const onDeleUser = () => {
    Promise.resolve(dispatch(deleteTeamUser(teamItem?.id, user?.id))).then(
      () => {
        dispatch(getTeam(localStorageService().get(LOCALSTORAGE.TEAM_ID)));
        setIsEditUserModalVisible(false);
      }
    );
  };

  useEffect(() => {
    if (
      user?.id === profile?.data?.id &&
      adminList.length === 1 &&
      teamItem?.users.length === 1
    ) {
      setModalTitle(`You are the last member of this team. 
      If you remove yourself the team and content will remain 
      on choice as an orphan. If you don’t want that please 
      delete first the team and all its content`);
    }
  }, [adminList, profile, user]);

  return (
    <>
      <Modal
        visible={isVisible}
        className="modal-basic-prototype edit-team-member-modal"
        mask={true}
        closable={true}
        onCancel={onCancel}
        footer={
          <>
            <div className="edit-team-member-modal-footer">
              {onAddAdmin &&
                !modalTitle &&
                adminList.length >= 1 &&
                !isUserAdmin &&
                isLoggedInUserAdmin && (
                  <button className="accept" onClick={onAddAdmin}>
                    Make Admin
                  </button>
                )}

              {(onAddAdmin &&
                adminList.length >= 1 &&
                isLoggedInUserAdmin === 0) ||
              (user?.id !== loggedInUser &&
                isLoggedInUserAdmin == undefined) ? (
                <span className="permission-text">
                  No permission for this action
                </span>
              ) : null}

              {onRemoveAdmin &&
              !modalTitle &&
              adminList.length >= 1 &&
              isLoggedInUserAdmin &&
              isUserAdmin &&
              loggedInUser === user?.id ? (
                <button onClick={onAdminRemoveAdmin}>
                  Remove Myself From Admin
                </button>
              ) : null}

              {onRemoveAdmin &&
              !modalTitle &&
              adminList.length >= 1 &&
              isLoggedInUserAdmin &&
              isUserAdmin &&
              loggedInUser !== user?.id ? (
                <button onClick={onRemoveAdmin}>Remove From Admin</button>
              ) : null}

              {onDeleUser &&
              isLoggedInUserAdmin &&
              !isUserAdmin &&
              !modalTitle ? (
                <button onClick={onDeleUser}>Remove From Team</button>
              ) : null}

              {onDeleUser && isLoggedInUserAdmin && modalTitle ? (
                <button onClick={onDeleUser}>Remove Myself</button>
              ) : null}
            </div>
          </>
        }
      >
        <div>
          <Paragraph>{modalTitle ? modalTitle : text}</Paragraph>
        </div>
      </Modal>
      );
      <SimpleWarningModal
        visible={showSimpleWarningModal}
        text={showWarningText}
        onCancel={() => setShowSimpleWarningModal(false)}
      />
    </>
  );
};

export default EditTeamMemberModal;
