export const CREATE_TEAM_REQUEST = "CREATE_TEAM_REQUEST";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_FAIL = "CREATE_TEAM_FAIL";

export const GET_TEAMS_REQUEST = "GET_TEAMS_REQUEST";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_FAIL = "GET_TEAMS_FAIL";

export const GET_TEAM_REQUEST = "GET_TEAM_REQUEST";
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const GET_TEAM_FAIL = "GET_TEAM_FAIL";

export const UPDATE_TEAM_REQUEST = "UPDATE_TEAM_REQUEST";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_FAIL = "UPDATE_TEAM_FAIL";

export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL";

// TEAM USERS
export const TOGGLE_TEAM_ADMIN_REQUEST = "TOGGLE_TEAM_ADMIN_REQUEST";
export const TOGGLE_TEAM_ADMIN_SUCCESS = "TOGGLE_TEAM_ADMIN_SUCCESS";
export const TOGGLE_TEAM_ADMIN_FAIL = "TOGGLE_TEAM_ADMIN_FAIL";

export const DELETE_TEAM_USER_REQUEST = "DELETE_TEAM_USER_REQUEST";
export const DELETE_TEAM_USER_SUCCESS = "DELETE_TEAM_USER_SUCCESS";
export const DELETE_TEAM_USER_FAIL = "DELETE_TEAM_USER_FAIL";

export const APPROVE_TEAM_REQUEST = "APPROVE_TEAM_REQUEST";
export const APPROVE_TEAM_SUCCESS = "APPROVE_TEAM_SUCCESS";
export const APPROVE_TEAM_FAIL = "APPROVE_TEAM_FAIL";

export const LEAVE_TEAM_REQUEST = "LEAVE_TEAM_REQUEST";
export const LEAVE_TEAM_SUCCESS = "LEAVE_TEAM_SUCCESS";
export const LEAVE_TEAM_FAIL = "LEAVE_TEAM_FAIL";

export const DELETE_TEAM_PLAYLIST_REQUEST = "DELETE_TEAM_PLAYLIST_REQUEST";
export const DELETE_TEAM_PLAYLIST_SUCCESS = "DELETE_TEAM_PLAYLIST_SUCCESS";
export const DELETE_TEAM_PLAYLIST_FAIL = "DELETE_TEAM_PLAYLIST_FAIL";

export const APPROVE_DELETE_ADMIN_REQUEST = "APPROVE_DELETE_ADMIN_REQUEST";
export const APPROVE_DELETE_ADMIN_SUCCESS = "APPROVE_DELETE_ADMIN_SUCCESS";
export const APPROVE_DELETE_ADMIN_FAIL = "APPROVE_DELETE_ADMIN_FAIL";
