import { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CSVLink } from 'react-csv'
import { Paragraph, Title } from "../../shared/atoms/Typography";
import SpinnerPrototype from "../../shared/atoms/SpinnerPrototype";
import Image from "../../shared/atoms/Image";
import CopySVG from "@assets/icons/copy.svg";
import {
  exportPlaylistStatistics,
  getBroadcastStatistics,
  getPlaylistStatistics,
  getStatistics,
} from "../../../../redux/actions/statisticsActions";
import { useHistory, useLocation } from "react-router-dom";
import { Context } from "@context/context";
import PlaylistStatisticChart from "../charts/PlaylistStatisticChart";
import DatePicker from "../../shared/atoms/DatePicker";
import querystring from "query-string"
import moment from "moment";
import Radio from "@shared/atoms/Radio";
import Space from "@components/shared/atoms/Space";
import PlaylistStatisticCalendar from "../calendar/PlaylistStatisticCalendar";
import Spacer from "@components/shared/atoms/Spacer";
import Button from "@components/shared/atoms/Button";

import "./index.less";


const initialStartDate = moment(new Date()).subtract(1, 'week').format("YYYY-MM-DD")
const initialEndDate = moment(new Date()).format("YYYY-MM-DD")


const StatisticsPrototype = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const { search } = useLocation();

  const parsedQueryString = querystring.parse(search);


  const [broadcastDate, setBroadcastDate] = useState(
    moment().format("YYYY-MM")
  );
  const [groupBy, setGroupBy] = useState("day")
  const [calendarStartDate, setCalendarStartDate] = useState(initialStartDate)
  const [calendarEndDate, setCalendarEndDate] = useState(initialEndDate)

  const csvLink = useRef(null)


  const { profile } = useSelector((state) => state.userProfile);
  const { data: statistics, loading: statisticsLoading } = useSelector(
    (state) => state.statistics
  );

  const { data: broadcastStatistics, loading: broadcastStatisticsLoading } =
    useSelector((state) => state.broadcastStatistics);
  const { data: exportedData } = useSelector(state => state.exportPlaylistStatistics)

  const { showPlaylistStatistics,
    setShowPlaylistStatistics } = useContext(Context)

  const { data: playlistChartData, loading: playlistChartDataLoading } = useSelector(state => state.playlistStatistics)



  useEffect(() => {
    if (profile && profile.data) {
      profile.data.is_broadcaster
        ? dispatch(getBroadcastStatistics(broadcastDate))
        : dispatch(getStatistics());
    }
  }, [dispatch, broadcastDate, profile]);


  useEffect(() => {
    if (parsedQueryString?.playlist) {
      setShowPlaylistStatistics(true)
    } else {
      setShowPlaylistStatistics(false)
    }
  }, [parsedQueryString, setShowPlaylistStatistics])

  useEffect(() => {
    dispatch(getPlaylistStatistics(parsedQueryString?.playlist, calendarStartDate, calendarEndDate, "day"))
  }, [dispatch, parsedQueryString?.playlist, calendarEndDate, calendarStartDate])

  const onDateChange = (dateToString) => {
    if (dateToString) {
      setBroadcastDate(moment(dateToString).format("YYYY-MM"));
    }
  };

  const copyBroadcastStats = (channelStats) => {
    let text = `${channelStats.channel} Followed: ${channelStats.followed_count} Shares: ${channelStats.sharings_count} Unique Views: ${channelStats.unique_viewers_count} Total Views: ${channelStats.viewers_count} `;
    navigator.clipboard.writeText(text);
  };

  const copyStats = (channelStats) => {
    let text = `${channelStats.name} - ${channelStats.view_count} views`;
    navigator.clipboard.writeText(text);
  };

  const playlistClickHandler = (playlist) => {
    history.push({ search: `?playlist=${playlist.id}` })
  }

  const GroupByStatisticChange = (e) => {
    setGroupBy(e.target.value);
    dispatch(getPlaylistStatistics(parsedQueryString?.playlist, calendarStartDate, calendarEndDate, e.target.value))
  };

  const exportPlaylistStatisticsHandler = () => {
    Promise.resolve(dispatch(exportPlaylistStatistics(parsedQueryString?.playlist, calendarStartDate, calendarEndDate, groupBy)))
      .then(() => {
        csvLink.current.link.click()
      })
  }

  return (
    <div className="statistics-container-prototype">
      <Title className="heading">{t("statistics")}</Title>
      {(statisticsLoading || broadcastStatisticsLoading || playlistChartDataLoading) && (
        <SpinnerPrototype />
      )}
      {profile && !profile?.data.is_broadcaster && !statisticsLoading && (
        <>
          {statistics?.playlists && profile && !showPlaylistStatistics && (
            <div className="statistics-content">
              {statistics?.playlists?.map((channel) => {
                return (
                  <div onClick={() => playlistClickHandler(channel)} className="item-container">
                    <Paragraph className="p" key={channel.id}>
                      <span>{`${channel.name} - ${channel?.view_count === 1 || channel?.view_count === 0
                        ? channel?.view_count + ` ${t("playlist_view")}`
                        : channel?.view_count + ` ${t("playlist_views")}`
                        }`}</span>
                    </Paragraph>
                    <Image
                      onClick={() => copyStats(channel)}
                      src={CopySVG}
                      className="copy-image"
                      alt="copy"
                    />
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
      {profile && profile?.data.is_broadcaster && !broadcastStatisticsLoading && (
        <div className="statistics-content">
          <div className="datepicker-container">
            <DatePicker
              dateFormat="MM.yyyy"
              showMonthYearPicker
              selected={moment(broadcastDate).toDate()}
              onChange={(date) => onDateChange(date)}
            />
          </div>

          {broadcastStatistics?.channels?.map((channel) => {
            return (
              <div className="broadcast-container" key={channel.id}>
                <div className="heading-container">
                  <Title className="broadcast-title">{channel.channel}</Title>
                  <Image
                    onClick={() => copyBroadcastStats(channel)}
                    src={CopySVG}
                    className="copy-image"
                    alt="copy"
                  />
                </div>

                <div className="broadcast-container-content">
                  <Paragraph className="broadcast-para">{`Followed by: ${channel.followed_count}`}</Paragraph>
                  <Paragraph className="broadcast-para">{`Shares: ${channel.sharings_count}`}</Paragraph>
                  <Paragraph className="broadcast-para">{`Unique Views: ${channel.unique_viewers_count}`}</Paragraph>
                  <Paragraph className="broadcast-para">{`Total Views: ${channel.viewers_count}`}</Paragraph>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {showPlaylistStatistics &&
        <div className="playlist-statistics-details">
          <div>
            <PlaylistStatisticCalendar
              setCalendarStartDate={setCalendarStartDate}
              setCalendarEndDate={setCalendarEndDate}
            />
          </div>
          <div className="group-by-radio-container">
            <label className="group-playlist-label">{t("group_statistics_by")}:</label>
            <Radio.Group disabled={playlistChartDataLoading} className="group-playlist-stats" onChange={GroupByStatisticChange} value={groupBy}>
              <Space direction="vertical">
                <Radio value={"day"}>{t("day")}</Radio>
                <Radio value={"week"}>{t("week")}</Radio>
              </Space>
            </Radio.Group>
          </div>
          {
            playlistChartData && (
              <>
                <Button onClick={exportPlaylistStatisticsHandler} className='response-buttons-prototype export-btn'>
                  {t("export")}
                </Button>
                {
                  exportedData && (
                    <CSVLink
                      data={exportedData}
                      filename='statistics.csv'
                      className='hidden'
                      ref={csvLink}
                      target='_blank'
                    />
                  )
                }
              </>
            )
          }

          {playlistChartData?.follows.length > 0 && <PlaylistStatisticChart
            chartName="Follows"
            dataSetName="Follows"
            labels={playlistChartData?.follows?.map(view => view.date)}
            dataSetData={playlistChartData?.follows?.map(view => view.count)} />
          }

          {playlistChartData?.newfollows?.length > 0 && <PlaylistStatisticChart
            chartName="new follows"
            dataSetName="new follows"
            labels={playlistChartData?.newfollows?.map(view => view.date)}
            dataSetData={playlistChartData?.newfollows?.map(view => view.count)} />
          }
          {playlistChartData?.unfollows?.length > 0 && <PlaylistStatisticChart
            chartName="Unfollows"
            dataSetName="Unfollows"
            labels={playlistChartData?.unfollows?.map(view => view.date)}
            dataSetData={playlistChartData?.unfollows?.map(view => view.count)} />
          }
          {playlistChartData?.goToClicks?.length > 0 && <PlaylistStatisticChart
            chartName="Go to clicks"
            dataSetName="Go to clicks"
            labels={playlistChartData?.goToClicks?.map(view => view.date)}
            dataSetData={playlistChartData?.goToClicks?.map(view => view.count)} />
          }

          {playlistChartData?.views?.length > 0 &&
            <PlaylistStatisticChart
              chartName="Views"
              dataSetName="Views"
              labels={playlistChartData?.views?.map(view => view.date)}
              dataSetData={playlistChartData?.views?.map(view => view.count)} />
          }
          {playlistChartData?.uniqueViews?.length > 0 &&
            <PlaylistStatisticChart
              chartName="Unique views"
              dataSetName="Unique views"
              labels={playlistChartData?.uniqueViews?.map(view => view.date)}
              dataSetData={playlistChartData?.uniqueViews?.map(view => view.count)} />
          }
          {playlistChartData?.sharing?.length > 0 &&
            <PlaylistStatisticChart
              chartName="Sharing"
              dataSetName="Sharing"
              labels={playlistChartData?.sharing?.map(view => view.date)}
              dataSetData={playlistChartData?.sharing?.map(view => view.count)} />
          }
          {playlistChartData?.moreInfoClicks?.length > 0 &&
            <PlaylistStatisticChart
              chartName="More info clicks"
              dataSetName="More info clicks"
              labels={playlistChartData?.moreInfoClicks?.map(view => view.date)}
              dataSetData={playlistChartData?.moreInfoClicks?.map(view => view.count)} />
          }
        </div>
      }
      <Spacer />
    </div>
  );
};

export default StatisticsPrototype;
