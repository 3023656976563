import React, { useEffect, useContext, createRef, useState } from "react";
import querystring from "query-string";
import List from "@shared/atoms/List/index";
import SubBarItemPrototype from "@shared/molecules/listItems/SubBarItemPrototype";
import { useDispatch, useSelector } from "react-redux";
import { getTiers } from "@redux/actions/paymentActions";
import { getPromotedChannels } from "@redux/actions/channelActions";
import { useHistory, useLocation } from "react-router-dom";
import { Context } from "@context/context";
import SpinnerPrototype from "../../../shared/atoms/SpinnerPrototype";
// import { useTranslation } from "react-i18next";
import { EPG_CHANNELS_RESET } from "@redux/constants/channelConstants";
import HomePositionPrototype from "@components/shared/molecules/HomePositionPrototype";
import { localStorageService } from "@modules/services/localStorage.service";
import { LOCALSTORAGE } from "const";

import "./index.less"

const TierListPrototype = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const [openedTireId, setOpenedTireId] = useState();

  // const { t } = useTranslation();

  const parsedQueryString = querystring.parse(search);
  // const tvChannelIdFromUrl = parsedQueryString.channel;
  const tierIdFromUrl = parsedQueryString.tier;
  const playlistIdFromUrl = parsedQueryString.playlist;

  // const { t } = useTranslation();

  const { promotedChannels, loading: promotedChannlesLoading } = useSelector(
    (state) => state.promotedChannels
  );

  const { data: tierData, loading: tierLoading } = useSelector(
    (state) => state.tiers
  );

  const {
    setShowChannelsPrototype,
    setShowTermsImagesPrototype,
    setShowSubBarPrototype,
    setShowTierBuyModalPrototype,
    setTierPricePrototype,
    setTierPrototype,
    tierPrototype,
    tierListOpened,
    setTierListOpened,
  } = useContext(Context);

  const history = useHistory();

  useEffect(() => {
    const isUserAuthenticated = !!localStorageService().get(LOCALSTORAGE.ACCESS_TOKEN);
    dispatch(getTiers(isUserAuthenticated));
    dispatch(getPromotedChannels());
  }, [dispatch]);

  const tierClickHandler = (tier) => {
    if (!tier.is_free && !tier.is_subscribed) {
      setTierPricePrototype(tier.price);
    }
    setTierPrototype(tier);
  };

  const channelClickHandler = (channel, ref) => {
    let currentTier = tierData?.tiers?.find(
      (tier) => tier.id === Number(ref.current.dataset.tierid)
    );
    // dispatch({ type: EPG_CHANNELS_RESET });
    setTierPrototype(currentTier);
    if (
      tierPrototype?.is_free ||
      (!tierPrototype?.is_free && tierPrototype?.is_subscribed)
    ) {
      const playlistInTierClicked = currentTier.playlists.find(
        (playlist) => playlist.id === channel.id
      );
      if (playlistInTierClicked) {
        history.push({
          search: `?channel=${channel.id}&isPlaylistInTier=true`,
        });
      } else {
        history.push({ search: `?channel=${channel.id}` });
      }
      setShowChannelsPrototype(true);
      setShowTermsImagesPrototype(false);
      setShowSubBarPrototype(false);
    } else {
      setShowTierBuyModalPrototype(true);
    }
    localStorageService().set(LOCALSTORAGE.TIER_CHANNEL_ID, channel.id)
  };

  const handleTierOpen = (tierIndex) => {
    const tierListArray = [...tierListOpened];
    tierListArray[tierIndex] = !tierListArray[tierIndex];
    setTierListOpened(tierListArray);
  };

  const handletierHomeClick = () => {
    setShowTermsImagesPrototype(true);
    setShowChannelsPrototype(false);
    history.push("/tiers");
  };

  const playlistClickHandler = (id) => {
    history.push({ search: `?channel=${id}` });
    //saveLatestPlaylistId(id, "globe");
    setShowTermsImagesPrototype(false);
  };

  const handleTireItemClick = (tier) => {
    tierClickHandler(tier);
    setOpenedTireId((prev) => {
      if (prev === tier.id) return;
      else return tier.id;
    });
  };

  // useEffect(() => {
  //   if (!tierListOpened?.length) {
  //     if (tierData?.tiers?.length) {
  //       setTierListOpened(() => Array(tierData?.tiers?.length).fill(false));
  //     }
  //   }
  // }, [setTierListOpened, tierData?.tiers?.length, tierListOpened?.length]);

  useEffect(() => {
    if (tierIdFromUrl) {
      setOpenedTireId(+tierIdFromUrl);
    }
  }, [tierIdFromUrl]);

  return (
    <List className="tier-list-prototype package-preview-list">
      {promotedChannels && (
        <>
          {promotedChannels?.channels.map((playlist) => (
            <SubBarItemPrototype
              itemClick={() => playlistClickHandler(playlist.id)}
              content={playlist.name}
              image={playlist.logoId}
              item={playlist}
              key={playlist.id}
              hasButtons={playlist.has_buttons}
              active={String(playlistIdFromUrl) === String(playlist.id)}
              isPromotion={true}
            />
          ))}
        </>
      )}

      <HomePositionPrototype onClick={handletierHomeClick} />

      <>
        {(tierLoading || promotedChannlesLoading) && (
          <SpinnerPrototype size={3} />
        )}
      </>
      {!tierLoading &&
        tierData?.tiers.map((tier, index) => {
          return (
            <SubBarItemPrototype
              key={tier.id}
              openable={true}
              content={tier.name}
              image={tier.icon}
              openableData={[...tier.channels, ...tier.playlists]}
              fullDataLength={tier.channels.length + tier.playlists.length}
              tierId={tier.id}
              openableItemClick={channelClickHandler}
              itemClick={() => handleTireItemClick(tier)}
              onClick={() => handleTierOpen(index)}
              opened={openedTireId === tier.id}
              active={String(tierIdFromUrl) === String(tier.id)}
              tierIndex={index}
              isTier={true}
              item={tier}
              isOpenableTierContext={true}
            />
          );
        })}
    </List>
  );
};

export default TierListPrototype;
