
/**
 * function that shortens strings
 *
 * @param {String[]} arr -Starting array
 * @param {Number} fromIndex - element index to move from
 * @param {Number} toIndex - element index to move to
 * @returns {String[]} arr
 * @example
 *
 * shortenString("some big big string", 10)
 */
export function moveArray(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr
}