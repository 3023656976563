import React, { useContext, useEffect } from "react";
import Layout from "antd/lib/layout/layout";
// import MobileNavigation from "@components/organisms/MobileNavigation";
import AddNewVideoModal from "@components/organisms/modals/AddNewVideoModal";
// import MobileHeader from "../../components/organisms/MobileHeader/mobile";
import MobileNavPrototype from "../../components/organisms/MobileNavPrototype";
import DesktopPrototype from "@components/organisms/DesktopPrototype";
import { isMobile } from "react-device-detect";
import { Context } from "@context/context";

import "./index.less";

const MainLayout = ({ children, hasNavigation = true }) => {
  const { setShowSubBarPrototype } = useContext(Context);

  useEffect(() => {
    const hideSubbarMenu = () => {
      setShowSubBarPrototype(false);
    };
    if (document) {
      document.addEventListener("wheel", hideSubbarMenu);
    }
    return () => {
      document.removeEventListener("wheel", hideSubbarMenu);
    };
  }, [setShowSubBarPrototype]);

  return (
    <>
      <AddNewVideoModal />
      {!isMobile ? (
        <DesktopPrototype></DesktopPrototype>
      ) : (
        <>
          <Layout className={"mobile-layout-prototype"}>
            {children}
            {hasNavigation && <MobileNavPrototype />}
          </Layout>
        </>
      )}
    </>
  );
};

export default MainLayout;
