import React from "react";
import List from "@shared/atoms/List/index";

import "./index.less";

const FollowingVideoList = ({ children }) => {
    return <List className="following-videos-list">{children}</List>;
};

export default FollowingVideoList;
