import { useContext, useState } from "react";
import { Context } from "@context/context";

// @hooks
import { useContextMenu } from "hooks/useContextMenu";
import { useTier } from "hooks";

// @components
import Image from "@components/shared/atoms/Image";
import List from "@components/shared/atoms/List";
import { Paragraph } from "@components/shared/atoms/Typography";
import ContextMenuPrototype, {
  ContextMenuOption,
} from "@components/shared/molecules/ContextMenuPrototype";

// @assets
import arrangeImg from "@assets/icons/arrange.svg";
import deleteImg from "@assets/icons/delete.svg";
import copyImg from "@assets/icons/copy.svg";

// @utils
import { shortenString } from "@utils/shortenString";
import DeleteShortcutModalPrototype from "@components/organisms/modals/DeleteShortcutModalPrototype";

// @constants
import { COPY_ITEM } from "const/playlists";
import SubbarItem from "../SubbarItem";

const ShortcutSubbarItem = (props) => {
  const { item, index, image, onClick } = props;

  const [showShortCutDeleteModal, setShowShortCutDeleteModal] = useState(false);

  const { createTireCopy } = useTier();

  const { setIsShortcutArrangeMode, copiedPlaylist, setCopiedPlaylist } =
    useContext(Context);

  const {
    isVisible,
    closeContextMenu,
    xPos,
    yPos,
    openContextMenu,
    withCloseContextMenu,
    contextMenuClickWithDebounce,
    onTouchEnd,
  } = useContextMenu(".subbar-item-container");

  const handlePasteClick = (dir, order) => () => {
    const { copiedItem } = copiedPlaylist;
    createTireCopy(copiedItem, dir, order + 1);
    setCopiedPlaylist(false);
  };

  const handleDeleteClick = () => {
    setShowShortCutDeleteModal(true);
  };

  const handleMoveClick = () => {
    setIsShortcutArrangeMode(true);
  };

  return (
    <>
      <ContextMenuPrototype
        isVisible={isVisible}
        handleClose={closeContextMenu}
        xPos={xPos}
        yPos={yPos}
      >
        {copiedPlaylist?.type === COPY_ITEM.TV_CHANNEL && (
          <>
            <ContextMenuOption
              onClick={withCloseContextMenu(handlePasteClick("Left", index))}
              icon={copyImg}
              label="<- Paste"
            />
            <ContextMenuOption
              onClick={withCloseContextMenu(handlePasteClick("Right", index))}
              icon={copyImg}
              label="Paste ->"
            />
          </>
        )}
        <ContextMenuOption
          label="Delete"
          icon={deleteImg}
          onClick={withCloseContextMenu(handleDeleteClick)}
        />
        <ContextMenuOption
          onClick={withCloseContextMenu(handleMoveClick)}
          icon={arrangeImg}
          label="Move"
        />
      </ContextMenuPrototype>
      <SubbarItem
        {...props}
        contextHandler={{
          onContextMenu: openContextMenu,
          onTouchStart: contextMenuClickWithDebounce,
          onTouchEnd: onTouchEnd
        }}
        closeContextMenu={closeContextMenu}
      />
      <DeleteShortcutModalPrototype
        isVisible={showShortCutDeleteModal}
        setIsVisible={setShowShortCutDeleteModal}
        id={item?.id}
      />
    </>
  );
};

export default ShortcutSubbarItem;
