import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CookieInfo from "../../components/shared/atoms/CookieInfo";

import "./index.less";

export const Cookies = () => {
    const { t } = useTranslation();
    return (
        <section className="cookies-info">
            <Link className="go-back" to="/settings">
                {t("go_back")}
            </Link>
            <CookieInfo />
        </section>
    );
};
