import { axiosInstance } from "@modules/request/api";
import {
  PLAYLISTS_REQUEST,
  PLAYLISTS_SUCCESS,
  PLAYLISTS_FAIL,
  PLAYLIST_CREATE_REQUEST,
  PLAYLIST_CREATE_SUCCESS,
  PLAYLIST_CREATE_FAIL,
  PLAYLIST_DELETE_REQUEST,
  PLAYLIST_DELETE_SUCCESS,
  PLAYLIST_DELETE_FAIL,
  PLAYLIST_VIDEO_DELETE_REQUEST,
  PLAYLIST_VIDEO_DELETE_SUCCESS,
  PLAYLIST_VIDEO_DELETE_FAIL,
  ADD_VIDEOS_TO_PLAYLIST_REQUEST,
  ADD_VIDEOS_TO_PLAYLIST_SUCCESS,
  ADD_VIDEOS_TO_PLAYLIST_FAIL,
  UPDATE_PLAYLIST_REQUEST,
  UPDATE_PLAYLIST_SUCCESS,
  UPDATE_PLAYLIST_FAIL,
  MOVE_PLAYLIST_REQUEST,
  MOVE_PLAYLIST_SUCCESS,
  MOVE_PLAYLIST_FAIL,
  ADD_RELATED_VIDEO_REQUEST,
  ADD_RELATED_VIDEO_SUCCESS,
  ADD_RELATED_VIDEO_FAIL,
  RELATED_VIDEOS_REQUEST,
  RELATED_VIDEOS_SUCCESS,
  RELATED_VIDEOS_FAIL,
  PLAYLIST_BY_ID_REQUEST,
  PLAYLIST_BY_ID_FAIL,
  PLAYLIST_BY_ID_SUCCESS,
  DELETE_RELATED_VIDEO_REQUEST,
  DELETE_RELATED_VIDEO_SUCCESS,
  DELETE_RELATED_VIDEO_FAIL,
  PLAYLIST_WITH_RELATED_VIDEOS_REQUEST,
  PLAYLIST_WITH_RELATED_VIDEOS_SUCCESS,
  PLAYLIST_WITH_RELATED_VIDEOS_FAIL,
  SEARCH_PLAYLIST_REQUEST,
  SEARCH_PLAYLIST_SUCCESS,
  SEARCH_PLAYLIST_FAIL,
  PLAYLIST_REPORTS_REQUEST,
  PLAYLIST_REPORTS_SUCCESS,
  PLAYLIST_REPORTS_FAIL,
  PLAYLIST_REPORT_ADD_REQUEST,
  PLAYLIST_REPORT_ADD_SUCCESS,
  PLAYLIST_REPORT_ADD_FAIL,
  PLAYLIST_VIDEO_REORDER_REQUEST,
  PLAYLIST_VIDEO_REORDER_SUCCESS,
  PLAYLIST_VIDEO_REORDER_FAIL,
  PLAYLIST_KEYWORDS_REQUEST,
  PLAYLIST_KEYWORDS_SUCCESS,
  PLAYLIST_KEYWORDS_FAIL,
  PLAYLIST_STATUSES_REQUEST,
  PLAYLIST_STATUSES_SUCCESS,
  PLAYLIST_STATUSES_FAIL,
  PLAYLIST_BY_ID_CLEAR,
  UPDATE_RELATED_VIDEO_ORDER_REQUEST,
  UPDATE_RELATED_VIDEO_ORDER_SUCCESS,
  UPDATE_RELATED_VIDEO_ORDER_FAIL,
  PLAYLIST_VIDEO_METADATA_REQUEST,
  PLAYLIST_VIDEO_METADATA_SUCCESS,
  PLAYLIST_VIDEO_METADATA_FAIL,
  PLAYLIST_VIDEO_UPDATE_REQUEST,
  PLAYLIST_VIDEO_UPDATE_SUCCESS,
  PLAYLIST_VIDEO_UPDATE_FAIL,
  DEFAULT_PLAYLISTS_REQUEST,
  DEFAULT_PLAYLISTS_SUCCESS,
  DEFAULT_PLAYLISTS_FAIL,
  ARRANGE_NAVBAR_PLAYLISTS,
  SEARCH_MY_PLAYLISTS_REQUEST,
  SEARCH_MY_PLAYLISTS_SUCCESS,
  ARRANGE_PLAYLIST_BY_ID,
  ARRANGE_SERIES_IN_GROUP,
  UPDATE_SERIE_DETAILS_REQUEST,
  UPDATE_SERIE_DETAILS_SUCCESS,
  UPDATE_SERIE_DETAILS_FAIL,
  ARRANGE_ARCHIVE,
  ARRANGE_OTHER_TO_ARCHIVE,
  ARRANGE_ARCHIVE_TO_OTHER,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  ARRANGE_GROUP,
  ARRANGE_GROUP_ITEMS,
  ADD_PLAYLIST_IN_GROUP,
  TAKE_OUT_PLAYLIST_FROM_GROUP,
  MOVE_PLAYLIST_FROM_GROUP_TO_PUBLIC,
  DIRECT_SHARE_ADD_SERIE_REQUEST,
  DIRECT_SHARE_ADD_SERIE_SUCCESS,
  DIRECT_SHARE_ADD_SERIE_FAIL,
  DIRECT_SHARE_ADD_VIDEO_REQUEST,
  DIRECT_SHARE_ADD_VIDEO_SUCCESS,
  DIRECT_SHARE_ADD_VIDEO_FAIL,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  FOLLOWED_PLAYLISTS_ARRANGE_REQUEST,
  FOLLOWED_PLAYLISTS_ARRANGE_SUCCESS,
  PLAYLISTS_ARRANGE_REQUEST,
  PLAYLISTS_ARRANGE_SUCCESS,
  PLAYLISTS_ARRANGE_FAIL,
  FOLLOWED_PLAYLISTS_ARRANGE_LOCAL,
  NOT_FOLLOWED_PLAYLIST_HISTORY_REQUEST,
  NOT_FOLLOWED_PLAYLIST_HISTORY_SUCCESS,
  NOT_FOLLOWED_PLAYLIST_HISTORY_FAIL,
  DELETE_CURRENT_SERIE_IMAGE_REQUEST,
  DELETE_CURRENT_SERIE_IMAGE_SUCCESS,
  DELETE_CURRENT_SERIE_IMAGE_FAIL
} from "../constants/playlistsConstants";
import { CHOICE_URL } from "@modules/config";
import debounce from "lodash.debounce";
import { socialFollowingPlaylists } from "./socialActions";
import { reArangeGroupPlaylists } from "./groupActions";
import { METHODS } from "const/apiMethods";

export const clearPlaylist = () => ({ type: PLAYLIST_BY_ID_CLEAR });
export const getPlaylists = () => async (dispatch) => {
  try {
    dispatch({ type: PLAYLISTS_REQUEST });
    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/playlists`);
    dispatch({ type: PLAYLISTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PLAYLISTS_FAIL, payload: error.response });
  }
};
export const createPlaylistGroup = (groupObj) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_GROUP_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/groups`,
      groupObj
    );
    dispatch({ type: CREATE_GROUP_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CREATE_GROUP_FAIL, payload: error.response });
  }
};

export const getDefaultPlaylists = () => async (dispatch) => {
  try {
    dispatch({ type: DEFAULT_PLAYLISTS_REQUEST });
    const { data } = await axiosInstance().get(
      `${CHOICE_URL}/api/playlists/default-playlists`
    );
    dispatch({ type: DEFAULT_PLAYLISTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: DEFAULT_PLAYLISTS_FAIL, payload: error.response });
  }
};

export const updatePlaylistLocal = (newData) => {
  return async (dispatch) => {
    dispatch({ type: PLAYLISTS_REQUEST });
    setTimeout(() => {
      dispatch({ type: PLAYLISTS_SUCCESS, payload: newData });
    }, 5000);
  };
};

export const getPlaylistById =
  (id, isFromDirectPublish = false) =>
  async (dispatch) => {
    let directPublishStr = "";
    try {
      dispatch({ type: PLAYLIST_BY_ID_REQUEST });
      if (isFromDirectPublish) {
        directPublishStr += "?from_direct_publish=true";
      }
      const { data } = await axiosInstance().get(
        `${CHOICE_URL}/api/playlists/${id}${directPublishStr}`
      );
      dispatch({ type: PLAYLIST_BY_ID_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PLAYLIST_BY_ID_FAIL,
        payload: error.response,
      });
    }
  };

export const createPlaylist = (playlistData) => async (dispatch) => {
  try {
    dispatch({ type: PLAYLIST_CREATE_REQUEST });
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/playlists`,
      playlistData,
      config
    );

    dispatch({ type: PLAYLIST_CREATE_SUCCESS, payload: data });

    dispatch(getPlaylists());
  } catch (error) {
    dispatch({
      type: PLAYLIST_CREATE_FAIL,
      payload: error.response,
    });
  }
};

export const deletePlaylist = (id) => async (dispatch) => {
  try {
    dispatch({ type: PLAYLIST_DELETE_REQUEST });

    const { data } = await axiosInstance().delete(
      `${CHOICE_URL}/api/playlists/${id}`
    );
    dispatch({ type: PLAYLIST_DELETE_SUCCESS, payload: data });
    dispatch(getPlaylists());
  } catch (error) {
    dispatch({
      type: PLAYLIST_DELETE_FAIL,
      payload: error.response,
    });
  }
};

export const deleteVideoInPlaylist =
  (videoId, playlistId) => async (dispatch) => {
    try {
      dispatch({ type: PLAYLIST_VIDEO_DELETE_REQUEST });

      const { data } = await axiosInstance().delete(
        `${CHOICE_URL}/api/playlist/videos/${videoId}/${playlistId}`
      );
      dispatch({
        type: PLAYLIST_VIDEO_DELETE_SUCCESS,
        payload: data,
      });
      debounce(dispatch(getPlaylists()), [300]);
      debounce(dispatch(getPlaylistById(playlistId)), [500]);
    } catch (error) {
      dispatch({
        type: PLAYLIST_VIDEO_DELETE_FAIL,
        payload: error.response,
      });
    }
  };

export const deleteSeriesGroupInPlaylist = (seriesId) => async (dispatch) => {
  try {
    await axiosInstance().delete(`${CHOICE_URL}/api/series/${seriesId}`);
  } catch (error) {
    console.group(
      `%c group`,
      `font-size:10px; font-weight: 200; color:white; background: red`
    );
    console.groupEnd();
  }
};

/* TODO get playlists metadata - https://api.watchchoice.tv/api/getMeta
        payload : {url: "https://www.youtube.com/watch?v=zucBfXpCA6s&ab_channel=ValentinaLisitsa"}
    */

export const getPlaylistVideoMetaData = (url) => async (dispatch) => {
  try {
    dispatch({ type: PLAYLIST_VIDEO_METADATA_REQUEST });
    const { data } = await axiosInstance().post(`${CHOICE_URL}/api/getMeta`);
    dispatch({ type: PLAYLIST_VIDEO_METADATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PLAYLIST_VIDEO_METADATA_FAIL, payload: error?.response });
  }
};

export const updateSerieDetails = (seriesId, newData) => async (dispatch) => {
  dispatch({
    type: UPDATE_SERIE_DETAILS_REQUEST,
  });

  try {
    // axiosInstance().put(`${CHOICE_URL}/api/series/${seriesId}`, newData);
    const data = axiosInstance().put(
      `${CHOICE_URL}/api/series/${seriesId}`,
      newData
    );

    dispatch({
      type: UPDATE_SERIE_DETAILS_SUCCESS,
      data: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_SERIE_DETAILS_FAIL,
      error: error,
    });
  }
};

export const deleteSerieImage = (video) => async (dispatch) => {
  dispatch({
    type: DELETE_CURRENT_SERIE_IMAGE_REQUEST
  });

  try {
    const data = axiosInstance().delete(
      `${CHOICE_URL}/api/videos/${video}/image`
    )
    dispatch({
      type: DELETE_CURRENT_SERIE_IMAGE_SUCCESS,
      data: data,
    });

  } catch (error) {
    dispatch({
      type: DELETE_CURRENT_SERIE_IMAGE_FAIL,
      error: error,
    });
  }
};

/**
 *
 * @param {number} seriesId id of the series to be updated
 * @param {{
 *  name?: string,
 *  description?: string,
 *  position?: number
 * }} newData
 * @returns
 */
export const updateSerieGroup = (seriesId, newData) => async (dispatch) => {
  axiosInstance().put(`${CHOICE_URL}/api/series/${seriesId}`, newData);
};

// Updates playlistById reducer slice locally and dispatches action to update it in the backend(background)
export const arrangePlaylistSeriesLocally = (payload) => (dispatch) => {
  // Update playlistById locally
  dispatch({
    type: ARRANGE_PLAYLIST_BY_ID,
    payload,
  });

  // Send request to the backend
  dispatch(
    updateSerieGroup(payload.seriesId, {
      position: payload.to,
    })
  );
};

/**
 *
 * @param {{
 *  seriesId: number,
 * title: string,
 * image: binary,
 * description: string,
 * src: string,
 * }} param0
 * @returns
 */
export const addNewSerieInGroup = (seriesId, formData) => async (dispatch) => {
  try {
    // Dispatch new video action. (it does not matter, only for showing spinner)
    dispatch({ type: ADD_VIDEOS_TO_PLAYLIST_REQUEST });

    await axiosInstance().post(
      `${CHOICE_URL}/api/series/${seriesId}/videos`,
      formData
    );

    dispatch({
      type: ADD_VIDEOS_TO_PLAYLIST_SUCCESS,
      payload: "Success",
    });
  } catch (error) {
    console.group(
      `%c group`,
      `font-size:10px; font-weight: 200; color:white; background: green`
    );
    console.groupEnd();
  }
};

export const arrangeSeriesInGroup =
  ({ videoId, currentSeriesId, destinationId, to: index }) =>
  async () => {
    await axiosInstance().post(
      `${CHOICE_URL}/api/videos/${videoId}/series/${currentSeriesId}/${destinationId}`,
      {
        index,
      }
    );
  };

export const arrangeSeriesInGroupLocally = (payload) => (dispatch) => {
  // Update locally
  dispatch({
    type: ARRANGE_SERIES_IN_GROUP,
    payload,
  });

  // Update in the backend
  dispatch(arrangeSeriesInGroup(payload));
};

export const arrangeSeriesInGroupLocallyNoSortMode =
  (payload) => (dispatch, getState) => {
    const series = getState()?.playlistById?.playlist?.data?.series;

    const sourceVideo = series.find((el) => el.id === +payload.draggableId)
      .videos[0];

    dispatch({
      type: "ARRANGE_SERIES_IN_GROUP_NO_SORT",
      payload: {
        sourceSerieId: +payload.draggableId,
        destinationSerieId: +payload.combine.draggableId,
      },
    });

    dispatch(
      arrangeSeriesInGroup({
        videoId: sourceVideo.id,
        currentSeriesId: +payload.draggableId,
        destinationId: +payload.combine.draggableId,
        to: 0,
      })
    );
  };

/**
 *
 * @param {{
 * playlistId: number,
 * seriesName: string,
 * title: string,
 * image: binary,
 * description: string,
 * src: string,
 * }} newVideo
 *
 * @param {boolean} refetchPlaylists - Refetch all playlists
 *
 */
export const addVideoInPlaylistNew =
  ({ playlistId, seriesName, videoItems, ...newVideo }) =>
  async (dispatch) => {
    try {
      dispatch({ type: ADD_VIDEOS_TO_PLAYLIST_REQUEST });

      await axiosInstance().post(`${CHOICE_URL}/api/series`, {
        playlist_id: playlistId,
        name: seriesName,
        videos: [...videoItems],
      });
      dispatch({
        type: ADD_VIDEOS_TO_PLAYLIST_SUCCESS,
        payload: "Success",
      });
    } catch (error) {
      dispatch({
        type: ADD_VIDEOS_TO_PLAYLIST_FAIL,
        payload: error.response,
      });
    }
  };

export const addNewSeries = (data) => async (dispatch) => {
  try {
    dispatch({ type: ADD_VIDEOS_TO_PLAYLIST_REQUEST });

    await axiosInstance().post(`${CHOICE_URL}/api/series`, data);
    dispatch({
      type: ADD_VIDEOS_TO_PLAYLIST_SUCCESS,
      payload: "Success",
    });
  } catch (error) {
    dispatch({
      type: ADD_VIDEOS_TO_PLAYLIST_FAIL,
      payload: error.response,
    });
  }
};

export const removeSerieInGroup =
  (serieGroupId, videoId) => async (dispatch) => {
    dispatch({ type: PLAYLIST_VIDEO_DELETE_REQUEST });
    try {
      await axiosInstance().delete(
        `${CHOICE_URL}/api/series/${serieGroupId}/videos`,
        {
          data: {
            videos: [videoId],
          },
        }
      );
      dispatch({ type: PLAYLIST_VIDEO_DELETE_SUCCESS });
    } catch (error) {
      dispatch({ type: PLAYLIST_VIDEO_DELETE_FAIL });
    }
  };

export const addVideoInPlaylist =
  (videoData, playlistId, refetchPlaylists = true) =>
  async (dispatch) => {
    try {
      dispatch({ type: ADD_VIDEOS_TO_PLAYLIST_REQUEST });
      const { data } = await axiosInstance().post(
        `${CHOICE_URL}/api/playlist/videos/${playlistId}`,
        videoData
      );

      dispatch({
        type: ADD_VIDEOS_TO_PLAYLIST_SUCCESS,
        payload: data,
      });
      if (refetchPlaylists) {
        dispatch(getPlaylists());
      }
    } catch (error) {
      dispatch({
        type: ADD_VIDEOS_TO_PLAYLIST_FAIL,
        payload: error.response,
      });
    }
  };

export const updatePlaylist =
  (
    updatedPlaylistData,
    playlistId,
    refetch = true,
    isPublicChannelsArrange = false
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PLAYLIST_REQUEST });

      if (isPublicChannelsArrange) {
        const { data } = await axiosInstance().post(
          `${CHOICE_URL}/api/follow/move`,
          updatedPlaylistData
        );

        dispatch({
          type: UPDATE_PLAYLIST_SUCCESS,
          payload: data,
        });

        dispatch(socialFollowingPlaylists());
      } else {
        const { data } = await axiosInstance().post(
          `${CHOICE_URL}/api/playlists/${playlistId}`,
          updatedPlaylistData
        );

        dispatch({
          type: UPDATE_PLAYLIST_SUCCESS,
          payload: data,
        });

        if (refetch) {
          dispatch(getPlaylists());
        }
      }
    } catch (error) {
      dispatch({
        type: UPDATE_PLAYLIST_FAIL,
        payload: error.response,
      });
    }
  };

export const updateGroup =
  (updatedData, groupId, refetch = true) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_GROUP_REQUEST });

      const { data } = await axiosInstance().post(
        `${CHOICE_URL}/api/groups/${groupId}/move`,
        updatedData
      );

      dispatch({
        type: UPDATE_GROUP_SUCCESS,
        payload: data,
      });
      if (refetch) {
        dispatch(getPlaylists());
      }
    } catch (error) {
      dispatch({
        type: UPDATE_GROUP_FAIL,
        payload: error.response,
      });
    }
  };

export const arrangeGroupItems =
  (updatedData, groupId, refetch = true) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_GROUP_REQUEST });

      const { data } = await axiosInstance().post(
        `${CHOICE_URL}/api/groups/${groupId}/move-playlist`,
        updatedData
      );

      dispatch({
        type: UPDATE_GROUP_SUCCESS,
        payload: data,
      });
      if (refetch) {
        dispatch(getPlaylists());
      }
    } catch (error) {
      dispatch({
        type: UPDATE_GROUP_FAIL,
        payload: error.response,
      });
    }
  };

export const addPlaylistInGroupHttp =
  (updatedData, groupId, refetch = true) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_GROUP_REQUEST });

      const { data } = await axiosInstance().post(
        `${CHOICE_URL}/api/groups/${groupId}/add-playlist`,
        updatedData
      );

      dispatch({
        type: UPDATE_GROUP_SUCCESS,
        payload: data,
      });
      if (refetch) {
        dispatch(getPlaylists());
      }
    } catch (error) {
      dispatch({
        type: UPDATE_GROUP_FAIL,
        payload: error.response,
      });
    }
  };

export const takeOutPlaylistFromGroupHttp =
  (updatedData, groupId, refetch = true) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_GROUP_REQUEST });

      const { data } = await axiosInstance().post(
        `${CHOICE_URL}/api/groups/${groupId}/remove-playlist`,
        updatedData
      );

      dispatch({
        type: UPDATE_GROUP_SUCCESS,
        payload: data,
      });
      if (refetch) {
        dispatch(getPlaylists());
      }
    } catch (error) {
      dispatch({
        type: UPDATE_GROUP_FAIL,
        payload: error.response,
      });
    }
  };

export const copyPlaylist =
  (updatedPlaylistData, refetch = true) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PLAYLIST_REQUEST });

      const { data } = await axiosInstance().post(
        `${CHOICE_URL}/api/playlists/copy`,
        updatedPlaylistData
      );

      dispatch({
        type: UPDATE_PLAYLIST_SUCCESS,
        payload: data,
      });
      if (refetch) {
        dispatch(getPlaylists());
      }
    } catch (error) {
      dispatch({
        type: UPDATE_PLAYLIST_FAIL,
        payload: error.response,
      });
    }
  };

export const arrangeNavbarPlaylist = (payload) => (dispatch) => {
  const typeToDispatch = payload.fromArchive
    ? ARRANGE_ARCHIVE_TO_OTHER
    : ARRANGE_NAVBAR_PLAYLISTS;

  dispatch({
    type: typeToDispatch,
    payload,
  });

  let order = payload.to + payload.archiveLength + 1;
  // console.log("order1: " + order);
  order = payload.fromArchive ? order - 1 : order;
  // console.log("order2: " + order);

  order = payload.hasMovedToRightPl ? order - 1 : order;
  // console.log("order3: " + order);

  order = payload.is_left
    ? order
    : order + payload.leftPlaylistLength + payload.publicPlaylistLength;
  // console.log("order4: " + order);
  // console.log("destination to " + payload.to);
  // console.log("archive " + payload.archiveLength);
  // console.log("left pl " + payload.leftPlaylistLength);
  // console.log("order: " + order);
  const updatedPlaylist = new FormData();
  updatedPlaylist.append("_method", METHODS.PATCH);
  updatedPlaylist.append("order", order);
  if (payload.hasOwnProperty("is_left")) {
    updatedPlaylist.append("is_left", payload.is_left);
  }
  if (payload.fromArchive) {
    updatedPlaylist.append("is_archived", false);
  }

  dispatch(updatePlaylist(updatedPlaylist, payload.playlistId, true));
};

export const arrangeArchive = (payload) => (dispatch) => {
  const typeToDispatch = payload.movedIn
    ? ARRANGE_OTHER_TO_ARCHIVE
    : ARRANGE_ARCHIVE;

  dispatch({
    type: typeToDispatch,
    payload,
  });

  const updatedArchivePl = new FormData();
  updatedArchivePl.append("_method", METHODS.PATCH);
  updatedArchivePl.append("order", payload.to + 1);
  updatedArchivePl.append("is_archived", true);

  dispatch(updatePlaylist(updatedArchivePl, payload.playlistId, true));
};

export const arrangeGroup = (payload) => (dispatch) => {
  const { to, groupId } = payload;

  dispatch({
    type: ARRANGE_GROUP,
    payload,
  });

  const reqData = {
    position: to + 1,
  };

  dispatch(updateGroup(reqData, groupId, true));
};

export const movePlaylistfromGroupToPublic = (payload) => (dispatch) => {
  const {
    to,
    groupId,
    playlistId,
    statusId,
    archiveLength,
    otherPlaylistLength,
  } = payload;

  dispatch({
    type: MOVE_PLAYLIST_FROM_GROUP_TO_PUBLIC,
    payload,
  });

  const reqData = {
    status_id: statusId,
    is_left: false,
    playlist_id: playlistId,
    order: to + 1 + archiveLength + otherPlaylistLength,
  };

  dispatch(takeOutPlaylistFromGroupHttp(reqData, groupId, true));
};

export const arrangePlaylistsInsideGroup = (payload) => (dispatch) => {
  const { to, groupId, playlistId } = payload;

  dispatch({
    type: ARRANGE_GROUP_ITEMS,
    payload,
  });

  const reqData = {
    playlist_id: playlistId,
    position: to + 1,
  };

  dispatch(arrangeGroupItems(reqData, groupId, true));

  dispatch(reArangeGroupPlaylists());
};

export const arrangeFollowedPlaylistsLocal = (newData) => (dispatch) => {
  dispatch({ type: FOLLOWED_PLAYLISTS_ARRANGE_LOCAL, payload: newData });
};

export const addPlaylistInGroup = (payload) => (dispatch) => {
  const { to, groupId, playlistId } = payload;

  dispatch({
    type: ADD_PLAYLIST_IN_GROUP,
    payload,
  });

  const reqData = {
    playlist_id: playlistId,
    // position: to + 1,
  };

  dispatch(addPlaylistInGroupHttp(reqData, groupId, true));
};

export const takeOutPlaylistFromGroup = (payload) => (dispatch) => {
  const {
    to,
    playlistId,
    groupId,
    leftPlaylistLength,
    publicPlaylistLength,
    archiveLength,
    statusId,
  } = payload;

  const order = to + archiveLength + 1;

  dispatch({
    type: TAKE_OUT_PLAYLIST_FROM_GROUP,
    payload: { ...payload, to: to },
  });

  const reqData = {
    playlist_id: playlistId,
    order,
    status_id: statusId,
  };

  dispatch(takeOutPlaylistFromGroupHttp(reqData, groupId, true));
};

export const copyNavbarPlaylist = (payload) => (dispatch) => {
  const updatedPlaylist = {};
  updatedPlaylist["playlist_id"] = payload.playlistId;
  updatedPlaylist["order"] = payload.to;
  if (payload.hasOwnProperty("is_left")) {
    updatedPlaylist["is_left"] = payload.is_left;
  }
  if (payload.status_id) {
    updatedPlaylist["status_id"] = payload.status_id;
  }

  dispatch(copyPlaylist(updatedPlaylist, true));
};

export const movePlaylistVideo =
  (videoId, oldPlaylistId, newPlaylistId) => async (dispatch) => {
    try {
      dispatch({ type: MOVE_PLAYLIST_REQUEST });
      const { data } = await axiosInstance().post(
        `${CHOICE_URL}/api/playlist/videos/${videoId}/${oldPlaylistId}/${newPlaylistId}`
      );
      dispatch({ type: MOVE_PLAYLIST_SUCCESS, payload: data });
      dispatch(getPlaylists());
      const debouncedGetPlaylist = debounce(() => {
        dispatch(getPlaylistById(oldPlaylistId));
      }, 200);

      debouncedGetPlaylist();
    } catch (error) {
      dispatch({
        type: MOVE_PLAYLIST_FAIL,
        payload: error.response,
      });
    }
  };

export const updatePlaylistVideoOrder =
  (playlistId, videoId, newIndex) => async (dispatch) => {
    try {
      dispatch({ type: PLAYLIST_VIDEO_REORDER_REQUEST });
      await axiosInstance().post(
        `${CHOICE_URL}/api/playlist/${playlistId}/videos/${videoId}/reorder`,
        { order: newIndex }
      );
      dispatch({ type: PLAYLIST_VIDEO_REORDER_SUCCESS });
    } catch (error) {
      dispatch({
        type: PLAYLIST_VIDEO_REORDER_FAIL,
        payload: error.response,
      });
    }
  };

export const addRelatedVideos =
  (playlistId, videoId, relatedVideoUrls) => async (dispatch) => {
    let relatedVideosLinksString = "";
    relatedVideoUrls?.forEach((url, index, array) => {
      if (index !== array.length - 1) {
        relatedVideosLinksString += `${index}[src]=${encodeURIComponent(url)}&`;
      } else {
        relatedVideosLinksString += `${index}[src]=${encodeURIComponent(url)}`;
      }
    });
    try {
      dispatch({ type: ADD_RELATED_VIDEO_REQUEST });
      const { data } = await axiosInstance().post(
        `${CHOICE_URL}/api/related/${playlistId}/${videoId}/videos?${relatedVideosLinksString}`
      );
      dispatch({
        type: ADD_RELATED_VIDEO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_RELATED_VIDEO_FAIL,
        payload: error.response,
      });
    }
  };

export const getRelatedVideos = (playlistId, videoId) => async (dispatch) => {
  try {
    dispatch({ type: RELATED_VIDEOS_REQUEST });
    const { data } = await axiosInstance().get(
      `${CHOICE_URL}/api/related/${playlistId}/${videoId}/videos`
    );
    dispatch({ type: RELATED_VIDEOS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: RELATED_VIDEOS_FAIL,
      payload: error.response,
    });
  }
};

export const deleteRelatedVideo =
  (playlistId, videoId, relatedVideoId) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_RELATED_VIDEO_REQUEST });
      const { data } = await axiosInstance().delete(
        `${CHOICE_URL}/api/related/${playlistId}/${videoId}/videos/${relatedVideoId}`
      );
      dispatch({
        type: DELETE_RELATED_VIDEO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_RELATED_VIDEO_FAIL,
        payload: error.response,
      });
    }
  };

export const updateRelatedVideoOrder =
  (playlistId, videoId, newIndex, relatedVideoId) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_RELATED_VIDEO_ORDER_REQUEST });
      await axiosInstance().put(
        `${CHOICE_URL}/api/related/${playlistId}/${videoId}/videos/${relatedVideoId}`,
        { order: newIndex }
      );
      dispatch({ type: UPDATE_RELATED_VIDEO_ORDER_SUCCESS });
    } catch (error) {
      dispatch({
        type: UPDATE_RELATED_VIDEO_ORDER_FAIL,
        payload: error.response,
      });
    }
  };

export const getFavoritesPlaylistRelatedVideos =
  (playlistId) => async (dispatch) => {
    try {
      dispatch({ type: PLAYLIST_WITH_RELATED_VIDEOS_REQUEST });
      const { data } = await axiosInstance().get(
        `${CHOICE_URL}/api/related/${playlistId}`
      );
      dispatch({
        type: PLAYLIST_WITH_RELATED_VIDEOS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PLAYLIST_WITH_RELATED_VIDEOS_FAIL,
        payload: error.response,
      });
    }
  };

export const searchPlaylists = (keyword) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_PLAYLIST_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/playlists/search`,
      { keyword }
    );
    dispatch({ type: SEARCH_PLAYLIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SEARCH_PLAYLIST_FAIL, payload: error.response });
  }
};

export const searchMyPlaylists = (keyword) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_MY_PLAYLISTS_REQUEST });
    const { data } = await axiosInstance().get(
      `${CHOICE_URL}/api/playlists/my-playlists/search?keyword=${keyword}`
    );
    dispatch({ type: SEARCH_MY_PLAYLISTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SEARCH_MY_PLAYLISTS_SUCCESS, payload: error.response });
  }
};

export const getPlaylistSuggestions = (query) => async (dispatch) => {
  try {
    dispatch({ type: PLAYLIST_KEYWORDS_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/playlist/keywords`,
      { keyword: query }
    );
    dispatch({ type: PLAYLIST_KEYWORDS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PLAYLIST_KEYWORDS_FAIL, payload: error?.response });
  }
};

export const getReports = () => async (dispatch) => {
  try {
    dispatch({ type: PLAYLIST_REPORTS_REQUEST });
    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/reportTypes`);
    dispatch({ type: PLAYLIST_REPORTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PLAYLIST_REPORTS_FAIL, payload: error.message });
  }
};

export const addReport = (reportData) => async (dispatch) => {
  try {
    dispatch({ type: PLAYLIST_REPORT_ADD_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/report`,
      reportData
    );
    dispatch({ type: PLAYLIST_REPORT_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PLAYLIST_REPORT_ADD_FAIL,
      payload: error.response,
    });
  }
};

export const getPlaylistStatuses = () => async (dispatch) => {
  try {
    dispatch({ type: PLAYLIST_STATUSES_REQUEST });
    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/statuses`);

    dispatch({ type: PLAYLIST_STATUSES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PLAYLIST_STATUSES_FAIL, payload: error.message });
  }
};

export const updatePlaylistVideo =
  (videoId, updatedVideoData) => async (dispatch) => {
    try {
      dispatch({ type: PLAYLIST_VIDEO_UPDATE_REQUEST });
      const { data } = await axiosInstance().post(
        `${CHOICE_URL}/api/videos/${videoId}`,
        updatedVideoData
      );
      dispatch({ type: PLAYLIST_VIDEO_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: PLAYLIST_VIDEO_UPDATE_FAIL, payload: error?.message });
    }
  };

export const addSerieToDirectShare = (seriesId, url) => async (dispatch) => {
  try {
    dispatch({ type: DIRECT_SHARE_ADD_SERIE_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/direct-share/series`,
      {
        series_id: seriesId,
        url,
      }
    );
    dispatch({ type: DIRECT_SHARE_ADD_SERIE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: DIRECT_SHARE_ADD_SERIE_FAIL, payload: error.response });
  }
};

export const addVideoToDirectShare =
  (seriesId, videoId, url) => async (dispatch) => {
    try {
      dispatch({ type: DIRECT_SHARE_ADD_VIDEO_REQUEST });
      const { data } = await axiosInstance().post(
        `${CHOICE_URL}/api/direct-share/video`,
        {
          series_id: seriesId,
          video_id: videoId,
          url,
        }
      );
      dispatch({ type: DIRECT_SHARE_ADD_VIDEO_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: DIRECT_SHARE_ADD_VIDEO_FAIL, payload: error.response });
    }
  };

export const arrangeFollowedPlaylists = (playlistsArr) => async (dispatch) => {
  try {
    dispatch({ type: FOLLOWED_PLAYLISTS_ARRANGE_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/follow/rearrange`,
      {
        playlists: playlistsArr,
      }
    );
    dispatch({ type: FOLLOWED_PLAYLISTS_ARRANGE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FOLLOWED_PLAYLISTS_ARRANGE_SUCCESS,
      payload: error.response,
    });
  }
};

export const arrangePlaylists = (playlistsArr) => async (dispatch) => {
  try {
    dispatch({ type: PLAYLISTS_ARRANGE_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/playlists/rearrange`,
      { playlists: playlistsArr }
    );
    dispatch({ type: PLAYLISTS_ARRANGE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PLAYLISTS_ARRANGE_FAIL, payload: error.response });
  }
};

export const getNotFollowedPlaylistsHistory = () => async (dispatch) => {
  try {
    dispatch({ type: NOT_FOLLOWED_PLAYLIST_HISTORY_REQUEST });
    const { data } = await axiosInstance().get(
      `${CHOICE_URL}/api/user/not-followed-playlists-history`
    );
    dispatch({ type: NOT_FOLLOWED_PLAYLIST_HISTORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: NOT_FOLLOWED_PLAYLIST_HISTORY_FAIL,
      payload: error.response,
    });
  }
};
