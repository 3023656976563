import React, { useState, useContext, useEffect, useRef } from "react";
import Image from "@shared/atoms/Image";
import Button from "@shared/atoms/Button";
import { Paragraph } from "@shared/atoms/Typography/index";
import ChannelMenuPrototype from "@shared/molecules/ChannelMenuPrototype";
// import CollapseMenuPrototype from "@shared/molecules/CollapseMenuPrototype";
import { useDispatch, useSelector } from "react-redux";
import useScrollToTop from "hooks/useScrollToTop";

import "./index.less";
import { useContextMenu } from "hooks/useContextMenu";
import ContextMenuPrototype, {
  arrowDirection,
  ContextMenuOption,
} from "@components/shared/molecules/ContextMenuPrototype";

import editImg from "@assets/icons/edit.svg";
// import deleteImg from "@assets/icons/deleteContext.svg";
// import addImg from "@assets/icons/add.svg";
// import moveImg from "@assets/icons/arrow-up-down.svg";
import shareImg from "@assets/icons/share-prototype.svg";
import { useTranslation } from "react-i18next";
import { Context } from "@context/context";
import { useHistory, useLocation } from "react-router-dom";
import usePathName from "hooks/usePathName";
import { PAGES } from "const/pages";
import Spacer from "@components/shared/atoms/Spacer";
import GuestModal from "../modals/GuestModal";
import AlertModalPrototype from "../modals/AlertModalPrototype";
import { useAccountMode } from "hooks";
import { VIEWS_PROTOTYPE } from "const/viewsPrototype";
import { getUserProfile } from "@redux/actions/profileActions";

const iconMarginTopStyle = {
  marginTop: "2rem",
};

const HeaderPrototype = ({
  name,
  image,
  buttonText = "",
  shareHandler,
  // reportHandler,
  isPlaylistsHeader,
  isChannelsHeader,
  removePlaylistHandler,
  toggleWelcomeMessage,
  followHandler,
  unfollowHandler,
  searchSubscribeHandler,
  toggleEditMode = () => {},
  archivePlaylist,
  removePlaylistFromArchive,
  isEditMode,
  isWatchLater,
  isDirectPublish,
  disabledSearchFollowBtn,
  searchSubscribeBtnText,
  showChannelMenu = true,
  headerPositionStyle,
  isDefault = false,
  isArchived,
  className = "",
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  // const [style, setStyle] = useState({});
  const [isTextOverflown, setIsTextOverflown] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const titleContainerRef = useRef(null);

  const scrollToTop = useScrollToTop();
  const { accountMode, isTeamMode, teamId } = useAccountMode();
  const location = useLocation();
  const dispatch = useDispatch();

  const page = usePathName();

  // const collapseBtnRef = useRef(null);
  const {
    setShowEditPlaylistModalPrototype,
    setShowSubBarPrototype,
    setCurrentViewPrototype,
    setShowAlertModalPrototype,
  } = useContext(Context);

  const { playlist: playlistById } = useSelector((state) => state.playlistById);

  const userProfile = useSelector((state) => state.userProfile);

  const currentPage = usePathName();

  const headerRef = useRef(null);

  const {
    xPos,
    yPos,
    isVisible,
    openContextMenu,
    closeContextMenu,
    contextMenuClickWithDebounce,
    withCloseContextMenu,
    onTouchEnd,
  } = useContextMenu();

  let textWidth = titleContainerRef?.current?.firstChild?.offsetWidth;

  useEffect(() => {
    if (!titleContainerRef.current) return;

    const containerWidth = titleContainerRef.current.offsetWidth;

    textWidth > containerWidth
      ? setIsTextOverflown(true)
      : setIsTextOverflown(false);

    if (isTextOverflown) {
      headerRef.current.style.height = "9rem";
    } else {
      headerRef.current.style.height = "4.7rem";
    }
  }, [isTextOverflown, textWidth]);

  const toggleFollow = () => {
    if (page === PAGES.tiers) return;
    playlistById?.data?.is_followed ? unfollowHandler() : followHandler();
  };

  // const collapseHandler = () => {
  //   if (!collapseBtnRef.current) return;

  //   if (collapseBtnRef.current.classList.contains("show-collapse-menu")) {
  //     collapseBtnRef.current.classList.remove("show-collapse-menu");
  //     setStyle({
  //       height: `0rem`,
  //     });
  //   } else {
  //     collapseBtnRef.current.classList.add("show-collapse-menu");
  //     setStyle({
  //       height: `${
  //         6.9 * collapseBtnRef?.current?.firstChild?.childElementCount
  //       }rem`,
  //     });
  //   }
  // };

  const myPlaylistPageToggleLabel = () => {
    let isShown;
    if (isDefault) {
      isShown = userProfile?.profile?.data?.hide_direct_publish_welcome;
    } else {
      isShown = userProfile?.profile?.data?.hide_heart_welcome;
    }
    return isShown;
  };

  const playlistPageToggleLabel = () => {
    let isShown;
    if (isWatchLater) {
      isShown = userProfile?.profile?.data?.hide_watch_later_welcome;
    } else {
      isShown = userProfile?.profile?.data?.hide_globe_welcome;
    }
    return isShown;
  };

  const showIfUserPlaylist = () => {
    return (
      isPlaylistsHeader &&
      location.search &&
      Number(playlistById?.data?.user_id) ===
        Number(userProfile?.profile?.data?.id)
    );
  };

  const redirectToSettings = () => {
    history.push("/settings");
    setCurrentViewPrototype(VIEWS_PROTOTYPE.settings);
    setShowSubBarPrototype(true);
  };

  const contextmenuOptions = (
    <>
      {showIfUserPlaylist() && (
        <>
          <ContextMenuOption
            icon={shareImg}
            label="Share"
            onClick={withCloseContextMenu(shareHandler)}
          />

          <ContextMenuOption
            label={
              isEditMode
                ? t("stop_edit_playlist_prototype")
                : t("edit_playlist_prototype")
            }
            icon={editImg}
            onClick={withCloseContextMenu(() => {
              setTimeout(() => {
                toggleEditMode();
              }, 250);
              isEditMode = { isEditMode };
            })}
          />

          {!playlistById.data.watch_later && (
            <>
              <ContextMenuOption
                label={t("playlist_settings")}
                icon={editImg}
                onClick={withCloseContextMenu(() => {
                  setShowEditPlaylistModalPrototype(true);
                })}
              />

              <ContextMenuOption
                label={isArchived ? "Remove From Archive" : "Archive Channel"}
                icon={editImg}
                onClick={withCloseContextMenu(() => {
                  (isArchived ? removePlaylistFromArchive : archivePlaylist)();
                })}
              />

              <ContextMenuOption
                label="Delete Channel"
                icon={editImg}
                onClick={removePlaylistHandler}
              />
            </>
          )}
        </>
      )}

      {/* {isPlaylistsHeader &&
        location.search &&
        !isWatchLater &&
        !playlistById?.data?.direct_publish &&
        Number(playlistById?.data?.user_id) ===
        Number(userProfile?.profile?.data?.id) && (
          <>
            <ContextMenuOption
              label={t("playlist_settings")}
              icon={editImg}
              onClick={withCloseContextMenu(() => {
                setShowEditPlaylistModalPrototype(true);
              })}
            />
          </>
        )} */}

      {(isPlaylistsHeader ||
        isChannelsHeader ||
        playlistById?.data?.is_default) &&
        location.search &&
        Number(playlistById?.data?.user_id) !==
          Number(userProfile?.profile?.data?.id) && (
          <>
            <ContextMenuOption
              icon={shareImg}
              label="Share"
              onClick={withCloseContextMenu(shareHandler)}
            />
          </>
        )}

      {isPlaylistsHeader &&
        (!location.search || isDirectPublish) &&
        currentPage === PAGES.myPlaylists && (
          <>
            <ContextMenuOption
              icon={shareImg}
              label={
                myPlaylistPageToggleLabel()
                  ? t("show_welcome_message")
                  : t("hide_welcome_message")
              }
              onClick={withCloseContextMenu(toggleWelcomeMessage)}
            />
          </>
        )}

      {isPlaylistsHeader &&
        (!location.search || isWatchLater) &&
        currentPage === PAGES.playlists && (
          <>
            <ContextMenuOption
              icon={shareImg}
              label={
                playlistPageToggleLabel()
                  ? t("show_welcome_message")
                  : t("hide_welcome_message")
              }
              onClick={withCloseContextMenu(toggleWelcomeMessage)}
            />
          </>
        )}

      {!location.search && currentPage === PAGES.tiers && (
        <>
          <ContextMenuOption
            icon={shareImg}
            label={
              userProfile?.profile?.data?.hide_tv_welcome
                ? t("show_welcome_message")
                : t("hide_welcome_message")
            }
            onClick={withCloseContextMenu(toggleWelcomeMessage)}
          />
        </>
      )}

      {location.search && currentPage === PAGES.settings && (
        <>
          <ContextMenuOption
            icon={shareImg}
            label={
              userProfile?.profile?.data?.hide_user_welcome
                ? t("show_welcome_message")
                : t("hide_welcome_message")
            }
            onClick={withCloseContextMenu(toggleWelcomeMessage)}
          />
        </>
      )}
    </>
  );

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  return (
    <>
      <div
        ref={headerRef}
        className={`header-prototype ${className}`}
        style={headerPositionStyle}
        onClick={scrollToTop}
        onContextMenu={openContextMenu}
        onTouchStart={contextMenuClickWithDebounce}
        onTouchEnd={onTouchEnd}
      >
        <ContextMenuPrototype
          isVisible={isVisible}
          xPos={xPos}
          yPos={yPos}
          handleClose={closeContextMenu}
          arrowDir={arrowDirection.left}
          isInPlaylistHeader={true}
        >
          {contextmenuOptions}
        </ContextMenuPrototype>

        <div ref={titleContainerRef}>
          <Paragraph className="title">{name}</Paragraph>
        </div>
        <div>
          {/* {accountMode && (
            <Paragraph
              onClick={redirectToSettings}
              className={`title ${isTextOverflown && "pane-mobile"}`}
            >
              {accountMode}
            </Paragraph>
          )} */}
        </div>
        <div
          className="icon-container"
          style={isTextOverflown ? iconMarginTopStyle : {}}
        >
          <Image
            alt="channel"
            src={image}
            className={name === "Ment TV Belgium" ? "uncropped-img" : ""}
          />
        </div>

        <AlertModalPrototype
          isVisible={isPopupOpen}
          closeHandler={() => setIsPopupOpen(false)}
        />

        {!searchSubscribeHandler && isDefault && (
          <div className="follow-container">
            {buttonText && (
              <Button
                className="ant-btn-prototype btn-sm-white"
                onClick={() => {
                  userProfile.error ? setIsPopupOpen(true) : toggleFollow();
                }}
              >
                {buttonText}
              </Button>
            )}
          </div>
        )}

        {!searchSubscribeHandler && !isDefault && (
          <div className="follow-container">
            {buttonText && (
              <Button
                className="ant-btn-prototype btn-sm-white"
                onClick={() => {
                  return userProfile ? console.log(userProfile) : toggleFollow;
                }}
              >
                {buttonText}
              </Button>
            )}
          </div>
        )}

        {searchSubscribeHandler && userProfile?.profile?.data?.id && (
          <div className="follow-container">
            <Button
              className="ant-btn-prototype btn-sm-white"
              onClick={searchSubscribeHandler}
              disabled={disabledSearchFollowBtn}
            >
              {searchSubscribeBtnText}
            </Button>
          </div>
        )}

        {searchSubscribeHandler && !userProfile?.profile?.data?.id && (
          <div className="follow-container">
            <Button
              className="ant-btn-prototype btn-sm-white"
              onClick={() => setShowAlertModalPrototype(true)}
              disabled={disabledSearchFollowBtn}
            >
              {searchSubscribeBtnText}
            </Button>
          </div>
        )}

        {showChannelMenu && (
          <ChannelMenuPrototype
            className="three-dot-prototype"
            onClick={openContextMenu}
          />
        )}
      </div>
      {isTextOverflown && <Spacer height={9} />}
    </>
  );
};

export default HeaderPrototype;
