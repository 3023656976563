// import { Context } from "@context/context";
import Modal from "@shared/atoms/Modal/index";
import { Paragraph } from "@shared/atoms/Typography/index";

import './index.less'

const SimplePopupWithSingleButton = ({
    text, btnText, onAccept, onCansel, isVissible,
}) => {
  
  return (
    <Modal
      visible={isVissible}
      className="modal-basic-prototype simple-popup"
      mask={true}
      closable={true}
      onCancel={onCansel}
      footer={
          <button
            className="modal-button"
            onClick={onAccept}
          >
            {btnText}
          </button>
    }
    >
      <div>
        <Paragraph>
          {text}
        </Paragraph>
      </div>
    </Modal>
  );
};

export default SimplePopupWithSingleButton;
