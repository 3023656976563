import { useState, useEffect } from "react";

import useDetectMobile from "./useDetectMobile";

function useAdditionalClassesOnMobile(recievedClasses) {
    const [classes, setClasses] = useState("")
    let isMobile = useDetectMobile();

    useEffect(() => {
        isMobile
            ? setClasses(recievedClasses)
            : setClasses("");
    }, [isMobile,recievedClasses]);

    return classes;
}

export default useAdditionalClassesOnMobile;
