import addIcon from "@assets/icons/add.svg";
import { useTranslation } from "react-i18next";

const NewVideo = ({ isSeriesItem, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={isSeriesItem ? "add-new-video bottom" : "add-new-video"} onClick={onClick}>
      <img src={addIcon} alt="add new video icon" />
      { 
        isSeriesItem ? <span>Add Item In New Series</span> : <span>{t("add_item")}</span>
      }
    </div>
  );
};

export default NewVideo;
