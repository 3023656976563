import React, { useContext, useRef } from "react";
import { Context } from "@context/context";
import { LiftUpStyle } from "./animationStyles";
import ScrollContainer from "react-indiana-drag-scroll";
import { useTranslation } from "react-i18next";
import usePathName from "hooks/usePathName";
import { PAGES } from "const/pages";
import { socialFollowingPlaylists } from "@redux/actions/socialActions";
import { useDispatch } from "react-redux";
import useOutsideClick from "hooks/useOutsideClick";

import "./index.less";

const SubBarPrototype = ({ children }) => {
  const page = usePathName();
  const dispatch = useDispatch();

  const subbarContentRef = useRef(null);

  const {
    showSubBarPrototype,
    isArrangeMode,
    setIsArrangeMode,
    isOpenableArrangeMode,
    setIsOpenableArrangeMode,
    subbarRefPrototype,
    setShowHomeContent,
    scrollSubbarRef,
    isRestrictedOpenableArrangeMode,
    setIsRestrictedOpenableArrangeMode,
    isSubbarHorizontalScrollable,
    setIsSubbarHorizontalScrollable,
    setIsGroupArrangeMode,
    isGroupArrangeMode,
    isShortcutArrangeMode,
    setIsShortcutArrangeMode,
  } = useContext(Context);

  const { t } = useTranslation();

  const doneBtnClickHandler = () => {
    setIsGroupArrangeMode(false);
    setIsArrangeMode(false);
    setIsOpenableArrangeMode(false);
    setIsRestrictedOpenableArrangeMode(false);
    setIsSubbarHorizontalScrollable(true);
    setIsShortcutArrangeMode(false);
    if (page === PAGES.playlists) {
      dispatch(socialFollowingPlaylists());
    }
  };

  useOutsideClick(subbarContentRef, function () {
    if (isArrangeMode) doneBtnClickHandler();
  });

  return (
    <>
      {showSubBarPrototype && LiftUpStyle && (
        <div
          className={`sub-bar-prototype`}
          ref={subbarRefPrototype}
          style={LiftUpStyle}
          onClick={() => setShowHomeContent(false)}
        >
          {(isArrangeMode ||
            isGroupArrangeMode ||
            isOpenableArrangeMode ||
            isRestrictedOpenableArrangeMode ||
            isShortcutArrangeMode) && (
            <div className="cancel-arrange-mode">
              <p>
                You can now drag items
                <button onClick={doneBtnClickHandler}>{t("done")}</button>
              </p>
            </div>
          )}
          <div className="sub-bar-prototype__content" ref={subbarContentRef}>
            <ScrollContainer
              hideScrollbars={false}
              className="scroll-cont"
              ref={scrollSubbarRef}
              horizontal={isSubbarHorizontalScrollable}
            >
              <div>{children}</div>
            </ScrollContainer>
          </div>
        </div>
      )}
    </>
  );
};

export default SubBarPrototype;
