import React, { useContext } from "react";
import Modal from "@shared/atoms/Modal/index";
import { Paragraph } from "@shared/atoms/Typography/index";
import Image from "@shared/atoms/Image/index";
import checkMarkIcon from "@assets/icons/checkmark.svg";
import { Context } from "@context/context";

import "./index.less";

const SuccessModalPrototype = ({ children }) => {
  const { showSuccessModalPrototype, setShowSuccessModalPrototype } =
    useContext(Context);
  const closeHandler = () => {
    setShowSuccessModalPrototype(false);
  };
  return (
    <Modal
      visible={showSuccessModalPrototype}
      className="modal-basic-prototype success-modal-prototype"
      mask={true}
      footer={<></>}
      closable={true}
      onCancel={() => {
        closeHandler();
      }}
    >
      <div>
        <Image className="success-img" src={checkMarkIcon} alt="success" />
        <Paragraph>{children}</Paragraph>
      </div>
    </Modal>
  );
};

export default SuccessModalPrototype;
