import React, { useState, useEffect, useCallback } from "react";
import Modal from "../../../shared/atoms/Modal";
import Button from "@shared/atoms/Button";
import UpdateProfileFormPrototype from "../../forms/UpdateProfileFormPrototype";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfile,
  updateUserProfile,
} from "../../../../../redux/actions/profileActions";
import ErrorFeedbackPrototype from "../../../shared/atoms/ErrorFeedbackPrototype";
import moment from "moment";

import "./index.less";
import { USER_PROFILE_UPDATE_RESET } from "../../../../../redux/constants/profileConstants";
import { useTranslation } from "react-i18next";
import { METHODS } from "const/apiMethods";

const UpdateProfileModalPrototype = ({ show, setShow }) => {
  const { profile } = useSelector((state) => state.userProfile);
  const {
    loading: updateLoading,
    error: updateError,
    profile: updatedProfile,
  } = useSelector((state) => state.updateUserProfile);

  const { t } = useTranslation();

  const [blobImg, setBlobImg] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickName, setNickName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [householdCount, setHouseholdCount] = useState(1);
  const [countryId, setCountryId] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [mailCheckbox, setMailCheckbox] = useState(true);
  const [findPublicProfileCheckbox, setFindPublicProfileCheckbox] =
    useState(true);
  const [userProfileImg, setUserProfileImg] = useState(null);

  useEffect(() => {
    setFirstName(profile?.data?.firstname);
    setLastName(profile?.data?.lastname);
    setNickName(profile?.data?.nickname);
    setEmail(profile?.data?.email);
    setAddress(profile?.data?.city);
    setPhoneNumber(profile?.data?.phone);
    setHouseholdCount(profile?.data?.household_count);
    setCountryId(profile?.data?.country_id);
    setBirthDate(
      profile?.data?.birthday
        ? moment(profile?.data.birthday).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD")
    );
    setGender(profile?.data?.gender);
    setMailCheckbox(profile?.data?.following_content_alert);
    setFindPublicProfileCheckbox(profile?.data?.is_public);
    setUserProfileImg(profile?.data?.profile_picture);
  }, [profile]);

  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    setShow(false);
    dispatch({ type: USER_PROFILE_UPDATE_RESET });
  }, [setShow, dispatch]);

  useEffect(() => {
    if (!updateError?.data?.message && updatedProfile?.id) {
      closeModal();
    }
  }, [updateError, closeModal, updatedProfile]);

  const updateProfile = () => {
    const fd = new FormData();

    if (blobImg) fd.append("profile_picture", blobImg);

    fd.append("firstname", firstName);
    fd.append("lastname", lastName);
    fd.append("email", email);
    fd.append("city", address);
    fd.append("phone", phoneNumber ? phoneNumber : "");
    fd.append("household_count", householdCount ? householdCount : "");
    fd.append("country_id", countryId ? countryId : 239);
    fd.append("birthday", birthDate);
    fd.append("gender", gender ? gender : "");
    fd.append("following_content_alert", mailCheckbox);
    findPublicProfileCheckbox
      ? fd.append("is_public", "1")
      : fd.append("is_public", "0");
    fd.append("_method", METHODS.PATCH);

    Promise.resolve(dispatch(updateUserProfile(fd))).then(() => {
      dispatch(getUserProfile());
    });
  };

  return (
    <Modal
      className="ant-modal-prototype update-profile-modal-prototype"
      visible={show}
      closable={true}
      onCancel={closeModal}
      footer={[
        <>
          <Button
            key="update"
            type="primary"
            onClick={updateProfile}
            disabled={updateLoading}
          >
            {t("update")}
          </Button>
          <Button key="cancel" onClick={closeModal}>
            {t("cancel")}
          </Button>
        </>,
      ]}
    >
      <UpdateProfileFormPrototype
        setBlobImg={setBlobImg}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setNickName={setNickName}
        setEmail={setEmail}
        setAddress={setAddress}
        setPhoneNumber={setPhoneNumber}
        setHouseholdCount={setHouseholdCount}
        setCountryId={setCountryId}
        setBirthDate={setBirthDate}
        setGender={setGender}
        setMailCheckbox={setMailCheckbox}
        setFindPublicProfileCheckbox={setFindPublicProfileCheckbox}
        setUserProfileImg={setUserProfileImg}
        firstName={firstName}
        nickName={nickName}
        lastName={lastName}
        email={email}
        address={address}
        phoneNumber={phoneNumber}
        householdCount={householdCount}
        countryId={countryId}
        birthDate={birthDate}
        gender={gender}
        mailCheckbox={mailCheckbox}
        findPublicProfileCheckbox={findPublicProfileCheckbox}
        userProfileImg={userProfileImg}
      />
      {updateError && (
        <>
          {updateError?.data?.message && (
            <ErrorFeedbackPrototype>
              {updateError?.data?.message}
            </ErrorFeedbackPrototype>
          )}

          {updateError?.data?.errors?.firstname && (
            <ErrorFeedbackPrototype>
              {updateError?.data?.errors?.firstname}
            </ErrorFeedbackPrototype>
          )}

          {updateError?.data?.errors?.lastname && (
            <ErrorFeedbackPrototype>
              {updateError?.data?.errors?.lastname}
            </ErrorFeedbackPrototype>
          )}

          {updateError?.data?.errors?.city && (
            <ErrorFeedbackPrototype>
              {updateError?.data?.errors?.city}
            </ErrorFeedbackPrototype>
          )}

          {updateError?.data?.errors?.phone && (
            <ErrorFeedbackPrototype>
              {updateError?.data?.errors?.phone}
            </ErrorFeedbackPrototype>
          )}

          {updateError?.data?.errors?.household_count && (
            <ErrorFeedbackPrototype>
              {updateError?.data?.errors?.household_count}
            </ErrorFeedbackPrototype>
          )}

          {updateError?.data?.errors?.country_id && (
            <ErrorFeedbackPrototype>
              {updateError?.data?.errors?.country_id}
            </ErrorFeedbackPrototype>
          )}

          {updateError?.data?.errors?.gender && (
            <ErrorFeedbackPrototype>
              {updateError?.data?.errors?.gender}
            </ErrorFeedbackPrototype>
          )}
        </>
      )}
    </Modal>
  );
};

export default UpdateProfileModalPrototype;
