import React from "react";

import "./index.less";

const KebabMenu = ({ className="", onClick }) => {
    return (
        <svg
            className={`kebab-icon-svg ${className}`}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            onClick={onClick}
        >
            <g transform="translate(17.535 24) rotate(180)">
                <rect
                    className="a"
                    width="24"
                    height="24"
                    transform="translate(-6.465)"
                />
                <g transform="translate(3.309 4)">
                    <path
                        className="b"
                        d="M2,4A2,2,0,1,0,0,2,2.006,2.006,0,0,0,2,4ZM2,6A2,2,0,1,0,4,8,2.006,2.006,0,0,0,2,6Zm0,6a2,2,0,1,0,2,2A2.006,2.006,0,0,0,2,12Z"
                        transform="translate(0)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default KebabMenu;
