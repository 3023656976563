const { useState, useEffect } = require('react');

export const useReorderByDirection = (items) => {
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const reorderByPosition = (items) => {
        let lPlaylist = [];
        let rPlaylist = [];

        for (let pl of items) {
            // if (pl.is_left) {
            // lPlaylist.push(pl);
            // } else {
            // rPlaylist.push(pl);
            // }
            lPlaylist.push(pl);

        }

        setLeft(lPlaylist);
        setRight(rPlaylist);

    };

    useEffect(() => {
        if(!items) return;
        reorderByPosition(items);
    }, [items])


    return [left, right];
}