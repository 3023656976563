import React from "react";
import { useContext } from "react";
import { Context } from "@context/context";

import "./index.less";
import DraggableList from "@components/organisms/lists/DraggableList";
import SubBarItemPrototype from "@shared/molecules/listItems/SubBarItemPrototype";
import { DroppableTypes } from "@context/dndContextPrototype";

const ArrangeMode = ({ 
  children, content, droppableId = DroppableTypes.ArrangeMode, defaultIcon
 }) => {
  const { isArrangeMode } =
    useContext(Context);

  if (!isArrangeMode) return children;

  return (
    <div className="arrange-mode">
      <DraggableList
        listItems={content}
        droppableId={ droppableId }
        direction="horizontal"
        style={{ display: "flex" }}
        isCombineEnabled
      >
        {(playlist, provided) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <SubBarItemPrototype
                itemClick={() => {}}
                content={playlist.name}
                image={playlist.icon || defaultIcon}
                item={playlist}
              />
            </div>
          );
        }}
      </DraggableList>
    </div>
  );
};

export default ArrangeMode;
