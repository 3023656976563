import { useEffect } from "react";

/**
 * Hook that checks clicks outside of the passed ref
 *
 * @param {ref} ref - used for container ref
 * @param {fuction} callback - executes when outside click happens
 * @example
 *
 *  useOutsideClick(containerRef, function () {
 *      closeMenu();
 *  });
 *
 *
 *
 */
function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export default useOutsideClick;
