import { CHOICE_URL } from "@modules/config";
import {
  COPY_GROUP_FAIL,
  COPY_GROUP_REQUEST,
  COPY_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  EDIT_GROUP_FAIL,
  EDIT_GROUP_REQUEST,
  EDIT_GROUP_SUCCESS,
  GROUP_ARRANGE_REQUEST,
  GROUP_ARRANGE_SUCCESS,
  GROUP_PLAYLISTS_ARRANGE_FAIL,
  GROUP_PLAYLISTS_ARRANGE_REQUEST,
  GROUP_PLAYLISTS_ARRANGE_SUCCESS,
} from "@redux/constants/groupConstants";
import { axiosInstance } from "../../modules/request/api";
import { getPlaylists } from "./playlistsActions";

export const deleteGroup = (id) => async (dispatch) => {
  dispatch({ type: DELETE_GROUP_REQUEST });

  try {
    const { data } = await axiosInstance().delete(
      `${CHOICE_URL}/api/groups/${id}`
    );
    dispatch({ type: DELETE_GROUP_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: DELETE_GROUP_FAIL, payload: error.response });
  }
};

export const editGroup = (id, dataObj, refetch = true) => async (dispatch) => {
  dispatch({ type: EDIT_GROUP_REQUEST });
  try {
    const data = await axiosInstance().put(
      `${CHOICE_URL}/api/groups/${id}`,
      dataObj
    );
    dispatch({ type: EDIT_GROUP_SUCCESS, payload: data });
    if (refetch) {
      dispatch(getPlaylists())
    }
  } catch (error) {
    dispatch({ type: EDIT_GROUP_FAIL });
  }
};

export const copyGroup =
  (groupId, copiedPosition, refetch = true) =>
    async (dispatch) => {
      dispatch({ type: COPY_GROUP_REQUEST });
      try {
        const data = await axiosInstance().post(
          `${CHOICE_URL}/api/groups/${groupId}/copy`,
          { position: copiedPosition }
        );
        dispatch({ type: COPY_GROUP_SUCCESS, payload: data });
        if (refetch) {
          dispatch(getPlaylists());
        }
      } catch (error) {
        dispatch({ type: COPY_GROUP_FAIL, payload: error.response });
      }
    };

export const reArrangeGroup = (groupArr) => async (dispatch) => {
  try {
    dispatch({ type: GROUP_ARRANGE_REQUEST })
    const { data } = await axiosInstance().post(`${CHOICE_URL}/api/groups/rearrange`,
      { groups: groupArr }
    )
    dispatch({ type: GROUP_ARRANGE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: GROUP_ARRANGE_SUCCESS, payload: error.response })
  }
}

export const reArangeGroupPlaylists = (groupId, playlistArr) => async (dispatch) => {
  try {
    dispatch({ type: GROUP_PLAYLISTS_ARRANGE_REQUEST })
    const { data } = await axiosInstance().post(`${CHOICE_URL}/api/groups/${groupId}/rearrange-playlists`, { playlists: playlistArr })
    dispatch({ type: GROUP_PLAYLISTS_ARRANGE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: GROUP_PLAYLISTS_ARRANGE_FAIL, payload: error.response })
  }
}