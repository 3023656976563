import {
  CHANNEL_GROUPS_REQUEST,
  CHANNEL_GROUPS_SUCCESS,
  CHANNEL_GROUPS_FAIL,
  CHANNEL_ITEMS_REQUEST,
  CHANNEL_ITEMS_SUCCESS,
  CHANNEL_ITEMS_FAIL,
  CHANNELS_REQUEST,
  CHANNELS_SUCCESS,
  CHANNELS_FAIL,
  CURRENT_CHANNEL_ITEMS_REQUEST,
  CURRENT_CHANNEL_ITEMS_SUCCESS,
  CURRENT_CHANNEL_ITEMS_FAIL,
  CREATE_CHANNEL_ITEM_REQUEST,
  CREATE_CHANNEL_ITEM_SUCCESS,
  CREATE_CHANNEL_ITEM_FAIL,
  UPDATE_CHANNELITEM_REQUEST,
  UPDATE_CHANNELITEM_SUCCESS,
  UPDATE_CHANNELITEM_FAIL,
  CREATE_CHANNELGROUP_REQUEST,
  CREATE_CHANNELGROUP_SUCCESS,
  CREATE_CHANNELGROUP_FAIL,
  DELETE_CHANNELGROUP_REQUEST,
  DELETE_CHANNELGROUP_SUCCESS,
  DELETE_CHANNELGROUP_FAIL,
  EPG_CHANNELS_REQUEST,
  EPG_CHANNELS_SUCCESS,
  EPG_CHANNELS_FAIL,
  EPG_CHANNELS_RESET,
  CHANNEL_KEYWORDS_REQUEST,
  CHANNEL_KEYWORDS_SUCCESS,
  CHANNEL_KEYWORDS_FAIL,
  CHANNEL_SEARCH_REQUEST,
  CHANNEL_SEARCH_SUCCESS,
  CHANNEL_SEARCH_FAIL,
  CHANNEL_BY_ID_REQUEST,
  CHANNEL_BY_ID_SUCCESS,
  CHANNEL_BY_ID_FAIL,
  DUTCH_CHANNELGROUPS_REQUEST,
  DUTCH_CHANNELGROUPS_SUCCESS,
  DUTCH_CHANNELGROUPS_FAIL,
  DUTCH_CHANNELS_REQUEST,
  DUTCH_CHANNELS_SUCCESS,
  DUTCH_CHANNELS_FAIL,
  CHANNEL_BY_ID_RESET,
  COPY_TIRES_REQUEST,
  COPY_TIRES_SUCCESS,
  COPY_TIRES_FAIL,
  PROMOTION_CHANNELS_REQUEST,
  PROMOTION_CHANNELS_SUCCESS,
  PROMOTION_CHANNELS_FAIL,
  CHANNELS_EPG_DIRECT_SHARE_REQUEST,
  CHANNELS_EPG_DIRECT_SHARE_SUCCESS,
  CHANNELS_EPG_DIRECT_SHARE_FAIL,
  UNSUBSCRIBE_TO_CHANNEL_REQUEST,
  UNSUBSCRIBE_TO_CHANNEL_SUCCESS,
  SUBSCRIBE_TO_CHANNEL_REQUEST,
  SUBSCRIBE_TO_CHANNEL_SUCCESS,
  SUBSCRIBE_TO_CHANNEL_FAIL,
  UNSUBSCRIBE_TO_CHANNEL_FAIL,
} from "../constants/channelConstants";

export const channelGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANNEL_GROUPS_REQUEST:
      return { loading: true };
    case CHANNEL_GROUPS_SUCCESS:
      return { loading: false, groups: action.payload };
    case CHANNEL_GROUPS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const channelItemsReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANNEL_ITEMS_REQUEST:
      return { loading: true };
    case CHANNEL_ITEMS_SUCCESS:
      return { loading: false, items: action.payload };
    case CHANNEL_ITEMS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const currentChannelItemsReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_CHANNEL_ITEMS_REQUEST:
      return { loading: true };
    case CURRENT_CHANNEL_ITEMS_SUCCESS:
      return { loading: false, currentChannelItems: action.payload };
    case CURRENT_CHANNEL_ITEMS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createChannelItemReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CHANNEL_ITEM_REQUEST:
      return { loading: true };
    case CREATE_CHANNEL_ITEM_SUCCESS:
      return { loading: false, createdChannelItem: action.payload };
    case CREATE_CHANNEL_ITEM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateChannelItemReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CHANNELITEM_REQUEST:
      return { loading: true };
    case UPDATE_CHANNELITEM_SUCCESS:
      return { loading: false, updatedChannelItem: action.payload };
    case UPDATE_CHANNELITEM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const channelsReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANNELS_REQUEST:
      return { loading: true };
    case CHANNELS_SUCCESS:
      return { loading: false, items: action.payload };
    case CHANNELS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createChannelGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CHANNELGROUP_REQUEST:
      return { loading: true };
    case CREATE_CHANNELGROUP_SUCCESS:
      return { loading: false, newGroup: action.payload };
    case CREATE_CHANNELGROUP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteChannelGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CHANNELGROUP_REQUEST:
      return { loading: true };
    case DELETE_CHANNELGROUP_SUCCESS:
      return { loading: false, success: true };
    case DELETE_CHANNELGROUP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const epgChannelsReducer = (state = {}, action) => {
  switch (action.type) {
    case EPG_CHANNELS_REQUEST:
      return { loading: true };
    case EPG_CHANNELS_SUCCESS:
      return { loading: false, epgs: action.payload };
    case EPG_CHANNELS_FAIL:
      return { loading: false, error: action.payload };

    case EPG_CHANNELS_RESET:
      return {};

    default:
      return state;
  }
};

export const channelByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANNEL_BY_ID_REQUEST:
      return { loading: true };
    case CHANNEL_BY_ID_SUCCESS:
      return { loading: false, channel: action.payload };
    case CHANNEL_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case CHANNEL_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const channelKeywordsReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANNEL_KEYWORDS_REQUEST:
      return { loading: true };
    case CHANNEL_KEYWORDS_SUCCESS:
      return { loading: false, suggestions: action.payload };
    case CHANNEL_KEYWORDS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const channelSearchReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANNEL_SEARCH_REQUEST:
      return { loading: true };
    case CHANNEL_SEARCH_SUCCESS:
      return { loading: false, items: action.payload };
    case CHANNEL_SEARCH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dutchChannelGroupsReducer = (state = {}, action) => {
  switch (action.type) {
    case DUTCH_CHANNELGROUPS_REQUEST:
      return { loading: true };
    case DUTCH_CHANNELGROUPS_SUCCESS:
      return { loading: false, groups: action.payload };
    case DUTCH_CHANNELGROUPS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dutchChannelsReducer = (state = {}, action) => {
  switch (action.type) {
    case DUTCH_CHANNELS_REQUEST:
      return { loading: true };
    case DUTCH_CHANNELS_SUCCESS:
      return { loading: false, channels: action.payload };
    case DUTCH_CHANNELS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const copyTierReducer = (state = {}, action) => {
  switch (action.type) {
    case COPY_TIRES_REQUEST:
      return { loading: true };
    case COPY_TIRES_SUCCESS:
      return { loading: false, success: true };
    case COPY_TIRES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const promotionChannelsReducer = (state = {}, action) => {
  switch (action.type) {
    case PROMOTION_CHANNELS_REQUEST:
      return { loading: true };
    case PROMOTION_CHANNELS_SUCCESS:
      return { loading: false, promotedChannels: action.payload };
    case PROMOTION_CHANNELS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const directShareChannelsEpgReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANNELS_EPG_DIRECT_SHARE_REQUEST:
      return { loading: true };
    case CHANNELS_EPG_DIRECT_SHARE_SUCCESS:
      return { loading: false, data: action.payload };
    case CHANNELS_EPG_DIRECT_SHARE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const subscribeToChanelReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIBE_TO_CHANNEL_REQUEST:
      return { loading: true };
    case SUBSCRIBE_TO_CHANNEL_SUCCESS:
      return { loading: false, data: action.payload };
    case SUBSCRIBE_TO_CHANNEL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const unsubscribeToChanelReducer = (state = {}, action) => {
  switch (action.type) {
    case UNSUBSCRIBE_TO_CHANNEL_REQUEST:
      return { loading: true };
    case UNSUBSCRIBE_TO_CHANNEL_SUCCESS:
      return { loading: false, data: action.payload };
    case UNSUBSCRIBE_TO_CHANNEL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
