import { axiosInstance } from "@modules/request/api";
import {
    SET_BALANCE_REQUST,
    SET_BALANCE_SUCCESS,
    SET_BALANCE_FAIL,
    TRANSACTIONS_REQUEST,
    TRANSACTIONS_SUCCESS,
    TRANSACTIONS_FAIL,
    SUBSCRIBTIONS_REQUEST,
    SUBSCRIBTIONS_SUCCESS,
    SUBSCRIBTIONS_FAIL,
    TIERS_REQUEST,
    TIERS_SUCCESS,
    TIERS_FAIL,
    SUBSCRIBE_TIER_REQUEST,
    SUBSCRIBE_TIER_SUCCESS,
    SUBSCRIBE_TIER_FAIL,
    SUBSCRIBE_TIER_CLEAR,
    TIER_DATE_PERIOD_REQUEST,
    TIER_DATE_PERIOD_SUCCESS,
    TIER_DATE_PERIOD_FAIL,
    COUPON_CODE_REQUEST,
    COUPON_CODE_SUCCESS,
    COUPON_CODE_FAIL,
    UNSUBSCRIBE_TIER_REQUEST,
    UNSUBSCRIBE_TIER_SUCCESS,
    UNSUBSCRIBE_TIER_FAIL,
} from "../constants/paymentConstants";
import { CHOICE_URL } from "@modules/config";

export const setBalance = (amount) => async (dispatch) => {
    try {
        dispatch({ type: SET_BALANCE_REQUST });
        const { data } = await axiosInstance().post(
            `${CHOICE_URL}/api/wallet`,
            { amount: amount }
        );
        dispatch({ type: SET_BALANCE_SUCCESS, payload: data });
        if (data.url) {
            window.location.replace(data.url);
        } else {
            dispatch({ type: SET_BALANCE_FAIL, payload: data });
        }
    } catch (error) {
        dispatch({ type: SET_BALANCE_FAIL, payload: error.response });
    }
};

export const getTransactions = () => async (dispatch) => {
    try {
        dispatch({ type: TRANSACTIONS_REQUEST });
        const { data } = await axiosInstance().get(`${CHOICE_URL}/api/wallet`);

        dispatch({ type: TRANSACTIONS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: TRANSACTIONS_FAIL, payload: error.response });
    }
};

export const getSubscribtions = () => async (dispatch) => {
    try {
        dispatch({ type: SUBSCRIBTIONS_REQUEST });
        const { data } = await axiosInstance().get(
            `${CHOICE_URL}/api/subscriptions`
        );
        dispatch({ type: SUBSCRIBTIONS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SUBSCRIBTIONS_FAIL, payload: error.response });
    }
};

export const getTiers = (isAuth = false) => async (dispatch) => {
    try {
        dispatch({ type: TIERS_REQUEST });
        // const URL = `${CHOICE_URL}/api/tier${isAuth ? "/auth" : ""}`;
        const URL = `${CHOICE_URL}/api/tier`;
        const { data } = await axiosInstance().get(URL);
        dispatch({ type: TIERS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: TIERS_FAIL, payload: error.response });
    }
};

export const subscribeTier = (tierId, couponCode = null) => async (dispatch) => {
    try {
        dispatch({ type: SUBSCRIBE_TIER_REQUEST });
        const { data } = await axiosInstance().post(
            `${CHOICE_URL}/api/subscriptions`,
            { tier_id: tierId, coupon_code: couponCode }
        );
        dispatch({ type: SUBSCRIBE_TIER_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SUBSCRIBE_TIER_FAIL, payload: error.response });
    }
};

export const unsubscribeTier = (tierId) => async (dispatch) => {
    try {
        dispatch({ type: UNSUBSCRIBE_TIER_REQUEST })
        const { data } = await axiosInstance().post(`${CHOICE_URL}/api/subscriptions/unsubscribe`, {
            tier_id: tierId
        })
        dispatch({ type: UNSUBSCRIBE_TIER_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: UNSUBSCRIBE_TIER_FAIL, payload: error.response })
    }
}

export const clearSubscribedTireMessage = () => {
    return { type: SUBSCRIBE_TIER_CLEAR };
};

export const getTierSubscriptionDate = (tierId) => async (dispatch) => {
    try {
        dispatch({ type: TIER_DATE_PERIOD_REQUEST });
        const { data } = await axiosInstance().get(
            `${CHOICE_URL}/api/tier/${tierId}/date-period`
        );
        dispatch({ type: TIER_DATE_PERIOD_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: TIER_DATE_PERIOD_FAIL, payload: error?.response });
    }
};


export const validateCouponCode = (couponCode, tierId) => async (dispatch) => {
    try {
        dispatch({ type: COUPON_CODE_REQUEST })
        const { data } = await axiosInstance().get(`${CHOICE_URL}/api/tier/sale-price?coupon_code=${couponCode}&tier_id=${tierId}`)

        dispatch({ type: COUPON_CODE_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: COUPON_CODE_FAIL, payload: error.response })
    }
}