import {
  GET_HOME_CONTENT_REQUEST,
  GET_HOME_CONTENT_SUCCESS,
  GET_HOME_CONTENT_FAIL,
  GET_HOME_GLOBE_REQUEST,
  GET_HOME_GLOBE_SUCCESS,
  GET_HOME_GLOBE_FAIL,
  GET_HOME_HEART_REQUEST,
  GET_HOME_HEART_SUCCESS,
  GET_HOME_HEART_FAIL,
  TOGGLE_WELCOME_MESSAGE_REQUEST,
  TOGGLE_WELCOME_MESSAGE_SUCCESS,
  TOGGLE_WELCOME_MESSAGE_FAIL,
  GET_HOME_TIERS_REQUEST,
  GET_HOME_TIERS_SUCCESS,
  GET_HOME_TIERS_FAIL,
  GET_HOME_USER_REQUEST,
  GET_HOME_USER_SUCCESS,
  GET_HOME_USER_FAIL,
  GET_HOME_DIRECT_REQUEST,
  GET_HOME_DIRECT_SUCCESS,
  GET_HOME_DIRECT_FAIL,
  GET_HOME_WATCH_LATER_REQUEST,
  GET_HOME_WATCH_LATER_SUCCESS,
  GET_HOME_WATCH_LATER_FAIL,
} from "../constants/homeConstants";
import { CHOICE_URL } from "@modules/config";
import { axiosInstance } from "../../modules/request/api";

export const getHomeContents = () => async (dispatch) => {
  dispatch({ type: GET_HOME_CONTENT_REQUEST });

  try {
    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/home`);

    dispatch({ type: GET_HOME_CONTENT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_HOME_CONTENT_FAIL, payload: error.message });
  }
};

export const getHomeGlobeContents = () => async (dispatch) => {
  dispatch({ type: GET_HOME_GLOBE_REQUEST });
  try {
    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/pages/globe`);
    dispatch({ type: GET_HOME_GLOBE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_HOME_GLOBE_FAIL, payload: error.message });
  }
};

export const getHomeHeartContents = () => async (dispatch) => {
  dispatch({ type: GET_HOME_HEART_REQUEST });
  try {
    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/pages/heart`);
    dispatch({ type: GET_HOME_HEART_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_HOME_HEART_FAIL, payload: error.message });
  }
};

export const getHomeTiersContents = () => async (dispatch) => {
  dispatch({ type: GET_HOME_TIERS_REQUEST });
  try {
    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/pages/tv`);
    dispatch({ type: GET_HOME_TIERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_HOME_TIERS_FAIL, payload: error });
  }
};

export const getHomeUserContents = () => async (dispatch) => {
  dispatch({ type: GET_HOME_USER_REQUEST });
  try {
    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/pages/user`);
    dispatch({ type: GET_HOME_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_HOME_USER_FAIL, payload: error.message });
  }
};

export const getDirectPublishContent = () => async (dispatch) => {
  dispatch({ type: GET_HOME_DIRECT_REQUEST });
  try {
    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/pages/directPublish`);
    dispatch({ type: GET_HOME_DIRECT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_HOME_DIRECT_FAIL, payload: error.message });
  }
};

export const getWatchLaterContent = () => async (dispatch) => {
  dispatch({ type: GET_HOME_WATCH_LATER_REQUEST });
  try {
    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/pages/watchLater`);
    dispatch({ type: GET_HOME_WATCH_LATER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_HOME_WATCH_LATER_FAIL, payload: error.message });
  }
};

export const toggleWelcomeMsg = (field) => async (dispatch) => {
  dispatch({ type: TOGGLE_WELCOME_MESSAGE_REQUEST });
  try {
    const { data } = await axiosInstance().put(
      `${CHOICE_URL}/api/pages/toggle-welcome`,
      { field }
    );
    // await axiosInstance().get(`${CHOICE_URL}/api/pages/watchLater`);

    // await axiosInstance().get(`${CHOICE_URL}/api/pages/directPublish`);
    dispatch({ type: TOGGLE_WELCOME_MESSAGE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: TOGGLE_WELCOME_MESSAGE_FAIL, payload: error.message });
  }
};
