import React, { useState, useEffect, useContext, useCallback } from "react";
import { Context } from "@context/context";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Button from "@shared/atoms/Button/index";
import Input from "@shared/atoms/Input/index";
import Alert from "@shared/atoms/Alert/index";
import Form from "@shared/atoms/Form/index";
import { formLayout } from "@modules/config";
import EnterEmailModal from "../../modals/EnterEmailModal/index";
import ForgetPasswordModal from "../../modals/ForgetPasswordModal/index";
import ConfirmEmailModal from "../../modals/ConfirmEmailModal/index";
import { login } from "@redux/actions/userActions";
import { localStorageService } from "../../../../services/localStorage.service";
import { passwordReset } from "@redux/actions/userActions";
import ReCAPTCHA from "react-google-recaptcha";
import { keyExists } from "../../../../../utils/keyExists";
import { useTranslation } from "react-i18next";

import "./index.less";

const AuthForm = () => {
    const history = useHistory();

    const { t } = useTranslation();
    const params = useParams();

    const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
    const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isVerified, setIsVerified] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const {
        userEmail,
        // resetPasswordOtpCode
    } = useContext(Context);

    const userLogin = useSelector((state) => state.userLogin);
    const { loading, error, loggedIn } = userLogin;

    const userPasswordReset = useSelector((state) => state.userPasswordReset);
    const { error: resetError, success: resetSuccess } = userPasswordReset;

    // confirm user reset password
    const { error: confirmResetError, success: confirmResetSuccess } =
        useSelector((state) => state.userConfirmResetPassword);

    const userConfirmResetPassword = useSelector(
        (state) => state.userConfirmResetPassword
    );
    const {
        error: resetConfrimError,
        // success: resetConfirmSuccess
    } = userConfirmResetPassword;

    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorageService().get("access_token")) {
            history.push("/channels");
        }
    }, [loggedIn, history]);

    useEffect(() => {
        if (loading) {
            setDisableBtn(true);
        } else {
            setDisableBtn(false);
        }
        if (username && password && isVerified
        ) {
            setDisableBtn(false);
            if (loading) {
                setDisableBtn(true);
            } else {
                setDisableBtn(false);
            }
        } else {
            setDisableBtn(true);
        }
    }, [disableBtn, loading, username, password, isVerified]);

    const loginErrorHandler = useCallback(() => {
        if (error) {
            if (!error.data.errors) {
                if (error.data.password) {
                    setErrorMsg(error.data.password);
                    return;
                }
                if (error.data.email) {
                    setErrorMsg(error.data.email);
                    return;
                }
                setErrorMsg(error.data.message);
                return;
            }
            let errorObj = error.data.errors;
            let errorMessage = "";

            for (const key in errorObj) {
                errorMessage += `${errorObj[key].join(" ")} `;
            }

            setErrorMsg(errorMessage);
        }
    }, [error]);

    useEffect(() => {
        loginErrorHandler();
    }, [loginErrorHandler, error]);

    const handleCloseEmailModal = () => {
        setIsEmailModalVisible(false);
    };
    const handleClosePasswordModal = () => {
        setIsPasswordModalVisible(false);
    };

    const handleCloseConfirmModal = () => {
        setIsConfirmModalVisible(false);
    };

    const handleShowPasswordModal = () => {
        // dispatch(confirmResetPassword(resetPasswordOtpCode, userEmail));
        handleCloseConfirmModal();
        // setTimeout(() => {
        //   setIsPasswordModalVisible(true);
        // }, 500);
    };

    const handleConfirmEmailModal = () => {
        // dispatch(confirmEmail(userEmail));
        dispatch(passwordReset(userEmail));

        handleCloseEmailModal();
        // setTimeout(() => {
        //   setIsConfirmModalVisible(true);
        // }, 500);
    };

    const handleSubmit = () => {
        dispatch(login(username, password));
    };

    const handleVerifyCaptcha = (value) => {
        setIsVerified(true);
    };

    // user is redirected from the reset password link -> example.com/reset-password/:token/:email
    useEffect(() => {
        if (keyExists(["token", "email"], params)) {
            setIsPasswordModalVisible(true);
        }
    }, [params]);

    return (
        <div className="auth-form">
            {error && (
                <Alert
                    message={errorMsg ? errorMsg : error.data.email}
                    type="error"
                />
            )}
            {resetSuccess && (
                <Alert
                    message={`${t(
                        "the_password_reset_link_is_sent_to"
                    )} "${userEmail}".`}
                    type="success"
                />
            )}
            {resetError && (
                <Alert
                    message={`${t("password_reset_link_send_error")}`}
                    type="error"
                />
            )}
            {confirmResetSuccess && (
                <Alert message={t("password_has_changed")} type="success" />
            )}
            {confirmResetError && (
                <Alert message={t("password_not_changed_error")} type="error" />
            )}
            {resetConfrimError && (
                <Alert
                    type="error"
                    message={resetConfrimError?.data?.message}
                />
            )}
            {/*<button onClick={() => setIsPasswordModalVisible(true)}>show modal</button>*/}
            <Form layout={formLayout}>
                <Form.Item label={`${t("email_or_username")}`}>
                    <Input
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder={`${t("enter_your_email_or_username")}`}
                    />
                </Form.Item>
                <Form.Item label={`${t("password")}`}>
                    <Input.Password
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={t("enter_password")}
                    />
                </Form.Item>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    onChange={handleVerifyCaptcha}
                    className="re-captcha"
                />
                <span
                    onClick={() => setIsEmailModalVisible(true)}
                    className="forgot-password"
                >
                    {t("forgot_password")}?
                </span>
                <div className="login-btn-container">
                    <Button
                        disabled={disableBtn}
                        onClick={handleSubmit}
                        className="login-btn-auth"
                        type="primary"
                        htmlType="submit"
                    >
                        {t("login")}
                    </Button>
                </div>
            </Form>
            <EnterEmailModal // request reset password link
                isModalVisible={isEmailModalVisible}
                handleClose={handleCloseEmailModal}
                showConfirmEmailModal={handleConfirmEmailModal}
                formLayout={formLayout}
            />
            <ConfirmEmailModal // code
                isModalVisible={isConfirmModalVisible}
                showPasswordModal={handleShowPasswordModal}
                handleClose={handleCloseConfirmModal}
            />
            {!resetConfrimError && (
                <ForgetPasswordModal // new password modal
                    isPasswordModalVisible={isPasswordModalVisible}
                    handleClosePassword={handleClosePasswordModal}
                    formLayout={formLayout}
                />
            )}
        </div>
    );
};

export default AuthForm;
