import React, { useState, useEffect, useContext, useRef } from "react";
import { Context } from "@context/context";
import List from "@shared/atoms/List";
import { Paragraph } from "@shared/atoms/Typography/index";
import Image from "@shared/atoms/Image";
import ContextMenuPrototype, {
  ContextMenuOption,
} from "@shared/molecules/ContextMenuPrototype";
import DeletePlaylistModalPrototype from "@components/organisms/modals/DeletePlaylistModalPrototype";
import { shortenString } from "@utils/shortenString";
import { useContextMenu } from "hooks/useContextMenu";
import useCopyPlaylist from "hooks/useCopyPlaylist";
import usePathName from "hooks/usePathName";
import minusSvg from "@assets/icons/minus.svg";
import shareImg from "@assets/icons/share-prototype.svg";
import copyImg from "@assets/icons/copy.svg";
import arrangeImg from "@assets/icons/arrange.svg";
import deleteImg from "@assets/icons/delete.svg";
import addImg from "@assets/icons/insert-icon.svg";
import archiveSvg from "@assets/icons/archive.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "@redux/reducers/profileReducers";
import { VIEWS_PROTOTYPE } from "const/viewsPrototype";
import RestrictedPlaylistItem from "@components/shared/atoms/icons/RestrictedPlaylistItem";
import PublicPlaylistItem from "@components/shared/atoms/icons/PublicPlaylistItem";
import settingsIcon from "@assets/icons/settings.svg";
import { useHistory, useLocation } from "react-router-dom";
import {
  copyNavbarPlaylist,
  getPlaylistById,
  updatePlaylist,
} from "@redux/actions/playlistsActions";
import { unfollow } from "@redux/actions/socialActions";
import { socialFollowingPlaylists } from "@redux/actions/socialActions";
import { COPY_ITEM } from "const/playlists";
import querystring from "query-string";

import { localStorageService } from "@modules/services/localStorage.service";
import {
  clearHistory,
  deleteSearchHistoryItem,
  getSearchHistory
} from "@redux/actions/searchActions";

import "./index.less";
import { PAGES } from "const/pages";
import { copyGroup, editGroup } from "@redux/actions/groupActions";
import DeleteShortcutModalPrototype from "@components/organisms/modals/DeleteShortcutModalPrototype";
import { METHODS } from "const/apiMethods";
import { saveLatestPlaylistId } from "@utils/saveLatestPlaylistId";
import { useAccountMode, useTier } from "hooks";
import AlertModalPrototype from "@components/organisms/modals/AlertModalPrototype";
import { LOCALSTORAGE } from "const";
import DeleteTeamModalPrototype from "@components/organisms/modals/DeleteTeamModalPrototype";
import { deleteTeamPlaylistAction } from "@redux/actions/TeamActions";

const activeItemStyle = {
  backgroundColor: "#577d97",
  borderRadius: "1rem",
  padding: "1rem 0.2rem",
};

const activeOpenableItemStyle = {
  backgroundColor: "#577d97",
  borderRadius: "1rem",
};

const SubBarItemPrototype = ({
  image,
  content = "title",
  isSearch = false,
  openable = false,
  isAddNew = false,
  itemClick,
  showSearch,
  openableData,
  openableItemClick,
  item,
  index,
  onClick,
  tierId,
  hasButtons,
  opened,
  tierIndex,
  restrictedTier,
  publicTier,
  contentLen,
  contextTier,
  isOpenableTierContext = false,
  openableDataClick = null,
  isOpenableRestrictedTierContext,
  isTier = false,
  isArchive,
  isGroup,
  isPromotion = false,
  isPublic,
  isRestricted,
  isDefaultPlaylist = false,
  isFollowed = false,
  isPlaylist,
  isShortCut,
  isTeam,
  isTeamOpenable,
  active,
  fullDataLength,
  defaultOpenableItemImage,
  arrangeChildren,
  isDirectPublish = false,
  isArchivedGroup,
  children,
  staticChildItems
}) => {
  const isLoggedIn = !!localStorageService().get("access_token");
  const currentPage = usePathName();
  const [subbarListClassName, setSubbarListClassName] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showShortCutDeleteModal, setShowShortCutDeleteModal] = useState(false);
  const [tierItem, setTierItem] = useState("");
  const [openableItem, setOpenableItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(item || null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [teamGroupItem, setTeamGroupItem] = useState(false);

  const { copyPlaylist, isCopyingItem } = useCopyPlaylist();
  const { isTeamMode, teamId, fetchTeam } = useAccountMode();

  const dispatch = useDispatch();
  const history = useHistory();

  //const isDirectPublish = playlistById.playlist?.data?.direct_publish;

  const activeItemRef = useRef(null);

  const {
    isVisible,
    closeContextMenu,
    xPos,
    yPos,
    openContextMenu,
    withCloseContextMenu,
    contextMenuClickWithDebounce,
    onTouchEnd,
  } = useContextMenu([".subbar-item-context", ".subbar-item-container"]);

  const {
    setShowShareModalPrototype,
    setSharebaleLink,
    setShowAddPlaylistModalPrototype,
    setIndexOfNewPlaylist,
    setTierListOpened,
    copiedPlaylist,
    setCopiedPlaylist,
    currentViewPrototype,
    setIsArrangeMode,
    setIsOpenableArrangeMode,
    setIsRestrictedOpenableArrangeMode,
    setShowAddPlaylistItemModalPrototype,
    setIsSubbarHorizontalScrollable,
    setOpenUnsubscribeTierModalPrototype,
    setTierPrototype,
    setShowDeleteGroupModalPrototype,
    setShowCreateGroupModalPrototype,
    setShowCopyInHeartDimensionModal,
    setDeletableGroupInfo,
    setIsGroupArrangeMode,
    isArrangeMode,
    setIsShortcutArrangeMode,
    copiedGroup,
    setCopiedGroup,
    setShowEditGroupNameModalPrototype,
    setCurrentGroupPrototype,
    togglePlaylistEditMode,
    isEditMode,
    setShowEditPlaylistModalPrototype,
    setShowCreateTeamModal,
    setShowDeleteTeamModal,
    showDeleteTeamModal,
    setShowEditTeamsModal
  } = useContext(Context);

  const { search, pathname } = useLocation();
  const currentRoute = pathname.substring(1, pathname.length);

  const isMyPlaylstsPage = currentRoute === PAGES.myPlaylists;

  const parsedQueryString = querystring.parse(search);
  const playlistIdFromUrl = parsedQueryString.playlist;
  const parsedGroupId = parsedQueryString["playlist-group"];
  const isArchiveIcon = parsedQueryString["archive"];
  // const tierIdFromUrl = parsedQueryString.tier;

  const currentUser = useSelector(selectCurrentUser);
  const { statuses } = useSelector((state) => state.playlistStatuses);
  const { playlist } = useSelector((state) => state.playlistById);

  const tierContainerRef = useRef(null);

  //isDirectPublish
  function preventContext() {
    return !(isSearch || isAddNew) || openable && isVisible;
  }

  const openListHandler = () => {
    if (onClick) onClick();
  };

  useEffect(() => {
    if (opened) {
      setSubbarListClassName("subbar-item-openable-opened");
    } else {
      setSubbarListClassName("");
    }
  }, [opened]);

  useEffect(() => {
    if (activeItemRef.current) {
      let element = activeItemRef.current;
      element?.scrollIntoView({
        block: "nearest",
        inline: "center",
      });
    }
    if (tierContainerRef) {
      let element = tierContainerRef.current;
      if (
        String(element?.dataset.id) === String(parsedQueryString.playlist) &&
        element
      ) {
        element?.scrollIntoView({
          block: "nearest",
          inline: "center",
        });
      }
    }
  }, [activeItemRef, currentPage]);

  const guestUser = () => {
    setIsPopupOpen(true);
  }

  const shareHandler = () => {
    if (!isLoggedIn) return guestUser();
    setShowShareModalPrototype(true);

    if (tierItem) {
      if (tierItem.user_id) {
        setSharebaleLink(
          window.location.origin + `/playlists?playlist=${tierItem.id}`
        );
      }
      return;
    }

    if (item) {
      if (item.user_id) {
        setSharebaleLink(
          window.location.origin + `/playlists?playlist=${item.id}`
        );
      }
    } else if (openableItem) {
      setSharebaleLink(
        window.location.origin + `/playlists?playlist=${openableItem.id}`
      );
    }
  };

  const openAddVideoHandler = (e) => {
    setShowAddPlaylistItemModalPrototype(true);
  };

  const copyHandler = () => {
    if (!isLoggedIn) return guestUser();
    if (tierItem) {
      const tierId = tierContainerRef.current.dataset.tierid;
      setCopiedPlaylist({
        copiedItem: { ...tierItem, tierName: item.name, tierId, },
        type: COPY_ITEM.TV_CHANNEL,
      });
      if (tierItem.user_id) {
        navigator.clipboard.writeText(
          window.location.origin + `/playlists?playlist=${tierItem.id}`
        );
      }
      return;
    }

    if (item) {
      if (item.user_id || isDefaultPlaylist) {
        let itemType = isDefaultPlaylist
          ? COPY_ITEM.DEFAULT_PLAYLIST
          : COPY_ITEM.MY_PLAYLIST;

        const isHiddne = JSON.parse(localStorageService().get(LOCALSTORAGE.HIDE_COPY_IN_HEART_DIMENSION_MODAL));

        if(isFollowed && !isHiddne) {
          setShowCopyInHeartDimensionModal(true);
        }

        setCopiedPlaylist({ copiedItem: item, type: itemType });
        navigator.clipboard.writeText(
          window.location.origin + `/playlists?playlist=${item.id}`
        );
      }
      if (Array.isArray(item.playlists)) {
        setCopiedGroup(item.id);
      }
    } else if (openableItem) {
      navigator.clipboard.writeText(
        window.location.origin + `/playlists?playlist=${openableItem.id}`
      );
    }
  };
  const unfollwHandler = () => {
    Promise.resolve(
      dispatch(unfollow("App\\Playlist", item.id.toString()))
    ).then(() => {
      dispatch(getPlaylistById(playlistIdFromUrl));
      dispatch(socialFollowingPlaylists());
    });
  };

  const openUnsubscribeTierModal = () => {
    setOpenUnsubscribeTierModalPrototype(true);
    setTierPrototype(item);
  };

  const settingsClickHandler = () => {
    dispatch(getPlaylistById(selectedItem.id));
    // history.push({ search: `?playlist=${selectedItem.id}` });
    // old icon/image does not change if we do not wait for some time
    setTimeout(() => {
      setShowEditPlaylistModalPrototype(true);
    }, 1000);
  };

  const editPlaylistHandler = () => {
    dispatch(getPlaylistById(selectedItem.id));
    togglePlaylistEditMode();
  };

  const deleteSearchHistoryItemHandler = () => {
    Promise.resolve(dispatch(deleteSearchHistoryItem(item.id))).then(() => {
      dispatch(getSearchHistory());
    });
  };

  const clearAllSearchHistoryItemHandler = () => {
    Promise.resolve(dispatch(clearHistory())).then(() => {
      dispatch(getSearchHistory());
    })
  }

  const openDeleteGroupModal = () => {
    setShowDeleteGroupModalPrototype(true);
    let channelsStr = item?.playlists
      ?.map((playlist) => playlist.name)
      .join(",");
    setDeletableGroupInfo({ id: item.id, name: item.name, channelsStr });
  };

  const deleteTeamHandler = () => {
    setShowDeleteTeamModal(true);
  }

  const insertPlaylistRightHandler = () => {
    let currentIndex = index;

    if (currentIndex < contentLen) ++currentIndex;
    setIndexOfNewPlaylist(currentIndex);

    setShowAddPlaylistModalPrototype(true);
  };
  
  const handleNewClick = () => {
    if (isTeam) {
      setShowCreateTeamModal(true);
    } else {
      insertPlaylistRightHandler();
    }
  }

  // const insertPlaylistLeftHandler = () => {
  //   let currentIndex = openableData?.findIndex(
  //     (item) => item.id === Number(targetElementId)
  //   );

  //   if (currentIndex > 1) currentIndex = 1;

  //   setIndexOfNewPlaylist(currentIndex);
  //   setShowAddPlaylistModalPrototype(true);
  // };

  const openAddNewGroupModal = () => {
    setShowCreateGroupModalPrototype(true);
  };

  const openEditGroupNameModal = () => {
    setShowEditGroupNameModalPrototype(true);
    setCurrentGroupPrototype(item);
  };

  const handleTierItemClick = (e, item, tierContainerRef) => {
    if (isOpenableTierContext) {
      e.stopPropagation();
      e.preventDefault();
    }
    openableItemClick(item, tierContainerRef);

    if (restrictedTier || publicTier) {
      return;
    } else {
      setTierListOpened((state) =>
        state.map((_, index) => {
          if (index === tierIndex) {
            return (item[index] = true);
          } else {
            return (item[index] = false);
          }
        })
      );
    }
  };

  // const onItemContextClickHandler = (e, item) => {
  //   if (item?.direct_publish) return;

  //   if (isOpenableTierContext) {
  //     setTierItem(item);
  //     return openContextMenu(e);
  //   } else {
  //     return null;
  //   }
  // };

  const handlePaste = (direction) => () => {
    const current = index + 1;
    let calcIndex = direction === "Left" ? current : current + 1;

    if (!Array.isArray(item.playlists)) {
      const { copiedItem } = copiedPlaylist;

      const payloadData = {
        to: calcIndex,
        playlistId: copiedItem.id,
      };

      if (isPublic) {
        payloadData["status_id"] = statuses.find(
          ({ name }) => name === "Public"
        )?.id;
      } else {
        payloadData["status_id"] = statuses.find(
          ({ name }) => name === "Private"
        )?.id;
      }

      dispatch(copyNavbarPlaylist(payloadData));
    } else {
      dispatch(copyGroup(item.id, calcIndex));
    }

    setCopiedPlaylist(null);
    setCopiedGroup(null);
  };

  const movePlaylistToArchive = () => {
    const fd = new FormData();
    fd.append("is_archived", true);
    fd.append("order", 1);
    fd.append("_method", METHODS.PATCH);

    dispatch(updatePlaylist(fd, item.id));
  };

  const handleMoveClick = () => {
    if (!isLoggedIn) return guestUser();
    setIsSubbarHorizontalScrollable(false);
    if (isOpenableTierContext && !isOpenableRestrictedTierContext) {
      setIsOpenableArrangeMode((prev) => !prev);
    } else if (isOpenableTierContext && isOpenableRestrictedTierContext) {
      setIsRestrictedOpenableArrangeMode((prev) => !prev);
    } else if (isGroup) {
      opened ? setIsArrangeMode(true) : setIsGroupArrangeMode(true);
    } else if (isShortCut) {
      setIsShortcutArrangeMode(true);
    } else {
      setIsArrangeMode((prev) => !prev);
    }
    // isOpenableTierContext ? setIsOpenableArrangeMode((prev) => !prev) :
    // setIsArrangeMode((prev) => !prev)
  };
  const toggleGroupInArchive = (action) => {
    if (action === "move") {
      dispatch(editGroup(item.id, { is_archived: true }));
    } else if (action === "remove") {
      dispatch(editGroup(item.id, { is_archived: false }));
    }
  };

  const moveOutPlaylistFromArchive = () => {
    const updatedPlaylist = { _method: "PATCH", is_archived: false };
    dispatch(updatePlaylist(updatedPlaylist, selectedItem.id));
  };

  const handleDelete = () => {
    if(isTeamMode) {
      console.log(playlist);
      dispatch(deleteTeamPlaylistAction(teamId, playlist.data.id)).then(() => {
        fetchTeam();
      })

      return;
    }


    if (isShortCut) {
      setShowShortCutDeleteModal(true);
    } else {
      setShowDeleteModal(true);
    }
  };

  const editModeText = () => {
    if (selectedItem)
      return isEditMode && selectedItem?.id === playlist?.data?.id
        ? "Stop Edit"
        : "Edit";
  };


  const updateUrlParamsForContext = () => {
    setTeamGroupItem(true);
    if (item && isPlaylist) {
      history.push({ search: `?playlist=${item.id}` });
      saveLatestPlaylistId(item.id, "heart");
    } else if (isGroup) {
      history.push({ search: `?playlist-group=${item.id}` });
    } else if (isArchive) {
      history.push({ search: `?archive=true` });
    }
    
  }

  const showContextAndSetPlaylistIdInUrl = (e, plId) => {
    if(e.type === "touchstart") {
      contextMenuClickWithDebounce(e, () => {
        if (isPlaylist || isGroup || isArchive) {
          history.push({ search: `?playlist=${plId}` });
        }
      });
    } else {
      openContextMenu(e)
      if (isPlaylist || isGroup || isArchive) {
        history.push({ search: `?playlist=${plId}` });
      }
    }
  }

  const handleChildItemContextMenu = (e, item) => {
    e.preventDefault();
    setTeamGroupItem(false);
    showContextAndSetPlaylistIdInUrl(e, item.id)
    setOpenableItem(item);
    setTierItem(item);
    if (isArchive || isGroup) {
      setSelectedItem(item);
    }
  }

  const childrenContainerWidth = () => {
    const width = opened ? arrangeChildren && isArrangeMode ? 0 :  7.3 * fullDataLength : 0
    return `${width}rem`
  }

  const getImage= (item) => {
    return item?.logoId || item?.icon || item?.logo || defaultOpenableItemImage
  }

  const handleChildItemClick = (e, item) => {
    if (openableDataClick) {
      openableDataClick(item);
    } else {
      if (isCopyingItem) {
        copyPlaylist(item.id);
      } else {
        setTierItem(item);
        handleTierItemClick(e, item, tierContainerRef);
      }
    }
  }

  const getStylesIfActive = (item) => {
    if(item?.id) {
      return playlistIdFromUrl === item?.id ? activeOpenableItemStyle : {} 
    }
    return {};
  }

  return (
    <>
      <div
        onClick={isSearch ? showSearch : openListHandler}
        className={`${
          isSearch
            ? "search-item flex-container"
            : `flex-container ${openable && "openable"}`
        }`}
        style={active && !openable ? activeItemStyle : {}}
        ref={active ? activeItemRef : null}
        onWheel={closeContextMenu}
      >
        <div
          className={`subbar-item-container ${isSearch ? "search" : ""}`}
          style={getStylesIfActive(item)}
        >
          <AlertModalPrototype 
            isVisible={isPopupOpen}
            closeHandler={() => setIsPopupOpen(false)}
          />  
          <List.Item
            onContextMenu={(e) => {
              updateUrlParamsForContext();
              openContextMenu(e);
            }}
            onTouchStart={(e) => {
              contextMenuClickWithDebounce(e, updateUrlParamsForContext)
            }}
            onTouchEnd={onTouchEnd}
            onClick={itemClick}
            className={`subbar-item ${
              openable ? "subbar-item-openable" : ""
            } ${subbarListClassName}`}
          >
            <div data-id={item?.id} className="subbar-content-container">
              {hasButtons && <div className="red-dots"></div>}
              {isRestricted && (
                <div className="permission-playlist">
                  <RestrictedPlaylistItem />
                </div>
              )}

              {item?.playlists && item?.status === "Private" && (
                <div className="permission-playlist">
                  <RestrictedPlaylistItem />
                </div>
              )}

              {item?.playlists && item?.status === "Public" && (
                <div className="permission-playlist">
                  <PublicPlaylistItem />
                </div>
              )}

              {isPublic && (
                <div className="permission-playlist">
                  <PublicPlaylistItem />
                </div>
              )}

              <Image
                className="subbar-item-img"
                src={image}
                alt={"subbar item "}
              />
              <Paragraph className="subbar-item-para">
                {shortenString(content, 15)}
              </Paragraph>
            </div>
          </List.Item>
          {preventContext() && (
            <ContextMenuPrototype
              isVisible={isVisible}
              handleClose={closeContextMenu}
              xPos={xPos}
              yPos={yPos}
            >
              {(!item?.watch_later &&
                !openableData &&
                !isDirectPublish &&
                !openableDataClick &&
                currentViewPrototype === VIEWS_PROTOTYPE.playlists &&
                !contextTier &&
                !isTier &&
                !isShortCut) || isTeamOpenable && (
                  <ContextMenuOption
                    label="New"
                    icon={addImg}
                    onClick={withCloseContextMenu(handleNewClick)}
                  />
                )}
              {currentRoute !== "search" &&
                !isPromotion &&
                !isTier &&
                !isArchivedGroup &&
                !isShortCut &&
                !isGroup &&
                !isTeam && (
                  <ContextMenuOption
                    onClick={withCloseContextMenu(shareHandler)}
                    icon={shareImg}
                    label="Share"
                  />
                )}

              {(isPlaylist || isArchive) && !isArchiveIcon && (
                <ContextMenuOption
                  onClick={withCloseContextMenu(editPlaylistHandler)}
                  icon={settingsIcon}
                  label={editModeText()}
                />
              )}

              {(isPlaylist || isArchive || isGroup) &&
                !isArchiveIcon &&
                !parsedGroupId && (
                  <ContextMenuOption
                    onClick={withCloseContextMenu(settingsClickHandler)}
                    icon={settingsIcon}
                    label="Settings"
                  />
                )}

              {isPlaylist && (
                <ContextMenuOption
                  label="Archive"
                  icon={archiveSvg}
                  onClick={withCloseContextMenu(movePlaylistToArchive)}
                />
              )}

              {(isArchivedGroup || isArchive) && !isArchiveIcon && (
                <>
                  <ContextMenuOption
                    label="Remove From Archive"
                    icon={archiveSvg}
                    onClick={withCloseContextMenu(() => {
                      if (isArchivedGroup) {
                        toggleGroupInArchive("remove");
                      } else {
                        moveOutPlaylistFromArchive();
                      }
                    })}
                  />
                </>
              )}
              {!isTier && isGroup && (
                <>
                  {!playlistIdFromUrl && (
                    <>
                      <ContextMenuOption
                        label="New Group"
                        icon={addImg}
                        onClick={withCloseContextMenu(openAddNewGroupModal)}
                      />
                      <ContextMenuOption
                        label="Settings"
                        icon={settingsIcon}
                        onClick={withCloseContextMenu(openEditGroupNameModal)}
                      />
                    </>
                  )}
                  <ContextMenuOption
                    label="Archive"
                    icon={archiveSvg}
                    onClick={withCloseContextMenu(() =>
                      toggleGroupInArchive("move")
                    )}
                  />

                  {parsedGroupId ? (
                    <ContextMenuOption
                      label="Delete"
                      icon={deleteImg}
                      onClick={withCloseContextMenu(openDeleteGroupModal)}
                    />
                  ) : (
                    <ContextMenuOption
                      label="Delete"
                      icon={deleteImg}
                      onClick={withCloseContextMenu(handleDelete)}
                    />
                  )}
                </>
              )}

              {((item?.user_id &&
                item?.user_id === currentUser?.id &&
                !item?.watch_later) ||
                isShortCut ||
                isArchive) &&
                !isArchiveIcon && (
                  <ContextMenuOption
                    onClick={withCloseContextMenu(handleDelete)}
                    icon={deleteImg}
                    label="Delete"
                  />
                )}

              {currentRoute !== PAGES.search &&
                !isArchivedGroup &&
                !isShortCut &&
                !isDirectPublish &&
                !isTeam && (
                  <ContextMenuOption
                    onClick={withCloseContextMenu(copyHandler)}
                    icon={copyImg}
                    label="Copy"
                  />
                )}

              {isLoggedIn &&
                !item?.watch_later &&
                currentRoute !== PAGES.search &&
                currentPage !== PAGES.myPlaylists &&
                !isPromotion &&
                !isTier &&
                !isTeam && (
                  <ContextMenuOption
                    onClick={withCloseContextMenu(unfollwHandler)}
                    icon={minusSvg}
                    label="Unsubscribe"
                  />
                )}
              {isTier && item?.is_subscribed && !isTeam && (
                <ContextMenuOption
                  onClick={withCloseContextMenu(openUnsubscribeTierModal)}
                  icon={minusSvg}
                  label={"Unsubscribe"}
                />
              )}

              {currentRoute === PAGES.search && !isTier && (
                <>
                  <ContextMenuOption
                    onClick={withCloseContextMenu(deleteSearchHistoryItemHandler)}
                    icon={deleteImg}
                    label="Delete"
                  />
                  <ContextMenuOption
                    onClick={withCloseContextMenu(clearAllSearchHistoryItemHandler)}
                    icon={deleteImg}
                    label="Delete all"
                  />
                </>
              )}

              {((copiedPlaylist &&
                (copiedPlaylist.type === COPY_ITEM.MY_PLAYLIST ||
                  copiedPlaylist.type === COPY_ITEM.DEFAULT_PLAYLIST)) ||
                copiedGroup) &&
                isMyPlaylstsPage &&
                !isDefaultPlaylist &&
                !isTier &&
                !isArchive && (
                  <>
                    <ContextMenuOption
                      onClick={withCloseContextMenu(handlePaste("Left"))}
                      icon={copyImg}
                      label="<- Paste"
                    />
                    <ContextMenuOption
                      onClick={withCloseContextMenu(handlePaste("Right"))}
                      icon={copyImg}
                      label="Paste ->"
                    />
                  </>
                )}

              {(!item?.watch_later &&
                !isDirectPublish &&
                !openableDataClick &&
                !isArchivedGroup &&
                !isArchiveIcon &&
                (currentViewPrototype === VIEWS_PROTOTYPE.playlists ||
                  currentViewPrototype === VIEWS_PROTOTYPE.channels) &&
                !contextTier) ||
              isGroup ||
              isShortCut ? (
                <ContextMenuOption
                  onClick={withCloseContextMenu(handleMoveClick)}
                  icon={arrangeImg}
                  label="Move"
                />
              ) : null}

              {item?.direct_publish &&
                !openableDataClick &&
                currentViewPrototype === VIEWS_PROTOTYPE.playlists && (
                  <ContextMenuOption
                    label="Add Item"
                    icon={addImg}
                    onClick={withCloseContextMenu(openAddVideoHandler)}
                  />
              )}

              { content === 'teams' && !teamGroupItem &&  (
                <ContextMenuOption
                  onClick={withCloseContextMenu(() => setShowEditTeamsModal(true))}
                  icon={addImg}
                  label="Edit"
                />
              )}

              { content === 'teams' && !teamGroupItem && (
                <ContextMenuOption
                  onClick={withCloseContextMenu(() => deleteTeamHandler())}
                  icon={deleteImg}
                  label="Delete"
                />
              )}
            </ContextMenuPrototype>
          )}
        </div>
        {isArrangeMode && arrangeChildren?.()}
        {openable && (
          <div
          className={`openable-content ${opened ? "opened" : ""} `}
          style={{ width: childrenContainerWidth()  }}
          >
            <>
              {staticChildItems}
              {arrangeChildren && isArrangeMode
                ? null
                : openableData?.map((item) => (
                    <div
                      data-id={item.id}
                      data-tierid={tierId}
                      ref={tierContainerRef}
                      key={item.id}
                      className="openable-item-content"
                      style={getStylesIfActive(item)}

                    >
                      <List.Item
                        className={"subbar-item-context"}
                        onContextMenu={(e) => handleChildItemContextMenu(e, item)}
                        onTouchStart={(e) => handleChildItemContextMenu(e, item)}
                        onTouchEnd={onTouchEnd}
                        onClick={(e) => handleChildItemClick(e, item)}
                      >
                        <Image
                          src={getImage(item)}
                          alt={"subbar item "}
                          className="subbar-item-img"
                        />
                        <Paragraph className="subbar-item-para">
                          {shortenString(item.name, 15)}
                        </Paragraph>
                      </List.Item>
                    </div>
                  ))}
              {children}
            </>
          </div>
        )}
      </div>
      <DeletePlaylistModalPrototype
        isVisible={showDeleteModal}
        setIsVisible={setShowDeleteModal}
        playlist={selectedItem}
      />
      <DeleteShortcutModalPrototype
        isVisible={showShortCutDeleteModal}
        setIsVisible={setShowShortCutDeleteModal}
        id={item?.id}
      />
      <DeleteTeamModalPrototype 
        isVisible={showDeleteTeamModal}
        text="Delete this team?"
        id={openableItem?.id}       
      />
    </>
  );
};

export default SubBarItemPrototype;
