import React from "react";
import AntdButton from "antd/lib/button/index";
import  "./index.less";

// className = ant-btn-prototype
const Button = ({ children, ...rest }) => {
  return (
    <AntdButton  {...rest}>
      {children}
    </AntdButton>
  );
};

export default Button;
