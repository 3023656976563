export const ADULT_CONTENT_REQUEST = "ADULT_CONTENT_REQUEST";
export const ADULT_CONTENT_SUCCESS = "ADULT_CONTENT_SUCCESS";
export const ADULT_CONTENT_FAIL = "ADULT_CONTENT_FAIL";

export const ADULT_CONTENT_CONFIRM_REQUEST = "ADULT_CONTENT_CONFIRM_REQUEST";
export const ADULT_CONTENT_CONFIRM_SUCCESS = "ADULT_CONTENT_CONFIRM_SUCCESS";
export const ADULT_CONTENT_CONFIRM_FAIL = "ADULT_CONTENT_CONFIRM_FAIL";

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";


export const USER_PROFILE_UPDATE_REQUEST = "USER_PROFILE_UPDATE_REQUEST";
export const USER_PROFILE_UPDATE_SUCCESS = "USER_PROFILE_UPDATE_SUCCESS";
export const USER_PROFILE_UPDATE_FAIL = "USER_PROFILE_UPDATE_FAIL";
export const USER_PROFILE_UPDATE_RESET = "USER_PROFILE_UPDATE_RESET";


export const USER_PROFILE_BY_ID_REQUEST = "USER_PROFILE_BY_ID_REQUEST"
export const USER_PROFILE_BY_ID_SUCCESS = "USER_PROFILE_BY_ID_SUCCESS"
export const USER_PROFILE_BY_ID_FAIL = "USER_PROFILE_BY_ID_FAIL"

export const USER_CHANGE_PASSWORD_REQUEST = "USER_CHANGE_PASSWORD_REQUEST"
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS"
export const USER_CHANGE_PASSWORD_FAIL = "USER_CHANGE_PASSWORD_FAIL"
export const USER_CHANGE_PASSWORD_RESET= "USER_CHANGE_PASSWORD_RESET"
