import React from "react";
import List from "@shared/atoms/List/index";

import "./index.less";

const SubscribedChannelList = ({ isPrototype = false, children }) => {
  return (
    <List
      className={`subscribed-channels-list ${
        isPrototype ? "subscribed-channels-list-prototype" : ""
      } `}
    >
      {children}
    </List>
  );
};

export default SubscribedChannelList;
