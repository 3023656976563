import React from "react";
import MainLayout from "../../layout/MainLayout";

import DesktopPrototype from "@components/organisms/DesktopPrototype";
import HomeContentPrototype from "@components/organisms/HomeContentPrototype";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { selectHomeContent } from "@redux/reducers/homeReducers";

import "./index.less";

export const Home = () => {
  const location = useLocation();

  const { homeData } = useSelector(selectHomeContent);

  const hasNav = location.pathname === "/";

  return (
    <>
      {homeData?.home && (
        <>
          {isMobile ? (
            <MainLayout fluid={true} className="mobile-layout">
              <HomeContentPrototype />
            </MainLayout>
          ) : (
            <DesktopPrototype hasNavigation={hasNav}>
              <HomeContentPrototype />
            </DesktopPrototype>
          )}
        </>
      )}
    </>
  );
};
