import React from "react";
import "./index.less";

const Share = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            className="share-icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g
                id="Group_5673"
                data-name="Group 5673"
                transform="translate(-4426 35)"
            >
                <rect
                    id="Rectangle_3863"
                    data-name="Rectangle 3863"
                    width="24"
                    height="24"
                    transform="translate(4426 -35)"
                    fill="none"
                    opacity="0"
                />
                <g id="ic_share_24px" transform="translate(4426 -35)">
                    <path
                        id="Path_4261"
                        data-name="Path 4261"
                        d="M18,16.137a2.906,2.906,0,0,0-1.96.773L8.91,12.743a3.3,3.3,0,0,0,.09-.7,3.3,3.3,0,0,0-.09-.7l7.05-4.127a3.008,3.008,0,1,0-.96-2.2,3.3,3.3,0,0,0,.09.7L8.04,9.841a3.012,3.012,0,1,0,0,4.4l7.12,4.177a2.843,2.843,0,0,0-.08.653A2.92,2.92,0,1,0,18,16.137Z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Share;
