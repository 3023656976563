import {
  SEARCH_UNAUTHORIZED_REQUEST,
  SEARCH_UNAUTHORIZED_SUCCESS,
  SEARCH_UNAUTHORIZED_FAIL,
  SEARCH_AUTHORIZED_REQUEST,
  SEARCH_AUTHORIZED_SUCCESS,
  SEARCH_AUTHORIZED_FAIL,
  SEARCH_HISTORY_REQUEST,
  SEARCH_HISTORY_SUCCESS,
  SEARCH_HISTORY_FAIL,
  CREATE_SEARCH_HISTORY_REQUEST,
  CREATE_SEARCH_HISTORY_SUCCESS,
  CREATE_SEARCH_HISTORY_FAIL,
  TOGGLE_FOLLOW_SEARCH_HISTORY_REQUEST,
  TOGGLE_FOLLOW_SEARCH_HISTORY_SUCCESS,
  TOGGLE_FOLLOW_SEARCH_HISTORY_FAIL,
  SEARCH_UNAUTHORIZED_RESET,
  SEARCH_AUTHORIZED_RESET,
  SEARCH_DELETE_REQUEST,
  SEARCH_DELETE_SUCCESS,
} from "../constants/searchConstants";

export const searchUnauthorizedReducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_UNAUTHORIZED_REQUEST:
      return { loading: true };
    case SEARCH_UNAUTHORIZED_SUCCESS:
      return { loading: false, data: action.payload };
    case SEARCH_UNAUTHORIZED_FAIL:
      return { loading: false, error: action.payload };
    case SEARCH_UNAUTHORIZED_RESET:
      return {};
    default:
      return state;
  }
};

export const searchAuthorizedReducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_AUTHORIZED_REQUEST:
      return { loading: true };
    case SEARCH_AUTHORIZED_SUCCESS:
      return { loading: false, data: action.payload };
    case SEARCH_AUTHORIZED_FAIL:
      return { loading: false, error: action.payload };
    case SEARCH_AUTHORIZED_RESET:
      return {};
    default:
      return state;
  }
};

export const searchHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_HISTORY_REQUEST:
      return { loading: true };
    case SEARCH_HISTORY_SUCCESS:
      return { loading: false, data: action.payload };
    case SEARCH_HISTORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createSearchHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_SEARCH_HISTORY_REQUEST:
      return { loading: true };
    case CREATE_SEARCH_HISTORY_SUCCESS:
      return { loading: false, data: action.payload };
    case CREATE_SEARCH_HISTORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const toggleFollowSearchHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_FOLLOW_SEARCH_HISTORY_REQUEST:
      return { loading: true };
    case TOGGLE_FOLLOW_SEARCH_HISTORY_SUCCESS:
      return { loading: false, data: action.payload };
    case TOGGLE_FOLLOW_SEARCH_HISTORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteSearchHistoryItemReducer = (state={}, action) => {
  switch(action.type) {
    case SEARCH_DELETE_REQUEST:
      return { loading: true }
    case SEARCH_DELETE_SUCCESS:
      return {
        loading: false,
        data: action.payload
      }
    case SEARCH_AUTHORIZED_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state;
  }
}