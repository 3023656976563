import React from "react";
import Form from "@shared/atoms/Form/index";
import Input from "@shared/atoms/Input/index";
import { useTranslation } from "react-i18next";

import "./index.less";

const UpdatePasswordFormPrototype = ({ oldPassword, newPassword, repeatNewPassword, setOldPassword, setNewPassword, setRepeatNewPassword }) => {
  const { t } = useTranslation();

  return (
    <Form className="form-prototype update-password-form-prototype">
      <Form.Item>
        <Input onChange={e => { setOldPassword(e.target.value) }} type="password" value={oldPassword} placeholder={`${t("old_password")}`} />
      </Form.Item>
      <Form.Item>
        <Input onChange={e => { setNewPassword(e.target.value) }} type="password" value={newPassword} placeholder={t("new_password")} />
      </Form.Item>
      <Form.Item>
        <Input onChange={e => { setRepeatNewPassword(e.target.value) }} type="password" value={repeatNewPassword} placeholder={t("repeat_password")} />
      </Form.Item>
    </Form>
  );
};

export default UpdatePasswordFormPrototype;
