import React, { useContext } from "react";
import Modal from "@shared/atoms/Modal";
import Button from "@shared/atoms/Button";
import { Paragraph } from "@shared/atoms/Typography/index";
import { useTranslation } from "react-i18next";
import { Context } from "@context/context";
import { useDispatch, useSelector } from "react-redux";
import { deleteGroup } from "@redux/actions/groupActions";
import { getPlaylists } from "@redux/actions/playlistsActions";

import "./index.less";

const DeleteGroupModalPrototype = () => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { loading: groupDeleteLoading } = useSelector(state => state.groupDelete)

  const { setShowDeleteGroupModalPrototype, showDeleteGroupModalPrototype, deletableGroupInfo } = useContext(Context)

  const deleteHandler = () => {
    Promise.resolve(dispatch(deleteGroup(deletableGroupInfo.id)))
      .then(() => {
        setShowDeleteGroupModalPrototype(false)
        dispatch(getPlaylists())
      })

  }
  return (
    <Modal
      className="delete-group-modal-prototype modal-basic-prototype"
      visible={showDeleteGroupModalPrototype}
      onCancel={() => {
        setShowDeleteGroupModalPrototype(false);
      }}
      closable={true}
      footer={[
        <footer className="footer">
          <Button
            key="delete"
            onClick={deleteHandler}
            className="response-buttons-prototype blue"
            disabled={groupDeleteLoading}
          >
            {t("delete")}
          </Button>
          <Button
            key="cancel"
            onClick={() => setShowDeleteGroupModalPrototype(false)}
            className="response-buttons-prototype gray"
          >
            {t("cancel")}
          </Button>
        </footer>,
      ]}
    >
      <Paragraph className="para"> 
        {t("are_you_sure_you_want_to_delete_this_group", 
          (deletableGroupInfo?.name || deletableGroupInfo?.channelsStr) &&
          { groupName: deletableGroupInfo?.name, 
            channelList: deletableGroupInfo.channelsStr 
        })}?
      </Paragraph>
    </Modal>
  )
}

export default DeleteGroupModalPrototype