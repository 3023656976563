import React, { useEffect, useState } from "react";
import AuthLayout from "../../layout/AuthLayout/index";
import { Row, Col } from "antd";
import AuthHeading from "@components/organisms/AuthHeading";
import AuthForm from "@components/organisms/forms/AuthForm/index";
import AuthFormMobile from "@components/organisms/forms/AuthForm/mobile";
import AuthShowcase from "@components/organisms/AuthShowcase";
// import SocialLogin from "@components/organisms/SocialLogin";
import { isMobile } from "react-device-detect";
import AuthHeaderMobile from "../../components/shared/molecules/AuthHeaderMobile";
import { localStorageService } from "../../services/localStorage.service";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { logOut } from "../../../redux/actions/userActions";
import { useDispatch } from "react-redux";
import MobileResetPasswordPrototype from "../../components/organisms/MobileResetPasswordPrototype";
import { keyExists } from "../../../utils/keyExists";

import "./index.less";

export const Login = () => {

  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const [showMobileReset, setShowMobileReset] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (localStorageService().get("access_token")) {
      dispatch(logOut());
      localStorageService().remove("access_token");
      localStorageService().remove("canceled_enter_fields_modal");
      localStorageService().set("redirect_to_following", true);

      history.push("/login");
    }
  }, [history, dispatch]);

  useEffect(() => {
    if (isMobile && location.search) {
      const [type] = location.search.split("=");
      if (type === "?verify_url") {
        history.push(`/settings${location.search}`);
      }
    }
  }, [location, history]);

  useEffect(() => {
    if (keyExists(["email", "token"], params)) {
      setShowMobileReset(true);
    }
  }, [params]);

  return (
    <>
      {!isMobile ? (
        <AuthLayout>
          <Row className="auth-row">
            <Col className="auth-light-col auth-gradient-bg-1" span={14}>
              <AuthHeading />
              <AuthForm />
              {/* <SocialLogin
                                responseGoogle={responseGoogle}
                                responseFacebook={responseFacebook}
                            /> */}
            </Col>
            <Col className="auth-dark-col" span={26}>
              <AuthShowcase />
            </Col>
          </Row>
        </AuthLayout>
      ) : (
        // <AuthLayout className="auth-mobile-layout">
        <AuthLayout className="auth-mobile-layout">
          {showMobileReset ? (
            <MobileResetPasswordPrototype />
          ) : (
            <>
              <AuthHeaderMobile headingText2={true} />
              <AuthFormMobile />
            </>
          )}
        </AuthLayout>
      )}
    </>
  );
};
