import React from "react";
import "./index.less";

const NavItem = ({ className = "nav-item", onClick, children }) => {
  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};

export default NavItem;
