import React from 'react';
import AntdTab from "antd/lib/tabs/index"

export const { TabPane } = AntdTab

const Tab = ({ children, ...rest }) => {
  return <AntdTab {...rest}>
    {children}
  </AntdTab>;
};

export default Tab;
