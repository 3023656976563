import React from "react";
import Modal from "@shared/atoms/Modal/index";
import Button from "@shared/atoms/Button/index";
import { Paragraph } from "@shared/atoms/Typography";
import "./index.less";
import { useTranslation } from "react-i18next";

const TermsModal = ({
    title = "Terms and conditions",
    closeModal,
    additionalClasses,
    ...rest
}) => {
    const { t } = useTranslation();
    return (
        <Modal
            className={`terms-modal ${
                additionalClasses ? additionalClasses : ""
            }`}
            closable={false}
            title={title}
            {...rest}
            footer={[
                <Button
                    onClick={closeModal}
                    className="light-btn"
                    type="primary"
                    key="close"
                >
                    {t("close")}
                </Button>,
            ]}
        >
            <Paragraph>
                {t("terms_modal_1")}
                <a
                    href="https://www.choice.be/"
                    target="_blank"
                    rel="noreferrer"
                >
                    {" "}
                    www.choice.be
                </a>{" "}
                {t("terms_modal_2")}{" "}
                <a
                    href="https://www.choice.be/"
                    target="_blank"
                    rel="noreferrer"
                >
                    {" "}
                    www.choice.be
                </a>{" "}
                {t("terms_modal_3")}{" "}
                <a
                    href="https://www.choice.be/"
                    target="_blank"
                    rel="noreferrer"
                >
                    {" "}
                    www.choice.be{" "}
                </a>
                {t("terms_modal_4")}{" "}
                <a
                    href="https://www.choice.be/"
                    target="_blank"
                    rel="noreferrer"
                >
                    {" "}
                    www.choice.be
                </a>{" "}
                {t("terms_modal_5")}
                <br />
                <br />
                <b>{t("eligibility")}</b>
                <br />
                {t("terms_modal_6")}
                <br />
                <br />
                <b>{t("term_and_termination")}</b>
                <br />
                {t("terms_modal_7")}
                <br />
                <br />
                <b>{t("fees")}</b>
                <br />
                {t("terms_modal_8")}
                <br />
                <br />
                <b>{t("password_creation")}</b>
                <br />
                {t("terms_modal_9")}
                <br />
                <br />
                <b>{t("non_commercial_use")} </b>
                <br />
                {t("terms_modal_10")}{" "}
                <a
                    href="https://www.choice.be/"
                    target="_blank"
                    rel="noreferrer"
                >
                    {" "}
                    www.choice.be
                </a>{" "}
                {t("terms_modal_11")}
                <br />
                <br />
                <b>{t("proprietary_rights_to_content")}</b>
                <br />
                {t("terms_modal_12")}
                <br />
                <br />
                <b>{t("privacy_policy_and_user_data")}</b>
                <br />
                {t("terms_modal_13")}
                <br />
                <br />
                <b>{t("commercial_rights_to_content")}</b>
                <br />
                {t("terms_modal_14")}
                <br />
                <br />
                <b>{t("liability_disclaimer")}.</b>
                <br />
                {t("terms_modal_15")}
                <a
                    href="https://www.choice.be/"
                    target="_blank"
                    rel="noreferrer"
                >
                    {" "}
                    www.choice.be
                </a>{" "}
                {t("terms_modal_16")}
                <br />
                <br />
                <b>{t("exclusion_of_warranty")}.</b>
                <br />
                {t("terms_modal_17")}
                <br />
                <br />
                <b>{t("disputes")}</b>
                <br />
                {t("terms_modal_18")}
                <br />
                <br />
                <b>{t("misuse")}</b>
                <br />
                {t("terms_modal_19")}
                <br />
                <br />
                <b>{t("indemnity")}.</b>
                <br />
                {t("terms_modal_20")}
                <br />
                <br />
                <b>{t("other")}.</b>
                <br />
                {t("terms_modal_21")}
            </Paragraph>
        </Modal>
    );
};

export default TermsModal;
