import React from 'react'
import AntdAlert from 'antd/lib/alert/index';
import './index.less'

const Alert = ({closable=true, ...rest}) => {
  return (
    <AntdAlert {...rest} closable={closable} showIcon/>
  )
}

export default Alert
