import { Context } from "@context/context";
import { deletePlaylist, getPlaylistById, updatePlaylist } from "@redux/actions/playlistsActions";
import { METHODS } from "const/apiMethods";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export const usePlaylist = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        setShowShareModalPrototype,
    } = useContext(Context)

    const updatePlaylistFun = ({ playlistId, ...rest }) => {
        const payload = {
            _method: METHODS.PATCH,
            ...rest,
        }

        Promise.resolve(
            dispatch(updatePlaylist(payload, playlistId))
        ).then(() => {
            dispatch(getPlaylistById(playlistId))
        })
    };

    const deletePlaylistFn = (playlistId) => {
        dispatch(deletePlaylist(playlistId))
    }

    const sharePlaylist = (id) => {
        setShowShareModalPrototype(true);
        history.push(`playlists?playlist=${id}`);
    };
    
    return {
        sharePlaylist,
        updatePlaylist: updatePlaylistFun,
        deletePlaylist: deletePlaylistFn
    }
}