import { useContext, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "@context/context";
import { Link } from "react-router-dom";
import { useAccountMode } from "hooks";
import List from "@shared/atoms/List/index";
import HomePositionPrototype from "@components/shared/molecules/HomePositionPrototype";
import StatisticsIconPrototype from "@components/shared/atoms/icons/StatisticsPrototype";
import choiceLogo from "@assets/icons/choice-icon-main.png";
import { VIEWS_PROTOTYPE } from "@src/const/viewsPrototype";

import Image from "@components/shared/atoms/Image";
import SubBarItemPrototype from "@components/shared/molecules/listItems/SubBarItemPrototype";
import { useSelector } from "react-redux";
import TeamIcon from "@assets/images/team.webp";
import defaultIcon from "@assets/icons/default-playlist-icon.svg";
import userImage from "@assets/images/user.png";
import StaticSubbarItem from "@components/shared/molecules/listItems/SubBarItemPrototype/StaticSubbarItem";
import UsersIconFillPrototype from "@components/shared/atoms/icons/UsersIconFillPrototype";
import { ReactComponent as WhiteTeamsIcon } from '@assets/icons/white-users-svg.svg'

import "./index.less";

const ProfileSubbarPrototype = () => {
  const [isTeamsOpened, toggleTeams] = useReducer((state) => !state, false);
  const history = useHistory();
  const { data: teams } = useSelector((state) => state.teams);
  const { isTeamMode, setOwnMode, setTeamMode } = useAccountMode();

  const {
    setShowSubBarPrototype,
    setShowCreateTeamModal,
    setCurrentViewPrototype,
    setShowTermsImagesPrototype,
  } = useContext(Context);

  const handleChoiceIconClick = () => {
    setShowTermsImagesPrototype(true);

    // history.push({ search: 'view=home' })
  };

  const handleHomeIconClick = () => {
    // setShowTermsImagesPrototype(true);
    history.push("/settings");
    setCurrentViewPrototype(VIEWS_PROTOTYPE.settings);
  };

  const handleTeamClick = ({ id }) => {
    history.push(`/teams?teamId=${id}`);
    setTeamMode(id);
  };

  return (
    <List className="profile-list">
      <HomePositionPrototype onClick={() => handleHomeIconClick()} />

      <StaticSubbarItem
        title="Switch to me"
        svgIcon={UsersIconFillPrototype}
        isVisible={isTeamMode}
        itemClick={setOwnMode}
      />

      <Link
        to={"?view=home"}
        className="choice-icon"
        onClick={handleChoiceIconClick}
      >
        <Image src={choiceLogo} className="profile-choice-home" />
      </Link>

      <Link
        to={"/statistics"}
        onClick={() => setShowSubBarPrototype(false)}
        className="statistics-icon"
      >
        <StatisticsIconPrototype />
      </Link>

      <SubBarItemPrototype
        key={"teams"}
        openable={true}
        content={"teams"}
        image={TeamIcon}
        defaultOpenableItemImage={defaultIcon}
        openableData={teams?.data.teams}
        openableItemClick={handleTeamClick}
        itemClick={toggleTeams}
        isTeamOpenable={true}
        isTeam={true}
        opened={isTeamsOpened}
        staticChildItems={
          <>
            <StaticSubbarItem
              title="add new"
              svgIcon={WhiteTeamsIcon}
              svgContainerStyles={{
                padding: "5px"
              }}
              itemClick={() => setShowCreateTeamModal(true)}
            />
          </>
        }
      />
    </List>
  );
};

export default ProfileSubbarPrototype;
