import React from "react";
import List from "@shared/atoms/List";

import DraggableList from "@components/organisms/lists/DraggableList";
import { DroppableTypes } from "@context/dndContextPrototype";
import Image from "@components/shared/atoms/Image";
import { Paragraph } from "@components/shared/atoms/Typography";
import "./index.less";

const ArrangeOpenableMode = ({ content, droppableId, defaultIcon }) => {
  return (
    <div className="arrange-modee">
      <DraggableList
        listItems={content}
        droppableId={droppableId}
        direction="horizontal"
        style={{ display: "flex" }}
      >
        {(item, provided) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div>
                <List.Item
                  className={true ? "subbar-item-context" : `subbar-item`}
                >
                  <Image
                    src={item.logoId || item.icon || defaultIcon}
                    alt={"subbar item "}
                  />
                  <Paragraph className="subbar-item-para">
                    {item.name}
                  </Paragraph>
                </List.Item>
              </div>
            </div>
          );
        }}
      </DraggableList>
    </div>
  );
};

export default ArrangeOpenableMode;
