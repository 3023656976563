import React from "react";
import "./index.less";

const TagClose = () => {
    return (
        <svg
            className="tag-close-icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
        >
            <rect className="a" width="18" height="18" />
            <path
                className="b"
                d="M7,14A7,7,0,0,1,2.048,2.048a7,7,0,0,1,9.9,9.9A6.956,6.956,0,0,1,7,14ZM7,7.987H7L9.513,10.5l.987-.987L7.987,7,10.5,4.487,9.513,3.5,7,6.013,4.487,3.5,3.5,4.487,6.013,7,3.5,9.513l.987.987L7,7.988Z"
                transform="translate(2 2)"
            />
        </svg>
    );
};

export default TagClose;
