import React from "react";
import List from "@shared/atoms/List/index";

import "./index.less";

const FavoritesList = ({ className = "", children }, ref) => {
    return (
        <List className={`favorites-list ${className}`} ref={ref}>
            {children}
        </List>
    );
};

const forwardedFavoritesList = React.forwardRef(FavoritesList);

export default forwardedFavoritesList;
