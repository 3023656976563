import React, { useContext } from "react";
import Select from "antd/lib/select";
import i18n from "../../../../i18n/i18n";
import { localStorageService } from "../../../../services/localStorage.service";
import { Context } from "@context/context";

import "./index.less";

const { Option } = Select;

const LanguagePickerPrototype = () => {
  const { setLocaleLang } = useContext(Context);

  const switchLang = (lang) => {
    i18n.changeLanguage(lang);
    localStorageService().set("lang", lang);
    setLocaleLang(localStorageService().get("lang"));
    setLocaleLang(lang);
    let urlArr = window.location.pathname.split("/");
    urlArr[1] = lang;
    window.location.replace(urlArr.join("/"));
  };

  const defaultLangValue = () => {
    return !localStorageService().get("lang") ? "English" : localStorageService().get("lang");
  }

  return (
    <div className="language-picker-prototype">
      <Select onChange={(lang) => switchLang(lang)} defaultValue={defaultLangValue}>
        <Option value="en">
          {/* <Image className="flag-icon" src={ukFlag} alt="en" /> */}
          <span className="lang-title">English</span>
        </Option>
        <Option value="nl">
          {/* <Image className="flag-icon" src={nlFlag} alt="nl" /> */}
          <span className="lang-title">Nederlands</span>
        </Option>
      </Select>
    </div>
  );
};

export default LanguagePickerPrototype;
