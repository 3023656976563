import React from 'react'
import AntdCard from 'antd/lib/card/index';

export const {Meta:CardMeta} = AntdCard
export const {Grid:CardGrid} = AntdCard

export const Card = ({children, ...rest}) => {
    return (
        <AntdCard {...rest}>
            {children}
        </AntdCard>
    )
}
