// @components
import Image from "@components/shared/atoms/Image";
import List from "@components/shared/atoms/List";
import { Paragraph } from "@components/shared/atoms/Typography";
import RestrictedPlaylistItem from "@components/shared/atoms/icons/RestrictedPlaylistItem";
import PublicPlaylistItem from "@components/shared/atoms/icons/PublicPlaylistItem";

// @utils
import { shortenString } from "@utils/shortenString";
import classNames from "classnames";

// @constants

import "./index.less";

const SubbarItem = ({
  item,
  image,
  itemClick,
  contextHandler,
  closeContextMenu,
  isActive,
  activeItemRef,
  hasStatus = true,
}) => {
  const itemClass = classNames("subbar-item-container", {
    active: isActive,
  });

  return (
    <div className={itemClass} ref={activeItemRef} onWheel={closeContextMenu}>
      <List.Item
        className="subbar-item"
        onClick={itemClick}
        {...contextHandler}
      >
        <div data-id={item?.id} className="subbar-content-container">
          {hasStatus && <SubbarStatusIcon status={item?.status} />}
          <Image className="subbar-item-img" src={image} alt="subbar item" />
          <Paragraph className="subbar-item-para">
            {shortenString(item?.name, 15)}
          </Paragraph>
        </div>
      </List.Item>
    </div>
  );
};

export default SubbarItem;

const statuses = {
  Private: <RestrictedPlaylistItem />,
  Public: <PublicPlaylistItem />,
};

const SubbarStatusIcon = ({ status }) => {
  if (!status) return <></>;

  const statusIcon = statuses[status];

  if (!statusIcon) return <></>;

  return <div className="permission-playlist">{statusIcon}</div>;
};
