export const STATISTICS_REQUEST = "STATISTICS_REQUEST";
export const STATISTICS_SUCCESS = "STATISTICS_SUCCESS";
export const STATISTICS_FAIL = "STATISTICS_FAIL";

export const BROADCAST_STATISTICS_REQUEST = "BROADCAST_STATISTICS_REQUEST";
export const BROADCAST_STATISTICS_SUCCESS = "BROADCAST_STATISTICS_SUCCESS";
export const BROADCAST_STATISTICS_FAIL = "BROADCAST_STATISTICS_FAIL";


export const PLAYLIST_STATISTICS_REQUEST = "PLAYLIST_STATISTICS_REQUEST"
export const PLAYLIST_STATISTICS_SUCCESS = "PLAYLIST_STATISTICS_SUCCESS"
export const PLAYLIST_STATISTICS_FAIL = "PLAYLIST_STATISTICS_FAIL"

export const EXPORT_PLAYLIST_STATISTICS_REQUEST = "EXPORT_PLAYLIST_STATISTICS_REQUEST"
export const EXPORT_PLAYLIST_STATISTICS_SUCCESS = "EXPORT_PLAYLIST_STATISTICS_SUCCESS"
export const EXPORT_PLAYLIST_STATISTICS_FAIL = "EXPORT_PLAYLIST_STATISTICS_FAIL"