import React, { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../../layout/MainLayout";
import { getUserProfile } from "../../../redux/actions/profileActions";
import { Context } from "../../../context/context";
import RequiredFieldsModal from "../../components/organisms/modals/RequiredFieldsModal";
import { localStorageService } from "../../services/localStorage.service";
import { useHistory } from "react-router-dom";
import EnterProfileInfoModal from "../../components/organisms/modals/EnterProfileInfoModal";
import MobileChannelsPrototype from "../../components/organisms/MobileChannelsPrototype";
import DesktopPrototype from "@components/organisms/DesktopPrototype";
import { isMobile } from "react-device-detect";



export const Channels = () => {
    const [showProfileModal, setShowProfileModal] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    const { profile } = useSelector((state) => state.userProfile);

    const { setShowRequiredFieldsModal, setShowEditProfile } =
        useContext(Context);

    useEffect(() => {
        dispatch(getUserProfile());
    }, [dispatch]);

    useEffect(() => {
        if (
            !profile &&
            !localStorageService().get("access_token") &&
            JSON.parse(localStorageService().get("redirect_to_following"))
        ) {
            // history.push("/following");
            // localStorageService().set("redirect_to_following", false);
        }
        if (profile && profile.data) {
            if (
                !profile?.data?.country_id &&
                !localStorageService().get("canceled_enter_fields_modal")
            ) {
                setShowProfileModal(true);
            }
        }

        if (profile?.data?.nickname) {
            if (!profile.data.nickname) {
                setShowRequiredFieldsModal(true);
            }
        }
    }, [profile, setShowRequiredFieldsModal, history, dispatch]);

    const cancelProfileModal = () => {
        setShowProfileModal(false);
        localStorageService().set("canceled_enter_fields_modal", true);
    };

    const redirectToProfile = () => {
        if (!isMobile) {
            history.push("/settings");
        } else {
            history.push("/settings?view=editProfile");
        }

        setShowEditProfile(true);
        setShowProfileModal(false);
        localStorageService().set("canceled_enter_fields_modal", true);
    };

    return (
        <>
            <RequiredFieldsModal />
            <EnterProfileInfoModal
                visible={showProfileModal}
                onCancel={cancelProfileModal}
                onOk={redirectToProfile}
            />
            {isMobile && (
                <MainLayout className="mobile-layout">
                    {/* <MobileChannels /> */}
                    <MobileChannelsPrototype />
                </MainLayout>
            )}
            {!isMobile && (
                // <MainLayout
                //     firstDimension={<ChannelsFirst />}
                //     secondDimension={<ChannelsSecond />}
                //     thirdDimension={<ChannelsThird />}
                //     className="channels-page"
                // ></MainLayout>
                <DesktopPrototype>
                    <MobileChannelsPrototype />
                </DesktopPrototype>
            )}
        </>
    );
};
