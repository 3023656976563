import Image from "@components/shared/atoms/Image";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import HomePositionIcon from "@assets/icons/home-position-icon.svg";
import { Context } from "@context/context";
import addImg from "@assets/icons/insert-icon.svg";
import ContextMenuPrototype, {
  ContextMenuOption,
} from "../ContextMenuPrototype";
import { selectPlaylists } from "@redux/reducers/playlistsReducer";
import { useDispatch, useSelector } from "react-redux";
import { copyNavbarPlaylist } from "@redux/actions/playlistsActions";
import copyImg from "@assets/icons/copy.svg";
import { COPY_ITEM } from "const/playlists";

import "./index.less";
import usePathName from "hooks/usePathName";
import { PAGES } from "const/pages";
import { useTier } from "hooks";
import { localStorageService } from "@modules/services/localStorage.service";
import { LOCALSTORAGE } from "const";

const HomePositionPrototype = ({
  className = "",
  onClick,
  openContextMenu,
  isVisible,
  closeContextMenu,
  xPosContextMenu,
  yPosContextMenu,
  withCloseContextMenu,
  insertPlaylistHandler,
  contextMenuClickWithDebounce,
  onTouchEnd,
}) => {
  const homeIconRef = useRef(null);
  const { scrollSubbarRef, copiedPlaylist, setCopiedPlaylist } =
    useContext(Context);

  const page = usePathName();

  const dispatch = useDispatch();
  const { playlists } = useSelector(selectPlaylists);
  const { statuses } = useSelector((state) => state.playlistStatuses);

  const { createTireCopy } = useTier();

  const createPlailistCopy = (copiedItem, is_left) => {
    const leftPlaylistLength = playlists.other.filter(
      ({ is_left }) => is_left
    ).length;

    const payloadData = {
      to: leftPlaylistLength + playlists.archive.length + 1,
      playlistId: copiedItem.id,
      is_left,
    };

    if (!copiedItem.status) {
      payloadData["status_id"] = statuses.find(
        ({ name }) => name === "Private"
      )?.id;
    }

    dispatch(copyNavbarPlaylist(payloadData));
  };

  const handlePaste = (direction) => () => {
    const is_left = direction === "Left";
    const { copiedItem, type } = copiedPlaylist;

    switch (type) {
      case COPY_ITEM.TV_CHANNEL:
        createTireCopy(copiedItem, is_left);
        break;
      case COPY_ITEM.MY_PLAYLIST:
        createPlailistCopy(copiedItem, is_left);
        break;
      case COPY_ITEM.DEFAULT_PLAYLIST:
        createPlailistCopy(copiedItem, is_left);
        break;

      default:
        break;
    }

    setCopiedPlaylist(null);
  };

  const isNotSavedPlaylistOnThisPage = () => {
    return (
      (page === PAGES.myPlaylists &&
        !localStorageService().get(LOCALSTORAGE.HEART_PLAYLIST_ID)) ||
      (page === PAGES.playlists &&
        !localStorageService().get(LOCALSTORAGE.GLOBAL_PLAYLIST_ID))
    );
  };

  const locateHomePosition = useCallback(() => {
    const homePositionIcon = homeIconRef.current;
    const scrollable = scrollSubbarRef.current;

    if (
      homeIconRef &&
      homePositionIcon &&
      (page === PAGES.myPlaylists ||
        page === PAGES.playlists ||
        page === PAGES.search)
    ) {
      if (!homePositionIcon.classList.contains("playlist-card-home-position")) {
        homePositionIcon.style.position = "sticky";
        homePositionIcon.style.marginLeft = "0";
      } else {
        homePositionIcon.style.position = "absolute";
        homePositionIcon.style.margin = "0.4rem 0.2rem";
      }

      const homePositionNextSibling = homePositionIcon?.nextSibling;
      const siblingWidth =
        homePositionNextSibling.getBoundingClientRect().width;

      if (isNotSavedPlaylistOnThisPage()) {
        homePositionNextSibling?.scrollIntoView({ inline: "center" });
      }

      if (page === PAGES.search) {
        scrollable?.container?.current?.scrollBy(-0.54 * siblingWidth, 0);
      } else {
        scrollable?.container?.current?.scrollBy(-1.5 * siblingWidth, 0);
      }
    }

    if (homeIconRef && page === PAGES.tiers) {
      const scrollableRef = scrollable?.container?.current;
      homePositionIcon.style.position = "sticky";
      if (scrollableRef) {
        scrollableRef.scrollLeft = 0;
      }
    }

    // if (homeIconRef && page === PAGES.search) {
    //   // homePositionIcon.style.marginLeft = "2rem"
    //   homePositionIcon.style.position = "sticky";

    //   const homePositionNextSibling = homePositionIcon?.nextSibling;
    //   const siblingWidth =
    //     homePositionNextSibling.getBoundingClientRect().width;

    //   homePositionNextSibling?.scrollIntoView({ inline: "center" });

    //   scrollable?.container?.current?.scrollBy(-1.5 * siblingWidth, 0);

    //   console.log(siblingWidth)
    //   console.log(scrollable);
    // }
  }, [scrollSubbarRef, page]);

  useEffect(() => {
    const homePositionIcon = homeIconRef.current;
    locateHomePosition();
    return () => {
      if (homePositionIcon) homePositionIcon.style.position = "relative";
    };
  }, [locateHomePosition, homeIconRef]);

  return (
    <div
      onContextMenu={openContextMenu}
      onTouchStart={contextMenuClickWithDebounce}
      onTouchEnd={onTouchEnd}
      className={`home-position-prototype ${className}`}
      ref={homeIconRef}
      onClick={(e) => {
        if (onClick) onClick(e);
        locateHomePosition();
      }}
    >
      {page === PAGES.myPlaylists && withCloseContextMenu && (
        <ContextMenuPrototype
          isVisible={isVisible}
          handleClose={closeContextMenu}
          xPos={xPosContextMenu}
          yPos={yPosContextMenu}
        >
          <ContextMenuOption
            label="New"
            icon={addImg}
            onClick={withCloseContextMenu(insertPlaylistHandler)}
          />
          {copiedPlaylist && (
            <>
              <ContextMenuOption
                onClick={withCloseContextMenu(handlePaste("Left"))}
                icon={copyImg}
                label="Paste Left"
              />
              {copiedPlaylist.type !== COPY_ITEM.TV_CHANNEL && (
                <ContextMenuOption
                  onClick={withCloseContextMenu(handlePaste("Right"))}
                  icon={copyImg}
                  label="Paste Right"
                />
              )}
            </>
          )}
        </ContextMenuPrototype>
      )}

      <Image src={HomePositionIcon} alt="home" />
    </div>
  );
};

export default HomePositionPrototype;
