import "./index.less";

const Pen = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            className="pen-icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g transform="translate(-4394 6)">
                <rect
                    className="a"
                    width="24"
                    height="24"
                    transform="translate(4394 -6)"
                />
                <g transform="translate(4393 -6.995)">
                    <path
                        className="b"
                        d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                        transform="translate(0 0)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Pen;
