import React from "react";
import "./index.less";

const CheckMark = () => {
    return (
        <svg
            className="checkmark-icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g transform="translate(-404 -745)">
                <rect
                    className="a"
                    width="24"
                    height="24"
                    transform="translate(404 745)"
                />
                <g className="b" transform="translate(405.501 746.51)">
                    <path
                        className="c"
                        d="M10.495,20.49A10,10,0,1,1,14.57,1.359a1.3,1.3,0,1,1-1.062,2.382,7.387,7.387,0,1,0,4.383,6.753V9.7a1.3,1.3,0,0,1,2.609,0v.8a10.014,10.014,0,0,1-10,10Z"
                        transform="translate(0)"
                    />
                    <path
                        className="c"
                        d="M11.412,13.809a1.3,1.3,0,0,1-.922-.382L7.882,10.819A1.3,1.3,0,1,1,9.726,8.975l1.685,1.685,7.77-7.778a1.3,1.3,0,0,1,1.845,1.843l-8.692,8.7a1.3,1.3,0,0,1-.922.382Z"
                        transform="translate(-0.909 -0.263)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default CheckMark;
