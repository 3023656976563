import "./index.less";

const MoreButtons = ({
  goToTitle,
  goTo,
  moreInfoTitle,
  moreInfoClickHandler,
}) => {
  function handleClick() {
    window.open(goTo);
  }

  return (
    <div className="more-buttons">
      {goToTitle && (
        <button onClick={handleClick} className="go-to">
          {goToTitle}
        </button>
      )}
      {moreInfoTitle && (
        <button className="more-info" onClick={moreInfoClickHandler}>
          {moreInfoTitle}
        </button>
      )}
    </div>
  );
};

export default MoreButtons;
