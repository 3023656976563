import {
  COPIED_TIRES_REQUEST,
  COPIED_TIRES_SUCCESS,
  COPIED_TIRES_FAIL,
  ARRAGNE_SHORTCUTS,
} from "@redux/constants/shortcutConstants";
import produce from "immer";

export const copiedTierReducer = produce((state = {}, action) => {
  switch (action.type) {
    case COPIED_TIRES_REQUEST:
      return { loading: true };
    case COPIED_TIRES_SUCCESS:
      return {
        loading: false,
        copiedTiers: action.payload,
      };
    case COPIED_TIRES_FAIL:
      return { loading: false, error: action.payload };
    case ARRAGNE_SHORTCUTS:
      const targetShortcut = state.copiedTiers[action.payload.from];
      state.copiedTiers.splice(action.payload.from, 1);
      state.copiedTiers.splice(action.payload.to, 0, targetShortcut);
      break;
    default:
      return state;
  }
});
