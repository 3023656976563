import React from "react";
import { useTranslation } from "react-i18next";
import { Title } from "../../atoms/Typography/index";
const RegisterGreeting = () => {
    const { t } = useTranslation();
    return (
        <>
            <Title level={2}>{t("welcome_to_choice_tv_platform")}</Title>
            <Title level={2}>{t("create_your_account")}</Title>
        </>
    );
};

export default RegisterGreeting;
