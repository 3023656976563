export const SOCIAL_LIKE_REQUEST = "SOCIAL_LIKE_REQUEST";
export const SOCIAL_LIKE_SUCCESS = "SOCIAL_LIKE_SUCCESS";
export const SOCIAL_LIKE_FAIL = "SOCIAL_LIKE_FAIL";

export const SOCIAL_UNLIKE_REQUEST = "SOCIAL_UNLIKE_REQUEST";
export const SOCIAL_UNLIKE_SUCCESS = "SOCIAL_UNLIKE_SUCCESS";
export const SOCIAL_UNLIKE_FAIL = "SOCIAL_UNLIKE_FAIL";

export const SOCIAL_FOLLOW_REQUEST = "SOCIAL_FOLLOW_REQUEST";
export const SOCIAL_FOLLOW_SUCCESS = "SOCIAL_FOLLOW_SUCCESS";
export const SOCIAL_FOLLOW_FAIL = "SOCIAL_FOLLOW_FAIL";

export const SOCIAL_UNFOLLOW_REQUEST = "SOCIAL_UNFOLLOW_REQUEST";
export const SOCIAL_UNFOLLOW_SUCCESS = "SOCIAL_UNFOLLOW_SUCCESS";
export const SOCIAL_UNFOLLOW_FAIL = "SOCIAL_UNFOLLOW_FAIL";

export const SOCIAL_SHARE_REQUEST = "SOCIAL_SHARE_REQUEST";
export const SOCIAL_SHARE_SUCCESS = "SOCIAL_SHARE_SUCCESS";
export const SOCIAL_SHARE_FAIL = "SOCIAL_SHARE_FAIL";

export const SOCIAL_SHARE_DELETE_REQUEST = "SOCIAL_SHARE_DELETE_REQUEST";
export const SOCIAL_SHARE_DELETE_SUCCESS = "SOCIAL_SHARE_DELETE_SUCCESS";
export const SOCIAL_SHARE_DELETE_FAIL = "SOCIAL_SHARE_DELETE_FAIL";

export const SOCIAL_SUGGESTED_PLAYLIST_REQUEST =
    "SOCIAL_SUGGESTED_PLAYLIST_REQUEST";
export const SOCIAL_SUGGESTED_PLAYLIST_SUCCESS =
    "SOCIAL_SUGGESTED_PLAYLIST_SUCCESS";
export const SOCIAL_SUGGESTED_PLAYLIST_FAIL = "SOCIAL_SUGGESTED_PLAYLIST_FAIL";


export const SOCIAL_FOLLOWED_PLAYLISTS_REQUEST = "FOLLOWED_PLAYLISTS";
export const SOCIAL_FOLLOWED_PLAYLISTS_SUCCESS = "FOLLOWED_PLAYLISTS_SUCCESS";
export const SOCIAL_FOLLOWED_PLAYLISTS_FAIL = "FOLLOWED_PLAYLISTS_FAIL";

export const SOCIAL_FOLLOWING_PLAYLISTS_REQUEST = "FOLLOWING_PLAYLISTS_REQUEST";
export const SOCIAL_FOLLOWING_PLAYLISTS_SUCCESS = "FOLLOWING_PLAYLISTS_SUCCESS";
export const SOCIAL_FOLLOWING_PLAYLISTS_FAIL = "FOLLOWING_PLAYLISTS_FAIL";