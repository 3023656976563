import React from 'react'
import "./index.less"

const SearchIconPrototype = () => {
  return (
    <svg className='search-icon-prototype' xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 477.869 477.869" xmlSpace="preserve">  
        <defs>
        <g id="Layer1_0_FILL">
        <path fill="#FFFFFF" stroke="none" d="
          M 166.05 149.05
          Q 183.4 126.15 183.4 96.1 183.4 59.3 157.4 33.35 131.35 7.25 94.6 7.25 57.8 7.25 31.8 33.35 5.75 59.3 5.75 96.1 5.75 132.85 31.8 158.9 57.8 184.9 94.6 184.9 119.35 184.9 139.3 173.1
          L 213 246.8
          Q 213.1 246.9 213.2 247 216.2 249.9 219.75 250.7 223.45 251.35 225.5 249.2
          L 240.85 233.9
          Q 242.9 231.8 242.25 228.2 241.5 224.45 238.4 221.4
          L 166.05 149.05
          M 139.8 50.9
          Q 158.55 69.6 158.55 96.1 158.55 122.55 139.8 141.35 121.05 160.05 94.6 160.05 68.1 160.05 49.4 141.35 30.65 122.55 30.65 96.1 30.65 69.6 49.4 50.9 68.1 32.15 94.6 32.15 121.05 32.15 139.8 50.9 Z"/>
        </g>
      </defs>

      <g transform="matrix( 1, 0, 0, 1, 0,0) ">
        <use xlinkHref="#Layer1_0_FILL"/>
      </g>
    </svg>

  )
}

export default SearchIconPrototype