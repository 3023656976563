import React, { useContext, useEffect, useState, useRef } from "react";
import { Context } from "../../../../../context/context";
import Modal from "@shared/atoms/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  getPlaylistById,
  updatePlaylist,
} from "../../../../../redux/actions/playlistsActions";
import { useTranslation } from "react-i18next";
import Tag from "@shared/atoms/Tag";
import Input from "@shared/atoms/Input";
import { v4 as uuidv4 } from "uuid";
import ErrorFeedbackPrototype from "@components/shared/atoms/ErrorFeedbackPrototype";
import { isMobile } from "react-device-detect";

import EditableImage from "@components/organisms/EditableImage";
import { METHODS } from "const/apiMethods";
import { changeStatusText } from "@utils/changeStatusText";
import "./index.less";

export default function EditPlaylistModalPrototype() {
  const { t } = useTranslation();

  const {
    showEditPlaylistModalPrototype,
    setShowEditPlaylistModalPrototype,
  } = useContext(Context);

  const [name, setName] = useState("");
  const [icon, setIcon] = useState("");
  const [describe, setDescribe] = useState("");
  const [status, setStatus] = useState("");
  const [statusesArr, setStatusesArr] = useState([]);
  const [statusId, setStatusId] = useState();
  const [tags, setTags] = useState([""]);
  const [tagInputVisible, setTagInputVisible] = useState(true);
  const [tagInputValue, setTagInputValue] = useState("");
  const [sizeError, setSizeError] = useState(false);
  const [isImageChange, setIsImageChange] = useState(false);

  const createTagRef = useRef(null);

  const { statuses } = useSelector((state) => state.playlistStatuses);

  const { playlist } = useSelector((state) => state.playlistById);

  const {
    loading: updatePlaylistLoading,
    error: updatePlaylistError,
    message: updatePlaylistMessage,
  } = useSelector((state) => state.updatePlaylist);

  const dispatch = useDispatch();

  useEffect(() => {
    if (updatePlaylistMessage) {
      setShowEditPlaylistModalPrototype(false);
    }
  }, [updatePlaylistMessage, setShowEditPlaylistModalPrototype]);

  useEffect(() => {
    const currentStatusData = statuses?.find(
      (status) => status.id === playlist?.data?.status_id
    );

    if (playlist) {
      setName(playlist?.data?.name);
      setIcon(playlist?.data?.icon);
      setDescribe(playlist?.data?.description);
      setStatusesArr(statuses);
      setStatus(currentStatusData?.name);
      setStatusId(currentStatusData?.id);
      setTags(playlist?.data?.tags.map((tag) => tag.name));
    }
  }, [playlist, statuses]);

  const updatePlaylistHandler = () => {
    // if (uploadedImageSize > 1400000) {
    //   setSizeError(true);
    //   return;
    // }

    let fd = new FormData();
    
    if (isImageChange) {
      fd.append("icon", icon);
    }

    fd.append("name", name);
    fd.append("status_id", statusId);
    fd.append("description", describe);

    if (tags.length === 0) {
      fd.append(`tags`, [])
    }
    else {
      tags?.forEach((tag, index) => {
        fd.append(`tags[${index}]`, tag);
      });
    }

    fd.append("_method", METHODS.PATCH);

    Promise.resolve(dispatch(updatePlaylist(fd, playlist?.data?.id))).then(
      () => {
        dispatch(getPlaylistById(playlist?.data?.id));
      }
    );
    setIsImageChange(false);
  };

  const changeStatus = (e) => {
    const statusId = statuses?.find(
      (status) => status.name === e.target.value
    ).id;

    setStatus(e.target.value);
    setStatusId(statusId);
  };

  const createNewTag = () => {
    if (tagInputValue && tags.indexOf(tagInputValue) === -1) {
      setTags([...tags, tagInputValue]);
      setTagInputValue("");
    }
  };

  const removeTag = (removedTag, index) => {
    const tagIndex = tags.indexOf(removedTag);
    const filteredTags = tags.splice(tagIndex, 1);
    const filterTags = tags.filter(tag => tag !== filteredTags)
    setTags(filterTags);
  };

  const showTagInput = () => {
    setTagInputVisible(true);
  };

  const closeModalHandler = () => {
    setShowEditPlaylistModalPrototype(false);
    setSizeError(false);
  };

  return (
    <div>
      <Modal
        title={t("update_playlist_title")}
        className={`add-modal update-playlist-modal ${!isMobile ? "desktop-modal" : ""}`}
        visible={showEditPlaylistModalPrototype}
        onCancel={closeModalHandler}
        footer={
          <button
            className="update-btn"
            disabled={updatePlaylistLoading}
            onClick={updatePlaylistHandler}
          >
            {t("update")}
          </button>
        }
        destroyOnClose={true}
      >
        {updatePlaylistError?.data && (
          <ErrorFeedbackPrototype>
            {`${updatePlaylistError?.data.message}
            ${updatePlaylistError?.data?.errors?.icon
                ? updatePlaylistError?.data?.errors?.icon[0]
                : ""
              } `}
          </ErrorFeedbackPrototype>
        )}

        <div>
          <label>{t("playlist_name")}</label>
          <input
            maxLength={50}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          {showEditPlaylistModalPrototype && (
            <EditableImage
              providedImageLink={icon}
              setBlobImg={setIcon}
              setIsChange={setIsImageChange}
              btnText={"Upload channel image"}
            />
          )}
        </div>
        {sizeError && (
          <div className="size-validation-error">
            Logo must be less than 1MB.
          </div>
        )}
        <div>
          <label>{t("playlist_description")}</label>
          <input
            value={describe}
            onChange={(e) => setDescribe(e.target.value)}
          />
        </div>
        <div>
          <label>{t("playlist_privacy")}</label>
          <select defaultValue={`${status}`} onChange={changeStatus}>
            {statusesArr?.map((el) => (
              <option key={el.id} value={el.name}>
                {changeStatusText(el.name)}
              </option>
            ))}
          </select>
        </div>
        <div className="tag-items playlist-text-area tags-text-area">
          <div className="tag-items-container">
            {tags?.map((tag, index) => (
              <Tag
                key={uuidv4()}
                onClose={(e) => {
                  removeTag(tag, index);
                  e.preventDefault();
                }}
                closable={true}
                className="tag"
              >
                {tag?.length > 20 ? `${tag?.slice(0, 20)}...` : tag}
              </Tag>
            ))}
          </div>
          {tagInputVisible && (
            <Input
              maxLength={50}
              className="tag-input"
              ref={createTagRef}
              type="text"
              size="small"
              value={tagInputValue}
              onChange={(e) => setTagInputValue(e.target.value)}
              onBlur={createNewTag}
              onPressEnter={createNewTag}
              placeholder={t("create_tag")}
            />
          )}

          {!tagInputVisible && (
            <Tag onClick={showTagInput} closable={true} className="tag">
              {t("new_tag")}
            </Tag>
          )}
        </div>
      </Modal>
    </div>
  );
}
