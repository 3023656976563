import { Context } from "@context/context";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  addVideoInPlaylistNew,
  getPlaylistById,
} from "@redux/actions/playlistsActions";

const useCopyPlaylist = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    copyingItem,
    isCopyingItem,
    copySource,
    setSelectedPlaylist,
    setShowTermsImagesPrototype,
    setShowSubBarPrototype,
  } = useContext(Context);

  const handleCopyingPlaylistItem = (copyTargetPlaylistId) => {
    Promise.resolve(
      dispatch(
        addVideoInPlaylistNew({
          playlistId: copyTargetPlaylistId,
          seriesName:
            copySource.seriesName ||
            `Copy from playlist - ${moment().format("YYYY-MM-DD - hh:mm:ss")}`,
          title: copySource.title,
          description: copySource.description,
          image: copySource.image,
          src: copySource.src,
        })
      )
    ).then(() => {
      copyingItem(null);
      setSelectedPlaylist(copyTargetPlaylistId);
      dispatch(getPlaylistById(copyTargetPlaylistId));
    });
  };

  const playlistClickHandler = (id) => {
    if (isCopyingItem) return handleCopyingPlaylistItem(id);

    history.push({ search: `?playlist=${id}` });
    setShowTermsImagesPrototype(false);
    setShowSubBarPrototype(false);
  };

  return {
    copyPlaylist: playlistClickHandler,
    isCopyingItem,
  };
};

export default useCopyPlaylist;
