import React, { useContext } from "react";
import DefaultSerieImage from '@src/assets/icons/defaultSeriesIcon.png';
import HomePositionPrototype from "@components/shared/molecules/HomePositionPrototype";
import { Context } from "@context/context";


const VideoItem = ({ onClick, serieItem, hasHomeIcon, thumbnail, title, ...rest }, ref) => {
  const {
    setShowUpdateSerieTitleModal,
    setCurrentSerieItem,
  } = useContext(Context);

  const handleClick = () => {
    setShowUpdateSerieTitleModal(true);
    setCurrentSerieItem(serieItem);
    // return debounce(() => {
    // },100)
  }

  return (
    <div
      ref={ref}
      className="video-item"
      style={thumbnail ? { backgroundImage: `url(${thumbnail})` } : { backgroundImage: `url(${DefaultSerieImage})` }}
      onClick={hasHomeIcon ? onClick : null }
      {...rest}
    >
      { hasHomeIcon && <HomePositionPrototype className="home-position-small" 
          onClick={() => handleClick()} 
        />
      }

      <p className="video-item__label">{title}</p>
    </div>
  );
};

export default React.forwardRef(VideoItem);
