import React, { useState } from "react";
import Form from "@shared/atoms/Form";
import Input from "@components/shared/atoms/Input";
import Button from "@components/shared/atoms/Button";
import Modal from "@components/shared/atoms/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { confirmResetPassword } from "@redux/actions/userActions";
import { useHistory, useParams } from "react-router-dom";

import "./index.less";
import ErrorFeedbackPrototype from "@components/shared/atoms/ErrorFeedbackPrototype";
import { USER_CONFIRM_RESET_PASSWORD_RESET } from "@redux/constants/userConstants";

const ForgetPasswordModalPrototype = ({ show, setShow }) => {
  const { t } = useTranslation();

  const { email, token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const history = useHistory();

  const dispatch = useDispatch();

  const {
    loading,
    error: forgetPasswordError,
    success,
  } = useSelector((state) => state.userConfirmResetPassword);

  const handleClosePassword = () => {
    setShow(false);
    dispatch({ type: USER_CONFIRM_RESET_PASSWORD_RESET });
    history.push("/settings");
  };

  const submitPassword = () => {
    dispatch(confirmResetPassword(email, token, password, confirmPassword));
  };

  return (
    <Modal
      title={t("reset_your_password")}
      className="ant-modal-prototype forget-password-modal-prototype"
      visible={show}
      closable={true}
      onCancel={handleClosePassword}
      footer={[
        <Button
          disabled={loading}
          type="primary"
          key="back"
          onClick={submitPassword}
        >
          {t("reset")}
        </Button>,
      ]}
    >
      <Form className="ant-form-prototype forget-password-form">
        <Form.Item label={t("password")}>
          <Input.Password
            className="input-forget-password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t("enter_password")}
          />
        </Form.Item>
        <Form.Item label={t("confirm_password")}>
          <Input.Password
            className="input-forget-password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder={t("confirm_password")}
          />
        </Form.Item>
      </Form>
      {forgetPasswordError && (
        <>
          <ErrorFeedbackPrototype>
            {forgetPasswordError?.data?.error}{" "}
          </ErrorFeedbackPrototype>
          <ErrorFeedbackPrototype>
            {forgetPasswordError?.data?.errors.password[0]}
          </ErrorFeedbackPrototype>
        </>
      )}
      {success && (
        <ErrorFeedbackPrototype>
          Password change success !
        </ErrorFeedbackPrototype>
      )}
    </Modal>
  );
};

export default ForgetPasswordModalPrototype;
