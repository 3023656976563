import React from "react";
import "./index.less";

const ClosePlayer = ({onClick}) => {
  return (
    <svg
      onClick={onClick}
      className="close-player-svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="none"
      x="0px"
      y="0px"
      width="83px"
      height="83px"
      viewBox="0 0 83 83"
    >
      <defs>
        <g id="Layer0_0_FILL">
          <path
            fill="#FFFFFF"
            stroke="none"
            d="
M 76.15 12.4
Q 76.15 9.8 74.3 7.95 72.4 6.05 69.8 6.05 67.2 6.05 65.35 7.95
L 41.2 32.05 17.1 7.95
Q 15.2 6.05 12.6 6.05 10 6.05 8.15 7.95 6.25 9.8 6.25 12.4 6.25 15 8.15 16.9
L 32.25 41 8.15 65.15
Q 6.25 67 6.25 69.6 6.25 72.2 8.15 74.1 10 75.95 12.6 75.95 15.2 75.95 17.1 74.1
L 41.2 49.95 65.35 74.1
Q 67.2 75.95 69.8 75.95 72.4 75.95 74.3 74.1 76.15 72.2 76.15 69.6 76.15 67 74.3 65.15
L 50.15 41 74.3 16.9
Q 76.15 15 76.15 12.4 Z"
          />
        </g>

        <path
          id="Layer0_1_MEMBER_0_1_STROKES"
          stroke="#000000"
          strokeOpacity="0"
          strokeWidth="3"
          strokeLinejoin="miter"
          strokeLinecap="butt"
          strokeMiterlimit="10"
          fill="none"
          d="
M 30 -10
Q 46.55 -10 58.25 1.75 70 13.45 70 30 70 46.55 58.25 58.3 46.55 70 30 70 13.45 70 1.7 58.3 -10 46.6 -10 30 -10 13.45 1.7 1.75 13.45 -10 30 -10 Z"
        />
      </defs>

      <g transform="matrix( 1, 0, 0, 1, 0,0) ">
        <use xlinkHref="#Layer0_0_FILL" />
      </g>

      <g transform="matrix( 1, 0, 0, 1, 11.5,11.5) ">
        <use xlinkHref="#Layer0_1_MEMBER_0_1_STROKES" />
      </g>
    </svg>
  );
};

export default ClosePlayer;
