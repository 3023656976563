import React, { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../../../../../context/context";
import Modal from "@shared/atoms/Modal";
import Alert from "@shared/atoms/Alert/index";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getMetaData } from "../../../../../redux/actions/metaDataActions";
import { URL_REGEX } from "../../../../../const/regex";
import SpinnerPrototype from "@shared/atoms/SpinnerPrototype";
import {
  addNewSerieInGroup,
  getPlaylistById,
} from "@redux/actions/playlistsActions";
import { META_DATA_RESET } from "@redux/constants/metaDataConstants";
import EditableImage from "@components/organisms/EditableImage";

import "./index.less";

export default function AddNewSeriePrototype({
  isVisible,
  closeModal,
  series,
  orderId,
}) {
  const { t } = useTranslation();
  const { selectedPlaylist } = useContext(Context);
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState("");
  const [descr, setDescr] = useState("");
  const [uploadedVideoName, setUploadedVideoName] = useState("");
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [isLinkDisabled, setIsLinkDisabled] = useState(false);
  const [isUploadVideoDisabled, setIsUploadVideoDisabled] = useState(false);

  const dispatch = useDispatch();
  const { data: metaData, loading: metaDataLoading } = useSelector(
    (state) => state.metadata
  );

  const { loading: addVideoLoading, error: addVideoError } = useSelector(
    (state) => state.addVideosToPlaylist
  );

  const { profile } = useSelector((state) => state.userProfile);

  const updateState = useCallback(() => {
    setDescr(metaData?.description);
    setIcon(metaData?.image);
    setTitle(metaData?.title);
  }, [metaData?.description, metaData?.image, metaData?.title]);

  useEffect(() => {
    updateState();
  }, [updateState]);

  const onLinkInputBlur = () => {
    if (link && link.match(URL_REGEX)) {
      dispatch(getMetaData(link));
      updateState();
    }
  };

  const linkChangeHandler = (e) => {
    setLink(e.target.value);
    if (e.target.value) {
      setIsUploadVideoDisabled(true);
    } else {
      setIsUploadVideoDisabled(false);
    }
  };

  const uploadVideoFile = (e) => {
    if (e.target.files[0]) {
      setUploadedVideoName(e.target.files[0].name);
      setUploadedVideo(e.target.files[0]);
      setIsLinkDisabled(true);
    } else {
      setUploadedVideoName("");
      setIsLinkDisabled(false);
    }
  };

  const resetInputs = () => {
    dispatch({ type: META_DATA_RESET });
    setLink("");
    // setSeriesName("");
  };

  const addNewSerie = () => {
    const fd = new FormData();
    fd.append("videos[0][title]", title);
    fd.append("videos[0][description]", descr);
    if (link) {
      fd.append("videos[0][src]", link);
    } else if (uploadedVideo) {
      fd.append("videos[0][src]", uploadedVideo);
    }

    fd.append("videos[0][order]", orderId);

    if (icon) fd.append("videos[0][image]", icon || "");

    Promise.resolve(dispatch(addNewSerieInGroup(series.id, fd))).then(() => {
      dispatch(getPlaylistById(selectedPlaylist.id));
      closeModal();
    });
    resetInputs();
  };

  return (
    <div>
      {(metaDataLoading || addVideoLoading) && (
        <SpinnerPrototype withOverlay={true} size={4} />
      )}
      <Modal
        title={t("add_item")}
        className="add-playlist-item-modal-prototype"
        visible={isVisible}
        onCancel={closeModal}
        destroyOnClose={true}
        mask={true}
        footer={
          <button onClick={addNewSerie} disabled={addVideoLoading}>
            {addVideoLoading && (
              <SpinnerPrototype className="btn-spinner" size={1.8} />
            )}
            Create
          </button>
        }
      >
        {addVideoError && (
          <Alert
            message={
              addVideoError?.data.message +
              " " +
              addVideoError?.data.errors["0.src"]
            }
            type="error"
          />
        )}

        <div className="form-control">
          <label>{t("link")} (url)</label>
          <input
            value={link}
            onChange={linkChangeHandler}
            onBlur={onLinkInputBlur}
            disabled={isLinkDisabled}
          />
        </div>
        {profile?.data?.can_upload_video && (
          <>
            <div className="form-control or">or</div>
            <div className="form-control">
              <label className="online-img-label">{t("upload_video")}</label>
              <input
                className="video-upload"
                data-content={`Upload video ${uploadedVideoName}`}
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                onChange={uploadVideoFile}
                disabled={isUploadVideoDisabled}
              />
            </div>
          </>
        )}

        <div className="form-control">
          <label>{t("title")}</label>
          <input value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="form-control">
          <label>{t("series_name")}</label>
          <input value={series?.name} disabled={true} />
        </div>
        <div className="form-control">
          <label>{t("picture")}</label>
          <EditableImage
            providedImageLink={icon}
            setBlobImg={setIcon}
            // setSizeError={setSizeError}
            btnText={t("upload_series_video_image")}
            shape="rectangle"
            aspect={3 / 2}
          />
        </div>
        <div className="form-control">
          <label>{t("description")}</label>
          <textarea
            style={{ height: "10rem" }}
            value={descr}
            onChange={(e) => setDescr(e.target.value)}
          />
        </div>
      </Modal>
    </div>
  );
}
