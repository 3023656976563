//channel groups
export const CHANNEL_GROUPS_REQUEST = "CHANNEL_GROUPS_REQUEST";
export const CHANNEL_GROUPS_SUCCESS = "CHANNEL_GROUPS_SUCCESS";
export const CHANNEL_GROUPS_FAIL = "CHANNEL_GROUPS_FAIL";

//channel items
export const CHANNEL_ITEMS_REQUEST = "CHANNEL_ITEMS_REQUEST";
export const CHANNEL_ITEMS_SUCCESS = "CHANNEL_ITEMS_SUCCESS";
export const CHANNEL_ITEMS_FAIL = "CHANNEL_ITEMS_FAIL";

//channels
export const CHANNELS_REQUEST = "CHANNELS_REQUEST";
export const CHANNELS_SUCCESS = "CHANNELS_SUCCESS";
export const CHANNELS_FAIL = "CHANNELS_FAIL";

// channel items
export const CURRENT_CHANNEL_ITEMS_REQUEST = "CURRENT_CHANNEL_ITEMS_REQUEST";
export const CURRENT_CHANNEL_ITEMS_SUCCESS = "CURRENT_CHANNEL_ITEMS_SUCCESS";
export const CURRENT_CHANNEL_ITEMS_FAIL = "CURRENT_CHANNEL_ITEMS_FAIL";

// create channel item
export const CREATE_CHANNEL_ITEM_REQUEST = "CREATE_CHANNEL_ITEM_REQUEST";
export const CREATE_CHANNEL_ITEM_SUCCESS = "CREATE_CHANNEL_ITEM_SUCCESS";
export const CREATE_CHANNEL_ITEM_FAIL = "CREATE_CHANNEL_ITEM_FAIL";

// update channel item
export const UPDATE_CHANNELITEM_REQUEST = "UPDATE_CHANNELITEM_REQUEST";
export const UPDATE_CHANNELITEM_SUCCESS = "UPDATE_CHANNELITEM_SUCCESS";
export const UPDATE_CHANNELITEM_FAIL = "UPDATE_CHANNELITEM_FAIL";

// create channel group
export const CREATE_CHANNELGROUP_REQUEST = "CREATE_CHANNELGROUP_REQUEST";
export const CREATE_CHANNELGROUP_SUCCESS = "CREATE_CHANNELGROUP_SUCCESS";
export const CREATE_CHANNELGROUP_FAIL = "CREATE_CHANNELGROUP_FAIL";

// delete channel group
export const DELETE_CHANNELGROUP_REQUEST = "DELETE_CHANNELGROUP_REQUEST";
export const DELETE_CHANNELGROUP_SUCCESS = "DELETE_CHANNELGROUP_SUCCESS";
export const DELETE_CHANNELGROUP_FAIL = "DELETE_CHANNELGROUP_FAIL";

// EPG channels
export const EPG_CHANNELS_REQUEST = "EPG_CHANNELS_REQUEST";
export const EPG_CHANNELS_SUCCESS = "EPG_CHANNELS_SUCCESS";
export const EPG_CHANNELS_FAIL = "EPG_CHANNELS_FAIL";
export const EPG_CHANNELS_RESET = "EPG_CHANNELS_RESET";

//Channel by id
export const CHANNEL_BY_ID_REQUEST = "CHANNEL_BY_ID_REQUEST";
export const CHANNEL_BY_ID_SUCCESS = "CHANNEL_BY_ID_SUCCESS";
export const CHANNEL_BY_ID_FAIL = "CHANNEL_BY_ID_FAIL";
export const CHANNEL_BY_ID_RESET = "CHANNEL_BY_ID_RESET";

//channel keywords (suggestions)
export const CHANNEL_KEYWORDS_REQUEST = "CHANNEL_KEYWORDS_REQUEST";
export const CHANNEL_KEYWORDS_SUCCESS = "CHANNEL_KEYWORDS_SUCCESS";
export const CHANNEL_KEYWORDS_FAIL = "CHANNEL_KEYWORDS_FAIL";

//channel search
export const CHANNEL_SEARCH_REQUEST = "CHANNEL_SEARCH_REQUEST";
export const CHANNEL_SEARCH_SUCCESS = "CHANNEL_SEARCH_SUCCESS";
export const CHANNEL_SEARCH_FAIL = "CHANNEL_SEARCH_FAIL";

//dutch dummy channel groups
export const DUTCH_CHANNELGROUPS_REQUEST = "DUTCH_CHANNELGROUPS_REQUEST";
export const DUTCH_CHANNELGROUPS_SUCCESS = "DUTCH_CHANNELGROUPS_SUCCESS";
export const DUTCH_CHANNELGROUPS_FAIL = "DUTCH_CHANNELGROUPS_FAIL";

//dutch dummy channels
export const DUTCH_CHANNELS_REQUEST = "DUTCH_CHANNELS_REQUEST";
export const DUTCH_CHANNELS_SUCCESS = "DUTCH_CHANNELS_SUCCESS";
export const DUTCH_CHANNELS_FAIL = "DUTCH_CHANNELS_FAIL";

export const COPY_TIRES_REQUEST = "COPY_TIRES_REQUEST";
export const COPY_TIRES_SUCCESS = "COPY_TIRES_SUCCESS";
export const COPY_TIRES_FAIL = "COPY_TIRES_FAIL";

// Promotion Channels
export const PROMOTION_CHANNELS_REQUEST = "PROMOTION_CHANNELS_REQUEST";
export const PROMOTION_CHANNELS_SUCCESS = "PROMOTION_CHANNELS_SUCCESS";
export const PROMOTION_CHANNELS_FAIL = "PROMOTION_CHANNELS_FAIL";

//direct share channel
export const CHANNELS_EPG_DIRECT_SHARE_REQUEST = "CHANNELS_EPG_DIRECT_SHARE_REQUEST";
export const CHANNELS_EPG_DIRECT_SHARE_SUCCESS = "CHANNELS_EPG_DIRECT_SHARE_SUCCESS";
export const CHANNELS_EPG_DIRECT_SHARE_FAIL = "CHANNELS_EPG_DIRECT_SHARE_FAIL";


export const SUBSCRIBE_TO_CHANNEL_REQUEST = "SUBSCRIBE_TO_CHANNEL_REQUEST";
export const SUBSCRIBE_TO_CHANNEL_SUCCESS = "SUBSCRIBE_TO_CHANNEL_SUCCESS";
export const SUBSCRIBE_TO_CHANNEL_FAIL = "SUBSCRIBE_TO_CHANNEL_FAIL";

export const UNSUBSCRIBE_TO_CHANNEL_REQUEST = "UNSUBSCRIBE_TO_CHANNEL_REQUEST";
export const UNSUBSCRIBE_TO_CHANNEL_SUCCESS = "UNSUBSCRIBE_TO_CHANNEL_SUCCESS";
export const UNSUBSCRIBE_TO_CHANNEL_FAIL = "UNSUBSCRIBE_TO_CHANNEL_FAIL";