import React, { useState, useRef, useEffect } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./cnavasPreview";
import { useDebounceEffect } from "@src/hooks/useDebounceEffect";

import "react-image-crop/dist/ReactCrop.css";
import SliderInput from "@components/shared/atoms/SliderInput";

import "./index.less";
import { useTranslation } from "react-i18next";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%", // Can be 'px' or '%'
        x: 25,
        y: 25,
        width: 70,
        height: 70,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const EditableImage = ({
  providedImageLink,
  setBlobImg,
  setSizeError,
  btnText,
  setIsChange,
  aspect = 1 / 1,
  shape = "circle",
}) => {
  const { t } = useTranslation();
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(0.5);
  const [rotate, setRotate] = useState(0);
  const [imageLink, setImageLink] = useState("");
  // could not find proper onchange event for react crop to detect image change
  const [isImageFirstLoad, setIsImageFirstLoad] = useState(true);
  const imageRef = useRef(null);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      if (setSizeError) setSizeError(false);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        return new Promise((resolve, reject) => {
          previewCanvasRef.current.toBlob((blob) => {
            if (!blob) {
              console.error("Canvas is empty");
              return;
            }
            setBlobImg(blob);
          }, "image/png");
        });
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const onChangeScale = (value) => {
    setScale(Number(value));
    setIsChange?.(true);
  };

  const onChangeRotate = (value) => {
    setRotate(Math.min(180, Math.max(-180, Number(value))));
    setIsChange?.(true);
  };

  const imageInputChange = async (URL) => {
    onImageReselect();

    const blob = await getBlobFromUrl(URL);
    if (blob) {
      readAndSetFile(blob);
    }
  };

  const readAndSetFile = (file) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgSrc(reader.result.toString() || "");
    });
    reader.readAsDataURL(file);
  };

  const getBlobFromUrl = async (url) => {
    const fullUrl = `https://api.dev.watchchoice.tv/api/stream-image?url=${url}`;
    try {
      return await simpleFetch(fullUrl, "POST");
    } catch (error) {
      return await simpleFetch(url);
    }
  };

  const simpleFetch = async (url, method = "GET") => {
    const response = await fetch(url, { method });
    if (response.ok) {
      return await response.blob();
    } else {
      throw new Error("Something went wrong");
    }
  };

  const onImageReselect = () => {
    setCrop(undefined);
    setImgSrc("");
    if (setSizeError) setSizeError(false);
  };

  // const isImage = (blob) => {
  //   return blob.type.includes("image/");
  // };

  useEffect(() => {
    if (!imageLink) return;

    imageInputChange(imageLink);
  }, [imageLink]);

  useEffect(() => {
    if (typeof providedImageLink === "string") {
      setImageLink(providedImageLink);
    } else {
      // if providedImageLink is not valid(user has not uploaded image) make as if thre was one
      setIsImageFirstLoad(false);
    }
  }, [providedImageLink]);

  return (
    <div className="editable-image-container">
      <div className={`crop-controls ${shape}`}>
        <div className="img-inputs">
          <div className="img-input-container">
            <label>{t("select_local_image")}</label>
            <input
              className="input-type-file"
              data-content={btnText}
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              ref={imageRef}
            />
            <button
              className="img-upload-btn"
              onClick={() => imageRef.current.click()}
            >
              {btnText}
            </button>
          </div>
          <p>or</p>
          <div className="img-input-container">
            <label className="online-img-label">
              {t("select_online_image")}
            </label>
            <input
              className="url-input"
              onChange={(e) => setImageLink(e.target.value)}
              placeholder="Enter image url"
              value={imageLink}
            />
          </div>
        </div>

        <div className="cropable-img-container">
          {Boolean(imgSrc) && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              locked
              maxHeight={50}
              maxWidth={50}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={(e) => {
                  if (isImageFirstLoad) {
                    setIsImageFirstLoad(false);
                  } else {
                    setIsChange?.(true);
                  }
                  onImageLoad(e);
                }}
              />
            </ReactCrop>
          )}
        </div>

        {Boolean(imgSrc) && (
          <>
            <div>
              <label className="crop-label" htmlFor="scale-input">
                Scale:
              </label>
              <SliderInput
                min={0.5}
                max={3}
                step={0.1}
                defaultValue={1}
                onChange={onChangeScale}
                value={scale}
              />
            </div>
            <div>
              <label className="crop-label" htmlFor="rotate-input">
                Rotate:
              </label>
              <SliderInput
                min={-180}
                max={180}
                step={5}
                defaultValue={0}
                onChange={onChangeRotate}
                value={rotate}
              />
            </div>
          </>
        )}
      </div>

      <div>
        {Boolean(completedCrop) && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: "1px solid black",
              objectFit: "contain",
              width: completedCrop.width,
              height: completedCrop.height,
              display: "none",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EditableImage;
