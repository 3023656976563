import List from "@components/shared/atoms/List";
import SpinnerPrototype from "@components/shared/atoms/SpinnerPrototype";
import HomePositionPrototype from "@components/shared/molecules/HomePositionPrototype";
import { Context } from "@context/context";
import { selectSearchHistory } from "@redux/actions/searchActions";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SearchListPrototype from "../lists/SearchListPrototype";
import "./index.less";

const SearchSubbarContainer = () => {
  const { loading } = useSelector(selectSearchHistory);
  const history = useHistory();

  const { setShowSearchModalPrototype } = useContext(Context);

  const homeItemClickHandler = () => {
    history.push("/search");
  }

  return (
    <List className="search-subbar-container">
      {loading ? (
        <SpinnerPrototype size={2} />
      ) : (
        <>
          <SearchListPrototype type="followed" />
          <HomePositionPrototype
            onClick={() => {
              homeItemClickHandler();
              setShowSearchModalPrototype(true);
            }}
          />
          <SearchListPrototype type="unfollowed" />
        </>
      )}
    </List>
  );
};

export default SearchSubbarContainer;
