import React from "react";
// import AntdDatePicker from "antd/lib/date-picker/index";
// import Space from "antd/lib/space/index";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./index.less";

const DatePicker = ({ ...rest }) => {
    return (
        // <Space direction="vertical">
        //     <AntdDatePicker {...rest} />
        // </Space>
        <ReactDatePicker {...rest} />
    );
};

export default DatePicker;
