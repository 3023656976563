import {
  PLAYLISTS_REQUEST,
  PLAYLISTS_SUCCESS,
  PLAYLISTS_FAIL,
  PLAYLISTS_RESET,
  PLAYLIST_CREATE_REQUEST,
  PLAYLIST_CREATE_SUCCESS,
  PLAYLIST_CREATE_FAIL,
  PLAYLIST_DELETE_REQUEST,
  PLAYLIST_DELETE_SUCCESS,
  PLAYLIST_DELETE_FAIL,
  PLAYLIST_VIDEO_DELETE_REQUEST,
  PLAYLIST_VIDEO_DELETE_SUCCESS,
  PLAYLIST_VIDEO_DELETE_FAIL,
  ADD_VIDEOS_TO_PLAYLIST_REQUEST,
  ADD_VIDEOS_TO_PLAYLIST_SUCCESS,
  ADD_VIDEOS_TO_PLAYLIST_FAIL,
  UPDATE_PLAYLIST_REQUEST,
  UPDATE_PLAYLIST_SUCCESS,
  UPDATE_PLAYLIST_FAIL,
  MOVE_PLAYLIST_SUCCESS,
  MOVE_PLAYLIST_REQUEST,
  MOVE_PLAYLIST_FAIL,
  ADD_RELATED_VIDEO_REQUEST,
  ADD_RELATED_VIDEO_SUCCESS,
  ADD_RELATED_VIDEO_FAIL,
  RELATED_VIDEOS_REQUEST,
  RELATED_VIDEOS_SUCCESS,
  RELATED_VIDEOS_FAIL,
  RELATED_VIDEOS_CLEAR,
  PLAYLIST_BY_ID_REQUEST,
  PLAYLIST_BY_ID_SUCCESS,
  PLAYLIST_BY_ID_FAIL,
  DELETE_RELATED_VIDEO_REQUEST,
  DELETE_RELATED_VIDEO_SUCCESS,
  DELETE_RELATED_VIDEO_FAIL,
  PLAYLIST_WITH_RELATED_VIDEOS_REQUEST,
  PLAYLIST_WITH_RELATED_VIDEOS_SUCCESS,
  PLAYLIST_WITH_RELATED_VIDEOS_FAIL,
  SEARCH_PLAYLIST_REQUEST,
  SEARCH_PLAYLIST_SUCCESS,
  SEARCH_PLAYLIST_FAIL,
  PLAYLIST_REPORTS_REQUEST,
  PLAYLIST_REPORTS_SUCCESS,
  PLAYLIST_REPORTS_FAIL,
  PLAYLIST_REPORT_ADD_FAIL,
  PLAYLIST_REPORT_ADD_REQUEST,
  PLAYLIST_REPORT_ADD_SUCCESS,
  PLAYLIST_REPORT_MODAL_CLOSE,
  PLAYLIST_VIDEO_REORDER,
  PLAYLIST_VIDEO_REORDER_SUCCESS,
  PLAYLIST_VIDEO_REORDER_FAIL,
  PLAYLIST_VIDEO_REORDER_REQUEST,
  PLAYLIST_KEYWORDS_REQUEST,
  PLAYLIST_KEYWORDS_SUCCESS,
  PLAYLIST_KEYWORDS_FAIL,
  PLAYLIST_STATUSES_REQUEST,
  PLAYLIST_STATUSES_SUCCESS,
  PLAYLIST_STATUSES_FAIL,
  PLAYLIST_BY_ID_CLEAR,
  UPDATE_RELATED_VIDEO_ORDER,
  UPDATE_RELATED_VIDEO_ORDER_REQUEST,
  UPDATE_RELATED_VIDEO_ORDER_SUCCESS,
  UPDATE_RELATED_VIDEO_ORDER_FAIL,
  PLAYLIST_VIDEO_METADATA_REQUEST,
  PLAYLIST_VIDEO_METADATA_SUCCESS,
  PLAYLIST_VIDEO_METADATA_FAIL,
  PLAYLIST_VIDEO_UPDATE_REQUEST,
  PLAYLIST_VIDEO_UPDATE_SUCCESS,
  PLAYLIST_VIDEO_UPDATE_FAIL,
  DEFAULT_PLAYLISTS_REQUEST,
  DEFAULT_PLAYLISTS_SUCCESS,
  DEFAULT_PLAYLISTS_FAIL,
  PLAYLIST_CREATE_RESET,
  ARRANGE_NAVBAR_PLAYLISTS,
  UPDATE_PLAYLIST_RESET,
  SEARCH_MY_PLAYLISTS_REQUEST,
  SEARCH_MY_PLAYLISTS_SUCCESS,
  SEARCH_MY_PLAYLISTS_FAIL,
  ARRANGE_PLAYLIST_BY_ID,
  ARRANGE_SERIES_IN_GROUP,
  ADD_VIDEOS_TO_PLAYLIST_RESET,
  UPDATE_SERIE_DETAILS_REQUEST,
  UPDATE_SERIE_DETAILS_SUCCESS,
  UPDATE_SERIE_DETAILS_FAIL,
  ARRANGE_ARCHIVE,
  ARRANGE_OTHER_TO_ARCHIVE,
  ARRANGE_ARCHIVE_TO_OTHER,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  UPDATE_GROUP_RESET,
  ARRANGE_GROUP,
  ARRANGE_GROUP_ITEMS,
  ADD_PLAYLIST_IN_GROUP,
  TAKE_OUT_PLAYLIST_FROM_GROUP,
  MOVE_PLAYLIST_FROM_GROUP_TO_PUBLIC,
  DIRECT_SHARE_ADD_SERIE_REQUEST,
  DIRECT_SHARE_ADD_SERIE_SUCCESS,
  DIRECT_SHARE_ADD_SERIE_FAIL,
  DIRECT_SHARE_ADD_VIDEO_REQUEST,
  DIRECT_SHARE_ADD_VIDEO_FAIL,
  DIRECT_SHARE_ADD_VIDEO_SUCCESS,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  CREATE_GROUP_RESET,
  FOLLOWED_PLAYLISTS_ARRANGE_REQUEST,
  FOLLOWED_PLAYLISTS_ARRANGE_SUCCESS,
  FOLLOWED_PLAYLISTS_ARRANGE_FAIL,
  FOLLOWED_PLAYLISTS_ARRANGE_LOCAL,
  PLAYLISTS_ARRANGE_REQUEST,
  PLAYLISTS_ARRANGE_FAIL,
  PLAYLISTS_ARRANGE_SUCCESS,
  PLAYLISTS_ARRANGE_LOCAL,
  NOT_FOLLOWED_PLAYLIST_HISTORY_REQUEST,
  NOT_FOLLOWED_PLAYLIST_HISTORY_SUCCESS,
  NOT_FOLLOWED_PLAYLIST_HISTORY_FAIL,
} from "../constants/playlistsConstants";
import { cloneDeep } from "lodash";
import groupBy from "@utils/groupBy";
import { createSelector } from "reselect";
import produce from "immer";

const find = (list, id) => {
  return list.find((el) => el.id === id);
};

export const playlistsReducer = produce((state = {}, action) => {
  switch (action.type) {
    case PLAYLISTS_REQUEST:
      state.loading = true;
      break;
    case PLAYLISTS_SUCCESS:
      state.loading = false;
      state.playlists = action.payload;
      break;
    // return { loading: false, playlists: action.payload?.playlists };
    case ARRANGE_NAVBAR_PLAYLISTS:
      const targetPlaylist = state.playlists?.other[action.payload.from];
      targetPlaylist.is_left = action.payload.is_left;
      state.playlists?.other.splice(action.payload.from, 1);
      if (action.payload.is_left) {
        state.playlists?.other.splice(action.payload.to, 0, targetPlaylist);
      } else {
        state.playlists?.other.splice(
          action.payload.to + action.payload.leftPlaylistLength,
          0,
          targetPlaylist
        );
      }
      break;
    case ARRANGE_ARCHIVE:
      const targetArchive = state.playlists?.archive[action.payload.from];
      state.playlists?.archive.splice(action.payload.from, 1);
      state.playlists?.archive.splice(action.payload.to, 0, targetArchive);
      break;
    case ARRANGE_OTHER_TO_ARCHIVE:
      const targetPl = state.playlists?.other[action.payload.from];
      state.playlists?.other.splice(action.payload.from, 1);
      state.playlists?.archive.splice(action.payload.to, 0, targetPl);
      break;
    case ARRANGE_ARCHIVE_TO_OTHER:
      let insertElementAt = action.payload.to;
      const removeElementAt = action.payload.from;

      const targetArch = state.playlists?.archive[action.payload.from];

      if (!action.payload.is_left) {
        insertElementAt += action.payload.leftPlaylistLength;
        targetArch.is_left = false;
      }
      state.playlists?.archive.splice(removeElementAt, 1);
      state.playlists?.other.splice(insertElementAt, 0, targetArch);
      break;
    case ARRANGE_GROUP:
      const targetGroup = state.playlists?.groups[action.payload.from];
      state.playlists?.groups.splice(action.payload.from, 1);
      state.playlists?.groups.splice(action.payload.to, 0, targetGroup);
      break;
    case ARRANGE_GROUP_ITEMS:
      const targetGr = state.playlists?.groups.find(
        ({ id }) => id === action.payload.groupId
      );
      if (!targetGr) break;
      const groupPlaylists = targetGr.playlists;

      const targetGroupPl = groupPlaylists[action.payload.from];
      groupPlaylists.splice(action.payload.from, 1);
      groupPlaylists.splice(action.payload.to, 0, targetGroupPl);
      break;
    case ADD_PLAYLIST_IN_GROUP:
      const removeIndex = action.payload.isFromLeftPl
        ? action.payload.from
        : state.playlists?.other.filter(({ is_left }) => is_left).length +
        action.payload.from;

      const targetG = state.playlists?.groups.find(
        ({ id }) => id === action.payload.groupId
      );

      const targetp = state.playlists?.other[removeIndex];
      state.playlists?.other.splice(removeIndex, 1);
      targetG.playlists.splice(action.payload.to, 0, targetp);
      break;
    case TAKE_OUT_PLAYLIST_FROM_GROUP:
      const targetGro = state.playlists?.groups.find(
        ({ id }) => id === action.payload.groupId
      );

      const tarGroupPl = targetGro.playlists[action.payload.from];
      targetGro.playlists.splice(action.payload.from, 1);
      state.playlists.other.splice(action.payload.to, 0, tarGroupPl);
      break;
    case MOVE_PLAYLIST_FROM_GROUP_TO_PUBLIC:
      const targetGrou = state.playlists?.groups.find(
        ({ id }) => id === action.payload.groupId
      );

      const tarGroupPla = targetGrou.playlists[action.payload.from];
      targetGrou.playlists.splice(action.payload.from, 1);
      state.playlists.public.splice(action.payload.to, 0, tarGroupPla);
      break;
    case PLAYLISTS_FAIL:
      state.loading = false;
      state.error = action.payload;
      break;
    case PLAYLISTS_RESET:
      return {};
    // return { loading: false, error: action.payload };
    default:
      return state;
  }
});

// Save finished and unfinished playlists together. (Uses **Immer**)
// export const playlistsNavbarReducer = produce(
//   (state = { mergedPlaylists: [] }, { type, payload }) => {
//     switch (type) {
//       case PLAYLISTS_SUCCESS:
//         state.mergedPlaylists = payload.playlists?.slice(1);
//         return;
//       case ARRANGE_NAVBAR_PLAYLISTS:
//         const targetPlaylist = state.mergedPlaylists[payload.from];
//         state.mergedPlaylists.splice(payload.from, 1);
//         state.mergedPlaylists.splice(payload.to, 0, targetPlaylist);
//         return;
//       default:
//         return state;
//     }
//   }
// );

export const defaultPlaylistsReducer = (state = {}, action) => {
  switch (action.type) {
    case DEFAULT_PLAYLISTS_REQUEST:
      return { loading: true };
    case DEFAULT_PLAYLISTS_SUCCESS:
      return {
        loading: false,
        defaultPlaylists: action.payload.defaultPlaylists,
      };
    case DEFAULT_PLAYLISTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const playlistByIdReducer = produce((state = {}, action) => {
  switch (action.type) {
    case PLAYLIST_BY_ID_REQUEST:
      state.loading = true;
      break;
    case PLAYLIST_BY_ID_SUCCESS:
      state.loading = false;

      state.playlist = action.payload;
      break;
    case ARRANGE_PLAYLIST_BY_ID:
      const targetPlaylist = state.playlist?.data?.series[action.payload.from];
      state.playlist?.data?.series?.splice(action.payload.from, 1);
      state.playlist?.data?.series?.splice(
        action.payload.to,
        0,
        targetPlaylist
      );
      break;

    case ARRANGE_SERIES_IN_GROUP:
      const _state = state.playlist?.data?.series;
      const { currentSeriesId, destinationId } = action.payload;
      const currentSeries = find(_state, currentSeriesId).videos;
      const destinationSeries = find(_state, destinationId).videos;
      const targetVideo = currentSeries[action.payload.from];

      currentSeries.splice(action.payload.from, 1);
      destinationSeries.splice(action.payload.to, 0, targetVideo);
      break;

    case "ARRANGE_SERIES_IN_GROUP_NO_SORT":
      const series = state.playlist?.data?.series;
      const { sourceSerieId, destinationSerieId } = action.payload;

      const sourceSerie = find(series, sourceSerieId).videos;
      const destSerie = find(series, destinationSerieId).videos;
      const target = sourceSerie[0];
      sourceSerie.splice(0, 1);
      destSerie.splice(0, 0, target);

      // return state;
      break;
    case PLAYLIST_BY_ID_FAIL:
      state.loading = false;
      state.error = action.payload;
      break;
    case PLAYLIST_BY_ID_CLEAR:
      state.loading = false;
      state.playlist = null;
      state.error = null;
      break;
    case PLAYLIST_VIDEO_REORDER:
      const cpVideos = cloneDeep(state.playlist?.data?.videos);
      cpVideos.splice(action.payload.from, 1);
      cpVideos.splice(
        action.payload.to,
        0,
        state.playlist?.data.videos.find(
          (el) => el.id === action.payload.videoId
        )
      );
      const newState = {
        ...state,
        playlist: {
          data: {
            ...state.playlist.data,
            videos: cpVideos,
          },
        },
      };
      return newState;

    default:
      return state;
  }
});

export const playlistCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYLIST_CREATE_REQUEST:
      return { loading: true };
    case PLAYLIST_CREATE_SUCCESS:
      return { loading: false, createdPlaylist: action.payload };
    case PLAYLIST_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PLAYLIST_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const playlistDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYLIST_DELETE_REQUEST:
      return { loading: true };
    case PLAYLIST_DELETE_SUCCESS:
      return { loading: false, message: action.payload };
    case PLAYLIST_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const playlistVideoDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYLIST_VIDEO_DELETE_REQUEST:
      return { loading: true };
    case PLAYLIST_VIDEO_DELETE_SUCCESS:
      return { loading: false, message: action.payload };
    case PLAYLIST_VIDEO_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const playlistVideoReorderReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYLIST_VIDEO_REORDER_REQUEST:
      return { loading: true };
    case PLAYLIST_VIDEO_REORDER_SUCCESS:
      return { loading: false, message: action.payload };
    case PLAYLIST_VIDEO_REORDER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const playlistRelatedVideoReorderReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_RELATED_VIDEO_ORDER_REQUEST:
      return { loading: true };
    case UPDATE_RELATED_VIDEO_ORDER_SUCCESS:
      return { loading: false, message: action.payload };
    case UPDATE_RELATED_VIDEO_ORDER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const playlistVideoMetaDataReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYLIST_VIDEO_METADATA_REQUEST:
      return { loading: true };
    case PLAYLIST_VIDEO_METADATA_SUCCESS:
      return { loading: false, data: action.payload };
    case PLAYLIST_VIDEO_METADATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addVideosToPlaylistReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_VIDEOS_TO_PLAYLIST_REQUEST:
      return { loading: true };
    case ADD_VIDEOS_TO_PLAYLIST_SUCCESS:
      return { loading: false, message: action.payload };
    case ADD_VIDEOS_TO_PLAYLIST_FAIL:
      return { loading: false, error: action.payload };
    case ADD_VIDEOS_TO_PLAYLIST_RESET:
      return {
        loading: false,
        error: null,
        message: "",
      };
    default:
      return state;
  }
};

export const updatePlaylistReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PLAYLIST_REQUEST:
      return { loading: true };
    case UPDATE_PLAYLIST_SUCCESS:
      return { loading: false, message: action.payload };
    case UPDATE_PLAYLIST_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_PLAYLIST_RESET:
      return {};
    default:
      return state;
  }
};

export const updateGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_GROUP_REQUEST:
      return { loading: true };
    case UPDATE_GROUP_SUCCESS:
      return { loading: false, message: action.payload };
    case UPDATE_GROUP_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_GROUP_RESET:
      return {};
    default:
      return state;
  }
};

export const movePlaylistVideoReducer = (state = {}, action) => {
  switch (action.type) {
    case MOVE_PLAYLIST_REQUEST:
      return { loading: true };
    case MOVE_PLAYLIST_SUCCESS:
      return { lodaing: false, message: action.payload };
    case MOVE_PLAYLIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addRelatedVideoReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_RELATED_VIDEO_REQUEST:
      return { loading: true };
    case ADD_RELATED_VIDEO_SUCCESS:
      return { loading: false, message: action.payload };
    case ADD_RELATED_VIDEO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const relatedVideosReducer = (state = {}, action) => {
  switch (action.type) {
    case "RELATED_VIDEOS_REORDER_REQUEST":
      const videos = cloneDeep(state.message);
      // if (!videos) throw new Error("Not Found Any Videos to find")
      const videoEntry = videos.find(
        (video) => video.id === action.payload.relatedVideoId
      );
      // const relatedVideoItem = videoEntry.find(rel => rel.id === action.payload.relatedVideoId);
      videos.splice(action.payload.from, 1);
      videos.splice(action.payload.to, 0, videoEntry);
      return {
        ...state,
        message: videos,
      };
    case RELATED_VIDEOS_REQUEST:
      // const copyRelatedVideos = cloneDeep(state.relatedVideos.message);
      // cpVideos.splice(action.payload.from, 1);
      // cpVideos.splice(
      //     action.payload.to,
      //     0,
      //     state.playlist?.data.videos.find(
      //         (el) => el.id === action.payload.videoId
      //     )
      // );
      // const newState = {
      //     ...state,
      //     playlist: {
      //         data: {
      //             ...state.playlist.data,
      //             videos: cpVideos,
      //         },
      //     },
      // };
      return state;
    case RELATED_VIDEOS_SUCCESS:
      return { loading: false, message: action.payload };
    case RELATED_VIDEOS_FAIL:
      return { loading: false, error: action.payload };
    case RELATED_VIDEOS_CLEAR:
      return { loading: false, message: [] };
    default:
      return state;
  }
};

export const relatedVideoDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_RELATED_VIDEO_REQUEST:
      return { loading: true };
    case DELETE_RELATED_VIDEO_SUCCESS:
      return { loading: false, message: action.payload };
    case DELETE_RELATED_VIDEO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const playlistWithRelatedVideosReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_RELATED_VIDEO_ORDER:
      const videos = cloneDeep(state.videos);
      if (!videos) throw new Error("Not Found Any Videos to find");
      const videoEntry = videos.find(
        (video) => video.playlistable_id === action.payload.videoId
      );
      const relatedVideoItem = videoEntry.related_videos.find(
        (rel) => rel.id === action.payload.relatedVideoId
      );
      videoEntry.related_videos.splice(action.payload.from, 1);
      videoEntry.related_videos.splice(action.payload.to, 0, relatedVideoItem);
      return {
        ...state,
        videos,
      };
    case PLAYLIST_WITH_RELATED_VIDEOS_REQUEST:
      return { loading: true };
    case PLAYLIST_WITH_RELATED_VIDEOS_SUCCESS:
      return { loading: false, videos: action.payload };
    case PLAYLIST_WITH_RELATED_VIDEOS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const searchPlaylistReducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_PLAYLIST_REQUEST:
      return { loading: true };
    case SEARCH_PLAYLIST_SUCCESS:
      return { loading: false, playlists: action.payload };
    case SEARCH_PLAYLIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const searchMyPlaylistsReducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_MY_PLAYLISTS_REQUEST:
      return { loading: true };
    case SEARCH_MY_PLAYLISTS_SUCCESS:
      return { loading: false, data: action.payload };
    case SEARCH_MY_PLAYLISTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const playlistKeywordsReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYLIST_KEYWORDS_REQUEST:
      return { loading: true };
    case PLAYLIST_KEYWORDS_SUCCESS:
      return { loading: false, suggestions: action.payload };
    case PLAYLIST_KEYWORDS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const fetchReportsReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYLIST_REPORTS_REQUEST:
      return { loading: true };
    case PLAYLIST_REPORTS_SUCCESS:
      return { loading: false, reports: action.payload };
    case PLAYLIST_REPORTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addReportReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYLIST_REPORT_ADD_REQUEST:
      return { loading: true };
    case PLAYLIST_REPORT_ADD_SUCCESS:
      return { loading: false, message: action.payload };
    case PLAYLIST_REPORT_ADD_FAIL:
      return { loading: false, error: action.payload };
    case PLAYLIST_REPORT_MODAL_CLOSE:
      return { loading: false, message: "" };
    default:
      return state;
  }
};

export const getPlaylistStatusesReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYLIST_STATUSES_REQUEST:
      return { loading: true };
    case PLAYLIST_STATUSES_SUCCESS:
      return { loading: false, statuses: action.payload };
    case PLAYLIST_STATUSES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const playlistVideoUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYLIST_VIDEO_UPDATE_REQUEST:
      return { loading: true };
    case PLAYLIST_VIDEO_UPDATE_SUCCESS:
      return { loading: false, data: action.payload };
    case PLAYLIST_VIDEO_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateSerieItemDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SERIE_DETAILS_REQUEST:
      return { loading: true };
    case UPDATE_SERIE_DETAILS_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case UPDATE_SERIE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addSerieToDirectShareReducer = (state = {}, action) => {
  switch (action.type) {
    case DIRECT_SHARE_ADD_SERIE_REQUEST:
      return { loading: true };
    case DIRECT_SHARE_ADD_SERIE_SUCCESS:
      return { loading: false, data: action.payload };
    case DIRECT_SHARE_ADD_SERIE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addVideoToDirectShareReducer = (state = {}, action) => {
  switch (action.type) {
    case DIRECT_SHARE_ADD_VIDEO_REQUEST:
      return { loading: true };
    case DIRECT_SHARE_ADD_VIDEO_SUCCESS:
      return { loading: false, data: action.payload };
    case DIRECT_SHARE_ADD_VIDEO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createPlaylistGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_GROUP_REQUEST:
      return { loading: true };
    case CREATE_GROUP_SUCCESS:
      return { loading: false, data: action.payload };
    case CREATE_GROUP_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_GROUP_RESET:
      return {};
    default:
      return state;
  }
};

export const arrangeFollowedPlaylistsReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOWED_PLAYLISTS_ARRANGE_REQUEST:
      return { loading: true };
    case FOLLOWED_PLAYLISTS_ARRANGE_SUCCESS:
      return { loading: false, data: action.payload };
    case FOLLOWED_PLAYLISTS_ARRANGE_FAIL:
      return { loading: false, error: action.payload };
    case FOLLOWED_PLAYLISTS_ARRANGE_LOCAL:
      return {
        dataLocal: action.payload,
      };
    default:
      return state;
  }
};

export const playlistsArrangeReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYLISTS_ARRANGE_REQUEST:
      return { loading: true };
    case PLAYLISTS_ARRANGE_SUCCESS:
      return { loading: false, data: action.payload };
    case PLAYLISTS_ARRANGE_FAIL:
      return { loading: false, error: action.payload };
    case PLAYLISTS_ARRANGE_LOCAL:
      return { dataLocal: action.payload };
    default:
      return state;
  }
};


export const notFollowedPlaylistsHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case NOT_FOLLOWED_PLAYLIST_HISTORY_REQUEST:
      return { loading: true }
    case NOT_FOLLOWED_PLAYLIST_HISTORY_SUCCESS:
      return { loading: false, data: action.payload }
    case NOT_FOLLOWED_PLAYLIST_HISTORY_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state;
  }
}


// Selector Functions
export const selectVideoDeleteStatus = (state) => state.playlistVideoDelete;

export const selectPlaylists = (state) => {
  const { loading, playlists, error } = state.playlists;
  return {
    loading,
    error,
    playlists: playlists || null,
  };
};

export const selectPlaylistById = (state) => {
  return state?.playlistById?.playlist?.data;
};

export const selectVideoRelatedVideos = createSelector(
  selectPlaylistById,
  (playlistById) => {
    if (!playlistById) return {};

    const groupedRelatedVideos = groupBy(
      "playlistable_id",
      playlistById.relatedVideos,
      (relatedVideo) => relatedVideo.related_videos
    );

    const flat = {};

    for (let key in groupedRelatedVideos) {
      flat[key] = groupedRelatedVideos[key].flat();
    }

    return flat;
  }
);


export const selectAddSerieToDirectShare = (state) => {
  return state.addSeriesToDirectShare;
};

export const selectAddVideoToDirectShareReducer = (state) => {
  return state.addVideoToDirectShare;
};
// // Return finished and unfinished playlists merged together
// export const selectPlaylistsWithoutWatchLater = (state) => {
//   return state.playlistsNavbar?.mergedPlaylists;
// };
