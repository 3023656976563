import React, { useContext, useReducer } from "react";
import { Context } from "@context/context";

import Checkbox from "@components/shared/atoms/Checkbox";
import Modal from "@shared/atoms/Modal/index";
import { Paragraph } from "@shared/atoms/Typography/index";
import { localStorageService } from "@modules/services/localStorage.service";

import { LOCALSTORAGE } from "const";
import heartDimensionImage from "@assets/images/heart-dimension.png";

import Image from "@components/shared/atoms/Image";

import "./index.less";

const CopyInHeartModal = () => {
  const {
    showCopyInHeartDimensionModal,
    setShowCopyInHeartDimensionModal
  } = useContext(Context);
  const [isCheckboxChecked, toggleCheckbox] = useReducer((state) => !state, false)

  const closeHandler = () => {
    setShowCopyInHeartDimensionModal(false);
    if(isCheckboxChecked) {
        localStorageService().set(LOCALSTORAGE.HIDE_COPY_IN_HEART_DIMENSION_MODAL, true);
    } else {
        localStorageService().set(LOCALSTORAGE.HIDE_COPY_IN_HEART_DIMENSION_MODAL, false);
    }
  };
  
  return (
    <Modal
      visible={showCopyInHeartDimensionModal}
      className="modal-basic-prototype copy-in-hart-modal"
      mask={false}
      footer={<></>}
      closable={true}
      onCancel={() => {
        closeHandler();
      }}
    >
      <div>
        <Paragraph>
          you can save a copy in <Image width={40} src={heartDimensionImage} alt="settings" />
        </Paragraph>
        <div className="checkbox-text-container">
          <Checkbox checked={isCheckboxChecked} onChange={toggleCheckbox}>
            Do not show again
          </Checkbox>
        </div>
      </div>
    </Modal>
  );
};

export default CopyInHeartModal;
