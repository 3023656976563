import React, { useLayoutEffect, useState } from "react";
import useWindowSize from "@src/hooks/useWindowSize";

import LogoBlue from "@assets/images/choice-logo-blue.svg";
import LoginGreeting from "@shared/molecules/LoginGreeting/index";
import Image from "@shared/atoms/Image/index";
import "./index.less";
import RegisterInfo from "@shared/molecules/RegisterInfo";

const AuthHeading = ({
    isRegistrationPage = false,
    hideLogoOnSmallScreen,
    // isSendConfirmationCode = false,
}) => {
    const [hideExtraText, setHideExtraText] = useState(false);

    let windowSize = useWindowSize();

    useLayoutEffect(() => {
        if (windowSize.height <= 768) {
            setHideExtraText(true);
        }
    }, [windowSize]);

    return (
        <div className="auth-heading-container">
            <Image
                preview={false}
                className={`choice-logo blue-logo ${
                    hideLogoOnSmallScreen ? "hide-logo" : ""
                }`}
                src={LogoBlue}
                alt="blue-logo"
            />
            {isRegistrationPage && (
                <RegisterInfo hideExtraText={hideExtraText} />
            )}
            {!isRegistrationPage && <LoginGreeting />}
        </div>
    );
};

export default AuthHeading;
