import React, { useContext, useEffect, useState } from "react";
import Modal from "@shared/atoms/Modal";
import Input from "@shared/atoms/Input";
import { Title } from "@shared/atoms/Typography";
import SearchSuggestionsListPrototype from "@components/organisms/lists/SearchSuggestionsListPrototype";
import { Context } from "../../../../../context/context";
import { useDispatch, useSelector } from "react-redux";

import {
  getAuthorizedSearch,
  getUnauthorizedSearch,
  selectAuthorizedSearch,
  selectUnauthorizedSearch,
} from "@redux/actions/searchActions";
import {
  SEARCH_AUTHORIZED_RESET,
  SEARCH_UNAUTHORIZED_RESET,
} from "@redux/constants/searchConstants";
import { useLocation } from "react-router-dom";
import { localStorageService } from "@modules/services/localStorage.service";
import debounce from "lodash/debounce";

import "./index.less";

const SearchModalPrototype = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isLoggedIn = !!localStorageService().get("access_token");
  const [searchType, setSearchType] = useState("all");

  const {
    showSearchModalPrototype,
    setShowSearchModalPrototype,
    searchedWordPrototype,
    setSearchedWordPrototype,
  } = useContext(Context);

  useEffect(() => {
    if (!searchedWordPrototype && location && location.search) {
      setSearchedWordPrototype(location.search?.split("&")[1]?.split("=")[1]);
    }
  }, [searchedWordPrototype, location, setSearchedWordPrototype, dispatch]);

  const { data: authorizedSearchData } = useSelector(selectAuthorizedSearch);
  const { data: unauthorizedSearchData } = useSelector(
    selectUnauthorizedSearch
  );

  const handleClose = () => {
    setShowSearchModalPrototype(false);
    isLoggedIn
      ? dispatch({ type: SEARCH_AUTHORIZED_RESET })
      : dispatch({ type: SEARCH_UNAUTHORIZED_RESET });
  };

  const handleSearch = (e) => {
    setSearchedWordPrototype(e.target.value);
    if (e.target.value) {
      isLoggedIn
        ? dispatch(getAuthorizedSearch(e.target.value, searchType))
        : dispatch(getUnauthorizedSearch(e.target.value));
    }
  };

  const handleSearchDebounced = debounce((e) => {
    if(e.target.value.length >= 3) handleSearch(e);
  }, 300);

  return (
    <Modal
      className={`ant-modal-prototype search-modal-prototype ${
        isLoggedIn ? "height-25" : "height-15"
      }`}
      destroyOnClose={true}
      onCancel={handleClose}
      visible={showSearchModalPrototype}
      maskClosable={false}
      closable={false}
      mask={false}
      wrapClassName={`search-modal-prototype-wrap ${
        isLoggedIn ? "height-21" : "height-15"
      }`}
      footer=""
    >
      <Title className="modal-title">Search</Title>
      <Input
        className="search-input"
        placeholder="..."
        onChange={(e) => handleSearchDebounced(e)}
      />
      <SearchSuggestionsListPrototype
        data={isLoggedIn ? authorizedSearchData : unauthorizedSearchData}
        searchWord={searchedWordPrototype}
        searchType={searchType}
        setSearchType={setSearchType}
      />
    </Modal>
  );
};

export default SearchModalPrototype;
