import React, {
  forwardRef,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { Context } from "@context/context";
import VideoPlayerControls from "../../shared/molecules/VideoPlayerControls";
import { viewEnd } from "@redux/actions/viewActions";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import "./index.less";

const VideoPlayerWrapper = (
  { title, videoUrl, className = "", setVideoUrl, children },
  ref
) => {
  const topControlRef = useRef(null);

  const dispatch = useDispatch();

  const { message: viewMsg } = useSelector((state) => state.view);

  const {
    setShowChannelsPrototype,
    setShowVideoPlayerPrototype,
    tabletFrameRef,
    tabletPrototypeRef,
  } = useContext(Context);

  const topControlsHandler = () => {
    if (topControlRef) {
      topControlRef.current.style.transform = "translateY(0)";

      setTimeout(() => {
        if (topControlRef && topControlRef.current) {
          topControlRef.current.style.transform = "translateY(-5.5rem)";
        }
      }, 2500);
    }
  };

  const onClosePlayer = useCallback(() => {
    if (ref) {
      setShowChannelsPrototype(true);
      // if (ref.current.style && ref.current.style.display ) ref.current.style.display = "none";
      setVideoUrl("");
      setShowVideoPlayerPrototype(false);
      dispatch(viewEnd(viewMsg?.viewActionId));

      // if (tabletFrameRef.current && tabletPrototypeRef.current) {
      //   tabletFrameRef.current.style.overflow = "scroll";
      //   tabletPrototypeRef.current.style.overflow = "scroll";
      // }
    }
  }, [
    dispatch,
    ref,
    setShowChannelsPrototype,
    setShowVideoPlayerPrototype,
    setVideoUrl,
    viewMsg?.viewActionId,
  ]);

  useEffect(() => {
    return () => {
      onClosePlayer();
    };
  }, [onClosePlayer]);

  return (
    <div
      ref={ref}
      className={`video-player-wrapper ${
        isMobile ? "mobile" : ""
      } ${className} `}
      onMouseEnter={() => topControlsHandler()}
      onTouchStart={() => topControlsHandler()}
    >
      {children}
      <VideoPlayerControls
        ref={topControlRef}
        onClosePlayer={onClosePlayer}
        title={title}
        videoUrl={videoUrl}
      />
    </div>
  );
};

const forwarderVideoPlayerWrapper = forwardRef(VideoPlayerWrapper);

export default forwarderVideoPlayerWrapper;
