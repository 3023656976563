import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Input } from "antd";
import Button from "@shared/atoms/Button/index";
import Modal from "@shared/atoms/Modal/index";
import CloseModal from "../CloseModal/index";
import comparePasswords from "@utils/comparePasswords";
import { useTranslation } from "react-i18next";
import { confirmResetPassword } from "../../../../../redux/actions/userActions";
import { useParams } from "react-router-dom";
import Alert from "../../../shared/atoms/Alert";

const ForgetPasswordModal = ({
  isPasswordModalVisible,
  handleClosePassword,
  formLayout,
}) => {
  const { email, token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [warning, setWarning] = useState("");
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    setDisableBtn(true);
    if (!(email && token)) {
      handleClosePassword();
    }
  }, [email, token, handleClosePassword]);

  useEffect(() => {
    let isSamePasswords = comparePasswords(password, confirmPassword);
    if (isSamePasswords) {
      setWarning("");
      setDisableBtn(false);
    } else {
      isSamePasswords !== undefined && setWarning("Passwords don't match");
      setDisableBtn(true);
    }
  }, [password, confirmPassword]);

  const submitPassword = () => {
    dispatch(confirmResetPassword(email, token, password, confirmPassword));
    handleClosePassword();
  };

  return (
    <Modal
      title={t("reset_your_password")}
      visible={isPasswordModalVisible}
      closable={true}
      onCancel={handleClosePassword}
      footer={[
        <Button
          disabled={disableBtn}
          type="primary"
          key="back"
          onClick={submitPassword}
        >
          {t("reset")}
        </Button>,
        <CloseModal handleClose={handleClosePassword} />,
      ]}
    >
      <Form layout={formLayout}>
        {warning && <Alert message={warning} closable={false} />}
        <Form.Item label={t("password")}>
          <Input.Password
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t("enter_password")}
          />
        </Form.Item>
        <Form.Item label={t("confirm_password")}>
          <Input.Password
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder={t("confirm_password")}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ForgetPasswordModal;
