import React, { useContext } from "react";
import AntdModal from "antd/lib/modal/Modal";
import prototypeCloseIcon from "../../../../../assets/icons/closeIconPrototype.svg";
import choiceLogo from "@assets/icons/choice-icon-white.png";
import Image from "../Image";
import { Context } from "@context/context";
import { isMobile } from "react-device-detect";

import "./index.less";

const CloseIcon = () => <img src={prototypeCloseIcon} alt="" />;


const Modal = ({ children, mask = false, className = "ant-modal", ...rest }) => {
  const { modalTypeAuthPrototype } = useContext(Context)

  return (
    <AntdModal
      getContainer={!isMobile ? ".tablet-prototype" : "html"}
      mask={mask}
      className={className}
      {...(className === "ant-modal-prototype"
        ? { icon: CloseIcon, width: "100%", closeable: true, mask: false }
        : {})}
      {...rest}
    >
      {modalTypeAuthPrototype === "Register" && <Image src={choiceLogo} className="choice-logo" />}
      {children}
    </AntdModal>
  );
};

export default Modal;
