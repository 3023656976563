import { CHOICE_URL } from "@modules/config";
import { axiosInstance } from "@modules/request/api";
import {
  CREATE_TEAM_FAIL,
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  GET_TEAMS_FAIL,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  GET_TEAM_REQUEST,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAIL,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAIL,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAIL,
  TOGGLE_TEAM_ADMIN_REQUEST,
  TOGGLE_TEAM_ADMIN_SUCCESS,
  TOGGLE_TEAM_ADMIN_FAIL,
  DELETE_TEAM_USER_REQUEST,
  DELETE_TEAM_USER_SUCCESS,
  DELETE_TEAM_USER_FAIL,
  APPROVE_TEAM_REQUEST,
  APPROVE_TEAM_SUCCESS,
  APPROVE_TEAM_FAIL,
  LEAVE_TEAM_REQUEST,
  LEAVE_TEAM_SUCCESS,
  LEAVE_TEAM_FAIL,
  DELETE_TEAM_PLAYLIST_FAIL,
  DELETE_TEAM_PLAYLIST_REQUEST,
  DELETE_TEAM_PLAYLIST_SUCCESS,
  APPROVE_DELETE_ADMIN_REQUEST,
  APPROVE_DELETE_ADMIN_SUCCESS,
  APPROVE_DELETE_ADMIN_FAIL,
} from "@redux/constants/TeamConstants";

export const createTeam = (newTeamData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_TEAM_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/teams`,
      newTeamData
    );
    dispatch({ type: CREATE_TEAM_SUCCESS, payload: data });

    return data;
  } catch (error) {
    dispatch({ type: CREATE_TEAM_FAIL, payload: error.response });

    throw error.response;
  }
};

export const updateTeam = (id, newTeamData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TEAM_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/teams/${id}`,
      newTeamData
    );

    dispatch({ type: UPDATE_TEAM_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({ type: UPDATE_TEAM_FAIL, payload: error.response });

    throw error.response;
  }
};

export const getTeamsAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_TEAMS_REQUEST });
    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/teams`);
    dispatch({ type: GET_TEAMS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_TEAMS_FAIL, payload: error.response });
  }
};

export const getTeam = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEAM_REQUEST,
    });

    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/teams/${id}`);

    dispatch({
      type: GET_TEAM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: GET_TEAM_FAIL, payload: error.response });
  }
};

export const deleteTeam = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_TEAM_REQUEST,
    });

    const { data } = await axiosInstance().delete(
      `${CHOICE_URL}/api/teams/${id}`
    );

    dispatch({
      type: DELETE_TEAM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: DELETE_TEAM_FAIL, payload: error.response });
  }
};

export const deleteTeamUser = (team_id, user_id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_TEAM_USER_REQUEST,
    });

    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/teams/delete-user/${team_id}/${user_id}`
    );

    dispatch({
      type: DELETE_TEAM_USER_SUCCESS,
      payload: data,
    });

    dispatch(getTeam(team_id));
  } catch (error) {
    dispatch({ type: DELETE_TEAM_USER_FAIL, payload: error.response });
  }
};

export const approveTeamInvitation = (id, is_rejected) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVE_TEAM_REQUEST,
    });

    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/teams/user-approval/${id}`,
      { is_rejected }
    );

    dispatch({
      type: APPROVE_TEAM_SUCCESS,
      payload: data,
    });

    return data;
  } catch (error) {
    dispatch({ type: APPROVE_TEAM_FAIL, payload: error.response });
  }
};

export const toggleTeamAdmin =
  (teamId, userId, isAdmin) => async (dispatch) => {
    try {
      dispatch({
        type: TOGGLE_TEAM_ADMIN_REQUEST,
      });

      const userData = {
        is_admin: isAdmin,
        user_id: userId,
      };

      const { data } = await axiosInstance().post(
        `${CHOICE_URL}/api/teams/admin-toggle/${teamId}`,
        userData
      );

      dispatch({
        type: TOGGLE_TEAM_ADMIN_SUCCESS,
        payload: data,
      });

      // dispatch({
      //   type: GET_TEAM_REQUEST
      // });

      return data;
    } catch (error) {
      dispatch({ type: TOGGLE_TEAM_ADMIN_FAIL, payload: error.response });
    }
  };

export const leaveTeamAction =
  (teamId, force = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: LEAVE_TEAM_REQUEST,
      });

      const { data } = await axiosInstance().post(
        `${CHOICE_URL}/api/teams/leave/${teamId}`,
        { force }
      );

      dispatch({
        type: LEAVE_TEAM_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: LEAVE_TEAM_FAIL, payload: error.response.data });
    }
  };

export const deleteTeamPlaylistAction =
  (teamId, playlistId) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_TEAM_PLAYLIST_REQUEST,
      });

      const { data } = await axiosInstance().delete(
        `${CHOICE_URL}/api/teams/playlist/${teamId}/${playlistId}`
      );

      dispatch({
        type: DELETE_TEAM_PLAYLIST_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({
        type: DELETE_TEAM_PLAYLIST_FAIL,
        payload: error.response.data,
      });
    }
  };

export const approveDeleteFromAdmin =
  (teamId, user_id, is_approved) => async (dispatch) => {
    try {
      dispatch({
        type: APPROVE_DELETE_ADMIN_REQUEST,
      });

      const { data } = await axiosInstance().post(
        `${CHOICE_URL}/api/teams/derank-admin/${teamId}`,
        {
          user_id,
          is_approved,
        }
      );

      dispatch({
        type: APPROVE_DELETE_ADMIN_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({
        type: APPROVE_DELETE_ADMIN_FAIL,
        payload: error.response.data,
      });
    }
  };
