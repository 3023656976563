import React, { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../../../../../context/context";
import Modal from "@shared/atoms/Modal";
import Alert from "@shared/atoms/Alert/index";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getMetaData } from "../../../../../redux/actions/metaDataActions";
import { URL_REGEX } from "../../../../../const/regex";
import { addNewSeries, getPlaylistById } from "@redux/actions/playlistsActions";
import querystring from "query-string";
import { useLocation } from "react-router-dom";
import { META_DATA_RESET } from "@redux/constants/metaDataConstants";
import { ADD_VIDEOS_TO_PLAYLIST_RESET } from "@redux/constants/playlistsConstants";
import EditableImage from "@components/organisms/EditableImage";

import "./index.less";
import Upload from "@components/shared/atoms/Upload";
import SpinnerPrototype from "@components/shared/atoms/SpinnerPrototype";

export default function AddPlaylistItemModalPrototype() {
  const { t } = useTranslation();
  const {
    showAddPlaylistItemModalPrototype,
    setShowAddPlaylistItemModalPrototype,
    selectedPlaylist,
    currentPlaylistIdPrototype,
    uploadedImageSize,
  } = useContext(Context);
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState("");
  const [descr, setDescr] = useState("");
  const [seriesName, setSeriesName] = useState("");

  const [sizeError, setSizeError] = useState(false);
  // const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedVideoName, setUploadedVideoName] = useState("");
  const [uploadedVideo, setUploadedVideo] = useState(null);

  const [blobImg, setBlobImg] = useState(null);

  const [isLinkDisabled, setIsLinkDisabled] = useState(false);
  const [isUploadVideoDisabled, setIsUploadVideoDisabled] = useState(false);

  const { search } = useLocation();

  const parsedQueryString = querystring.parse(search);

  const dispatch = useDispatch();

  const { data: metaData } = useSelector((state) => state.metadata);
  const { profile } = useSelector((state) => state.userProfile);

  const {
    error: addVideoError,
    message: addVideoSuccess,
    loading: addVideoLoading,
  } = useSelector((state) => state.addVideosToPlaylist);

  const updateState = useCallback(() => {
    setDescr(metaData?.description);
    setIcon(metaData?.image);
    setTitle(metaData?.title);
  }, [metaData?.description, metaData?.image, metaData?.title]);

  useEffect(() => {
    updateState();
  }, [updateState]);

  useEffect(() => {
    if (addVideoSuccess) {
      setShowAddPlaylistItemModalPrototype(false);
      resetInputs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addVideoSuccess, setShowAddPlaylistItemModalPrototype]);

  const linkChangeHandler = (e) => {
    setLink(e.target.value);
    if (e.target.value) {
      setIsUploadVideoDisabled(true);
    } else {
      setIsUploadVideoDisabled(false);
    }
  };

  const onLinkInputBlur = () => {
    if (link && link.match(URL_REGEX)) {
      dispatch(getMetaData(link));
      updateState();
    }
  };

  const resetInputs = () => {
    dispatch({ type: META_DATA_RESET });
    // setLink("");
    // setSeriesName("");
    setLink("");
    setTitle("");
    setIcon("");
    setDescr("");
    setSeriesName("");
    setUploadedVideoName("");
    setIsUploadVideoDisabled(false);
    setIsLinkDisabled(false);
  };

  const uploadVideoFile = (e) => {
    if (e.target.files[0]) {
      setUploadedVideoName(e.target.files[0].name);
      setUploadedVideo(e.target.files[0]);
      setIsLinkDisabled(true);
    } else {
      setUploadedVideoName("");
      setIsLinkDisabled(false);
    }
  };

  const addItemToPlaylistHandler = () => {
    if (uploadedImageSize > 1400000) {
      setSizeError(true);
      return;
    }

    let fd = new FormData();
    let data = Object.assign(
      {},
      descr && { description: descr },
      icon && !blobImg && { image: icon },
      title && { title },
      link && { src: link }
    );
    const videos = [data];

    fd.append(
      "playlist_id",
      parsedQueryString.playlist || currentPlaylistIdPrototype
    );
    if (seriesName) fd.append("name", seriesName);
    if (blobImg) fd.append("videos[0][image]", blobImg || "");
    if (uploadedVideo) fd.append("videos[0][src]", uploadedVideo);
    videos.forEach((video, index) => {
      Object.entries(video).forEach(([property, value]) => {
        fd.append(`videos[${index}][${property}]`, value);
      });
    });

    Promise.resolve(dispatch(addNewSeries(fd))).then(() => {
      if (selectedPlaylist.id) {
        dispatch(getPlaylistById(selectedPlaylist.id));
      }
      // resetInputs();
    });
  };

  const onCloseModal = () => {
    setShowAddPlaylistItemModalPrototype(false);
    dispatch({ type: ADD_VIDEOS_TO_PLAYLIST_RESET });
    setSizeError(false);
    resetInputs();
  };

  return (
    <div>
      {/* {(metaDataLoading || addVideoLoading) && (
        <SpinnerPrototype withOverlay={true} size={4} />
      )} */}
      <Modal
        title={t("add_series")}
        className="add-playlist-item-modal-prototype"
        mask={true}
        visible={showAddPlaylistItemModalPrototype}
        onCancel={onCloseModal}
        destroyOnClose={true}
        footer={
          <button onClick={addItemToPlaylistHandler} disabled={addVideoLoading}>
            {addVideoLoading && (
              <SpinnerPrototype className="btn-spinner" size={1.8} />
            )}
            {t("create")}
          </button>
        }
      >
        {addVideoError && (
          <Alert
            message={
              addVideoError?.data.message +
              " " +
              addVideoError?.data.errors[
                Object.keys(addVideoError?.data.errors)[0]
              ]
            }
            type="error"
          />
        )}

        <div className="form-control">
          <label>{t("link")} (url)</label>
          <input
            value={link}
            onChange={linkChangeHandler}
            onBlur={onLinkInputBlur}
            disabled={isLinkDisabled}
          />
        </div>
        {profile?.data?.can_upload_video && (
          <>
            <div className="form-control or">or</div>
            <div className="form-control">
              <label className="online-img-label">{t("upload_video")}</label>
              <input
                className="video-upload"
                data-content={`Upload video ${uploadedVideoName}`}
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                onChange={uploadVideoFile}
                disabled={isUploadVideoDisabled}
              />
            </div>
          </>
        )}

        <div className="form-control">
          <label>{t("title")}</label>
          <input value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="form-control">
          <label>{t("series_name")}</label>
          <input
            value={seriesName}
            onChange={(e) => setSeriesName(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label>{t("Picture")}</label>
        </div>
        <EditableImage
          setBlobImg={setBlobImg}
          setSizeError={setSizeError}
          btnText={t("upload_image")}
          shape="rectangle"
          aspect={3 / 2}
        />

        {/* <UploadFilePrototype
          setIcon={setSelectedFile}
          isNewItem={true}
          setSizeError={setSizeError}
          placeholder={t("upload_series_image")}
        /> */}
        <div className="form-control">
          <label>{t("description")}</label>
          <textarea
            style={{ height: "10rem" }}
            value={descr}
            onChange={(e) => setDescr(e.target.value)}
          />
        </div>
      </Modal>
    </div>
  );
}
