import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Title, Paragraph } from "../../atoms/Typography/index";

const RegisterInfo = ({ hideExtraText }) => {
    const { t } = useTranslation();
    return (
        <>
            <Title level={2}>{t("register_for_tv_curated_by_you")}.</Title>
            <Paragraph className="register-para">
                {hideExtraText ? (
                    <span> {t("learn_more_about_our")}</span>
                ) : (
                    <span> {t("register_info_1")}</span>
                )}
                <Link to={"/privacy"} target="_blank" rel="noopener noreferrer">
                    {" "}
                    {t("privacy_policy")}
                </Link>
            </Paragraph>
        </>
    );
};

export default RegisterInfo;
