import React, { useEffect, useState } from "react";
import { localStorageService } from "@modules/services/localStorage.service";
import AcceptCookiesModal from "../modals/AcceptCookiesModalPrototype";
import { useSelector } from "react-redux";
import {
  selectHomeContent,
  selectHomeGlobeContent,
  selectHomeHeartContent,
  selectHomeTiersContent,
  selectHomeUserContent,
} from "@redux/reducers/homeReducers";
import usePathName from "hooks/usePathName";
// import Spacer from "@components/shared/atoms/Spacer";
import HomeWelcome from "@components/shared/molecules/HomeWelcome";
import { PAGES } from "const/pages";
import AddWebsiteToHomeScreenModalPrototype from "../modals/AddWebsiteToHomeScreenModalPrototype";
import iphoneHomeScreenLogo from "@assets/icons/iphone-add-to-home-screen.png";
import androidHomeScreenLogo from "@assets/icons/android-add-to-home-screen.png";
import { isIOS, isMobile } from "react-device-detect";

import "./index.less";

let isExpired;
if (localStorageService().get("add_to_home_screen_show_time")) {
  const showTime = Date.parse(
    localStorageService().get("add_to_home_screen_show_time")
  );
  if (showTime - new Date().getTime() > 0) {
    isExpired = false;
  }
} else {
  isExpired = true;
}

const hideAddToHomeScreen = JSON.parse(
  localStorageService().get("hide_add_to_home_screen")
);


const HomeContentPrototype = () => {
  const [isCookieModalHidden, setIsCookieModalHidden] = useState(
    !!localStorageService().get("cookies")
  );
  const [showAddWebsiteToHomeScreenModal, setShowAddWebsiteToHomeScreenModal] =
    useState(false);

  const { homeData } = useSelector(selectHomeContent);
  const { globeData } = useSelector(selectHomeGlobeContent);
  const { heartData } = useSelector(selectHomeHeartContent);
  const { tierData } = useSelector(selectHomeTiersContent);
  const { userData } = useSelector(selectHomeUserContent);

  const { profile } = useSelector((state) => state.userProfile);
  const currentPage = usePathName();

  useEffect(() => {
    if (isCookieModalHidden && isMobile && isExpired && !hideAddToHomeScreen) {
      setShowAddWebsiteToHomeScreenModal(true);
    }

    if (
      localStorageService().get("add_to_home_screen_show_time") ||
      JSON.parse(localStorageService().get("hide_add_to_home_screen"))
    ) {
      setShowAddWebsiteToHomeScreenModal(false);
    }
  }, [isCookieModalHidden]);
  return (
    <>
      <AddWebsiteToHomeScreenModalPrototype
        logo={isIOS ? iphoneHomeScreenLogo : androidHomeScreenLogo}
        isVisible={showAddWebsiteToHomeScreenModal}
        setIsVisible={setShowAddWebsiteToHomeScreenModal}
      />

      {!isCookieModalHidden && (
        <AcceptCookiesModal close={() => setIsCookieModalHidden(true)} />
      )}
      {/* {["playlists", "my-playlists", "tiers", "settings"].includes(currentPage) && (
        <Spacer />
      )} */}
      <div className="homepage-container">
        {currentPage === PAGES.root && (
          <HomeWelcome data={homeData?.home} show />
        )}
        {currentPage === PAGES.playlists && globeData && (
          <HomeWelcome
            data={globeData.home}
            playlistData={globeData.home.playlist}
            show={!profile?.data?.hide_globe_welcome}
          />
        )}
        {currentPage === PAGES.myPlaylists && heartData && (
          <HomeWelcome
            data={heartData.home}
            playlistData={heartData.home.playlist}
            show={!profile?.data?.hide_heart_welcome}
          />
        )}
        {currentPage === PAGES.tiers && tierData && (
          <HomeWelcome
            data={tierData?.home}
            playlistData={tierData?.home?.playlist}
            show={!profile?.data?.hide_tv_welcome}
          />
        )}
        {currentPage === PAGES.settings && userData && (
          <HomeWelcome
            data={userData?.home}
            playlistData={userData?.home?.playlist}
            show={!profile?.data?.hide_user_welcome}
          />
        )}
      </div>
    </>
  );
};

export default HomeContentPrototype;
