import {
  CLEAR_INVALID_COUNTRY_ERROR,
  COUNTRY_REQUEST,
  COUNTRY_REQUEST_FAIL,
  COUNTRY_REQUEST_SUCCESS,
  SET_INVALID_COUNTRY_ERROR,
} from "../constants/countryConstants";
import { CHOICE_URL } from "@modules/config";
import { axiosInstance } from "../../modules/request/api";

export const getCountries = () => async (dispatch) => {
  dispatch({ type: COUNTRY_REQUEST });

  try {
    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/countries`);

    dispatch({ type: COUNTRY_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COUNTRY_REQUEST_FAIL, payload: error.message });
  }
};

export const setInvalidCountryError = () => ({
  type: SET_INVALID_COUNTRY_ERROR,
});

export const clearInvalidCountryError = () => ({
  type: CLEAR_INVALID_COUNTRY_ERROR,
});
