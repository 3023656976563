import React, {
  useContext,
  useEffect,
  useReducer,
  useCallback,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import List from "@shared/atoms/List/index";
import SubBarItemPrototype from "@shared/molecules/listItems/SubBarItemPrototype";
import { getPlaylists } from "../../../../../redux/actions/playlistsActions";
import SpinnerPrototype from "../../../shared/atoms/SpinnerPrototype";
import { useHistory, useLocation } from "react-router-dom";
import { Context } from "../../../../../context/context";

// import PublicContent from "@assets/icons/public_content.svg";
// import RestrictedContent from "@assets/icons/restricted_content.svg";
import AddItem from '@assets/icons/add_item.svg'

import ArrangeMode from "@components/shared/molecules/ArrangeMode";
import GroupArrangeMode from "@components/shared/molecules/ArrangeGroup";
import ArrangeShortcutsMode from "@components/shared/molecules/ArrangeShortcutsMode";
import { selectPlaylists } from "@redux/reducers/playlistsReducer";
// import { useTranslation } from "react-i18next";
// import { EPG_CHANNELS_RESET } from "@redux/constants/channelConstants";
import defaultIcon from "@assets/icons/default-playlist-icon.svg";
import archiveIcon from "@assets/icons/archive.jpeg";
import HomePositionPrototype from "@components/shared/molecules/HomePositionPrototype";
// import { getTiers } from "@redux/actions/paymentActions";
import CreatePlaylistModalPrototype from "@components/organisms/modals/CreatePlaylistModalPrototype";
// import ArrangeOpenableMode from "@components/shared/molecules/ArrangeOpenableMode";
import { useContextMenu } from "hooks/useContextMenu";
import { saveLatestPlaylistId } from "@utils/saveLatestPlaylistId";
import querystring from "query-string";
import { DroppableTypes } from "@context/dndContextPrototype";
import { Droppable } from "react-beautiful-dnd";
import ArrangeOpenableMode from "@components/shared/molecules/ArrangeOpenableMode";
import { getCopiedTiers } from "@redux/actions/shortcutActions";
import { VIEWS_PROTOTYPE } from "const/viewsPrototype";
import { useAccountMode } from "hooks";
import ShortcutSubbarItem from "@components/shared/molecules/listItems/SubBarItemPrototype/ShortcutSubbarItem";
import MyPlaylistSubbarItem from "@components/shared/molecules/listItems/SubBarItemPrototype/MyPlaylistSubbarItem";

import "./index.less";
import SubbarItem from "@components/shared/molecules/listItems/SubBarItemPrototype/SubbarItem";
import StaticSubbarItem from "@components/shared/molecules/listItems/SubBarItemPrototype/StaticSubbarItem";
import SimplePopupWithSingleButton from "@components/organisms/modals/SimplePopupWithButton";

const PlaylistListPrototype = () => {
  const dispatch = useDispatch();
  // const tierRef = createRef(null);
  // const [openRestrictedTier, setOpenRestrictedTier] = useState(false);
  // const [openPublicTier, setOpenPublicTier] = useState(false);
  const [openedGroupId, setOpenedGroupId] = useState();
  const [archiveState, toggleArchive] = useReducer((state) => !state, false);
  // const [indexOfNewPlaylist, setIndexOfNewPlaylist] = useState(null);
  const [currentArchiveGroupId, setCurrentArchiveGroupId] = useState(null);
  const [archiveFullOpenedDataLength, setArchiveFullOpenedDataLength] =
    useState(null);
  const [isDirectShareWarningShown, setIsDirectShareWarningShown] = useState(false);

  const {
    setShowTermsImagesPrototype,
    setCurrentViewPrototype,
    setShowSubBarPrototype,
    isCopyingItem,
    // setTierPrototype,
    setShowChannelsPrototype,
    // tierListOpened,
    // setTierListOpened,
    // isOpenableArrangeMode,
    // isRestrictedOpenableArrangeMode,
    setShowAddPlaylistModalPrototype,
    setShowConfirmPasteModalPrototype,
    setCurrentPlaylistIdPrototype,
    setIsEditMode
  } = useContext(Context);

  const {
    isVisible,
    closeContextMenu,
    xPos,
    yPos,
    // targetElementId,
    openContextMenu,
    withCloseContextMenu,
    contextMenuClickWithDebounce,
    onTouchEnd,
  } = useContextMenu(".home-position-prototype");

  const history = useHistory();
  const { search } = useLocation();
  const { isOwnMode, isTeamMode } = useAccountMode()

  const parsedQueryString = querystring.parse(search);
  const playlistIdFromUrl = parsedQueryString.playlist;

  // const { t } = useTranslation();

  const { playlists, loading: loadingPlaylists } = useSelector(selectPlaylists);
  const teamPlaylist = useSelector((state) => state.teamItem?.data?.data?.playlists)
  const { dataLocal: playlistsLocal } = useSelector(
    (state) => state.playlistsArrange
  );
  const { copiedTiers } = useSelector((state) => state.copiedTiers);
  // const [leftPlaylist, rightPlaylist] = useReorderByDirection(playlists?.other);
  // const [, publicPlaylist] = useReorderByDirection(playlists?.public);

  // const [leftCopiedTiers, rightCopiedTiers] =
  //   useReorderByDirection(copiedTiers);

  // useEffect(() => {
  //   dispatch(getCopiedTiers());
  // }, [dispatch]);

  const findPlaylistInGroup = useCallback(() => {
    let activeGroup;
    playlists?.groups?.forEach((group) => {
      const found = group.playlists.find(
        (playlist) => playlist.id === parsedQueryString.playlist
      );
      if (found) {
        activeGroup = group;
      }
    });
    setOpenedGroupId(activeGroup?.id);
  }, [parsedQueryString?.playlist, playlists?.groups]);

  useEffect(() => {
    findPlaylistInGroup();
  }, [findPlaylistInGroup]);

  // const { data: tierData, error: tierDataError } = useSelector(
  //   (state) => state.tiers
  // );

  const playlistClickHandler = (id, isDirectShare = false) => {
    if(isDirectShare && isCopyingItem) {
      setIsDirectShareWarningShown(true);
      return;
    }
    if (isCopyingItem) {
      setShowConfirmPasteModalPrototype(true);
      setCurrentPlaylistIdPrototype(id);
    }
    // if (isCopyingItem) return handleCopyingPlaylistItem(id);

    history.push({ search: `?playlist=${id}` });
    saveLatestPlaylistId(id, "heart");
    setShowTermsImagesPrototype(false);
  };

  const homePositionContextMenuOpenHandler = (e) => {
    openContextMenu(e);
    history.push("/my-playlists");
    setShowTermsImagesPrototype(true);
  };

  // const customTierClickHandler = (item, tierRef) => {
  //   dispatch({ type: EPG_CHANNELS_RESET });
  //   setTierPrototype(tierRef.current.tierId);
  //   history.push({ search: `?playlist=${item.id}` });
  //   saveLatestPlaylistId(item.id, "heart");
  //   setShowChannelsPrototype(true);
  //   setShowTermsImagesPrototype(false);
  //   setShowSubBarPrototype(false);
  // };

  // const tierDataLength = tierData?.tiers?.length;

  // const PublicTier = useMemo(() => {
  //   return {
  //     name: "Public",
  //     content: playlists?.public,
  //     image: PublicContent,
  //     tierIndex: tierDataLength + 1,
  //     is_free: true,
  //     index: 1,
  //   }
  // }, [playlists?.public, tierDataLength]);

  // const RestrictedTier = useMemo(() => {
  //   return {
  //     name: "Restricted",
  //     content: playlists?.restricted,
  //     image: RestrictedContent,
  //     tierIndex: tierDataLength + 2,
  //     is_free: true,
  //     index: 2,
  //   }
  // }, [playlists?.restricted, tierDataLength]);

  // const tierClickHandler = (tier) => {
  //   setTierPrototype(tier);
  // };

  // const handlePublicTierOpen = () => {
  //   setOpenPublicTier((state) => !state);
  // };

  // const handleRestrictedTierOpen = () => {
  //   setOpenRestrictedTier((state) => !state);
  // };

  const insertPlaylistHandler = () => {
    // setIndexOfNewPlaylist(1);
    setShowAddPlaylistModalPrototype(true);
  };

  const homePositionClickHandler = (e) => {
    e.preventDefault();
    history.push("/my-playlists");
    setShowTermsImagesPrototype(true);
    setIsEditMode(false);
  };

  const copiedTierClick = (channelURL) => {
    history.push(channelURL);
    setShowTermsImagesPrototype(true);
    setCurrentViewPrototype(VIEWS_PROTOTYPE.tiers);
    setShowChannelsPrototype(false);
  };

  const toggleOpenArchivedGroup = (archivedGroup) => {
    // setOpenArchivedGroup(!openArchivedGroup);
    if (currentArchiveGroupId === archivedGroup.id) {
      setCurrentArchiveGroupId(null);
      setArchiveFullOpenedDataLength(null);
    } else {
      setCurrentArchiveGroupId(archivedGroup.id);
      const fullDataLength =
        playlists?.archive.length +
        playlists?.archivedGroups.length +
        archivedGroup.playlists.length;
      setArchiveFullOpenedDataLength(fullDataLength);
    }
  };

  /*
   *
   * COMMENTED BELOW CODE IS FOR NEXT RELEASES
   *
   */

  // const item={
  //     description: "Random st",
  //     direct_publish: false,
  //     finished: 0,
  //     icon: null,
  //     id: 38268,
  //     is_default: false,
  //     is_followed: false,
  //     name: "New Something",
  //     nickname: "Ioseb",
  //     report_status: "new",
  //     series: [],
  //     status_id: 3,
  //     tags: [],
  //     user_id: 15456,
  //     watch_later: false,
  //   }
  return (
    <List className="playlist-preview-list">
      {loadingPlaylists && <SpinnerPrototype size={3} />}
      {isTeamMode && (
        <>
        <HomePositionPrototype
          onClick={(e) => homePositionClickHandler(e)}
          openContextMenu={(e) => homePositionContextMenuOpenHandler(e)}
          contextMenuClickWithDebounce={contextMenuClickWithDebounce}
          scrollLen={playlists?.playlists?.length}
          isVisible={isVisible}
          closeContextMenu={closeContextMenu}
          xPosContextMenu={xPos}
          yPosContextMenu={yPos}
          withCloseContextMenu={withCloseContextMenu}
          insertPlaylistHandler={insertPlaylistHandler}
          onTouchEnd={onTouchEnd}
      />
      {teamPlaylist?.map((playlist, index) => {
        return (
          <SubBarItemPrototype
            itemClick={() => {
              playlistClickHandler(playlist?.id);
            }}
            content={playlist?.name}
            image={playlist?.icon || defaultIcon}
            item={playlist}
            key={playlist.id}
            hasButtons={playlist?.has_buttons}
            index={index}
            contentLen={playlists.other.length}
            isRestricted={playlist?.status === "Private link"}
            isPublic={playlist?.status === "Public"}
            active={String(playlistIdFromUrl) === String(playlist?.id)}
            isPlaylist
          />
        );
      })}
        </>

      )}
      {playlists?.other && isOwnMode && (
        <>
          {/* {
            <ArrangeMode content={playlists?.private}>
              {playlists?.private.map((playlist, index) => {
                return (
                  <SubBarItemPrototype
                    itemClick={() => {
                      playlistClickHandler(playlist?.id);
                    }}
                    content={playlist?.name}
                    image={playlist?.icon}
                    item={playlist}
                    key={playlist?.id}
                    hasButtons={playlist?.has_buttons}
                    index={index}
                    contentLen={playlists?.private?.length}
                    active={Number(playlistIdFromUrl) === playlist.id}
                  />
                );
              })}
            </ArrangeMode>
          }
          */}
          <Droppable
            droppableId={DroppableTypes.Archive}
            direction="horizontal"
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{
                  backgroundColor: snapshot.isDraggingOver ? "grey" : "",
                }}
              >
                <SubBarItemPrototype
                  key={"archive"}
                  openable={true}
                  content={"archive"}
                  image={archiveIcon}
                  defaultOpenableItemImage={defaultIcon}
                  openableData={playlists?.archive}
                  openableItemClick={(item) => playlistClickHandler(item?.id)}
                  itemClick={toggleArchive}
                  opened={archiveState}
                  isArchive={true}
                  fullDataLength={
                    archiveFullOpenedDataLength ||
                    playlists?.archive.length + playlists?.archivedGroups.length
                  }
                  arrangeChildren={() => {
                    return (
                      archiveState && (
                        <ArrangeOpenableMode
                          droppableId={DroppableTypes.Archive}
                          content={playlists.archive}
                          defaultIcon={defaultIcon}
                        />
                      )
                    );
                  }}
                >
                  {playlists?.archivedGroups.map((archivedGroup, index) => {
                    return (
                      <SubBarItemPrototype
                        key={archivedGroup.id + archivedGroup.name}
                        openable={true}
                        content={archivedGroup.name}
                        image={defaultIcon}
                        defaultOpenableItemImage={defaultIcon}
                        openableData={
                          playlists?.archivedGroups[index].playlists
                        }
                        openableItemClick={(item) =>
                          playlistClickHandler(item?.id)
                        }
                        item={archivedGroup}
                        itemClick={() => toggleOpenArchivedGroup(archivedGroup)}
                        opened={archivedGroup.id === currentArchiveGroupId}
                        isArchivedGroup={true}
                      />
                    );
                  })}
                </SubBarItemPrototype>
              </div>
            )}
          </Droppable>
          {
            <ArrangeMode
              content={playlists.other}
              droppableId={DroppableTypes.MyPlaylistLeft}
              defaultIcon={defaultIcon}
            >
              {playlists.other.map((playlist, index) => {
                return (
                  // <SubBarItemPrototype
                    // itemClick={() => {
                    //   playlistClickHandler(playlist?.id);
                    // }}
                    // content={playlist?.name}
                    // image={playlist?.icon || defaultIcon}
                    // item={playlist}
                    // key={playlist.id}
                    // hasButtons={playlist?.has_buttons}
                    // index={index}
                    // contentLen={playlists.other.length}
                    // isRestricted={playlist?.status === "Private link"}
                    // isPublic={playlist?.status === "Public"}
                    // active={String(playlistIdFromUrl) === String(playlist?.id)}
                    // archiveLength={playlists?.archive?.length}
                    // isLeftPl
                    // isPlaylist
                  // />
                  <MyPlaylistSubbarItem 
                    itemClick={() => {
                      playlistClickHandler(playlist?.id);
                    }}
                    image={playlist?.icon || defaultIcon}
                    item={playlist}
                    key={playlist.id}
                    index={index}
                    isActive={String(playlistIdFromUrl) === String(playlist?.id)}
                  />
                );
              })}
            </ArrangeMode>
          }
          <ArrangeShortcutsMode content={copiedTiers}>
            {(copiedTiers || [])?.map((shortcut, i) => {
              const { id, icon, name, url } = shortcut;
              return (
                <ShortcutSubbarItem
                  key={`${id}_${name}`}
                  item={shortcut}
                  index={i}
                  image={icon}
                  itemClick={() => copiedTierClick(url)}
                 />
              );
            })}
          </ArrangeShortcutsMode>
          <GroupArrangeMode content={playlists?.groups}>
            {playlists?.groups?.filter(gr => gr.status !== 'Public').map((group, index) => {
              return (
                <Droppable
                  droppableId={`${DroppableTypes?.GroupItem}:${group.id}`}
                  direction="horizontal"
                >
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                      <SubBarItemPrototype
                        key={group.id}
                        openable={true}
                        content={group.name}
                        image={group.icon || defaultIcon}
                        defaultOpenableItemImage={defaultIcon}
                        openableData={group.playlists}
                        fullDataLength={group.playlists.length}
                        openableItemClick={() => {}}
                        itemClick={() =>
                          setOpenedGroupId((prev) => {
                            if (prev === group.id) return;
                            else return group.id;
                          })
                        }
                        item={group}
                        index={index}
                        openableDataClick={(item) =>
                          playlistClickHandler(item.id)
                        }
                        opened={openedGroupId === group.id}
                        isGroup
                        arrangeChildren={() => {
                          return (
                            openedGroupId === group.id && (
                              <ArrangeOpenableMode
                                droppableId={`${DroppableTypes.GroupItem}:${group.id}`}
                                content={group.playlists}
                                defaultIcon={defaultIcon}
                              />
                            )
                          );
                        }}
                      />
                    </div>
                  )}
                </Droppable>
              );
            })}
          </GroupArrangeMode>
          <HomePositionPrototype
            onClick={(e) => homePositionClickHandler(e)}
            openContextMenu={(e) => homePositionContextMenuOpenHandler(e)}
            contextMenuClickWithDebounce={contextMenuClickWithDebounce}
            scrollLen={playlists?.playlists?.length}
            isVisible={isVisible}
            closeContextMenu={closeContextMenu}
            xPosContextMenu={xPos}
            yPosContextMenu={yPos}
            withCloseContextMenu={withCloseContextMenu}
            insertPlaylistHandler={insertPlaylistHandler}
            onTouchEnd={onTouchEnd}
          />
          {playlists?.directPublish[0] && (
            <SubBarItemPrototype
              key={"direct-publish"}
              content={"Direct share"}
              image={playlists?.directPublish[0]?.icon}
              itemClick={() => {
                playlistClickHandler(playlists?.directPublish[0]?.id, true);
              }}
              isDirectPublish={true}
              active={
                String(playlistIdFromUrl) ===
                String(playlists?.directPublish[0]?.id)
              }
            />
          )}
          {playlists?.public && (
            <ArrangeMode
              content={playlists?.public}
              droppableId={DroppableTypes.Public}
              defaultIcon={defaultIcon}
            >
              {playlists?.public?.map((playlist, index) => {
                return (
                  // <SubBarItemPrototype
                  //   itemClick={() => {
                  //     playlistClickHandler(playlist?.id);
                  //   }}
                  //   content={playlist?.name}
                  //   image={playlist?.icon || defaultIcon}
                  //   item={playlist}
                  //   key={playlist.id}
                  //   hasButtons={playlist.has_buttons}
                  //   index={index}
                  //   contentLen={playlists?.public?.length}
                  //   isRestricted={playlist?.status === "Private link"}
                  //   isPublic={playlist?.status === "Public"}
                  //   leftArrLength={playlists?.public?.length}
                  //   archiveLength={playlists?.archive?.length}
                  //   active={String(playlistIdFromUrl) === String(playlist?.id)}
                  //   isPlaylist
                  // />
                  <MyPlaylistSubbarItem 
                    itemClick={() => {
                      playlistClickHandler(playlist?.id);
                    }}
                    image={playlist?.icon || defaultIcon}
                    item={playlist}
                    key={playlist.id}
                    index={index}
                    isActive={String(playlistIdFromUrl) === String(playlist?.id)}
                  />
                );
              })}
            </ArrangeMode>
          )}

          <GroupArrangeMode content={playlists?.groups}>
            {playlists?.groups?.filter(gr => gr.status === 'Public').map((group, index) => {
              return (
                <Droppable
                  droppableId={`${DroppableTypes?.GroupItem}:${group.id}`}
                  direction="horizontal"
                >
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                      <SubBarItemPrototype
                        key={group.id}
                        openable={true}
                        content={group.name}
                        image={group.icon || defaultIcon}
                        defaultOpenableItemImage={defaultIcon}
                        openableData={group.playlists}
                        fullDataLength={group.playlists.length}
                        openableItemClick={() => {}}
                        itemClick={() =>
                          setOpenedGroupId((prev) => {
                            if (prev === group.id) return;
                            else return group.id;
                          })
                        }
                        item={group}
                        index={index}
                        openableDataClick={(item) =>
                          playlistClickHandler(item.id)
                        }
                        opened={openedGroupId === group.id}
                        isGroup
                        arrangeChildren={() => {
                          return (
                            openedGroupId === group.id && (
                              <ArrangeOpenableMode
                                droppableId={`${DroppableTypes.GroupItem}:${group.id}`}
                                content={group.playlists}
                                defaultIcon={defaultIcon}
                              />
                            )
                          );
                        }}
                      />
                    </div>
                  )}
                </Droppable>
              );
            })}
          </GroupArrangeMode>

          {/* <SubBarItemPrototype
            key={1}
            openable={false}
            content={"Add Item"}
            image={AddItem}
            onClick={() => {
              setShowAddPlaylistModalPrototype(true);
            }}
            isOpenableTierContext={true}
            isOpenableRestrictedTierContext={true}
          /> */}

          <StaticSubbarItem 
            title="Add Item"
            image={AddItem}
            itemClick={() => setShowAddPlaylistModalPrototype(true)}
          />

          {/* {
            <ArrangeMode
              content={playlists?.other}
              droppableId={DroppableTypes.ArrangeMode}
            >
              {playlists?.other?.map((playlist, index) => {
                return (
                  <SubBarItemPrototype
                    itemClick={() => {
                      playlistClickHandler(playlist?.id);
                    }}
                    content={playlist?.name}
                    image={playlist?.icon || defaultIcon}
                    item={playlist}
                    key={playlist?.id}
                    hasButtons={playlist?.has_buttons}
                    index={index}
                    contentLen={playlists?.other.length}
                    isRestricted={playlist?.status === "Private link"}
                    isPublic={playlist?.status === "Public"}
                    active={String(playlistIdFromUrl) === String(playlist?.id)}
                    leftArrLength={playlists?.other.length}
                    archiveLength={playlists?.archive?.length}
                    isPlaylist
                  />
                );
              })}
            </ArrangeMode>
          } */}

          {/* {isOpenableArrangeMode ? (
            <ArrangeOpenableMode content={PublicTier.content} />
          ) : (
            <SubBarItemPrototype
              key={0}
              openable={true}
              content={PublicTier.name}
              image={PublicTier.image}
              openableData={PublicTier.content}
              openableItemClick={customTierClickHandler}
              itemClick={() => tierClickHandler(PublicTier)}
              onClick={(e) => {
                handlePublicTierOpen();
              }}
              opened={openPublicTier}
              tierIndex={PublicTier.index}
              ref={tierRef}
              publicTier={PublicTier}
              isOpenableTierContext={true}
              isOpenableRestrictedTierContext={false}
            />
          )}

          {isRestrictedOpenableArrangeMode ? (
            <ArrangeOpenableMode content={RestrictedTier.content} />
          ) : (
            <SubBarItemPrototype
              key={1}
              openable={true}
              content={RestrictedTier.name}
              image={RestrictedTier.image}
              openableData={RestrictedTier.content}
              openableItemClick={customTierClickHandler}
              itemClick={() => tierClickHandler(RestrictedTier)}
              onClick={() => {
                handleRestrictedTierOpen();
              }}
              opened={openRestrictedTier}
              tierIndex={RestrictedContent.index}
              ref={tierRef}
              restrictedTier={RestrictedTier}
              isOpenableTierContext={true}
              isOpenableRestrictedTierContext={true}
            />
          } */}
        </>
      )}

      <SimplePopupWithSingleButton 
        isVissible={isDirectShareWarningShown}
        btnText="OK" 
        text="please use direct share"
        onAccept={() => setIsDirectShareWarningShown(false)}
        onCansel={() => setIsDirectShareWarningShown(false)}
      />
      <CreatePlaylistModalPrototype />
    </List>
  );
};

export default PlaylistListPrototype;
