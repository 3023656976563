import React, { useState, useEffect, useContext } from "react";
import Button from "@shared/atoms/Button/index";
import Modal from "@shared/atoms/Modal/index";
import CloseModal from "../CloseModal/index";
import { useTranslation } from "react-i18next";
import { Context } from "@context/context";

import OtpInput from "react-otp-input";

import "./index.less";

const ConfirmEmailModal = ({
    showPasswordModal,
    isModalVisible,
    handleClose,
}) => {
    const [disableBtn, setDisableBtn] = useState(false);

    const { t } = useTranslation();

    const { resetPasswordOtpCode, setResetPasswordOtpCode } =
        useContext(Context);

    useEffect(() => {
        if (resetPasswordOtpCode.length !== 4) {
            setDisableBtn(true);
        } else {
            setDisableBtn(false);
        }
    }, [resetPasswordOtpCode]);

    const handleChangeOtpCode = (otpCode) => {
        setResetPasswordOtpCode(otpCode);
    };

    return (
        <Modal
            title={t("enter_confirmation_code")}
            closable={false}
            visible={isModalVisible}
            className="enter-email-modal"
            footer={[
                <Button
                    disabled={disableBtn}
                    type="primary"
                    key="back"
                    onClick={showPasswordModal}
                    onCancel={handleClose}
                    htmlType="submit"
                >
                    {t("confirm")}
                </Button>,
                <CloseModal handleClose={handleClose} />,
            ]}
        >
            <div className="otp-container">
                <OtpInput
                    value={resetPasswordOtpCode}
                    onChange={handleChangeOtpCode}
                    numInputs={4}
                    separator={<span style={{ visibility: "hidden" }}>-</span>}
                    containerStyle="otp-container-style"
                    inputStyle="otp-style"
                    isInputSecure={true}
                    shouldAutoFocus={true}
                />
            </div>
        </Modal>
    );
};

export default ConfirmEmailModal;
