import React from "react";
// import FollowingFirst from "../../components/organisms/diemensions/first/Following";
// import FollowingSecond from "../../components/organisms/diemensions/second/Following";
// import FollowingThird from "../../components/organisms/diemensions/third/Following";
import MainLayout from "../../layout/MainLayout";
import MobileFollowing from "../../components/organisms/MobileFollowing";
import ShareModal from "@components/organisms/modals/ShareModal";
import PlaylistMoveToModal from "@components/organisms/modals/PlaylistMoveToModal";
import ReportContentModal from "@components/organisms/modals/ReportContentModal";
import { isMobile } from "react-device-detect";

export const Following = () => {

    return (
        <>
            {!isMobile ? (
                <>
                    <ShareModal />
                    <PlaylistMoveToModal />
                    <ReportContentModal />

                    {/* <MainLayout
                        firstDimension={<FollowingFirst />}
                        secondDimension={<FollowingSecond />}
                        thirdDimension={<FollowingThird />}
                        className={!token ? "two-dimentions" : null}
                    ></MainLayout> */}
                </>
            ) : (
                <MainLayout>
                    <MobileFollowing />
                </MainLayout>
            )}
        </>
    );
};
