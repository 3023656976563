import React, { useEffect, useRef } from "react";

import "./index.less";

const KebabMenuPopup = ({ children, setShowDropdown, className = "" }) => {
    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (e.target.tagName === "path" || e.target.tagName === "svg") {
                return;
            }
            if (
                !popupRef.current.contains(e.target) &&
                !e.target.classList.contains("kebab-menu") &&
                !e.target.classList.contains("popup-toggle")
            ) {
                setShowDropdown(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowDropdown]);

    return (
        <div
            ref={popupRef}
            className={`kebab-menu-modal-container ${className}`}
            onClick={() => {
                setShowDropdown(false);
            }}
        >
            {children}
        </div>
    );
};

export default KebabMenuPopup;
