import React from "react";
import List from "@shared/atoms/List/index";
import Checkbox from "@components/shared/atoms/Checkbox";
import Image from "@components/shared/atoms/Image";
import { Paragraph } from "@components/shared/atoms/Typography";

import "./index.less";

const PlaylistSelectableItemPrototype = ({ playlist, setCheckedPlaylists }) => {
  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setCheckedPlaylists((prev) => [...prev, playlist.id]);
    } else {
      setCheckedPlaylists((prev) =>
        prev.filter((prev) => prev !== playlist.id)
      ); //delete
    }
  };
  return (
    <div>
      <List.Item>
        <div className="playlist-selectable-item">
          <Image className="playlist-image" src={playlist.icon} />
          <Paragraph className="playlist-name">{playlist.name}</Paragraph>
          <Checkbox onChange={handleCheckboxChange} />
        </div>
      </List.Item>
    </div>
  );
};

export default PlaylistSelectableItemPrototype;
