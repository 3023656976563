import { CHOICE_URL } from "@modules/config";
import { axiosInstance } from "@modules/request/api";

import {
  SEARCH_UNAUTHORIZED_REQUEST,
  SEARCH_UNAUTHORIZED_SUCCESS,
  SEARCH_UNAUTHORIZED_FAIL,
  SEARCH_AUTHORIZED_REQUEST,
  SEARCH_AUTHORIZED_SUCCESS,
  SEARCH_AUTHORIZED_FAIL,
  SEARCH_HISTORY_REQUEST,
  SEARCH_HISTORY_SUCCESS,
  SEARCH_HISTORY_FAIL,
  CLEAR_HISTORY_REQUEST,
  CLEAR_HISTORY_SUCCESS,
  CLEAR_HISTORY_FAIL,
  CREATE_SEARCH_HISTORY_REQUEST,
  CREATE_SEARCH_HISTORY_SUCCESS,
  CREATE_SEARCH_HISTORY_FAIL,
  TOGGLE_FOLLOW_SEARCH_HISTORY_REQUEST,
  TOGGLE_FOLLOW_SEARCH_HISTORY_FAIL,
  TOGGLE_FOLLOW_SEARCH_HISTORY_SUCCESS,
  SEARCH_DELETE_REQUEST,
  SEARCH_DELETE_SUCCESS,
  SEARCH_DELETE_FAIL,
} from "../constants/searchConstants";

export const getUnauthorizedSearch = (keyword) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_UNAUTHORIZED_REQUEST });
    const { data } = await axiosInstance().get(
      `${CHOICE_URL}/api/search?keyword=${encodeURIComponent(keyword)}`
    );
    dispatch({ type: SEARCH_UNAUTHORIZED_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SEARCH_UNAUTHORIZED_FAIL, error: error.response });
  }
};

export const getAuthorizedSearch =
  (keyword, type = "all") =>
  async (dispatch) => {
    try {
      dispatch({ type: SEARCH_AUTHORIZED_REQUEST });
      const { data } = await axiosInstance().get(
        `${CHOICE_URL}/api/search/auth?keyword=${encodeURIComponent(
          keyword
        )}&type=${type}`
      );
      dispatch({ type: SEARCH_AUTHORIZED_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: SEARCH_AUTHORIZED_FAIL, error: error.response });
    }
  };

export const getSearchHistory = () => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_HISTORY_REQUEST });
    const { data } = await axiosInstance().get(
      `${CHOICE_URL}/api/search-history`
    );
    dispatch({ type: SEARCH_HISTORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SEARCH_HISTORY_FAIL, error: error.response });
  }
};

export const createSearchHistory = (search) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_SEARCH_HISTORY_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/search-history`,
      { search }
    );
    dispatch({ type: CREATE_SEARCH_HISTORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CREATE_SEARCH_HISTORY_FAIL, payload: error.response });
  }
};

export const toggleFollowSearchHistory = (id) => async (dispatch) => {
  try {
    dispatch({ type: TOGGLE_FOLLOW_SEARCH_HISTORY_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/search-history/${id}/follow`
    );
    dispatch({ type: TOGGLE_FOLLOW_SEARCH_HISTORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TOGGLE_FOLLOW_SEARCH_HISTORY_FAIL,
      payload: error.response,
    });
  }
};

export const clearHistory = () => async  (dispatch) => {
  try {
    dispatch({
      type: CLEAR_HISTORY_REQUEST,
    });

    const { data } = await axiosInstance().delete(
      `${CHOICE_URL}/api/search-history/`
    );

    dispatch({
      type: CLEAR_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLEAR_HISTORY_FAIL,
      payload: error.response,
    });
  }
};

export const deleteSearchHistoryItem = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_DELETE_REQUEST,
    });

    const { data } = await axiosInstance().delete(
      `${CHOICE_URL}/api/search-history/${id}`
    );

    dispatch({
      type: SEARCH_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_DELETE_FAIL,
      payload: error.response,
    });
  }
};

//selectors

export const selectAuthorizedSearch = (state) => state.searchAuthorized;

export const selectUnauthorizedSearch = (state) => state.searchUnauthorized;

export const selectSearchHistory = (state) => state.searchHistory;

export const selectCreateSearchHistory = (state) => state.createSearchHistory;

export const selectToggleFollowSearchHistory = (state) =>
  state.toggleFollowSearchHistory;
