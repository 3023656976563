import React, { useContext, useEffect } from "react";
import ChannelMenuPrototype from "../ChannelMenuPrototype";
import { Title } from "@shared/atoms/Typography";
import homePositionIcon from "@assets/icons/home-position-icon.svg";

import ContextMenuPrototype, { arrowDirection } from "../ContextMenuPrototype";
import { useContextMenu } from "hooks/useContextMenu";
import { Context } from "@context/context";

import "./index.less";
import MoreButtons from "../VideoMoreButtons";
import Image from "@components/shared/atoms/Image";
import useWindowSize from "hooks/useWindowSize";

const InfoBoxPrototype = ({
  description,
  moreInfo,
  contextmenuOptions,
  subTitle = " ",
  time,
  isMoreInfoOpened = false,
  title = "title",
  hasThreeDotMenu = true,
  infoboxClickHandler,
  isContextMenuVisible,
  goToTitle,
  goTo,
  moreInfoTitle,
  moreInfoClickHandler,
  moreButtons,
  isSeriesHomeItem,
  openContextMenu,
  onTouchStart,
  onTouchEnd,
  onTouchMove,
}) => {

  const { tabletFrameRef } = useContext(Context);
  const { width } = useWindowSize();

  useEffect(() => {
    if (tabletFrameRef && tabletFrameRef.current) {
      if (isContextMenuVisible) {
        tabletFrameRef.current.style.overflow = "hidden";
      } else {
        tabletFrameRef.current.style.overflow = "auto";
      }
    }
  }, [tabletFrameRef, isContextMenuVisible]);

  return (
    <>
      <div 
        className="infobox-prototype" 
        onContextMenu={openContextMenu} 
        onTouchStart={onTouchStart} 
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
        >
        <div
          className="infobox-subtitle"
          onClick={infoboxClickHandler}
        >
          {isSeriesHomeItem ? (
            <div className="series-title">
              <Image src={homePositionIcon} className="home-position-title" />
              <span>Series: </span>{" "}
              
              <span dangerouslySetInnerHTML={{ __html: width >= 978 ? title : title.slice(0, 14) + "..."} }></span>
            </div>
          ) : width >= 978 ? (
            <span dangerouslySetInnerHTML={{ __html: title.slice(0, 80) }}/>
          ) : (
            <span dangerouslySetInnerHTML={{ __html: title.slice(0, 20) + "..." }}/>
          )}
        </div>
        {time && <div className="infobox-time">{time}</div>}
        <div className="infobox-title-container">
          {subTitle && (
            <Title className="infobox-title" level={2}>
              <div dangerouslySetInnerHTML={{ __html: subTitle }}></div>
            </Title>
          )}
          {hasThreeDotMenu && (
            <ChannelMenuPrototype
              className="three-dot-prototype"
              onClick={openContextMenu}
            />
          )}
        </div>

        <MoreButtons
          goToTitle={moreButtons?.goToTitle}
          goTo={moreButtons?.goToLink}
          moreInfoTitle={moreButtons?.moreInfoTitle}
          moreInfoClickHandler={moreButtons?.moreInfoClickHandler}
        />

        <div
          className="infobox-description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
        {isMoreInfoOpened && (
          <div className="infobox-more-info">
            <h2
              className="infobox-more-info__title"
              dangerouslySetInnerHTML={{ __html: moreInfo?.moreTitle }}
            ></h2>
            <p
              className="infobox-more-info__desc"
              dangerouslySetInnerHTML={{ __html: moreInfo?.moreDescription }}
            ></p>
            {moreInfo?.morePhoto && (
              <img
                src={moreInfo?.morePhoto}
                alt="Playlist video"
                className="infobox-more-info__img"
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default InfoBoxPrototype;
