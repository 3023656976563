import { Context } from "@context/context";
import { localStorageService } from "@modules/services/localStorage.service";
import {
  getAuthorizedSearch,
  getUnauthorizedSearch,
  selectAuthorizedSearch,
  selectUnauthorizedSearch,
} from "@redux/actions/searchActions";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SearchResultListPrototype from "../lists/SearchResultListPrototype";
import SearchModalPrototype from "../modals/SearchModalPrototype";

const SearchPrototype = () => {
  const [searchContent, setSearchContent] = useState("");
  const { setShowSearchModalPrototype } = useContext(Context);

  const isLoggedIn = !!localStorageService().get("access_token");
  const { data: unauthorizedSearchResult } = useSelector(
    selectUnauthorizedSearch
  );
  const { data: authorizedSearchResult } = useSelector(selectAuthorizedSearch);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.search) {
      setShowSearchModalPrototype(false);
      const searchWord = location.search.split("&")[1].split("=")[1];
      const searchContent = location.search.split("&")[0].split("=")[1];
      const searchType = location.search.split("&")[2].split("=")[1];

      setSearchContent(searchContent);
      if (searchWord) {
        if (isLoggedIn) {
          dispatch(getAuthorizedSearch(decodeURI(searchWord), searchType));
        } else {
          dispatch(getUnauthorizedSearch(decodeURI(searchWord)));
        }
      }
    }
  }, [location, setShowSearchModalPrototype, isLoggedIn, dispatch]);

  return (
    <div className="search-prototype">
      <SearchModalPrototype />
      <SearchResultListPrototype
        data={isLoggedIn ? authorizedSearchResult : unauthorizedSearchResult}
        searchType={searchContent}
      />
    </div>
  );
};

export default SearchPrototype;
