import { 
  COPY_EPG_FAIL,
  COPY_EPG_ITEM_FAIL,
  COPY_EPG_ITEM_REQUEST,
  COPY_EPG_ITEM_SUCCESS,
  COPY_EPG_REQUEST,
  COPY_EPG_SUCCESS,
  COPY_SERIES_FAIL, 
  COPY_SERIES_REQUEST, 
  COPY_SERIES_SUCCESS, 
  COPY_TIER_CHANNEL_FAIL, 
  COPY_TIER_CHANNEL_REQUEST, 
  COPY_TIER_CHANNEL_SUCCESS, 
  COPY_VIDEO_FAIL, 
  COPY_VIDEO_REQUEST, 
  COPY_VIDEO_SUCCESS
} from "@redux/constants/copy";

export const copyVideoReducer = (state = {}, action) => {
    switch (action.type) {
      case COPY_VIDEO_REQUEST:
        return { loading: true };
      case COPY_VIDEO_SUCCESS:
        return { loading: false, data: action.payload, success: true };
      case COPY_VIDEO_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
};

export const copySerieReducer = (state = {}, action) => {
  switch (action.type) {
    case COPY_SERIES_REQUEST:
      return { loading: true };
    case COPY_SERIES_SUCCESS:
      return { loading: false, success: true };
    case COPY_SERIES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const copyEpgItemReducer = (state = {}, action) => {
  switch (action.type) {
    case COPY_EPG_ITEM_REQUEST:
      return { loading: true };
    case COPY_EPG_ITEM_SUCCESS:
      return { loading: false, success: true };
    case COPY_EPG_ITEM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const copyEpgReducer = (state = {}, action) => {
  switch (action.type) {
    case COPY_EPG_REQUEST:
      return { loading: true };
    case COPY_EPG_SUCCESS:
      return { loading: false, success: true };
    case COPY_EPG_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const copyTierChannelReducer = (state = {}, action) => {
  switch (action.type) {
    case COPY_TIER_CHANNEL_REQUEST:
      return { loading: true };
    case COPY_TIER_CHANNEL_SUCCESS:
      return { loading: false, success: true };
    case COPY_TIER_CHANNEL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};