import React from "react";
import AntTypography from "antd/lib/typography/index";

const { Title: AntTitle, Paragraph: AntParagraph } = AntTypography;

export const Title = ({ onClick, children, ...rest }) => {
    return (
        <AntTitle onClick={onClick} {...rest}>
            {children}
        </AntTitle>
    );
};

// Prototype version: paragraph-prototype
export const Paragraph = ({ onClick, children, ...rest }) => {
    return (
        <AntParagraph onClick={onClick} {...rest} >
            {children}
        </AntParagraph>
    );
};
