import React, { useContext } from "react";
import querystring from "query-string";
import Modal from "@shared/atoms/Modal/index";
import { Paragraph } from "@shared/atoms/Typography/index";
import { useTranslation } from "react-i18next";
import Button from "@components/shared/atoms/Button";
import {
  addNewSeries,
  getPlaylistById,
} from "../../../../../redux/actions/playlistsActions";
import { Context } from "@context/context";
import { useDispatch } from "react-redux";
import moment from "moment";

import "./index.less";
import { useLocation } from "react-router-dom";

const ConfirmPasteModalPrototype = ({ playlistName = "" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation()
  const parsedQueryString = querystring.parse(search);
  const playlistIdFromUrl = parsedQueryString.playlist;
  const {
    showConfirmPasteModalPrototype,
    setShowConfirmPasteModalPrototype,
    currentPlaylistIdPrototype,
    copySource,
    copyingItem,
    setSelectedPlaylist,
  } = useContext(Context);

  const handleCopyingPlaylistItem = () => {
    const fd = new FormData();
    fd.append('name', `Copy from playlist - ${moment().format("YYYY-MM-DD - hh:mm:ss")}`);
    fd.append('playlist_id', playlistIdFromUrl)
    fd.append('videos[0][image]', copySource.image);
    fd.append('videos[0][title]', copySource.title);
    fd.append('videos[0][description]', copySource.description);
    fd.append('videos[0][src]', copySource.src);

    Promise.resolve(
      dispatch(addNewSeries(fd))
    ).then(() => {
      setSelectedPlaylist(currentPlaylistIdPrototype);
      dispatch(getPlaylistById(currentPlaylistIdPrototype));
      setShowConfirmPasteModalPrototype(false);
      copyingItem(null);
    });
  };
  return (
    <Modal
      visible={showConfirmPasteModalPrototype}
      className="modal-basic-prototype confirm-paste-modal-prototype"
      mask={true}
      footer={
        <>
          <Button
            type="primary"
            className="ant-btn-prototype"
            onClick={handleCopyingPlaylistItem}
          >
            {t("yes")}
          </Button>

          <Button
            className="ant-btn-prototype dark different-channel-btn"
            onClick={() => setShowConfirmPasteModalPrototype(false)}
          >
            {t("no")} <br/>
            {t("different_channel")}
          </Button>

          <Button
            className="ant-btn-prototype white"
            onClick={() => {
              setShowConfirmPasteModalPrototype(false);
              copyingItem(null);
            }}
          >
            {t("cancel")}
          </Button>
        </>
      }
      closable={true}
      //   onCancel={() => {
      //     closeHandler();
      //   }}
    >
      <div>
        <Paragraph className="para">{`${t(
          "do_you_want_to_paste_in"
        )} ${playlistName}`}</Paragraph>
      </div>
    </Modal>
  );
};

export default ConfirmPasteModalPrototype;
