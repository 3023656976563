import { useState } from "react";

const useSelectSearch = (type = "Playlist", options = []) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const isAllSelected = selectedItems.length === options?.length;

  const selectValue = isAllSelected ? [`All ${type}s Selected`] : selectedItems;

  const onSelectChange = (arg1, arg2) => {
    if (arg1.includes(`Select All ${type}`)) {
      setSelectedItems(options?.map((opt) => opt.id));
    } else {
      setSelectedItems(arg1, arg2);
    }
  };

  return {
    selectValue,
    selectedItems,
    isAllSelected,
    onSelectChange,
  };
};

export default useSelectSearch;
