import { copyTier } from "@redux/actions/channelActions";
import { useDispatch } from "react-redux";

export const useTier = () => {
    const dispatch = useDispatch();

    const createTireCopy = (copiedItem, dir, order) => {
        const { id, name, logoId, tierId } = copiedItem;
        const tireCopy = {
          name,
          icon: logoId,
          url: `tiers?tier=${tierId}&channel=${id}`,
        // unused property
          is_left: true,
        };

        if(order) {
            tireCopy["position"] = dir === "Left" ? order : order + 1; 
        }
    
        dispatch(copyTier(tireCopy));
      };

      return {
        createTireCopy,
      }
}