import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { ContextProvider } from "./context/context";
// import DNDContext from "./context/dndContext";
import store from "./redux/store";

import "../src/modules/i18n/i18n";
import reportWebVitals from "./reportWebVitals";
import DragDropContextPrototype from "@context/dndContextPrototype";

ReactDOM.render(
  <ContextProvider>
    <Provider store={store}>
      <DragDropContextPrototype>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </DragDropContextPrototype>
    </Provider>
  </ContextProvider>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
