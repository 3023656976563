export const VIEW_REQUEST = "VIEW_REQUEST";
export const VIEW_SUCCESS = "VIEW_SUCCESS";
export const VIEW_FAIL = "VIEW_FAIL";

export const CLICK_VIEW_REQUEST = "CLICK_VIEW_REQUEST";
export const CLICK_VIEW_SUCCESS = "VIEWCLICK__SUCCESS";
export const CLICK_VIEW_FAIL = "VIEW_CLICK_FAIL";

export const VIEW_START_REQUEST = "VIEW_START_REQUEST";
export const VIEW_START_SUCCESS = "VIEW_START_SUCCESS";
export const VIEW_START_FAIL = "VIEW_START_FAIL";

export const VIEW_END_REQUEST = "VIEW_END_REQUEST";
export const VIEW_END_SUCCESS = "VIEW_END_SUCCESS";
export const VIEW_END_FAIL = "VIEW_END_FAIL";

export const VIEW_LIST_REQUEST = "VIEW_LIST_REQUEST"
export const VIEW_LIST_SUCCESS = "VIEW_LIST_SUCCESS"
export const VIEW_LIST_FAIL = "VIEW_LIST_FAIL"
