import React from "react";

import "./index.less";
const PopupItem = ({ children, icon, allowed = true, onClick }) => {
    return (
        allowed && (
            <div onClick={onClick} className="popup-item">
                {icon}
                {children}
            </div>
        )
    );
};

export default PopupItem;
