import { localStorageService } from "@modules/services/localStorage.service";
import { useHistory } from "react-router-dom";
import "./index.less";
import { useTranslation } from "react-i18next";

const TermsOfUsePrototype = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const goBackHandler = () => {
    history.push(localStorageService().get("redirected_from") || "/settings");
    localStorageService().remove("redirected_from");
    // history.push('/settings');
  };

  return (
    <div className="privacy-container-prototype">
      <span className="privacy-policy-secondary-title">
        <button id="back-button" onClick={goBackHandler}>
          {t("just_back")}
        </button>
      </span>
      <div className="privacy-policy-secondary-title flex justify-between align-center">
        <span>Terms Of Use</span>
        <span className="span-small text-underline">
          Version: November 2021
        </span>
      </div>
      <div className="privacy-policy-text-block border-1">
        Disclaimer: Please read these terms of use carefully before using the
        Choice Platform. By clicking the "I agree" button, (i) you agree that
        your use of the Choice Platform shall be governed by the present terms
        of use, (ii) you explicitly confirm all acknowledgments, agreements,
        representations and warranties as set out in the Terms of Use.
      </div>
      <div className="privacy-policy-text-block">
        These terms of use (the <strong>"Terms of Use"</strong>) describe the
        terms and conditions under which users (the <strong>"User"</strong> or{" "}
        <strong>"You"</strong>) can use the Platform (as defined hereinafter).
      </div>
      <div className="privacy-policy-text-block">
        The Platform is operated and managed by Choice NV, a limited liability
        company incorporated under the laws of Belgium, registered at the
        Crossroads Bank for Enterprises under the number 0748.659.757 and with
        its registered office at Jachthoorn 5, 3210 Lubbeek, Belgium or by any
        of its affiliates (jointly <strong>"Choice"</strong>,{" "}
        <strong>"we"</strong> or <strong>"us"</strong>).
        <br /> <br />
        If you have any questions about the Platform or these Terms of Use,
        please contact us at
        <a href="mailto:info@watchchoice.tv"> info@watchchoice.tv</a>
      </div>
      <ol className="privacy-policy-ol">
        <li>
          <span className="privacy-policy-tertiary-title">
            THE PLATFORM AND TYPES OF USERS
          </span>
          <p className="privacy-p-small">
            1.1 The Choice platform is an online social media platform for
            television and video content to be accessed from the webpage
            "watch.choice.be" or "watchchoice.tv" (the{" "}
            <strong>"Platform"</strong>).
          </p>
          <p className="privacy-p-small">
            1.2 The following categories of Users (collectively, the{" "}
            <strong>"Users"</strong>) shall have access to the Platform:{" "}
          </p>
          <ol className="list-style-none">
            <li className="margin-10">
              1.2.1 <strong>"Basic Users"</strong> – natural persons having
              accepted the present Terms of Use and related privacy and cookie
              policy, but not having created a User Account (as defined
              hereinafter) shall be entitled to discover and watch playlists;
            </li>
            <li className="margin-10">
              1.2.2 <strong>"Registered Users"</strong> – natural persons having
              accepted the Terms of Use and related privacy and cookie policy
              and having created a unique user account with a unique username
              and password (a "<strong>User Account</strong>") shall be entitled
              to (i) discover and watch playlists with links to video content
              from television and other platforms or sources on the Platform and
              (ii) create channels or playlists with their favourite television,
              video or other visual media content posted or made available on
              the Internet in accordance with the procedures and guidelines set
              out on the Platform (each, a "<strong>Channel</strong>") whereby
              such channel or playlist contains hyperlinks to the source of such
              content or the website on which such content is hosted and is
              accessible (jointly, "<strong>Visual Media Content</strong>"). A
              Registered User can also select and upload its own information,
              data, pictures, video, texts, pages, materials, tools and other
              content to his channels or playlists. Registered Users have
              additional possibilities such as but not limited to following
              Channels, liking Channels, sharing or promoting Channels;{" "}
            </li>
            <li className="margin-10">
              1.2.3 <strong>"Registered Users Plus"</strong> – natural persons
              having accepted the Terms of Use and related privacy and cookie
              policy and having created a User Account with - 2 - additional
              required credentials such as for example the residence of the User
              and shall be entitled to (i) discover and watch playlists with
              video content from television and other platforms or source on the
              Platform, (ii) create Channels with links to their favourite
              Visual Media Content and to select and upload his own Content in
              his Channels, and (iii) access certain Content that is only
              accessible to Registered Users Plus. The extra required
              credentials for a Plus User may vary from time to time and are
              determined at the sole discretion of Choice.
            </li>
            <li className="margin-10">
              1.2.4<strong>"Commercial Users"</strong> – natural persons or
              legal entities having accepted the Terms of Use and related
              privacy and cookie policy, having created minimally a Registered
              User Account and having entered into a commercial partnership
              agreement with Choice in a form to be determined by Choice at its
              sole discretion (the "<strong>Commercial User Agreement</strong>
              "). Commercial Users shall have the use and access rights to the
              Platform as set out in their Commercial User Agreement.{" "}
            </li>
          </ol>
          <p className="privacy-p-small">
            1.3 In case you need technical support, you can contact us on the
            following e-mail address{" "}
            <a href="mailto:support@watchchoice.tv">support@watchchoice.tv</a>
          </p>
        </li>
        <li>
          <span className="privacy-policy-tertiary-title">APPLICABILITY</span>
          <p className="privacy-p-small">
            2.1 You acknowledge and agree that your use of the Platform is
            exclusively governed by these Terms of Use, even when they are
            conflicting your or your company's general or special terms and
            conditions. The fact that Choice did not explicitly reject the terms
            and conditions of the User shall not be interpreted by the User as
            an acceptance by Choice of such terms and conditions. If you do not
            agree to any provision of these Terms of Use, you shall stop using
            the Platform immediately, even if you already are a Registered User,
            Registered User Plus or a Commercial User.
          </p>
          <p className="privacy-p-small">
            2.2 We reserve the right at any time, and from time to time, with or
            without cause to:
          </p>
          <ol type="a">
            <li>
              change the Platform, including eliminating or discontinuing,
              temporarily or permanently, any service or other feature of the
              Platform without any liability towards the User or any third
              parties;{" "}
            </li>
            <li>
              amend or change these Terms of Use and/or the privacy and cookie
              policy; or{" "}
            </li>
            <li>
              deny or terminate, in part, temporarily or permanently, your use
              of and/or access to the Platform as set forth herein.{" "}
            </li>
          </ol>
          <p className="privacy-p-small">
            Any such amendments or changes made will be effective immediately
            upon us making such changes available in the Platform or otherwise
            providing notice thereof. You agree that your continued use of the
            Platform after such changes constitutes your acceptance of such
            changes.{" "}
          </p>
        </li>
        <li>
          <span className="privacy-policy-tertiary-title">
            USE OF THE PLATFORM
          </span>
          <p className="privacy-p-small">
            3.1 You may only use the Platform if you are 18 years old or older.
            To the extent authorised by the applicable laws, if you are aged
            between 13 and 18 years old, you may use the Platform provided that
            you and your parent or guardian have carefully read and agreed with
            these Terms of Use. If we believe that you are underaged, we reserve
            the right to delete your User Account and withhold your access to
            the Platform.{" "}
          </p>
          <p className="privacy-p-small">
            3.2 To use the Platform, your device requires a WIFI or mobile
            internet connection. The access to such WIFI or mobile internet
            connection is your sole responsibility. Choice shall not be liable
            for any loss or damage in connection with your failure to comply
            with the above requirements.{" "}
          </p>
          <p className="privacy-p-small">
            3.3 You will be required to create a password when registering to
            become a Registered User (Plus) or Commercial User. You shall be
            solely responsible for maintaining the confidentiality and security
            of your account login information such as your password. You shall
            be fully responsible for all activities that arise under your
            account. You shall immediately notify any unauthorised use, or
            suspected unauthorised use of your account or any other breach of
            security with respect to your account on the Platform which come to
            your knowledge. Choice shall not be liable for any loss or damage
            arising from your failure to comply with the abovementioned
            requirements.{" "}
          </p>
          <p className="privacy-p-small">
            3.4 Choice has the right, but not the obligation, at its sole
            discretion, to provide you with certain updates of the Platform.
          </p>
        </li>
        <li>
          <span className="privacy-policy-tertiary-title">
            THIRD-PARTY CONTENT AND THIRD-PARTY SERVICES
          </span>
          <p className="privacy-p-small">
            4.1 In order to (i) fully utilise certain elements available on or
            via the Platform or (ii) access the Platform through any domain via
            which the Platform is accessible, the Platform may include gateways,
            links, references to downloadable software or other functionalities
            or the User may be allowed or required to download software or
            access third-party services (the "
            <strong>Third-Party Services</strong>") and/or third-party Content
            (the "<strong>Third-Party Content</strong>"). If a User uses
            Third-Party Services or Third-Party Content through or via the
            Platform, such User shall be obliged to agree with and comply with
            the terms of service, terms of use, license agreements, end-user
            agreements, general terms and conditions, privacy and cookie
            policies, and/or security policy of the provider of such Third-Party
            Services or Third-Party Content (the "
            <strong>Third-Party ToU</strong>").
          </p>
          <p className="privacy-p-small">
            4.2 By accessing Third-party Services or Third-party Content, the
            User agrees to comply with the applicable Third-Party ToU and
            acknowledges to be the sole party to such Third-Party ToU. Choice
            shall not be held liable in any way in connection to the content of
            Third-Party ToU, or with respect to the compliance therewith by such
            third-party.
          </p>
          <p className="privacy-p-small">
            4.3 If the Platform allows Third-Party Services or Third-Party
            Content, Choice does not supply and is not responsible for any
            Third-Party Services or Third-Party Content.
          </p>
          <p className="privacy-p-small">
            4.4 The User shall defend, fully indemnify and hold Choice and its
            personnel, officers, directors, successors and assigns harmless from
            and against any and all direct and - 4 - indirect damages incurred
            by it in relation to the User breaching or otherwise not complying
            with Third-Party ToU.
          </p>
        </li>
        <li>
          <span className="privacy-policy-tertiary-title">
            LICENCE GRANTED TO THE USER
          </span>
          <p className="privacy-p-small">
            5.1 During the Term and subject to your compliance with the Terms of
            Use, Choice grants you a non-exclusive, personal, restricted,
            non-sublicensable and nontransferable licence to use the Platform in
            sole compliance with (i) these Terms of Use, (ii) any additional
            instructions or policies issued by Choice, including, but not
            limited to, those posted within the Platform and (iii) any
            applicable legislation, rules or regulations (the "
            <strong>License</strong>").
          </p>
          <p className="privacy-p-small">
            5.2 You agree to use the Platform only for its intended use as set
            forth in these Terms of Use and the additional instructions or
            policies issued by Choice, including, but not limited to, those
            posted within the Platform. Within the limits of the applicable
            legislation, rules and regulations, you are not permitted to:
          </p>
          <ol type="a">
            <li>
              alter, translate, adapt or modify in any manner the Platform;{" "}
            </li>
            <li>
              sublicense, lease, rent, loan, distribute, make available or
              otherwise transfer the Platform to any third party;
            </li>
            <li>
              make the Platform available or to sell or rent the Platform to any
              third party;{" "}
            </li>
            <li>
              decompile, reverse engineer, disassemble, decode, circumvent,
              reidentify, de-anonymise, unscramble, unencrypt or otherwise
              derive or determine or attempt to derive or determine the source
              code (or the underlying ideas, algorithms, structure or
              organisation) of the Platform;{" "}
            </li>
            <li>
              use or copy the Platform except as expressly allowed under this
              Clause 5;
            </li>
            <li>gain unauthorised access to accounts of other Users;</li>
            <li>
              use the Platform to conduct or promote any illegal activities;
            </li>
            <li>
              use the Platform to circulate unsolicited e-mail advertisements or
              spam;
            </li>
            <li>
              impersonate any person or entity, or otherwise misrepresent your
              affiliation with a person or entity;{" "}
            </li>
            <li>
              use any high volume automatic, electronic or manual processes to
              access, search or harvest information from the Platform (including
              without limitation algorithms, robots, spiders or scripts);{" "}
            </li>
            <li>
              alter, remove, or obscure any copyright notice, digital
              watermarks, proprietary legends or other notice included in the
              Platform;
            </li>
            <li>
              remove or in any manner circumvent any technical or other
              protective measures in the Platform; or{" "}
            </li>
            <li>
              intentionally distribute any worms, Trojan horses, corrupted
              files, or other items of a destructive or deceptive nature or use
              the Platform for any unlawful, invasive, infringing, defamatory or
              fraudulent purpose.
            </li>
            <li>
              upload or link Visual Media Content on the Platform which could be
              considered as Illegal Content or inappropriate Content (as defined
              hereinafter).
            </li>
          </ol>
          <p className="privacy-p-small">
            5.3 Except as expressly set forth herein, no express or implied
            licence or right of any kind is granted to you regarding the
            Platform thereof, including but not limited to any right to obtain
            possession of any source code, data or other technical material
            relating to the Platform
          </p>
          <p className="privacy-p-small">
            5.4 The License is for the personal use of Users only, unless
            specified otherwise in a User's Commercial User Agreement. Goods and
            services available on or via the Platform (including, but not
            limited to, Third-Party Services and Third-Party Content) and/or
            other domain or subdomain on which the Platform is available, may
            not be used in connection with any commercial activities except
            those which are endorsed and agreed by Choice. Any and all
            unauthorised commercial advertisements, affiliate links and other
            forms of solicitations will be removed from the Platform.
          </p>
        </li>
        <li>
          <span className="privacy-policy-tertiary-title">
            LICENCE GRANTED TO CHOICE
          </span>
          <p className="privacy-p-small">
            6.1 You grant Choice a non-exclusive, perpetual, royalty-free,
            worldwide, sublicensable, transferable, license to use, copy, store,
            modify, transmit and display the links you provide in the Platform
            and the information, data, or content you upload, create or
            otherwise provide through the Platform (including in each case your
            Visual Media Content and Channels) (the "
            <strong>User Content</strong>") and all Intellectual Property Rights
            (as defined hereinafter) included therein. Choice reserves the
            right, but is not obliged, at its sole discretion, to review and
            remove any User Content (including in each case your Visual Media
            Content, links and Channels) that Choice deems to be in violation
            with the provisions of these Terms of Use, any rights of
            third-parties, any applicable legislation or regulation, or that is
            otherwise deemed inappropriate.
          </p>
        </li>
        <li>
          <span className="privacy-policy-tertiary-title">FEES</span>
          <p className="privacy-p-small">
            Choice does not currently charge for use of the Platform. However,
            Choice reserves the right to, at its sole discretion, do so in the
            future. If Choice decides to charge the User for the use of the
            Platform, such charge will be disclosed to you prior to taking
            effect and if you do not wish to pay such charges, your sole and
            exclusive remedy will be to terminate your use of the Platform, at
            which point each party's obligations under these Terms of Use will
            automatically terminate, expect as otherwise stated in these Terms
            of Use.{" "}
          </p>
        </li>
        <li>
          <span className="privacy-policy-tertiary-title">DATA PROTECTION</span>
          <p className="privacy-p-small">
            When performing its obligations under these Terms of Use, Choice
            will process some of your personal data. In this case, Choice's
            privacy policy{" "}
            <a
              href="https://watchchoice.tv/?page=privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://watchchoice.tv/?page=privacy-policy
            </a>{" "}
            will apply.{" "}
          </p>
        </li>
        <li>
          <span className="privacy-policy-tertiary-title">
            INTELLECTUAL PROPERTY RIGHTS{" "}
          </span>
          <p className="privacy-p-small">
            9.1 For purposes of these Terms of Use, "
            <storng>Intellectual Property Rights</storng>" shall mean any and
            all now known or hereafter existing (a) rights associated with works
            of authorship, including copyrights, mask work rights, and moral
            rights; (b) trademark or service mark rights; (c) trade secret
            rights, know-how; (d) patents, patent rights, and industrial
            property rights; (e) layout design rights, design rights, (f) trade
            and business names, domain names, database rights, rental rights and
            any other industrial or intellectual proprietary rights or similar
            right (whether registered or unregistered); (g) all registrations,
            applications for registration, renewals, extensions, divisions,
            improvements or reissues relating to any of these rights and the
            right to apply for, maintain and enforce any of the preceding items,
            in each case in any jurisdiction throughout the world.
          </p>
          <p className="privacy-p-small margin-10">
            9.2 Except for the limited licence expressly granted in Clause 5 by
            Choice to the User, Choice will exclusively retain all rights,
            titles and interests, including any Intellectual Property Rights, in
            and to the Platform (or any part thereof), it being understood that
            nothing in these Terms of Use shall constitute or be construed as a
            transfer of ownership in any proprietary right or title, including
            in any Intellectual Property Rights of Choice to the User.
          </p>
          <p className="privacy-p-small margin-10">
            9.3 Except for the limited licence expressly granted in Clause 6 by
            the User to Choice, the User will retain all rights, titles and
            interests, including any Intellectual Property Rights, in and to the
            User Content, it being understood that nothing in these Terms of Use
            shall constitute or be construed as a transfer of ownership in any
            proprietary right or title, including in any Intellectual Property
            Rights of the User to Choice.
          </p>
        </li>
        <li>
          <span className="privacy-policy-tertiary-title">
            SUSPENSION FOR BREACH
          </span>
          <p className="privacy-p-small">
            Choice shall be entitled, at its sole discretion, to suspend or
            limit your access to the Platform if it becomes aware or suspects,
            at its sole discretion, that you violated any provision of these
            Terms of Use or any other instructions, guidelines or policies
            issued by Choice. The duration of any suspension by Choice shall be
            until you have cured the breach which caused such suspension or
            limitation.
          </p>
        </li>
        <li>
          <span className="privacy-policy-tertiary-title">LIABILITY</span>
          <p className="privacy-p-small margin-10">
            11.1 To the maximum extent permitted under applicable law, Choice
            shall only be liable for damages resulting from (i) its gross
            negligence or fraud, or (ii) its wilful misconduct.
          </p>
          <p className="privacy-p-small margin-10">
            11.2 To the extent legally permitted under applicable law, Choice
            shall not be liable to the User or any third-party, for any special,
            indirect, exemplary, punitive, incidental or consequential damages
            of any nature including, but not limited to damages or costs due to
            loss of profits, data, revenue, goodwill, production of use,
            procurement of substitute services, or property damage arising out
            of or in connection with the Platform under these Terms of Use,
            including but not limited to any miscalculations, or the use,
            misuse, or inability to use the Platform, regardless of the cause of
            action or the theory of liability, whether in tort, contract, or
            otherwise, even if Choice have been notified of the likelihood of
            such damages.
          </p>
          <p className="privacy-p-small margin-10">
            11.3 You agree that Choice can only be held liable as per the terms
            of this Clause 11 to the extent damages suffered by you are directly
            attributable to Choice. <em>For the avoidance of doubt</em>, Choice
            shall not be liable for any claims resulting from or in connection
            with:
          </p>
          <ol type="a">
            <li>
              the accuracy, completeness, correctness or adequateness for its
              intended use of the User Content (including in each case your
              Visual Media Content and Channels), the Third-Party Services and
              Third-Party Content;{" "}
            </li>
            <li>your unauthorised use of the Platform;</li>
            <li>
              your or any third-party's modification of (any parts of) the
              Platform;
            </li>
            <li>
              your failure to use the most recent version of the Platform made
              available to you or your failure to integrate or install any
              corrections to the Platform issued by Choice; or{" "}
            </li>
            <li>
              your use of the Platform in combination with any non-Company
              products or services.{" "}
            </li>
          </ol>
          <p className="privacy-p-small margin-10">
            11.4 The exclusions and limitations of liability under this Clause
            shall operate to the benefit of any of Company’s affiliates and
            subcontractors under these Terms of Use to the same extent such
            provisions operate to the benefit of Choice.
          </p>
        </li>
        <li>
          <span className="privacy-policy-tertiary-title">WARRANTIES</span>
          <p className="privacy-p-small margin-10">12.1 By the User</p>
          <p className="privacy-p-small margin-10 margin-left-30">
            12.1.1 You represent and warrant to Choice that you are 18 years old
            or above or, if you are aged between 13 and 18 years, that you and
            your parent or guardian have read, reviewed and accepted these Terms
            of Use.
          </p>
          <p className="privacy-p-small margin-10 margin-left-30">
            12.1.2 You represent and warrant to Choice that any User Content
            provided by you (including in each case your Visual Media Content,
            Channels and the hyperlinks or references uploaded on the Platform
            referring to your Visual Media Content) is accurate, complete,
            truthful and adequate for its intended use through the Platform, and
            shall not at any point in time:
          </p>
          <ol type="a">
            <li>
              infringe any Intellectual Property Rights of third parties;{" "}
            </li>
            <li>
              constitute a violation of a person's right to privacy or data
              protection;
            </li>
            <li>misappropriate any trade secret; </li>
            <li>
              be threatening, vulgar, deceptive, defamatory, obscene,
              pornographic or otherwise objectionable;{" "}
            </li>
            <li>
              contain any unwanted commercial content, such as spam, unsolicited
              messages, whose purpose is to drive Users to third-party websites
              [in breach of the Terms and Conditions];{" "}
            </li>
            <li>contain any Virus; </li>
            <li>be considered illegal; or</li>
            <li>otherwise violate the rights of a third-party</li>
          </ol>
          <p className="privacy-p-small margin-10 margin-left-30">
            For the purposes of these Terms of Use, <strong>"Virus"</strong>{" "}
            means a virus, cancelbot, worm, time-bombs, logic bomb, keystroke
            loggers, spyware, Trojan horse, adware or other harmful program,
            component of software/data or similar computer code that adversely
            affects the operation of any computer software, hardware, system or
            data.
          </p>
          <p></p>
          <p className="privacy-p-small margin-10 margin-left-30">
            12.1.3 The User acknowledges and agrees that Choice is not obligated
            to endorse any User Content. You agree that any use of the Platform
            contrary to or in violation of these representations and warranties
            shall constitute unauthorised and improper use of the Platform for
            which Choice cannot be held liable.
          </p>
          <p></p>
          <p className="privacy-p-small margin-10">12.2 By Choice</p>
          <p className="privacy-p-small margin-10 margin-left-30">
            12.2.1 Except as expressly provided in this Clause 12.2 and to the
            maximum extent permitted by applicable law:
          </p>
          <ol type="a">
            <li>the Platform is provided "as is"; and</li>
            <li>
              Choice makes no (and hereby disclaims all) other warranties,
              guarantees, covenants or representations, or conditions, whether
              written, oral, express or implied including, without limitation,
              any implied warranties of satisfactory quality, course of dealing,
              trade usage or practice, merchantability, accuracy, suitability,
              availability, title, noninfringement, or fitness for a particular
              use or purpose, with respect to (i) the use, misuse, or inability
              to use the Platform or any other products or services provided to
              the User by Choice or accessible through the Platform and (ii) the
              Third-Party Services and ThirdParty Content.{" "}
            </li>
          </ol>
          <p className="privacy-p-small margin-10 margin-left-30">
            Choice does not warrant that the Platform or its content will meet
            your requirements, is error-free, secure, reliable or will operate
            without interruption.{" "}
          </p>
          <p></p>
          <p className="privacy-p-small margin-10 margin-left-30">
            12.2.2 The User acknowledges and agrees that there are risks
            inherent to transmitting information over and storing information on
            the internet and that Choice is not responsible and cannot be held
            liable for any losses of your data, including but not limited to the
            User Content.{" "}
          </p>
          <p></p>
          <p></p>
        </li>
        <li>
          <span className="privacy-policy-tertiary-title">INDEMNIFICATION</span>
          <p className="privacy-p-small margin-10">13.1 By the User</p>
          <p className="privacy-p-small margin-10 margin-left-30">
            13.1.1 You hereby agree to indemnify and hold Choice and its current
            and future affiliates, officers, directors, employees, agents and
            representatives harmless from each and every demand, claim, loss,
            liability, or damage of any kind whatsoever (including reasonable
            attorney's fees) whether in tort or in contract, that it or any of
            them may incur by reason of, arising out of or in connection with
            any claim which is made by any third party with respect to:
          </p>
          <ol type="a">
            <li>
              any breach or violation by you of any provisions of these Terms of
              Use (including, but not limited to, a breach of the
              representations and warranties as set out in Clause 12.1) or any
              other instructions or policies issued by Choice;{" "}
            </li>
            <li>
              any User Content violating any Intellectual Property Rights of a
              third party; or
            </li>
            <li>
              fraud, wilful misconduct, or gross negligence committed by you.
            </li>
          </ol>
          <p></p>
          <p className="privacy-p-small margin-10 margin-left-30">
            13.1.2 If you are aged between 13 and 18 years old, your parent or
            guardian shall be jointly and severally responsible for indemnifying
            Choice under this Clause 13.1.
          </p>
          <p></p>
          <p className="privacy-p-small margin-10">13.2 By Choice</p>
          <p className="privacy-p-small margin-10 margin-left-30">
            13.2.1 Choice shall defend and indemnify you as specified herein
            against any founded and well-substantiated claim (excluding indirect
            or consequential damages) - 9 - brought by third-parties to the
            extent such claim is based on an infringement of the Intellectual
            Property Rights of such third-party by the Platform and excluding
            any claims resulting from or in connection with:
          </p>
          <ol type="a">
            <li>
              the accuracy, completeness, correctness or adequateness for its
              intended use of the User Content (including in each case your
              Visual Media Content and Channels), Third-Party Services and/or
              Third-Party Content;{" "}
            </li>
            <li>your unauthorised use of the Platform;</li>
            <li>
              your or any third-party's modification of (any parts of) the
              Platform;
            </li>
            <li>
              your failure to use the most recent version of the Platform made
              available to you or your failure to integrate or install any
              corrections to the Platform issued by Choice; or
            </li>
            <li>
              your use of the Platform in combination with any non-Company
              products or services.
            </li>
          </ol>
          <p></p>
          <p className="privacy-p-small margin-10 margin-left-30">
            13.2.2 The indemnity obligation set out in Clause 13.2.1 shall be
            conditional upon the following:
          </p>
          <ol type="a">
            <li>Choice is given prompt written notice of any such claim;</li>
            <li>
              Choice is granted sole control of the defence and settlement of
              such a claim;
            </li>
            <li>
              upon Choice's request, the User fully cooperates with Choice in
              the defence and settlement of such a claim, at Choice's expense;
              and
            </li>
            <li>
              the User makes no admission as to Choice's liability in respect of
              such a claim, nor does the User agree to any settlement in respect
              of such a claim without Choice's prior written consent.{" "}
            </li>
          </ol>
          <p></p>
          <p className="privacy-p-small margin-10 margin-left-30">
            13.2.3 Provided that the conditions set out in these Terms of Use
            are met, Choice shall indemnify the User for all damages and costs
            incurred by the User as a result of such a claim set out in Clause
            13.2.1, up to an amount equal to the fees (if any) paid by the User
            to Choice during the preceding month.
          </p>
          <p className="privacy-p-small margin-10 margin-left-30">
            13.2.4 In the event the Platform, in Choice's reasonable opinion, is
            likely to or becomes the subject of a third-party infringement claim
            (as per this Clause 13.2.1), Choice shall have the right, at its
            sole discretion and expense, to:
          </p>
          <ol type="a">
            <li>
              modify the (allegedly) infringing part of the Platform so that it
              becomes non-infringing while preserving materially equivalent
              functionalities;
            </li>
            <li>
              obtain for the User a licence to continue using the Platform in
              accordance with these Terms of Use; or
            </li>
            <li>
              terminate the Terms of Use and/or any other agreement entered into
              between the User and Choice and refund up to an amount equal to
              the fees (if any) paid by the User to Choice during the preceding
              month.
            </li>
          </ol>
          <p></p>
          <p className="privacy-p-small margin-10 margin-left-30">
            13.2.5 The foregoing states the entire liability and obligation of
            Choice and the sole remedy of the User with respect to any
            infringement or alleged infringement of any Intellectual Property
            Rights caused by the Platform or any part thereof.
          </p>
          <p></p>
        </li>
        <li>
          <span className="privacy-policy-tertiary-title">TERM</span>
          <p className="privacy-p-small margin-10">
            14.1 These Terms of Use shall remain in full force as long as you
            are using the Platform and the License, unless these Terms of Use
            are terminated prior in accordance with Clause 15 (the "
            <storng>Term</storng>").
          </p>
          <p className="privacy-p-small margin-10">
            14.2 At the end of the Term, any rights on the Platform that were
            granted to you under these Terms of Use shall automatically expire.
          </p>
        </li>
        <li>
          <span className="privacy-policy-tertiary-title">TERMINATION</span>
          <p className="privacy-p-small margin-10">
            15.1 Choice may terminate with immediate effect these Terms of Use
            and your right to access and use of the Platform if Choice believes
            or has reasonable grounds to suspect that you are violating these
            Terms of Use (including but not limited to any violation of the
            Intellectual Property Rights of Choice), or any other guidelines or
            policies issued by Choice.
          </p>
          <p className="privacy-p-small margin-10">
            15.2 Upon the termination of these Terms of Use for any reason
            whatsoever in accordance with the provisions of these Terms of Use,
            at the moment of effective termination:
          </p>
          <ol type="a">
            <li>
              you will no longer be authorised to access or use the Platform;{" "}
            </li>
            <li>
              if you are a Registered User, Registered User Plus or Commercial
              User, your account on the Platform will be deleted; and{" "}
            </li>
            <li>
              all rights and obligations of Choice or the User under these Terms
              of Use shall terminate, except those rights and obligations under
              those Clause specifically designated in Clause 16.5.
            </li>
          </ol>
          <p></p>
        </li>
        <li>
          <span className="privacy-policy-tertiary-title">MISCELLANEOUS</span>
          <p className="privacy-p-small margin-10">16.1 Force Majeure</p>
          <p className="privacy-p-small margin-left-30">
            Choice shall not be liable for any failure or delay in the
            performance of its obligations with regard to the Platform if such
            delay or failure is due to causes beyond our control including by
            not limited to war, strikes or labour disputes, embargoes,
            government orders, telecommunications, network, computer, server or
            internet downtime, unauthorised access to Company's information
            technology systems by third parties or any other cause beyond the
            reasonable control of Choice (the "
            <strong>Force Majeure Event</strong>"). We shall notify you of the
            nature of such Force Majeure Event and the effect on our ability to
            perform our obligations under these Terms of Use, and how we plan to
            mitigate the effect of such Force Majeure Event.
          </p>
          <p></p>
          <p className="privacy-p-small margin-10">16.2 Severability</p>
          <p className="privacy-p-small margin-left-30">
            In the event that any provision of these Terms of Use are, for any
            reason, held to be invalid, void or unenforceable, the other
            provisions of these Terms of Use will remain enforceable and the
            invalid, void or unenforceable provision will be deemed modified so
            that it is valid and enforceable to the maximum extent permitted by
            law.
          </p>
          <p></p>
          <p className="privacy-p-small margin-10">16.3 Waiver</p>
          <p className="privacy-p-small margin-left-30">
            Any failure to enforce any provision of the Terms of Use shall not
            constitute a waiver thereof or of any other provision
          </p>
          <p></p>
          <p className="privacy-p-small margin-10">16.4 Assignment</p>
          <p className="privacy-p-small margin-left-30">
            16.4.1 You may not assign or transfer these Terms of Use, or any
            other rights or obligations to any third party.
          </p>
          <p className="privacy-p-small margin-left-30">
            16.4.1 You may not assign or transfer these Terms of Use, or any
            other rights or obligations to any third party.
          </p>
          <p className="privacy-p-small margin-left-30">
            16.4.2 Choice shall be free to:
          </p>
          <ol type="a" className="margin-left-30">
            <li>
              transfer or assign (part of) its obligations or rights under the
              Terms of Use to one of its affiliates; and
            </li>
            <li>
              to subcontract performance or the support of the performance of
              these Terms of Use, to its affiliates, to individual contractors
              and to third party service providers without prior notification to
              the User.{" "}
            </li>
          </ol>
          <p></p>
          <p className="privacy-p-small margin-10">16.5 Survival</p>
          <p className="privacy-p-small margin-left-30">
            Clauses 9, 11, 12 and 13 shall survive any termination or expiration
            of these Terms of Use.
          </p>
          <p className="privacy-p-small margin-10">16.6 Discount vouchers</p>
          <ol type="a">
            <li>
              Choice offers the possibility to use discount vouchers: with the
              code of your discount voucher, a specific amount is deducted from
              the price of a specific product or service offered on the Choice
              platform.
            </li>
            <li>Each Choice discount voucher can only be used once.</li>
            <li>
              All Choice discount vouchers are valid for a certain period of
              time and may differ from each other.
            </li>
            <li>
              A Choice discount voucher is only valid for online payment methods
              on the Choice platform.
            </li>
            <li>
              A Choice discount voucher can be redeemed by using it on the
              according payment page on the Choice platform.
            </li>
            <li>
              If after using a Choice discount voucher, there is still any
              remaining amount for this Choice discount voucher, this Choice
              discount voucher will be reduced to € 0.
            </li>
            <li>
              A Choice discount voucher can no longer be used after the expiry
              date.
            </li>
            <li>
              Copying or (re)selling a Choice discount voucher is strictly
              prohibited. Attempts to copy or (re)sell a Choice discount voucher
              may invalidate the voucher.
            </li>
            <li>
              A Choice discount voucher cannot be used in combination with any
              other vouchers or offered discounts.
            </li>
            <li>
              The value of a Choice discount voucher cannot be refunded or
              converted into cash.
            </li>
            <li>
              A Choice discount voucher cannot be replaced if it is lost,
              damaged or stolen.
            </li>
            <li>
              Choice is entitled to cancel the use of any Choice discount
              vouchers at any time.
            </li>
            <li>
              A Choice discount voucher may be linked to certain terms of use
              (such as minimum order value).
            </li>
            <li>
              Choice may unilaterally change these terms and conditions for
              Choice discount vouchers at any time at its sole discretion.
            </li>
            <li>
              When a Choice discount voucher has been used for an order that is
              subsequently cancelled, the voucher value will not be restored.
            </li>
          </ol>
          <p></p>
          <p className="privacy-p-small margin-10">
            16.7 Vanity URL and Nicknames
          </p>
          <ol type="a">
            <li>
              Choice may offer to its members a custom URL for a playlist on
              Choice (vanity URL) and can offer nicknames to its members.
            </li>
            <li>
              Choice can at any time remove or change the custom URL or the
              nickname of a user.
            </li>
            <li>
              If a Choice user selects a custom URL or nickname that uses partly
              or in whole a brand name that is protected by any copyright, it is
              the Choice user who is responsible to have received the required
              rights or licenses from the brand to use this custom URL or
              nickname. The user holds Choice harmless.
            </li>
          </ol>
          <p></p>
          <p className="privacy-p-small margin-10">16.8 TV channels packages</p>
          <ol type="a">
            <li>
              Choice Partners offer TV channel packages with TV channels to the
              Choice users.
            </li>
            <li>
              Choice has at any time the right to change (reduce, replace , add)
              channels in such a package and this at any time, without that the
              subscribers for this package can cancel their subscription or can
              claim any compensation.
            </li>
          </ol>
          <p></p>
          <p className="privacy-p-small margin-10">
            16.9 Governing law and jurisdiction
          </p>
          <p>
            These Terms of Use shall be exclusively governed by and construed in
            accordance with the laws of Belgium, without giving effect to any of
            its conflict of law principles or rules. The courts and tribunals of
            Leuven shall have sole jurisdiction should any dispute arise
            relating to these Terms of Use.
          </p>
          <p></p>
        </li>
      </ol>
    </div>
  );
};

export default TermsOfUsePrototype;
