import { axiosInstance } from "../../modules/request/api";
import {
  CHANNEL_GROUPS_REQUEST,
  CHANNEL_GROUPS_SUCCESS,
  CHANNEL_GROUPS_FAIL,
  CHANNEL_ITEMS_REQUEST,
  CHANNEL_ITEMS_SUCCESS,
  CHANNEL_ITEMS_FAIL,
  CHANNELS_REQUEST,
  CHANNELS_SUCCESS,
  CHANNELS_FAIL,
  CURRENT_CHANNEL_ITEMS_REQUEST,
  CURRENT_CHANNEL_ITEMS_SUCCESS,
  CURRENT_CHANNEL_ITEMS_FAIL,
  CREATE_CHANNELGROUP_REQUEST,
  CREATE_CHANNELGROUP_SUCCESS,
  CREATE_CHANNELGROUP_FAIL,
  DELETE_CHANNELGROUP_FAIL,
  DELETE_CHANNELGROUP_REQUEST,
  DELETE_CHANNELGROUP_SUCCESS,
  CREATE_CHANNEL_ITEM_REQUEST,
  CREATE_CHANNEL_ITEM_SUCCESS,
  CREATE_CHANNEL_ITEM_FAIL,
  UPDATE_CHANNELITEM_REQUEST,
  UPDATE_CHANNELITEM_FAIL,
  UPDATE_CHANNELITEM_SUCCESS,
  EPG_CHANNELS_REQUEST,
  EPG_CHANNELS_SUCCESS,
  EPG_CHANNELS_FAIL,
  CHANNEL_KEYWORDS_REQUEST,
  CHANNEL_KEYWORDS_SUCCESS,
  CHANNEL_KEYWORDS_FAIL,
  CHANNEL_SEARCH_REQUEST,
  CHANNEL_SEARCH_SUCCESS,
  CHANNEL_SEARCH_FAIL,
  CHANNEL_BY_ID_REQUEST,
  CHANNEL_BY_ID_SUCCESS,
  CHANNEL_BY_ID_FAIL,
  DUTCH_CHANNELGROUPS_REQUEST,
  DUTCH_CHANNELGROUPS_SUCCESS,
  DUTCH_CHANNELGROUPS_FAIL,
  DUTCH_CHANNELS_REQUEST,
  DUTCH_CHANNELS_SUCCESS,
  DUTCH_CHANNELS_FAIL,
  CHANNEL_BY_ID_RESET,
  COPY_TIRES_REQUEST,
  COPY_TIRES_SUCCESS,
  COPY_TIRES_FAIL,
  PROMOTION_CHANNELS_REQUEST,
  PROMOTION_CHANNELS_SUCCESS,
  PROMOTION_CHANNELS_FAIL,
  CHANNELS_EPG_DIRECT_SHARE_REQUEST,
  CHANNELS_EPG_DIRECT_SHARE_SUCCESS,
  CHANNELS_EPG_DIRECT_SHARE_FAIL,
  SUBSCRIBE_TO_CHANNEL_REQUEST,
  SUBSCRIBE_TO_CHANNEL_SUCCESS,
  SUBSCRIBE_TO_CHANNEL_FAIL,
  UNSUBSCRIBE_TO_CHANNEL_REQUEST,
  UNSUBSCRIBE_TO_CHANNEL_SUCCESS,
  UNSUBSCRIBE_TO_CHANNEL_FAIL,
} from "../constants/channelConstants";
import { CHOICE_URL } from "@modules/config";
import { getCopiedTiers } from "./shortcutActions";

export const getChannelGroups = () => async (dispatch) => {
  try {
    dispatch({ type: CHANNEL_GROUPS_REQUEST });
    // --------------- Sweeft API ------------- //
    // const { data } = await axiosInstance().get(
    //     `${API_URL}/tv/channel/getChannelGroups?choiceToken=${token}`,
    // );
    // --------------------------------------- //

    //--------------- Choice API --------------//

    const { data } = await axiosInstance().get(
      `${CHOICE_URL}/api/channelgroup`
    );
    // --------------------------------------- //

    dispatch({ type: CHANNEL_GROUPS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CHANNEL_GROUPS_FAIL, payload: error.response });
  }
};

export const getChannelItems = () => async (dispatch) => {
  try {
    dispatch({ type: CHANNEL_ITEMS_REQUEST });
    // --------------- Sweeft API ------------- //
    // const { data } = await axiosInstance().get(
    //     `${API_URL}/tv/channel/getChannelItems?choiceToken=${token}`,
    // );
    // --------------------------------------- //

    //--------------- Choice API --------------//
    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/channelitem`);
    // ----------------------------------------//

    dispatch({ type: CHANNEL_ITEMS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CHANNEL_ITEMS_FAIL, payload: error.response });
  }
};

export const getCurrentChannelItems = (channelId) => async (dispatch) => {
  try {
    dispatch({ type: CURRENT_CHANNEL_ITEMS_REQUEST });

    const { data } = await axiosInstance().get(
      `${CHOICE_URL}/api/channel/${channelId}/items`
    );
    dispatch({ type: CURRENT_CHANNEL_ITEMS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CURRENT_CHANNEL_ITEMS_FAIL, payload: error.response });
  }
};

export const createChannelItem = (body) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CHANNEL_ITEM_REQUEST });

    const dummyData = {
      url: "https://www.youtube.com/watch?v=Pr9TdbTDMH0",
      title: "Test3",
      channel_id: "094acda8-5e12-4c0d-8614-a57c892ebda9",
    };

    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/channelitem`,
      dummyData
    );

    dispatch({ type: CREATE_CHANNEL_ITEM_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CREATE_CHANNEL_ITEM_FAIL, payload: error.response });
  }
};

export const updateChannelItem = (channelItemId, body) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CHANNELITEM_REQUEST });

    const { data } = await axiosInstance().patch(
      `${CHOICE_URL}/api/channelitem/${channelItemId}`,
      body
    );
    dispatch({ type: UPDATE_CHANNELITEM_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_CHANNELITEM_FAIL, payload: error.response });
  }
};

export const getChannels = () => async (dispatch) => {
  try {
    dispatch({ type: CHANNELS_REQUEST });

    // --------------- Sweeft API ------------- //
    // const { data } = await axiosInstance().get(
    //     `${API_URL}/tv/channel/getChannels`,
    // );
    //--------------------------------------- //

    //---------------- Choice API -------------//
    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/channel`);
    //-----------------------------------------//

    dispatch({ type: CHANNELS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CHANNELS_FAIL, payload: error });
  }
};

// channel group

export const createChannelGroup = (newGroup) => async (dispatch) => {
  const { name, type, icon, user_id } = newGroup;

  try {
    dispatch({ type: CREATE_CHANNELGROUP_REQUEST });

    const body = {
      name,
      type,
      icon,
      user_id,
    };

    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/channelgroup`,
      body
    );

    dispatch({ type: CREATE_CHANNELGROUP_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CREATE_CHANNELGROUP_FAIL,
      payload: error.response,
    });
  }
};

export const deleteChannelGroup = (groupId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CHANNELGROUP_REQUEST });

    await axiosInstance().delete(`${CHOICE_URL}/api/channelgroup/${groupId}`);
    dispatch({ type: DELETE_CHANNELGROUP_SUCCESS });
  } catch (error) {
    dispatch({ type: DELETE_CHANNELGROUP_FAIL, payload: error.response });
  }
};

export const getEpgChannels = (channelId) => async (dispatch) => {
  try {
    //https://api.dev.choice.be/api/channel/71f04bce-4f55-46f8-8288-8c7c50fd6492
    dispatch({ type: EPG_CHANNELS_REQUEST });
    const { data } = await axiosInstance().get(
      // `https://oauth.choice.be/api/epg/${epgProviderChannelName}`
      `${CHOICE_URL}/api/channel/${channelId}/epg`
    );
    dispatch({ type: EPG_CHANNELS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: EPG_CHANNELS_FAIL, payload: error?.response });
  }
};

export const getChannelById = (channelId) => async (dispatch) => {
  try {
    dispatch({ type: CHANNEL_BY_ID_REQUEST });
    const { data } = await axiosInstance().get(
      `${CHOICE_URL}/api/channel/${channelId}`
    );
    dispatch({ type: CHANNEL_BY_ID_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CHANNEL_BY_ID_FAIL, payload: error?.response });
  }
};

export const resetChannelsById = () => ({
  type: CHANNEL_BY_ID_RESET,
});

export const getChannelSuggestions = (query) => async (dispatch) => {
  try {
    dispatch({ type: CHANNEL_KEYWORDS_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/channel/keywords`,
      { query }
    );
    dispatch({ type: CHANNEL_KEYWORDS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CHANNEL_KEYWORDS_FAIL, payload: error?.response });
  }
};

export const searchChannels = (query) => async (dispatch) => {
  try {
    dispatch({ type: CHANNEL_SEARCH_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/channel/search`,
      { query }
    );

    dispatch({ type: CHANNEL_SEARCH_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CHANNEL_SEARCH_FAIL, payload: error?.response });
  }
};

export const getDutchChannelGroups = () => async (dispatch) => {
  try {
    dispatch({ type: DUTCH_CHANNELGROUPS_REQUEST });
    const { data } = await axiosInstance({
      basicToken: true,
    }).get(`${window.location.origin}/dutch_dummy_channels.json`);
    dispatch({ type: DUTCH_CHANNELGROUPS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DUTCH_CHANNELGROUPS_FAIL,
      payload: error?.response,
    });
  }
};

export const getDutchChannels = (channelUrl) => async (dispatch) => {
  try {
    dispatch({ type: DUTCH_CHANNELS_REQUEST });

    const { data } = await axiosInstance({ withToken: false }).get(channelUrl);
    dispatch({ type: DUTCH_CHANNELS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DUTCH_CHANNELS_FAIL,
      payload: { error: "Cant fetch" },
    });
  }
};

export const copyTier =
  (copiedTier, refetch = true) =>
  async (dispatch) => {
    try {
      dispatch({ type: COPY_TIRES_REQUEST });

      const { data } = await axiosInstance().post(
        `${CHOICE_URL}/api/shortcuts`,
        copiedTier
      );

      dispatch({
        type: COPY_TIRES_SUCCESS,
        payload: data,
      });
      if (refetch) {
        dispatch(getCopiedTiers());
      }
    } catch (error) {
      dispatch({
        type: COPY_TIRES_FAIL,
        payload: error.response,
      });
    }
  };

export const getPromotedChannels = () => async (dispatch) => {
  try {
    dispatch({
      type: PROMOTION_CHANNELS_REQUEST,
    });

    const { data } = await axiosInstance().get(`${CHOICE_URL}/api/promotions`);

    dispatch({
      type: PROMOTION_CHANNELS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROMOTION_CHANNELS_FAIL,
      payload: { error: "Cant fetch promoted channels" },
    });
  }
};

export const directShareChannelEpg = (epgId, url) => async (dispatch) => {
  try {
    dispatch({ type: CHANNELS_EPG_DIRECT_SHARE_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/direct-share/epg`,
      {
        epg_id: epgId,
        url,
      }
    );
    dispatch({ type: CHANNELS_EPG_DIRECT_SHARE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CHANNELS_EPG_DIRECT_SHARE_FAIL, payload: error.response });
  }
};

export const subscribeToChanel = (channelId) => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIBE_TO_CHANNEL_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/tier/subscribe-tierable`,
      {
        tierable_id: channelId,
      }
    );
    dispatch({ type: SUBSCRIBE_TO_CHANNEL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SUBSCRIBE_TO_CHANNEL_FAIL, payload: error.response });
  }
};

export const unsubscribeToChanel = (channelId) => async (dispatch) => {
  try {
    dispatch({ type: UNSUBSCRIBE_TO_CHANNEL_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/tier/unsubscribe-tierable`,
      {
        tierable_id: channelId,
      }
    );
    dispatch({ type: UNSUBSCRIBE_TO_CHANNEL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UNSUBSCRIBE_TO_CHANNEL_FAIL, payload: error.response });
  }
};