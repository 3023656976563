import { useContext, useEffect, useState } from "react";
import Modal from "@shared/atoms/Modal/index";
import Image from "@shared/atoms/Image";
import Input from "@shared/atoms/Input";
import { Context } from "../../../../../context/context";
import facebook from "../../../../../assets/icons/facebook-icon.svg";
import whatsApp from "../../../../../assets/icons/whatsApp-icon.svg";
import twitter from "../../../../../assets/icons/twitter-icon.svg";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  EmailIcon,
} from "react-share";

import { useTranslation } from "react-i18next";
import "./index.less";

const ShareModalPrototype = () => {
  const [copyClass, setCopyClass] = useState("");
  const {
    showShareModalPrototype,
    setShowShareModalPrototype,
    shareableLink,
    setSharebaleLink,
  } = useContext(Context);

  let href = shareableLink || window.location.href;

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setSharebaleLink(null);
    };
  }, [setSharebaleLink]);

  const handleClose = () => {
    setShowShareModalPrototype(false);
    setSharebaleLink(null);
  };

  const copyText = () => {
    navigator.clipboard.writeText(href);
    Promise.resolve(
      setTimeout(() => {
        setCopyClass("");
      }, 200)
    ).then(() => {
      setCopyClass("copy-ok");
    });
  };
  return (
    <Modal
      className="modal-basic-prototype share-modal-prototype"
      onCancel={handleClose}
      closable={true}
      visible={showShareModalPrototype}
      footer=""
    >
      <div className="social-icons-container">
        <FacebookShareButton
          url={href}
          //   quote={selectedPlaylist?.name}
        >
          <Image src={facebook} alt="facebook" />
        </FacebookShareButton>
        <WhatsappShareButton
          //   title={selectedPlaylist?.name}
          url={href}
        >
          <Image src={whatsApp} alt="whatsApp" />
        </WhatsappShareButton>
        <TwitterShareButton
          //   title={selectedPlaylist?.name}
          url={href}
        >
          <Image src={twitter} alt="twitter" />
        </TwitterShareButton>
        <EmailShareButton
          url={href}
          //   subject={`Playlist: ${selectedPlaylist?.name}`}
          body={"Choice TV: "}
        >
          <EmailIcon size={48} round={true} />
        </EmailShareButton>
      </div>
      <p className="copy-para">{t("share_copy_prototype")}</p>
      <div className={`copy-input-container ${copyClass}`}>
        <div className="copy" onClick={copyText}></div>
        <Input className="copy-input" placeholder="copy link" value={href} />
      </div>
    </Modal>
  );
};

export default ShareModalPrototype;
