import React, { useContext } from "react";
import { Context } from "@context/context";

import Cast from "@components/shared/atoms/icons/Cast";

export default function CastButton({ videoUrl }) {
  const { videoPlayerItemRef } = useContext(Context);

  const videoPlayer = videoPlayerItemRef?.current?.firstChild?.firstChild;

  var initializeCastApi = function () {
    if (window.chrome) {
      var sessionRequest = new window.chrome.cast.SessionRequest(
        window.chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID
      );
      var apiConfig = new window.chrome.cast.ApiConfig(
        sessionRequest,
        sessionListener,
        receiverListener
      );
      window.chrome.cast.initialize(apiConfig, onInitSuccess, onError);
    }
  };

  if (window.chrome && (window.chrome.cast || window.chrome.cast?.isAvailable)) {
    setTimeout(initializeCastApi, 1000);
  }

  function onInitSuccess() {
    console.log("onInitSuccess");
  }

  function onError() {
    throw new Error("Cast init error ");
  }

  function sessionListener(e) {
    console.log("sessionListener", e);
  }

  function receiverListener(availability) {
    if (window.chrome) {
      if (availability === window.chrome.cast.ReceiverAvailability.AVAILABLE) {
        console.log("AVAILABLE");
      }
    }
  }

  function onSessionRequestSuccess(session) {
    var mediaInfo = new window.chrome.cast.media.MediaInfo(videoUrl);
    var request = new window.chrome.cast.media.LoadRequest(mediaInfo);
    session.loadMedia(request, onMediaLoadSuccess, onError);
  }

  function onMediaLoadSuccess(e) {
    if (videoPlayer) {
      videoPlayer.pause();
    }
  }

  return (
    <>
      {window.chrome && (
        <Cast
          onClick={() =>
            window.chrome.cast.requestSession(onSessionRequestSuccess, onError)
          }
        ></Cast>
      )}
    </>
  );
}
