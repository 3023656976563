import React, { useContext, useRef, useState, useEffect } from "react";
import { Context } from "../../../../../context/context";
import Modal from "@shared/atoms/Modal";
import { useSelector, useDispatch } from "react-redux";
import { createPlaylist } from "@redux/actions/playlistsActions";
import Tag from "@shared/atoms/Tag";
import Alert from "@shared/atoms/Alert/index";
import { v4 as uuidv4 } from "uuid";
import Input from "@shared/atoms/Input";
import { useTranslation } from "react-i18next";
import { PLAYLIST_CREATE_RESET } from "@redux/constants/playlistsConstants";
import { isMobile } from "react-device-detect";
import EditableImage from "@components/organisms/EditableImage";
import { changeStatusText } from "@utils/changeStatusText";

import "./index.less";

export default function CreatePlaylistModalPrototype() {
  const { t } = useTranslation();

  const {
    showAddPlaylistModalPrototype,
    setShowAddPlaylistModalPrototype,
    uploadedImageSize,
    setIndexOfNewPlaylist,
  } = useContext(Context);
  const { statuses } = useSelector((state) => state.playlistStatuses);

  const [name, setName] = useState("");
  const [icon, setIcon] = useState(null);
  const [describe, setDescribe] = useState("");
  const [status, setStatus] = useState("");
  const [statusId, setStatusId] = useState(null);
  const [tags, setTags] = useState([]);
  const [tagInputVisible, setTagInputVisible] = useState(true);
  const [tagInputValue, setTagInputValue] = useState("");
  const [sizeError, setSizeError] = useState(false);
  const [blobImg, setBlobImg] = useState(null);

  const { loading: playlistCreateLoading, error: createPlaylistError } =
    useSelector((state) => state.playlistCreate);
  const { playlists } = useSelector((state) => state.playlists);

  const createTagRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (statuses) setStatusId(statuses[0]?.id);
  }, [statuses]);

  useEffect(() => {
    if (createPlaylistError || playlistCreateLoading) {
      setShowAddPlaylistModalPrototype(true);
    } else {
      setShowAddPlaylistModalPrototype(false);
    }
  }, [
    createPlaylistError,
    setShowAddPlaylistModalPrototype,
    playlistCreateLoading,
  ]);

  const closeModalHandler = () => {
    resetState();
    setShowAddPlaylistModalPrototype(false);
    if (createPlaylistError) {
      dispatch({ type: PLAYLIST_CREATE_RESET });
    }
  };

  const changeStatus = (e) => {
    const statusId = statuses?.find(
      (status) => status.name === e.target.value
    ).id;

    setStatus(e.target.value);
    setStatusId(statusId);
  };

  const resetState = () => {
    setName("");
    setIcon("");
    setDescribe("");
    setStatus("");
    setTags([]);
    setTagInputValue(true);
    setTagInputValue("");
    setSizeError(false);
    setStatusId(statuses[0]?.id);
    setTags([]);
    setBlobImg(null);
  };

  const createPlaylistHandler = () => {
    if (uploadedImageSize > 1400000) {
      setSizeError(true);
      return;
    }

    const publicId = statuses.find(({ name }) => name === "Public")?.id;

    let fd = new FormData();
    fd.append("name", name);
    fd.append("status_id", statusId);
    const leftPlaylistLength = playlists.other.filter(
      ({ is_left }) => is_left
    ).length;

    fd.append("order", playlists.archive.length + leftPlaylistLength + 1);
    if (publicId !== statusId) {
      fd.append("is_left", true);
    }

    if (describe) fd.append("description", describe);
    if (blobImg && typeof blobImg == "object") {
      fd.append("icon", blobImg, icon?.name);
    } else {
      fd.delete("icon");
    }

    tags?.forEach((tag, index) => {
      fd.append(`tags[${index}]`, tag);
    });

    Promise.resolve(dispatch(createPlaylist(fd))).then(() => {
      setIndexOfNewPlaylist(1);
      resetState();
    });
  };

  const showTagInput = () => {
    setTagInputVisible(true);
  };

  const removeTag = (removedTag) => {
    const filteredTags = tags.filter((tag) => tag !== removedTag);
    setTags(filteredTags);
  };

  const createNewTag = () => {
    if (tagInputValue && tags.indexOf(tagInputValue) === -1) {
      setTags([...tags, tagInputValue]);
      setTagInputValue("");
    }
  };

  return (
    <div>
      <Modal
        modalContainer=".tablet-prototype"
        title={t("create_new_playlist")}
        className={`add-modal create-playlist-modal-prototype ${!isMobile ? "desktop-modal" : ""
          }`}
        visible={showAddPlaylistModalPrototype}
        onCancel={closeModalHandler}
        footer={
          <button
            disabled={playlistCreateLoading}
            onClick={createPlaylistHandler}
          >
            {t("create")}
          </button>
        }
        destroyOnClose={true}
      >
        {createPlaylistError && (
          <Alert
            className="create-playlist-alert"
            message={`${createPlaylistError?.data?.message} ${Object.values(createPlaylistError?.data?.errors)[0][0]
              } `}
            type="error"
          />
        )}

        <div>
          <label>{t("playlist_name")}</label>
          <input
            maxLength={50}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label>{t("image")}</label>
          <EditableImage
            setBlobImg={setBlobImg}
            btnText={"Upload channel image"}
          />

        </div>
        {sizeError && (
          <div className="size-validation-error">
            Logo must be less than 1MB.
          </div>
        )}
        <div>
          <label>{t("playlist_description")}</label>
          <input
            value={describe}
            onChange={(e) => setDescribe(e.target.value)}
          />
        </div>
        <div>
          <label>{t("playlist_privacy")}</label>
          <select defaultValue={`${status}`} onChange={changeStatus}>
            {statuses?.map((el) => (
              <option key={el.id} value={el.name}>
                {changeStatusText(el.name)}
              </option>
            ))}
          </select>
        </div>
        <div className="tag-items playlist-text-area tags-text-area">
          <div className="tag-items-container">
            {tags.map((tag) => (
              <Tag
                key={uuidv4()}
                onClose={(e) => {
                  removeTag(tag);
                  e.preventDefault();
                }}
                closable={true}
                className="tag"
                maxLength={50}
              >
                {tag.length > 20 ? `${tag.slice(0, 20)}...` : tag}
              </Tag>
            ))}
          </div>
          {tagInputVisible && (
            <Input
              className="tag-input"
              ref={createTagRef}
              type="text"
              size="small"
              value={tagInputValue}
              onChange={(e) => setTagInputValue(e.target.value)}
              onBlur={createNewTag}
              onPressEnter={createNewTag}
              placeholder={t("create_tag")}
              maxLength={50}
            />
          )}

          {!tagInputVisible && (
            <Tag onClick={showTagInput} closable={true} className="tag">
              {t("new_tag")}
            </Tag>
          )}
        </div>
      </Modal>
    </div>
  );
}
