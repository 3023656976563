import React, { useState, useEffect, useContext } from "react";
import Modal from "@shared/atoms/Modal";
import { useTranslation } from "react-i18next";

import {
  getPlaylistById,
  removeSerieInGroup,
  updatePlaylistVideo,
  deleteSerieImage,
} from "../../../../../redux/actions/playlistsActions";
import { useDispatch, useSelector } from "react-redux";

import "./index.less";
import { Context } from "@context/context";
import { selectVideoDeleteStatus } from "@redux/reducers/playlistsReducer";
import EditableImage from "@components/organisms/EditableImage";
import { METHODS } from "const/apiMethods";
import SpinnerPrototype from "@components/shared/atoms/SpinnerPrototype";
import deleteImg from "@assets/icons/deleteContext.svg";

const EditPlaylistVideoModalPrototype = ({ video }) => {
  const { t } = useTranslation();

  const {
    showEditPlaylistVideoModalPrototype,
    setShowEditPlaylistVideoModalPrototype,
    playlistVideoPrototype,
    selectedPlaylist,
  } = useContext(Context);

  const [random, setRandom] = useState(1);
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [isHome, setIsHome] = useState(false);
  const [icon, setIcon] = useState("");
  const [descr, setDescr] = useState("");
  const [seriesName, setSeriesName] = useState("");
  const [serieId, setSerieId] = useState(0);
  const [uploadedVideoName, setUploadedVideoName] = useState("");
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [isLinkDisabled, setIsLinkDisabled] = useState(false);

  const [isImageChange, setIsImageChange] = useState(false);

  const dispatch = useDispatch();
  const { loading: playlistVideoUpdateLoading } = useSelector(
    (state) => state.playlistVideoUpdate
  );

  const { loading: videoDeleteLoading } = useSelector(selectVideoDeleteStatus);
  const { profile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (playlistVideoPrototype) {
      setLink(playlistVideoPrototype?.src);
      setTitle(playlistVideoPrototype?.title);
      setIcon(playlistVideoPrototype?.image);
      setDescr(playlistVideoPrototype?.description);
      setSeriesName(playlistVideoPrototype?.series_name);
      setSerieId(playlistVideoPrototype?.pivot?.serie_id);
      setIsHome(playlistVideoPrototype?.is_home);
    }
  }, [playlistVideoPrototype]);

  const editVideo = () => {
    const fd = new FormData();

    if (link) {
      fd.append("src", link);
    } else if (uploadedVideo) {
      fd.append("src", uploadedVideo);
    }
    fd.append("is_home", isHome);
    fd.append("title", title);
    fd.append("description", descr);
    if (isImageChange) {
      fd.append("image", icon);
    }
    fd.append("series_name", seriesName);
    fd.append("series_id", serieId);
    fd.append("_method", METHODS.PUT);

    Promise.resolve(
      dispatch(updatePlaylistVideo(playlistVideoPrototype.id, fd))
    ).then(() => {
      setShowEditPlaylistVideoModalPrototype(false);
      dispatch(getPlaylistById(selectedPlaylist.id));
    });
    setIsImageChange(false);
  };

  const uploadVideoFile = (e) => {
    if (e.target.files[0]) {
      setUploadedVideoName(e.target.files[0].name);
      setUploadedVideo(e.target.files[0]);
      setIsLinkDisabled(true);
      setLink("");
    } else {
      setUploadedVideoName("");
      setIsLinkDisabled(false);
    }
  };

  const deleteVideo = () => {
    Promise.resolve(
      dispatch(
        removeSerieInGroup(
          playlistVideoPrototype?.pivot?.serie_id,
          playlistVideoPrototype?.id
        )
      )
    ).then(() => {
      dispatch(getPlaylistById(selectedPlaylist.id));
      setShowEditPlaylistVideoModalPrototype(false);
    });
  };

  const deleteCurrentImage = async () => {
    dispatch(deleteSerieImage(playlistVideoPrototype?.id))
    setIcon("");
    setRandom(Math.random());
  }

  return (
    <Modal
      className="modal-basic-prototype edit-playlist-video-modal-prototype"
      visible={showEditPlaylistVideoModalPrototype}
      destroyOnClose={true}
      onCancel={() => {
        setShowEditPlaylistVideoModalPrototype(false);
      }}
      footer={
        <div className="edit-video-footer">
          <button
            className="edit-video-btn"
            disabled={playlistVideoUpdateLoading}
            onClick={editVideo}
          >
            {playlistVideoUpdateLoading && (
              <SpinnerPrototype className="btn-spinner" size={1.8} />
            )}
            Edit
          </button>
          <button
            onClick={deleteVideo}
            className="remove-video-btn"
            disabled={videoDeleteLoading}
          >
            Delete
          </button>
        </div>
      }
    >
      <div className="form-control">
        <label disabled={isLinkDisabled}>{t("link")} (url)</label>
        <input value={link} onChange={(e) => setLink(e.target.value)} />
      </div>
      {profile?.data?.can_upload_video && (
        <>
          <div className="form-control or">or</div>
          <div className="form-control">
            <label className="online-img-label">{t("upload_video")}</label>
            <input
              className="video-upload"
              data-content={`Upload video ${uploadedVideoName}`}
              type="file"
              accept="video/mp4,video/x-m4v,video/*"
              onChange={uploadVideoFile}
            />
          </div>
        </>
      )}

      <div className="form-control">
        <label>Home Position Of This Series</label>
        <input
          type="checkbox"
          value={playlistVideoPrototype?.is_home}
          checked={isHome}
          onChange={() => setIsHome((prev) => !prev)}
        />
      </div>
      <div className="form-control">
        <label>{t("title")}</label>
        <input value={title} onChange={(e) => setTitle(e.target.value)} />
      </div>
      <div className="form-control">
        <label>{t("series_name")}</label>
        <input
          value={seriesName}
          onChange={(e) => setSeriesName(e.target.value)}
        />
      </div>
      <div className="form-control">
        <label>{t("picture")}</label>
        <EditableImage
          key={random}
          aspect={3 / 2}
          providedImageLink={icon}
          setBlobImg={setIcon}
          // setSizeError={setSizeError}
          setIsChange={setIsImageChange}
          btnText={t("upload_series_video_image")}
          shape="rectangle"
        />
        <button className="img-delete-btn" icon={deleteImg} onClick={deleteCurrentImage}>Delete Current Image</button>
      </div>
      <div className="form-control">
        <label>{t("description")}</label>
        <textarea
          style={{ height: "10rem" }}
          value={descr}
          onChange={(e) => setDescr(e.target.value)}
        />
      </div>
    </Modal>
  );
};

export default EditPlaylistVideoModalPrototype;
