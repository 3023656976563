import React from "react";

import "./index.less";

const CopyTwo = () => {
    return (
        <svg
            className="copy2-icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g
                id="Group_10974"
                data-name="Group 10974"
                transform="translate(-2220 906)"
            >
                <rect
                    id="Rectangle_4175"
                    data-name="Rectangle 4175"
                    width="24"
                    height="24"
                    transform="translate(2220 -906)"
                    fill="none"
                    opacity="0"
                />
                <g id="ic_content_copy_24px" transform="translate(2222 -905)">
                    <path
                        id="Path_4587"
                        data-name="Path 4587"
                        d="M13.789,1H3.684A1.76,1.76,0,0,0,2,2.818V15.545H3.684V2.818H13.789Zm2.526,3.636H7.053A1.76,1.76,0,0,0,5.368,6.455V19.182A1.76,1.76,0,0,0,7.053,21h9.263A1.76,1.76,0,0,0,18,19.182V6.455A1.76,1.76,0,0,0,16.316,4.636Zm0,14.545H7.053V6.455h9.263Z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default CopyTwo;
