import React, { useState, useRef, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Context } from "@context/context";
import { useSelector, useDispatch } from "react-redux";
import {
    getPlaylistById,
    getFavoritesPlaylistRelatedVideos,
} from "@redux/actions/playlistsActions";
import { view } from "@redux/actions/viewActions";
import Loader from "@shared/atoms/Loader";
import FollowingList from "@components/organisms/lists/FollowingList";
import FollowingVideoList from "@components/organisms/lists/FollowingVideoList";
import SaveToPlaylistModal from "../modals/SaveToPlaylistModal";
import ShareModal from "../modals/ShareModal";
import { like, unlike } from "@redux/actions/socialActions";
import FollowingItem from "@shared/molecules/listItems/FollowingItem";
import FollowingVideoItem from "@shared/molecules/listItems/FollowingVideoItem";
import BorderedDivider from "@shared/atoms/BorderedDivider";
import VideosHeaderMobile from "@shared/molecules/VideosHeaderMobile";
import ChannelSearch from "@shared/molecules/ChannelSearch";
import BackToHome from "@shared/molecules/BackToHome";
import querystring from "query-string";
import BackToLogin from "@shared/molecules/BackToLogin";
import EmptyPlaylistSvg from "@shared/molecules/EmptPlaylistSvg";
import defaultLoadingImg from "@assets/images/video_loading.jpg";
import { useTranslation } from "react-i18next";

import "./index.less";

const MobileFollowing = () => {
    const [showVideos, setShowVideos] = useState(false);
    const [goBack, setGoBack] = useState(false);
    const [videoCount, setVideoCount] = useState(null);
    const [playlistTitle, setPlaylistTitle] = useState("");

    const { t } = useTranslation();

    const {
        selectedPlaylist,
        setSelectedPlaylist,
        setSelectedVideo,
        setMobileHeaderText,
    } = useContext(Context);

    const history = useHistory();
    const location = useLocation();

    const dispatch = useDispatch();

    const {
        loading: suggestedPlaylistsLoading,
        playlists: suggestedPlaylists,
        // error: suggestedPlaylistsError,
    } = useSelector((state) => state.socialSuggestedPlaylists);

    const { playlist: playlistById, error: playlistByIdError } = useSelector(
        (state) => state.playlistById
    );

    const {
        loading: relatedVideosLoading,
        videos: relatedVideos,
        // error: relatedVideosError,
    } = useSelector((state) => state.playlistWithRelatedVideos);

    const { playlists: followedPlaylists, loading: followedPlaylistsLoading } =
        useSelector((state) => state.following);

    const searchRef = useRef(null);

    useEffect(() => {
        let search = querystring.parse(location.search);
        if (Object.keys(search).length === 0) {
            setShowVideos(false);
            setGoBack(false);
            setMobileHeaderText("Following");
        } else {
            setShowVideos(true);
            setGoBack(true);
            setSelectedPlaylist(playlistById?.data);
        }
    }, [
        showVideos,
        location.search,
        dispatch,
        setSelectedPlaylist,
        setMobileHeaderText,
        playlistById,
    ]);

    useEffect(() => {
        if (playlistById) {
            setMobileHeaderText(playlistById.data.name);
            setPlaylistTitle(playlistById.data.name);
            setVideoCount(playlistById.data.videos.length);
            dispatch(getFavoritesPlaylistRelatedVideos(playlistById.data.id));
        }
    }, [
        dispatch,
        playlistById,
        setMobileHeaderText,
        setVideoCount,
        setPlaylistTitle,
    ]);

    useEffect(() => {
        setMobileHeaderText("Following");
    }, [setMobileHeaderText]);

    const createLinkForPlaylist = (selectedPlaylist) => {
        history.push({ search: `?playlist=${selectedPlaylist.id}` });
        setSelectedPlaylist(selectedPlaylist);
    };

    const followingItemClickHandler = (e, playlist) => {
        if (
            !e.target.classList.contains("kebab-menu") &&
            e.target.nodeName !== "path"
        ) {
            setShowVideos(true);
            dispatch(getPlaylistById(playlist.id));
            dispatch(view("App\\Playlist", playlist.id));
            createLinkForPlaylist(playlist);
            setMobileHeaderText(playlist.name);
            setVideoCount(playlist.videos.length);
            setPlaylistTitle(playlist.name);
            window.scrollTo(0, 0);
        } else {
            setShowVideos(false);
        }
    };
    const followingVideohandler = (relatedVideo, selectedVideo) => {
        likeHandler(selectedVideo);
        if (relatedVideo) {
            history.push({
                search: `?playlist=${playlistById.data.id}&video=${selectedVideo.id}&relatedVideo=${relatedVideo.id}`,
            });
        } else {
            history.push({
                search: `?playlist=${playlistById.data.id}&video=${selectedVideo.id}`,
            });
        }
    };
    const likeHandler = (video) => {
        if (video.liked === false) {
            Promise.resolve(
                dispatch(like("App\\Video", video.id.toString()))
            ).then(() => {
                dispatch(getPlaylistById(selectedPlaylist.id));
            });
        } else {
            Promise.resolve(
                dispatch(unlike("App\\Video", video.id.toString()))
            ).then(() => {
                dispatch(getPlaylistById(selectedPlaylist.id));
            });
        }
    };

    return (
        <div className="mobile-following-container">
            <ShareModal />
            <SaveToPlaylistModal />
            {!showVideos && (
                <>
                    <FollowingList className="suggestions">
                        {suggestedPlaylistsLoading && (
                            <Loader centered={true} />
                        )}
                        {suggestedPlaylists
                            ?.filter((suggested) =>
                                suggested.is_followed ? false : true
                            )
                            .map((suggested) => (
                                <FollowingItem
                                    onClick={(e) =>
                                        followingItemClickHandler(e, suggested)
                                    }
                                    key={suggested.id}
                                    title={suggested.name}
                                    subTitle={suggested.videos.length}
                                    playlist={suggested}
                                ></FollowingItem>
                            ))}
                    </FollowingList>
                    <BorderedDivider className="search-borders" hide={true}>
                        <div ref={searchRef}>
                            <ChannelSearch className="following-search" />
                        </div>
                    </BorderedDivider>{" "}
                    {followedPlaylistsLoading && <Loader centered={true} />}
                    <FollowingList>
                        {followedPlaylists?.playlists
                            ?.filter(
                                (followed) => followed.is_followed === true
                            )
                            .map((followed) => (
                                <FollowingItem
                                    onClick={(e) =>
                                        followingItemClickHandler(e, followed)
                                    }
                                    key={followed.id}
                                    title={followed.name}
                                    subTitle={followed.videos.length}
                                    playlist={followed}
                                ></FollowingItem>
                            ))}
                    </FollowingList>
                </>
            )}
            {relatedVideosLoading && <Loader centered={true} />}
            {showVideos && (
                <>
                    {!relatedVideosLoading && (
                        <>
                            {playlistByIdError && (
                                <>
                                    <EmptyPlaylistSvg />
                                    <div className="error-contaienr">
                                        <BackToLogin />
                                    </div>
                                </>
                            )}
                            {playlistById && (
                                <>
                                    <VideosHeaderMobile
                                        hasKebabMenu={true}
                                        hasPlus={false}
                                        hasEdit={false}
                                        videoCount={videoCount}
                                        title={playlistTitle}
                                    />
                                    {!playlistById?.data?.videos.length && (
                                        <EmptyPlaylistSvg />
                                    )}
                                    <FollowingVideoList>
                                        {playlistById?.data?.videos?.map(
                                            (video, i) => (
                                                <>
                                                    <div
                                                        key={video.id}
                                                        className="following-video-scroll"
                                                        onClick={() => {
                                                            setSelectedVideo(
                                                                video
                                                            );
                                                        }}
                                                    >
                                                        {relatedVideos
                                                            ?.filter(
                                                                (
                                                                    relatedVideo
                                                                ) =>
                                                                    relatedVideo.video_id ===
                                                                    video.id
                                                            )
                                                            .map((item) => {
                                                                let related =
                                                                    item.related;
                                                                return related.map(
                                                                    (rel) => {
                                                                        return (
                                                                            <FollowingVideoItem
                                                                                key={
                                                                                    rel.id
                                                                                }
                                                                                hasLike={
                                                                                    false
                                                                                }
                                                                                liked={
                                                                                    video.liked
                                                                                }
                                                                                onClick={() =>
                                                                                    followingVideohandler(
                                                                                        rel,
                                                                                        video
                                                                                    )
                                                                                }
                                                                                playlist={
                                                                                    playlistById
                                                                                }
                                                                                video={
                                                                                    video
                                                                                }
                                                                                image={
                                                                                    rel.image
                                                                                        ? rel.image
                                                                                        : defaultLoadingImg
                                                                                }
                                                                                title={
                                                                                    rel.title
                                                                                        ? rel.title
                                                                                        : t(
                                                                                            "data_is_loading"
                                                                                        )
                                                                                }
                                                                            ></FollowingVideoItem>
                                                                        );
                                                                    }
                                                                );
                                                            })}

                                                        <FollowingVideoItem
                                                            onClick={() =>
                                                                followingVideohandler(
                                                                    null,
                                                                    video
                                                                )
                                                            }
                                                            liked={video.liked}
                                                            playlist={
                                                                playlistById
                                                            }
                                                            video={video}
                                                            image={
                                                                video.image
                                                                    ? video.image
                                                                    : defaultLoadingImg
                                                            }
                                                            title={
                                                                video.title
                                                                    ? video.title
                                                                    : t(
                                                                        "data_is_loading"
                                                                    )
                                                            }
                                                        ></FollowingVideoItem>
                                                    </div>
                                                </>
                                            )
                                        )}
                                    </FollowingVideoList>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
            <BackToHome
                searchRef={searchRef}
                page={"following"}
                goBack={goBack}
            />
        </div>
    );
};

export default MobileFollowing;
