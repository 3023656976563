import Modal from "@shared/atoms/Modal";
import Button from "@shared/atoms/Button";
import { Paragraph } from "@shared/atoms/Typography/index";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import { deleteShortCut } from "@redux/actions/shortcutActions";

import "./index.less";

const DeleteShortcutModalPrototype = ({
  isVisible,
  setIsVisible,
  id,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deletePlaylistHandler = () => {
    dispatch(deleteShortCut(id));
    setIsVisible(false);
  };

  return (
    <Modal
      className="delete-playlist-modal modal-basic-prototype"
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      closable={true}
      footer={[
        <footer className="footer">
          <Button
            key="delete"
            onClick={deletePlaylistHandler}
            className="response-buttons-prototype blue"
          >
            {t("delete")}
          </Button>
          <Button
            key="cancel"
            onClick={() => setIsVisible(false)}
            className="response-buttons-prototype gray"
          >
            {t("cancel")}
          </Button>
        </footer>,
      ]}
    >
      <Paragraph>
        {t("are_you_sure_to_delete_playlist_name")}
      </Paragraph>
    </Modal>
  );
};

export default DeleteShortcutModalPrototype;
