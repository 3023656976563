import { useState, useEffect, useContext, useRef } from "react";
// import { debounce } from "lodash";
import { Context } from "@context/context";
import { localStorageService } from "@modules/services/localStorage.service";


const isLoggedIn = !!localStorageService().get("access_token");
const CONTEXT_MENU_OPEN_TIME = 800 //ms

// function disableScroll(target = window) {
//   let scrollTop, scrollLeft;

//   // Get the current page scroll position
//   if (target === window) {
//     scrollTop = window.pageYOffset || document.documentElement.scrollTop;
//     scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
//   } else {
//     scrollTop = target.scrollTop;
//     scrollLeft = target.scrollLeft;
//   }

//   // if any scroll is attempted, set this to the previous value
//   target.onscroll = function () {
//     target.scrollTo(scrollLeft, scrollTop);
//   };
// }

export function enableScroll(target = window) {
  target.onscroll = function () { };
}

export function useContextMenu(...targetElClassName) {
  const { setCurrentPlaylistIdPrototype } = useContext(Context);
  const [isVisible, setIsVisible] = useState(false);
  const [targetElementId, setTargetElementId] = useState(null);
  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0);
  const [contextMenuHeight, setContextMenuHeight] = useState(0);

  // const [touchStartMs, setTouchStartMs] = useState(0);
  // const [touchEndMs, setTouchEndMs] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // let resultRef = useRef();
  let isTouching = useRef(false);

  function openContextMenu(e) {

    if (targetElClassName.length > 0) {
      let playlistId;
      for (let i = 0; i < targetElClassName.length; i++) {
        playlistId = e?.target[i]?.closest()?.dataset?.id;
      }
      if (playlistId) {
        setCurrentPlaylistIdPrototype(playlistId);
      }
    }

    let x, y;
    // Open context menu on top of the target element
    let position;

    if (targetElClassName.length > 0) {
      const targetItem = e?.target;
      for (let i = 0; i < targetElClassName.length; i++) {
        // targetItem?.closest(targetElClassName)?.getBoundingClientRect();
        position = targetItem
          ?.closest(targetElClassName)
          ?.getBoundingClientRect();
      }

      if (position) {
        x = position.left + position.width / 2;
        y = position.top;
      }
    }
    // Open context menu on cursor position
    else {
      if (e.type === "touchstart") {
        x = e.touches[0].clientX;
        y = e.touches[0].clientY;
      } else {
        x = e.clientX
        y = e.clientY
      }
    }

    setContextMenuHeight(e?.target?.getBoundingClientRect().height);

    setXPos(x);
    setYPos(y);
    setIsVisible(true);
    setTargetElementId(
      e?.target?.closest(".openable-item-content")?.dataset?.id
    );

    e.preventDefault();
  }

  // const contextMenuClickWithDeb = debounce((e) => {
  //   e.preventDefault();
  //   if (resultRef.current > CONTEXT_MENU_OPEN_TIME) {
  //     openContextMenu(e, true);
  //   }
  // }, 1250);

  const contextMenuClickWithDebounce = (e, cb, timing = 1250) => {
    isTouching.current = true;
    setTimeout(() => {
      if(isTouching.current) {
        cb?.()
        openContextMenu(e)
      }
    }, timing)
  }

  const onTouchEnd = () => {
    isTouching.current = false;
  }

  function onTouchMove()  {
    preventContextMenuWhenTouchMove();
  }

  function preventContextMenuWhenTouchMove() {
    if(!isTouching.current) return

    isTouching.current = false;
  }

  function closeContextMenu() {
    setIsVisible(false);
  }

  function guestUser() {
    setIsPopupOpen(true);
  }

  function withCloseContextMenu(cb) {
    return function withClose() {
      // if (!isLoggedIn) return guestUser();
      cb();
      closeContextMenu();
    };
  }

  // useEffect(() => {
  //   function handleTouchStart(e) {
  //     setTouchStartMs(e.timeStamp);
  //   }
  //   function handleTouchEnd(e) {
  //     setTouchEndMs(e.timeStamp);
  //   }

  //   window.addEventListener("touchstart", handleTouchStart, false);
  //   window.addEventListener("touchend", handleTouchEnd, false);

  //   resultRef.current = touchEndMs - touchStartMs;

  //   return () => {
  //     window.removeEventListener("touchstart", handleTouchStart);
  //     window.removeEventListener("touchend", handleTouchEnd);
  //   };
  // }, [touchEndMs, touchStartMs]);

  return {
    openContextMenu,
    closeContextMenu,
    withCloseContextMenu,
    contextMenuClickWithDebounce,
    isVisible,
    targetElementId,
    xPos,
    yPos,
    contextMenuHeight,
    onTouchEnd,
    onTouchMove,
  };
}
