import React from 'react'
import AntdMenu from 'antd/lib/menu/index';
import "./index.less"

const Menu = ({children, ...rest}) => {
  return (
    <AntdMenu className="menu" {...rest}>
        {children}
    </AntdMenu>
  )
}

export default Menu
