import React, { useContext, useEffect, useState } from "react";
import Input from "@shared/atoms/Input/index";
import Form from "@shared/atoms/Form/index";
import Button from "@shared/atoms/Button/index";
import { formLayout } from "@modules/config";
import { Context } from "@context/context";
import validateEmail from "@utils/validateEmail";

import "./index.less";
import { useTranslation } from "react-i18next";

const ForgotPasswordForm = ({ setConfirmEmail }) => {
    const { userEmailHandler, userEmail } = useContext(Context);
    const [disableBtn, setDisableBtn] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        let isValidEmail = validateEmail(userEmail);
        if (isValidEmail) {
            setDisableBtn(false);
        } else {
            setDisableBtn(true);
        }
    }, [userEmail]);

    return (
        <Form className="forgot-password-form" layout={formLayout}>
            <Form.Item>
                <Input
                    onChange={(e) => userEmailHandler(e.target.value)}
                    placeholder={t("email")}
                />
            </Form.Item>
            <div className="email-label">{t("reset_password_link_msg")}.</div>
            <Button
                className="forget-password-btn"
                disabled={disableBtn}
                type="primary"
                key="back"
                htmlType="submit"
                onClick={setConfirmEmail}
            >
                {t("send")}
            </Button>
        </Form>
    );
};

export default ForgotPasswordForm;
