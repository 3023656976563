import { API_URL, CHOICE_URL } from "@modules/config";

import {
  USER_CONFIRM_CODE_RESEND_REQUEST,
  USER_CONFIRM_CODE_RESEND_SUCCESS,
  USER_CONFIRM_CODE_RESEND_FAIL,
  USER_CONFIRM_EMAIL_REQUEST,
  USER_CONFIRM_EMAIL_SUCCESS,
  USER_CONFIRM_EMAIL_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_CONFIRM_CODE_REQUEST,
  USER_CONFIRM_CODE_SUCCESS,
  USER_CONFIRM_CODE_FAIL,
  USER_CONFIRM_RESET_PASSWORD_REQUEST,
  USER_CONFIRM_RESET_PASSWORD_FAIL,
  USER_CONFIRM_RESET_PASSWORD_SUCCESS,
  USER_REQUIRED_FIELDS_REQUEST,
  USER_REQUIRED_FIELDS_SUCCESS,
  USER_REQUIRED_FIELDS_FAIL,
  USER_VERIFY_EMAIL_REQUEST,
  USER_VERIFY_EMAIL_SUCCESS,
  USER_VERIFY_EMAIL_FAIL,
  USER_CONFIRM_RESET_PASSWORD_CLEAR,
  USER_REGISTER_ONLY_EMAIL_REQUEST,
  USER_REGISTER_ONLY_EMAIL_FAIL,
  USER_REGISTER_FINALIZE_REQUEST,
  USER_REGISTER_FINALIZE_SUCCESS,
  USER_REGISTER_FINALIZE_FAIL,
  USER_REGISTER_ONLY_EMAIL_SUCCESS,
  FIND_USERES_EMAIL_REQUEST,
  FIND_USERES_EMAIL_SUCCESS,
  FIND_USERES_EMAIL_FAIL,
} from "../constants/userConstants";
import { axiosInstance } from "../../modules/request/api";
import { localStorageService } from "../../modules/services/localStorage.service";

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });
    let bodyFormData = new FormData();

    bodyFormData.append(
      "client_secret",
      "WEW5sNeIbnuXv91JMwEZbqf8VCuQuWbUnNt1ZJlb"
    );
    bodyFormData.append("client_id", 15);
    bodyFormData.append("grant_type", "password");
    bodyFormData.append("user", username);
    bodyFormData.append("password", password);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/user/token`,
      bodyFormData,
      config
    );
    if (data.access_token) {
      let access_token = "Bearer " + data.access_token;
      localStorageService().set("access_token", access_token);

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({ type: USER_LOGIN_FAIL, payload: error?.response });
  }
};

export const logOut = () => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGOUT_REQUEST });
    const { data } = await axiosInstance().post(`${CHOICE_URL}/api/logout`);
    dispatch({ type: USER_LOGOUT_SUCCESS, message: data });
    // localStorageService().remove("token");
  } catch (error) {
    dispatch({ type: USER_LOGOUT_FAIL, payload: error.response });
  }
};

export const confirmEmail = (email) => async (dispatch) => {
  try {
    dispatch({ type: USER_CONFIRM_EMAIL_REQUEST });

    await axiosInstance().get(`${API_URL}/auth/reset/password?email=${email}`);
    dispatch({ type: USER_CONFIRM_EMAIL_SUCCESS });
  } catch (error) {
    dispatch({
      type: USER_CONFIRM_EMAIL_FAIL,
      payload: error.response?.data?.message,
    });
  }
};

// export const confirmResetPassword =
//     (confirmationCode, email) => async (dispatch) => {
//         try {
//             dispatch({ type: USER_CONFIRM_RESET_PASSWORD_REQUEST });
//             await axiosInstance().get(
//                 `${API_URL}/auth/reset/password/confirm?confirmation=${confirmationCode}&email=${email}`
//             );
//             dispatch({ type: USER_CONFIRM_RESET_PASSWORD_SUCCESS });
//         } catch (error) {
//             dispatch({
//                 type: USER_CONFIRM_RESET_PASSWORD_FAIL,
//                 payload: error?.response,
//             });
//         }
//     };

export const confirmResetPassword =
  (email, token, password, password_confirmation) => async (dispatch) => {
    try {
      dispatch({ type: USER_CONFIRM_RESET_PASSWORD_REQUEST });
      await axiosInstance().post(`${CHOICE_URL}/api/user/reset-password`, {
        email,
        token,
        password,
        password_confirmation,
      });
      dispatch({ type: USER_CONFIRM_RESET_PASSWORD_SUCCESS });
    } catch (error) {
      dispatch({
        type: USER_CONFIRM_RESET_PASSWORD_FAIL,
        payload: error?.response,
      });
    }
  };

export const passwordReset = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_RESET_PASSWORD_REQUEST });
    const config = {
      headers: {
        "Email-request": true,
      },
    };
    await axiosInstance().post(
      `${CHOICE_URL}/api/user/reset`,
      {
        email,
      },
      config
    );

    dispatch({ type: USER_RESET_PASSWORD_SUCCESS });
  } catch (error) {
    dispatch({ type: USER_RESET_PASSWORD_FAIL, payload: error });
  }
};

export const clearPasswordResetData = () => ({
  type: USER_CONFIRM_RESET_PASSWORD_CLEAR,
});

export const register = (newUserData) => async (dispatch) => {
  try {
    dispatch({ type: USER_REGISTER_REQUEST });

    let formData = new FormData();

    for (const key in newUserData) {
      if (newUserData[key]) {
        formData.append(key, newUserData[key]);
      }
    }

    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/user`,
      formData
    );

    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_REGISTER_FAIL, payload: error?.response });
  }
};

export const registerOnlyEmail = (email, url) => async (dispatch) => {
  try {
    dispatch({ type: USER_REGISTER_ONLY_EMAIL_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/register/email-only`,
      { email, url }
    );
    dispatch({ type: USER_REGISTER_ONLY_EMAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_REGISTER_ONLY_EMAIL_FAIL, payload: error?.response });
  }
};

export const registerFinalize = (userData) => async (dispatch) => {
  try {
    dispatch({ type: USER_REGISTER_FINALIZE_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/register`,
      userData
    );
    dispatch({ type: USER_REGISTER_FINALIZE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_REGISTER_FINALIZE_FAIL, payload: error.response });
  }
};

export const confirmCode = (userId, confirmation) => async (dispatch) => {
  try {
    dispatch({ type: USER_CONFIRM_CODE_REQUEST });

    const data = await axiosInstance().get(
      `${API_URL}/auth/confirm-user?confirmation=${confirmation}&userId=${userId}`
    );
    dispatch({ type: USER_CONFIRM_CODE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_CONFIRM_CODE_FAIL, payload: error.response });
  }
};

export const confirmCodeResend = (userName) => async (dispatch) => {
  try {
    dispatch({ type: USER_CONFIRM_CODE_RESEND_REQUEST });
    const data = await axiosInstance().get(
      `${API_URL}/auth/confirm-user/resend?username=${userName}`
    );
    dispatch({ type: USER_CONFIRM_CODE_RESEND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_CONFIRM_CODE_RESEND_FAIL, payload: error });
  }
};

export const fillRequiredFields = (userName) => async (dispatch) => {
  try {
    dispatch({ type: USER_REQUIRED_FIELDS_REQUEST });
    const data = await axiosInstance().put(
      `${CHOICE_URL}/api/user/required-fields`,
      { nickname: userName }
    );
    dispatch({ type: USER_REQUIRED_FIELDS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_REQUIRED_FIELDS_FAIL, payload: error.response });
  }
};

export const verifyEmail = (url) => async (dispatch) => {
  try {
    dispatch({ type: USER_VERIFY_EMAIL_REQUEST });
    const { data } = await axiosInstance().get(url);
    dispatch({ type: USER_VERIFY_EMAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_VERIFY_EMAIL_FAIL, payload: error });
  }
};

export const findUsersEmail = (email) => async (dispatch) => {
  try {
    dispatch({ type: FIND_USERES_EMAIL_REQUEST });
    const { data } = await axiosInstance().post(
      `${CHOICE_URL}/api/teams/user-suggestion`,
      { email: email }
    );
    dispatch({ type: FIND_USERES_EMAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FIND_USERES_EMAIL_FAIL, payload: error });
  }
};
