import React from 'react'
import { Layout } from 'antd';

import './index.less'

const AuthLayout = ({children, ...rest}) => {
  return (
    <Layout {...rest}>
        {children}
    </Layout>
  )
}

export default AuthLayout
