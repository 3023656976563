export const AppRoutes = {
  login: {
    path: "login",
    isPrivate: false,
    exact: true,
    page: "Login",
  },
  reset_password: {
    path: "reset-password/:token/:email",
    isPrivate: false,
    exact: true,
    page: "Settings",
  },
  register: {
    path: "register",
    isPrivate: false,
    exact: true,
    page: "Registration",
  },
  home: {
    path: "",
    isPrivate: false,
    exact: true,
    page: "Home",
  },
  channels: {
    path: "channels",
    isPrivate: false,
    exact: true,
    page: "Channels",
  },
  playlists: {
    path: "playlists",
    isPrivate: false,
    exact: true,
    page: "Playlists",
  },
  myPlaylists: {
    path: "my-playlists",
    isPrivate: false,
    exact: true,
    page: "MyPlaylists",
  },
  tiers: {
    path: "tiers",
    isPrivate: false,
    exact: true,
    page: "Tiers",
  },
  following: {
    path: "following",
    isPrivate: false,
    exact: true,
    page: "Following",
  },
  search: {
    path: "search",
    isPrivate: false,
    exact: true,
    page: "Search",
  },
  statistics: {
    path: "statistics",
    isPrivate: false,
    exact: true,
    page: "Statistics",
  },
  teams: {
    path: "teams",
    isPrivate: false,
    exact: true,
    page: "Teams",
  },
  add_team_member: {
    path: "request/:teamId/:teamName",
    isPrivate: false,
    exact: true,
    page: "Settings",
  },
  delete_team_admin: {
    path: "admin-derank/:userId/:teamId/:teamName/:adminName",
    isPrivate: false,
    exact: true,
    page: "Settings",
  },
  settings: {
    path: "settings",
    isPrivate: false,
    exact: true,
    page: "Settings",
  },
  cookies: {
    path: "cookies",
    isPrivate: false,
    exact: true,
    page: "CookiesPolicy",
  },
  terms: {
    path: "terms",
    isPrivate: false,
    exact: true,
    page: "Terms",
  },
  privacy: {
    path: "privacy",
    isPrivate: false,
    exact: true,
    page: "Privacy",
  },
  cookiesPolicy: {
    path: "cookiesPolicy",
    isPrivate: false,
    exact: true,
    page: "CookiesPolicy",
  },
  notFound: {
    path: "not-found",
    isPrivate: false,
    exact: true,
    page: "NotFound",
  },
  vanityUrl: {
    path: "*",
    isPrivate: false,
    exact: true,
    page: "VanityPage",
  },
};
