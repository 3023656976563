import React, { useState } from "react";
import { Card, CardMeta } from "@shared/atoms/Card";
import Image from "@shared/atoms/Image";
import InfoBoxPrototype from "../InfoBoxPrototype";
import addImg from "@assets/icons/add.svg";
import defaultImg from "@assets/images/default_img.png";
import copyImg from "@assets/icons/copy.svg";
import ContextMenuPrototype, {
  ContextMenuOption,
} from "../ContextMenuPrototype";
import { useContextMenu } from "hooks/useContextMenu";

import "./index.less";

const ChannelCard = ({
  coverImg = defaultImg,
  title,
  metaInfo,
  infoBoxSubtitle,
  infoBoxTime,
  onClick,
  shareChannelHandler,
  reportChannelItemHandler,
  infoBoxDescription,
  directShareHandler,
  copyItem,
  copyEpg,
}) => {
  const { isVisible, xPos, yPos, withCloseContextMenu, closeContextMenu, openContextMenu } =
    useContextMenu();

  const contextMenuOptions = (
    <>
      <ContextMenuOption
        label="Direct share"
        icon={addImg}
        onClick={withCloseContextMenu(directShareHandler)}
      />

      <ContextMenuOption
        label={"Copy Item"}
        icon={copyImg}
        onClick={withCloseContextMenu(copyItem)}
      />

      <ContextMenuOption
        label={"Copy Series"}
        icon={copyImg}
        onClick={withCloseContextMenu(copyEpg)}
      />
    </>
  );

  return (
    <>
      {isVisible && (
        <ContextMenuPrototype
          isVisible={isVisible}
          xPos={xPos}
          yPos={yPos}
          handleClose={closeContextMenu}
        >
          {contextMenuOptions}
        </ContextMenuPrototype>
      )}
      <Card
        className="channel-card"
        cover={
          <div data-aos="zoom-in">
            <Image
              onContextMenu={openContextMenu}
              onClick={onClick}
              src={coverImg}
              alt="channel cover"
            />
          </div>
        }
      >
        <CardMeta className="channel-card-meta" title={metaInfo}>
          meta info
        </CardMeta>
        <InfoBoxPrototype
          onShare={shareChannelHandler}
          onReport={reportChannelItemHandler}
          title={title}
          subTitle={infoBoxSubtitle}
          time={infoBoxTime}
          description={infoBoxDescription}
          infoBoxBtnClass="channels"
          contextmenuOptions={contextMenuOptions}
          isContextMenuVisible={isVisible}
          openContextMenu={openContextMenu}
        />
      </Card>
    </>
  );
};

export default ChannelCard;
