import { 
  CREATE_TEAM_FAIL,
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  GET_TEAMS_FAIL,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  GET_TEAM_FAIL, 
  GET_TEAM_REQUEST, 
  GET_TEAM_SUCCESS,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAIL,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAIL,
  TOGGLE_TEAM_ADMIN_REQUEST,
  TOGGLE_TEAM_ADMIN_SUCCESS,
  TOGGLE_TEAM_ADMIN_FAIL,
  DELETE_TEAM_USER_REQUEST,
  DELETE_TEAM_USER_SUCCESS,
  DELETE_TEAM_USER_FAIL,
  LEAVE_TEAM_REQUEST,
  LEAVE_TEAM_SUCCESS,
  LEAVE_TEAM_FAIL,
  DELETE_TEAM_PLAYLIST_REQUEST,
  DELETE_TEAM_PLAYLIST_SUCCESS,
  DELETE_TEAM_PLAYLIST_FAIL
} from "@redux/constants/TeamConstants";

export const createTeamReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_TEAM_REQUEST:
      return { loading: true };
    case CREATE_TEAM_SUCCESS:
      return { loading: false, data: action.payload };
    case CREATE_TEAM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateTeamReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TEAM_REQUEST:
      return { loading: true };
    case UPDATE_TEAM_SUCCESS:
      return { loading: false, data: action.payload };
    case UPDATE_TEAM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getTeamsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TEAMS_REQUEST:
      return { loading: true };
    case GET_TEAMS_SUCCESS:
      return { loading: false, data: action.payload };
    case GET_TEAMS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getTeamReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_TEAM_REQUEST:
      return { loading: true };
    case GET_TEAM_SUCCESS:
      return {
        loading: false,
        data: action.payload
      }
    case GET_TEAM_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default: 
      return state;
  }
}

export const deleteTeamReducer = (state = {}, action) => {
  switch(action.type) {
    case DELETE_TEAM_REQUEST:
      return { loading: true };
    case DELETE_TEAM_SUCCESS:
      return {
        loading: false,
        data: action.payload
      }
    case DELETE_TEAM_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default: 
      return state;
  }
}

export const deleteTeamUser = (state={}, action) => {
  switch(action.type) {
    case DELETE_TEAM_USER_REQUEST:
      return { loading: true };
    case DELETE_TEAM_USER_SUCCESS:
      return {
        loading: false,
        data: action.payload
      }
    case DELETE_TEAM_USER_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default: 
      return state;
  }
}

export const toggleTeamAdmin = (state={}, action) => {
  switch(action.type) {
    case TOGGLE_TEAM_ADMIN_REQUEST:
      return { loading: true };
    case TOGGLE_TEAM_ADMIN_SUCCESS:
      return {
        loading: false,
        data: action.payload
      }
    case TOGGLE_TEAM_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default: 
      return state;
  }
}

export const leaveTeamReducer = (state={}, action) => {
  switch(action.type) {
    case LEAVE_TEAM_REQUEST:
      return { loading: true };
    case LEAVE_TEAM_SUCCESS:
      return {
        loading: false,
        data: action.payload
      }
    case LEAVE_TEAM_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default: 
      return state;
  }
}

export const deleteTeamPlaylistReducer = (state={}, action) => {
  switch(action.type) {
    case DELETE_TEAM_PLAYLIST_REQUEST:
      return { loading: true };
    case DELETE_TEAM_PLAYLIST_SUCCESS:
      return {
        loading: false,
        data: action.payload
      }
    case DELETE_TEAM_PLAYLIST_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default: 
      return state;
  }
}