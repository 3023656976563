import React from "react";
import "./index.less";

const Like = ({ className, onClick }) => {
    return (
        <svg
            onClick={onClick}
            className={`${className} like-icon-svg`}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <defs></defs>
            <g transform="translate(-2137 789)">
                <rect
                    className="a"
                    width="24"
                    height="24"
                    transform="translate(2137 -789)"
                />
                <g transform="translate(2138 -787)">
                    <path
                        className="b"
                        d="M1,19H4.636V8.2H1ZM21,9.1a1.814,1.814,0,0,0-1.818-1.8H13.445l.864-4.113.027-.288a1.348,1.348,0,0,0-.4-.954L12.973,1,6.991,6.931A1.751,1.751,0,0,0,6.455,8.2v9A1.814,1.814,0,0,0,8.273,19h8.182a1.806,1.806,0,0,0,1.673-1.1l2.745-6.345A1.763,1.763,0,0,0,21,10.9V9.181l-.009-.009Z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Like;
