import {
    SOCIAL_FOLLOW_REQUEST,
    SOCIAL_FOLLOW_SUCCESS,
    SOCIAL_FOLLOW_FAIL,
    SOCIAL_SHARE_REQUEST,
    SOCIAL_SHARE_SUCCESS,
    SOCIAL_SHARE_FAIL,
    SOCIAL_SHARE_DELETE_REQUEST,
    SOCIAL_SHARE_DELETE_SUCCESS,
    SOCIAL_SHARE_DELETE_FAIL,
    SOCIAL_LIKE_REQUEST,
    SOCIAL_LIKE_SUCCESS,
    SOCIAL_LIKE_FAIL,
    SOCIAL_UNLIKE_REQUEST,
    SOCIAL_UNLIKE_SUCCESS,
    SOCIAL_UNLIKE_FAIL,
    SOCIAL_SUGGESTED_PLAYLIST_REQUEST,
    SOCIAL_SUGGESTED_PLAYLIST_SUCCESS,
    SOCIAL_SUGGESTED_PLAYLIST_FAIL,
    SOCIAL_UNFOLLOW_REQUEST,
    SOCIAL_UNFOLLOW_SUCCESS,
    SOCIAL_UNFOLLOW_FAIL,
    SOCIAL_FOLLOWING_PLAYLISTS_REQUEST,
    SOCIAL_FOLLOWING_PLAYLISTS_SUCCESS,
    SOCIAL_FOLLOWING_PLAYLISTS_FAIL,
    SOCIAL_FOLLOWED_PLAYLISTS_REQUEST,
    SOCIAL_FOLLOWED_PLAYLISTS_SUCCESS,
    SOCIAL_FOLLOWED_PLAYLISTS_FAIL,
} from "../constants/socialConstants";

export const socialLikeReducer = (state = {}, action) => {
    switch (action.type) {
        case SOCIAL_LIKE_REQUEST:
            return { loading: true };
        case SOCIAL_LIKE_SUCCESS:
            return { loading: false, message: action.payload };
        case SOCIAL_LIKE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const socialUnlikeReducer = (state = {}, action) => {
    switch (action.type) {
        case SOCIAL_UNLIKE_REQUEST:
            return { loading: true };
        case SOCIAL_UNLIKE_SUCCESS:
            return { loading: false, message: action.payload };
        case SOCIAL_UNLIKE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const socialFollowReducer = (state = {}, action) => {
    switch (action.type) {
        case SOCIAL_FOLLOW_REQUEST:
            return { loading: true };
        case SOCIAL_FOLLOW_SUCCESS:
            return { loading: false, message: action.payload };
        case SOCIAL_FOLLOW_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const socialUnfollowReducer = (state = {}, action) => {
    switch (action.type) {
        case SOCIAL_UNFOLLOW_REQUEST:
            return { loading: true };
        case SOCIAL_UNFOLLOW_SUCCESS:
            return { loading: false, message: action.payload };
        case SOCIAL_UNFOLLOW_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const socialShareReducer = (state = {}, action) => {
    switch (action.type) {
        case SOCIAL_SHARE_REQUEST:
            return { loading: true };
        case SOCIAL_SHARE_SUCCESS:
            return { loading: false, message: action.payload };
        case SOCIAL_SHARE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const socialShareDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case SOCIAL_SHARE_DELETE_REQUEST:
            return { loading: true };
        case SOCIAL_SHARE_DELETE_SUCCESS:
            return { loading: true, message: action.payload };
        case SOCIAL_SHARE_DELETE_FAIL:
            return { loading: true, error: action.payload };
        default:
            return state;
    }
};

export const socialSuggestedPlaylistsReducer = (state = {}, action) => {
    switch (action.type) {
        case SOCIAL_SUGGESTED_PLAYLIST_REQUEST:
            return { loading: true };
        case SOCIAL_SUGGESTED_PLAYLIST_SUCCESS:
            return { loading: false, playlists: action.payload };
        case SOCIAL_SUGGESTED_PLAYLIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const socialFollowingPlaylistsReducer = (state = {}, action) => {
    switch (action.type) {
        case SOCIAL_FOLLOWING_PLAYLISTS_REQUEST:
            return { loading: true };
        case SOCIAL_FOLLOWING_PLAYLISTS_SUCCESS:
            return { loading: false, playlists: action.payload };
        case SOCIAL_FOLLOWING_PLAYLISTS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const socialFollowedPlaylistsReducer = (state = {}, action) => {
    switch (action.type) {
        case SOCIAL_FOLLOWED_PLAYLISTS_REQUEST:
            return { loading: true };
        case SOCIAL_FOLLOWED_PLAYLISTS_SUCCESS:
            return { loading: false, playlists: action.payload };
        case SOCIAL_FOLLOWED_PLAYLISTS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
