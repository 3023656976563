import React from "react";
import List from "@shared/atoms/List/index";

import "./index.less";

const FollowingList = ({ className, children }) => {
    return (
        <List className={`following-list  ${className ? className : ""}`}>
            {children}
        </List>
    );
};

export default FollowingList;
