import { useState, useLayoutEffect } from "react";
import { mobileScreenWidth, mobileScreenHeight } from "@modules/config";

import useWindowSize from "./useWindowSize";

function useDetectMobile() {
    const [isMobile, setIsMobile] = useState(false);
    let windowSize = useWindowSize();

    useLayoutEffect(() => {
        windowSize.width <= mobileScreenWidth &&
        windowSize.height <= mobileScreenHeight
            ? setIsMobile(true)
            : setIsMobile(false);
    }, [windowSize]);

    return isMobile;
}

export default useDetectMobile;
