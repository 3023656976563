import React from "react";
import List from "@shared/atoms/List/index";
import { Title } from "@shared/atoms/Typography";
import "./index.less";

const TransactionsList = ({ hasTitle = true, children }) => {
  return (
    <>
      {hasTitle && (
        <Title className="transaction-list-title" level={3}>
          Transactions
        </Title>
      )}

      <List className="transactions-list">{children}</List>
    </>
  );
};

export default TransactionsList;
