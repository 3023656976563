import React, { useCallback, useContext, useEffect, useState } from "react";
import Modal from "@shared/atoms/Modal/index";
import { Input } from "antd";
import Form from "@components/shared/atoms/Form";

import Button from "@components/shared/atoms/Button";
import { Context } from "@context/context";
import { useDispatch, useSelector } from "react-redux";
import { editGroup } from "@redux/actions/groupActions";

import "./index.less";
import GroupStatusesSelect from "@components/shared/molecules/GroupStatusesSelect";

const EditGroupNameModalPrototype = () => {
  const [groupName, setGroupName] = useState("");
  const [statusId, setStatusId] = useState(null);
  const [status, setStatus] = useState("");

  const { loading } = useSelector((state) => state.editGroup);
  const {
    showEditGroupNameModalPrototype,
    setShowEditGroupNameModalPrototype,
    setCurrentGroupPrototype,
    currentGroupPrototype,
  } = useContext(Context);
  const { statuses } = useSelector((state) => state.playlistStatuses);

  const dispatch = useDispatch();

  const setExistedGroupInfo = useCallback(() => {
    if (currentGroupPrototype) setGroupName(currentGroupPrototype.name);

    if (currentGroupPrototype && currentGroupPrototype.playlists.length) {
      setStatusId(currentGroupPrototype.status_id);
      const status = statuses.find(
        (status) => status.id === currentGroupPrototype.status_id
      );
      if (status && status.name) setStatus(status.name);
    }
  }, [currentGroupPrototype, statuses]);

  useEffect(() => {
    setExistedGroupInfo();
  }, [setExistedGroupInfo]);

  const changeStatus = (e) => {
    const statusId = statuses?.find(
      (status) => status.name === e.target.value
    ).id;

    setStatus(e.target.value);
    setStatusId(statusId);
  };

  function onSubmit() {
    Promise.resolve(
      dispatch(
        editGroup(currentGroupPrototype.id, {
          name: groupName,
          status_id: statusId,
        })
      )
    ).then(() => {
      closeHandler();
    });
  }
  const closeHandler = () => {
    setShowEditGroupNameModalPrototype(false);
    setCurrentGroupPrototype(null);
  };
  return (
    <Modal
      visible={showEditGroupNameModalPrototype}
      className="modal-basic-prototype alert-modal-prototype group-playlists"
      mask={true}
      footer={<></>}
      closable={true}
      onCancel={() => {
        closeHandler();
      }}
    >
      <Form>
        <Form.Item>
          <Input
            onChange={(e) => setGroupName(e.target.value)}
            value={groupName}
            placeholder="Group name"
            className="group-input"
          />
        </Form.Item>
        <Form.Item>
          <GroupStatusesSelect
            changeStatusHandler={changeStatus}
            defaultValue={status}
            activeStatus={status}
          />
        </Form.Item>
        <Button
          type="submit"
          disabled={loading}
          onClick={onSubmit}
          className="ant-btn-prototype"
        >
          Edit
        </Button>
      </Form>
    </Modal>
  );
};

export default EditGroupNameModalPrototype;
