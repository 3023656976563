import HomeWelcome from "@components/shared/molecules/HomeWelcome";
import { selectHomeDirectPublishContent, selectHomeWatchLaterContent } from "@redux/reducers/homeReducers";
import { memo } from "react";
import { useSelector } from "react-redux";

import './index.less'

const PlaylistMessage = ({ playlist }) => {
    const { profile } = useSelector((state) => state.userProfile);

    const { data: directData } = useSelector(selectHomeDirectPublishContent);
    const { data: WatchLaterData } = useSelector(selectHomeWatchLaterContent);

    return (
        <div className="homepage-container playlis-message">
        {playlist?.direct_publish && (
            <HomeWelcome
                data={directData?.home}
                playlistData={directData?.home.playlist}
                show={!profile?.data?.hide_direct_publish_welcome}
            />
        )}
        {playlist?.watch_later && (
            <HomeWelcome
                data={WatchLaterData?.home}
                playlistData={WatchLaterData?.home.playlist}
                show={!profile?.data?.hide_watch_later_welcome}
            />
        )}
        </div>
    )
}

export default memo(PlaylistMessage);