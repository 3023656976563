import React from "react";
import "./index.less";

const SpinnerPrototype = ({ size = 8, withOverlay = false , className=""}) => {
  const spinnerSizeStyle = {
    width: `${size}rem`,
    height: `${size}rem`,
    margin: ` -${Number(size) / 2}rem 0 0 -${Number(size) / 2}rem`,
    borderTop: `${Number(size) / 10}rem solid rgba(255, 255, 255, 0.2)`,
    borderRight: `${Number(size) / 10}rem solid rgba(255, 255, 255, 0.2)`,
    borderBottom: `${Number(size) / 10}rem solid rgba(255, 255, 255, 0.2)`,
    borderLeft: `${Number(size) / 10}rem solid #ffffff`,
  };
  return (
    <div className={`${withOverlay ? "spinner-overlay-prototype " : ""}`}>
      <div className={`spinner-prototype ${className}`} style={spinnerSizeStyle}></div>
    </div>
  );
};

export default SpinnerPrototype;
